import React from 'react';
import { Route, Routes } from 'react-router';
import { EAuthType } from '../../components/AuthContent';
import { Footer } from '../../components/Footer';
import { AboutPage } from '../../pages/AboutPage';
import { AuthPage } from '../../pages/AuthPage';
import { ComingSoonPage } from '../../pages/ComingSoonPage';
import { NewListingPage, NewListingSubPage, NewListingPublishedPage } from '../../pages/listings/create';
import { DialogSubPage } from '../../pages/DialogSubPage';
import { DiscoverPage } from '../../pages/DiscoverPage';
import { FAQPage } from '../../pages/FAQPage';
import { FinanceReportPage } from '../../pages/FinanceReportPage';
import { HomePage } from '../../pages/HomePage';
import { ListingDetailsPage } from '../../pages/listings/view/ListingDetailsPage';
import { ListingDetailsPhotosPage } from '../../pages/listings/view/ListingDetailsPhotosPage';
import { NotFoundPage } from '../../pages/NotFoundPage';
import { ProfilePage } from '../../pages/ProfilePage';
import { SearchListingsPage } from '../../pages/SearchPage';
import { SitemapPage } from '../../pages/SitemapPage';
import { SupportUsPage } from '../../pages/SupportUsPage';
import { AccountPage } from '../../pages/user/AccountPage';
import { AccountSubPage } from '../../pages/user/AccountSubPage/AccountSubPage';
import { UserDashboardPage } from '../../pages/user/UserDashboardPage';
import { useRemoteConfig } from '../../services/remoteConfig';
import { SUBDOMAIN_CAREERS, SUBDOMAIN_FORUMS, SUBDOMAIN_HELP_CENTER, SUBDOMAIN_NEWS, SUBDOMAIN_SHOP, buildSubdomainBaseUrl } from '../../types/constants';
import { RC_MAINTENANCE_MODE, RC_SUPPORT } from '../../types/remote_config';
import { ROUTE_PARAMS, ROUTE_PATH, ROUTE_SUBPATH } from './RouterConfig';
import { UserRoleSelector } from './UserRoleSelector';
import { ForumsRoutes } from './subdomains/ForumsRoutes';
import { HelpCenterRoutes } from './subdomains/HelpCenterRoutes';
import { ShopRoutes } from './subdomains/ShopRoutes';
import { MaintenanceModePage } from '../../pages/MaintenanceModePage';
import { NewsRoutes } from './subdomains/NewsRoutes';
import { UserInboxPage } from '../../pages/user/UserInboxPage';
import { UserListingsPage } from '../../pages/user/UserListingsPage';
import { UserBookingsPage } from '../../pages/user/UserBookingsPage';
import { ListingBookingPage } from '../../pages/listings/ListingBookingPage';
import { SimpleAppBar } from '../../components/appbar/SimpleAppBar/SimpleAppBar';
import { EditListingPage, EditListingSubPage } from '../../pages/listings/edit';
import { ChatContentPage, ChatDetailsPage } from '../../pages/chat';
import { HomeAppBar } from '../../components/appbar/HomeAppBar';
import { DashboardAppBar } from '../../components/appbar/DashboardAppBar';
import { CareersRoutes } from './subdomains/CareersRoutes';
import { AnonymousRestrictedRoute } from './AnonymousRestictedRoute';
import { TermsPage } from '../../pages/terms/TermsPage';
import { PolicyPage } from '../../pages/terms/PolicyPage';
import { RedirectRoute } from './RedirectRoute';
import { ShopCartProvider } from '../../context/shop';
import { TestimonialPage } from '../../pages/TestimonialPage/TestimonialPage';
import { extractSubdomain } from './subdomains/utils';
import { SubdomainLoadingPage } from '../../pages/SubdomainLoadingPage';

const SUBDOMAINS_LIST = [
    SUBDOMAIN_CAREERS,
    SUBDOMAIN_FORUMS,
    SUBDOMAIN_HELP_CENTER,
    SUBDOMAIN_SHOP,
    SUBDOMAIN_NEWS
];

export const FullRoutes = () => {
    const {
        [RC_MAINTENANCE_MODE]: remoteConfigMaintenanceMode,
        isLoading
    } = useRemoteConfig();

    if (isLoading) {
        return <SubdomainLoadingPage />
    }

    if (remoteConfigMaintenanceMode.enabled
        && remoteConfigMaintenanceMode.subdomainsAffected === "ALL") {
        return <MaintenanceModePage />
    }

    const subdomain = extractSubdomain();
    // console.log("subdomain is", subdomain);
    switch (subdomain) {
        case SUBDOMAIN_CAREERS:
            return <CareersRoutes />
        case SUBDOMAIN_FORUMS:
            return <ForumsRoutes />
        case SUBDOMAIN_HELP_CENTER:
            return <HelpCenterRoutes />
        case SUBDOMAIN_SHOP:
            return (
                /* current user's shop cart */
                <ShopCartProvider>
                    <ShopRoutes />
                </ShopCartProvider>
            );
        case SUBDOMAIN_NEWS:
            return <NewsRoutes />
        default:
            return <MainRoutes />
    }
}

/** Routes for the main domain (non-subdomain) */
const MainRoutes = () => {
    const {
        [RC_SUPPORT]: remoteConfigSupport,
        [RC_MAINTENANCE_MODE]: remoteConfigMaintenanceMode
    } = useRemoteConfig();

    if (remoteConfigMaintenanceMode.enabled
        && remoteConfigMaintenanceMode.subdomainsAffected.includes("MAIN")) {
        return <MaintenanceModePage />
    }

    return (
        <>
            {/* AppBar */}
            <Routes>
                {/* User dashboard app bar */}
                <Route path={ROUTE_PATH.dashboard} element={<DashboardAppBar />}>
                    <Route index element={<UserRoleSelector />} />
                    <Route path={"*"} element={<UserRoleSelector />} />
                </Route>
                {/* App bar for the booking flow (keep it as slim as possible) */}
                <Route path={ROUTE_PATH.book}>
                    <Route path={'*'} element={<SimpleAppBar />} />
                </Route>
                {/* App bar for the listing creation flow (keep it as slim as possible) */}
                <Route path={ROUTE_PATH.listings}>
                    <Route path={ROUTE_SUBPATH.new}>
                        <Route index element={<SimpleAppBar />} />
                        <Route path={'*'} element={<SimpleAppBar />} />
                    </Route>
                    <Route path={ROUTE_SUBPATH.publish_success}>
                        <Route index element={<SimpleAppBar />} />
                        <Route path={'*'} element={<SimpleAppBar />} />
                    </Route>
                </Route>
                {/* Simple app bar for terms */}
                <Route path={ROUTE_PATH.terms} element={<SimpleAppBar />} />
                <Route path={ROUTE_PATH.policies} element={<SimpleAppBar />} />
                {/* Header/app bar not showing when redirecting to subdomain */}
                {SUBDOMAINS_LIST.map(sd => <Route key={sd} path={sd} element={null} />)}
                {/* Default App bar */}
                <Route path={"*"} element={<HomeAppBar />} />
            </Routes>

            {/* Content */}
            <Routes>
                <Route path={ROUTE_PATH.home} element={<HomePage />} />
                {/* Testimonials */}
                <Route path={ROUTE_PATH.testimonials} element={<TestimonialPage />} />
                {/* Terms and policies */}
                <Route path={ROUTE_PATH.terms} element={<TermsPage />} />
                <Route path={ROUTE_PATH.policies} element={<PolicyPage />} />
                {/* About */}
                <Route path={ROUTE_PATH.about}>
                    <Route index element={<AboutPage />} />
                    <Route path={ROUTE_PATH.faq} element={<FAQPage />} />
                    <Route path={ROUTE_PATH.finance_report} element={<FinanceReportPage />} />
                </Route>
                {/* Listings */}
                <Route path={ROUTE_PATH.listings}>
                    <Route index element={<NotFoundPage />} />
                    <Route path={ROUTE_SUBPATH.new} element={<NewListingPage />}>
                        <Route index element={<NewListingSubPage />} />
                        <Route path={`:${ROUTE_PARAMS.listingID}`}>
                            <Route index element={<NewListingSubPage />} />
                            <Route path={`:${ROUTE_PARAMS.flowStepID}`} element={<NewListingSubPage />} />
                        </Route>
                    </Route>
                    <Route path={ROUTE_SUBPATH.publish_success}>
                        <Route index element={<NewListingPublishedPage />} />
                        <Route path={`:${ROUTE_PARAMS.listingID}`} element={<NewListingPublishedPage />} />
                    </Route>
                    <Route path={`:${ROUTE_PARAMS.listingID}`} element={<ListingDetailsPage />}>
                        <Route path={ROUTE_SUBPATH.photos} element={<DialogSubPage><ListingDetailsPhotosPage /></DialogSubPage>} />
                        <Route path={ROUTE_SUBPATH.reviews} element={<div>Listing reviews</div>} />
                    </Route>
                </Route>
                {/* Users Profile */}
                <Route path={ROUTE_PATH.users}>
                    <Route path={ROUTE_PATH.auth_join} element={<AuthPage authType={EAuthType.SIGN_UP} />} />
                    <Route path={ROUTE_PATH.auth_login} element={<AuthPage authType={EAuthType.LOG_IN} />} />
                    <Route path={ROUTE_PATH.auth_link} element={<AuthPage authType={EAuthType.LINK} />} />
                    <Route path={`:${ROUTE_PARAMS.userID}`} element={<ProfilePage />} />
                </Route>
                {/* User's account */}
                <Route path={ROUTE_PATH.account} element={<AnonymousRestrictedRoute />}>
                    <Route index element={<AccountPage />} />
                    {/* <Route path={ROUTE_SUBPATH.account_perso} element={<ComingSoonPage />} />
                    <Route path={ROUTE_SUBPATH.account_login} element={<ComingSoonPage />} />
                    <Route path={ROUTE_SUBPATH.account_finance} element={<ComingSoonPage />} />
                    <Route path={ROUTE_SUBPATH.account_notifs} element={<ComingSoonPage />} />
                    <Route path={ROUTE_SUBPATH.account_privacy} element={<ComingSoonPage />} />
                    <Route path={ROUTE_SUBPATH.account_prefs} element={<ComingSoonPage />} />
                    <Route path={ROUTE_SUBPATH.account_referrals} element={<ComingSoonPage />} /> */}
                    {/* Simplify the route by making the subpage a param itself */}
                    <Route path={`:${ROUTE_PARAMS.accountPage}`} element={<AccountSubPage />} />
                </Route>
                {/* Events */}
                <Route path={ROUTE_PATH.events} element={<ComingSoonPage />} />
                {/* Search */}
                <Route path={ROUTE_PATH.search}>
                    <Route index element={<DiscoverPage action='search' />} />
                    <Route path={ROUTE_PATH.listings} element={<SearchListingsPage />} />
                    {/* <Route path={ROUTE_PATH.users} element={<ComingSoonPage />} />
                    <Route path={ROUTE_PATH.events} element={<ComingSoonPage />} /> */}
                    <Route path={ROUTE_PATH.users} element={<DiscoverPage action='search' />} />
                    <Route path={ROUTE_PATH.events} element={<DiscoverPage action='search' />} />
                </Route>
                {/* Book */}
                <Route path={ROUTE_PATH.book} element={<AnonymousRestrictedRoute />}>
                    <Route index element={<DiscoverPage action='book' />} />
                    <Route path={ROUTE_PATH.listings} >
                        <Route index element={<SearchListingsPage />} />
                        <Route path={`:${ROUTE_PARAMS.listingID}`} element={<ListingBookingPage />} />
                    </Route>
                    <Route path={ROUTE_PATH.users} element={<ComingSoonPage />} />
                    <Route path={ROUTE_PATH.events} element={<ComingSoonPage />} />
                </Route>

                {/* Dashboard */}
                <Route path={ROUTE_PATH.dashboard} element={<AnonymousRestrictedRoute />}>
                    <Route index element={<UserDashboardPage />} />
                    <Route path={ROUTE_PATH.inbox} element={<UserInboxPage />}>
                        <Route path={`:${ROUTE_PARAMS.threadID}`} element={<ChatContentPage />}>
                            <Route path={ROUTE_SUBPATH.details} element={<ChatDetailsPage />} />
                        </Route>
                    </Route>
                    <Route path={ROUTE_PATH.listings} element={<UserListingsPage />}>
                        <Route path={`:${ROUTE_PARAMS.listingID}`} element={<EditListingPage />}>
                            <Route path={`:${ROUTE_PARAMS.settingsTabID}`} element={<EditListingSubPage />} />
                        </Route>
                    </Route>
                    <Route path={ROUTE_PATH.bookings} element={<UserBookingsPage />} />
                    <Route path={ROUTE_PATH.events} element={<ComingSoonPage />} />
                </Route>

                {/* Testing */}
                {/* <Route path={'test'} element={<TestPage />}>
                        <Route path={'sub'} element={<TestDialogSubPage />} />
                    </Route> */}

                {/* Support Us Page */}
                {
                    !!remoteConfigSupport.enabled &&
                    <Route path={ROUTE_PATH.support}>
                        <Route index element={<SupportUsPage />} />
                    </Route>
                }

                {/* Sitemap */}
                <Route path={ROUTE_PATH.sitemap}>
                    <Route index element={<SitemapPage />} />
                </Route>

                {/* Redirection of old link to their respective subdomain */}
                {SUBDOMAINS_LIST.map(sd => <Route key={sd} path={sd} element={<RedirectRoute redirectPath={buildSubdomainBaseUrl(sd)} />} />)}

                {/* Anything else */}
                <Route path={'*'} element={<NotFoundPage />} />
            </Routes>

            {/* Footer */}
            <Routes>
                {/* Not showing for sitemap */}
                <Route path={ROUTE_PATH.sitemap} element={null} />
                {/* Not showing for chat/inbox */}
                <Route path={ROUTE_PATH.dashboard}>
                    <Route path={ROUTE_PATH.inbox} >
                        <Route index element={null} />
                        <Route path={'*'} element={null} />
                    </Route>
                </Route>
                {/* For the listing creation flow (keep it as slim as possible) */}
                <Route path={ROUTE_PATH.listings}>
                    <Route path={ROUTE_SUBPATH.new}>
                        <Route path={'*'} element={null} />
                    </Route>
                </Route>
                {/* Footer not showing when redirecting to subdomain */}
                {SUBDOMAINS_LIST.map(sd => <Route key={sd} path={sd} element={null} />)}
                {/* The footer showing in every other pages */}
                <Route path={"*"} element={<Footer />} />
            </Routes>
        </>
    )
}
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography, debounce } from '@mui/material';
import * as geofire from 'geofire-common';
import { CurrentLocationButton } from '../../../containers/CurrentLocationButton';
import { GOOGLE_MAPS_API_KEY } from '../../../services/firebase';
import { ListingCreationFlowContext } from '../../../context/Listing';
import { useTranslation } from 'react-i18next';
import { GoogleMap, Libraries, LoadScript, Marker } from '@react-google-maps/api';
import { getListingInfo } from '../../../services/listing';
import { Listing, ListingAddress } from '../../../types/db';
import { DeepPartial } from '../../../types/common';
import { useQuery } from 'react-query';
import { getListingInfoFromType } from '../../../utilities/listings';
import { SearchLocationInputView } from '../../../components/SearchLocationInput/SearchLocationInput';
import { Address } from '../../../components/address/Address';
import { Address as AddressType, FieldName } from '../../../components/address/Address.utils';
import { getAddressComp } from '../../../utilities/address';

/** The list of libraries used by the ListingLocationPage */
const GOOGLE_LIBRARIES: Libraries = ["places", "geocoding"];

/** The location page in the listing creation flow */
export const ListingLocationPage = () => {
    return (
        <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}
            libraries={GOOGLE_LIBRARIES}
            loadingElement={<CircularProgress />}
            onLoad={() => console.log("loaded")}>
            <Container />
        </LoadScript>
    );
}

type MapLatLng = { lat: number, lng: number };
const DEFAULT_MAP_CENTER: MapLatLng = { lat: 0, lng: 0 };

const isValidLocation = (loc?: DeepPartial<Listing['location']>) => {
    return !!loc;
}

/** Helper function which take a geoloc pos and return a google.maps.places.PlaceResult */
const reverseGeoloc = (pos: MapLatLng) => {
    return new window.google.maps.Geocoder().geocode({ location: pos })
        .then((response) => {
            // console.log('reverse geocode result', response.results[0]);
            return response.results[0];
        }).catch(e => {
            console.error('reverse geocode error', e)
            return Promise.reject()
        })
}

/** The location container for the creation flow which take care for taking/updating info of the context */
export const Container = () => {
    const listingCreationFlowContext = useContext(ListingCreationFlowContext);
    if (!listingCreationFlowContext) {
        throw new Error('Need listingCreationFlowContext')
    }
    const { t } = useTranslation();
    // In the listing, only interested by the location 
    // and the breadcrumb to identify if this is a children listing (ie: not changing its location)
    const { listing: { location: loc, breadcrumbs, type: typeListing }, setActivePageInfo } = listingCreationFlowContext;

    const { data: parentData } = useQuery(["listing", breadcrumbs?.[breadcrumbs?.length - 1]?.id],
        () => getListingInfo(breadcrumbs?.[breadcrumbs?.length - 1]?.ref),
        {
            enabled: !!breadcrumbs?.length && !loc,//enabled if there is parent and the location isn't defined yet
            refetchOnWindowFocus: false,
        });

    // a reference to the map, used for the PlacesService
    const mapRef = useRef<google.maps.Map>();
    // the location of the listing
    const location = loc || parentData?.location;
    // the center of the map from the given location
    const mapCenter: MapLatLng = (location && location.lat && location.lng) ?
        { lat: location.lat, lng: location.lng }
        : DEFAULT_MAP_CENTER;

    useEffect(() => {
        // console.warn('useEffect', JSON.stringify(breadcrumbs), loc)
        if (isValidLocation(location)) {
            setActivePageInfo({}, true);
        } else {
            setActivePageInfo({}, false)
        }
    }, [breadcrumbs, location, setActivePageInfo])

    // Handle the selection of a place in the list of autocomplete
    const handlePlaceSelect = (place: google.maps.places.AutocompletePrediction | null) => {
        // console.log('handlePlaceSelect', place);
        if (!place) return;//if no place selected
        if (!mapRef.current) {
            console.warn('No mapRef,cannot fetch place details')
            return;//if no map reference
        }
        // console.log('Getting details for place', place);
        //fetch the details of this place
        (new google.maps.places.PlacesService(mapRef.current))
            .getDetails({
                placeId: place.place_id,
                fields: ["address_components", "geometry"]
            },
                (results, status) => {
                    // console.log('result', results, status)
                    if (status === google.maps.places.PlacesServiceStatus.OK && !!results?.geometry?.location) {
                        const loc = results.geometry.location;
                        const newCenter = {
                            lat: loc.lat(),
                            lng: loc.lng(),
                        };

                        mapRef.current?.panTo(newCenter);
                        const hash = geofire.geohashForLocation([newCenter.lat, newCenter.lng]);
                        setActivePageInfo({
                            location: {
                                place_id: place.place_id,
                                geopoint: { latitude: newCenter.lat, longitude: newCenter.lng } as firebase.default.firestore.GeoPoint,
                                ...newCenter,
                                geohash: hash,
                                address: {
                                    line1: getAddressComp(results, "street_number") + ' ' + getAddressComp(results, "route"),
                                    zip: getAddressComp(results, 'postal_code'),
                                    city: getAddressComp(results, 'locality', 'long_name') || getAddressComp(results, 'administrative_area_level_2', 'long_name'),
                                    state_name: getAddressComp(results, 'administrative_area_level_1', 'long_name'),
                                    state_code: getAddressComp(results, 'administrative_area_level_1', 'short_name'),
                                    country_name: getAddressComp(results, 'country', 'long_name'),
                                    country_code: getAddressComp(results, 'country', 'short_name'),
                                }
                            }
                        })
                    }
                });
    };

    // Handle the press on the current location of the user
    const handleCurrentLocationResult = (args: {
        lat: number;
        lng: number;
    }) => {
        reverseGeoloc(args)
            .then((result) => {
                const newCenter = args;
                const hash = geofire.geohashForLocation([newCenter.lat, newCenter.lng]);
                setActivePageInfo({
                    location: {
                        place_id: result.place_id,
                        geopoint: { latitude: newCenter.lat, longitude: newCenter.lng } as firebase.default.firestore.GeoPoint,
                        ...newCenter,
                        geohash: hash,
                        address: {
                            line1: getAddressComp(result, "street_number") + ' ' + getAddressComp(result, "route"),
                            zip: getAddressComp(result, 'postal_code'),
                            city: getAddressComp(result, 'locality', 'long_name') || getAddressComp(result, 'administrative_area_level_2', 'long_name'),
                            state_name: getAddressComp(result, 'administrative_area_level_1', 'long_name'),
                            state_code: getAddressComp(result, 'administrative_area_level_1', 'short_name'),
                            country_name: getAddressComp(result, 'country', 'long_name'),
                            country_code: getAddressComp(result, 'country', 'short_name'),
                        }
                    }
                })
            });
    }

    // debounce isn't passing the typing
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const recenterMap = useCallback(debounce((address) => {
        const geocoder = new google.maps.Geocoder();
        const place = Object.values(address).filter(v => !!v).join(',');
        // console.log('recenter', address, place)
        //research lat/lng of this place
        geocoder.geocode({ address: place }, (results, status) => {
            // console.log('recenter result', results, status)
            if (status === google.maps.GeocoderStatus.OK && results?.[0]) {
                const location = results[0].geometry.location;
                const newCenter = {
                    lat: location.lat(),
                    lng: location.lng(),
                };

                mapRef.current?.panTo(newCenter);
                const hash = geofire.geohashForLocation([newCenter.lat, newCenter.lng]);
                setActivePageInfo(info => ({
                    location: {
                        ...info.location,
                        geopoint: { latitude: newCenter.lat, longitude: newCenter.lng } as firebase.default.firestore.GeoPoint,
                        ...newCenter,
                        geohash: hash,
                    }
                }))
            }
        })
    }, 1000)
        , [setActivePageInfo])

    const [hasManualEdit, setHasManualEdit] = useState(false)

    const handleManualAddressChange = (newAddress: AddressType) => {
        setHasManualEdit(true);
        const newLoc: ListingAddress = {
            line1: newAddress[FieldName.Address1],
            line2: newAddress[FieldName.Address2],
            zip: newAddress[FieldName.PostalCode],
            city: newAddress[FieldName.City],
            state_code: newAddress[FieldName.Zone],
            country_code: newAddress[FieldName.Country],
        }
        // console.log('manual change', loc, location, newAddress, newLoc)
        setActivePageInfo(info => ({
            location: {
                ...info.location,
                address: {
                    ...info.location?.address,
                    ...newLoc,
                },
                place_id: undefined,//manually changed, so reset place_id
            }
        }));
    }

    const handleAddressConfirmation = () => {
        if (!hasManualEdit) return;
        setHasManualEdit(false);//reset
        recenterMap(location?.address)
    }

    const isSubListing = !!breadcrumbs?.length;
    const address: AddressType | undefined = !!location?.address ?
        {
            [FieldName.Address1]: location.address.line1 || '',
            [FieldName.Address2]: location.address.line2,
            [FieldName.PostalCode]: location.address.zip || '',
            [FieldName.City]: location.address.city || '',
            [FieldName.Zone]: location.address.state_code,
            [FieldName.Country]: location.address.country_code || '',
        } : undefined

    return (
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={6}>
                <Stack direction={'column'} spacing={1}>
                    {isSubListing ?
                        <Typography>{t('listing.creation.locationSubListingMessage', { type: typeListing ? getListingInfoFromType(typeListing, t).title : t('listing.type.generic') })}</Typography>
                        :
                        <SearchLocationInputView
                            onPlaceSelected={(place) => handlePlaceSelect(place)}
                            label={t('listings.search.locationInputLabel')}
                            placeholder={t('listings.search.locationInputPlaceholder')}
                            variant="outlined"
                        />
                    }
                    <Divider>{t('listing.creation.locationAddressTitle')}</Divider>
                    <Address address={address}
                        onChange={handleManualAddressChange}
                        editable
                        editableFields={isSubListing ? [FieldName.Address2] : undefined}
                        omitFields={[FieldName.FirstName, FieldName.LastName, FieldName.Phone, FieldName.Email, FieldName.Company]} />
                    <Button onClick={handleAddressConfirmation} variant={'contained'} disabled={!hasManualEdit}>{t('listing.creation.locationConfirmBtn')}</Button>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ minHeight: 300 }}>
                <>
                    <Box sx={{ display: !!location ? 'flex' : 'none', height: '100%' }}>
                        <GoogleMap
                            mapContainerStyle={{
                                height: '100%',
                                width: '100%',
                            }}
                            options={{
                                disableDefaultUI: true,
                                streetViewControl: false,
                                ...(isSubListing ?
                                    { disableDoubleClickZoom: true, zoomControl: false, gestureHandling: 'none' }
                                    : {})
                            }}
                            center={mapCenter}
                            zoom={15}
                            onLoad={(map) => { mapRef.current = map }}
                        >
                            {
                                mapCenter !== DEFAULT_MAP_CENTER &&
                                <Marker position={mapCenter} />
                            }
                        </GoogleMap>
                    </Box>
                    <Box sx={{ display: !!location ? 'none' : 'block', justifyContent: 'center', alignItems: 'center' }}>
                        <CurrentLocationButton label={t('listing.creation.locationCurrentBtn')} onCurrentLocation={handleCurrentLocationResult} />
                    </Box>
                </>
            </Grid>
        </Grid>
    );
}
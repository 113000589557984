import React from "react";
import { useNavigate } from "react-router";
import { ForumsNewPostContainer } from "../../../containers/forums/ForumsNewPostContainer";
import { useForumsNavigation } from "../../../hooks/useForumNavigation";

/** Page to create a new post on the forum */
export const ForumsNewPostPage = () => {
    const navigate = useNavigate();
    const { navigateToPost } = useForumsNavigation();

    const handleNavigateBack = () => {
        navigate(-1);
    }
    return <ForumsNewPostContainer onGoBack={handleNavigateBack} onNavigateToPost={navigateToPost} />
}
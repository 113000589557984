import React from 'react';
import { Breadcrumbs, Typography, Link, Skeleton } from '@mui/material';
import { PropsWithLoadingState } from '../../../utilities';

interface ListingBreadcrumbsItem {
    /** The label of the breadcrumb */
    label: string;
    /**
     * The icon placed in front of the label
     * @optional
     */
    icon?: (iconProps: any) => JSX.Element;
    /**
     * Callback on click (optional if not clickable like the last item)
     */
    onClick?: () => void;
}

export interface IListingBreadcrumbsProps {
    listingBreadcrumbs: ListingBreadcrumbsItem[];
}

export const ListingBreadcrumbs = ({ listingBreadcrumbs, loading }: PropsWithLoadingState<IListingBreadcrumbsProps>) => {
    if (!listingBreadcrumbs || !listingBreadcrumbs.length) {
        return null;
    }

    return (
        <Breadcrumbs>
            {
                !loading ?
                    listingBreadcrumbs.map(({ label, onClick, icon }, index) => {
                        const key = `ListingBreadcrumb-${index}`;
                        const iconProps = { sx: { mr: 0.5 }, fontSize: "inherit" };
                        if (index === (listingBreadcrumbs.length - 1) || !onClick) {
                            return (
                                <Typography
                                    key={key}
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="text.primary"
                                    aria-label={label}
                                >
                                    {icon?.(iconProps)}
                                    {label}
                                </Typography>
                            );
                        }
                        return (
                            <Link
                                key={key}
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                aria-label={label}
                                onClick={onClick}
                            >
                                {icon?.(iconProps)}
                                {label}
                            </Link>);
                    })
                    :
                    new Array(2).fill({}).map((_, index) => {
                        const key = `ListingBreadcrumb-loading-${index}`;
                        return (
                            <Skeleton key={key} variant={'text'}>
                                <Typography sx={{ minWidth: 50, minHeight: 20 }}>Loading</Typography>
                            </Skeleton>

                        );
                    })
            }
        </Breadcrumbs>
    );
}
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTE_PATH, buildPath } from "../../utilities/navigation";

export const ComingSoonPage = () => {
  return (
    <Stack
      sx={{ m: 2 }}
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      spacing={2}>
      <img
        src="https://cdn.pixabay.com/photo/2019/02/21/01/58/under-construction-4010445__340.jpg"
        alt="Page under construction"
      />
      <Typography variant={'h2'}>Coming soon!</Typography>
      <Button
        component={Link}
        to={buildPath(ROUTE_PATH.about)}
        variant="contained"
      >
        Learn more
      </Button>
    </Stack>
  );
};

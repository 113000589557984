import { db } from "./firebase";
import firebase from "firebase/app";

//TODO only left here as example, can be deleted anytime

// export const createGroceryList = (userName, userId) => {
//     const groceriesColRef = db.collection('groceryLists')
//     return groceriesColRef.add({
//         created: serverTimestamp(),
//         createdBy: userId,
//         users: [{
//             userId: userId,
//             name: userName
//         }]
//     });
// };

export const getGroceryList = (groceryListId: string) => {
    return db.collection('groceryLists').doc(groceryListId).get();
};

export const getGroceryListItems = (groceryListId: string) => {
    return db.collection('groceryLists').doc(groceryListId).collection('items').get();
}

// export const streamGroceryListItems = (groceryListId:string, snapshot, error) => {
//     const itemsColRef = db.collection('groceryLists').doc(groceryListId).collection('items');
// const itemsQuery = db.query(itemsColRef, orderBy('created'))
// return db.onSnapshot(itemsQuery, snapshot, error);
// };

// export const addUserToGroceryList = (userName, groceryListId, userId) => {
//     const groceryDocRef = doc(db, 'groceryLists', groceryListId)
//     return updateDoc(groceryDocRef, {
//         users: arrayUnion({
//             userId: userId,
//             name: userName
//         })
//     });
// };

// export const addGroceryListItem = (item, groceryListId, userId) => {
//     return getGroceryListItems(groceryListId)
//         .then(querySnapshot => querySnapshot.docs)
//         .then(groceryListItems => groceryListItems.find(groceryListItem => groceryListItem.data().name.toLowerCase() === item.toLowerCase()))
//         .then((matchingItem) => {
//             if (!matchingItem) {
//                 const itemsColRef = collection(db, 'groceryLists', groceryListId, 'items')
//                 return addDoc(itemsColRef, {
//                     name: item,
//                     created: serverTimestamp(),
//                     createdBy: userId
//                 });
//             }
//             throw new Error('duplicate-item-error');
//         });
// };

export type QueryVariablesWhere = {
    fieldPath: string | firebase.firestore.FieldPath,
    opStr: firebase.firestore.WhereFilterOp,
    value: any
};
export type QueryVariables = {
    limit?: number,
    limitToLast?: number,
    startAfter?: firebase.firestore.DocumentReference,
    startAt?: firebase.firestore.DocumentReference,
    endBefore?: firebase.firestore.DocumentReference,
    endAt?: firebase.firestore.DocumentReference,
    orderBy?: {
        field: string,
        dir?: firebase.firestore.OrderByDirection
    },
    where?: QueryVariablesWhere[],
};

/** Helper function which apply all the defined variable to a query */
export const applyVariablesToQuery = (
    query: firebase.firestore.Query<firebase.firestore.DocumentData>,
    variables?: QueryVariables)
    : firebase.firestore.Query<firebase.firestore.DocumentData> => {
    if (!variables) {
        //if no variables to apply, just return the query as is
        return query;
    }

    let tempQuery = query;
    const { orderBy, where, ...restVariables } = variables;

    if (orderBy) {
        tempQuery = tempQuery.orderBy(orderBy.field, orderBy.dir);
    }

    if (!!where?.length) {
        where.forEach(w => {
            tempQuery = tempQuery.where(w.fieldPath, w.opStr, w.value);
        })
    }

    Object.keys(restVariables).forEach((keyString) => {
        const key = keyString as keyof Omit<QueryVariables, 'orderBy' | 'where'>;
        const value = restVariables[key] as any;
        if (!!value) {
            tempQuery = tempQuery[key](value);
        }
    })

    return tempQuery;
}
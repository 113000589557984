import React, { PropsWithChildren } from "react";
import { Box, Button, Divider, Stack, Tab, Tabs, Typography } from "@mui/material";
import { functions } from "../../../services/firebase";

export interface IHelpCategoriesProps<T> {
    selectedCategory: T;
    categories: {
        value: T;
        title: string;
    }[];
    onCategoryChange: (value: T) => void;
}

/** The display of all the topics with tabs to select one category */
export function HelpCategories<T>({
    selectedCategory,
    categories,
    onCategoryChange,
    children,
}: PropsWithChildren<IHelpCategoriesProps<T>>) {
    const handleChange = (_: React.SyntheticEvent, newValue: T) => {
        onCategoryChange(newValue);
    }
    return (
        <Stack direction={'column'}>
            <Typography variant="h1">All topics</Typography>
            <Typography>Browse our full library of help topics.</Typography>
            <Button onClick={() => { functions.httpsCallable("createHelpArticle")() }}>Create article</Button>
            <Button onClick={() => { functions.httpsCallable("createHelpTopic")() }}>Create topic</Button>
            <Tabs
                value={!!selectedCategory && selectedCategory}
                onChange={handleChange}
            >
                {
                    categories.map((cat, index) =>
                        <Tab key={`cat-index-${index}`} label={cat.title} value={cat.value} />
                    )
                }
            </Tabs>
            <Divider />
            <Box sx={{ py: 2, px: 1 }}>
                {children}
            </Box>
        </Stack>
    )
}
import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { PropsWithLoadingState } from '../../../utilities';
import { DEFAULT_LISTING_IMAGE_URL } from '../../../utilities/assets/assetHelper';

interface IListingImageProps {
    /** The image url of the listing, if not provided or null, will show a default listing image */
    imageUrl?: string | null;
    /** The description of the image (will be used for alt) */
    description?: string;
}

export const ListingImage = ({ imageUrl, description, loading = false }: PropsWithLoadingState<IListingImageProps>) => {

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            {
                !!loading ?
                    <Skeleton variant={"rectangular"} sx={{ height: '100%', width: '100%' }} />
                    :
                    <img style={{ height: '100%', width: '100%' }} src={imageUrl || DEFAULT_LISTING_IMAGE_URL} alt={description || "Listing image"} placeholder={DEFAULT_LISTING_IMAGE_URL} />

            }
        </Box>
    );
}
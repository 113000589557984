import React, { PropsWithChildren } from 'react';
import { Button, Container, Divider, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';

import { IssueStateView } from '../../IssueStateView';
import { IShopCartItemProps, ShopCartItem } from '../ShopCartItem';
import { useTranslation } from 'react-i18next';
import { PropsWithErrorState, PropsWithLoadingState } from '../../../utilities';

type Address = {
    line1: string;
    line2?: string;
    city: string;
    zip: string;
    country: string;
    phone?: string;
}

export interface IShopCheckoutProps {
    /** The order id to display to the user */
    orderId: string;
    /** The customer's display name */
    name?: string;
    /** The customer's email */
    email?: string;
    /** The list of items in the cart */
    items: IShopCartItemProps[];
    /** The shipping option name for this order */
    shippingOption: string;
    /** The shipping address for this order */
    shippingAddress: Address;
    /** The billing address for this order, if not provided, will take the shipping address */
    billingAddress?: Address;
    /** The payment method display name */
    paymentMethod: string;
    /** Callback on continue shopping clicked */
    onContinueShopping: () => void;
}

export const ShopCheckoutSuccess = ({
    orderId,
    name,
    email,
    items,
    shippingOption,
    shippingAddress,
    billingAddress,
    paymentMethod,
    onContinueShopping,
    loading,
    error,
}: PropsWithErrorState<PropsWithLoadingState<IShopCheckoutProps>>) => {
    const { t } = useTranslation();

    if (!!error) {
        return (
            <IssueStateView
                title={t('shop.checkout.errorTitle')}
                image=''
                description={t('shop.checkout.errorContent')}
                button={onContinueShopping ? { onActionClick: onContinueShopping, text: t('shop.checkout.errorButton') } : undefined}
            />
        )
    }

    const renderAddress = (address: Address) => {
        return (
            <Stack direction={'column'}>
                <Typography>{address.line1}</Typography>
                {!!address.line2 && <Typography>{address.line2}</Typography>}
                <Typography>{`${address.city}, ${address.zip}`}</Typography>
                <Typography>{address.country}</Typography>
                {!!address.phone && <Typography>{`T:${address.phone}`}</Typography>}
            </Stack>
        )
    };

    return (
        <Grid container spacing={1} padding={2}>
            <Grid item xs={12}>
                <Stack direction={'column'}
                    justifyContent={'start'}
                    alignItems={'stretch'}
                    spacing={2}>
                    {!loading ?
                        <>
                            <Typography variant='h3' textAlign={'center'}>
                                {t('shop.checkout.successTitle')}
                            </Typography>
                            <Divider />
                            {
                                !!name &&
                                <Typography>
                                    {t('shop.checkout.successThanks', { name })}
                                </Typography>
                            }
                            <Typography>
                                {t('shop.checkout.successInfo', { orderId })}
                            </Typography>
                            {
                                !!email &&
                                <Typography>
                                    {t('shop.checkout.successContent', { email })}
                                </Typography>
                            }
                        </>
                        :
                        <Skeleton variant='rectangular' sx={{ width: '100%', height: 150 }} />
                    }
                    <Button onClick={onContinueShopping} variant={'contained'} disabled={!!loading}>
                        {t('shop.checkout.successButton')}
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <ShopCheckoutSuccessSection title={t('shop.checkout.shippingAddressLabel')!} loading={!!loading}>
                    {!loading ? renderAddress(shippingAddress) : <Skeleton height={100} />}
                </ShopCheckoutSuccessSection>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <ShopCheckoutSuccessSection title={t('shop.checkout.billingAddressLabel')!} loading={!!loading}>
                    {!loading ? renderAddress(billingAddress || shippingAddress) : <Skeleton height={100} />}
                </ShopCheckoutSuccessSection>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <ShopCheckoutSuccessSection title={t('shop.checkout.shippingMethodLabel')!} loading={!!loading}>
                    <Typography>{!loading ? shippingOption : <Skeleton />}</Typography>
                </ShopCheckoutSuccessSection>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <ShopCheckoutSuccessSection title={t('shop.checkout.paymentMethodLabel')!} loading={!!loading}>
                    <Typography>{!loading ? paymentMethod : <Skeleton />}</Typography>
                </ShopCheckoutSuccessSection>
            </Grid>
            <Grid item xs={12}>
                <ShopCheckoutSuccessSection title={t('shop.checkout.itemsSummaryLabel')!} loading={!!loading}>
                    {!loading ?
                        items.map((item, index) =>
                            <ShopCartItem key={`item-${index}`} {...item} />
                        )
                        :
                        new Array(3).fill('').map((_, index) => <ShopCartItem loading key={`item-loading-${index}`} />)}
                </ShopCheckoutSuccessSection>
            </Grid>
        </Grid>
    );
}

interface IShopCheckoutSuccessSectionProps {
    title: string
}
const ShopCheckoutSuccessSection = ({
    title,
    children,
    loading,
}: PropsWithChildren<PropsWithLoadingState<IShopCheckoutSuccessSectionProps>>) => {
    return (
        <Paper elevation={1} sx={{ p: 2 }}>
            <Typography>
                {title}
            </Typography>
            <Container sx={{ p: 1 }}>
                {
                    children ||
                    (!loading && <Skeleton variant='rectangular'
                        sx={{ width: '100%', height: 40 }} />)
                }
            </Container>
        </Paper>
    );
}
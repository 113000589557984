import React from 'react';
// import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { ForumsPostContainer } from '../../../containers/forums/ForumsPostContainer';
import { useForumsNavigation } from '../../../hooks/useForumNavigation';
import { ROUTE_PARAMS } from '../../../utilities/navigation';

/** Forum Post page displaying detail of a post */
export const ForumsPostPage = () => {
    const { navigateToTopic, navigateToPost, navigateToArticle } = useForumsNavigation();
    const { [ROUTE_PARAMS.postID]: postId } = useParams();

    return <ForumsPostContainer postId={postId || ''}
        navigateToPost={navigateToPost}
        navigateToTopic={navigateToTopic}
        navigateToArticle={navigateToArticle} />;
}

import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import firebase from "firebase/app";
import { auth, rtdb } from "../../services/firebase";
import { UserContext } from "../UserInformation";
import { removeKeyFromObject } from "../../utilities";
import { DEFAULT_SHOP_ID } from "../../utilities/shop";
import { CartItem, USERS_CART_ROOT } from "../../types/db";

const DEFAULT_MESSAGE_USER_AUTH_NEEDED = "User need to be authenticated to use the cart";

//the context shop cart item (same as CartItem from db, but transformed in camelCase for js convention)
export type ShopCartItem = {
  /** The Product ID from firebase DB */
  productId: string;
  /** The Variant ID from firebase DB */
  variantId: string;
  /** The num of this specific item is in the cart */
  quantity?: number;
}

const getShortCartItemFrom = (cartItem: CartItem): ShopCartItem => ({
  productId: cartItem.product_id,
  variantId: cartItem.variant_id,
  quantity: cartItem.quantity,
})

/** The key is the Shop ID from the firebase DB which the cart relate to */
type ShopCartContent = { [key: string]: ShopCartItem[] }

export interface IShopCartContext {
  /** The information on all carts (usually only one, but could have multiple for different shops) */
  cartContent: ShopCartContent;
  /** Add an item to the cart for the specific shop id */
  addToCart(item: ShopCartItem, shopId: string): Promise<void>;
  /** Update the cart information for a specific item */
  updateQuantityItem({ variantId, quantity }: Pick<ShopCartItem, 'variantId' | 'quantity'>, shopId: string): Promise<void>;
  /** Remove an item from the cart for a specific shop id */
  removeFromCart({ variantId }: Pick<ShopCartItem, 'variantId'>, shopId: string): Promise<void>;
  /** Fully empty a cart from the shop id */
  emptyCart(shopId: string): Promise<void>;
}

export const ShopCartContext = createContext<IShopCartContext | undefined>(undefined);

export const ShopCartProvider: React.FC = ({ children }) => {
  const userContext = useContext(UserContext);
  const userCartRef = useRef<firebase.database.Reference>();
  const [cartContent, setCartContent] = useState<ShopCartContent>({});

  if (!userContext) {
    throw new Error('Need user context');
  }

  //helper function for all onComplete promise db changes
  const onComplete = (
    e: Error | null,
    resolve: (value: void | PromiseLike<void>) => void,
    reject: (reason?: any) => void) => {
    if (!e) {
      resolve();
    } else {
      console.error("Error in ShopCart Context Provider", e)
      reject(e);
    }
  }

  const addToCart = (item: ShopCartItem, shopId: string): Promise<void> => {
    // console.log("ShopCartContext.addToCart", item, shopId);
    return new Promise((resolve, reject) => {
      userCartRef.current?.child(shopId).child(item.variantId)
        .transaction((currentItem: CartItem) => {
          if (currentItem === null) {
            //new item added
            return {
              product_id: item.productId,
              variant_id: item.variantId,
              quantity: item.quantity || 1,
            } as CartItem;
          } else {
            //item already exist in the cart, so just incrementing the quantity of it
            return {
              ...currentItem,
              quantity: currentItem.quantity + 1,
            } as CartItem;
          }
        }, (e) => onComplete(e, resolve, reject))
        ?? reject(DEFAULT_MESSAGE_USER_AUTH_NEEDED);
    });
  }

  const updateQuantityItem = ({ variantId, quantity }: Pick<ShopCartItem, 'variantId' | 'quantity'>, shopId: string): Promise<void> => {
    // console.log("ShopCartContext.updateQuantityItem", { variantId, quantity }, shopId);
    return new Promise((resolve, reject) => {
      if (quantity === 0) {
        //delete the item from the cart
        userCartRef.current?.child(shopId).child(variantId).remove(
          (e) => onComplete(e, resolve, reject))
          ?? reject(DEFAULT_MESSAGE_USER_AUTH_NEEDED);
      } else {
        //update the quantity
        userCartRef.current?.child(shopId).child(variantId).update({
          quantity: quantity === undefined ? 1 : quantity,
        }, (e) => onComplete(e, resolve, reject))
          ?? reject(DEFAULT_MESSAGE_USER_AUTH_NEEDED);
      }
    });
  }

  const removeFromCart = ({ variantId }: Pick<ShopCartItem, 'variantId'>, shopId: string): Promise<void> => {
    // console.log("ShopCartContext.removeFromCart", { variantId }, shopId);
    return new Promise((resolve, reject) => {
      userCartRef.current?.child(shopId).child(variantId).remove(
        (e) => onComplete(e, resolve, reject)
      )
        ?? reject(DEFAULT_MESSAGE_USER_AUTH_NEEDED);
    });
  }

  const emptyCart = (shopId: string): Promise<void> => {
    // console.log("ShopCartContext.emptyCart", shopId);
    return new Promise((resolve, reject) => {
      userCartRef.current?.child(shopId).remove(
        (e) => onComplete(e, resolve, reject)
      )
        ?? reject(DEFAULT_MESSAGE_USER_AUTH_NEEDED);
    });
  }

  useEffect(() => {
    const uid = userContext.user?.firebaseUser?.uid;
    if (!uid) {
      if (!userContext.loading) {
        //if after loading still not logged in, log as anonymous
        console.warn('Not signed in, so sign in anonymously for the shop')
        auth.signInAnonymously();
      }
      console.log('Shop cart without uid, bypassing subscriptions to cart events')
      return;//if no current user, we cannot link a cart to a user
    }

    console.log('Shop cart with uid, subscriptions to cart events', uid)
    userCartRef.current = rtdb.ref(USERS_CART_ROOT).child(uid);

    //subscribe to cart shop added for the current user
    userCartRef.current.on("child_added", (value) => {
      const shopId = value.key!;
      const data = value.val() as { [variantId: string]: CartItem };
      // console.debug("child added", value, data);
      setCartContent(currentCartContent => ({
        ...currentCartContent,
        [shopId]: Object.keys(data).map(key => getShortCartItemFrom(data[key])),
      }));
    });

    //subscribe to cart shop changed for the current user
    userCartRef.current.on("child_changed", (value) => {
      const shopId = value.key!;
      const data = value.val() as { [variantId: string]: CartItem };
      // console.debug("child changed", value);
      setCartContent(currentCartContent => ({
        ...currentCartContent,
        [shopId]: Object.keys(data).map(key => getShortCartItemFrom(data[key])),
      }));
    });

    //subscribe to cart shop removed for the current user
    userCartRef.current.on("child_removed", (value) => {
      const shopId = value.key!;
      // const data = value.val() as { [variantId: string]: CartItem };
      // console.log("child removed", data);
      setCartContent(currentCartContent =>
        removeKeyFromObject(currentCartContent, shopId)
      );
    });

    return () => {
      //unsubscribe to changes
      userCartRef.current?.off();
      //remove current cart info
      setCartContent({});
      //reset the ref
      userCartRef.current = undefined;
    }
  }, [userContext.loading, userContext.user?.firebaseUser?.uid]);

  return (
    <ShopCartContext.Provider value={{
      cartContent,
      addToCart,
      updateQuantityItem,
      removeFromCart,
      emptyCart,
    }}>
      {children}
    </ShopCartContext.Provider>
  );
};

/** 
 * Utility function for the ShopCartContext to extract the specific info for a shopId
 * @param cartContent The cartContent coming directly from the context
 * @param shopId the shopId of the store we want the cart information from
 * @return Return the cart for a specific shopId, null if not found. 
 * @default shopId defaulting to CoHostOp shop if not provided.
 */
export const getCartItemsFromShop = (
  cartContent: ShopCartContent,
  shopId: string = DEFAULT_SHOP_ID,
): ShopCartItem[] => {
  return cartContent[shopId] || [];
}

/** Custom hook to simplify just for one shopId */
export const useUserCart = (shopId: string) => {
  const cartContext = useContext(ShopCartContext);

  if (!cartContext) {
    console.warn('Check if the Provider from ShopCartContext is there');
    return;
  }

  return {
    cartItems: getCartItemsFromShop(cartContext.cartContent, shopId),
    addToCart: (item: ShopCartItem) => cartContext.addToCart(item, shopId),
    updateQuantityItem: (item: Pick<ShopCartItem, "variantId" | "quantity">) =>
      cartContext.updateQuantityItem(item, shopId),
    removeFromCart: (item: Pick<ShopCartItem, "variantId">) =>
      cartContext.removeFromCart(item, shopId),
    emptyCart: () => cartContext.emptyCart(shopId),
  }
}
import React from "react"
import { Divider, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next";

export interface ICalendarDisplayProps {
    minNight?: number;
    maxNight?: number;
}

export const CalendarDisplay = ({ minNight, maxNight }: ICalendarDisplayProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Stack sx={{ border: 1, borderColor: 'grey', borderRadius: 2, p: 2 }}
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={1}>
            <Stack direction={'column'}
                spacing={1}
                justifyContent={'center'}
                alignItems={'center'}>
                <Typography>{t('listing.calendar.minNightTitle')}</Typography>

                <Typography variant="h4" fontWeight={'bold'}>{
                    !!minNight ?
                        minNight
                        :
                        t('listing.calendar.numNightUnset')
                }</Typography>
            </Stack>
            <Divider orientation={smUp ? "vertical" : "horizontal"}
                flexItem />
            <Stack direction={'column'}
                spacing={1}
                justifyContent={'center'}
                alignItems={'center'}>
                <Typography>{t('listing.calendar.maxNightTitle')}</Typography>
                <Typography variant="h4" fontWeight={'bold'}>{
                    !!maxNight ?
                        maxNight
                        :
                        t('listing.calendar.numNightUnset')
                }</Typography>
            </Stack>
        </Stack >
    );
}
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ERole } from '../../../../utilities/EnumUtility';
import { Carousel } from '../../../Carousel';
import { ISubListingItemProps, SubListingItem } from '../../../listing/SubListingItem';
import { DashboardSection, IDashboardSectionProps } from '../DashboardSection/DashboardSection';
import { DashboardSectionEmptyContent } from '../DashboardSectionEmptyContent';
import { getEmptyContentButtonLabelFrom, getEmptyContentMessageFrom, getSectionTitleFrom } from './DashboardSectionListings.utils';

export interface IDashboardSectionListingsProps extends Pick<IDashboardSectionProps, 'onViewAll'> {
    /** The current user role (this will determine the title of the section) */
    userRole: ERole;
    /** The list of listings */
    items: ISubListingItemProps[];
    /** (optional) Callback for empty state action */
    onEmptyViewClick?: () => void;
}

export const DashboardSectionListings = ({
    userRole,
    items,
    onEmptyViewClick,
    onViewAll
}: IDashboardSectionListingsProps) => {
    const { t } = useTranslation()
    return (
        <DashboardSection title={getSectionTitleFrom(t, userRole)} onViewAll={items.length ? onViewAll : undefined}>
            {
                !items.length ?
                    <DashboardSectionEmptyContent message={getEmptyContentMessageFrom(t, userRole)}
                        button={onEmptyViewClick && { onClick: onEmptyViewClick, label: getEmptyContentButtonLabelFrom(t, userRole) }} />
                    :
                    <Carousel items={items.map(item =>
                        <SubListingItem {...item} />
                    )} />
            }
        </DashboardSection>
    )
}
import React, { useCallback, useState, useEffect } from "react";
import { Stack, Typography, TypographyProps } from "@mui/material";
import { addDays, eachDayOfInterval, format, isAfter, isBefore, isSameDay, isValid } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";

export interface IDateRangePicker {
    /** The preselected start date */
    startDate?: Date;
    /** The preselected end date */
    endDate?: Date;
    /** List of unavailable Dates */
    unavailableDates?: Date[];
    /** The min date which can be selected, default to today's date */
    minDate?: Date;
    /** The max date user can select (useful if listing has a max "advance" booking) */
    maxDate?: Date;
    /** The max number of night user can select (useful if listing has a "max number of night" booking) */
    maxNights?: number;
    /** Callback when range is selected (only called when full range selected without error) */
    onDateRangeSelected: (start: Date, end: Date) => void;
}

export const DateRangePicker = ({
    startDate: startDateProp,
    endDate: endDateProp,
    unavailableDates, //= [new Date(2023, 8, 18), new Date(2023, 8, 22)],//specified for test
    minDate = new Date(Date.now()),
    maxDate,
    maxNights,
    onDateRangeSelected
}: IDateRangePicker) => {
    const [startDate, setStartDate] = useState<Date | null>(startDateProp || null);
    const [endDate, setEndDate] = useState<Date | null>(endDateProp || null);

    const handleStartDateChange = (date: Date | null) => {
        setStartDate(date);
        if (date && endDate && isAfter(date, endDate)) {
            // Ensure start date is before or equal to end date
            setEndDate(null);
        }
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
        if (date && startDate && isBefore(date, startDate)) {
            // Ensure end date is after or equal to start date
            setStartDate(null);
        }
    };

    // Custom function to check if a date is unavailable
    const isDateUnavailable = useCallback((date: Date) => {
        return !!unavailableDates?.some((unavailableDate) => isSameDay(date, unavailableDate));
    }, [unavailableDates]);

    // Custom function to disable specific dates
    const shouldDisableDate = (date: Date) => {
        return isBefore(date, minDate)
            || (!!maxDate && isAfter(date, maxDate))
            || (!!maxNights && !!startDate && isAfter(date, addDays(startDate, maxNights)))
            || isDateUnavailable(date);
    };

    // Custom function to check if the selected range contains unavailable dates
    const isRangeValid = useCallback((start: Date | null, end: Date | null) => {
        if (!!start && !!end && isValid(start) && isValid(end)) {
            const daysInRange = eachDayOfInterval(isBefore(end, start) ? { start: end, end: start } : { start, end });
            return !daysInRange.some((date) => isDateUnavailable(date));
        }
        return true;
    }, [isDateUnavailable]);

    useEffect(() => {
        if (!!startDate && isValid(startDate)
            && !!endDate && isValid(endDate)
            && isRangeValid(startDate, endDate)
            && !(startDateProp && isSameDay(startDate, startDateProp)
                && endDateProp && isSameDay(endDate, endDateProp))) {
            onDateRangeSelected?.(startDate, endDate);
        }
    }, [endDate, endDateProp, isRangeValid, onDateRangeSelected, startDate, startDateProp])

    const disableStartDate = (date: Date) => {
        return shouldDisableDate(date)
            || (!!endDate && isSameDay(date, endDate)) //cannot select same date (check-in cannot be same day as check-out)
            || !isRangeValid(date, endDate); // Disable the start date if the selected range contains unavailable dates
    };

    const disableEndDate = (date: Date) => {
        return shouldDisableDate(date)
            || (!!startDate && isSameDay(date, startDate)) //cannot select same date (check-in cannot be same day as check-out)
            || !isRangeValid(startDate, date); // Disable the end date if the selected range contains unavailable dates
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'center'} spacing={2}>
                <DatePicker
                    label={"Check-in"}
                    value={startDate}
                    onChange={handleStartDateChange}
                    minDate={minDate}
                    maxDate={endDate || maxDate}
                    shouldDisableDate={disableStartDate}
                />
                <DatePicker
                    label={"Check-out"}
                    value={endDate}
                    onChange={handleEndDateChange}
                    minDate={startDate || minDate}
                    maxDate={maxDate}
                    shouldDisableDate={disableEndDate}
                />
            </Stack>
        </LocalizationProvider >
    );
};

interface IDateRangeDisplayProps extends Pick<IDateRangePicker, 'startDate' | 'endDate'> {
    /** The style of the text */
    style?: TypographyProps['sx'];
}
/** The display (non-editable) of date range */
export const DateRangeDisplay = ({ startDate, endDate, style }: IDateRangeDisplayProps) => {
    const { t } = useTranslation();
    return <Typography sx={style}>{
        !!startDate && !!endDate ?
            t('listings.filters.datesSummary', { start: format(startDate, 'yyyy-MM-dd'), end: format(endDate, 'yyyy-MM-dd') })
            :
            t('listings.filters.datesSummaryNone')
    }</Typography>;

}
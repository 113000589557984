import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { IssueStateView } from '../../../components/IssueStateView';
import { ProfileDetails } from '../../../components/profile/ProfileDetails';
import { UserContext } from '../../../context/UserInformation';
import { EditInformation } from '../../../dialog/EditProfileDialog/EditProfileDialog';
import { addPictureToUser } from '../../../services/storage';
import { getUserInfo, updateUserInfo } from '../../../services/user';
import { getUIMedalTypeFrom, getUIVerificationFrom } from '../../../utilities/EnumUtility';
import { ProfileListingsContainer } from '../ProfileListingsContainer';
import { ProfileReviewsContainer } from '../ProfileReviewsContainer';

export interface IProfileDetailsContainerProps {
    userId: string;
    onEditClick: (opening: boolean) => void;
    isInEditMode: boolean;
    onAddAccountVerificationClick: () => void;
}

export const ProfileDetailsContainer = ({
    userId,
    onEditClick,
    isInEditMode,
    onAddAccountVerificationClick,
}: IProfileDetailsContainerProps) => {
    const userContext = useContext(UserContext);
    const currentUserId = userContext?.user?.firebaseUser?.uid;
    const isEditable = !!currentUserId && userId === currentUserId;
    const { isLoading, isError, data, refetch } = useQuery(['ProfileDetails', userId], () => getUserInfo(userId));

    if (!userContext) {
        throw new Error("Have to be used within a UserProvider");
    }

    if (isLoading) {
        return <ProfileDetails loading />
    }

    if (isError || !data) {
        return <ProfileDetails error />
    }

    if (isInEditMode && !isEditable) {
        return <IssueStateView title='Profile'
            description='You do not have the permission to edit this profile. Please log into your account to edit your profile.'
            button={{
                text: 'Only display profile',
                onActionClick: () => onEditClick(false)
            }} />
    }

    const handleEditClick = (info?: EditInformation) => {
        isEditable && onEditClick?.(!isInEditMode);
        if (!!info) {
            updateUserInfo(userId,
                {
                    display_name: info.displayName,
                    avatar_url: info.avatarUrl,
                    description: info.description,
                })
                .then(() => refetch());
        }
    }

    const handleAvatarEdit = (file: File) => {
        return new Promise<string>((resolve, reject) => {
            addPictureToUser(
                currentUserId!,
                file,
                (_, final, error) => {
                    if (!!error) {
                        reject(error);
                    } else if (!!final) {
                        resolve(final.url);
                    }
                });
        });
    }

    return <ProfileDetails
        summary={{
            displayName: data.display_name,
            avatarImgSrc: data.avatar_url,
            verifications: {
                items: data.verifications?.map(getUIVerificationFrom) ?? [],
                isProfileVerified: data.is_verified,
                onAddVerificationClick: isEditable && !data.is_verified ? onAddAccountVerificationClick : undefined
            },
            medals: !data.medal ? undefined
                :
                {
                    items: Array.isArray(data.medal) ?
                        data.medal.map(getUIMedalTypeFrom)
                        : [getUIMedalTypeFrom(data.medal)]
                }
        }}
        about={{
            displayName: data.display_name,
            description: data.description || '',
            joinDate: new Date(data.join_date),
            from: data.from || 'Earth',
            languages: data.languages || []
        }}
        renderListings={() => <ProfileListingsContainer userId={userId} userDisplayName={data.display_name} />}
        renderReviews={() => <ProfileReviewsContainer userId={userId} userInfo={data} />}
        isInEditMode={isInEditMode && isEditable}
        //only alow the edit function if it's editable
        onEditClick={isEditable ? handleEditClick : undefined}
        onAvatarChanged={isEditable ? handleAvatarEdit : undefined}
        //only reportable if not self profile
        onReportClick={!isEditable ? () => alert('User reported') : undefined}
    />
}
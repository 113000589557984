import { Stack, Typography } from '@mui/material';
import React from 'react';
import { currencyFormatter } from '../../../utilities/currency/function';

type Price = {
    /** The price amount */
    amount: number;
    /** The 3 letter currency of that price */
    currency: string;
}

export interface IShopPriceProps {
    /** The price currently/original */
    originalPrice: Price;
    /** 
     * (Optional) The discounted price if needed (will show the original price with strike). 
     * Same currency as original price
     */
    discountedPrice?: Pick<Price, 'amount'>;
}

export const ShopPrice = ({ originalPrice, discountedPrice }: IShopPriceProps) => {
    if (!!discountedPrice) {
        return (
            <Stack direction={'row'}>
                <Typography>{currencyFormatter({ quantity: discountedPrice.amount, currency: originalPrice.currency })}</Typography>
                <Typography sx={{ textDecoration: "line-through" }}>{currencyFormatter({ quantity: originalPrice.amount, currency: originalPrice.currency })}</Typography>
            </Stack>
        )
    }

    return <Typography>{currencyFormatter({ quantity: originalPrice.amount, currency: originalPrice.currency })}</Typography>
}
import React from "react";
import { Box, CircularProgress, Container } from "@mui/material";
import { LOGO_BLACK } from "../../utilities/assets/assetHelper";

export const SubdomainLoadingPage = () => {
    return <Container>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
            <img
                style={{
                    objectFit: 'contain',
                    maxHeight: '25%', maxWidth: '25%'
                }}
                src={LOGO_BLACK}
                alt="CoHostOp logo"
            />
            <CircularProgress />
        </Box>
    </Container>;
}
import { LOGO_COLOR_URL } from "../../../utilities/assets/assetHelper";

/** The platforms we can fetch listings from */
export enum ESupportedPlatform {
    COHOSTOP = 'CoHostOp',
    BOOKING_COM = "Booking.com",
    HOTEL_COM = "Hotel.com",
    AIRBNB = "Airbnb"
}

export const getPlatformLogo = (platform: ESupportedPlatform) => {
    switch (platform) {
        case ESupportedPlatform.COHOSTOP:
            return LOGO_COLOR_URL;
        case ESupportedPlatform.BOOKING_COM:
            return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYr6JfnwmiNSd-q4biy50qkyH26i-3WppOkw&s';
        case ESupportedPlatform.HOTEL_COM:
            return 'https://cdn6.aptoide.com/imgs/d/9/f/d9fa1f8d333c68e54e699bf8251d5618_icon.png';
        case ESupportedPlatform.AIRBNB:
            return 'https://cdn.icon-icons.com/icons2/2699/PNG/512/airbnb_tile_logo_icon_168680.png';
        default: return ''
    }
}

export const getPlatformDisplayName = (platform: ESupportedPlatform) => {
    switch (platform) {
        case ESupportedPlatform.COHOSTOP:
            return 'CoHostOp';
        case ESupportedPlatform.BOOKING_COM:
            return 'Booking.com';
        case ESupportedPlatform.HOTEL_COM:
            return 'Hotel.com';
        case ESupportedPlatform.AIRBNB:
            return 'Airbnb';
        default: return '';
    }
}
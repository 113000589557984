import React, { useContext } from 'react';
import { useRemoteConfig } from '../../../services/remoteConfig';
import { RC_SHOP } from '../../../types/remote_config';

import { ChoAppBar } from "../ChoAppBar";
import { SimpleAppBar } from '../SimpleAppBar/SimpleAppBar';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context/UserInformation';
import { useNavigate } from 'react-router';
import { ROUTE_PATH, buildPath } from '../../../utilities/navigation';

export const ShopAppBar = () => {
    const { t } = useTranslation()
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error('need UserContext');
    const navigate = useNavigate();
    const {
        [RC_SHOP]: remoteConfigShop,
    } = useRemoteConfig(RC_SHOP);


    const userMenuItems = [
        userContext.isUserLoggedIn() && !userContext.isUserAnonymous() ? {
            label: t('user.menu.logout'),
            action: () => {
                userContext?.logOut();
                navigate(ROUTE_PATH.home);
            },
        } : {
            label: t('home.menu.join'),
            action: () => {
                navigate(buildPath(ROUTE_PATH.authBuild(ROUTE_PATH.auth_link)));
            },
        },
    ]

    return !!remoteConfigShop.enabled ?
        <ChoAppBar mainMenuItems={[]} userMenuItems={userMenuItems} />
        : <SimpleAppBar />
}
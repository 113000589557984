import { Alert, Button, Divider, Slide, Snackbar, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Banner } from '../../../components/Banner';
import { analytics } from '../../../services/firebase';
import { isEmailValid } from '../../../utilities';
import { BANNER_UNIVERSITY } from '../../../utilities/assets/assetHelper';
import { ROUTE_PATH, buildPath } from '../../../utilities/navigation';

type Section = { title: string, content: string }

const renderSection = ({ title, content }: Section) => {
    return (
        <Stack direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ p: 3 }}>
            <Typography variant="h4" textAlign={'center'}>{title}</Typography>
            <Typography variant="body1" textAlign={'center'}>{content}</Typography>
        </Stack>
    );
}

/** Career page for internship and entry level job */
export const CareerUniversityPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Stack direction={'column'} spacing={1}>
            <Banner {...{
                title: t('careers.university.bannerTitle'),
                backgroundImageSrc: BANNER_UNIVERSITY,
                subTitle: t('careers.university.bannerContent'),
                primaryButton: {
                    text: t('careers.university.bannerBtn'),
                    onClick: () => navigate(buildPath(ROUTE_PATH.careers.positions, true, [{ query: 'level', value: 'entry' }])),
                }
            }} />
            <Stack direction={'column'} spacing={2}>
                {renderSection({
                    title: t('careers.university.internTitle'),
                    content: t('careers.university.internContent'),
                })}
                <Divider />
                {renderSection({
                    title: t('careers.university.newGraduateTitle'),
                    content: t('careers.university.newGraduateContent'),
                })}
                <Divider />
                <UniversityApplication />
            </Stack>
            <Stack direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                padding={5}
                sx={{ backgroundColor: "#00FF4222" }}>
                <Typography textAlign={'center'}>{t('careers.learnMore.title')}</Typography>
                <Typography textAlign={'center'}>{t('careers.learnMore.content')}</Typography>
                <Button variant="contained" onClick={() => navigate("/faq")}>{t('careers.learnMore.actionBtn')}</Button>
            </Stack>
        </Stack>
    );
}

const UniversityApplication = () => {
    const { t } = useTranslation();
    const [contactField, setContactField] =
        useState<Pick<TextFieldProps, 'value' | 'error' | 'helperText'>>({ value: '' });
    const [linkField, setLinkField] =
        useState<Pick<TextFieldProps, 'value' | 'error' | 'helperText'>>({ value: '' });

    const [open, setOpen] = useState(false);

    const handleSendQuestion = () => {
        const email = contactField.value as string;
        const link = linkField.value as string;

        if (!email) {
            return setContactField(c => ({ ...c, error: true, helperText: t('error.requiredFieldEmpty') }))
        }

        if (!link) {
            setLinkField(l => ({ ...l, error: true, helperText: t('error.requiredFieldEmpty') }));
        }

        if (!isEmailValid(email)) {
            return setContactField(c => ({ ...c, error: true, helperText: t('error.invalidEmail') }))
        }

        //send somewhere...
        //ignore questions!! :P
        analytics.logEvent("generate_lead", { page: "career-university", email, link })
        //clear inputs
        setContactField({ value: '' });
        setLinkField({ value: '' });

        return setOpen(true);
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <Stack direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                spacing={1}
                sx={{ p: 1 }}>
                <Typography variant='h5' textAlign={'center'}>{t('careers.application.universityTitle')}</Typography>
                <Typography variant='body1' textAlign={'center'}>{t('careers.application.content')}</Typography>
                <TextField label={t('careers.application.contactInputLabel')}
                    placeholder={t('careers.application.contactInputPlaceholder')}
                    type={'email'}
                    variant={'outlined'}
                    fullWidth
                    onChange={(e) => setContactField({ value: e.target.value })}
                    {...contactField} />
                <TextField label={t('careers.application.linkInputLabel')}
                    placeholder={t('careers.application.linkInputPlaceholder')}
                    type={'link'}
                    variant={'outlined'}
                    fullWidth
                    onChange={(e) => setLinkField({ value: e.target.value })}
                    {...linkField} />
                <Button variant='contained' onClick={handleSendQuestion}>{t('careers.application.sendBtn')}</Button>
            </Stack>
            <Snackbar open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                TransitionComponent={(props) => <Slide {...props} direction="up" />}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {t('careers.application.successSendMessage')}
                </Alert>
            </Snackbar>
        </>
    );
}
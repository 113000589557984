import React from "react";
import { TextFieldProps, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MIN_NIGHT } from "../../../utilities/listings";

// export type FieldName = 'minNight' | 'maxNight';
export type FieldName = keyof ICalendarInputProps;

export interface ICalendarInputProps {
    minNight: Pick<TextFieldProps, 'value' | 'helperText' | 'error' | 'onChange' | 'onBlur'>;
    maxNight: Pick<TextFieldProps, 'value' | 'helperText' | 'error' | 'onChange' | 'onBlur'>;
}

/** The Calendar input info for min and max night of listing */
export const CalendarInput = ({ minNight: minNightProps, maxNight: maxNightProps }: ICalendarInputProps) => {
    const { t } = useTranslation();
    return (
        <Stack direction={'column'} spacing={2}>
            <TextField value={''}
                {...minNightProps}
                label={t('listing.calendar.minNightLabel')}
                placeholder={t('listing.calendar.minNightPlaceholder')}
                inputProps={{
                    min: MIN_NIGHT,
                }}
                type={'number'}
            />
            <TextField value={''}
                {...maxNightProps}
                label={t('listing.calendar.maxNightLabel')}
                placeholder={t('listing.calendar.maxNightPlaceholder')}
                inputProps={{
                    min: MIN_NIGHT,
                }}
                type={'number'}
            />
        </Stack>
    )
}
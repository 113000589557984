import React, { PropsWithChildren } from "react";
import { Navigate, Outlet } from "react-router";
import { ROUTE_PATH } from "./RouterConfig";

interface IProtectedRouteProps {
    /** Boolean indicating the statement of who is allowed to access this route */
    isAllowed: boolean;
    /** The redirection path if the `isAllowed` is false @default '/' (home) */
    redirectPath?: string;
}

/**
 * Utils which allow to add a protected route
 * @example
    Protection of a route and sub route:
    ```
    <Route element={<ProtectedRoute isAllowed={!!user} />}>
          <Route path="home" element={<Home />} />
          <Route path="dashboard" element={<Dashboard />} />
        </Route>
    ```
    Protection of a specific route for user with permission:
    ```
    <Route
        path="analytics"
        element={
        <ProtectedRoute
            redirectPath="/home"
            isAllowed={
            !!user && user.permissions.includes('analyze')
            }
        >
            <Analytics />
        </ProtectedRoute>
        }
    />
    ```
    Protection of a specific route for user with roles:
    ```
    <Route
        path="admin"
        element={
        <ProtectedRoute
            redirectPath="/home"
            isAllowed={!!user && user.roles.includes('admin')}
        >
            <Admin />
        </ProtectedRoute>
        }
    />
    ```
 * @param props IProtectedRouteProps
 * @returns the route under protection
 */
export const ProtectedRoute = ({
    isAllowed,
    redirectPath = ROUTE_PATH.home,
    children,
}: PropsWithChildren<IProtectedRouteProps>) => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? <>{children}</> : <Outlet />;
};
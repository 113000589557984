import React from "react";
import { ERole } from "../../../../utilities/EnumUtility";
import { DashboardSection } from "../DashboardSection/DashboardSection";
import { DashboardSectionEmptyContent } from "../DashboardSectionEmptyContent";
import { getEmptyContentButtonLabelFrom, getEmptyContentMessageFrom, getSectionTitleFrom } from "./DashboardSectionReservations.utils";
import { IReservationItemProps, ReservationItem } from "../../../ReservationItem";
import { Carousel } from "../../../Carousel";
import { useTranslation } from "react-i18next";

export interface IDashboardSectionReservationsProps {
    /** The current user role (with determine the title of the section) */
    userRole: ERole;
    /** The item list of reservation */
    items: IReservationItemProps[];
    /** (optional) Callback for empty state action */
    onEmptyViewClick?: () => void;
}

export const DashboardSectionReservations = ({
    userRole,
    items,
    onEmptyViewClick,
}: IDashboardSectionReservationsProps) => {
    const { t } = useTranslation();
    if (!userRole) {
        return null;
    }
    return (
        <DashboardSection title={getSectionTitleFrom(t, userRole)}>
            {
                !items.length ?
                    <DashboardSectionEmptyContent
                        message={getEmptyContentMessageFrom(t, userRole)}
                        button={onEmptyViewClick && { label: getEmptyContentButtonLabelFrom(t, userRole), onClick: onEmptyViewClick }} />
                    :
                    <Carousel items={items.map(item =>
                        <ReservationItem {...item} />
                    )} />
            }
        </DashboardSection>
    )
}
import { Avatar, Badge, Card, CardActionArea, CardContent, CardHeader, CardMedia, IconButton, Stack, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { CurrencyView, ICurrencyViewProps } from '../../CurrencyView';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

export interface IListingItemProps {
  /** The title of the listing */
  title: string;
  /** The short description of the listing */
  shortDescription?: string;
  /** The price per night of the listing */
  price: ICurrencyViewProps;
  /** The rating of the listing */
  rating: {
    /** The rate out of 5 stars */
    rate: number;
    /** The number of reviews which make this rating */
    numberReview: number;
  }
  /** The image to show in the item, can be an array if more than one */
  images?: string | string[];
  /** 
   * Boolean indicating if the listing is in the favorite of the user 
   * @default false
  */
  isFavorite?: boolean;
  /**
   * Callback click on favorite button
   */
  onFavoriteClick?: () => void;
  /**
   * Click handler
   */
  onClick: () => void;
  /** The avatar of the host */
  hostAvatar: string;
  /** The avatar of the cohost or icon url of the provider if other than CoHostOp listing */
  cohostAvatar?: string;
}

/**
 * The item for the listing in the research page
 */
export const ListingItem: React.FC<IListingItemProps> = ({
  title,
  shortDescription,
  price,
  rating,
  images,
  isFavorite = false,
  onFavoriteClick,
  onClick,
  hostAvatar,
  cohostAvatar,
}) => {
  const [isFav, setIsFav] = useState(isFavorite);
  const renderImages = () => {
    if (!images || images.length === 0) {
      return null;
    }
    if (typeof images === 'string') {
      return <CardMedia
        component="img"
        image={images}
        alt={title}
        sx={{ aspectRatio: 3 / 2 }}
      />
    }
    return <CardMedia
      component="img"
      image={images[0]}
      alt={title}
      sx={{ aspectRatio: 3 / 2 }}
    />
  }
  const onFavClick = () => {
    setIsFav(!isFav);
    onFavoriteClick?.();
  }
  return (
    <Card>
      <CardHeader title={title}
        titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
        action={!!onFavoriteClick ?
          <IconButton onClick={onFavClick}
            aria-label={isFav ? "remove from favorite" : "add to favorites"}>
            {isFav ?
              <FavoriteIcon />
              :
              <FavoriteBorderIcon />
            }
          </IconButton>
          : null}
        avatar={
          !!cohostAvatar ?
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <SmallAvatar alt="CoHost" src={cohostAvatar} />
              }
            >
              <Avatar alt="Host" src={hostAvatar} variant={'rounded'} />
            </Badge>
            :
            <Avatar src={hostAvatar} variant={'rounded'} />
        }
      />
      <CardActionArea onClick={onClick}>
        {renderImages()}
        <CardContent>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
            <Typography>{shortDescription || ''}</Typography>
            {/* TODO rating view */}
            <Typography variant="body2" color="text.secondary">
              {`${rating.rate} (${rating.numberReview})`}
            </Typography>
          </Stack>
          <CurrencyView {...price} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

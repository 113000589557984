import { Country } from './Address.utils';

export const COUNTRIES_INFO: { [key: string]: Country } = {
  AF: {
    code: 'AF',
    continent: 'AS',
    phoneNumberPrefix: 93,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  AX: {
    code: 'AX',
    continent: 'EU',
    phoneNumberPrefix: 358,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  AL: {
    code: 'AL',
    continent: 'EU',
    phoneNumberPrefix: 355,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  DZ: {
    code: 'DZ',
    continent: 'AF',
    phoneNumberPrefix: 213,
    autocompletionField: 'address1',
    zoneKey: 'PROVINCE',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  AD: {
    code: 'AD',
    continent: 'EU',
    phoneNumberPrefix: 376,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  AO: {
    code: 'AO',
    continent: 'AF',
    phoneNumberPrefix: 244,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  AI: {
    code: 'AI',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  AG: {
    code: 'AG',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  AR: {
    code: 'AR',
    continent: 'SA',
    phoneNumberPrefix: 54,
    autocompletionField: 'address1',
    zoneKey: 'PROVINCE',
    zones: [
      'B',
      'K',
      'H',
      'U',
      'C',
      'X',
      'W',
      'E',
      'P',
      'Y',
      'L',
      'F',
      'M',
      'N',
      'Q',
      'R',
      'A',
      'J',
      'D',
      'Z',
      'S',
      'G',
      'V',
      'T',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city} {zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['zone', 'country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  AM: {
    code: 'AM',
    continent: 'EU',
    phoneNumberPrefix: 374,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  AW: {
    code: 'AW',
    continent: 'CA',
    phoneNumberPrefix: 297,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  //commenting it as Ascension Island is part of SH (Saint-Helene, Ascension et Tristan da Cunha, Britannic territory )
  // AC: {
  //   code: 'AC',
  //   continent: 'AF',
  //   phoneNumberPrefix: 247,
  //   autocompletionField: 'address1',
  //   zoneKey: 'REGION',
  //   zones: [],
  //   formatting:
  //     '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
  //   layout: [
  //     ['firstName', 'lastName'],
  //     ['company'],
  //     ['address1'],
  //     ['address2'],
  //     ['city'],
  //     ['country', 'zip'],
  //     ['phone'],
  //   ],
  //   optionalFields: ['address2'],
  //   labelKeys: { zip: 'zip.postalcode' },
  // },
  AU: {
    code: 'AU',
    continent: 'OC',
    phoneNumberPrefix: 61,
    autocompletionField: 'address1',
    zoneKey: 'STATE_AND_TERRITORY',
    zones: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zone} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { city: 'city.suburb', zip: 'zip.postcode' },
  },
  AT: {
    code: 'AT',
    continent: 'EU',
    phoneNumberPrefix: 43,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: {
      address1: 'address1.partial',
      address2: 'address2.addon',
      zip: 'zip.postalcode',
    },
  },
  AZ: {
    code: 'AZ',
    continent: 'AS',
    phoneNumberPrefix: 994,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BS: {
    code: 'BS',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BH: {
    code: 'BH',
    continent: 'AS',
    phoneNumberPrefix: 973,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BD: {
    code: 'BD',
    continent: 'AS',
    phoneNumberPrefix: 880,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BB: {
    code: 'BB',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BY: {
    code: 'BY',
    continent: 'EU',
    phoneNumberPrefix: 375,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BE: {
    code: 'BE',
    continent: 'EU',
    phoneNumberPrefix: 32,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BZ: {
    code: 'BZ',
    continent: 'CA',
    phoneNumberPrefix: 501,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BJ: {
    code: 'BJ',
    continent: 'AF',
    phoneNumberPrefix: 229,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BM: {
    code: 'BM',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BT: {
    code: 'BT',
    continent: 'AS',
    phoneNumberPrefix: 975,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BO: {
    code: 'BO',
    continent: 'SA',
    phoneNumberPrefix: 591,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BA: {
    code: 'BA',
    continent: 'EU',
    phoneNumberPrefix: 387,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BW: {
    code: 'BW',
    continent: 'AF',
    phoneNumberPrefix: 267,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BV: {
    code: 'BV',
    continent: 'EU',
    phoneNumberPrefix: 55,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BR: {
    code: 'BR',
    continent: 'SA',
    phoneNumberPrefix: 55,
    autocompletionField: 'address1',
    zoneKey: 'STATE',
    zones: [
      'AC',
      'AL',
      'AP',
      'AM',
      'BA',
      'CE',
      'DF',
      'ES',
      'GO',
      'MA',
      'MT',
      'MS',
      'MG',
      'PA',
      'PB',
      'PR',
      'PE',
      'PI',
      'RN',
      'RS',
      'RJ',
      'RO',
      'RR',
      'SC',
      'SP',
      'SE',
      'TO',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zone}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { address1: 'address1.partial', zip: 'zip.postalcode' },
  },
  IO: {
    code: 'IO',
    continent: 'AS',
    phoneNumberPrefix: 246,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  VG: {
    code: 'VG',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BN: {
    code: 'BN',
    continent: 'AS',
    phoneNumberPrefix: 673,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BG: {
    code: 'BG',
    continent: 'EU',
    phoneNumberPrefix: 359,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BF: {
    code: 'BF',
    continent: 'AF',
    phoneNumberPrefix: 226,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BI: {
    code: 'BI',
    continent: 'AF',
    phoneNumberPrefix: 257,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  KH: {
    code: 'KH',
    continent: 'AS',
    phoneNumberPrefix: 855,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CM: {
    code: 'CM',
    continent: 'AF',
    phoneNumberPrefix: 237,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CA: {
    code: 'CA',
    continent: 'NA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'PROVINCE',
    zones: [
      'AB',
      'BC',
      'MB',
      'NB',
      'NL',
      'NT',
      'NS',
      'NU',
      'ON',
      'PE',
      'QC',
      'SK',
      'YT',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zone} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CV: {
    code: 'CV',
    continent: 'AF',
    phoneNumberPrefix: 238,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BQ: {
    code: 'BQ',
    continent: 'SA',
    phoneNumberPrefix: 599,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  KY: {
    code: 'KY',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CF: {
    code: 'CF',
    continent: 'AF',
    phoneNumberPrefix: 236,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  TD: {
    code: 'TD',
    continent: 'AF',
    phoneNumberPrefix: 235,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CL: {
    code: 'CL',
    continent: 'SA',
    phoneNumberPrefix: 56,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [
      'AP',
      'TA',
      'AN',
      'AT',
      'CO',
      'VS',
      'RM',
      'LI',
      'ML',
      'NB',
      'BI',
      'AR',
      'LR',
      'LL',
      'AI',
      'MA',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['zone'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CN: {
    code: 'CN',
    continent: 'AS',
    phoneNumberPrefix: 86,
    autocompletionField: 'address1',
    zoneKey: 'PROVINCE',
    zones: [
      'AH',
      'BJ',
      'CQ',
      'FJ',
      'GS',
      'GD',
      'GX',
      'GZ',
      'HI',
      'HE',
      'HL',
      'HA',
      'HB',
      'HN',
      'NM',
      'JS',
      'JX',
      'JL',
      'LN',
      'NX',
      'QH',
      'SN',
      'SD',
      'SH',
      'SX',
      'SC',
      'TJ',
      'XJ',
      'YZ',
      'YN',
      'ZJ',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1} {address2} {city}\n{zip} {zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { address1: 'address1.full', zip: 'zip.postalcode' },
  },
  CX: {
    code: 'CX',
    continent: 'AS',
    phoneNumberPrefix: 61,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CC: {
    code: 'CC',
    continent: 'AS',
    phoneNumberPrefix: 891,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CO: {
    code: 'CO',
    continent: 'SA',
    phoneNumberPrefix: 57,
    autocompletionField: 'address1',
    zoneKey: 'PROVINCE',
    zones: [
      'DC',
      'AMA',
      'ANT',
      'ARA',
      'ATL',
      'BOL',
      'BOY',
      'CAL',
      'CAQ',
      'CAS',
      'CAU',
      'CES',
      'CHO',
      'COR',
      'CUN',
      'GUA',
      'GUV',
      'HUI',
      'LAG',
      'MAG',
      'MET',
      'NAR',
      'NSA',
      'PUT',
      'QUI',
      'RIS',
      'SAP',
      'SAN',
      'SUC',
      'TOL',
      'VAC',
      'VAU',
      'VID',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city} {zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  KM: {
    code: 'KM',
    continent: 'AF',
    phoneNumberPrefix: 269,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CG: {
    code: 'CG',
    continent: 'AF',
    phoneNumberPrefix: 243,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CD: {
    code: 'CD',
    continent: 'AF',
    phoneNumberPrefix: 243,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CK: {
    code: 'CK',
    continent: 'OC',
    phoneNumberPrefix: 682,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CR: {
    code: 'CR',
    continent: 'CA',
    phoneNumberPrefix: 506,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  HR: {
    code: 'HR',
    continent: 'EU',
    phoneNumberPrefix: 385,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CW: {
    code: 'CW',
    continent: 'CA',
    phoneNumberPrefix: 599,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CY: {
    code: 'CY',
    continent: 'EU',
    phoneNumberPrefix: 357,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CZ: {
    code: 'CZ',
    continent: 'EU',
    phoneNumberPrefix: 420,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  CI: {
    code: 'CI',
    continent: 'AF',
    phoneNumberPrefix: 225,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  DK: {
    code: 'DK',
    continent: 'EU',
    phoneNumberPrefix: 45,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { address1: 'address1.partial', zip: 'zip.postalcode' },
  },
  DJ: {
    code: 'DJ',
    continent: 'AF',
    phoneNumberPrefix: 253,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  DM: {
    code: 'DM',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  DO: {
    code: 'DO',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  EC: {
    code: 'EC',
    continent: 'SA',
    phoneNumberPrefix: 593,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  EG: {
    code: 'EG',
    continent: 'AF',
    phoneNumberPrefix: 20,
    autocompletionField: 'address1',
    zoneKey: 'GOVERNORATE',
    zones: [
      'SU',
      'SHR',
      'ALX',
      'ASN',
      'AST',
      'BH',
      'BNS',
      'C',
      'DK',
      'DT',
      'FYM',
      'GH',
      'GZ',
      'HU',
      'IS',
      'KFS',
      'LX',
      'MT',
      'MN',
      'MNF',
      'WAD',
      'SIN',
      'PTS',
      'KB',
      'KN',
      'BA',
      'SHG',
      'JS',
      'SUZ',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zone}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SV: {
    code: 'SV',
    continent: 'CA',
    phoneNumberPrefix: 503,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GQ: {
    code: 'GQ',
    continent: 'AF',
    phoneNumberPrefix: 240,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  ER: {
    code: 'ER',
    continent: 'AF',
    phoneNumberPrefix: 291,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  EE: {
    code: 'EE',
    continent: 'EU',
    phoneNumberPrefix: 372,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SZ: {
    code: 'SZ',
    continent: 'AF',
    phoneNumberPrefix: 268,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  ET: {
    code: 'ET',
    continent: 'AF',
    phoneNumberPrefix: 251,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  FK: {
    code: 'FK',
    continent: 'SA',
    phoneNumberPrefix: 500,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  FO: {
    code: 'FO',
    continent: 'EU',
    phoneNumberPrefix: 298,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  FJ: {
    code: 'FJ',
    continent: 'OC',
    phoneNumberPrefix: 679,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  FI: {
    code: 'FI',
    continent: 'EU',
    phoneNumberPrefix: 358,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  FR: {
    code: 'FR',
    continent: 'EU',
    phoneNumberPrefix: 33,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city', 'country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GF: {
    code: 'GF',
    continent: 'SA',
    phoneNumberPrefix: 594,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  PF: {
    code: 'PF',
    continent: 'OC',
    phoneNumberPrefix: 689,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  TF: {
    code: 'TF',
    continent: 'O',
    phoneNumberPrefix: 262,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GA: {
    code: 'GA',
    continent: 'AF',
    phoneNumberPrefix: 241,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GM: {
    code: 'GM',
    continent: 'AF',
    phoneNumberPrefix: 220,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GE: {
    code: 'GE',
    continent: 'EU',
    phoneNumberPrefix: 995,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  DE: {
    code: 'DE',
    continent: 'EU',
    phoneNumberPrefix: 49,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: {
      address1: 'address1.partial',
      address2: 'address2.addon',
      zip: 'zip.postalcode',
    },
  },
  GH: {
    code: 'GH',
    continent: 'AF',
    phoneNumberPrefix: 233,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GI: {
    code: 'GI',
    continent: 'EU',
    phoneNumberPrefix: 350,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GR: {
    code: 'GR',
    continent: 'EU',
    phoneNumberPrefix: 30,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GL: {
    code: 'GL',
    continent: 'EU',
    phoneNumberPrefix: 299,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GD: {
    code: 'GD',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GP: {
    code: 'GP',
    continent: 'EU',
    phoneNumberPrefix: 590,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GT: {
    code: 'GT',
    continent: 'CA',
    phoneNumberPrefix: 502,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [
      'AVE',
      'BVE',
      'CMT',
      'CQM',
      'EPR',
      'ESC',
      'GUA',
      'HUE',
      'IZA',
      'JAL',
      'JUT',
      'PET',
      'QUE',
      'QUI',
      'RET',
      'SAC',
      'SMA',
      'SRO',
      'SOL',
      'SUC',
      'TOT',
      'ZAC',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zone}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GG: {
    code: 'GG',
    continent: 'EU',
    phoneNumberPrefix: 44,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GN: {
    code: 'GN',
    continent: 'AF',
    phoneNumberPrefix: 224,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GW: {
    code: 'GW',
    continent: 'AF',
    phoneNumberPrefix: 245,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GY: {
    code: 'GY',
    continent: 'SA',
    phoneNumberPrefix: 592,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  HT: {
    code: 'HT',
    continent: 'CA',
    phoneNumberPrefix: 509,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  HM: {
    code: 'HM',
    continent: 'O',
    phoneNumberPrefix: 0,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  HN: {
    code: 'HN',
    continent: 'CA',
    phoneNumberPrefix: 504,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  HK: {
    code: 'HK',
    continent: 'AS',
    phoneNumberPrefix: 852,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: ['HK', 'KL', 'NT'],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zone} {country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { city: 'city.district', zip: 'zip.postalcode' },
  },
  HU: {
    code: 'HU',
    continent: 'EU',
    phoneNumberPrefix: 36,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  IS: {
    code: 'IS',
    continent: 'EU',
    phoneNumberPrefix: 354,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  IN: {
    code: 'IN',
    continent: 'AS',
    phoneNumberPrefix: 91,
    autocompletionField: 'address1',
    zoneKey: 'STATE',
    zones: [
      'AN',
      'AP',
      'AR',
      'AS',
      'BR',
      'CH',
      'CG',
      'DN',
      'DD',
      'DL',
      'GA',
      'GJ',
      'HR',
      'HP',
      'JK',
      'JH',
      'KA',
      'KL',
      'LA',
      'LD',
      'MP',
      'MH',
      'MN',
      'ML',
      'MZ',
      'NL',
      'OR',
      'PY',
      'PB',
      'RJ',
      'SK',
      'TN',
      'TS',
      'TR',
      'UP',
      'UK',
      'WB',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city} {zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.pincode' },
  },
  ID: {
    code: 'ID',
    continent: 'AS',
    phoneNumberPrefix: 62,
    autocompletionField: 'address1',
    zoneKey: 'PROVINCE',
    zones: [
      'AC',
      'BA',
      'BB',
      'BT',
      'BE',
      'GO',
      'JK',
      'JA',
      'JB',
      'JT',
      'JI',
      'KB',
      'KS',
      'KT',
      'KI',
      'KU',
      'KR',
      'LA',
      'MA',
      'MU',
      'SU',
      'NB',
      'NT',
      'PA',
      'PB',
      'RI',
      'SS',
      'SR',
      'SN',
      'ST',
      'SG',
      'SA',
      'SB',
      'YO',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zone} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['zone', 'zip'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  IQ: {
    code: 'IQ',
    continent: 'AS',
    phoneNumberPrefix: 964,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  IE: {
    code: 'IE',
    continent: 'EU',
    phoneNumberPrefix: 353,
    autocompletionField: 'address1',
    zoneKey: 'COUNTY',
    zones: [
      'CW',
      'CN',
      'CE',
      'CO',
      'DL',
      'D',
      'G',
      'KY',
      'KE',
      'KK',
      'LS',
      'LM',
      'LK',
      'LD',
      'LH',
      'MO',
      'MH',
      'MN',
      'OY',
      'RN',
      'SO',
      'TA',
      'WD',
      'WH',
      'WX',
      'WW',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zone}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  IM: {
    code: 'IM',
    continent: 'EU',
    phoneNumberPrefix: 44,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  IL: {
    code: 'IL',
    continent: 'AS',
    phoneNumberPrefix: 972,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  IT: {
    code: 'IT',
    continent: 'EU',
    phoneNumberPrefix: 39,
    autocompletionField: 'address1',
    zoneKey: 'PROVINCE',
    zones: [
      'AG',
      'AL',
      'AN',
      'AO',
      'AR',
      'AP',
      'AT',
      'AV',
      'BA',
      'BT',
      'BL',
      'BN',
      'BG',
      'BI',
      'BO',
      'BZ',
      'BS',
      'BR',
      'CA',
      'CL',
      'CB',
      'CI',
      'CE',
      'CT',
      'CZ',
      'CH',
      'CO',
      'CS',
      'CR',
      'KR',
      'CN',
      'EN',
      'FM',
      'FE',
      'FI',
      'FG',
      'FC',
      'FR',
      'GE',
      'GO',
      'GR',
      'IM',
      'IS',
      'AQ',
      'SP',
      'LT',
      'LE',
      'LC',
      'LI',
      'LO',
      'LU',
      'MC',
      'MN',
      'MS',
      'MT',
      'VS',
      'ME',
      'MI',
      'MO',
      'MB',
      'NA',
      'NO',
      'NU',
      'OG',
      'OT',
      'OR',
      'PD',
      'PA',
      'PR',
      'PV',
      'PG',
      'PU',
      'PE',
      'PC',
      'PI',
      'PT',
      'PN',
      'PZ',
      'PO',
      'RG',
      'RA',
      'RC',
      'RE',
      'RI',
      'RN',
      'RM',
      'RO',
      'SA',
      'SS',
      'SV',
      'SI',
      'SR',
      'SO',
      'TA',
      'TE',
      'TR',
      'TO',
      'TP',
      'TN',
      'TV',
      'TS',
      'UD',
      'VA',
      'VE',
      'VB',
      'VC',
      'VR',
      'VV',
      'VI',
      'VT',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city} {zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city', 'zone'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { address1: 'address1.partial', zip: 'zip.postalcode' },
  },
  JM: {
    code: 'JM',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  JP: {
    code: 'JP',
    continent: 'AS',
    phoneNumberPrefix: 81,
    autocompletionField: 'zip',
    zoneKey: 'PREFECTURE',
    zones: [
      'JP-01',
      'JP-02',
      'JP-03',
      'JP-04',
      'JP-05',
      'JP-06',
      'JP-07',
      'JP-08',
      'JP-09',
      'JP-10',
      'JP-11',
      'JP-12',
      'JP-13',
      'JP-14',
      'JP-15',
      'JP-16',
      'JP-17',
      'JP-18',
      'JP-19',
      'JP-20',
      'JP-21',
      'JP-22',
      'JP-23',
      'JP-24',
      'JP-25',
      'JP-26',
      'JP-27',
      'JP-28',
      'JP-29',
      'JP-30',
      'JP-31',
      'JP-32',
      'JP-33',
      'JP-34',
      'JP-35',
      'JP-36',
      'JP-37',
      'JP-38',
      'JP-39',
      'JP-40',
      'JP-41',
      'JP-42',
      'JP-43',
      'JP-44',
      'JP-45',
      'JP-46',
      'JP-47',
    ],
    formatting:
      '{country} 〒{zip}\n{zone} {city}\n{address1}\n{address2}\n{company}\n{lastName} {firstName}様\n{phone}',
    layout: [
      ['lastName', 'firstName'],
      ['company'],
      ['country', 'zip'],
      ['zone'],
      ['city'],
      ['address1'],
      ['address2'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { city: 'city.all', zip: 'zip.postalcode' },
  },
  JE: {
    code: 'JE',
    continent: 'EU',
    phoneNumberPrefix: 44,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  JO: {
    code: 'JO',
    continent: 'AS',
    phoneNumberPrefix: 962,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  KZ: {
    code: 'KZ',
    continent: 'AS',
    phoneNumberPrefix: 7,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  KE: {
    code: 'KE',
    continent: 'AF',
    phoneNumberPrefix: 254,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  KI: {
    code: 'KI',
    continent: 'OC',
    phoneNumberPrefix: 686,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  XK: {
    code: 'XK',
    continent: 'EU',
    phoneNumberPrefix: 383,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  KW: {
    code: 'KW',
    continent: 'AS',
    phoneNumberPrefix: 965,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  KG: {
    code: 'KG',
    continent: 'AS',
    phoneNumberPrefix: 996,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{zip} {city}\n{address2}\n{address1}\n{company}\n{firstName} {lastName}\n{country}\n{phone}',
    layout: [
      ['zip', 'city'],
      ['address2'],
      ['address1'],
      ['company'],
      ['firstName', 'lastName'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  LA: {
    code: 'LA',
    continent: 'AS',
    phoneNumberPrefix: 856,
    autocompletionField: 'address1',
    zoneKey: 'PROVINCE',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  LV: {
    code: 'LV',
    continent: 'EU',
    phoneNumberPrefix: 371,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  LB: {
    code: 'LB',
    continent: 'AS',
    phoneNumberPrefix: 961,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  LS: {
    code: 'LS',
    continent: 'AF',
    phoneNumberPrefix: 266,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  LR: {
    code: 'LR',
    continent: 'AF',
    phoneNumberPrefix: 231,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  LY: {
    code: 'LY',
    continent: 'AF',
    phoneNumberPrefix: 218,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  LI: {
    code: 'LI',
    continent: 'EU',
    phoneNumberPrefix: 423,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  LT: {
    code: 'LT',
    continent: 'EU',
    phoneNumberPrefix: 370,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  LU: {
    code: 'LU',
    continent: 'EU',
    phoneNumberPrefix: 352,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MO: {
    code: 'MO',
    continent: 'AS',
    phoneNumberPrefix: 853,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MG: {
    code: 'MG',
    continent: 'AF',
    phoneNumberPrefix: 261,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MW: {
    code: 'MW',
    continent: 'AF',
    phoneNumberPrefix: 265,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MY: {
    code: 'MY',
    continent: 'AS',
    phoneNumberPrefix: 60,
    autocompletionField: 'address1',
    zoneKey: 'STATE_AND_TERRITORY',
    zones: [
      'JHR',
      'KDH',
      'KTN',
      'KUL',
      'LBN',
      'MLK',
      'NSN',
      'PHG',
      'PNG',
      'PRK',
      'PLS',
      'PJY',
      'SBH',
      'SWK',
      'SGR',
      'TRG',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city} {zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['zone', 'country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postcode' },
  },
  MV: {
    code: 'MV',
    continent: 'AS',
    phoneNumberPrefix: 960,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  ML: {
    code: 'ML',
    continent: 'AF',
    phoneNumberPrefix: 223,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MT: {
    code: 'MT',
    continent: 'EU',
    phoneNumberPrefix: 356,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MQ: {
    code: 'MQ',
    continent: 'CA',
    phoneNumberPrefix: 596,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MR: {
    code: 'MR',
    continent: 'AF',
    phoneNumberPrefix: 222,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MU: {
    code: 'MU',
    continent: 'AF',
    phoneNumberPrefix: 230,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  YT: {
    code: 'YT',
    continent: 'EU',
    phoneNumberPrefix: 262,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MX: {
    code: 'MX',
    continent: 'NA',
    phoneNumberPrefix: 52,
    autocompletionField: 'address1',
    zoneKey: 'STATE',
    zones: [
      'AGS',
      'BC',
      'BCS',
      'CAMP',
      'CHIS',
      'CHIH',
      'DF',
      'COAH',
      'COL',
      'DGO',
      'GTO',
      'GRO',
      'HGO',
      'JAL',
      'MEX',
      'MICH',
      'MOR',
      'NAY',
      'NL',
      'OAX',
      'PUE',
      'QRO',
      'Q ROO',
      'SLP',
      'SIN',
      'SON',
      'TAB',
      'TAMPS',
      'TLAX',
      'VER',
      'YUC',
      'ZAC',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city} {zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['zone', 'country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { address1: 'address1.partial', zip: 'zip.postalcode' },
  },
  MD: {
    code: 'MD',
    continent: 'EU',
    phoneNumberPrefix: 373,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MC: {
    code: 'MC',
    continent: 'EU',
    phoneNumberPrefix: 377,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MN: {
    code: 'MN',
    continent: 'AS',
    phoneNumberPrefix: 976,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  ME: {
    code: 'ME',
    continent: 'EU',
    phoneNumberPrefix: 382,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MS: {
    code: 'MS',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MA: {
    code: 'MA',
    continent: 'AF',
    phoneNumberPrefix: 212,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MZ: {
    code: 'MZ',
    continent: 'AF',
    phoneNumberPrefix: 258,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MM: {
    code: 'MM',
    continent: 'AS',
    phoneNumberPrefix: 95,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  NA: {
    code: 'NA',
    continent: 'AF',
    phoneNumberPrefix: 264,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  NR: {
    code: 'NR',
    continent: 'OC',
    phoneNumberPrefix: 674,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  NP: {
    code: 'NP',
    continent: 'AS',
    phoneNumberPrefix: 977,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  NL: {
    code: 'NL',
    continent: 'EU',
    phoneNumberPrefix: 31,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { address1: 'address1.partial', zip: 'zip.postalcode' },
  },
  NC: {
    code: 'NC',
    continent: 'OC',
    phoneNumberPrefix: 687,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  NZ: {
    code: 'NZ',
    continent: 'OC',
    phoneNumberPrefix: 64,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [
      'AUK',
      'BOP',
      'CAN',
      'CIT',
      'GIS',
      'HKB',
      'MWT',
      'MBH',
      'NSN',
      'NTL',
      'OTA',
      'STL',
      'TKI',
      'TAS',
      'WKO',
      'WGN',
      'WTC',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zone}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  NI: {
    code: 'NI',
    continent: 'CA',
    phoneNumberPrefix: 505,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  NE: {
    code: 'NE',
    continent: 'AF',
    phoneNumberPrefix: 227,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  NG: {
    code: 'NG',
    continent: 'AF',
    phoneNumberPrefix: 234,
    autocompletionField: 'address1',
    zoneKey: 'STATE',
    zones: [
      'AB',
      'FC',
      'AD',
      'AK',
      'AN',
      'BA',
      'BY',
      'BE',
      'BO',
      'CR',
      'DE',
      'EB',
      'ED',
      'EK',
      'EN',
      'GO',
      'IM',
      'JI',
      'KD',
      'KN',
      'KT',
      'KE',
      'KO',
      'KW',
      'LA',
      'NA',
      'NI',
      'OG',
      'ON',
      'OS',
      'OY',
      'PL',
      'RI',
      'SO',
      'TA',
      'YO',
      'ZA',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city} {zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  NU: {
    code: 'NU',
    continent: 'OC',
    phoneNumberPrefix: 683,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  NF: {
    code: 'NF',
    continent: 'OC',
    phoneNumberPrefix: 672,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MK: {
    code: 'MK',
    continent: 'EU',
    phoneNumberPrefix: 389,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  NO: {
    code: 'NO',
    continent: 'EU',
    phoneNumberPrefix: 47,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { address1: 'address1.partial', zip: 'zip.postalcode' },
  },
  OM: {
    code: 'OM',
    continent: 'AS',
    phoneNumberPrefix: 968,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  PK: {
    code: 'PK',
    continent: 'AS',
    phoneNumberPrefix: 92,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  PS: {
    code: 'PS',
    continent: 'AS',
    phoneNumberPrefix: 970,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  PA: {
    code: 'PA',
    continent: 'CA',
    phoneNumberPrefix: 507,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [
      'PA-1',
      'PA-4',
      'PA-2',
      'PA-3',
      'PA-5',
      'PA-EM',
      'PA-6',
      'PA-KY',
      'PA-7',
      'PA-NB',
      'PA-8',
      'PA-10',
      'PA-9',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  PG: {
    code: 'PG',
    continent: 'OC',
    phoneNumberPrefix: 675,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  PY: {
    code: 'PY',
    continent: 'SA',
    phoneNumberPrefix: 595,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  PE: {
    code: 'PE',
    continent: 'SA',
    phoneNumberPrefix: 51,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [
      'PE-AMA',
      'PE-ANC',
      'PE-APU',
      'PE-ARE',
      'PE-AYA',
      'PE-CAJ',
      'PE-CAL',
      'PE-CUS',
      'PE-HUV',
      'PE-HUC',
      'PE-ICA',
      'PE-JUN',
      'PE-LAL',
      'PE-LAM',
      'PE-LIM',
      'PE-LMA',
      'PE-LOR',
      'PE-MDD',
      'PE-MOQ',
      'PE-PAS',
      'PE-PIU',
      'PE-PUN',
      'PE-SAM',
      'PE-TAC',
      'PE-TUM',
      'PE-UCA',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  PH: {
    code: 'PH',
    continent: 'AS',
    phoneNumberPrefix: 63,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [
      'PH-ABR',
      'PH-AGN',
      'PH-AGS',
      'PH-AKL',
      'PH-ALB',
      'PH-ANT',
      'PH-APA',
      'PH-AUR',
      'PH-BAS',
      'PH-BAN',
      'PH-BTN',
      'PH-BTG',
      'PH-BEN',
      'PH-BIL',
      'PH-BOH',
      'PH-BUK',
      'PH-BUL',
      'PH-CAG',
      'PH-CAN',
      'PH-CAS',
      'PH-CAM',
      'PH-CAP',
      'PH-CAT',
      'PH-CAV',
      'PH-CEB',
      'PH-NCO',
      'PH-DVO',
      'PH-DAO',
      'PH-COM',
      'PH-DAV',
      'PH-DAS',
      'PH-DIN',
      'PH-EAS',
      'PH-GUI',
      'PH-IFU',
      'PH-ILN',
      'PH-ILS',
      'PH-ILI',
      'PH-ISA',
      'PH-KAL',
      'PH-LUN',
      'PH-LAG',
      'PH-LAN',
      'PH-LAS',
      'PH-LEY',
      'PH-MAG',
      'PH-MAD',
      'PH-MAS',
      'PH-00',
      'PH-MSC',
      'PH-MSR',
      'PH-MOU',
      'PH-NEC',
      'PH-NER',
      'PH-NSA',
      'PH-NUE',
      'PH-NUV',
      'PH-MDC',
      'PH-MDR',
      'PH-PLW',
      'PH-PAM',
      'PH-PAN',
      'PH-QUE',
      'PH-QUI',
      'PH-RIZ',
      'PH-ROM',
      'PH-WSA',
      'PH-SAR',
      'PH-SIG',
      'PH-SOR',
      'PH-SCO',
      'PH-SLE',
      'PH-SUK',
      'PH-SLU',
      'PH-SUN',
      'PH-SUR',
      'PH-TAR',
      'PH-TAW',
      'PH-ZMB',
      'PH-ZSI',
      'PH-ZAN',
      'PH-ZAS',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['zone'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  PN: {
    code: 'PN',
    continent: 'OC',
    phoneNumberPrefix: 64,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  PL: {
    code: 'PL',
    continent: 'EU',
    phoneNumberPrefix: 48,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  PT: {
    code: 'PT',
    continent: 'EU',
    phoneNumberPrefix: 351,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [
      'PT-20',
      'PT-01',
      'PT-02',
      'PT-03',
      'PT-04',
      'PT-05',
      'PT-06',
      'PT-07',
      'PT-08',
      'PT-09',
      'PT-10',
      'PT-11',
      'PT-30',
      'PT-12',
      'PT-13',
      'PT-14',
      'PT-15',
      'PT-16',
      'PT-17',
      'PT-18',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip} {zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  QA: {
    code: 'QA',
    continent: 'AS',
    phoneNumberPrefix: 974,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  RE: {
    code: 'RE',
    continent: 'EU',
    phoneNumberPrefix: 262,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  RO: {
    code: 'RO',
    continent: 'EU',
    phoneNumberPrefix: 40,
    autocompletionField: 'address1',
    zoneKey: 'COUNTY',
    zones: [
      'AB',
      'AR',
      'AG',
      'BC',
      'BH',
      'BN',
      'BT',
      'BR',
      'BV',
      'B',
      'BZ',
      'CS',
      'CJ',
      'CT',
      'CV',
      'CL',
      'DJ',
      'DB',
      'GL',
      'GR',
      'GJ',
      'HR',
      'HD',
      'IL',
      'IS',
      'IF',
      'MM',
      'MH',
      'MS',
      'NT',
      'OT',
      'PH',
      'SJ',
      'SM',
      'SB',
      'SV',
      'TR',
      'TM',
      'TL',
      'VL',
      'VS',
      'VN',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city} {zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['zone', 'country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  RU: {
    code: 'RU',
    continent: 'AS',
    phoneNumberPrefix: 7,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [
      'ALT',
      'AL',
      'AMU',
      'ARK',
      'AST',
      'BEL',
      'BRY',
      'CE',
      'CHE',
      'CHU',
      'CU',
      'IRK',
      'IVA',
      'YEV',
      'KB',
      'KGD',
      'KLU',
      'KAM',
      'KC',
      'KEM',
      'KHA',
      'KHM',
      'KIR',
      'KO',
      'KOS',
      'KDA',
      'KYA',
      'KGN',
      'KRS',
      'LEN',
      'LIP',
      'MAG',
      'ME',
      'MOW',
      'MOS',
      'MUR',
      'NIZ',
      'NGR',
      'NVS',
      'OMS',
      'ORE',
      'ORL',
      'PNZ',
      'PER',
      'PRI',
      'PSK',
      'AD',
      'BA',
      'BU',
      'DA',
      'IN',
      'KL',
      'KR',
      'KK',
      'MO',
      'SE',
      'TA',
      'ROS',
      'RYA',
      'SPE',
      'SA',
      'SAK',
      'SAM',
      'SAR',
      'SMO',
      'STA',
      'SVE',
      'TAM',
      'TOM',
      'TUL',
      'TVE',
      'TYU',
      'TY',
      'UD',
      'ULY',
      'VLA',
      'VGG',
      'VLG',
      'VOR',
      'YAN',
      'YAR',
      'ZAB',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zone}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  RW: {
    code: 'RW',
    continent: 'AF',
    phoneNumberPrefix: 250,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  WS: {
    code: 'WS',
    continent: 'OC',
    phoneNumberPrefix: 685,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SM: {
    code: 'SM',
    continent: 'EU',
    phoneNumberPrefix: 378,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  ST: {
    code: 'ST',
    continent: 'AF',
    phoneNumberPrefix: 239,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SA: {
    code: 'SA',
    continent: 'AS',
    phoneNumberPrefix: 966,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SN: {
    code: 'SN',
    continent: 'AF',
    phoneNumberPrefix: 221,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  RS: {
    code: 'RS',
    continent: 'EU',
    phoneNumberPrefix: 381,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SC: {
    code: 'SC',
    continent: 'AF',
    phoneNumberPrefix: 248,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SL: {
    code: 'SL',
    continent: 'AF',
    phoneNumberPrefix: 232,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SG: {
    code: 'SG',
    continent: 'AS',
    phoneNumberPrefix: 65,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SX: {
    code: 'SX',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SK: {
    code: 'SK',
    continent: 'EU',
    phoneNumberPrefix: 421,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SI: {
    code: 'SI',
    continent: 'EU',
    phoneNumberPrefix: 386,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SB: {
    code: 'SB',
    continent: 'OC',
    phoneNumberPrefix: 677,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SO: {
    code: 'SO',
    continent: 'AF',
    phoneNumberPrefix: 252,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  ZA: {
    code: 'ZA',
    continent: 'AF',
    phoneNumberPrefix: 27,
    autocompletionField: 'address1',
    zoneKey: 'PROVINCE',
    zones: ['EC', 'FS', 'GT', 'NL', 'LP', 'MP', 'NW', 'NC', 'WC'],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zone}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GS: {
    code: 'GS',
    continent: 'O',
    phoneNumberPrefix: 500,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  KR: {
    code: 'KR',
    continent: 'AS',
    phoneNumberPrefix: 82,
    autocompletionField: 'zip',
    zoneKey: 'PROVINCE',
    zones: [
      'KR-26',
      'KR-43',
      'KR-44',
      'KR-27',
      'KR-30',
      'KR-42',
      'KR-29',
      'KR-47',
      'KR-41',
      'KR-48',
      'KR-28',
      'KR-49',
      'KR-45',
      'KR-46',
      'KR-50',
      'KR-11',
      'KR-31',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{zone}\n{country}\n{phone}',
    layout: [
      ['company'],
      ['lastName', 'firstName'],
      ['zip'],
      ['country'],
      ['zone', 'city'],
      ['address1'],
      ['address2'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SS: {
    code: 'SS',
    continent: 'AF',
    phoneNumberPrefix: 211,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  ES: {
    code: 'ES',
    continent: 'EU',
    phoneNumberPrefix: 34,
    autocompletionField: 'address1',
    zoneKey: 'PROVINCE',
    zones: [
      'C',
      'VI',
      'AB',
      'A',
      'AL',
      'O',
      'AV',
      'BA',
      'PM',
      'B',
      'BU',
      'CC',
      'CA',
      'S',
      'CS',
      'CE',
      'CR',
      'CO',
      'CU',
      'GI',
      'GR',
      'GU',
      'SS',
      'H',
      'HU',
      'J',
      'LO',
      'GC',
      'LE',
      'L',
      'LU',
      'M',
      'MA',
      'ML',
      'MU',
      'NA',
      'OR',
      'P',
      'PO',
      'SA',
      'TF',
      'SG',
      'SE',
      'SO',
      'T',
      'TE',
      'TO',
      'V',
      'VA',
      'BI',
      'ZA',
      'Z',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city', 'zone'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { address1: 'address1.partial', zip: 'zip.postalcode' },
  },
  LK: {
    code: 'LK',
    continent: 'AS',
    phoneNumberPrefix: 94,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  BL: {
    code: 'BL',
    continent: 'CA',
    phoneNumberPrefix: 590,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SH: {
    code: 'SH',
    continent: 'AF',
    phoneNumberPrefix: 290,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  KN: {
    code: 'KN',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  LC: {
    code: 'LC',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  MF: {
    code: 'MF',
    continent: 'CA',
    phoneNumberPrefix: 590,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  PM: {
    code: 'PM',
    continent: 'CA',
    phoneNumberPrefix: 508,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  VC: {
    code: 'VC',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SD: {
    code: 'SD',
    continent: 'AF',
    phoneNumberPrefix: 249,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SR: {
    code: 'SR',
    continent: 'SA',
    phoneNumberPrefix: 597,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SJ: {
    code: 'SJ',
    continent: 'EU',
    phoneNumberPrefix: 47,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  SE: {
    code: 'SE',
    continent: 'EU',
    phoneNumberPrefix: 46,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: {
      address1: 'address1.partial',
      city: 'city.town',
      zip: 'zip.postalcode',
    },
  },
  CH: {
    code: 'CH',
    continent: 'EU',
    phoneNumberPrefix: 41,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: {
      address1: 'address1.partial',
      address2: 'address2.addon',
      zip: 'zip.postalcode',
    },
  },
  TW: {
    code: 'TW',
    continent: 'AS',
    phoneNumberPrefix: 886,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  TJ: {
    code: 'TJ',
    continent: 'AS',
    phoneNumberPrefix: 992,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  TZ: {
    code: 'TZ',
    continent: 'AF',
    phoneNumberPrefix: 255,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  TH: {
    code: 'TH',
    continent: 'AS',
    phoneNumberPrefix: 66,
    autocompletionField: 'address1',
    zoneKey: 'PROVINCE',
    zones: [
      'TH-37',
      'TH-15',
      'TH-10',
      'TH-38',
      'TH-31',
      'TH-24',
      'TH-18',
      'TH-36',
      'TH-22',
      'TH-50',
      'TH-57',
      'TH-20',
      'TH-86',
      'TH-46',
      'TH-62',
      'TH-71',
      'TH-40',
      'TH-81',
      'TH-52',
      'TH-51',
      'TH-42',
      'TH-16',
      'TH-58',
      'TH-44',
      'TH-49',
      'TH-26',
      'TH-73',
      'TH-48',
      'TH-30',
      'TH-60',
      'TH-80',
      'TH-55',
      'TH-96',
      'TH-39',
      'TH-43',
      'TH-12',
      'TH-13',
      'TH-94',
      'TH-S',
      'TH-82',
      'TH-93',
      'TH-56',
      'TH-67',
      'TH-76',
      'TH-66',
      'TH-65',
      'TH-14',
      'TH-54',
      'TH-83',
      'TH-25',
      'TH-77',
      'TH-85',
      'TH-70',
      'TH-21',
      'TH-45',
      'TH-27',
      'TH-47',
      'TH-11',
      'TH-74',
      'TH-75',
      'TH-19',
      'TH-91',
      'TH-17',
      'TH-33',
      'TH-90',
      'TH-64',
      'TH-72',
      'TH-84',
      'TH-32',
      'TH-63',
      'TH-92',
      'TH-23',
      'TH-34',
      'TH-41',
      'TH-61',
      'TH-53',
      'TH-95',
      'TH-35',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zone} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  TL: {
    code: 'TL',
    continent: 'OC',
    phoneNumberPrefix: 670,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  TG: {
    code: 'TG',
    continent: 'AF',
    phoneNumberPrefix: 228,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  TK: {
    code: 'TK',
    continent: 'OC',
    phoneNumberPrefix: 690,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  TO: {
    code: 'TO',
    continent: 'OC',
    phoneNumberPrefix: 676,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  TT: {
    code: 'TT',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  //commenting it as Tristan da Cunha is part of SH (Saint-Helene, Ascension et Tristan da Cunha, Britannic territory )
  // TA: {
  //   code: 'TA',
  //   continent: 'AF',
  //   phoneNumberPrefix: 2908,
  //   autocompletionField: 'address1',
  //   zoneKey: 'REGION',
  //   zones: [],
  //   formatting:
  //     '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
  //   layout: [
  //     ['firstName', 'lastName'],
  //     ['company'],
  //     ['address1'],
  //     ['address2'],
  //     ['city'],
  //     ['country', 'zip'],
  //     ['phone'],
  //   ],
  //   optionalFields: ['address2'],
  //   labelKeys: { zip: 'zip.postalcode' },
  // },
  TN: {
    code: 'TN',
    continent: 'AF',
    phoneNumberPrefix: 216,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  TR: {
    code: 'TR',
    continent: 'EU',
    phoneNumberPrefix: 90,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  TM: {
    code: 'TM',
    continent: 'AS',
    phoneNumberPrefix: 993,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{zip}{city}\n{country}\n{firstName}{lastName}\n{company}\n{address1}\n{address2}\n{phone}',
    layout: [
      ['zip', 'city'],
      ['country'],
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  TC: {
    code: 'TC',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  TV: {
    code: 'TV',
    continent: 'OC',
    phoneNumberPrefix: 688,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  UM: {
    code: 'UM',
    continent: 'CA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'STATE',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  UG: {
    code: 'UG',
    continent: 'AF',
    phoneNumberPrefix: 256,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  UA: {
    code: 'UA',
    continent: 'EU',
    phoneNumberPrefix: 380,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  AE: {
    code: 'AE',
    continent: 'AS',
    phoneNumberPrefix: 971,
    autocompletionField: 'address1',
    zoneKey: 'EMIRATE',
    zones: ['AZ', 'AJ', 'DU', 'FU', 'RK', 'SH', 'UQ'],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zone}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  GB: {
    code: 'GB',
    continent: 'EU',
    phoneNumberPrefix: 44,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: ['BFP', 'ENG', 'NIR', 'SCT', 'WLS'],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postcode' },
  },
  US: {
    code: 'US',
    continent: 'NA',
    phoneNumberPrefix: 1,
    autocompletionField: 'address1',
    zoneKey: 'STATE',
    zones: [
      'AL',
      'AK',
      'AS',
      'AZ',
      'AR',
      'CA',
      'CO',
      'CT',
      'DE',
      'DC',
      'FM',
      'FL',
      'GA',
      'GU',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'ME',
      'MH',
      'MD',
      'MA',
      'MI',
      'MN',
      'MS',
      'MO',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'MP',
      'OH',
      'OK',
      'OR',
      'PW',
      'PA',
      'PR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VI',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
      'AA',
      'AE',
      'AP',
    ],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zone} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zone', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.zipcode' },
  },
  UY: {
    code: 'UY',
    continent: 'SA',
    phoneNumberPrefix: 598,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  UZ: {
    code: 'UZ',
    continent: 'AS',
    phoneNumberPrefix: 998,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  VU: {
    code: 'VU',
    continent: 'OC',
    phoneNumberPrefix: 678,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  VA: {
    code: 'VA',
    continent: 'EU',
    phoneNumberPrefix: 379,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  VE: {
    code: 'VE',
    continent: 'SA',
    phoneNumberPrefix: 58,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  VN: {
    code: 'VN',
    continent: 'AS',
    phoneNumberPrefix: 84,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city} {zip}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  WF: {
    code: 'WF',
    continent: 'OC',
    phoneNumberPrefix: 681,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  EH: {
    code: 'EH',
    continent: 'AF',
    phoneNumberPrefix: 212,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country', 'zip'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  YE: {
    code: 'YE',
    continent: 'AS',
    phoneNumberPrefix: 967,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  ZM: {
    code: 'ZM',
    continent: 'AF',
    phoneNumberPrefix: 260,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{zip} {city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['zip', 'city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
  ZW: {
    code: 'ZW',
    continent: 'AF',
    phoneNumberPrefix: 263,
    autocompletionField: 'address1',
    zoneKey: 'REGION',
    zones: [],
    formatting:
      '{firstName} {lastName}\n{company}\n{address1}\n{address2}\n{city}\n{country}\n{phone}',
    layout: [
      ['firstName', 'lastName'],
      ['company'],
      ['address1'],
      ['address2'],
      ['city'],
      ['country'],
      ['phone'],
    ],
    optionalFields: ['address2'],
    labelKeys: { zip: 'zip.postalcode' },
  },
};

import React, { useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface IForgotPWDialogProps {
  /** Boolean indicating if the dialog is open or not */
  open: boolean;
  /** Callback on dialog closed or cancelled */
  onClose: () => void;
  /** Callback on main action click with the email entered by the user */
  onActionClick: (email: string) => void;
}

export const ForgotPWDialog = ({ open, onClose, onActionClick }: IForgotPWDialogProps) => {
  const { t } = useTranslation();
  const emailField = useRef<HTMLInputElement>();

  const handleSendRestLink = () => {
    const email = emailField.current?.value ?? "";
    if (!email) {
      //TODO error directly on input
    }
    onActionClick(email);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('auth.forgotPassword.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('auth.forgotPassword.content')}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label={t('auth.forgotPassword.inputLabel')}
          placeholder={t('auth.forgotPassword.inputPlaceholder')}
          type="email"
          fullWidth
          inputRef={emailField}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleSendRestLink} color="primary">
          {t('auth.forgotPassword.okBtn')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

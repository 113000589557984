import { Breadcrumbs, Container, Link, Stack, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

export interface IAccountSubPageViewProps {
    /** The title of the sub page view */
    title: string;
    /** The breadcrumbs of the sub-page-view (excluding the current page) */
    breadcrumbs: { label: string, href: string }[];
}

/** Account sub page view, only takes care of common visual of a account sub page view like padding for all pages and breadcrumbs */
export const AccountSubPageView = ({ title, breadcrumbs, children }: PropsWithChildren<IAccountSubPageViewProps>) => {
    return (
        <Container sx={{ p: 3 }}>
            <Stack direction={'column'}>
                <Breadcrumbs>
                    {
                        breadcrumbs.map((b, index) =>
                            <Link key={`breadcrumb-${index}`}
                                underline="hover"
                                color="inherit"
                                href={b.href}>
                                {b.label}
                            </Link>
                        )
                    }
                    <Typography color="text.primary">{title}</Typography>
                </Breadcrumbs>
                {children}
            </Stack>
        </Container>
    );
}
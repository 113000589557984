import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ProfileSummary } from "../../components/profile/ProfileSummary";
import { ROUTE_PATH } from "../../utilities/navigation";
import { useTranslation } from "react-i18next";
import { FOUNDERS } from "../../containers/news/NewsAboutContainer";

export const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <Stack direction={'column'} sx={{ m: 3 }} spacing={1}>
      <Typography variant="h1" color={"primary"}>{t('about.title')}</Typography>
      <Typography variant="h2">{t('about.theName.title')}</Typography>
      <Typography variant="body1">{t('about.theName.content')}</Typography>
      <Typography variant="h2">{t('about.theIdea.title')}</Typography>
      <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{t('about.theIdea.content')}</Typography>
      <Typography variant="h2">{t('about.thePeople.title')}</Typography>
      <Typography variant="body1">{t('about.thePeople.content')}</Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'space-evenly'} alignItems={'center'}>
        {
          FOUNDERS(t).map((founder, index) =>
            <ProfileSummary
              key={index.toString()}
              avatarImgSrc={founder.image}
              displayName={founder.name}
              verifications={
                {
                  items: [founder.responsibility1, founder.responsibility2, founder.responsibility3]
                }
              } />
          )
        }
      </Stack>
      <Typography variant="h2">{t('about.thePast.title')}</Typography>
      <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{t('about.thePast.content')}</Typography>
      <Typography variant="h2">{t('about.theConcept.title')}</Typography>
      <Typography variant="body1">{t('about.theConcept.content')}</Typography>
      <ul>
        {
          (t('about.theConcepts', { returnObjects: true }) as Array<string>)
            .map((concept, index) =>
              <li key={`concept-${index}`}>
                <Typography variant="body1">{concept}</Typography>
              </li>
            )
        }
      </ul>
      <Typography variant="h3">{t('about.learnMore.title')}</Typography>
      <Button
        component={Link}
        to={ROUTE_PATH.aboutBuild(ROUTE_PATH.faq)}
        variant="contained" color="primary">{t('about.learnMore.contentBtn')}</Button>
    </Stack>
  );
};

import React, { useState } from 'react';
import { Button, Link, Stack, TextField, Typography } from "@mui/material"
import { Trans, useTranslation } from 'react-i18next';

interface INewsContactScreenProps {
    /** The contact email */
    email: string;
}

/** The UI Component for the contact page in the news */
export const NewsContactScreen = ({ email }: INewsContactScreenProps) => {
    const { t } = useTranslation();
    // const [sender, setSender] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    return <Stack direction={'column'} justifyContent={'start'} alignItems={'stretch'} spacing={2} p={3}>
        <Typography variant='h1'>{t('news.contact.title')}</Typography>
        <Trans
            i18nKey={'news.contact.content'}
            parent={Typography}
            components={[
                <Link href={`mailto:${email}`} color={'inherit'} />
            ]}
            values={{
                email: email,
            }}
        />
        {/* TODO actually send the message directly instead of through mailto action */}
        {/* <Typography variant='h2'>{t('news.contact.formTitle')}</Typography> */}
        {/* <TextField label={t('news.contact.emailLabel')} placeholder={t('news.contact.emailPlaceholder')} value={sender} onChange={(event) => setSender(event.target.value)} /> */}
        <TextField
            label={t('news.contact.subjectLabel')}
            placeholder={t('news.contact.subjectPlaceholder')}
            value={subject}
            onChange={(event) => setSubject(event.target.value)} />
        <TextField
            label={t('news.contact.messageLabel')}
            placeholder={t('news.contact.messagePlaceholder')}
            multiline
            minRows={3}
            value={message}
            onChange={(event) => setMessage(event.target.value)} />
        <Button variant={'contained'}
            href={`mailto:${email}?subject=${encodeURI(subject)}&body=${encodeURI(message)}`}>
            {t('news.contact.sendButton')}
        </Button>
    </Stack>
}
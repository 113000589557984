// Utility file for all Booking.com Listing search

import { format } from "date-fns";
import { DEFAULT_CURRENCY } from "../../../utilities/currency/function";
import { ROUTE_QUERY } from "../../../utilities/navigation";
import { Filters, ISearchListingItemProps } from "../SearchListingsPage.types";
import { ESupportedPlatform, getPlatformLogo } from "./common";

const headers = {
    'X-RapidAPI-Key': '3d394a7680msh6c2f70b58a310ccp174ff1jsn1b630541c4e8',
    'X-RapidAPI-Host': 'booking-com.p.rapidapi.com'
};

enum ESupportedLocales {
    EN_GB = "en-gb",
    EN_US = "en-us",
    DE = "de",
    NL = "nl",
    FR = "fr",
    ES = "es",
    ES_AR = "es-ar",
    ES_MX = "es-mx",
    CA = "ca",
    IT = "it",
    PT_PT = "pt-pt",
    PT_BR = "pt-br",
    NO = "no",
    FI = "fi",
    SV = "sv",
    DA = "da",
    CS = "cs",
    HU = "hu",
    RO = "ro",
    JA = "ja",
    ZH_CN = "zh-cn",
    ZH_TW = "zh-tw",
    PL = "pl",
    EL = "el",
    RU = "ru",
    TR = "tr",
    BG = "bg",
    AR = "ar",
    KO = "ko",
    HE = "he",
    LV = "lv",
    UK = "uk",
    ID = "id",
    MS = "ms",
    TH = "th",
    ET = "et",
    HR = "hr",
    LT = "lt",
    SK = "sk",
    SR = "sr",
    SL = "sl",
    VI = "vi",
    TL = "tl",
    IS = "is"
}
type DestinationType = "city" | "region" | "landmark" | "district" | "hotel" | "country" | "airport" | "latlong";
type SearchLocationItem = {
    "dest_id": number | string,//"-1746443",
    "dest_type": DestinationType,//"city",
    "image_url": string,//"https://cf.bstatic.com/xdata/images/city/150x150/977058.jpg?k=b22d1a8e3de5e776a2d7a56be3c33f8c97a9a5d4323d56eec29d25ac1f30cb56&o=",
    "name": string,//"Berlin",
    "city_ufi": null,
    "roundtrip": string,//"GgAgACgBMgJlbjoGQmVybGluQABKAFAA",
    "latitude": number,//52.516212,
    "longitude": number,//13.376817,
    "region": string,//"Berlin Federal State",
    "cc1": string,//"de",
    "type": string,//"ci",
    "timezone": string,//"Europe/Berlin",
    "country": string,//"Germany",
    "b_max_los_data": {
        "is_fullon": number,//0,
        "has_extended_los": number,//1,
        "extended_los": number,//90,
        "experiment": string,//"long_stays_android_extend_los_2",
        "default_los": number,//45,
        "max_allowed_los": number,//90
    },
    "rtl": number,//0,
    "city_name": string,//"Berlin",
    "label": string,//"Berlin, Berlin Federal State, Germany",
    "lc": string,//"en",
    "hotels": number,//1748,
    "nr_hotels": number,//1748,
}

type SearchLocationResponse = SearchLocationItem[];

type SearchLocationVariable = {
    name: string;
    locale: ESupportedLocales;
}

/** Get the location information (like dest_id) from the name of a city */
const getLocation = async (args: SearchLocationVariable) => {
    const url = `https://booking-com.p.rapidapi.com/v1/hotels/locations?${(new URLSearchParams(args)).toString()}`;
    // console.log('location url', url)

    const options = {
        method: 'GET',
        headers,
    };

    const response = await fetch(url, options);
    const result = (await response.json()) as SearchLocationResponse;

    return result;
}

type SearchListingsVariables =
    (
        {
            /** The type of destination (default to "city") */
            dest_type?: DestinationType;//"city"
            /** The destination id (given by the search location) */
            dest_id: number | string;
        }
        |
        {
            /** Longitude */
            longitude: number;
            /** Latitude */
            latitude: number;
        }
    )
    & {
        /** Check in date format YYYY-MM-DD */
        checkin_date: string;
        /** Check out date format YYYY-MM-DD */
        checkout_date: string;
        /** The units (default to 'metric') */
        units?: 'metric' | 'imperial';
        /** The number of adults */
        adults_number: number;
        /** (Optional) The number of children min:1, max:29 */
        children_number?: number;
        /** (Optional) Children ages, comma separated string (0=less than a year) */
        children_ages?: string;
        /** The order by */
        order_by: 'popularity' | 'distance' | 'review_score' | 'price';//|'class_ascending'|'class_descending'|'upsort_bh'
        /** Filter by currency (default 'CAD') */
        filter_by_currency: string;
        /** The locale (default 'en-us') */
        locale: ESupportedLocales;
        /** The number of room (default 1) */
        room_number?: number;
        /** (Optional) The specific filters (need fetching of filters first for that one) will default to "class::2,class::4,free_cancellation::1"*/
        categories_filter_ids?: string;
        /** (Optional) Page number */
        page_number?: number;
        /** (Optional) Include nearby places. If there are few hotels in the selected location, nearby locations will be added. You should pay attention to the primary_count parameter - it is the number of hotels from the beginning of the array that matches the strict filter. (default to true)*/
        include_adjacency?: boolean
    }

/** Get the listings with the defined variables */
const getListings = async ({
    units = 'metric',
    room_number = 1,
    include_adjacency = true,
    // categories_filter_ids = "class::2,class::4,free_cancellation::1",//for now
    children_number,//this is specific min 1, so if not provided don't default to anything
    ...args }: SearchListingsVariables) => {
    if (!("dest_id" in args)) {
        // console.log("Missing dest_id, if looking for coordinate use getListingsByCoordinate")
        throw new Error("Missing variable")
    }
    //need to stringify all variables for URLSearchParams
    const variables: { [key: string]: string } = {
        ...args,
        units,
        room_number: room_number.toString(),
        dest_type: args.dest_type || "city",
        dest_id: args.dest_id.toString(),
        adults_number: args.adults_number.toString(),
        include_adjacency: !!include_adjacency ? "true" : "false",
        ...(!!children_number ? { children_number: children_number?.toString() } : {}),
        ...(args.page_number ? { page_number: args.page_number?.toString() } : { page_number: "0" }),
    };
    const url = `https://booking-com.p.rapidapi.com/v1/hotels/search?${(new URLSearchParams(variables)).toString()}`;
    // console.log('url listings', url)
    const options = {
        method: 'GET',
        headers,
    };

    const response = await fetch(url, options);
    const result = (await response.json()) as (typeof mockedListingsResult);
    return result;
}

const getListingsByCoordinate = async ({
    units = 'metric',
    room_number = 1,
    include_adjacency = true,
    // categories_filter_ids = "class::2,class::4,free_cancellation::1",//for now
    children_number,//this is specific min 1, so if not provided don't default to anything
    ...args }: SearchListingsVariables) => {
    if (!(("latitude" in args) && ("longitude" in args))) {
        // console.log("Missing dest_id, if looking for getListings?")
        throw new Error("Missing variable")
    }
    //need to stringify all variables for URLSearchParams
    const variables: { [key: string]: string } = {
        ...args,
        longitude: args.longitude.toString(),
        latitude: args.latitude.toString(),
        units,
        room_number: room_number.toString(),
        adults_number: args.adults_number.toString(),
        include_adjacency: !!include_adjacency ? "true" : "false",
        ...(!!children_number ? { children_number: children_number?.toString() } : {}),
        ...(args.page_number ? { page_number: args.page_number?.toString() } : { page_number: "0" }),
    };
    const url = `https://booking-com.p.rapidapi.com/v1/hotels/search-by-coordinates?${(new URLSearchParams(variables)).toString()}`;
    const options = {
        method: 'GET',
        headers,
    };

    const response = await fetch(url, options);
    const result = await response.json() as (typeof mockedListingsResult);
    return result;
}

const mapDataToUI = (result?: typeof mockedListingsResult): ISearchListingItemProps[] => {
    return result?.result?.map(item => (
        {
            title: item.hotel_name,
            price: { amount: item.price_breakdown.all_inclusive_price, currencyCode: item.currencycode },
            images: item.max_photo_url,
            rating: { rate: item.review_score || 0, numberReview: item.review_nr || 0 },
            onClick: () => window.open(item.url, '_blank'),
            hostAvatar: getPlatformLogo(ESupportedPlatform.BOOKING_COM),
            lat: item.latitude,
            lng: item.longitude,
            platformId: ESupportedPlatform.BOOKING_COM,
        }
    )) ?? [];
}

export const queryFn = async (filterValues: Partial<Filters>, filterDefaults: Filters): Promise<ReturnType<typeof mapDataToUI>> => {
    const formatDate = (date: Date) => format(date, "yyyy-MM-dd")
    let location = filterValues.location || filterDefaults.location;
    const restVariables: Omit<SearchListingsVariables, 'latitude' | 'longitude' | 'dest_type' | 'dest_id'> = {
        checkin_date: formatDate(filterValues[ROUTE_QUERY.listings_search.check_in_date]
            || filterDefaults[ROUTE_QUERY.listings_search.check_in_date]),
        checkout_date: formatDate(filterValues[ROUTE_QUERY.listings_search.check_out_date]
            || filterDefaults[ROUTE_QUERY.listings_search.check_out_date]),
        adults_number: filterValues[ROUTE_QUERY.listings_search.adults_number]
            || filterDefaults[ROUTE_QUERY.listings_search.adults_number],
        filter_by_currency: filterValues[ROUTE_QUERY.listings_search.currency]
            || filterDefaults[ROUTE_QUERY.listings_search.currency]
            || DEFAULT_CURRENCY,
        locale: (!!filterValues.locale && Object.values(ESupportedLocales).some(v => v === filterValues.locale)) ?
            filterValues.locale as ESupportedLocales
            : ESupportedLocales.EN_US,
        order_by: filterValues.order_by || "popularity",
        ...(((filterValues.children_number || 0) + (filterValues.infants_number || 0)) > 0 ?
            {
                children_number: (filterValues.children_number || 0) + (filterValues.infants_number || 0),
                //"5,5,1,1"
                children_ages: `${new Array(filterValues.children_number || 0).fill("5").join(',')},${new Array(filterValues.infants_number || 0).fill("1").join(',')}`,
            }
            : {}),
    }
    if (typeof location === 'string' || Array.isArray(location)) {
        try {
            if (Array.isArray(location)) {
                location = location.join(',')
            }

            const locationResult = await getLocation({ name: location, locale: restVariables.locale });
            // console.log('location results', locationResult)
            const destTypes: DestinationType[] = ["city", "region", "district", "landmark", "airport", "country"];//in order
            var index = 0;
            let destId;
            let destType = destTypes[index];
            do {
                destType = destTypes[index]
                // eslint-disable-next-line no-loop-func
                destId = locationResult?.find(r => r.dest_type === destType)?.dest_id
                index++
            } while (!destId && index < destTypes.length);

            if (!destId) {
                return [];
            }
            return getListings({
                ...restVariables,
                dest_type: destType,
                dest_id: destId,
            }).then(mapDataToUI);
        } catch {
            return [];
        }
    } else {
        return getListingsByCoordinate({
            ...restVariables,
            latitude: location.lat,
            longitude: location.lng,
        }).then(mapDataToUI);
    }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mockedLocation: SearchLocationItem = {
    "dest_id": "-575268",
    "dest_type": "city",
    "type": "ci",
    "hotels": 626,
    "nr_hotels": 626,
    "country": "Canada",
    "image_url": "https://cf.bstatic.com/xdata/images/city/150x150/653280.jpg?k=22d48ac39a830a7f37538454fdb0857980fe22cda6ac1afd46d31db9453c0893&o=",
    "city_ufi": null,
    "timezone": "America/Vancouver",
    "name": "Vancouver",
    "city_name": "Vancouver",
    "label": "Vancouver, British Columbia, Canada",
    "cc1": "ca",
    "latitude": 49.282413,
    "longitude": -123.12119,
    "lc": "en",
    "region": "British Columbia",
    "rtl": 0,
    "roundtrip": "GgAgACgBMgJlbjoJVmFuY291dmVyQABKAFAA",
    "b_max_los_data": {
        "experiment": "long_stays_android_extend_los_2",
        "extended_los": 90,
        "max_allowed_los": 90,
        "default_los": 45,
        "is_fullon": 0,
        "has_extended_los": 1
    }
};

const mockedListingsResult = {
    "primary_count": 24,
    "count": 24,
    "room_distribution": [
        {
            "adults": "2",
            "children": [
                5,
                0
            ]
        }
    ],
    "map_bounding_box": {
        "sw_lat": 49.10964659,
        "ne_lat": 49.379963,
        "ne_long": -122.84536331892,
        "sw_long": -123.349952
    },
    "total_count_with_filters": 48,
    "unfiltered_count": 350,
    "extended_count": 24,
    "unfiltered_primary_count": 350,
    "search_radius": 25,
    "sort": [
        {
            "id": "distance",
            "name": "Distance From Downtown"
        },
        {
            "id": "popularity",
            "name": "Popularity"
        },
        {
            "name": "Stars (5 to 0)",
            "id": "class_descending"
        },
        {
            "id": "class_ascending",
            "name": "Stars (0 to 5)"
        },
        {
            "id": "bayesian_review_score",
            "name": "Guest Review Score"
        },
        {
            "name": "Price (low to high)",
            "id": "price"
        }
    ],
    "result": [
        {
            "url": "https://www.booking.com/hotel/ca/blue-horizon-hotel.html",
            "preferred_plus": 0,
            "native_ads_tracking": "",
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "unit_type_id": 4,
                    "localized_area": null
                }
            },
            "is_city_center": 0,
            "soldout": 0,
            "class": 4,
            "review_score_word": "Excellent",
            "accommodation_type_name": "Hotel",
            "distances": [
                {
                    "text": "‎West End‬ • ‎650 m from center‬",
                    "icon_set": null,
                    "icon_name": "bui_geo_pin"
                }
            ],
            "city_trans": "Vancouver",
            "id": "property_card_360169",
            "min_total_price": 339,
            "review_score": 8.6,
            "bwallet": {
                "hotel_eligibility": 0
            },
            "has_swimming_pool": 1,
            "badges": [],
            "is_genius_deal": 0,
            "hotel_has_vb_boost": 0,
            "ufi": -575268,
            "price_is_final": 0,
            "class_is_estimated": 0,
            "distance_to_cc": "0.65",
            "default_language": "en",
            "extended": 0,
            "district": "West End",
            "selected_review_topic": null,
            "longitude": -123.127425760031,
            "native_ads_cpc": 0,
            "is_beach_front": 0,
            "hotel_id": 360169,
            "zip": "V6E 1C3",
            "hotel_facilities": "458,187,63,111,465,451,433,460,3,253,444,490,449,456,185,219,103,200,198,488,467,182,421,76,459,8,454,203,48,446,301,23,80,448,107,441,11,16,484,211,462,218,435,426,499,452,440,135,15,51,445,2,163,453,22,101,49,305,108,420,419,464,47,436,20,425,121,439,188,457,195,81,181,422,28,423,450,160,442,109,64,241,25,186,455,6,461,443,496,468,418,124,91,96",
            "district_id": 1552,
            "countrycode": "ca",
            "in_best_district": 0,
            "latitude": 49.2863952416855,
            "address": "1225 Robson Street",
            "genius_discount_percentage": 0,
            "updated_checkin": null,
            "hotel_name_trans": "Blue Horizon Hotel",
            "is_free_cancellable": 1,
            "hotel_include_breakfast": 0,
            "is_mobile_deal": 0,
            "composite_price_breakdown": {
                "included_taxes_and_charges_amount": {
                    "currency": "CAD",
                    "value": 0
                },
                "items": [
                    {
                        "base": {
                            "percentage": 20.7,
                            "kind": "percentage"
                        },
                        "details": "20.70 % TAX",
                        "kind": "charge",
                        "name": "TAX",
                        "inclusion_type": "excluded",
                        "item_amount": {
                            "value": 70.173,
                            "currency": "CAD"
                        }
                    }
                ],
                "product_price_breakdowns": [
                    {
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "items": [
                            {
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 70.173
                                },
                                "name": "TAX",
                                "inclusion_type": "excluded",
                                "kind": "charge",
                                "details": "20.70 % TAX",
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 20.7
                                }
                            }
                        ],
                        "benefits": [],
                        "net_amount": {
                            "value": 339,
                            "currency": "CAD"
                        },
                        "excluded_amount": {
                            "currency": "CAD",
                            "value": 70.173
                        },
                        "charges_details": {
                            "amount": {
                                "currency": "CAD",
                                "value": 70
                            },
                            "translated_copy": "+CAD 70.17 taxes and charges",
                            "mode": "extra_charges"
                        },
                        "gross_amount_hotel_currency": {
                            "value": 339,
                            "currency": "CAD"
                        },
                        "gross_amount": {
                            "value": 339,
                            "currency": "CAD"
                        },
                        "all_inclusive_amount": {
                            "value": 409.173,
                            "currency": "CAD"
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 339
                        }
                    }
                ],
                "excluded_amount": {
                    "value": 70.173,
                    "currency": "CAD"
                },
                "net_amount": {
                    "value": 339,
                    "currency": "CAD"
                },
                "benefits": [],
                "all_inclusive_amount": {
                    "currency": "CAD",
                    "value": 409.173
                },
                "gross_amount_hotel_currency": {
                    "currency": "CAD",
                    "value": 339
                },
                "gross_amount": {
                    "currency": "CAD",
                    "value": 339
                },
                "gross_amount_per_night": {
                    "value": 339,
                    "currency": "CAD"
                },
                "charges_details": {
                    "amount": {
                        "value": 70,
                        "currency": "CAD"
                    },
                    "translated_copy": "+CAD 70.17 taxes and charges",
                    "mode": "extra_charges"
                }
            },
            "hotel_name": "Blue Horizon Hotel",
            "city_name_en": "Vancouver (British Columbia)",
            "price_breakdown": {
                "sum_excluded_raw": 70.17,
                "has_fine_print_charges": 1,
                "has_tax_exceptions": 0,
                "currency": "CAD",
                "all_inclusive_price": 409.17,
                "gross_price": "339.00",
                "has_incalculable_charges": 0
            },
            "main_photo_id": 130939401,
            "cc1": "ca",
            "mobile_discount_percentage": 0,
            "review_nr": 7441,
            "city": "Vancouver (British Columbia)",
            "review_recommendation": "",
            "is_smart_deal": 0,
            "districts": "1552,1629",
            "unit_configuration_label": "<b>Hotel room</b>: 2 beds",
            "currency_code": "CAD",
            "children_not_allowed": 0,
            "native_ad_id": "",
            "is_no_prepayment_block": 1,
            "cant_book": 0,
            "preferred": 0,
            "block_ids": [
                "36016902_117516947_2_0_0"
            ],
            "timezone": "America/Vancouver",
            "type": "property_card",
            "default_wishlist_name": "Vancouver",
            "country_trans": "Canada",
            "distance": "0.63",
            "is_geo_rate": "",
            "checkin": {
                "until": "",
                "from": "15:00"
            },
            "distance_to_cc_formatted": "650 m",
            "checkout": {
                "until": "11:00",
                "from": ""
            },
            "wishlist_count": 0,
            "currencycode": "CAD",
            "updated_checkout": null,
            "cc_required": 1,
            "address_trans": "1225 Robson Street",
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/130939401.jpg?k=231e561cd00efa83d2509059557d00d45c19b706e89bfbcadb068471923cedb7&o=",
            "accommodation_type": 204,
            "city_in_trans": "in Vancouver",
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/130939401.jpg?k=231e561cd00efa83d2509059557d00d45c19b706e89bfbcadb068471923cedb7&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/130939401.jpg?k=231e561cd00efa83d2509059557d00d45c19b706e89bfbcadb068471923cedb7&o="
        },
        {
            "accommodation_type": 204,
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/88496364.jpg?k=dcfea7afa48de124c62dffb7f7c6bdbb9716be609eaae366c55d76d48b3c6bb6&o=",
            "city_in_trans": "in Vancouver",
            "cc_required": 1,
            "address_trans": "567 Hornby Street",
            "updated_checkout": null,
            "distance_to_cc_formatted": "0",
            "wishlist_count": 0,
            "checkout": {
                "until": "12:00",
                "from": ""
            },
            "currencycode": "CAD",
            "is_geo_rate": "",
            "default_wishlist_name": "Vancouver",
            "country_trans": "Canada",
            "distance": "0.38",
            "checkin": {
                "until": "",
                "from": "16:00"
            },
            "block_ids": [
                "7620302_350798656_2_2_0"
            ],
            "timezone": "America/Vancouver",
            "type": "property_card",
            "native_ad_id": "",
            "currency_code": "CAD",
            "children_not_allowed": 0,
            "preferred": 1,
            "is_no_prepayment_block": 0,
            "cant_book": 0,
            "city": "Vancouver (British Columbia)",
            "mobile_discount_percentage": 41.85,
            "review_nr": 2837,
            "districts": "1629",
            "unit_configuration_label": "<b>Hotel room</b>: 2 beds",
            "review_recommendation": "",
            "is_smart_deal": 0,
            "city_name_en": "Vancouver (British Columbia)",
            "hotel_name": "Hotel Le Soleil by Executive Hotels",
            "composite_price_breakdown": {
                "strikethrough_amount": {
                    "currency": "CAD",
                    "value": 418.5
                },
                "product_price_breakdowns": [
                    {
                        "strikethrough_amount_per_night": {
                            "currency": "CAD",
                            "value": 418.5
                        },
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "charges_details": {
                            "amount": {
                                "value": 78,
                                "currency": "CAD"
                            },
                            "mode": "extra_charges",
                            "translated_copy": "+CAD 77.85 taxes and charges"
                        },
                        "gross_amount": {
                            "currency": "CAD",
                            "value": 376.65
                        },
                        "discounted_amount": {
                            "currency": "CAD",
                            "value": 41.85
                        },
                        "excluded_amount": {
                            "currency": "CAD",
                            "value": 77.853555
                        },
                        "items": [
                            {
                                "name": "TAX",
                                "inclusion_type": "excluded",
                                "item_amount": {
                                    "value": 77.853555,
                                    "currency": "CAD"
                                },
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 20.67
                                },
                                "details": "20.67 % TAX",
                                "kind": "charge"
                            },
                            {
                                "identifier": "mobile-discount",
                                "item_amount": {
                                    "value": 41.85,
                                    "currency": "CAD"
                                },
                                "name": "Mobile-only price",
                                "kind": "discount",
                                "details": "You're getting a reduced rate because you're using a mobile device.",
                                "base": {
                                    "kind": "rate"
                                }
                            }
                        ],
                        "strikethrough_amount": {
                            "currency": "CAD",
                            "value": 418.5
                        },
                        "gross_amount_hotel_currency": {
                            "currency": "CAD",
                            "value": 376.65
                        },
                        "gross_amount_per_night": {
                            "value": 376.65,
                            "currency": "CAD"
                        },
                        "all_inclusive_amount": {
                            "currency": "CAD",
                            "value": 454.503555
                        },
                        "net_amount": {
                            "value": 376.65,
                            "currency": "CAD"
                        },
                        "benefits": [
                            {
                                "details": "You’re getting a reduced rate because this property is offering a discount when you book from a mobile device.",
                                "kind": "badge",
                                "icon": null,
                                "badge_variant": "constructive",
                                "name": "Mobile-only price",
                                "identifier": "mobile-rate"
                            }
                        ]
                    }
                ],
                "items": [
                    {
                        "details": "20.67 % TAX",
                        "kind": "charge",
                        "base": {
                            "kind": "percentage",
                            "percentage": 20.67
                        },
                        "item_amount": {
                            "currency": "CAD",
                            "value": 77.853555
                        },
                        "name": "TAX",
                        "inclusion_type": "excluded"
                    },
                    {
                        "details": "You're getting a reduced rate because you're using a mobile device.",
                        "kind": "discount",
                        "base": {
                            "kind": "rate"
                        },
                        "identifier": "mobile-discount",
                        "item_amount": {
                            "currency": "CAD",
                            "value": 41.85
                        },
                        "name": "Mobile-only price"
                    }
                ],
                "gross_amount_per_night": {
                    "value": 376.65,
                    "currency": "CAD"
                },
                "gross_amount_hotel_currency": {
                    "currency": "CAD",
                    "value": 376.65
                },
                "all_inclusive_amount": {
                    "value": 454.503555,
                    "currency": "CAD"
                },
                "net_amount": {
                    "currency": "CAD",
                    "value": 376.65
                },
                "benefits": [
                    {
                        "identifier": "mobile-rate",
                        "icon": null,
                        "badge_variant": "constructive",
                        "name": "Mobile-only price",
                        "kind": "badge",
                        "details": "You’re getting a reduced rate because this property is offering a discount when you book from a mobile device."
                    }
                ],
                "strikethrough_amount_per_night": {
                    "currency": "CAD",
                    "value": 418.5
                },
                "included_taxes_and_charges_amount": {
                    "currency": "CAD",
                    "value": 0
                },
                "gross_amount": {
                    "value": 376.65,
                    "currency": "CAD"
                },
                "discounted_amount": {
                    "currency": "CAD",
                    "value": 41.85
                },
                "charges_details": {
                    "amount": {
                        "value": 78,
                        "currency": "CAD"
                    },
                    "translated_copy": "+CAD 77.85 taxes and charges",
                    "mode": "extra_charges"
                },
                "excluded_amount": {
                    "currency": "CAD",
                    "value": 77.853555
                }
            },
            "main_photo_id": 88496364,
            "cc1": "ca",
            "price_breakdown": {
                "all_inclusive_price": 454.5,
                "currency": "CAD",
                "gross_price": 376.65,
                "has_incalculable_charges": 0,
                "has_fine_print_charges": 1,
                "sum_excluded_raw": 77.85,
                "has_tax_exceptions": 0
            },
            "hotel_name_trans": "Hotel Le Soleil by Executive Hotels",
            "is_free_cancellable": 1,
            "updated_checkin": null,
            "hotel_include_breakfast": 0,
            "is_mobile_deal": 1,
            "in_best_district": 0,
            "countrycode": "ca",
            "address": "567 Hornby Street",
            "latitude": 49.285183665494,
            "genius_discount_percentage": 0,
            "longitude": -123.118112459779,
            "is_beach_front": 0,
            "hotel_id": 76203,
            "zip": "V6C 2E8",
            "hotel_facilities": "499,78,440,51,2,445,163,483,101,447,22,49,305,420,108,400,419,500,159,436,47,482,425,20,439,7,457,485,422,181,81,423,478,450,109,442,160,25,455,511,461,6,418,468,496,443,124,91,96,158,424,465,161,486,497,451,5,477,4,3,52,475,490,449,456,495,467,182,421,459,492,203,454,437,8,48,446,501,80,448,506,23,16,107,489,218,426",
            "district_id": 1629,
            "native_ads_cpc": 0,
            "distance_to_cc": "0.40",
            "extended": 0,
            "district": "Downtown Vancouver",
            "selected_review_topic": null,
            "default_language": "en",
            "hotel_has_vb_boost": 0,
            "ufi": -575268,
            "class_is_estimated": 0,
            "price_is_final": 0,
            "badges": [
                {
                    "badge_variant": "constructive",
                    "text": "Mobile-only price",
                    "id": "Mobile Rate"
                }
            ],
            "is_genius_deal": 0,
            "id": "property_card_76203",
            "distances": [
                {
                    "text": "‎In city center‬",
                    "icon_name": "bui_geo_pin",
                    "icon_set": null
                }
            ],
            "city_trans": "Vancouver",
            "bwallet": {
                "hotel_eligibility": 0
            },
            "min_total_price": 376.65,
            "review_score": 8.4,
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "unit_type_id": 13,
                    "localized_area": null
                }
            },
            "url": "https://www.booking.com/hotel/ca/lesoleil.html",
            "native_ads_tracking": "",
            "preferred_plus": 0,
            "review_score_word": "Very Good",
            "accommodation_type_name": "Hotel",
            "class": 4,
            "is_city_center": 1,
            "soldout": 0,
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/88496364.jpg?k=dcfea7afa48de124c62dffb7f7c6bdbb9716be609eaae366c55d76d48b3c6bb6&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/88496364.jpg?k=dcfea7afa48de124c62dffb7f7c6bdbb9716be609eaae366c55d76d48b3c6bb6&o="
        },
        {
            "soldout": 0,
            "is_city_center": 1,
            "review_score_word": "Excellent",
            "accommodation_type_name": "Hotel",
            "class": 4,
            "url": "https://www.booking.com/hotel/ca/blu.html",
            "preferred_plus": 0,
            "native_ads_tracking": "",
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "localized_area": null,
                    "unit_type_id": 13
                }
            },
            "min_total_price": 404.1,
            "review_score": 8.9,
            "bwallet": {
                "hotel_eligibility": 0
            },
            "distances": [
                {
                    "text": "‎In city center‬",
                    "icon_set": null,
                    "icon_name": "bui_geo_pin"
                },
                {
                    "icon_set": null,
                    "icon_name": "bui_baby_cot",
                    "text": "‎This property has free cribs available‬"
                }
            ],
            "city_trans": "Vancouver",
            "id": "property_card_1040382",
            "is_genius_deal": 0,
            "has_swimming_pool": 1,
            "badges": [
                {
                    "id": "Mobile Rate",
                    "text": "Mobile-only price",
                    "badge_variant": "constructive"
                }
            ],
            "ufi": -575268,
            "class_is_estimated": 0,
            "price_is_final": 0,
            "hotel_has_vb_boost": 0,
            "default_language": "en",
            "extended": 0,
            "district": "Downtown Vancouver",
            "selected_review_topic": null,
            "distance_to_cc": "0.70",
            "native_ads_cpc": 0,
            "is_beach_front": 0,
            "hotel_id": 1040382,
            "zip": "V6E 1B2",
            "hotel_facilities": "464,419,20,425,121,47,436,505,49,22,447,108,420,305,445,2,15,51,453,163,440,494,78,452,499,184,6,461,455,158,124,96,91,443,496,468,442,160,109,43,53,450,241,502,28,423,195,457,491,10,439,81,422,181,485,466,103,456,200,488,467,495,27,52,220,449,444,490,4,433,5,3,253,460,504,63,118,458,451,486,161,465,127,484,426,462,218,435,463,506,448,80,441,107,11,16,301,446,493,48,182,8,454,222,459,492",
            "district_id": 1629,
            "longitude": -123.114671838879,
            "latitude": 49.2781410087963,
            "address": "177 Robson Street",
            "genius_discount_percentage": 0,
            "countrycode": "ca",
            "in_best_district": 0,
            "is_mobile_deal": 1,
            "hotel_include_breakfast": 0,
            "updated_checkin": null,
            "hotel_name_trans": "Hotel BLU",
            "is_free_cancellable": 1,
            "price_breakdown": {
                "sum_excluded_raw": 83.57,
                "has_fine_print_charges": 0,
                "has_tax_exceptions": 0,
                "all_inclusive_price": 487.67,
                "currency": "CAD",
                "gross_price": "404.10",
                "has_incalculable_charges": 0
            },
            "main_photo_id": 124969084,
            "cc1": "ca",
            "composite_price_breakdown": {
                "product_price_breakdowns": [
                    {
                        "strikethrough_amount": {
                            "value": 449,
                            "currency": "CAD"
                        },
                        "items": [
                            {
                                "name": "TAX",
                                "inclusion_type": "excluded",
                                "item_amount": {
                                    "value": 83.56788,
                                    "currency": "CAD"
                                },
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 20.68
                                },
                                "details": "20.68 % TAX",
                                "kind": "charge"
                            },
                            {
                                "details": "You're getting a reduced rate because you're using a mobile device.",
                                "kind": "discount",
                                "base": {
                                    "kind": "rate"
                                },
                                "identifier": "mobile-discount",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 44.9
                                },
                                "name": "Mobile-only price"
                            }
                        ],
                        "gross_amount_hotel_currency": {
                            "value": 404.1,
                            "currency": "CAD"
                        },
                        "all_inclusive_amount": {
                            "value": 487.66788,
                            "currency": "CAD"
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 404.1
                        },
                        "benefits": [
                            {
                                "name": "Mobile-only price",
                                "icon": null,
                                "badge_variant": "constructive",
                                "details": "You’re getting a reduced rate because this property is offering a discount when you book from a mobile device.",
                                "kind": "badge",
                                "identifier": "mobile-rate"
                            }
                        ],
                        "net_amount": {
                            "value": 404.1,
                            "currency": "CAD"
                        },
                        "strikethrough_amount_per_night": {
                            "currency": "CAD",
                            "value": 449
                        },
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "discounted_amount": {
                            "value": 44.9,
                            "currency": "CAD"
                        },
                        "gross_amount": {
                            "currency": "CAD",
                            "value": 404.1
                        },
                        "charges_details": {
                            "mode": "extra_charges",
                            "translated_copy": "+CAD 83.57 taxes and charges",
                            "amount": {
                                "value": 84,
                                "currency": "CAD"
                            }
                        },
                        "excluded_amount": {
                            "value": 83.56788,
                            "currency": "CAD"
                        }
                    }
                ],
                "strikethrough_amount": {
                    "value": 449,
                    "currency": "CAD"
                },
                "items": [
                    {
                        "details": "20.68 % TAX",
                        "kind": "charge",
                        "base": {
                            "percentage": 20.68,
                            "kind": "percentage"
                        },
                        "item_amount": {
                            "currency": "CAD",
                            "value": 83.56788
                        },
                        "inclusion_type": "excluded",
                        "name": "TAX"
                    },
                    {
                        "base": {
                            "kind": "rate"
                        },
                        "kind": "discount",
                        "details": "You're getting a reduced rate because you're using a mobile device.",
                        "name": "Mobile-only price",
                        "identifier": "mobile-discount",
                        "item_amount": {
                            "value": 44.9,
                            "currency": "CAD"
                        }
                    }
                ],
                "gross_amount_hotel_currency": {
                    "value": 404.1,
                    "currency": "CAD"
                },
                "all_inclusive_amount": {
                    "currency": "CAD",
                    "value": 487.66788
                },
                "gross_amount_per_night": {
                    "value": 404.1,
                    "currency": "CAD"
                },
                "benefits": [
                    {
                        "badge_variant": "constructive",
                        "icon": null,
                        "name": "Mobile-only price",
                        "kind": "badge",
                        "details": "You’re getting a reduced rate because this property is offering a discount when you book from a mobile device.",
                        "identifier": "mobile-rate"
                    }
                ],
                "net_amount": {
                    "value": 404.1,
                    "currency": "CAD"
                },
                "strikethrough_amount_per_night": {
                    "value": 449,
                    "currency": "CAD"
                },
                "included_taxes_and_charges_amount": {
                    "currency": "CAD",
                    "value": 0
                },
                "charges_details": {
                    "amount": {
                        "currency": "CAD",
                        "value": 84
                    },
                    "translated_copy": "+CAD 83.57 taxes and charges",
                    "mode": "extra_charges"
                },
                "gross_amount": {
                    "currency": "CAD",
                    "value": 404.1
                },
                "discounted_amount": {
                    "value": 44.9,
                    "currency": "CAD"
                },
                "excluded_amount": {
                    "value": 83.56788,
                    "currency": "CAD"
                }
            },
            "hotel_name": "Hotel BLU",
            "city_name_en": "Vancouver (British Columbia)",
            "review_recommendation": "",
            "is_smart_deal": 0,
            "districts": "1629",
            "unit_configuration_label": "<b>Hotel room</b>: 2 beds",
            "mobile_discount_percentage": 44.9,
            "review_nr": 2452,
            "city": "Vancouver (British Columbia)",
            "is_no_prepayment_block": 1,
            "cant_book": 0,
            "preferred": 1,
            "currency_code": "CAD",
            "children_not_allowed": 0,
            "native_ad_id": "",
            "type": "property_card",
            "block_ids": [
                "104038202_184402848_2_2_0"
            ],
            "timezone": "America/Vancouver",
            "checkin": {
                "until": "",
                "from": "16:00"
            },
            "default_wishlist_name": "Vancouver",
            "country_trans": "Canada",
            "distance": "0.67",
            "is_geo_rate": "",
            "currencycode": "CAD",
            "checkout": {
                "until": "12:00",
                "from": ""
            },
            "distance_to_cc_formatted": "0",
            "wishlist_count": 0,
            "updated_checkout": null,
            "cc_required": 1,
            "address_trans": "177 Robson Street",
            "city_in_trans": "in Vancouver",
            "accommodation_type": 204,
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/124969084.jpg?k=b68dd0843235045f934e116ac203751d588a2862a76f3b8520ed60917b9b3959&o=",
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/124969084.jpg?k=b68dd0843235045f934e116ac203751d588a2862a76f3b8520ed60917b9b3959&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/124969084.jpg?k=b68dd0843235045f934e116ac203751d588a2862a76f3b8520ed60917b9b3959&o="
        },
        {
            "longitude": -123.1088566,
            "hotel_id": 10553631,
            "is_beach_front": 0,
            "zip": "V6B 0J1",
            "district_id": 1629,
            "hotel_facilities": "96,479,121,47,163,474,2,4,433,46,14,103,48,108,107,195,160",
            "native_ads_cpc": 0,
            "distance_to_cc": "0.95",
            "district": "Downtown Vancouver",
            "extended": 0,
            "selected_review_topic": null,
            "default_language": "en",
            "hotel_name_trans": "Downtown 3br/2ba+Views+Skytrain+Free Parking",
            "is_free_cancellable": 1,
            "updated_checkin": null,
            "is_mobile_deal": 1,
            "hotel_include_breakfast": 0,
            "countrycode": "ca",
            "in_best_district": 0,
            "address": "188 Keefer Place 3306",
            "latitude": 49.2796404,
            "genius_discount_percentage": 0,
            "id": "property_card_10553631",
            "city_trans": "Vancouver",
            "distances": [
                {
                    "text": "‎In city center‬",
                    "icon_name": "bui_geo_pin",
                    "icon_set": null
                }
            ],
            "bwallet": {
                "hotel_eligibility": 0
            },
            "min_total_price": 540,
            "review_score": null,
            "booking_home": {
                "segment": 2,
                "is_single_unit_property": 1,
                "quality_class": 4,
                "is_booking_home": 1,
                "group": "apartment_like"
            },
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "unit_type_id": 1,
                    "localized_area": "88"
                }
            },
            "url": "https://www.booking.com/hotel/ca/downtown-3br-2ba-views-skytrain-free-parking.html",
            "preferred_plus": 0,
            "native_ads_tracking": "",
            "accommodation_type_name": "Apartment",
            "review_score_word": "Very Good",
            "class": 0,
            "soldout": 0,
            "is_city_center": 1,
            "hotel_has_vb_boost": 0,
            "ufi": -575268,
            "class_is_estimated": 0,
            "price_is_final": 0,
            "badges": [
                {
                    "id": "Mobile Rate",
                    "text": "Mobile-only price",
                    "badge_variant": "constructive"
                },
                {
                    "badge_variant": "accent",
                    "text": "New to Booking.com",
                    "id": "new_to_booking"
                }
            ],
            "has_swimming_pool": 1,
            "is_genius_deal": 0,
            "distance_to_cc_formatted": "0",
            "checkout": {
                "from": "",
                "until": "11:00"
            },
            "wishlist_count": 0,
            "currencycode": "CAD",
            "has_free_parking": 1,
            "is_geo_rate": "",
            "default_wishlist_name": "Vancouver",
            "country_trans": "Canada",
            "distance": "0.95",
            "checkin": {
                "from": "16:00",
                "until": ""
            },
            "accommodation_type": 201,
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/482086604.jpg?k=4830c7f24061f1041a979abda4ecda7deb55aa0e934cd0855902fc2b07ed434f&o=",
            "city_in_trans": "in Vancouver",
            "cc_required": 1,
            "address_trans": "188 Keefer Place 3306",
            "updated_checkout": null,
            "city": "Vancouver",
            "mobile_discount_percentage": 60,
            "review_nr": 2,
            "districts": "1629",
            "unit_configuration_label": "<b>Entire apartment – 88 m²</b>: 4 beds • 3 bedrooms • 1 living room • 2 bathrooms",
            "review_recommendation": "",
            "is_smart_deal": 0,
            "city_name_en": "Vancouver",
            "hotel_name": "Downtown 3br/2ba+Views+Skytrain+Free Parking",
            "composite_price_breakdown": {
                "excluded_amount": {
                    "value": 86.4,
                    "currency": "CAD"
                },
                "discounted_amount": {
                    "value": 60,
                    "currency": "CAD"
                },
                "gross_amount": {
                    "value": 540,
                    "currency": "CAD"
                },
                "charges_details": {
                    "mode": "extra_charges",
                    "translated_copy": "+CAD 86.40 taxes and charges",
                    "amount": {
                        "currency": "CAD",
                        "value": 86
                    }
                },
                "included_taxes_and_charges_amount": {
                    "currency": "CAD",
                    "value": 0
                },
                "strikethrough_amount_per_night": {
                    "value": 600,
                    "currency": "CAD"
                },
                "net_amount": {
                    "currency": "CAD",
                    "value": 540
                },
                "benefits": [
                    {
                        "identifier": "mobile-rate",
                        "name": "Mobile-only price",
                        "badge_variant": "constructive",
                        "icon": null,
                        "kind": "badge",
                        "details": "You’re getting a reduced rate because this property is offering a discount when you book from a mobile device."
                    }
                ],
                "gross_amount_per_night": {
                    "value": 540,
                    "currency": "CAD"
                },
                "gross_amount_hotel_currency": {
                    "value": 540,
                    "currency": "CAD"
                },
                "all_inclusive_amount": {
                    "currency": "CAD",
                    "value": 626.4
                },
                "strikethrough_amount": {
                    "currency": "CAD",
                    "value": 600
                },
                "product_price_breakdowns": [
                    {
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "strikethrough_amount_per_night": {
                            "currency": "CAD",
                            "value": 600
                        },
                        "excluded_amount": {
                            "value": 86.4,
                            "currency": "CAD"
                        },
                        "charges_details": {
                            "amount": {
                                "currency": "CAD",
                                "value": 86
                            },
                            "translated_copy": "+CAD 86.40 taxes and charges",
                            "mode": "extra_charges"
                        },
                        "discounted_amount": {
                            "currency": "CAD",
                            "value": 60
                        },
                        "gross_amount": {
                            "value": 540,
                            "currency": "CAD"
                        },
                        "items": [
                            {
                                "kind": "charge",
                                "details": "0 % City tax",
                                "base": {
                                    "percentage": 0,
                                    "kind": "percentage"
                                },
                                "item_amount": {
                                    "value": 0,
                                    "currency": "CAD"
                                },
                                "name": "City tax",
                                "inclusion_type": "included"
                            },
                            {
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 86.4
                                },
                                "name": "TAX",
                                "inclusion_type": "excluded",
                                "details": "16 % TAX",
                                "kind": "charge",
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 16
                                }
                            },
                            {
                                "base": {
                                    "kind": "rate"
                                },
                                "kind": "discount",
                                "details": "You're getting a reduced rate because you're using a mobile device.",
                                "name": "Mobile-only price",
                                "identifier": "mobile-discount",
                                "item_amount": {
                                    "value": 60,
                                    "currency": "CAD"
                                }
                            }
                        ],
                        "strikethrough_amount": {
                            "value": 600,
                            "currency": "CAD"
                        },
                        "benefits": [
                            {
                                "badge_variant": "constructive",
                                "icon": null,
                                "name": "Mobile-only price",
                                "kind": "badge",
                                "details": "You’re getting a reduced rate because this property is offering a discount when you book from a mobile device.",
                                "identifier": "mobile-rate"
                            }
                        ],
                        "net_amount": {
                            "value": 540,
                            "currency": "CAD"
                        },
                        "gross_amount_per_night": {
                            "value": 540,
                            "currency": "CAD"
                        },
                        "gross_amount_hotel_currency": {
                            "value": 540,
                            "currency": "CAD"
                        },
                        "all_inclusive_amount": {
                            "value": 626.4,
                            "currency": "CAD"
                        }
                    }
                ],
                "items": [
                    {
                        "name": "City tax",
                        "inclusion_type": "included",
                        "item_amount": {
                            "value": 0,
                            "currency": "CAD"
                        },
                        "base": {
                            "kind": "percentage",
                            "percentage": 0
                        },
                        "kind": "charge",
                        "details": "0 % City tax"
                    },
                    {
                        "item_amount": {
                            "value": 86.4,
                            "currency": "CAD"
                        },
                        "name": "TAX",
                        "inclusion_type": "excluded",
                        "details": "16 % TAX",
                        "kind": "charge",
                        "base": {
                            "kind": "percentage",
                            "percentage": 16
                        }
                    },
                    {
                        "details": "You're getting a reduced rate because you're using a mobile device.",
                        "kind": "discount",
                        "base": {
                            "kind": "rate"
                        },
                        "item_amount": {
                            "value": 60,
                            "currency": "CAD"
                        },
                        "identifier": "mobile-discount",
                        "name": "Mobile-only price"
                    }
                ]
            },
            "main_photo_id": 482086604,
            "cc1": "ca",
            "price_breakdown": {
                "has_fine_print_charges": 0,
                "sum_excluded_raw": "86.40",
                "has_tax_exceptions": 0,
                "currency": "CAD",
                "all_inclusive_price": 626.4,
                "gross_price": "540.00",
                "has_incalculable_charges": 0
            },
            "block_ids": [
                "1055363101_377722585_6_0_0"
            ],
            "timezone": "America/Vancouver",
            "type": "property_card",
            "native_ad_id": "",
            "currency_code": "CAD",
            "children_not_allowed": 0,
            "preferred": 0,
            "is_no_prepayment_block": 0,
            "cant_book": 0,
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/482086604.jpg?k=4830c7f24061f1041a979abda4ecda7deb55aa0e934cd0855902fc2b07ed434f&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/482086604.jpg?k=4830c7f24061f1041a979abda4ecda7deb55aa0e934cd0855902fc2b07ed434f&o="
        },
        {
            "review_nr": 3040,
            "mobile_discount_percentage": 45.28,
            "city": "Vancouver",
            "is_smart_deal": 0,
            "review_recommendation": "",
            "unit_configuration_label": "2 beds",
            "districts": "1629",
            "composite_price_breakdown": {
                "net_amount": {
                    "currency": "CAD",
                    "value": 407.52
                },
                "benefits": [
                    {
                        "kind": "badge",
                        "details": "You’re getting a reduced rate because this property is offering a discount when you book from a mobile device.",
                        "badge_variant": "constructive",
                        "icon": null,
                        "name": "Mobile-only price",
                        "identifier": "mobile-rate"
                    }
                ],
                "gross_amount_hotel_currency": {
                    "value": 407.52,
                    "currency": "CAD"
                },
                "all_inclusive_amount": {
                    "currency": "CAD",
                    "value": 491.754384
                },
                "gross_amount_per_night": {
                    "value": 407.52,
                    "currency": "CAD"
                },
                "product_price_breakdowns": [
                    {
                        "included_taxes_and_charges_amount": {
                            "value": 0,
                            "currency": "CAD"
                        },
                        "strikethrough_amount_per_night": {
                            "value": 566,
                            "currency": "CAD"
                        },
                        "excluded_amount": {
                            "currency": "CAD",
                            "value": 84.234384
                        },
                        "charges_details": {
                            "translated_copy": "+CAD 84.23 taxes and charges",
                            "mode": "extra_charges",
                            "amount": {
                                "currency": "CAD",
                                "value": 84
                            }
                        },
                        "discounted_amount": {
                            "value": 158.48,
                            "currency": "CAD"
                        },
                        "gross_amount": {
                            "currency": "CAD",
                            "value": 407.52
                        },
                        "items": [
                            {
                                "inclusion_type": "excluded",
                                "name": "TAX",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 84.234384
                                },
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 20.67
                                },
                                "details": "20.67 % TAX",
                                "kind": "charge"
                            },
                            {
                                "identifier": "basic-deal",
                                "item_amount": {
                                    "value": 113.2,
                                    "currency": "CAD"
                                },
                                "name": "Bonus savings",
                                "kind": "discount",
                                "details": "You’re getting a reduced rate because this property is offering a discount.",
                                "base": {
                                    "kind": "rate"
                                }
                            },
                            {
                                "identifier": "mobile-discount",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 45.28
                                },
                                "name": "Mobile-only price",
                                "kind": "discount",
                                "details": "You're getting a reduced rate because you're using a mobile device.",
                                "base": {
                                    "kind": "rate"
                                }
                            }
                        ],
                        "strikethrough_amount": {
                            "value": 566,
                            "currency": "CAD"
                        },
                        "net_amount": {
                            "currency": "CAD",
                            "value": 407.52
                        },
                        "benefits": [
                            {
                                "name": "Mobile-only price",
                                "icon": null,
                                "badge_variant": "constructive",
                                "kind": "badge",
                                "details": "You’re getting a reduced rate because this property is offering a discount when you book from a mobile device.",
                                "identifier": "mobile-rate"
                            }
                        ],
                        "gross_amount_hotel_currency": {
                            "currency": "CAD",
                            "value": 407.52
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 407.52
                        },
                        "all_inclusive_amount": {
                            "currency": "CAD",
                            "value": 491.754384
                        }
                    }
                ],
                "strikethrough_amount": {
                    "currency": "CAD",
                    "value": 566
                },
                "items": [
                    {
                        "kind": "charge",
                        "details": "20.67 % TAX",
                        "base": {
                            "percentage": 20.67,
                            "kind": "percentage"
                        },
                        "item_amount": {
                            "currency": "CAD",
                            "value": 84.234384
                        },
                        "name": "TAX",
                        "inclusion_type": "excluded"
                    },
                    {
                        "name": "Bonus savings",
                        "identifier": "basic-deal",
                        "item_amount": {
                            "value": 113.2,
                            "currency": "CAD"
                        },
                        "base": {
                            "kind": "rate"
                        },
                        "details": "You’re getting a reduced rate because this property is offering a discount.",
                        "kind": "discount"
                    },
                    {
                        "item_amount": {
                            "value": 45.28,
                            "currency": "CAD"
                        },
                        "identifier": "mobile-discount",
                        "name": "Mobile-only price",
                        "kind": "discount",
                        "details": "You're getting a reduced rate because you're using a mobile device.",
                        "base": {
                            "kind": "rate"
                        }
                    }
                ],
                "excluded_amount": {
                    "value": 84.234384,
                    "currency": "CAD"
                },
                "charges_details": {
                    "mode": "extra_charges",
                    "translated_copy": "+CAD 84.23 taxes and charges",
                    "amount": {
                        "currency": "CAD",
                        "value": 84
                    }
                },
                "gross_amount": {
                    "currency": "CAD",
                    "value": 407.52
                },
                "discounted_amount": {
                    "currency": "CAD",
                    "value": 158.48
                },
                "included_taxes_and_charges_amount": {
                    "currency": "CAD",
                    "value": 0
                },
                "strikethrough_amount_per_night": {
                    "value": 566,
                    "currency": "CAD"
                }
            },
            "hotel_name": "EXchange Hotel Vancouver",
            "city_name_en": "Vancouver",
            "price_breakdown": {
                "has_tax_exceptions": 0,
                "has_fine_print_charges": 0,
                "sum_excluded_raw": 84.23,
                "currency": "CAD",
                "all_inclusive_price": 491.75,
                "has_incalculable_charges": 0,
                "gross_price": 407.52
            },
            "cc1": "ca",
            "main_photo_id": 155014582,
            "timezone": "America/Vancouver",
            "block_ids": [
                "352555504_379909928_2_42_0"
            ],
            "type": "property_card",
            "children_not_allowed": 0,
            "currency_code": "CAD",
            "native_ad_id": "",
            "cant_book": 0,
            "is_no_prepayment_block": 0,
            "preferred": 1,
            "distance_to_cc_formatted": "0",
            "wishlist_count": 0,
            "checkout": {
                "from": "",
                "until": "12:00"
            },
            "currencycode": "CAD",
            "distance": "0.54",
            "default_wishlist_name": "Vancouver",
            "country_trans": "Canada",
            "is_geo_rate": "",
            "checkin": {
                "until": "",
                "from": "16:00"
            },
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/155014582.jpg?k=8346805dce2932afe5081827547b89efbf2f01a8b33b867255e3731e6ff02762&o=",
            "accommodation_type": 204,
            "city_in_trans": "in Vancouver",
            "updated_checkout": null,
            "address_trans": "475 Howe Street",
            "cc_required": 1,
            "distances": [
                {
                    "icon_set": null,
                    "icon_name": "bui_geo_pin",
                    "text": "‎In city center‬"
                },
                {
                    "icon_set": null,
                    "icon_name": "bui_baby_cot",
                    "text": "‎This property has free cribs available‬"
                }
            ],
            "city_trans": "Vancouver",
            "id": "property_card_3525555",
            "review_score": 8.9,
            "min_total_price": 407.52,
            "bwallet": {
                "hotel_eligibility": 0
            },
            "native_ads_tracking": "",
            "preferred_plus": 0,
            "url": "https://www.booking.com/hotel/ca/exchange-vancouver.html",
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "localized_area": null,
                    "unit_type_id": 4
                }
            },
            "is_city_center": 1,
            "soldout": 0,
            "accommodation_type_name": "Hotel",
            "review_score_word": "Excellent",
            "class": 4,
            "hotel_has_vb_boost": 0,
            "price_is_final": 0,
            "class_is_estimated": 0,
            "ufi": -575268,
            "badges": [
                {
                    "badge_variant": "constructive",
                    "id": "Mobile Rate",
                    "text": "Mobile-only price"
                }
            ],
            "is_genius_deal": 0,
            "longitude": -123.115714734718,
            "native_ads_cpc": 0,
            "zip": "V6B 2B3",
            "hotel_facilities": "51,2,445,163,474,453,499,452,78,440,494,184,419,500,464,47,436,425,482,217,101,483,49,305,420,108,423,502,478,439,188,7,457,466,181,422,485,81,186,455,6,461,468,418,443,487,124,91,96,158,450,53,160,442,43,109,64,25,477,5,4,460,253,3,424,458,187,161,111,451,486,219,456,185,470,495,467,488,475,52,490,449,48,472,446,493,501,182,421,459,492,454,8,484,211,218,462,506,80,448,463,23,16,11,107",
            "district_id": 1629,
            "hotel_id": 3525555,
            "is_beach_front": 0,
            "distance_to_cc": "0.55",
            "default_language": "xu",
            "selected_review_topic": null,
            "district": "Downtown Vancouver",
            "extended": 0,
            "updated_checkin": null,
            "is_free_cancellable": 1,
            "hotel_name_trans": "EXchange Hotel Vancouver",
            "is_mobile_deal": 1,
            "hotel_include_breakfast": 0,
            "countrycode": "ca",
            "in_best_district": 0,
            "genius_discount_percentage": 0,
            "latitude": 49.2857058832292,
            "address": "475 Howe Street",
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/155014582.jpg?k=8346805dce2932afe5081827547b89efbf2f01a8b33b867255e3731e6ff02762&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/155014582.jpg?k=8346805dce2932afe5081827547b89efbf2f01a8b33b867255e3731e6ff02762&o="
        },
        {
            "distance_to_cc": "0.75",
            "default_language": "en",
            "selected_review_topic": null,
            "district": "Downtown Vancouver",
            "extended": 0,
            "longitude": -123.113472908735,
            "native_ads_cpc": 0,
            "district_id": 1629,
            "zip": "V6B 2M4",
            "hotel_facilities": "25,53,450,43,109,442,160,110,496,443,468,418,158,124,96,91,455,461,6,81,485,422,181,10,439,457,7,28,423,305,108,420,22,447,101,49,436,47,20,217,425,464,184,78,499,440,163,453,51,407,445,2,441,107,11,16,23,463,242,80,448,462,435,218,426,459,492,437,8,203,454,421,48,446,490,449,495,488,467,456,198,465,161,44,54,451,458,424,240,63,176,460,410,3,253,4",
            "is_beach_front": 0,
            "hotel_id": 76302,
            "in_best_district": 0,
            "countrycode": "ca",
            "genius_discount_percentage": 0,
            "address": "773 Beatty Street",
            "latitude": 49.2780999415998,
            "updated_checkin": null,
            "is_free_cancellable": 1,
            "hotel_name_trans": "Georgian Court Hotel, WorldHotels Elite",
            "hotel_include_breakfast": 0,
            "is_mobile_deal": 0,
            "preferred_plus": 0,
            "native_ads_tracking": "",
            "url": "https://www.booking.com/hotel/ca/the-georgian-court.html",
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "unit_type_id": 4,
                    "localized_area": null
                }
            },
            "soldout": 0,
            "is_city_center": 1,
            "accommodation_type_name": "Hotel",
            "review_score_word": "Excellent",
            "class": 4,
            "city_trans": "Vancouver",
            "distances": [
                {
                    "text": "‎In city center‬",
                    "icon_name": "bui_geo_pin",
                    "icon_set": null
                },
                {
                    "text": "‎This property has free cribs available‬",
                    "icon_set": null,
                    "icon_name": "bui_baby_cot"
                }
            ],
            "id": "property_card_76302",
            "review_score": 8.6,
            "min_total_price": 429,
            "bwallet": {
                "hotel_eligibility": 0
            },
            "badges": [],
            "is_genius_deal": 0,
            "hotel_has_vb_boost": 0,
            "price_is_final": 0,
            "class_is_estimated": 0,
            "ufi": -575268,
            "distance": "0.74",
            "default_wishlist_name": "Vancouver",
            "country_trans": "Canada",
            "is_geo_rate": "",
            "checkin": {
                "until": "",
                "from": "16:00"
            },
            "checkout": {
                "until": "12:00",
                "from": ""
            },
            "distance_to_cc_formatted": "0",
            "wishlist_count": 0,
            "currencycode": "CAD",
            "updated_checkout": null,
            "address_trans": "773 Beatty Street",
            "cc_required": 1,
            "accommodation_type": 204,
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/165980764.jpg?k=05f285610d933b7af394b30b8c4b142d602f8d00ef9cacc617259d791184d2a7&o=",
            "city_in_trans": "in Vancouver",
            "hotel_name": "Georgian Court Hotel, WorldHotels Elite",
            "composite_price_breakdown": {
                "included_taxes_and_charges_amount": {
                    "currency": "CAD",
                    "value": 0
                },
                "product_price_breakdowns": [
                    {
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "items": [
                            {
                                "kind": "charge",
                                "details": "20.68 % TAX",
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 20.68
                                },
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 88.7172
                                },
                                "name": "TAX",
                                "inclusion_type": "excluded"
                            }
                        ],
                        "benefits": [],
                        "net_amount": {
                            "currency": "CAD",
                            "value": 429
                        },
                        "excluded_amount": {
                            "currency": "CAD",
                            "value": 88.7172
                        },
                        "charges_details": {
                            "mode": "extra_charges",
                            "translated_copy": "+CAD 88.72 taxes and charges",
                            "amount": {
                                "currency": "CAD",
                                "value": 89
                            }
                        },
                        "gross_amount_hotel_currency": {
                            "value": 429,
                            "currency": "CAD"
                        },
                        "all_inclusive_amount": {
                            "currency": "CAD",
                            "value": 517.7172
                        },
                        "gross_amount": {
                            "value": 429,
                            "currency": "CAD"
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 429
                        }
                    }
                ],
                "items": [
                    {
                        "name": "TAX",
                        "inclusion_type": "excluded",
                        "item_amount": {
                            "value": 88.7172,
                            "currency": "CAD"
                        },
                        "base": {
                            "kind": "percentage",
                            "percentage": 20.68
                        },
                        "details": "20.68 % TAX",
                        "kind": "charge"
                    }
                ],
                "excluded_amount": {
                    "value": 88.7172,
                    "currency": "CAD"
                },
                "benefits": [],
                "net_amount": {
                    "value": 429,
                    "currency": "CAD"
                },
                "gross_amount_hotel_currency": {
                    "currency": "CAD",
                    "value": 429
                },
                "gross_amount_per_night": {
                    "currency": "CAD",
                    "value": 429
                },
                "all_inclusive_amount": {
                    "currency": "CAD",
                    "value": 517.7172
                },
                "gross_amount": {
                    "value": 429,
                    "currency": "CAD"
                },
                "charges_details": {
                    "translated_copy": "+CAD 88.72 taxes and charges",
                    "mode": "extra_charges",
                    "amount": {
                        "currency": "CAD",
                        "value": 89
                    }
                }
            },
            "city_name_en": "Vancouver (British Columbia)",
            "price_breakdown": {
                "sum_excluded_raw": 88.72,
                "has_fine_print_charges": 1,
                "has_tax_exceptions": 0,
                "gross_price": "429.00",
                "has_incalculable_charges": 0,
                "currency": "CAD",
                "all_inclusive_price": 517.72
            },
            "cc1": "ca",
            "main_photo_id": 165980764,
            "review_nr": 1419,
            "mobile_discount_percentage": 0,
            "city": "Vancouver (British Columbia)",
            "is_smart_deal": 0,
            "review_recommendation": "",
            "unit_configuration_label": "<b>Hotel room</b>: 2 beds",
            "districts": "1629",
            "children_not_allowed": 0,
            "currency_code": "CAD",
            "native_ad_id": "",
            "cant_book": 0,
            "is_no_prepayment_block": 0,
            "preferred": 1,
            "timezone": "America/Vancouver",
            "block_ids": [
                "7630215_377176094_2_0_0"
            ],
            "type": "property_card",
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/165980764.jpg?k=05f285610d933b7af394b30b8c4b142d602f8d00ef9cacc617259d791184d2a7&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/165980764.jpg?k=05f285610d933b7af394b30b8c4b142d602f8d00ef9cacc617259d791184d2a7&o="
        },
        {
            "distance_to_cc": "0.60",
            "district": "Downtown Vancouver",
            "extended": 0,
            "selected_review_topic": null,
            "default_language": "en",
            "longitude": -123.124575912952,
            "is_beach_front": 0,
            "hotel_id": 79520,
            "zip": "V6Z 2B6",
            "district_id": 1629,
            "hotel_facilities": "20,425,217,467,495,47,436,464,500,456,185,219,108,420,449,305,444,490,205,3,453,253,163,176,445,4,2,51,5,451,184,438,465,494,135,78,424,158,124,91,96,462,496,218,468,6,461,186,455,484,25,107,133,11,16,160,110,109,80,53,450,501,493,28,48,423,8,437,81,181,422,203,485,492,459,498,457,136,7,439,182",
            "native_ads_cpc": 0,
            "countrycode": "ca",
            "in_best_district": 0,
            "address": "1100 Granville Street",
            "latitude": 49.2779092085248,
            "genius_discount_percentage": 0,
            "hotel_name_trans": "Best Western Premier Chateau Granville Hotel & Suites & Conference Centre",
            "is_free_cancellable": 1,
            "updated_checkin": null,
            "hotel_include_breakfast": 0,
            "is_mobile_deal": 0,
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "localized_area": null,
                    "unit_type_id": 13
                }
            },
            "url": "https://www.booking.com/hotel/ca/best-western-vancouver-chateau-granville.html",
            "preferred_plus": 0,
            "native_ads_tracking": "",
            "class": 4,
            "review_score_word": "Good",
            "accommodation_type_name": "Hotel",
            "is_city_center": 1,
            "soldout": 0,
            "id": "property_card_79520",
            "distances": [
                {
                    "icon_set": null,
                    "icon_name": "bui_geo_pin",
                    "text": "‎In city center‬"
                }
            ],
            "city_trans": "Vancouver",
            "bwallet": {
                "hotel_eligibility": 0
            },
            "min_total_price": 387.6,
            "review_score": 7.9,
            "badges": [
                {
                    "badge_variant": "constructive",
                    "id": "Getaway 2021 Deals",
                    "text": "Getaway Deal "
                }
            ],
            "is_genius_deal": 0,
            "hotel_has_vb_boost": 0,
            "ufi": -575268,
            "class_is_estimated": 0,
            "price_is_final": 0,
            "is_geo_rate": "",
            "country_trans": "Canada",
            "default_wishlist_name": "Vancouver",
            "distance": "0.56",
            "checkin": {
                "until": "",
                "from": "16:00"
            },
            "checkout": {
                "from": "",
                "until": "11:00"
            },
            "distance_to_cc_formatted": "0",
            "wishlist_count": 0,
            "currencycode": "CAD",
            "cc_required": 1,
            "address_trans": "1100 Granville Street",
            "updated_checkout": null,
            "accommodation_type": 204,
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/461631181.jpg?k=5209bf671d9e1f82fc35603a0abe792c96aaca97ff65c8adf6a1956494ac3c23&o=",
            "city_in_trans": "in Vancouver",
            "city_name_en": "Vancouver (British Columbia)",
            "composite_price_breakdown": {
                "excluded_amount": {
                    "value": 74.34168,
                    "currency": "CAD"
                },
                "net_amount": {
                    "value": 387.6,
                    "currency": "CAD"
                },
                "benefits": [
                    {
                        "badge_variant": "constructive",
                        "icon": null,
                        "name": "Getaway Deal ",
                        "details": "You’re getting a reduced rate because this property is offering a discount on stays between April 4 and September 28, 2023.",
                        "kind": "badge",
                        "identifier": "getaway-2021"
                    }
                ],
                "gross_amount_hotel_currency": {
                    "currency": "CAD",
                    "value": 387.6
                },
                "all_inclusive_amount": {
                    "currency": "CAD",
                    "value": 461.94168
                },
                "gross_amount_per_night": {
                    "currency": "CAD",
                    "value": 387.6
                },
                "gross_amount": {
                    "value": 387.6,
                    "currency": "CAD"
                },
                "charges_details": {
                    "amount": {
                        "value": 74,
                        "currency": "CAD"
                    },
                    "mode": "extra_charges",
                    "translated_copy": "+CAD 74.34 taxes and charges"
                },
                "included_taxes_and_charges_amount": {
                    "value": 0,
                    "currency": "CAD"
                },
                "items": [
                    {
                        "base": {
                            "kind": "percentage",
                            "percentage": 19.18
                        },
                        "kind": "charge",
                        "details": "19.18 % TAX",
                        "inclusion_type": "excluded",
                        "name": "TAX",
                        "item_amount": {
                            "currency": "CAD",
                            "value": 74.34168
                        }
                    }
                ],
                "product_price_breakdowns": [
                    {
                        "items": [
                            {
                                "details": "19.18 % TAX",
                                "kind": "charge",
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 19.18
                                },
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 74.34168
                                },
                                "inclusion_type": "excluded",
                                "name": "TAX"
                            }
                        ],
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "charges_details": {
                            "amount": {
                                "value": 74,
                                "currency": "CAD"
                            },
                            "translated_copy": "+CAD 74.34 taxes and charges",
                            "mode": "extra_charges"
                        },
                        "all_inclusive_amount": {
                            "value": 461.94168,
                            "currency": "CAD"
                        },
                        "gross_amount_hotel_currency": {
                            "value": 387.6,
                            "currency": "CAD"
                        },
                        "gross_amount": {
                            "currency": "CAD",
                            "value": 387.6
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 387.6
                        },
                        "net_amount": {
                            "currency": "CAD",
                            "value": 387.6
                        },
                        "benefits": [
                            {
                                "details": "You’re getting a reduced rate because this property is offering a discount on stays between April 4 and September 28, 2023.",
                                "kind": "badge",
                                "name": "Getaway Deal ",
                                "badge_variant": "constructive",
                                "icon": null,
                                "identifier": "getaway-2021"
                            }
                        ],
                        "excluded_amount": {
                            "value": 74.34168,
                            "currency": "CAD"
                        }
                    }
                ]
            },
            "hotel_name": "Best Western Premier Chateau Granville Hotel & Suites & Conference Centre",
            "main_photo_id": 461631181,
            "cc1": "ca",
            "price_breakdown": {
                "has_fine_print_charges": 0,
                "sum_excluded_raw": 74.34,
                "has_tax_exceptions": 0,
                "all_inclusive_price": 461.94,
                "currency": "CAD",
                "gross_price": "387.60",
                "has_incalculable_charges": 0
            },
            "city": "Vancouver (British Columbia)",
            "mobile_discount_percentage": 0,
            "review_nr": 781,
            "districts": "1629",
            "unit_configuration_label": "<b>Hotel room</b>: 3 beds",
            "review_recommendation": "",
            "is_smart_deal": 0,
            "native_ad_id": "",
            "currency_code": "CAD",
            "children_not_allowed": 0,
            "preferred": 0,
            "is_no_prepayment_block": 1,
            "cant_book": 0,
            "block_ids": [
                "7952024_338216315_2_42_0"
            ],
            "timezone": "America/Vancouver",
            "type": "property_card",
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/461631181.jpg?k=5209bf671d9e1f82fc35603a0abe792c96aaca97ff65c8adf6a1956494ac3c23&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/461631181.jpg?k=5209bf671d9e1f82fc35603a0abe792c96aaca97ff65c8adf6a1956494ac3c23&o="
        },
        {
            "class": 4,
            "review_score_word": "Very Good",
            "accommodation_type_name": "Hotel",
            "soldout": 0,
            "is_city_center": 0,
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "localized_area": null,
                    "unit_type_id": 9
                }
            },
            "preferred_plus": 0,
            "native_ads_tracking": "",
            "url": "https://www.booking.com/hotel/ca/l-vancouver-british-columbia.html",
            "bwallet": {
                "hotel_eligibility": 0
            },
            "review_score": 8.3,
            "min_total_price": 363.69,
            "id": "property_card_76260",
            "distances": [
                {
                    "icon_set": null,
                    "icon_name": "bui_geo_pin",
                    "text": "‎West End‬ • ‎800 m from center‬"
                }
            ],
            "city_trans": "Vancouver",
            "is_genius_deal": 0,
            "badges": [
                {
                    "id": "Mobile Rate",
                    "text": "Mobile-only price",
                    "badge_variant": "constructive"
                }
            ],
            "class_is_estimated": 0,
            "price_is_final": 0,
            "ufi": -575268,
            "hotel_has_vb_boost": 0,
            "selected_review_topic": null,
            "extended": 0,
            "district": "West End",
            "default_language": "en",
            "distance_to_cc": "0.80",
            "zip": "V6E 1C5",
            "hotel_facilities": "423,502,439,7,491,457,498,503,485,181,422,81,455,511,461,6,468,418,487,496,443,124,96,158,450,53,109,110,442,160,51,2,407,445,163,499,78,494,440,184,400,419,436,47,217,425,20,101,22,49,505,420,108,48,493,472,446,182,421,459,492,203,454,437,8,484,127,489,435,218,462,426,448,80,506,23,16,11,441,107,4,504,460,253,3,424,458,44,465,161,497,486,451,456,495,467,488,52,490,444,449",
            "district_id": 1552,
            "hotel_id": 76260,
            "is_beach_front": 0,
            "native_ads_cpc": 0,
            "longitude": -123.129220157862,
            "genius_discount_percentage": 0,
            "latitude": 49.2872918763764,
            "address": "1300 Robson Street",
            "countrycode": "ca",
            "in_best_district": 0,
            "hotel_include_breakfast": 0,
            "is_mobile_deal": 1,
            "is_free_cancellable": 1,
            "hotel_name_trans": "The Listel Hotel Vancouver",
            "updated_checkin": null,
            "cc1": "ca",
            "main_photo_id": 288078719,
            "price_breakdown": {
                "has_incalculable_charges": 0,
                "gross_price": 363.69,
                "currency": "CAD",
                "all_inclusive_price": 438.9,
                "has_tax_exceptions": 0,
                "sum_excluded_raw": 75.21,
                "has_fine_print_charges": 0
            },
            "city_name_en": "Vancouver (British Columbia)",
            "composite_price_breakdown": {
                "product_price_breakdowns": [
                    {
                        "items": [
                            {
                                "name": "TAX",
                                "inclusion_type": "excluded",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 75.211092
                                },
                                "base": {
                                    "percentage": 20.68,
                                    "kind": "percentage"
                                },
                                "details": "20.68 % TAX",
                                "kind": "charge"
                            },
                            {
                                "details": "You’re getting a reduced rate because this property is offering a discount.",
                                "kind": "discount",
                                "base": {
                                    "kind": "rate"
                                },
                                "identifier": "basic-deal",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 44.9
                                },
                                "name": "Bonus savings"
                            },
                            {
                                "details": "You're getting a reduced rate because you're using a mobile device.",
                                "kind": "discount",
                                "base": {
                                    "kind": "rate"
                                },
                                "identifier": "mobile-discount",
                                "item_amount": {
                                    "value": 40.41,
                                    "currency": "CAD"
                                },
                                "name": "Mobile-only price"
                            }
                        ],
                        "strikethrough_amount": {
                            "currency": "CAD",
                            "value": 449
                        },
                        "gross_amount_hotel_currency": {
                            "value": 363.69,
                            "currency": "CAD"
                        },
                        "all_inclusive_amount": {
                            "value": 438.901092,
                            "currency": "CAD"
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 363.69
                        },
                        "benefits": [
                            {
                                "identifier": "mobile-rate",
                                "name": "Mobile-only price",
                                "icon": null,
                                "badge_variant": "constructive",
                                "kind": "badge",
                                "details": "You’re getting a reduced rate because this property is offering a discount when you book from a mobile device."
                            }
                        ],
                        "net_amount": {
                            "currency": "CAD",
                            "value": 363.69
                        },
                        "strikethrough_amount_per_night": {
                            "currency": "CAD",
                            "value": 449
                        },
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "discounted_amount": {
                            "value": 85.31,
                            "currency": "CAD"
                        },
                        "gross_amount": {
                            "value": 363.69,
                            "currency": "CAD"
                        },
                        "charges_details": {
                            "amount": {
                                "currency": "CAD",
                                "value": 75
                            },
                            "mode": "extra_charges",
                            "translated_copy": "+CAD 75.21 taxes and charges"
                        },
                        "excluded_amount": {
                            "value": 75.211092,
                            "currency": "CAD"
                        }
                    }
                ],
                "strikethrough_amount": {
                    "value": 449,
                    "currency": "CAD"
                },
                "items": [
                    {
                        "base": {
                            "percentage": 20.68,
                            "kind": "percentage"
                        },
                        "details": "20.68 % TAX",
                        "kind": "charge",
                        "name": "TAX",
                        "inclusion_type": "excluded",
                        "item_amount": {
                            "currency": "CAD",
                            "value": 75.211092
                        }
                    },
                    {
                        "base": {
                            "kind": "rate"
                        },
                        "kind": "discount",
                        "details": "You’re getting a reduced rate because this property is offering a discount.",
                        "name": "Bonus savings",
                        "item_amount": {
                            "value": 44.9,
                            "currency": "CAD"
                        },
                        "identifier": "basic-deal"
                    },
                    {
                        "identifier": "mobile-discount",
                        "item_amount": {
                            "currency": "CAD",
                            "value": 40.41
                        },
                        "name": "Mobile-only price",
                        "kind": "discount",
                        "details": "You're getting a reduced rate because you're using a mobile device.",
                        "base": {
                            "kind": "rate"
                        }
                    }
                ],
                "net_amount": {
                    "currency": "CAD",
                    "value": 363.69
                },
                "benefits": [
                    {
                        "kind": "badge",
                        "details": "You’re getting a reduced rate because this property is offering a discount when you book from a mobile device.",
                        "icon": null,
                        "badge_variant": "constructive",
                        "name": "Mobile-only price",
                        "identifier": "mobile-rate"
                    }
                ],
                "gross_amount_per_night": {
                    "value": 363.69,
                    "currency": "CAD"
                },
                "gross_amount_hotel_currency": {
                    "currency": "CAD",
                    "value": 363.69
                },
                "all_inclusive_amount": {
                    "value": 438.901092,
                    "currency": "CAD"
                },
                "included_taxes_and_charges_amount": {
                    "value": 0,
                    "currency": "CAD"
                },
                "strikethrough_amount_per_night": {
                    "value": 449,
                    "currency": "CAD"
                },
                "excluded_amount": {
                    "value": 75.211092,
                    "currency": "CAD"
                },
                "charges_details": {
                    "translated_copy": "+CAD 75.21 taxes and charges",
                    "mode": "extra_charges",
                    "amount": {
                        "value": 75,
                        "currency": "CAD"
                    }
                },
                "discounted_amount": {
                    "currency": "CAD",
                    "value": 85.31
                },
                "gross_amount": {
                    "currency": "CAD",
                    "value": 363.69
                }
            },
            "hotel_name": "The Listel Hotel Vancouver",
            "unit_configuration_label": "<b>Hotel room</b>: 2 beds",
            "districts": "1552,1629",
            "is_smart_deal": 0,
            "review_recommendation": "",
            "city": "Vancouver (British Columbia)",
            "review_nr": 2156,
            "mobile_discount_percentage": 40.41,
            "preferred": 1,
            "cant_book": 0,
            "is_no_prepayment_block": 0,
            "native_ad_id": "",
            "children_not_allowed": 0,
            "currency_code": "CAD",
            "type": "property_card",
            "timezone": "America/Vancouver",
            "block_ids": [
                "7626014_377598479_2_0_0"
            ],
            "checkin": {
                "until": "",
                "from": "15:00"
            },
            "is_geo_rate": "",
            "distance": "0.80",
            "default_wishlist_name": "Vancouver",
            "country_trans": "Canada",
            "currencycode": "CAD",
            "distance_to_cc_formatted": "800 m",
            "checkout": {
                "until": "12:00",
                "from": ""
            },
            "wishlist_count": 0,
            "address_trans": "1300 Robson Street",
            "cc_required": 1,
            "updated_checkout": null,
            "city_in_trans": "in Vancouver",
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/288078719.jpg?k=4655d20931f83840b0a1d7be52bcebf7bd743a7a4b1430af35c094484f65aef9&o=",
            "accommodation_type": 204,
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/288078719.jpg?k=4655d20931f83840b0a1d7be52bcebf7bd743a7a4b1430af35c094484f65aef9&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/288078719.jpg?k=4655d20931f83840b0a1d7be52bcebf7bd743a7a4b1430af35c094484f65aef9&o="
        },
        {
            "district_id": 1629,
            "zip": "V6B 6K4",
            "hotel_facilities": "439,421,491,7,76,492,459,8,81,422,203,485,48,423,446,472,493,450,160,442,463,23,109,80,448,107,16,133,455,489,6,127,461,443,496,468,435,158,91,124,96,78,424,440,111,465,51,445,407,4,2,163,460,3,447,505,305,444,490,108,420,470,495,47,436,488,20,425",
            "hotel_id": 76200,
            "is_beach_front": 0,
            "native_ads_cpc": 0,
            "longitude": -123.122702389956,
            "selected_review_topic": null,
            "extended": 0,
            "district": "Downtown Vancouver",
            "default_language": "en",
            "distance_to_cc": "0.45",
            "is_mobile_deal": 0,
            "hotel_include_breakfast": 0,
            "is_free_cancellable": 1,
            "hotel_name_trans": "Hotel Belmont Vancouver MGallery",
            "updated_checkin": null,
            "genius_discount_percentage": 0,
            "latitude": 49.2789455451786,
            "address": "654 Nelson Street",
            "countrycode": "ca",
            "in_best_district": 0,
            "bwallet": {
                "hotel_eligibility": 0
            },
            "review_score": 8.1,
            "min_total_price": 419,
            "id": "property_card_76200",
            "city_trans": "Vancouver",
            "distances": [
                {
                    "icon_set": null,
                    "icon_name": "bui_geo_pin",
                    "text": "‎In city center‬"
                }
            ],
            "review_score_word": "Very Good",
            "accommodation_type_name": "Hotel",
            "class": 4,
            "soldout": 0,
            "is_city_center": 1,
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "localized_area": null,
                    "unit_type_id": 4
                }
            },
            "preferred_plus": 0,
            "native_ads_tracking": "",
            "url": "https://www.booking.com/hotel/ca/hotel-belmont-vancouver-mgallery.html",
            "class_is_estimated": 0,
            "price_is_final": 0,
            "ufi": -575268,
            "hotel_has_vb_boost": 0,
            "is_genius_deal": 0,
            "badges": [],
            "currencycode": "CAD",
            "checkout": {
                "until": "11:00",
                "from": ""
            },
            "distance_to_cc_formatted": "0",
            "wishlist_count": 0,
            "checkin": {
                "until": "",
                "from": "15:00"
            },
            "is_geo_rate": "",
            "distance": "0.40",
            "country_trans": "Canada",
            "default_wishlist_name": "Vancouver",
            "city_in_trans": "in Vancouver",
            "accommodation_type": 204,
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/332211312.jpg?k=3e47d00a5b925bc5a9f724019455459c24a190ae23abf4bfd12eb00b0ad7d491&o=",
            "cc_required": 1,
            "address_trans": "654 Nelson Street",
            "updated_checkout": null,
            "unit_configuration_label": "<b>Hotel room</b>: 2 beds",
            "districts": "1629",
            "is_smart_deal": 0,
            "review_recommendation": "",
            "city": "Vancouver (British Columbia)",
            "review_nr": 1350,
            "mobile_discount_percentage": 0,
            "cc1": "ca",
            "main_photo_id": 332211312,
            "price_breakdown": {
                "all_inclusive_price": 510.21,
                "currency": "CAD",
                "gross_price": "419.00",
                "has_incalculable_charges": 0,
                "sum_excluded_raw": 91.21,
                "has_fine_print_charges": 1,
                "has_tax_exceptions": 0
            },
            "city_name_en": "Vancouver (British Columbia)",
            "composite_price_breakdown": {
                "product_price_breakdowns": [
                    {
                        "excluded_amount": {
                            "currency": "CAD",
                            "value": 91.2092
                        },
                        "net_amount": {
                            "value": 419,
                            "currency": "CAD"
                        },
                        "benefits": [],
                        "gross_amount_hotel_currency": {
                            "currency": "CAD",
                            "value": 419
                        },
                        "all_inclusive_amount": {
                            "value": 510.2092,
                            "currency": "CAD"
                        },
                        "gross_amount": {
                            "value": 419,
                            "currency": "CAD"
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 419
                        },
                        "charges_details": {
                            "amount": {
                                "value": 91,
                                "currency": "CAD"
                            },
                            "mode": "extra_charges",
                            "translated_copy": "+CAD 91.21 taxes and charges"
                        },
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "items": [
                            {
                                "name": "TAX",
                                "inclusion_type": "excluded",
                                "item_amount": {
                                    "value": 86.6492,
                                    "currency": "CAD"
                                },
                                "base": {
                                    "percentage": 20.68,
                                    "kind": "percentage"
                                },
                                "kind": "charge",
                                "details": "20.68 % TAX"
                            },
                            {
                                "base": {
                                    "base_amount": 4.56,
                                    "kind": "per_night"
                                },
                                "kind": "charge",
                                "details": null,
                                "inclusion_type": "excluded",
                                "name": "Service charge",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 4.56
                                }
                            }
                        ]
                    }
                ],
                "items": [
                    {
                        "kind": "charge",
                        "details": "20.68 % TAX",
                        "base": {
                            "percentage": 20.68,
                            "kind": "percentage"
                        },
                        "item_amount": {
                            "currency": "CAD",
                            "value": 86.6492
                        },
                        "inclusion_type": "excluded",
                        "name": "TAX"
                    },
                    {
                        "inclusion_type": "excluded",
                        "name": "Service charge",
                        "item_amount": {
                            "currency": "CAD",
                            "value": 4.56
                        },
                        "base": {
                            "base_amount": 4.56,
                            "kind": "per_night"
                        },
                        "details": null,
                        "kind": "charge"
                    }
                ],
                "included_taxes_and_charges_amount": {
                    "value": 0,
                    "currency": "CAD"
                },
                "gross_amount_hotel_currency": {
                    "currency": "CAD",
                    "value": 419
                },
                "all_inclusive_amount": {
                    "value": 510.2092,
                    "currency": "CAD"
                },
                "gross_amount": {
                    "currency": "CAD",
                    "value": 419
                },
                "gross_amount_per_night": {
                    "currency": "CAD",
                    "value": 419
                },
                "charges_details": {
                    "mode": "extra_charges",
                    "translated_copy": "+CAD 91.21 taxes and charges",
                    "amount": {
                        "currency": "CAD",
                        "value": 91
                    }
                },
                "excluded_amount": {
                    "currency": "CAD",
                    "value": 91.2092
                },
                "net_amount": {
                    "currency": "CAD",
                    "value": 419
                },
                "benefits": []
            },
            "hotel_name": "Hotel Belmont Vancouver MGallery",
            "type": "property_card",
            "timezone": "America/Vancouver",
            "block_ids": [
                "7620037_175185962_2_2_0"
            ],
            "preferred": 1,
            "cant_book": 0,
            "is_no_prepayment_block": 1,
            "native_ad_id": "",
            "children_not_allowed": 0,
            "currency_code": "CAD",
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/332211312.jpg?k=3e47d00a5b925bc5a9f724019455459c24a190ae23abf4bfd12eb00b0ad7d491&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/332211312.jpg?k=3e47d00a5b925bc5a9f724019455459c24a190ae23abf4bfd12eb00b0ad7d491&o="
        },
        {
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "unit_type_id": 9,
                    "localized_area": null
                }
            },
            "preferred_plus": 0,
            "native_ads_tracking": "",
            "url": "https://www.booking.com/hotel/ca/sheraton-vancouver-wall-centre.html",
            "review_score_word": "Very Good",
            "accommodation_type_name": "Hotel",
            "class": 4,
            "soldout": 0,
            "is_city_center": 1,
            "id": "property_card_191090",
            "city_trans": "Vancouver",
            "distances": [
                {
                    "text": "‎In city center‬",
                    "icon_name": "bui_geo_pin",
                    "icon_set": null
                },
                {
                    "text": "‎This property has free cribs available‬",
                    "icon_name": "bui_baby_cot",
                    "icon_set": null
                }
            ],
            "bwallet": {
                "hotel_eligibility": 0
            },
            "review_score": 8.4,
            "min_total_price": 459,
            "badges": [],
            "has_swimming_pool": 1,
            "is_genius_deal": 0,
            "hotel_has_vb_boost": 0,
            "class_is_estimated": 0,
            "price_is_final": 0,
            "ufi": -575268,
            "distance_to_cc": "0.50",
            "selected_review_topic": null,
            "district": "Downtown Vancouver",
            "extended": 0,
            "default_language": "en",
            "longitude": -123.126847743988,
            "zip": "V6Z 2R9",
            "hotel_facilities": "502,423,28,12,7,195,457,439,188,181,422,81,14,6,461,455,186,91,124,96,158,418,468,443,160,213,109,43,450,25,2,445,407,453,163,494,452,65,184,464,425,121,20,47,436,505,49,447,22,420,108,305,301,493,48,257,454,203,8,459,492,218,435,462,506,80,448,463,23,11,16,441,107,4,433,5,253,3,176,460,142,63,187,143,424,451,486,111,103,456,219,185,467,488,134,27,52,205,449,490",
            "district_id": 1629,
            "is_beach_front": 0,
            "hotel_id": 191090,
            "native_ads_cpc": 0,
            "countrycode": "ca",
            "in_best_district": 0,
            "genius_discount_percentage": 0,
            "latitude": 49.2801104683195,
            "address": "1000 Burrard Street",
            "is_free_cancellable": 1,
            "hotel_name_trans": "Sheraton Vancouver Wall Centre",
            "updated_checkin": null,
            "is_mobile_deal": 0,
            "hotel_include_breakfast": 0,
            "city_name_en": "Vancouver (British Columbia)",
            "hotel_name": "Sheraton Vancouver Wall Centre",
            "composite_price_breakdown": {
                "product_price_breakdowns": [
                    {
                        "items": [
                            {
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 13.67
                                },
                                "kind": "charge",
                                "details": "13.67 % TAX",
                                "inclusion_type": "excluded",
                                "name": "TAX",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 62.7453
                                }
                            },
                            {
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 5.7834
                                },
                                "name": "Destination fee",
                                "inclusion_type": "excluded",
                                "details": "1.26 % Destination fee",
                                "kind": "charge",
                                "base": {
                                    "percentage": 1.26,
                                    "kind": "percentage"
                                }
                            },
                            {
                                "kind": "charge",
                                "details": "5.75 % Goods & services tax",
                                "base": {
                                    "percentage": 5.75,
                                    "kind": "percentage"
                                },
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 26.7250455
                                },
                                "name": "Goods & services tax",
                                "inclusion_type": "excluded"
                            }
                        ],
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "charges_details": {
                            "amount": {
                                "currency": "CAD",
                                "value": 95
                            },
                            "translated_copy": "+CAD 95.25 taxes and charges",
                            "mode": "extra_charges"
                        },
                        "all_inclusive_amount": {
                            "currency": "CAD",
                            "value": 554.2537455
                        },
                        "gross_amount_hotel_currency": {
                            "currency": "CAD",
                            "value": 459
                        },
                        "gross_amount": {
                            "currency": "CAD",
                            "value": 459
                        },
                        "gross_amount_per_night": {
                            "value": 459,
                            "currency": "CAD"
                        },
                        "benefits": [],
                        "net_amount": {
                            "currency": "CAD",
                            "value": 459
                        },
                        "excluded_amount": {
                            "value": 95.2537455,
                            "currency": "CAD"
                        }
                    }
                ],
                "items": [
                    {
                        "name": "TAX",
                        "inclusion_type": "excluded",
                        "item_amount": {
                            "currency": "CAD",
                            "value": 62.7453
                        },
                        "base": {
                            "kind": "percentage",
                            "percentage": 13.67
                        },
                        "kind": "charge",
                        "details": "13.67 % TAX"
                    },
                    {
                        "base": {
                            "percentage": 1.26,
                            "kind": "percentage"
                        },
                        "details": "1.26 % Destination fee",
                        "kind": "charge",
                        "name": "Destination fee",
                        "inclusion_type": "excluded",
                        "item_amount": {
                            "value": 5.7834,
                            "currency": "CAD"
                        }
                    },
                    {
                        "kind": "charge",
                        "details": "5.75 % Goods & services tax",
                        "base": {
                            "percentage": 5.75,
                            "kind": "percentage"
                        },
                        "item_amount": {
                            "value": 26.7250455,
                            "currency": "CAD"
                        },
                        "name": "Goods & services tax",
                        "inclusion_type": "excluded"
                    }
                ],
                "included_taxes_and_charges_amount": {
                    "currency": "CAD",
                    "value": 0
                },
                "all_inclusive_amount": {
                    "value": 554.2537455,
                    "currency": "CAD"
                },
                "gross_amount_hotel_currency": {
                    "value": 459,
                    "currency": "CAD"
                },
                "gross_amount_per_night": {
                    "currency": "CAD",
                    "value": 459
                },
                "gross_amount": {
                    "currency": "CAD",
                    "value": 459
                },
                "charges_details": {
                    "amount": {
                        "currency": "CAD",
                        "value": 95
                    },
                    "translated_copy": "+CAD 95.25 taxes and charges",
                    "mode": "extra_charges"
                },
                "excluded_amount": {
                    "value": 95.2537455,
                    "currency": "CAD"
                },
                "net_amount": {
                    "value": 459,
                    "currency": "CAD"
                },
                "benefits": []
            },
            "cc1": "ca",
            "main_photo_id": 463643039,
            "price_breakdown": {
                "currency": "CAD",
                "all_inclusive_price": 554.25,
                "gross_price": "459.00",
                "has_incalculable_charges": 0,
                "has_fine_print_charges": 1,
                "sum_excluded_raw": 95.25,
                "has_tax_exceptions": 0
            },
            "city": "Vancouver (British Columbia)",
            "review_nr": 1310,
            "mobile_discount_percentage": 0,
            "unit_configuration_label": "<b>Hotel room</b>: 2 beds",
            "districts": "1629",
            "is_smart_deal": 0,
            "review_recommendation": "",
            "native_ad_id": "",
            "children_not_allowed": 0,
            "currency_code": "CAD",
            "preferred": 1,
            "cant_book": 0,
            "is_no_prepayment_block": 1,
            "timezone": "America/Vancouver",
            "block_ids": [
                "19109033_343180711_2_42_0"
            ],
            "type": "property_card",
            "is_geo_rate": "",
            "distance": "0.48",
            "country_trans": "Canada",
            "default_wishlist_name": "Vancouver",
            "checkin": {
                "from": "15:00",
                "until": ""
            },
            "distance_to_cc_formatted": "0",
            "checkout": {
                "until": "12:00",
                "from": ""
            },
            "wishlist_count": 0,
            "currencycode": "CAD",
            "cc_required": 1,
            "address_trans": "1000 Burrard Street",
            "updated_checkout": null,
            "accommodation_type": 204,
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/463643039.jpg?k=554234234de8e309f9b83d4559b08800f64957f5a7b866e699166222803c4a2f&o=",
            "city_in_trans": "in Vancouver",
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/463643039.jpg?k=554234234de8e309f9b83d4559b08800f64957f5a7b866e699166222803c4a2f&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/463643039.jpg?k=554234234de8e309f9b83d4559b08800f64957f5a7b866e699166222803c4a2f&o="
        },
        {
            "address_trans": "1771 Comox Street",
            "cc_required": 1,
            "updated_checkout": null,
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/489450302.jpg?k=61f64da184ac71fb86a0fcb0944051b21ded889ab6a3faaf8eb3260f256dc017&o=",
            "accommodation_type": 219,
            "city_in_trans": "in Vancouver",
            "is_geo_rate": "",
            "country_trans": "Canada",
            "default_wishlist_name": "Vancouver",
            "distance": "1.44",
            "checkin": {
                "from": "16:00",
                "until": ""
            },
            "checkout": {
                "from": "",
                "until": "11:00"
            },
            "wishlist_count": 0,
            "currencycode": "CAD",
            "native_ad_id": "",
            "currency_code": "CAD",
            "children_not_allowed": 0,
            "preferred": 1,
            "is_no_prepayment_block": 0,
            "cant_book": 0,
            "block_ids": [
                "686360202_279081942_2_0_0",
                "686360203_279081942_2_0_0"
            ],
            "timezone": "America/Vancouver",
            "type": "property_card",
            "city_name_en": "Vancouver",
            "hotel_name": "Sonder at Revival",
            "composite_price_breakdown": {
                "strikethrough_amount_per_night": {
                    "currency": "CAD",
                    "value": 1061.58
                },
                "included_taxes_and_charges_amount": {
                    "currency": "CAD",
                    "value": 0
                },
                "gross_amount": {
                    "currency": "CAD",
                    "value": 955.43
                },
                "discounted_amount": {
                    "currency": "CAD",
                    "value": 106.15
                },
                "charges_details": {
                    "mode": "extra_charges",
                    "translated_copy": "+CAD 200.64 taxes and charges",
                    "amount": {
                        "value": 201,
                        "currency": "CAD"
                    }
                },
                "excluded_amount": {
                    "currency": "CAD",
                    "value": 200.6403
                },
                "items": [
                    {
                        "base": {
                            "percentage": 8,
                            "kind": "percentage"
                        },
                        "details": "8 % TAX",
                        "kind": "charge",
                        "name": "TAX",
                        "inclusion_type": "excluded",
                        "item_amount": {
                            "value": 76.4344,
                            "currency": "CAD"
                        }
                    },
                    {
                        "kind": "charge",
                        "details": "2.50 % City tax",
                        "base": {
                            "percentage": 2.5,
                            "kind": "percentage"
                        },
                        "item_amount": {
                            "currency": "CAD",
                            "value": 23.88575
                        },
                        "inclusion_type": "excluded",
                        "name": "City tax"
                    },
                    {
                        "base": {
                            "percentage": 5,
                            "kind": "percentage"
                        },
                        "kind": "charge",
                        "details": "5 % Goods & services tax",
                        "inclusion_type": "excluded",
                        "name": "Goods & services tax",
                        "item_amount": {
                            "currency": "CAD",
                            "value": 47.7715
                        }
                    },
                    {
                        "name": "Destination fee",
                        "inclusion_type": "excluded",
                        "item_amount": {
                            "currency": "CAD",
                            "value": 52.54865
                        },
                        "base": {
                            "kind": "percentage",
                            "percentage": 5.5
                        },
                        "details": "5.50 % Destination fee",
                        "kind": "charge"
                    },
                    {
                        "details": "You don't pay the cleaning fee thanks to Genius",
                        "kind": "charge",
                        "base": {
                            "base_amount": 0,
                            "kind": "per_stay"
                        },
                        "item_amount": {
                            "value": 0,
                            "currency": "CAD"
                        },
                        "inclusion_type": "excluded",
                        "name": "Cleaning fee"
                    },
                    {
                        "kind": "discount",
                        "details": "You're getting a reduced rate because you're using a mobile device.",
                        "base": {
                            "kind": "rate"
                        },
                        "item_amount": {
                            "currency": "CAD",
                            "value": 106.15
                        },
                        "identifier": "mobile-discount",
                        "name": "Mobile-only price"
                    }
                ],
                "strikethrough_amount": {
                    "value": 1061.58,
                    "currency": "CAD"
                },
                "product_price_breakdowns": [
                    {
                        "gross_amount": {
                            "currency": "CAD",
                            "value": 468.41
                        },
                        "discounted_amount": {
                            "currency": "CAD",
                            "value": 52.04
                        },
                        "charges_details": {
                            "translated_copy": "+CAD 98.37 taxes and charges",
                            "mode": "extra_charges",
                            "amount": {
                                "value": 98,
                                "currency": "CAD"
                            }
                        },
                        "excluded_amount": {
                            "currency": "CAD",
                            "value": 98.3661
                        },
                        "strikethrough_amount_per_night": {
                            "currency": "CAD",
                            "value": 520.45
                        },
                        "included_taxes_and_charges_amount": {
                            "value": 0,
                            "currency": "CAD"
                        },
                        "gross_amount_hotel_currency": {
                            "value": 468.41,
                            "currency": "CAD"
                        },
                        "all_inclusive_amount": {
                            "currency": "CAD",
                            "value": 566.7761
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 468.41
                        },
                        "benefits": [
                            {
                                "details": "You’re getting a reduced rate because this property is offering a discount when you book from a mobile device.",
                                "kind": "badge",
                                "badge_variant": "constructive",
                                "icon": null,
                                "name": "Mobile-only price",
                                "identifier": "mobile-rate"
                            }
                        ],
                        "net_amount": {
                            "value": 468.41,
                            "currency": "CAD"
                        },
                        "items": [
                            {
                                "base": {
                                    "percentage": 8,
                                    "kind": "percentage"
                                },
                                "details": "8 % TAX",
                                "kind": "charge",
                                "name": "TAX",
                                "inclusion_type": "excluded",
                                "item_amount": {
                                    "value": 37.4728,
                                    "currency": "CAD"
                                }
                            },
                            {
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 2.5
                                },
                                "details": "2.50 % City tax",
                                "kind": "charge",
                                "name": "City tax",
                                "inclusion_type": "excluded",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 11.71025
                                }
                            },
                            {
                                "details": "5 % Goods & services tax",
                                "kind": "charge",
                                "base": {
                                    "percentage": 5,
                                    "kind": "percentage"
                                },
                                "item_amount": {
                                    "value": 23.4205,
                                    "currency": "CAD"
                                },
                                "name": "Goods & services tax",
                                "inclusion_type": "excluded"
                            },
                            {
                                "inclusion_type": "excluded",
                                "name": "Cleaning fee",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 0
                                },
                                "base": {
                                    "kind": "per_stay",
                                    "base_amount": 0
                                },
                                "kind": "charge",
                                "details": "You don't pay the cleaning fee thanks to Genius"
                            },
                            {
                                "details": "5.50 % Destination fee",
                                "kind": "charge",
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 5.5
                                },
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 25.76255
                                },
                                "name": "Destination fee",
                                "inclusion_type": "excluded"
                            },
                            {
                                "base": {
                                    "kind": "rate"
                                },
                                "details": "You're getting a reduced rate because you're using a mobile device.",
                                "kind": "discount",
                                "name": "Mobile-only price",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 52.04
                                },
                                "identifier": "mobile-discount"
                            }
                        ],
                        "strikethrough_amount": {
                            "currency": "CAD",
                            "value": 520.45
                        }
                    },
                    {
                        "all_inclusive_amount": {
                            "value": 589.2942,
                            "currency": "CAD"
                        },
                        "gross_amount_hotel_currency": {
                            "value": 487.02,
                            "currency": "CAD"
                        },
                        "gross_amount_per_night": {
                            "value": 487.02,
                            "currency": "CAD"
                        },
                        "benefits": [
                            {
                                "name": "Mobile-only price",
                                "icon": null,
                                "badge_variant": "constructive",
                                "details": "You’re getting a reduced rate because this property is offering a discount when you book from a mobile device.",
                                "kind": "badge",
                                "identifier": "mobile-rate"
                            }
                        ],
                        "net_amount": {
                            "currency": "CAD",
                            "value": 487.02
                        },
                        "strikethrough_amount": {
                            "value": 541.13,
                            "currency": "CAD"
                        },
                        "items": [
                            {
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 38.9616
                                },
                                "name": "TAX",
                                "inclusion_type": "excluded",
                                "kind": "charge",
                                "details": "8 % TAX",
                                "base": {
                                    "percentage": 8,
                                    "kind": "percentage"
                                }
                            },
                            {
                                "details": "2.50 % City tax",
                                "kind": "charge",
                                "base": {
                                    "percentage": 2.5,
                                    "kind": "percentage"
                                },
                                "item_amount": {
                                    "value": 12.1755,
                                    "currency": "CAD"
                                },
                                "inclusion_type": "excluded",
                                "name": "City tax"
                            },
                            {
                                "kind": "charge",
                                "details": "You don't pay the cleaning fee thanks to Genius",
                                "base": {
                                    "base_amount": 0,
                                    "kind": "per_stay"
                                },
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 0
                                },
                                "name": "Cleaning fee",
                                "inclusion_type": "excluded"
                            },
                            {
                                "details": "5.50 % Destination fee",
                                "kind": "charge",
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 5.5
                                },
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 26.7861
                                },
                                "name": "Destination fee",
                                "inclusion_type": "excluded"
                            },
                            {
                                "base": {
                                    "percentage": 5,
                                    "kind": "percentage"
                                },
                                "kind": "charge",
                                "details": "5 % Goods & services tax",
                                "inclusion_type": "excluded",
                                "name": "Goods & services tax",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 24.351
                                }
                            },
                            {
                                "name": "Mobile-only price",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 54.11
                                },
                                "identifier": "mobile-discount",
                                "base": {
                                    "kind": "rate"
                                },
                                "kind": "discount",
                                "details": "You're getting a reduced rate because you're using a mobile device."
                            }
                        ],
                        "charges_details": {
                            "mode": "extra_charges",
                            "translated_copy": "+CAD 102.27 taxes and charges",
                            "amount": {
                                "value": 102,
                                "currency": "CAD"
                            }
                        },
                        "discounted_amount": {
                            "value": 54.11,
                            "currency": "CAD"
                        },
                        "gross_amount": {
                            "currency": "CAD",
                            "value": 487.02
                        },
                        "excluded_amount": {
                            "value": 102.2742,
                            "currency": "CAD"
                        },
                        "strikethrough_amount_per_night": {
                            "value": 541.13,
                            "currency": "CAD"
                        },
                        "included_taxes_and_charges_amount": {
                            "value": 0,
                            "currency": "CAD"
                        }
                    }
                ],
                "all_inclusive_amount": {
                    "value": 1156.0703,
                    "currency": "CAD"
                },
                "gross_amount_hotel_currency": {
                    "value": 955.43,
                    "currency": "CAD"
                },
                "gross_amount_per_night": {
                    "currency": "CAD",
                    "value": 955.43
                },
                "benefits": [
                    {
                        "identifier": "mobile-rate",
                        "name": "Mobile-only price",
                        "icon": null,
                        "badge_variant": "constructive",
                        "details": "You’re getting a reduced rate because this property is offering a discount when you book from a mobile device.",
                        "kind": "badge"
                    }
                ],
                "net_amount": {
                    "currency": "CAD",
                    "value": 955.43
                }
            },
            "main_photo_id": 489450302,
            "cc1": "ca",
            "price_breakdown": {
                "sum_excluded_raw": 200.64,
                "has_fine_print_charges": 0,
                "has_tax_exceptions": 0,
                "gross_price": 955.43,
                "has_incalculable_charges": 0,
                "all_inclusive_price": 1156.07,
                "currency": "CAD"
            },
            "city": "Vancouver",
            "mobile_discount_percentage": 52.04,
            "review_nr": 1105,
            "districts": "1552,1629",
            "unit_configuration_label": "<b>2 private rooms</b>: 2 beds",
            "review_recommendation": "",
            "is_smart_deal": 0,
            "in_best_district": 0,
            "countrycode": "ca",
            "latitude": 49.2884841,
            "address": "1771 Comox Street",
            "genius_discount_percentage": 0,
            "hotel_name_trans": "Sonder at Revival",
            "is_free_cancellable": 1,
            "updated_checkin": null,
            "is_mobile_deal": 1,
            "hotel_include_breakfast": 0,
            "distance_to_cc": "1.45",
            "extended": 1,
            "district": "West End",
            "selected_review_topic": null,
            "default_language": "en",
            "longitude": -123.1388017,
            "is_beach_front": 0,
            "hotel_id": 6863602,
            "hotel_facilities": "460,453,48,445,466,465,451,452,439,440,457,47,468,462,443,96,467,455,456,461,464,16,441,107,449,108,450,448,109,442",
            "zip": "V6G1P6",
            "district_id": 1552,
            "native_ads_cpc": 0,
            "badges": [
                {
                    "badge_variant": "constructive",
                    "text": "Mobile-only price",
                    "id": "Mobile Rate"
                }
            ],
            "is_genius_deal": 0,
            "hotel_has_vb_boost": 0,
            "ufi": -575268,
            "price_is_final": 1,
            "class_is_estimated": 0,
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "unit_type_id": 9,
                    "localized_area": null
                }
            },
            "url": "https://www.booking.com/hotel/ca/sonder-at-revival.html",
            "preferred_plus": 0,
            "native_ads_tracking": "",
            "review_score_word": "Excellent",
            "accommodation_type_name": "Condo Hotel",
            "class": 0,
            "is_city_center": 0,
            "soldout": 0,
            "external_reviews": {
                "score": 10,
                "score_word": "Exceptional",
                "num_reviews": 531,
                "should_display": ""
            },
            "id": "property_card_6863602",
            "city_trans": "Vancouver",
            "distances": [
                {
                    "text": "‎Vancouver‬ • ‎1.4 km from Vancouver‬",
                    "icon_set": null,
                    "icon_name": "bui_geo_pin"
                }
            ],
            "bwallet": {
                "hotel_eligibility": 0
            },
            "min_total_price": 955.43,
            "booking_home": {
                "is_single_unit_property": "",
                "segment": 1,
                "group": "apartment_like",
                "is_booking_home": 1,
                "quality_class": 4
            },
            "review_score": 8.9,
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/489450302.jpg?k=61f64da184ac71fb86a0fcb0944051b21ded889ab6a3faaf8eb3260f256dc017&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/489450302.jpg?k=61f64da184ac71fb86a0fcb0944051b21ded889ab6a3faaf8eb3260f256dc017&o="
        },
        {
            "type": "property_card",
            "block_ids": [
                "7647809_169688737_0_42_0",
                "7647801_169688737_0_42_0"
            ],
            "timezone": "America/Vancouver",
            "preferred": 1,
            "cant_book": 0,
            "is_no_prepayment_block": 1,
            "native_ad_id": "",
            "children_not_allowed": 0,
            "currency_code": "CAD",
            "unit_configuration_label": "<b>1 hotel room • 1 private studio</b>: 2 beds • 2 bedrooms • 2 bathrooms",
            "districts": "1629",
            "is_smart_deal": 0,
            "review_recommendation": "",
            "city": "Vancouver (British Columbia)",
            "review_nr": 994,
            "mobile_discount_percentage": 0,
            "cc1": "ca",
            "main_photo_id": 304499957,
            "price_breakdown": {
                "all_inclusive_price": 1072.62,
                "currency": "CAD",
                "gross_price": "900.00",
                "has_incalculable_charges": 0,
                "has_fine_print_charges": 1,
                "sum_excluded_raw": 172.62,
                "has_tax_exceptions": 0
            },
            "city_name_en": "Vancouver (British Columbia)",
            "composite_price_breakdown": {
                "included_taxes_and_charges_amount": {
                    "value": 0,
                    "currency": "CAD"
                },
                "items": [
                    {
                        "base": {
                            "kind": "percentage",
                            "percentage": 19.18
                        },
                        "details": "19.18 % TAX",
                        "kind": "charge",
                        "inclusion_type": "excluded",
                        "name": "TAX",
                        "item_amount": {
                            "value": 172.62,
                            "currency": "CAD"
                        }
                    }
                ],
                "product_price_breakdowns": [
                    {
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 425
                        },
                        "gross_amount_hotel_currency": {
                            "value": 425,
                            "currency": "CAD"
                        },
                        "all_inclusive_amount": {
                            "currency": "CAD",
                            "value": 506.515
                        },
                        "gross_amount": {
                            "value": 425,
                            "currency": "CAD"
                        },
                        "charges_details": {
                            "amount": {
                                "currency": "CAD",
                                "value": 82
                            },
                            "mode": "extra_charges",
                            "translated_copy": "+CAD 81.52 taxes and charges"
                        },
                        "excluded_amount": {
                            "value": 81.515,
                            "currency": "CAD"
                        },
                        "benefits": [],
                        "net_amount": {
                            "value": 425,
                            "currency": "CAD"
                        },
                        "items": [
                            {
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 81.515
                                },
                                "inclusion_type": "excluded",
                                "name": "TAX",
                                "kind": "charge",
                                "details": "19.18 % TAX",
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 19.18
                                }
                            }
                        ],
                        "included_taxes_and_charges_amount": {
                            "value": 0,
                            "currency": "CAD"
                        }
                    },
                    {
                        "charges_details": {
                            "mode": "extra_charges",
                            "translated_copy": "+CAD 91.11 taxes and charges",
                            "amount": {
                                "value": 91,
                                "currency": "CAD"
                            }
                        },
                        "gross_amount_hotel_currency": {
                            "currency": "CAD",
                            "value": 475
                        },
                        "all_inclusive_amount": {
                            "currency": "CAD",
                            "value": 566.105
                        },
                        "gross_amount": {
                            "currency": "CAD",
                            "value": 475
                        },
                        "gross_amount_per_night": {
                            "value": 475,
                            "currency": "CAD"
                        },
                        "net_amount": {
                            "value": 475,
                            "currency": "CAD"
                        },
                        "benefits": [],
                        "excluded_amount": {
                            "currency": "CAD",
                            "value": 91.105
                        },
                        "items": [
                            {
                                "base": {
                                    "percentage": 19.18,
                                    "kind": "percentage"
                                },
                                "kind": "charge",
                                "details": "19.18 % TAX",
                                "name": "TAX",
                                "inclusion_type": "excluded",
                                "item_amount": {
                                    "value": 91.105,
                                    "currency": "CAD"
                                }
                            }
                        ],
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        }
                    }
                ],
                "benefits": [],
                "net_amount": {
                    "currency": "CAD",
                    "value": 900
                },
                "excluded_amount": {
                    "currency": "CAD",
                    "value": 172.62
                },
                "charges_details": {
                    "amount": {
                        "value": 173,
                        "currency": "CAD"
                    },
                    "mode": "extra_charges",
                    "translated_copy": "+CAD 172.62 taxes and charges"
                },
                "gross_amount_hotel_currency": {
                    "currency": "CAD",
                    "value": 900
                },
                "all_inclusive_amount": {
                    "currency": "CAD",
                    "value": 1072.62
                },
                "gross_amount_per_night": {
                    "value": 900,
                    "currency": "CAD"
                },
                "gross_amount": {
                    "value": 900,
                    "currency": "CAD"
                }
            },
            "hotel_name": "L'Hermitage Hotel",
            "city_in_trans": "in Vancouver",
            "accommodation_type": 204,
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/304499957.jpg?k=6bd17003f1a4e3626e04c51856fa01ee9e747764bc93fecd84ab26301cae90e4&o=",
            "cc_required": 1,
            "address_trans": "788 Richards Street",
            "updated_checkout": null,
            "currencycode": "CAD",
            "checkout": {
                "from": "",
                "until": "12:00"
            },
            "wishlist_count": 0,
            "checkin": {
                "from": "16:00",
                "until": ""
            },
            "is_geo_rate": "",
            "distance": "0.37",
            "country_trans": "Canada",
            "default_wishlist_name": "Vancouver",
            "price_is_final": 0,
            "class_is_estimated": 0,
            "ufi": -575268,
            "hotel_has_vb_boost": 0,
            "is_genius_deal": 0,
            "badges": [],
            "has_swimming_pool": 1,
            "bwallet": {
                "hotel_eligibility": 0
            },
            "review_score": 9.2,
            "min_total_price": 900,
            "id": "property_card_76478",
            "distances": [
                {
                    "icon_set": null,
                    "icon_name": "bui_geo_pin",
                    "text": "‎Vancouver‬ • ‎350 m from Vancouver‬"
                }
            ],
            "city_trans": "Vancouver",
            "review_score_word": "Wonderful",
            "accommodation_type_name": "Hotel",
            "class": 4,
            "soldout": 0,
            "is_city_center": 1,
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "localized_area": null,
                    "unit_type_id": 9
                }
            },
            "native_ads_tracking": "",
            "preferred_plus": 0,
            "url": "https://www.booking.com/hotel/ca/hermitage.html",
            "is_mobile_deal": 0,
            "hotel_include_breakfast": 0,
            "is_free_cancellable": 1,
            "hotel_name_trans": "L'Hermitage Hotel",
            "updated_checkin": null,
            "genius_discount_percentage": 0,
            "address": "788 Richards Street",
            "latitude": 49.2804927915843,
            "countrycode": "ca",
            "in_best_district": 0,
            "zip": "V6B 3A4",
            "hotel_facilities": "159,464,198,456,488,20,425,467,495,47,436,52,49,22,108,420,220,449,305,490,445,2,433,21,15,51,5,3,453,253,163,196,63,452,424,184,451,161,465,461,14,511,455,484,158,96,124,91,462,218,468,160,23,109,43,80,104,450,25,107,241,16,11,301,58,502,48,423,457,195,7,439,182,8,81,422,454,222,485,119,203,170,492",
            "district_id": 1629,
            "hotel_id": 76478,
            "is_beach_front": 0,
            "native_ads_cpc": 0,
            "longitude": -123.117028176785,
            "selected_review_topic": null,
            "district": "Downtown Vancouver",
            "extended": 1,
            "default_language": "en",
            "distance_to_cc": "0.40",
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/304499957.jpg?k=6bd17003f1a4e3626e04c51856fa01ee9e747764bc93fecd84ab26301cae90e4&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/304499957.jpg?k=6bd17003f1a4e3626e04c51856fa01ee9e747764bc93fecd84ab26301cae90e4&o="
        },
        {
            "city_in_trans": "in Vancouver",
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/135434372.jpg?k=b36805fac0d6638e41ec820de0cf7c05144e885e931c745c82b945fc8af5a89f&o=",
            "accommodation_type": 204,
            "updated_checkout": null,
            "cc_required": 1,
            "address_trans": "602 Dunsmuir Street",
            "currencycode": "CAD",
            "checkout": {
                "until": "12:00",
                "from": ""
            },
            "distance_to_cc_formatted": "0",
            "wishlist_count": 0,
            "checkin": {
                "until": "",
                "from": "15:00"
            },
            "distance": "0.41",
            "country_trans": "Canada",
            "default_wishlist_name": "Vancouver",
            "is_geo_rate": "",
            "type": "property_card",
            "block_ids": [
                "7641804_174407459_2_1_0"
            ],
            "timezone": "America/Vancouver",
            "cant_book": 0,
            "is_no_prepayment_block": 1,
            "preferred": 1,
            "children_not_allowed": 0,
            "ribbon_text": "Breakfast included",
            "currency_code": "CAD",
            "native_ad_id": "",
            "is_smart_deal": 0,
            "review_recommendation": "",
            "unit_configuration_label": "<b>Hotel room</b>: 2 beds",
            "districts": "1629",
            "review_nr": 964,
            "mobile_discount_percentage": 0,
            "city": "Vancouver (British Columbia)",
            "price_breakdown": {
                "gross_price": "439.00",
                "has_incalculable_charges": 0,
                "all_inclusive_price": 518.02,
                "currency": "CAD",
                "has_fine_print_charges": 0,
                "sum_excluded_raw": 79.02,
                "has_tax_exceptions": 0
            },
            "cc1": "ca",
            "main_photo_id": 135434372,
            "composite_price_breakdown": {
                "charges_details": {
                    "amount": {
                        "value": 79,
                        "currency": "CAD"
                    },
                    "translated_copy": "+CAD 79.02 taxes and charges",
                    "mode": "extra_charges"
                },
                "gross_amount_per_night": {
                    "currency": "CAD",
                    "value": 439
                },
                "gross_amount_hotel_currency": {
                    "currency": "CAD",
                    "value": 439
                },
                "all_inclusive_amount": {
                    "currency": "CAD",
                    "value": 518.02
                },
                "gross_amount": {
                    "value": 439,
                    "currency": "CAD"
                },
                "benefits": [],
                "net_amount": {
                    "currency": "CAD",
                    "value": 439
                },
                "excluded_amount": {
                    "value": 79.02,
                    "currency": "CAD"
                },
                "items": [
                    {
                        "kind": "charge",
                        "details": "18 % TAX",
                        "base": {
                            "kind": "percentage",
                            "percentage": 18
                        },
                        "item_amount": {
                            "currency": "CAD",
                            "value": 79.02
                        },
                        "name": "TAX",
                        "inclusion_type": "excluded"
                    }
                ],
                "product_price_breakdowns": [
                    {
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "items": [
                            {
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 18
                                },
                                "details": "18 % TAX",
                                "kind": "charge",
                                "inclusion_type": "excluded",
                                "name": "TAX",
                                "item_amount": {
                                    "value": 79.02,
                                    "currency": "CAD"
                                }
                            }
                        ],
                        "net_amount": {
                            "currency": "CAD",
                            "value": 439
                        },
                        "benefits": [],
                        "excluded_amount": {
                            "currency": "CAD",
                            "value": 79.02
                        },
                        "charges_details": {
                            "amount": {
                                "value": 79,
                                "currency": "CAD"
                            },
                            "mode": "extra_charges",
                            "translated_copy": "+CAD 79.02 taxes and charges"
                        },
                        "gross_amount_hotel_currency": {
                            "currency": "CAD",
                            "value": 439
                        },
                        "all_inclusive_amount": {
                            "currency": "CAD",
                            "value": 518.02
                        },
                        "gross_amount": {
                            "value": 439,
                            "currency": "CAD"
                        },
                        "gross_amount_per_night": {
                            "value": 439,
                            "currency": "CAD"
                        }
                    }
                ],
                "included_taxes_and_charges_amount": {
                    "currency": "CAD",
                    "value": 0
                }
            },
            "hotel_name": "The St. Regis Hotel",
            "city_name_en": "Vancouver (British Columbia)",
            "is_mobile_deal": 0,
            "updated_checkin": null,
            "is_free_cancellable": 1,
            "hotel_name_trans": "The St. Regis Hotel",
            "genius_discount_percentage": 0,
            "address": "602 Dunsmuir Street",
            "latitude": 49.2830307479963,
            "countrycode": "ca",
            "in_best_district": 0,
            "native_ads_cpc": 0,
            "hotel_facilities": "407,445,2,5,51,3,504,143,494,440,499,424,497,486,184,451,465,161,20,467,425,495,436,47,505,108,449,305,444,493,446,28,48,423,491,7,439,182,421,437,81,8,203,485,422,454,181,459,492,498,503,461,6,489,455,158,91,426,496,443,435,468,418,109,442,448,80,506,53,450,25,16",
            "zip": "V6B 1Y6",
            "district_id": 1629,
            "hotel_id": 76418,
            "is_beach_front": 0,
            "longitude": -123.115676343441,
            "default_language": "en",
            "selected_review_topic": null,
            "extended": 0,
            "district": "Downtown Vancouver",
            "distance_to_cc": "0.45",
            "price_is_final": 0,
            "class_is_estimated": 0,
            "ufi": -575268,
            "hotel_has_vb_boost": 0,
            "is_genius_deal": 0,
            "badges": [],
            "review_score": 8.7,
            "min_total_price": 439,
            "bwallet": {
                "hotel_eligibility": 0
            },
            "distances": [
                {
                    "text": "‎In city center‬",
                    "icon_name": "bui_geo_pin",
                    "icon_set": null
                }
            ],
            "city_trans": "Vancouver",
            "id": "property_card_76418",
            "is_city_center": 1,
            "soldout": 0,
            "accommodation_type_name": "Hotel",
            "review_score_word": "Excellent",
            "class": 4,
            "native_ads_tracking": "",
            "preferred_plus": 0,
            "url": "https://www.booking.com/hotel/ca/the-s-r-vancouver-british-columbia.html",
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "unit_type_id": 13,
                    "localized_area": null
                }
            },
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/135434372.jpg?k=b36805fac0d6638e41ec820de0cf7c05144e885e931c745c82b945fc8af5a89f&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/135434372.jpg?k=b36805fac0d6638e41ec820de0cf7c05144e885e931c745c82b945fc8af5a89f&o="
        },
        {
            "checkin": {
                "until": "",
                "from": "16:00"
            },
            "is_geo_rate": "",
            "country_trans": "Canada",
            "default_wishlist_name": "Vancouver",
            "distance": "0.42",
            "currencycode": "CAD",
            "distance_to_cc_formatted": "0",
            "checkout": {
                "from": "",
                "until": "11:00"
            },
            "wishlist_count": 0,
            "address_trans": "433 Robson Street",
            "cc_required": 1,
            "updated_checkout": null,
            "city_in_trans": "in Vancouver",
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/485762935.jpg?k=315f698acd6816a4e7e9ec335b97033c4b9b60b0344d4b4391e67e49510247ae&o=",
            "accommodation_type": 204,
            "main_photo_id": 485762935,
            "cc1": "ca",
            "price_breakdown": {
                "currency": "CAD",
                "all_inclusive_price": 630.47,
                "has_incalculable_charges": 0,
                "gross_price": "519.70",
                "has_tax_exceptions": 0,
                "has_fine_print_charges": 1,
                "sum_excluded_raw": 110.77
            },
            "city_name_en": "Vancouver (British Columbia)",
            "hotel_name": "Hilton Vancouver Downtown, BC, Canada",
            "composite_price_breakdown": {
                "included_taxes_and_charges_amount": {
                    "value": 0,
                    "currency": "CAD"
                },
                "items": [
                    {
                        "name": "TAX",
                        "inclusion_type": "excluded",
                        "item_amount": {
                            "currency": "CAD",
                            "value": 107.47396
                        },
                        "base": {
                            "kind": "percentage",
                            "percentage": 20.68
                        },
                        "details": "20.68 % TAX",
                        "kind": "charge"
                    },
                    {
                        "base": {
                            "base_amount": 1.65,
                            "kind": "per_person_per_night"
                        },
                        "kind": "charge",
                        "details": null,
                        "inclusion_type": "excluded",
                        "name": "City tax",
                        "item_amount": {
                            "value": 3.3,
                            "currency": "CAD"
                        }
                    }
                ],
                "product_price_breakdowns": [
                    {
                        "items": [
                            {
                                "base": {
                                    "percentage": 20.68,
                                    "kind": "percentage"
                                },
                                "kind": "charge",
                                "details": "20.68 % TAX",
                                "inclusion_type": "excluded",
                                "name": "TAX",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 107.47396
                                }
                            },
                            {
                                "item_amount": {
                                    "value": 3.3,
                                    "currency": "CAD"
                                },
                                "inclusion_type": "excluded",
                                "name": "City tax",
                                "details": null,
                                "kind": "charge",
                                "base": {
                                    "kind": "per_person_per_night",
                                    "base_amount": 1.65
                                }
                            }
                        ],
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 519.7
                        },
                        "gross_amount_hotel_currency": {
                            "value": 519.7,
                            "currency": "CAD"
                        },
                        "all_inclusive_amount": {
                            "currency": "CAD",
                            "value": 630.47396
                        },
                        "gross_amount": {
                            "value": 519.7,
                            "currency": "CAD"
                        },
                        "charges_details": {
                            "mode": "extra_charges",
                            "translated_copy": "+CAD 110.77 taxes and charges",
                            "amount": {
                                "value": 111,
                                "currency": "CAD"
                            }
                        },
                        "excluded_amount": {
                            "currency": "CAD",
                            "value": 110.77396
                        },
                        "benefits": [],
                        "net_amount": {
                            "value": 519.7,
                            "currency": "CAD"
                        }
                    }
                ],
                "benefits": [],
                "net_amount": {
                    "value": 519.7,
                    "currency": "CAD"
                },
                "excluded_amount": {
                    "currency": "CAD",
                    "value": 110.77396
                },
                "charges_details": {
                    "translated_copy": "+CAD 110.77 taxes and charges",
                    "mode": "extra_charges",
                    "amount": {
                        "currency": "CAD",
                        "value": 111
                    }
                },
                "gross_amount_hotel_currency": {
                    "currency": "CAD",
                    "value": 519.7
                },
                "gross_amount_per_night": {
                    "currency": "CAD",
                    "value": 519.7
                },
                "all_inclusive_amount": {
                    "value": 630.47396,
                    "currency": "CAD"
                },
                "gross_amount": {
                    "currency": "CAD",
                    "value": 519.7
                }
            },
            "districts": "1629",
            "unit_configuration_label": "<b>Private suite – 37 m²</b>: 2 beds • 1 bedroom • 1 living room • 1 bathroom",
            "review_recommendation": "",
            "is_smart_deal": 0,
            "city": "Vancouver (British Columbia)",
            "mobile_discount_percentage": 0,
            "review_nr": 632,
            "preferred": 1,
            "is_no_prepayment_block": 1,
            "cant_book": 0,
            "native_ad_id": "",
            "currency_code": "CAD",
            "children_not_allowed": 0,
            "type": "property_card",
            "block_ids": [
                "7615968_344385908_2_42_0"
            ],
            "timezone": "America/Vancouver",
            "district": "Downtown Vancouver",
            "extended": 0,
            "selected_review_topic": null,
            "default_language": "en",
            "distance_to_cc": "0.45",
            "hotel_id": 76159,
            "is_beach_front": 0,
            "zip": "V6B 6L9",
            "hotel_facilities": "20,425,436,47,500,108,420,305,49,505,22,483,479,474,445,2,15,51,184,494,440,78,499,224,91,124,96,496,443,468,418,461,6,186,455,25,177,241,133,109,213,43,442,160,110,53,104,450,478,502,423,81,485,181,422,170,498,195,457,7,188,10,488,467,495,134,198,456,185,449,220,490,52,3,253,460,142,176,504,433,4,5,451,465,111,187,118,63,424,426,462,489,484,107,16,11,23,80,448,506,501,301,446,48,8,119,203,454,222,492,459,257,421,182",
            "district_id": 1629,
            "native_ads_cpc": 0,
            "longitude": -123.117081820965,
            "address": "433 Robson Street",
            "latitude": 49.2797395156827,
            "genius_discount_percentage": 0,
            "countrycode": "ca",
            "in_best_district": 0,
            "hotel_include_breakfast": 0,
            "is_mobile_deal": 0,
            "hotel_name_trans": "Hilton Vancouver Downtown, BC, Canada",
            "is_free_cancellable": 1,
            "updated_checkin": null,
            "review_score_word": "Excellent",
            "class": 4,
            "accommodation_type_name": "Hotel",
            "soldout": 0,
            "is_city_center": 1,
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "unit_type_id": 5,
                    "localized_area": "37"
                }
            },
            "url": "https://www.booking.com/hotel/ca/hilton-vancouver-downtown-british-columbia.html",
            "preferred_plus": 0,
            "native_ads_tracking": "",
            "bwallet": {
                "hotel_eligibility": 0
            },
            "min_total_price": 519.7,
            "review_score": 8.6,
            "id": "property_card_76159",
            "city_trans": "Vancouver",
            "distances": [
                {
                    "text": "‎In city center‬",
                    "icon_set": null,
                    "icon_name": "bui_geo_pin"
                }
            ],
            "is_genius_deal": 0,
            "badges": [],
            "has_swimming_pool": 1,
            "ufi": -575268,
            "class_is_estimated": 0,
            "price_is_final": 0,
            "hotel_has_vb_boost": 0,
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/485762935.jpg?k=315f698acd6816a4e7e9ec335b97033c4b9b60b0344d4b4391e67e49510247ae&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/485762935.jpg?k=315f698acd6816a4e7e9ec335b97033c4b9b60b0344d4b4391e67e49510247ae&o="
        },
        {
            "default_language": "en",
            "district": "Downtown Vancouver",
            "extended": 0,
            "selected_review_topic": null,
            "distance_to_cc": "0.70",
            "native_ads_cpc": 0,
            "hotel_id": 25281,
            "is_beach_front": 0,
            "hotel_facilities": "256,20,75,217,121,425,436,47,49,22,447,101,108,420,305,407,445,2,15,51,453,135,78,184,461,6,455,158,91,124,96,468,418,109,160,110,53,450,25,64,133,423,12,457,195,7,117,81,485,99,181,422,73,198,103,488,467,50,134,52,449,220,26,433,4,3,253,118,63,458,424,486,451,111,44,484,255,462,218,23,80,107,16,11,301,48,257,76,136,8,203,454,459",
            "zip": "V6E 3T3",
            "district_id": 1629,
            "longitude": -123.120973706245,
            "latitude": 49.2883651922663,
            "address": "1133 West Hastings Street",
            "genius_discount_percentage": 0,
            "countrycode": "ca",
            "in_best_district": 0,
            "is_mobile_deal": 0,
            "hotel_include_breakfast": 0,
            "updated_checkin": null,
            "hotel_name_trans": "Pinnacle Hotel Harbourfront",
            "is_free_cancellable": 1,
            "is_city_center": 1,
            "soldout": 0,
            "class": 4,
            "review_score_word": "Good",
            "accommodation_type_name": "Hotel",
            "url": "https://www.booking.com/hotel/ca/pinnacle-hotel-vancouver-harbourfront.html",
            "native_ads_tracking": "",
            "preferred_plus": 0,
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "unit_type_id": 9,
                    "localized_area": null
                }
            },
            "min_total_price": 454.5,
            "review_score": 7.9,
            "bwallet": {
                "hotel_eligibility": 0
            },
            "city_trans": "Vancouver",
            "distances": [
                {
                    "text": "‎In city center‬",
                    "icon_set": null,
                    "icon_name": "bui_geo_pin"
                }
            ],
            "id": "property_card_25281",
            "is_genius_deal": 0,
            "has_swimming_pool": 1,
            "badges": [],
            "ufi": -575268,
            "class_is_estimated": 0,
            "price_is_final": 0,
            "hotel_has_vb_boost": 0,
            "checkin": {
                "from": "15:00",
                "until": ""
            },
            "default_wishlist_name": "Vancouver",
            "country_trans": "Canada",
            "distance": "0.66",
            "is_geo_rate": "",
            "currencycode": "CAD",
            "distance_to_cc_formatted": "0",
            "checkout": {
                "until": "12:00",
                "from": ""
            },
            "wishlist_count": 0,
            "updated_checkout": null,
            "cc_required": 1,
            "address_trans": "1133 West Hastings Street",
            "city_in_trans": "in Vancouver",
            "accommodation_type": 204,
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/65529082.jpg?k=5fb76d126e92829a679e76c3c845eb013ded9bec8f3c5c43f08846a0c0bc3298&o=",
            "price_breakdown": {
                "has_tax_exceptions": 0,
                "sum_excluded_raw": 93.99,
                "has_fine_print_charges": 1,
                "has_incalculable_charges": 0,
                "gross_price": "454.50",
                "all_inclusive_price": 548.49,
                "currency": "CAD"
            },
            "main_photo_id": 65529082,
            "cc1": "ca",
            "composite_price_breakdown": {
                "strikethrough_amount": {
                    "currency": "CAD",
                    "value": 505
                },
                "product_price_breakdowns": [
                    {
                        "items": [
                            {
                                "base": {
                                    "percentage": 20.68,
                                    "kind": "percentage"
                                },
                                "details": "20.68 % TAX",
                                "kind": "charge",
                                "inclusion_type": "excluded",
                                "name": "TAX",
                                "item_amount": {
                                    "value": 93.9906,
                                    "currency": "CAD"
                                }
                            },
                            {
                                "item_amount": {
                                    "value": 50.5,
                                    "currency": "CAD"
                                },
                                "identifier": "basic-deal",
                                "name": "Bonus savings",
                                "details": "You’re getting a reduced rate because this property is offering a discount.",
                                "kind": "discount",
                                "base": {
                                    "kind": "rate"
                                }
                            }
                        ],
                        "strikethrough_amount": {
                            "currency": "CAD",
                            "value": 505
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 454.5
                        },
                        "gross_amount_hotel_currency": {
                            "value": 454.5,
                            "currency": "CAD"
                        },
                        "all_inclusive_amount": {
                            "value": 548.4906,
                            "currency": "CAD"
                        },
                        "benefits": [],
                        "net_amount": {
                            "currency": "CAD",
                            "value": 454.5
                        },
                        "strikethrough_amount_per_night": {
                            "currency": "CAD",
                            "value": 505
                        },
                        "included_taxes_and_charges_amount": {
                            "value": 0,
                            "currency": "CAD"
                        },
                        "discounted_amount": {
                            "value": 50.5,
                            "currency": "CAD"
                        },
                        "gross_amount": {
                            "value": 454.5,
                            "currency": "CAD"
                        },
                        "charges_details": {
                            "mode": "extra_charges",
                            "translated_copy": "+CAD 93.99 taxes and charges",
                            "amount": {
                                "currency": "CAD",
                                "value": 94
                            }
                        },
                        "excluded_amount": {
                            "value": 93.9906,
                            "currency": "CAD"
                        }
                    }
                ],
                "items": [
                    {
                        "item_amount": {
                            "value": 93.9906,
                            "currency": "CAD"
                        },
                        "name": "TAX",
                        "inclusion_type": "excluded",
                        "details": "20.68 % TAX",
                        "kind": "charge",
                        "base": {
                            "percentage": 20.68,
                            "kind": "percentage"
                        }
                    },
                    {
                        "base": {
                            "kind": "rate"
                        },
                        "details": "You’re getting a reduced rate because this property is offering a discount.",
                        "kind": "discount",
                        "name": "Bonus savings",
                        "identifier": "basic-deal",
                        "item_amount": {
                            "value": 50.5,
                            "currency": "CAD"
                        }
                    }
                ],
                "gross_amount_hotel_currency": {
                    "currency": "CAD",
                    "value": 454.5
                },
                "all_inclusive_amount": {
                    "currency": "CAD",
                    "value": 548.4906
                },
                "gross_amount_per_night": {
                    "value": 454.5,
                    "currency": "CAD"
                },
                "net_amount": {
                    "value": 454.5,
                    "currency": "CAD"
                },
                "benefits": [],
                "strikethrough_amount_per_night": {
                    "value": 505,
                    "currency": "CAD"
                },
                "included_taxes_and_charges_amount": {
                    "currency": "CAD",
                    "value": 0
                },
                "gross_amount": {
                    "value": 454.5,
                    "currency": "CAD"
                },
                "discounted_amount": {
                    "currency": "CAD",
                    "value": 50.5
                },
                "charges_details": {
                    "translated_copy": "+CAD 93.99 taxes and charges",
                    "mode": "extra_charges",
                    "amount": {
                        "value": 94,
                        "currency": "CAD"
                    }
                },
                "excluded_amount": {
                    "value": 93.9906,
                    "currency": "CAD"
                }
            },
            "hotel_name": "Pinnacle Hotel Harbourfront",
            "city_name_en": "Vancouver (British Columbia)",
            "review_recommendation": "",
            "is_smart_deal": 0,
            "districts": "1629,4185",
            "unit_configuration_label": "<b>Hotel room</b>: 2 beds",
            "mobile_discount_percentage": 0,
            "review_nr": 3137,
            "city": "Vancouver (British Columbia)",
            "is_no_prepayment_block": 1,
            "cant_book": 0,
            "preferred": 1,
            "currency_code": "CAD",
            "children_not_allowed": 0,
            "native_ad_id": "",
            "type": "property_card",
            "block_ids": [
                "2528122_265750744_2_0_0"
            ],
            "timezone": "America/Vancouver",
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/65529082.jpg?k=5fb76d126e92829a679e76c3c845eb013ded9bec8f3c5c43f08846a0c0bc3298&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/65529082.jpg?k=5fb76d126e92829a679e76c3c845eb013ded9bec8f3c5c43f08846a0c0bc3298&o="
        },
        {
            "is_genius_deal": 0,
            "badges": [
                {
                    "id": "new_to_booking",
                    "text": "New to Booking.com",
                    "badge_variant": "accent"
                }
            ],
            "ufi": -575268,
            "price_is_final": 1,
            "class_is_estimated": 0,
            "hotel_has_vb_boost": 0,
            "review_score_word": "",
            "class": 0,
            "accommodation_type_name": "Villa",
            "soldout": 0,
            "is_city_center": 0,
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "unit_type_id": 31,
                    "localized_area": "172"
                }
            },
            "url": "https://www.booking.com/hotel/ca/new-lovely-air-conditioned-duplex-house-1628.html",
            "native_ads_tracking": "",
            "preferred_plus": 0,
            "bwallet": {
                "hotel_eligibility": 0
            },
            "min_total_price": 379.05,
            "review_score": null,
            "booking_home": {
                "segment": 2,
                "is_single_unit_property": 1,
                "is_booking_home": 1,
                "quality_class": 4,
                "group": "house_like"
            },
            "id": "property_card_10685202",
            "distances": [
                {
                    "text": "‎Vancouver South‬ • ‎8 km from center‬",
                    "icon_set": null,
                    "icon_name": "bui_geo_pin"
                }
            ],
            "city_trans": "Vancouver",
            "latitude": 49.2190206,
            "address": "1628 East 56th Avenue",
            "genius_discount_percentage": 0,
            "in_best_district": 0,
            "countrycode": "ca",
            "hotel_include_breakfast": 0,
            "is_mobile_deal": 0,
            "hotel_name_trans": "Four bedrooms New Lovely Air-conditioned Duplex House-1628",
            "is_free_cancellable": 1,
            "updated_checkin": null,
            "district": "Vancouver South",
            "extended": 0,
            "selected_review_topic": null,
            "default_language": "zh",
            "distance_to_cc": "7.90",
            "is_beach_front": 0,
            "hotel_id": 10685202,
            "district_id": 5201,
            "zip": "V5P 2A5",
            "hotel_facilities": "457,160,109,108,437,422,107,161,466,16,2,46,14,28,15,96,460,47",
            "native_ads_cpc": 0,
            "longitude": -123.0733071,
            "preferred": 0,
            "is_no_prepayment_block": 0,
            "cant_book": 0,
            "native_ad_id": "",
            "currency_code": "CAD",
            "children_not_allowed": 0,
            "type": "property_card",
            "block_ids": [
                "1068520201_379354308_7_0_0"
            ],
            "timezone": "America/Vancouver",
            "main_photo_id": 488390251,
            "cc1": "ca",
            "price_breakdown": {
                "has_tax_exceptions": 0,
                "has_fine_print_charges": 0,
                "sum_excluded_raw": 260.65,
                "has_incalculable_charges": 0,
                "gross_price": 379.05,
                "all_inclusive_price": 639.7,
                "currency": "CAD"
            },
            "city_name_en": "Vancouver",
            "hotel_name": "Four bedrooms New Lovely Air-conditioned Duplex House-1628",
            "composite_price_breakdown": {
                "excluded_amount": {
                    "currency": "CAD",
                    "value": 260.648
                },
                "net_amount": {
                    "currency": "CAD",
                    "value": 379.05
                },
                "benefits": [],
                "gross_amount_hotel_currency": {
                    "currency": "CAD",
                    "value": 379.05
                },
                "gross_amount_per_night": {
                    "value": 379.05,
                    "currency": "CAD"
                },
                "all_inclusive_amount": {
                    "currency": "CAD",
                    "value": 639.698
                },
                "gross_amount": {
                    "currency": "CAD",
                    "value": 379.05
                },
                "charges_details": {
                    "mode": "extra_charges",
                    "translated_copy": "+CAD 260.65 taxes and charges",
                    "amount": {
                        "value": 261,
                        "currency": "CAD"
                    }
                },
                "included_taxes_and_charges_amount": {
                    "value": 0,
                    "currency": "CAD"
                },
                "items": [
                    {
                        "base": {
                            "percentage": 0,
                            "kind": "percentage"
                        },
                        "kind": "charge",
                        "details": "0 % City tax",
                        "name": "City tax",
                        "inclusion_type": "included",
                        "item_amount": {
                            "value": 0,
                            "currency": "CAD"
                        }
                    },
                    {
                        "item_amount": {
                            "value": 60.648,
                            "currency": "CAD"
                        },
                        "name": "TAX",
                        "inclusion_type": "excluded",
                        "kind": "charge",
                        "details": "16 % TAX",
                        "base": {
                            "kind": "percentage",
                            "percentage": 16
                        }
                    },
                    {
                        "details": "You don't pay the cleaning fee thanks to Genius",
                        "kind": "charge",
                        "base": {
                            "kind": "per_stay",
                            "base_amount": 200
                        },
                        "item_amount": {
                            "currency": "CAD",
                            "value": 200
                        },
                        "name": "Cleaning fee",
                        "inclusion_type": "excluded"
                    }
                ],
                "product_price_breakdowns": [
                    {
                        "items": [
                            {
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 0
                                },
                                "kind": "charge",
                                "details": "0 % City tax",
                                "inclusion_type": "included",
                                "name": "City tax",
                                "item_amount": {
                                    "value": 0,
                                    "currency": "CAD"
                                }
                            },
                            {
                                "base": {
                                    "percentage": 16,
                                    "kind": "percentage"
                                },
                                "details": "16 % TAX",
                                "kind": "charge",
                                "name": "TAX",
                                "inclusion_type": "excluded",
                                "item_amount": {
                                    "value": 60.648,
                                    "currency": "CAD"
                                }
                            },
                            {
                                "base": {
                                    "kind": "per_stay",
                                    "base_amount": 200
                                },
                                "details": "You don't pay the cleaning fee thanks to Genius",
                                "kind": "charge",
                                "name": "Cleaning fee",
                                "inclusion_type": "excluded",
                                "item_amount": {
                                    "value": 200,
                                    "currency": "CAD"
                                }
                            }
                        ],
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "charges_details": {
                            "amount": {
                                "value": 261,
                                "currency": "CAD"
                            },
                            "translated_copy": "+CAD 260.65 taxes and charges",
                            "mode": "extra_charges"
                        },
                        "gross_amount_hotel_currency": {
                            "currency": "CAD",
                            "value": 379.05
                        },
                        "all_inclusive_amount": {
                            "currency": "CAD",
                            "value": 639.698
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 379.05
                        },
                        "gross_amount": {
                            "value": 379.05,
                            "currency": "CAD"
                        },
                        "net_amount": {
                            "currency": "CAD",
                            "value": 379.05
                        },
                        "benefits": [],
                        "excluded_amount": {
                            "value": 260.648,
                            "currency": "CAD"
                        }
                    }
                ]
            },
            "districts": "5201",
            "unit_configuration_label": "<b>Entire villa – 172 m²</b>: 4 beds • 4 bedrooms • 1 living room • 3 bathrooms",
            "review_recommendation": "",
            "is_smart_deal": 0,
            "city": "Vancouver",
            "mobile_discount_percentage": 0,
            "review_nr": null,
            "cc_required": 1,
            "address_trans": "1628 East 56th Avenue",
            "updated_checkout": null,
            "city_in_trans": "in Vancouver",
            "accommodation_type": 213,
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/488390251.jpg?k=3d5873b8d3421625cb72bb5bec1303fb7ed02fa1915b78beb57ce55ab567aaef&o=",
            "checkin": {
                "from": "15:00",
                "until": ""
            },
            "is_geo_rate": "",
            "default_wishlist_name": "Vancouver",
            "country_trans": "Canada",
            "distance": "7.86",
            "currencycode": "CAD",
            "has_free_parking": 1,
            "checkout": {
                "until": "11:00",
                "from": ""
            },
            "distance_to_cc_formatted": "8 km",
            "wishlist_count": 0,
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/488390251.jpg?k=3d5873b8d3421625cb72bb5bec1303fb7ed02fa1915b78beb57ce55ab567aaef&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/488390251.jpg?k=3d5873b8d3421625cb72bb5bec1303fb7ed02fa1915b78beb57ce55ab567aaef&o="
        },
        {
            "accommodation_type": 213,
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/488459231.jpg?k=9655ba48548df15c5c996baaffae187d87fab30e179713f0b5400dae3453cdcd&o=",
            "city_in_trans": "in Vancouver",
            "updated_checkout": null,
            "address_trans": "2793 East 1st Avenue",
            "cc_required": 1,
            "wishlist_count": 0,
            "distance_to_cc_formatted": "6 km",
            "checkout": {
                "from": "",
                "until": "11:00"
            },
            "has_free_parking": 1,
            "currencycode": "CAD",
            "country_trans": "Canada",
            "default_wishlist_name": "Vancouver",
            "distance": "5.54",
            "is_geo_rate": "",
            "checkin": {
                "until": "",
                "from": "15:00"
            },
            "block_ids": [
                "1068658901_379568914_5_0_0"
            ],
            "timezone": "America/Vancouver",
            "type": "property_card",
            "currency_code": "CAD",
            "children_not_allowed": 0,
            "native_ad_id": "",
            "is_no_prepayment_block": 0,
            "cant_book": 0,
            "preferred": 0,
            "mobile_discount_percentage": 0,
            "review_nr": null,
            "city": "Vancouver",
            "review_recommendation": "",
            "is_smart_deal": 0,
            "districts": "4191",
            "unit_configuration_label": "<b>Entire villa – 128 m²</b>: 1 bed • 1 bedroom • 2 bathrooms",
            "hotel_name": "Three bedrooms New Modern Air-conditioned Duplex-2793",
            "composite_price_breakdown": {
                "included_taxes_and_charges_amount": {
                    "value": 0,
                    "currency": "CAD"
                },
                "items": [
                    {
                        "base": {
                            "percentage": 0,
                            "kind": "percentage"
                        },
                        "kind": "charge",
                        "details": "0 % City tax",
                        "name": "City tax",
                        "inclusion_type": "included",
                        "item_amount": {
                            "value": 0,
                            "currency": "CAD"
                        }
                    },
                    {
                        "base": {
                            "percentage": 16,
                            "kind": "percentage"
                        },
                        "kind": "charge",
                        "details": "16 % TAX",
                        "inclusion_type": "excluded",
                        "name": "TAX",
                        "item_amount": {
                            "currency": "CAD",
                            "value": 56.088
                        }
                    },
                    {
                        "item_amount": {
                            "value": 200,
                            "currency": "CAD"
                        },
                        "inclusion_type": "excluded",
                        "name": "Cleaning fee",
                        "kind": "charge",
                        "details": "You don't pay the cleaning fee thanks to Genius",
                        "base": {
                            "kind": "per_stay",
                            "base_amount": 200
                        }
                    }
                ],
                "product_price_breakdowns": [
                    {
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "items": [
                            {
                                "name": "City tax",
                                "inclusion_type": "included",
                                "item_amount": {
                                    "value": 0,
                                    "currency": "CAD"
                                },
                                "base": {
                                    "percentage": 0,
                                    "kind": "percentage"
                                },
                                "kind": "charge",
                                "details": "0 % City tax"
                            },
                            {
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 16
                                },
                                "kind": "charge",
                                "details": "16 % TAX",
                                "name": "TAX",
                                "inclusion_type": "excluded",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 56.088
                                }
                            },
                            {
                                "inclusion_type": "excluded",
                                "name": "Cleaning fee",
                                "item_amount": {
                                    "value": 200,
                                    "currency": "CAD"
                                },
                                "base": {
                                    "base_amount": 200,
                                    "kind": "per_stay"
                                },
                                "kind": "charge",
                                "details": "You don't pay the cleaning fee thanks to Genius"
                            }
                        ],
                        "excluded_amount": {
                            "currency": "CAD",
                            "value": 256.088
                        },
                        "benefits": [],
                        "net_amount": {
                            "value": 350.55,
                            "currency": "CAD"
                        },
                        "all_inclusive_amount": {
                            "value": 606.638,
                            "currency": "CAD"
                        },
                        "gross_amount_hotel_currency": {
                            "currency": "CAD",
                            "value": 350.55
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 350.55
                        },
                        "gross_amount": {
                            "currency": "CAD",
                            "value": 350.55
                        },
                        "charges_details": {
                            "amount": {
                                "value": 256,
                                "currency": "CAD"
                            },
                            "translated_copy": "+CAD 256.09 taxes and charges",
                            "mode": "extra_charges"
                        }
                    }
                ],
                "benefits": [],
                "net_amount": {
                    "currency": "CAD",
                    "value": 350.55
                },
                "excluded_amount": {
                    "currency": "CAD",
                    "value": 256.088
                },
                "charges_details": {
                    "mode": "extra_charges",
                    "translated_copy": "+CAD 256.09 taxes and charges",
                    "amount": {
                        "value": 256,
                        "currency": "CAD"
                    }
                },
                "gross_amount_hotel_currency": {
                    "value": 350.55,
                    "currency": "CAD"
                },
                "gross_amount_per_night": {
                    "currency": "CAD",
                    "value": 350.55
                },
                "all_inclusive_amount": {
                    "currency": "CAD",
                    "value": 606.638
                },
                "gross_amount": {
                    "value": 350.55,
                    "currency": "CAD"
                }
            },
            "city_name_en": "Vancouver",
            "price_breakdown": {
                "has_incalculable_charges": 0,
                "gross_price": 350.55,
                "currency": "CAD",
                "all_inclusive_price": 606.64,
                "has_tax_exceptions": 0,
                "sum_excluded_raw": 256.09,
                "has_fine_print_charges": 0
            },
            "main_photo_id": 488459231,
            "cc1": "ca",
            "updated_checkin": null,
            "hotel_name_trans": "Three bedrooms New Modern Air-conditioned Duplex-2793",
            "is_free_cancellable": 1,
            "is_mobile_deal": 0,
            "hotel_include_breakfast": 0,
            "countrycode": "ca",
            "in_best_district": 0,
            "address": "2793 East 1st Avenue",
            "latitude": 49.2697701,
            "genius_discount_percentage": 0,
            "longitude": -123.0473289,
            "native_ads_cpc": 0,
            "is_beach_front": 0,
            "hotel_id": 10686589,
            "district_id": 4191,
            "zip": "V5M 1A6",
            "hotel_facilities": "46,14,2,423,15,422,437,108,466,161,109,160,476,424",
            "distance_to_cc": "5.55",
            "default_language": "en",
            "extended": 0,
            "district": "Hastings",
            "selected_review_topic": null,
            "hotel_has_vb_boost": 0,
            "ufi": -575268,
            "class_is_estimated": 0,
            "price_is_final": 1,
            "badges": [
                {
                    "badge_variant": "accent",
                    "text": "New to Booking.com",
                    "id": "new_to_booking"
                }
            ],
            "is_genius_deal": 0,
            "city_trans": "Vancouver",
            "distances": [
                {
                    "icon_set": null,
                    "icon_name": "bui_geo_pin",
                    "text": "‎Hastings‬ • ‎6 km from center‬"
                }
            ],
            "id": "property_card_10686589",
            "min_total_price": 350.55,
            "booking_home": {
                "is_single_unit_property": 1,
                "segment": 2,
                "group": "house_like",
                "quality_class": 4,
                "is_booking_home": 1
            },
            "review_score": null,
            "bwallet": {
                "hotel_eligibility": 0
            },
            "url": "https://www.booking.com/hotel/ca/new-modern-air-conditioned-duplex-2793.html",
            "preferred_plus": 0,
            "native_ads_tracking": "",
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "unit_type_id": 31,
                    "localized_area": "128"
                }
            },
            "is_city_center": 0,
            "soldout": 0,
            "accommodation_type_name": "Villa",
            "review_score_word": "",
            "class": 0,
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/488459231.jpg?k=9655ba48548df15c5c996baaffae187d87fab30e179713f0b5400dae3453cdcd&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/488459231.jpg?k=9655ba48548df15c5c996baaffae187d87fab30e179713f0b5400dae3453cdcd&o="
        },
        {
            "hotel_include_breakfast": 0,
            "is_mobile_deal": 0,
            "updated_checkin": null,
            "is_free_cancellable": 1,
            "hotel_name_trans": "Explore Vancouver from Deluxe 2B/1B basement",
            "genius_discount_percentage": 0,
            "latitude": 49.2546687,
            "address": "West 20th Avenue",
            "countrycode": "ca",
            "in_best_district": 0,
            "native_ads_cpc": 0,
            "hotel_facilities": "163,107,47,108,96,160,109,2,46",
            "zip": "V6S 1E5",
            "district_id": 4190,
            "hotel_id": 9776703,
            "is_beach_front": 0,
            "longitude": -123.1806776,
            "default_language": "en",
            "selected_review_topic": null,
            "district": "Dunbar",
            "extended": 0,
            "distance_to_cc": "5.35",
            "class_is_estimated": 0,
            "price_is_final": 1,
            "ufi": -575268,
            "hotel_has_vb_boost": 0,
            "is_genius_deal": 0,
            "badges": [],
            "review_score": 5.8,
            "booking_home": {
                "quality_class": 4,
                "is_booking_home": 1,
                "group": "house_like",
                "segment": 2,
                "is_single_unit_property": 1
            },
            "min_total_price": 161,
            "bwallet": {
                "hotel_eligibility": 0
            },
            "distances": [
                {
                    "text": "‎Dunbar‬ • ‎5 km from center‬",
                    "icon_set": null,
                    "icon_name": "bui_geo_pin"
                }
            ],
            "city_trans": "Vancouver",
            "id": "property_card_9776703",
            "external_reviews": {
                "score": 9,
                "score_word": "Wonderful",
                "num_reviews": 3,
                "should_display": ""
            },
            "soldout": 0,
            "is_city_center": 0,
            "review_score_word": "Okay",
            "accommodation_type_name": "Vacation Home",
            "class": 0,
            "preferred_plus": 0,
            "native_ads_tracking": "",
            "url": "https://www.booking.com/hotel/ca/explore-vancouver-from-deluxe-2b-1b-basement.html",
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "localized_area": "70",
                    "unit_type_id": 29
                }
            },
            "city_in_trans": "in Vancouver",
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/441179945.jpg?k=e08e7d92d4182f122d973833a07eda1c4c4268e3c80f89ebd710fe23a3717212&o=",
            "accommodation_type": 220,
            "updated_checkout": null,
            "address_trans": "West 20th Avenue",
            "cc_required": 1,
            "has_free_parking": 1,
            "currencycode": "CAD",
            "wishlist_count": 0,
            "distance_to_cc_formatted": "5 km",
            "checkout": {
                "until": "11:00",
                "from": ""
            },
            "checkin": {
                "from": "15:00",
                "until": "21:00"
            },
            "distance": "5.31",
            "default_wishlist_name": "Vancouver",
            "country_trans": "Canada",
            "is_geo_rate": "",
            "type": "property_card",
            "timezone": "America/Vancouver",
            "block_ids": [
                "977670301_375633652_4_0_0"
            ],
            "cant_book": 0,
            "is_no_prepayment_block": 1,
            "preferred": 0,
            "children_not_allowed": 0,
            "currency_code": "CAD",
            "native_ad_id": "",
            "is_smart_deal": 0,
            "review_recommendation": "",
            "unit_configuration_label": "<b>Entire vacation home – 70 m²</b>: 3 beds • 2 bedrooms • 1 living room • 1 bathroom",
            "districts": "4190,5201",
            "review_nr": 6,
            "mobile_discount_percentage": 0,
            "city": "Vancouver",
            "price_breakdown": {
                "currency": "CAD",
                "all_inclusive_price": 336.76,
                "has_incalculable_charges": 0,
                "gross_price": "161.00",
                "has_tax_exceptions": 0,
                "has_fine_print_charges": 0,
                "sum_excluded_raw": 175.76
            },
            "cc1": "ca",
            "main_photo_id": 441179945,
            "hotel_name": "Explore Vancouver from Deluxe 2B/1B basement",
            "composite_price_breakdown": {
                "included_taxes_and_charges_amount": {
                    "value": 0,
                    "currency": "CAD"
                },
                "product_price_breakdowns": [
                    {
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "items": [
                            {
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 0
                                },
                                "inclusion_type": "included",
                                "name": "City tax",
                                "kind": "charge",
                                "details": "0 % City tax",
                                "base": {
                                    "percentage": 0,
                                    "kind": "percentage"
                                }
                            },
                            {
                                "inclusion_type": "excluded",
                                "name": "TAX",
                                "item_amount": {
                                    "value": 25.76,
                                    "currency": "CAD"
                                },
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 16
                                },
                                "details": "16 % TAX",
                                "kind": "charge"
                            },
                            {
                                "base": {
                                    "base_amount": 150,
                                    "kind": "per_stay"
                                },
                                "details": "You don't pay the cleaning fee thanks to Genius",
                                "kind": "charge",
                                "inclusion_type": "excluded",
                                "name": "Cleaning fee",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 150
                                }
                            }
                        ],
                        "benefits": [],
                        "net_amount": {
                            "currency": "CAD",
                            "value": 161
                        },
                        "excluded_amount": {
                            "value": 175.76,
                            "currency": "CAD"
                        },
                        "charges_details": {
                            "amount": {
                                "value": 176,
                                "currency": "CAD"
                            },
                            "mode": "extra_charges",
                            "translated_copy": "+CAD 175.76 taxes and charges"
                        },
                        "all_inclusive_amount": {
                            "currency": "CAD",
                            "value": 336.76
                        },
                        "gross_amount_hotel_currency": {
                            "value": 161,
                            "currency": "CAD"
                        },
                        "gross_amount": {
                            "value": 161,
                            "currency": "CAD"
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 161
                        }
                    }
                ],
                "items": [
                    {
                        "details": "0 % City tax",
                        "kind": "charge",
                        "base": {
                            "kind": "percentage",
                            "percentage": 0
                        },
                        "item_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "inclusion_type": "included",
                        "name": "City tax"
                    },
                    {
                        "kind": "charge",
                        "details": "16 % TAX",
                        "base": {
                            "kind": "percentage",
                            "percentage": 16
                        },
                        "item_amount": {
                            "value": 25.76,
                            "currency": "CAD"
                        },
                        "name": "TAX",
                        "inclusion_type": "excluded"
                    },
                    {
                        "details": "You don't pay the cleaning fee thanks to Genius",
                        "kind": "charge",
                        "base": {
                            "base_amount": 150,
                            "kind": "per_stay"
                        },
                        "item_amount": {
                            "currency": "CAD",
                            "value": 150
                        },
                        "inclusion_type": "excluded",
                        "name": "Cleaning fee"
                    }
                ],
                "excluded_amount": {
                    "currency": "CAD",
                    "value": 175.76
                },
                "net_amount": {
                    "currency": "CAD",
                    "value": 161
                },
                "benefits": [],
                "gross_amount_hotel_currency": {
                    "value": 161,
                    "currency": "CAD"
                },
                "gross_amount": {
                    "value": 161,
                    "currency": "CAD"
                },
                "all_inclusive_amount": {
                    "value": 336.76,
                    "currency": "CAD"
                },
                "gross_amount_per_night": {
                    "currency": "CAD",
                    "value": 161
                },
                "charges_details": {
                    "amount": {
                        "value": 176,
                        "currency": "CAD"
                    },
                    "translated_copy": "+CAD 175.76 taxes and charges",
                    "mode": "extra_charges"
                }
            },
            "city_name_en": "Vancouver",
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/441179945.jpg?k=e08e7d92d4182f122d973833a07eda1c4c4268e3c80f89ebd710fe23a3717212&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/441179945.jpg?k=e08e7d92d4182f122d973833a07eda1c4c4268e3c80f89ebd710fe23a3717212&o="
        },
        {
            "district": "Downtown Vancouver",
            "extended": 0,
            "selected_review_topic": null,
            "default_language": "en",
            "distance_to_cc": "1.25",
            "is_beach_front": 0,
            "hotel_id": 76293,
            "hotel_facilities": "253,3,460,176,433,4,245,5,54,451,44,465,111,118,63,143,240,467,225,488,134,198,456,103,219,449,220,490,444,247,493,301,120,48,119,203,222,437,8,492,459,76,435,218,462,194,484,255,16,11,55,107,441,448,80,23,242,250,479,453,474,2,445,51,65,251,184,494,440,217,121,425,20,75,436,47,464,419,249,420,108,244,305,49,483,447,22,478,502,246,28,485,422,254,181,81,7,491,457,195,221,10,439,91,96,468,496,443,14,461,6,455,25,133,248,43,109,160,450,104,53",
            "zip": "V6G 2V4",
            "district_id": 1629,
            "native_ads_cpc": 0,
            "longitude": -123.129079341888,
            "address": "1601 Bayshore Drive",
            "latitude": 49.2921491660351,
            "genius_discount_percentage": 0,
            "in_best_district": 0,
            "countrycode": "ca",
            "is_mobile_deal": 0,
            "hotel_name_trans": "The Westin Bayshore, Vancouver",
            "is_free_cancellable": 1,
            "updated_checkin": null,
            "review_score_word": "Very Good",
            "class": 4,
            "accommodation_type_name": "Hotel",
            "is_city_center": 1,
            "soldout": 0,
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "localized_area": null,
                    "unit_type_id": 9
                }
            },
            "url": "https://www.booking.com/hotel/ca/the-westin-bayshore-vancouver.html",
            "preferred_plus": 0,
            "native_ads_tracking": "",
            "bwallet": {
                "hotel_eligibility": 0
            },
            "min_total_price": 1107,
            "review_score": 8.5,
            "id": "property_card_76293",
            "city_trans": "Vancouver",
            "distances": [
                {
                    "icon_name": "bui_geo_pin",
                    "icon_set": null,
                    "text": "‎In city center‬"
                },
                {
                    "text": "‎This property has free cribs available‬",
                    "icon_name": "bui_baby_cot",
                    "icon_set": null
                }
            ],
            "is_genius_deal": 0,
            "badges": [],
            "has_swimming_pool": 1,
            "ufi": -575268,
            "class_is_estimated": 0,
            "price_is_final": 1,
            "hotel_has_vb_boost": 0,
            "checkin": {
                "until": "23:30",
                "from": "16:00"
            },
            "is_geo_rate": "",
            "default_wishlist_name": "Vancouver",
            "country_trans": "Canada",
            "distance": "1.22",
            "currencycode": "CAD",
            "distance_to_cc_formatted": "0",
            "wishlist_count": 0,
            "checkout": {
                "until": "12:00",
                "from": ""
            },
            "address_trans": "1601 Bayshore Drive",
            "cc_required": 1,
            "updated_checkout": null,
            "city_in_trans": "in Vancouver",
            "accommodation_type": 204,
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/446995032.jpg?k=cb6a6512ec6777d167bb05894146c8beb64e8315d1120bb3e08fbfa208c8c004&o=",
            "main_photo_id": 446995032,
            "cc1": "ca",
            "price_breakdown": {
                "currency": "CAD",
                "all_inclusive_price": 1335.93,
                "gross_price": "1107.00",
                "has_incalculable_charges": 0,
                "has_fine_print_charges": 0,
                "sum_excluded_raw": 228.93,
                "has_tax_exceptions": 0
            },
            "city_name_en": "Vancouver (British Columbia)",
            "composite_price_breakdown": {
                "gross_amount_hotel_currency": {
                    "value": 1107,
                    "currency": "CAD"
                },
                "all_inclusive_amount": {
                    "value": 1335.9276,
                    "currency": "CAD"
                },
                "gross_amount_per_night": {
                    "currency": "CAD",
                    "value": 1107
                },
                "gross_amount": {
                    "currency": "CAD",
                    "value": 1107
                },
                "charges_details": {
                    "mode": "extra_charges",
                    "translated_copy": "+CAD 228.93 taxes and charges",
                    "amount": {
                        "value": 229,
                        "currency": "CAD"
                    }
                },
                "excluded_amount": {
                    "currency": "CAD",
                    "value": 228.9276
                },
                "benefits": [],
                "net_amount": {
                    "currency": "CAD",
                    "value": 1107
                },
                "items": [
                    {
                        "item_amount": {
                            "currency": "CAD",
                            "value": 89.667
                        },
                        "inclusion_type": "excluded",
                        "name": "TAX",
                        "details": "8.10 % TAX",
                        "kind": "charge",
                        "base": {
                            "percentage": 8.1,
                            "kind": "percentage"
                        }
                    },
                    {
                        "inclusion_type": "excluded",
                        "name": "City tax",
                        "item_amount": {
                            "value": 61.6599,
                            "currency": "CAD"
                        },
                        "base": {
                            "percentage": 5.57,
                            "kind": "percentage"
                        },
                        "details": "5.57 % City tax",
                        "kind": "charge"
                    },
                    {
                        "kind": "charge",
                        "details": "5.75 % Goods & services tax",
                        "base": {
                            "percentage": 5.75,
                            "kind": "percentage"
                        },
                        "item_amount": {
                            "value": 63.6525,
                            "currency": "CAD"
                        },
                        "name": "Goods & services tax",
                        "inclusion_type": "excluded"
                    },
                    {
                        "inclusion_type": "excluded",
                        "name": "Destination fee",
                        "item_amount": {
                            "value": 13.9482,
                            "currency": "CAD"
                        },
                        "base": {
                            "percentage": 1.26,
                            "kind": "percentage"
                        },
                        "kind": "charge",
                        "details": "1.26 % Destination fee"
                    }
                ],
                "product_price_breakdowns": [
                    {
                        "included_taxes_and_charges_amount": {
                            "value": 0,
                            "currency": "CAD"
                        },
                        "items": [
                            {
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 89.667
                                },
                                "inclusion_type": "excluded",
                                "name": "TAX",
                                "details": "8.10 % TAX",
                                "kind": "charge",
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 8.1
                                }
                            },
                            {
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 5.57
                                },
                                "kind": "charge",
                                "details": "5.57 % City tax",
                                "name": "City tax",
                                "inclusion_type": "excluded",
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 61.6599
                                }
                            },
                            {
                                "base": {
                                    "percentage": 1.26,
                                    "kind": "percentage"
                                },
                                "details": "1.26 % Destination fee",
                                "kind": "charge",
                                "name": "Destination fee",
                                "inclusion_type": "excluded",
                                "item_amount": {
                                    "value": 13.9482,
                                    "currency": "CAD"
                                }
                            },
                            {
                                "details": "5.75 % Goods & services tax",
                                "kind": "charge",
                                "base": {
                                    "percentage": 5.75,
                                    "kind": "percentage"
                                },
                                "item_amount": {
                                    "currency": "CAD",
                                    "value": 63.6525
                                },
                                "inclusion_type": "excluded",
                                "name": "Goods & services tax"
                            }
                        ],
                        "excluded_amount": {
                            "value": 228.9276,
                            "currency": "CAD"
                        },
                        "benefits": [],
                        "net_amount": {
                            "currency": "CAD",
                            "value": 1107
                        },
                        "gross_amount_hotel_currency": {
                            "value": 1107,
                            "currency": "CAD"
                        },
                        "all_inclusive_amount": {
                            "value": 1335.9276,
                            "currency": "CAD"
                        },
                        "gross_amount": {
                            "value": 1107,
                            "currency": "CAD"
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 1107
                        },
                        "charges_details": {
                            "translated_copy": "+CAD 228.93 taxes and charges",
                            "mode": "extra_charges",
                            "amount": {
                                "currency": "CAD",
                                "value": 229
                            }
                        }
                    }
                ],
                "included_taxes_and_charges_amount": {
                    "currency": "CAD",
                    "value": 0
                }
            },
            "hotel_name": "The Westin Bayshore, Vancouver",
            "districts": "1629,4185",
            "unit_configuration_label": "<b>Hotel room</b>: 2 beds",
            "review_recommendation": "",
            "is_smart_deal": 0,
            "city": "Vancouver (British Columbia)",
            "mobile_discount_percentage": 0,
            "review_nr": 943,
            "preferred": 0,
            "is_no_prepayment_block": 1,
            "cant_book": 0,
            "native_ad_id": "",
            "currency_code": "CAD",
            "ribbon_text": "Breakfast included",
            "children_not_allowed": 0,
            "type": "property_card",
            "timezone": "America/Vancouver",
            "block_ids": [
                "7629341_246420886_2_1_0"
            ],
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/446995032.jpg?k=cb6a6512ec6777d167bb05894146c8beb64e8315d1120bb3e08fbfa208c8c004&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/446995032.jpg?k=cb6a6512ec6777d167bb05894146c8beb64e8315d1120bb3e08fbfa208c8c004&o="
        },
        {
            "countrycode": "ca",
            "in_best_district": 0,
            "latitude": 49.2839003245265,
            "address": "645 Howe Street",
            "genius_discount_percentage": 0,
            "updated_checkin": null,
            "hotel_name_trans": "Metropolitan Hotel Vancouver",
            "is_free_cancellable": 1,
            "hotel_include_breakfast": 0,
            "is_mobile_deal": 0,
            "distance_to_cc": "0.30",
            "default_language": "en",
            "district": "Downtown Vancouver",
            "extended": 0,
            "selected_review_topic": null,
            "longitude": -123.118079602718,
            "native_ads_cpc": 0,
            "is_beach_front": 0,
            "hotel_id": 76222,
            "hotel_facilities": "444,490,449,205,495,134,488,467,185,103,198,161,465,451,424,187,176,142,504,3,253,5,4,433,26,441,107,16,11,23,506,80,448,462,435,484,489,459,492,8,437,454,203,257,69,76,48,472,301,446,493,305,108,420,22,101,447,505,47,436,425,121,464,65,499,440,494,453,51,445,407,2,64,133,25,70,450,160,109,43,443,496,468,158,96,124,91,455,186,6,461,466,498,81,86,181,422,485,99,10,439,188,195,457,491,7,28,423,502",
            "zip": "V6C 2Y9",
            "district_id": 1629,
            "has_swimming_pool": 1,
            "badges": [],
            "is_genius_deal": 0,
            "hotel_has_vb_boost": 0,
            "ufi": -575268,
            "price_is_final": 0,
            "class_is_estimated": 0,
            "url": "https://www.booking.com/hotel/ca/metropolitan-vancouver.html",
            "preferred_plus": 0,
            "native_ads_tracking": "",
            "matching_units_configuration": {
                "matching_units_common_config": {
                    "localized_area": null,
                    "unit_type_id": 9
                }
            },
            "is_city_center": 1,
            "soldout": 0,
            "review_score_word": "Excellent",
            "accommodation_type_name": "Hotel",
            "class": 4,
            "distances": [
                {
                    "icon_name": "bui_geo_pin",
                    "icon_set": null,
                    "text": "‎In city center‬"
                },
                {
                    "text": "‎This property has free cribs available‬",
                    "icon_name": "bui_baby_cot",
                    "icon_set": null
                }
            ],
            "city_trans": "Vancouver",
            "id": "property_card_76222",
            "min_total_price": 489,
            "review_score": 8.6,
            "bwallet": {
                "hotel_eligibility": 0
            },
            "updated_checkout": null,
            "address_trans": "645 Howe Street",
            "cc_required": 1,
            "main_photo_url": "https://cf.bstatic.com/xdata/images/hotel/square60/446992788.jpg?k=ecb0cfe43addbd4a1eee2019bbced398291d397a8546b7b737fae764691828cf&o=",
            "accommodation_type": 204,
            "city_in_trans": "in Vancouver",
            "default_wishlist_name": "Vancouver",
            "country_trans": "Canada",
            "distance": "0.28",
            "is_geo_rate": "",
            "checkin": {
                "from": "16:00",
                "until": ""
            },
            "distance_to_cc_formatted": "0",
            "wishlist_count": 0,
            "checkout": {
                "from": "",
                "until": "12:00"
            },
            "currencycode": "CAD",
            "currency_code": "CAD",
            "children_not_allowed": 0,
            "native_ad_id": "",
            "is_no_prepayment_block": 1,
            "cant_book": 0,
            "preferred": 0,
            "block_ids": [
                "7622226_277300247_2_2_0"
            ],
            "timezone": "America/Vancouver",
            "type": "property_card",
            "hotel_name": "Metropolitan Hotel Vancouver",
            "composite_price_breakdown": {
                "included_taxes_and_charges_amount": {
                    "currency": "CAD",
                    "value": 0
                },
                "product_price_breakdowns": [
                    {
                        "included_taxes_and_charges_amount": {
                            "currency": "CAD",
                            "value": 0
                        },
                        "items": [
                            {
                                "name": "TAX",
                                "inclusion_type": "excluded",
                                "item_amount": {
                                    "value": 101.1252,
                                    "currency": "CAD"
                                },
                                "base": {
                                    "kind": "percentage",
                                    "percentage": 20.68
                                },
                                "details": "20.68 % TAX",
                                "kind": "charge"
                            }
                        ],
                        "excluded_amount": {
                            "currency": "CAD",
                            "value": 101.1252
                        },
                        "benefits": [],
                        "net_amount": {
                            "currency": "CAD",
                            "value": 489
                        },
                        "gross_amount_hotel_currency": {
                            "value": 489,
                            "currency": "CAD"
                        },
                        "all_inclusive_amount": {
                            "value": 590.1252,
                            "currency": "CAD"
                        },
                        "gross_amount": {
                            "value": 489,
                            "currency": "CAD"
                        },
                        "gross_amount_per_night": {
                            "currency": "CAD",
                            "value": 489
                        },
                        "charges_details": {
                            "mode": "extra_charges",
                            "translated_copy": "+CAD 101.13 taxes and charges",
                            "amount": {
                                "value": 101,
                                "currency": "CAD"
                            }
                        }
                    }
                ],
                "items": [
                    {
                        "inclusion_type": "excluded",
                        "name": "TAX",
                        "item_amount": {
                            "value": 101.1252,
                            "currency": "CAD"
                        },
                        "base": {
                            "kind": "percentage",
                            "percentage": 20.68
                        },
                        "details": "20.68 % TAX",
                        "kind": "charge"
                    }
                ],
                "excluded_amount": {
                    "value": 101.1252,
                    "currency": "CAD"
                },
                "net_amount": {
                    "currency": "CAD",
                    "value": 489
                },
                "benefits": [],
                "all_inclusive_amount": {
                    "value": 590.1252,
                    "currency": "CAD"
                },
                "gross_amount_hotel_currency": {
                    "value": 489,
                    "currency": "CAD"
                },
                "gross_amount_per_night": {
                    "currency": "CAD",
                    "value": 489
                },
                "gross_amount": {
                    "value": 489,
                    "currency": "CAD"
                },
                "charges_details": {
                    "amount": {
                        "value": 101,
                        "currency": "CAD"
                    },
                    "mode": "extra_charges",
                    "translated_copy": "+CAD 101.13 taxes and charges"
                }
            },
            "city_name_en": "Vancouver (British Columbia)",
            "price_breakdown": {
                "currency": "CAD",
                "all_inclusive_price": 590.13,
                "has_incalculable_charges": 0,
                "gross_price": "489.00",
                "has_tax_exceptions": 0,
                "sum_excluded_raw": 101.13,
                "has_fine_print_charges": 1
            },
            "main_photo_id": 446992788,
            "cc1": "ca",
            "mobile_discount_percentage": 0,
            "review_nr": 197,
            "city": "Vancouver (British Columbia)",
            "review_recommendation": "",
            "is_smart_deal": 0,
            "districts": "1629",
            "unit_configuration_label": "<b>Hotel room</b>: 2 beds",
            "max_photo_url": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/446992788.jpg?k=ecb0cfe43addbd4a1eee2019bbced398291d397a8546b7b737fae764691828cf&o=",
            "max_1440_photo_url": "https://cf.bstatic.com/xdata/images/hotel/1440x1440/446992788.jpg?k=ecb0cfe43addbd4a1eee2019bbced398291d397a8546b7b737fae764691828cf&o="
        }
    ]
}
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { convertToOpacity, getColorFrom } from "../../../utilities/theme/ColorUtils";
import { IChatThreadMessageItemProps } from "./ChatThreadMessageItem.type";

const BORDER_RADIUS_BUBBLE = 10;
const BACKGROUND_COLOR_ALPHA = 0.1;

const styles = ({ isSelf, userRole }: Pick<IChatThreadMessageItemProps, 'isSelf' | 'userRole'>) => {
    const userSpecificStyle = !userRole ? {
        backgroundColor: convertToOpacity('#000000', BACKGROUND_COLOR_ALPHA),
        // backgroundColor: `linear-gradient(to right bottom, ${convertToOpacity(getColorFrom(EUserRole.HOST), BACKGROUND_COLOR_ALPHA)}, ${convertToOpacity(getColorFrom(EUserRole.COHOST), BACKGROUND_COLOR_ALPHA)}, ${convertToOpacity(getColorFrom(EUserRole.GUEST), BACKGROUND_COLOR_ALPHA)})`,
        border: 1,
        borderColor: '#000000',//borderColor has to be after border
    }
        : Array.isArray(userRole) ?
            {
                background: `linear-gradient(to right bottom, ${convertToOpacity(getColorFrom(userRole[0]), BACKGROUND_COLOR_ALPHA)}, ${convertToOpacity(getColorFrom(userRole[1]), BACKGROUND_COLOR_ALPHA)})`,
                border: 2,
                borderColor: 'black',
            }
            : {
                backgroundColor: convertToOpacity(getColorFrom(userRole), BACKGROUND_COLOR_ALPHA),
                border: 2,
                borderColor: getColorFrom(userRole),//borderColor has to be after border
            };
    return {
        container: {
            direction: 'row'
        },
        subContainer: {

        },
        bubble: {
            ...userSpecificStyle,
            padding: 1,
            borderTopLeftRadius: isSelf ? BORDER_RADIUS_BUBBLE : 0,
            borderTopRightRadius: isSelf ? 0 : BORDER_RADIUS_BUBBLE,
            borderBottomLeftRadius: BORDER_RADIUS_BUBBLE,
            borderBottomRightRadius: BORDER_RADIUS_BUBBLE,
        } as SxProps<Theme>
    }
};

export default styles;
import React from 'react';
import { Button, Divider, Paper, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { GuestsFilterSelection, IGuestsFilterSelectionProps } from '../../GuestsFilterSelection';
import { DateRangePicker, IDateRangePicker } from '../../../pages/SearchPage/DateRangePicker';
import { Trans, useTranslation } from 'react-i18next';
import { PropsWithLoadingState } from '../../../utilities';

interface PriceBreakdownItem {
    key?: string;
    label: string;
    price: string;
    extraInfo?: string;
}

export interface IListingBookingSectionProps {
    pricePerNight: string;
    totalPrice?: string;
    priceBreakdowns?: PriceBreakdownItem[];
    /** The dates filter selection */
    dates: IDateRangePicker;
    /** The guest filter selection */
    guests: IGuestsFilterSelectionProps;
    /** Callback on booking, button disabled if not provided */
    onBookClick?: () => void;
}

const renderPriceBreakdownLine = ({ key, label, price, extraInfo, loading }: PropsWithLoadingState<PriceBreakdownItem>) => {
    return (
        <Stack key={key || label} width={'100%'} direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={loading ? 5 : 2}>
            {!!extraInfo ?
                <Tooltip title={extraInfo}>
                    <Typography textAlign={'start'} flex={1} sx={{ textDecoration: 'underline' }}>{loading ? <Skeleton width={'100%'} /> : label}</Typography>
                </Tooltip>
                :
                <Typography textAlign={'start'} flex={1}>{loading ? <Skeleton width={'100%'} /> : label}</Typography>
            }
            <Typography textAlign={'end'} flex={1}>{loading ? <Skeleton width={'100%'} /> : price}</Typography>
        </Stack>
    )
}

export const ListingBookingSection = ({ pricePerNight, totalPrice, priceBreakdowns, dates, guests, onBookClick, loading }: PropsWithLoadingState<IListingBookingSectionProps>) => {
    const { t } = useTranslation();
    return (
        <Paper elevation={2} sx={{ padding: 2 }}>
            <Stack direction={'column'} justifyContent={'center'} alignItems={'stretch'} spacing={1}>
                {!loading ?
                    <>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'baseline'} spacing={1}>
                            <Trans
                                i18nKey={'listing.booking.pricePerNight'}
                                components={[<Typography variant={'h4'} />, <Typography variant={'h6'} />]}
                                values={{ amount: pricePerNight }}
                            />
                        </Stack>
                        <DateRangePicker {...dates} />
                        <GuestsFilterSelection {...guests} direction={'column'} />
                        <Button variant={'contained'} onClick={onBookClick} disabled={!onBookClick}>{t('listing.booking.bookButton')}</Button>
                        {!!priceBreakdowns?.length && totalPrice &&
                            <PriceBreakdown items={priceBreakdowns} totalPrice={totalPrice} />
                        }
                    </>
                    :
                    <>
                        <Typography variant={'h4'} width={'50%'} ><Skeleton /></Typography>
                        <Skeleton variant={'rectangular'} width={'100%'} height={100} />
                        <Button variant={'contained'} disabled={true}>{t('listing.booking.bookButton')}</Button>
                    </>
                }
            </Stack>
        </Paper>
    );
}

export interface IPriceBreakdownProps {
    items: PriceBreakdownItem[];
    totalPrice: string;
}
export const PriceBreakdown = ({ items, totalPrice, loading }: PropsWithLoadingState<IPriceBreakdownProps>) => {
    const { t } = useTranslation();
    if (!loading && !items?.length && !totalPrice) {
        return null;
    }
    return <Stack direction={'column'} justifyContent={'center'} alignItems={'stretch'} spacing={1} width={'100%'}>
        <Divider orientation={'horizontal'} sx={{ width: '100%' }}>{t('listing.booking.priceDetails')}</Divider>
        {(loading ? new Array(3).fill({}) : items).map((priceBreakdown, index) => renderPriceBreakdownLine({ ...priceBreakdown, loading, key: loading ? `price-breakdown-loading-${index}` : undefined }))}
        {(loading || !!items.length) && <Divider orientation={'horizontal'} sx={{ width: '100%', height: 2 }} />}
        {loading ?
            renderPriceBreakdownLine({ loading, key: `price-breakdown-loading-total` })
            :
            renderPriceBreakdownLine({ label: t('listing.booking.priceTotal'), price: totalPrice })}
    </Stack>
}
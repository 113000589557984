import React, { ReactElement } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ChatInboxItem, IChatInboxItemProps } from '../ChatInboxItem';
import { ChatSearch, IChatSearchProps } from '../ChatSearch';
import { useTranslation } from 'react-i18next';
import { PropsWithErrorState, PropsWithLoadingState } from '../../../utilities';

export interface IChatInboxProps<T> {
    /** The items of the side bar chat with the list of thread */
    items: T[];
    /** The function to render an item (taking React-Native FlatList as idea) */
    renderItem: (item: T) => ReactElement<IChatInboxItemProps>;
    /** Search functionality props, not showing if not provided */
    search?: IChatSearchProps;
}

/**
 * The Chat Side Bar, represent the list of thread with an optional search input
 * @param props  IChatInboxProps
 * @returns The render of the chat side bar
 */
export const ChatInbox = <T extends any>({ items, renderItem, search, error, loading }: PropsWithLoadingState<PropsWithErrorState<IChatInboxProps<T>>>) => {
    const { t } = useTranslation();
    return (
        <Stack direction={'column'}
            justifyContent={'flex-start'}
            alignItems={'stretch'}
            height={'92vh'}
            maxHeight={'92vh'}>
            {!error && !!search && <Box flex={0}><ChatSearch {...search} disabled={!!loading} /></Box>}
            {!error ?
                !loading && !items?.length ?
                    <>
                        <Typography variant='h5'>{t('chat.inbox.emptyTitle')}</Typography>
                        <Typography>{t('chat.inbox.emptyContent')}</Typography>
                    </>
                    :
                    <Box flex={1} overflow={'auto'}>
                        {
                            !loading ?
                                items?.map((item) => renderItem?.(item))
                                :
                                new Array(10).fill({}).map((_, index) => <ChatInboxItem key={`inbox-item-loading-${index}`} loading={true} />)
                        }
                    </Box>
                :
                <>
                    <Typography variant='h5'>{t('chat.inbox.errorTitle')}</Typography>
                    <Typography>{t('chat.inbox.errorContent')}</Typography>
                </>
            }
        </Stack>
    );
}
import React from 'react';
import { Route, Routes } from "react-router"
import { HelpFooter } from "../../../components/Footer/Footer"
import { FeatureNotAvailablePage } from '../../../pages/FeatureNotAvailablePage';
import { HelpPage, HelpInitPage, HelpTopicPage, HelpArticlePage } from '../../../pages/help';
import { ChoHelpCenterAppBar } from '../../../pages/help/ChoHelpCenterAppBar';
import { MaintenanceModePage } from '../../../pages/MaintenanceModePage';
import { NotFoundPage } from "../../../pages/NotFoundPage";
import { useRemoteConfig } from '../../../services/remoteConfig';
import { RC_HELP, RC_MAINTENANCE_MODE } from '../../../types/remote_config';
import { ROUTE_PARAMS, ROUTE_PATH } from "../RouterConfig";

export const HelpCenterRoutes = () => {
    const { [RC_HELP]: FF_Help, [RC_MAINTENANCE_MODE]: FF_Maintenance } = useRemoteConfig();
    if (!FF_Help.enabled) {
        return <FeatureNotAvailablePage />
    }
    if (FF_Maintenance.enabled && FF_Maintenance.subdomainsAffected.includes("HELP_CENTER")) {
        return <MaintenanceModePage />
    }

    return (
        <>
            {/* AppBar */}
            <Routes>
                {/* Default App bar */}
                <Route path={"*"} element={<ChoHelpCenterAppBar />} />
            </Routes>
            {/* Content */}
            <Routes>
                <Route path={ROUTE_PATH.home} element={<HelpPage />}>
                    <Route index element={<HelpInitPage />} />
                    {/* Topics */}
                    <Route path={ROUTE_PATH.help.topics}>
                        <Route index element={<HelpTopicPage />} />
                        <Route path={`:${ROUTE_PARAMS.topicID}`} element={<HelpTopicPage />} />
                    </Route>
                    {/* Articles */}
                    <Route path={ROUTE_PATH.help.articles}>
                        <Route index element={<HelpArticlePage />} />
                        <Route path={`:${ROUTE_PARAMS.articleID}`} element={<HelpArticlePage />} />
                    </Route>
                </Route>
                {/* Anything else */}
                <Route path={'*'} element={<NotFoundPage />} />
            </Routes>
            {/* Footer */}
            <Routes>
                {/* The footer showing in every other pages */}
                <Route path={"*"} element={<HelpFooter />} />
            </Routes>
        </>
    )
}
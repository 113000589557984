//File which regroup all utils/const to help with testing

/** Mock listing image */
export const LISTING_IMAGE_1 = 'https://www.triptipedia.com/img/accommodation5.jpg';
export const LISTING_IMAGE_2 = 'http://loghomeslifestyle.com/wp-content/uploads/2018/03/mountain-log-home-bedroom.jpg';
export const LISTING_IMAGE_3 = 'https://www.loghome.com/assets/img/cache/massachusetts_riverside_retreat_bedroom-1280x720.jpg';
export const LISTING_IMAGE_4 = 'https://www.triptipedia.com/img/log-home.jpg';
export const LISTING_IMAGE_5 = 'https://i.pinimg.com/originals/14/c3/68/14c368b66f4b8283f930923821c2b957.jpg';

/** Mock event images */
export const EVENT_IMAGE_1 = 'https://www.triptipedia.com/img/accommodation5.jpg';
export const EVENT_IMAGE_2 = 'http://loghomeslifestyle.com/wp-content/uploads/2018/03/mountain-log-home-bedroom.jpg';
export const EVENT_IMAGE_3 = 'https://www.loghome.com/assets/img/cache/massachusetts_riverside_retreat_bedroom-1280x720.jpg';
export const EVENT_IMAGE_4 = 'https://www.triptipedia.com/img/log-home.jpg';
export const EVENT_IMAGE_5 = 'https://i.pinimg.com/originals/14/c3/68/14c368b66f4b8283f930923821c2b957.jpg';

/** Mock shop product image */
export const SHOP_PRODUCT_IMAGE_1 = 'https://enhancedecommerce.appspot.com/images/orangeT.png';
export const SHOP_PRODUCT_IMAGE_2 = 'https://enhancedecommerce.appspot.com/images/yellowT.png';
export const SHOP_PRODUCT_IMAGE_3 = 'https://enhancedecommerce.appspot.com/images/greenT.png';
export const SHOP_PRODUCT_IMAGE_4 = 'https://enhancedecommerce.appspot.com/images/greyT.png';
export const SHOP_PRODUCT_IMAGE_5 = 'https://enhancedecommerce.appspot.com/images/azureT.png';

export const getMockedShopProductImage = (index?: number) => {
    switch ((index || 0) % 5) {
        case 0:
            return SHOP_PRODUCT_IMAGE_1;
        case 1:
            return SHOP_PRODUCT_IMAGE_2;
        case 2:
            return SHOP_PRODUCT_IMAGE_3;
        case 3:
            return SHOP_PRODUCT_IMAGE_4;
        case 4:
            return SHOP_PRODUCT_IMAGE_5;
        default:
            return SHOP_PRODUCT_IMAGE_1;
    }
}

/** Mock user images */
export const USER_IMAGE_1 = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFnG0huY6whcqQtmgJDP7XgSb8VCpmLUnKXw&usqp=CAU';
export const USER_IMAGE_2 = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw7QkfjQ7yvMpDiPlgagN_hYtCrd2acymT1TDim7Kyt-WSAFhtXgHeZ_W0y_MAnxXtJqM&usqp=CAU';
export const USER_IMAGE_3 = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTraQqd74PWQiILjbnCfSYDPwxPxzaucoJ2XXzN9uCfhtnm-JbJLtodtN91LHMutEewcxE&usqp=CAU';
export const USER_IMAGE_4 = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgY2WJcq5Kc6dBwxsOG1d0ThNAuBifIMt7rbSMEGCaDp7TdA2_Hgw5cXLQT9cCnirO4X4&usqp=CAU';
export const USER_IMAGE_5 = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-JMJjLtbbTyRzV7atG8YMSpUrEHw8V5IU7wDfU03RUPxJh4icHPVkCZ21u3vpwH4BZCQ&usqp=CAU';

import React from "react";
import { Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CategoryItem } from "../../../components/CategoryItem";
import { WEBSITE_BASE_URL } from "../../../types/constants";

//only propose options with earn money in the career page TODO reuse CategorySection
type targetType = 'host' | 'cohost';

/** Career part to invite people to actually use the platform as host or cohost (copy of the home page pretty much) */
export const CareerMembersPage = () => {
    const { t } = useTranslation();

    const renderCategorySection = (target: targetType) => {
        const categoriesKey = `categories_${target}`;
        const memberKey = `careers.members_${target}`;

        return (
            <Stack sx={{ px: 2 }} direction={'column'}>
                <Typography fontWeight={'bold'} variant={'h4'}>{t(`${memberKey}.title`)}</Typography>
                <Typography variant={'subtitle2'} sx={{ py: 1 }}>{t(`${memberKey}.content`)}</Typography>
                <Grid container spacing={2} justifyContent={'center'}>
                    {(t(`${categoriesKey}.list`, { returnObjects: true }) as Array<{ title: string, description: string, image: string, type: string }>)
                        .map((category, index) => (
                            !!index && //bypass the first option
                            <Grid key={index} item xs={12} sm={6} md={4}>
                                <CategoryItem
                                    key={index}
                                    imgSrc={category.image}
                                    title={category.title}
                                    description={category.description}
                                    onClick={() => window.open(WEBSITE_BASE_URL)}
                                />
                            </Grid>
                        ))}
                </Grid>
            </Stack>
        );
    }
    return (
        <Container sx={{ p: 2 }}>
            <Stack direction={'column'} sx={{ mb: 2 }} spacing={1}>
                <Typography variant="h2" fontWeight={"bold"}>{t('careers.members.title')}</Typography>
                <Typography>{t('careers.members.content')}</Typography>
                <Stack direction={'column'} spacing={2}>
                    {renderCategorySection('host')}
                    <Divider />
                    {renderCategorySection('cohost')}
                </Stack>
            </Stack>
        </Container>
    );
};

import React, { useMemo } from "react";
import { Tag } from "@mui/icons-material";
import { Container, Stack, Typography, Skeleton, IconButton, Box, Chip, Grid, Link } from "@mui/material";
import MuiMarkdown from "mui-markdown";
import { useTranslation, Trans } from "react-i18next";
import { PropsWithErrorState, PropsWithLoadingState } from "../../utilities";
import { IssueStateView } from "../IssueStateView";
import { INewsItemProps, NewsItem } from "./NewsItem";
import { isAfter, isSameDay } from "date-fns";

interface INewsStoryScreenProps {
    /** The title of the story */
    title: string;
    /** The subtitle of the story */
    subtitle: string;
    /** The date of the article */
    timeline: { publishedAt: string | Date, updatedAt: string | Date };
    /** The author of the story */
    author: {
        displayName: string;
        image?: string;
        onClick?: () => void;
    },
    /** The category of the story */
    category: {
        label: string;
        onClick: () => void;
    },
    /** The list of shareable button */
    shareWith: {
        label?: string;
        icon: React.JSX.Element;
        onClick: () => void;
    }[];
    /** The tags on this story */
    tags: {
        label: string;
        onClick: () => void;
    }[],
    /** The main image url */
    image: string;
    /** The content (in markdown) */
    content: string;
    /** The related stories */
    relatedStories?: INewsItemProps[];
}

/** Component displaying a single story screen */
export const NewsStoryScreen = ({
    title,
    subtitle,
    image,
    timeline,
    author,
    category,
    shareWith,
    tags,
    content,
    relatedStories,
    loading,
    error
}: PropsWithErrorState<PropsWithLoadingState<INewsStoryScreenProps>>) => {
    const { t } = useTranslation();

    const isUpdated = useMemo(() => {
        if (!timeline || !timeline.updatedAt) {
            return false;
        }
        const updatedAt = typeof timeline.updatedAt === 'string' ?
            new Date(timeline.updatedAt) : timeline.updatedAt;
        const createdAt = typeof timeline.publishedAt === 'string' ?
            new Date(timeline.publishedAt) : timeline.publishedAt;
        if (isSameDay(updatedAt, createdAt)) {
            return false;//no update if same day
        };
        return isAfter(updatedAt, createdAt)
    }, [timeline]);

    if (!!error) {
        return (
            <IssueStateView title={t('news.story.errorTitle')}
                description={t('news.story.errorContent')}
                button={typeof error !== 'boolean' && !!error.onRetryClick ? {
                    text: t('news.story.errorButton'),
                    onActionClick: error.onRetryClick
                } : undefined} />
        )
    }

    return (
        <Container sx={{ p: 4 }} maxWidth={'md'}>
            <Stack direction={'column'} spacing={1}>
                <Typography variant='h1'>{!loading ? title : <Skeleton />}</Typography>
                {!loading && !!subtitle && <Typography variant='h2'>{subtitle}</Typography>}
                {!loading ?
                    <Trans
                        parent={Typography}
                        i18nKey={'news.story.info'}
                        components={[
                            <Link color={'inherit'} onClick={author.onClick} />,
                            <Link color={'inherit'} onClick={category.onClick} />
                        ]}
                        values={{
                            author: author.displayName,
                            date: typeof timeline.publishedAt === 'string' ? timeline.publishedAt : timeline.publishedAt.toDateString(),
                            category: category.label,
                            update: typeof timeline.updatedAt === 'string' ? timeline.updatedAt : timeline.updatedAt.toDateString(),
                        }}
                        context={isUpdated ? 'updated' : undefined}
                    />
                    // <Typography>By <Link color={'inherit'} onClick={author.onClick}>{author.displayName}</Link> - {typeof timeline.createdAt === 'string' ? timeline.createdAt : timeline.createdAt.toDateString()} - <Link color={'inherit'} onClick={category.onClick}>{category.label}</Link></Typography>
                    :
                    <Skeleton />
                }
                {!loading && <Stack direction={'row'} flexWrap={'wrap'} spacing={1}>
                    {/* //share with */}
                    {
                        shareWith.map((social, index) =>
                            <IconButton key={`share-social-${social.label || index}`} onClick={social.onClick}>
                                {social.icon}
                            </IconButton>
                        )
                    }
                </Stack>
                }
                {!loading ?
                    !!image &&
                    <Box
                        component={"img"}
                        src={image}
                        sx={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover',
                            borderRadius: 3
                        }}
                    />
                    :
                    <Skeleton variant='rectangular' height={150} />
                }
                {!loading ?
                    <MuiMarkdown>{content}</MuiMarkdown>
                    :
                    <>
                        <Typography><Skeleton /></Typography>
                        <Typography><Skeleton /></Typography>
                    </>
                }
                {
                    !loading ?
                        !!tags.length &&
                        <Stack direction={'row'} flexWrap={'wrap'} spacing={1}>
                            {tags.map((tag, index) => <Chip key={`tag-${tag}-${index}`} icon={<Tag />} variant={'filled'} size={'small'} label={tag.label} onClick={tag.onClick} />)}
                        </Stack>
                        :
                        <Stack direction={'row'} flexWrap={'wrap'} spacing={1}>
                            {new Array(3).fill({}).map((_, index) => <Chip key={`tag-${index}-loading`} icon={<Tag />} variant={'filled'} size={'small'} />)}
                        </Stack>
                }
                {
                    !loading ?
                        !!relatedStories?.length &&
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant='h3'>{t('news.story.relatedStoriesTitle')}</Typography>
                            </Grid>
                            {
                                relatedStories.map((story, index) =>
                                    <Grid item key={`related-story-${index}`} xs={12} sm={4}>
                                        <NewsItem {...story} />
                                    </Grid>
                                )
                            }
                        </Grid>
                        :
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant='h3'><Skeleton /></Typography>
                            </Grid>
                            {
                                new Array(6).fill({}).map((_, index) =>
                                    <Grid item key={`related-story-${index}-loading`} xs={12} sm={4}>
                                        <NewsItem loading />
                                    </Grid>
                                )
                            }
                        </Grid>
                }
            </Stack>
        </Container>
    )
}

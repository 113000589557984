import React from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DEFAULT_CURRENCY, currencyFormatter } from "../../../utilities/currency/function";
import { getPriceAmount } from "../../../utilities/listings";

interface IViewPricingDisplayProps {
    currency?: string;
    nightlyPrice: number;
    weeklyDiscount: number;
    monthlyDiscount: number;
    extraDiscount?: number;
}

/** The Component to display the pricing of a listing (in the listing edit) */
export const PricingDisplay = ({ currency = DEFAULT_CURRENCY, nightlyPrice, weeklyDiscount, monthlyDiscount, extraDiscount }: IViewPricingDisplayProps) => {
    const { t } = useTranslation();
    const price = { nightly_price: { amount: nightlyPrice, currency }, weekly_discount: weeklyDiscount, monthly_discount: monthlyDiscount };
    const calculateTotDiscount = (originalDiscount?: number, extraDiscount?: number) => {
        // (1-d1)(1-d2) = 1 - d1 - d2 + d1*d2 = 1 - (d1+d2-d1*d2)
        const d1 = (originalDiscount || 0) / 100;
        const d2 = (extraDiscount || 0) / 100;
        const totDiscount = (d1 + d2 - d1 * d2) * 100;
        return totDiscount.toFixed(2);
    }
    return (
        <Stack direction={'column'}
            alignItems={'stretch'}
            spacing={2}>
            <Typography>{t('listing.pricing.pricingDisplay', {
                price: currencyFormatter({ quantity: getPriceAmount(price, 'daily', extraDiscount), currency }),
                period: t('listing.pricing.dayPeriod')
            })}</Typography>
            <Typography>{t('listing.pricing.pricingDisplay', {
                context: 'discount',
                price: currencyFormatter({ quantity: getPriceAmount(price, 'weekly', extraDiscount), currency }),
                period: t('listing.pricing.weekPeriod'),
                discount: calculateTotDiscount(weeklyDiscount, extraDiscount),
            })}</Typography>
            <Typography>{t('listing.pricing.pricingDisplay', {
                context: 'discount',
                price: currencyFormatter({ quantity: getPriceAmount(price, 'monthly', extraDiscount), currency }),
                period: t('listing.pricing.monthPeriod'),
                discount: calculateTotDiscount(monthlyDiscount, extraDiscount),
            })}</Typography>
        </Stack>
    )
}


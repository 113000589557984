import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { CategoryItem } from "../CategoryItem";

export interface ICategorySectionProps {
    /** The title of the category */
    title: string;
    /** The description of the category */
    description: string;
    /** The array of subcategories */
    categories: { title: string, description: string, image: string, onClick: () => void }[]
}

/** Component which render a section (used for categories in home page and in career subdomain) */
export const CategorySection = ({ title, description, categories }: ICategorySectionProps) => {
    return (
        <Stack sx={{ px: 2 }} direction={'column'}>
            <Typography fontWeight={'bold'} variant={'h4'}>{title}</Typography>
            <Typography variant={'subtitle2'} sx={{ py: 1 }}>{description}</Typography>
            <Grid container spacing={2}>
                {categories
                    .map((category, index) => (
                        <Grid key={index} item xs={12} sm={6} md={3}>
                            <CategoryItem
                                key={index}
                                imgSrc={category.image}
                                title={category.title}
                                description={category.description}
                                onClick={category.onClick}
                            />
                        </Grid>
                    ))}
            </Grid>
        </Stack>
    );
}
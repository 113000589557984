/** The remote config for the donation */
export const RC_DONATION = "donation";

export type Donation = {
    /** Boolean indicating if the donation is enabled */
    enabled: boolean;
    /** The text in the donation banner */
    text: string;
    /** The text in the button of the banner */
    label: string;
    /** The stripe checkout link for single donation */
    link: string;
}

export const DEFAULT_DONATION: Donation = {
    "enabled": false,
    "text": "Support CoHostOp by a donation which will be used to cover our basic fees.",
    "label": "Donate",
    "link": "https://donate.stripe.com/test_4gw4ird8I3ez5Uc6oo"
}
import React from "react";
import { AccountCircle, ChildCare, Crib, Pets } from "@mui/icons-material";
import { Stack, TextField, InputAdornment, StackProps, Typography, TypographyProps } from "@mui/material";
import { useTranslation } from "react-i18next";

type TFilter = { value: number, onChange: (value: number) => void }
export interface IGuestsFilterSelectionProps {
    /** The filter for number of adults */
    adults: TFilter
    /** The filter for number of children */
    children?: TFilter
    /** The filter for number of infants */
    infants?: TFilter
    /** The filter for number of pets */
    pets?: TFilter
    /** The direction those filters should be displayed */
    // direction:ResponsiveStyleValue<"column" | "row" | "row-reverse" | "column-reverse">;
    direction?: StackProps['direction'];
}

export const GuestsFilterSelection = ({ adults, children, infants, pets, direction }: IGuestsFilterSelectionProps) => {
    const { t } = useTranslation();
    return (
        <Stack direction={direction || { xs: 'column', sm: 'row' }} spacing={2} justifyContent={'space-evenly'} alignItems={'stretch'}>
            <TextField label={t('listings.filters.adultLabel')}
                variant={"outlined"}
                type={'number'}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: "1", max: "10", step: "1" }}
                InputProps={{
                    startAdornment:
                        <InputAdornment position="start"><AccountCircle /></InputAdornment>
                }}
                value={adults.value || ''}
                onChange={(event) => adults.onChange(+event.target.value)}
                fullWidth
            />
            {children &&
                <TextField label={t('listings.filters.childLabel')}
                    variant={"outlined"}
                    type={'number'}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: "0", max: "5", step: "1" }}
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start"><ChildCare /></InputAdornment>
                    }}
                    value={children.value || ''}
                    onChange={(event) => children.onChange(+event.target.value)}
                    fullWidth
                />
            }
            {infants &&
                <TextField label={t('listings.filters.infantLabel')}
                    variant={"outlined"}
                    type={'number'}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: "0", max: "3", step: "1" }}
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start"><Crib /></InputAdornment>
                    }}
                    value={infants.value || ''}
                    onChange={(event) => infants.onChange(+event.target.value)}
                    fullWidth
                />
            }
            {pets &&
                <TextField label={t('listings.filters.petLabel')}
                    variant={"outlined"}
                    type={'number'}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: "0", max: "3", step: "1" }}
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start"><Pets /></InputAdornment>
                    }}
                    value={pets.value || ''}
                    onChange={(event) => pets.onChange(+event.target.value)}
                    fullWidth
                />
            }
        </Stack>
    )
}

interface IGuestsSelectionDisplayProps {
    /** The filter for number of adults */
    adults?: Pick<TFilter, 'value'> | number;
    /** The filter for number of children */
    children?: Pick<TFilter, 'value'> | number;
    /** The filter for number of infants */
    infants?: Pick<TFilter, 'value'> | number;
    /** The filter for number of pets */
    pets?: Pick<TFilter, 'value'> | number;
    /** The style of the text */
    style?: TypographyProps['sx'];
}

/** The display (non-editable) of the guest selection */
export const GuestsSelectionDisplay = ({ adults, children, infants, pets, style }: IGuestsSelectionDisplayProps) => {
    const { t } = useTranslation();
    const adultsValue = typeof adults === 'number' ? adults : adults?.value;
    const childrenValue = typeof children === 'number' ? children : children?.value;
    const infantsValue = typeof infants === 'number' ? infants : infants?.value;
    const petsValue = typeof pets === 'number' ? pets : pets?.value;
    const valuesList = [
        {
            value: adultsValue,
            label: t("listings.filters.adult", { context: 'count', count: adultsValue || 0, })
        },
        {
            value: childrenValue,
            label: t("listings.filters.child", { context: 'count', count: childrenValue || 0, })
        },
        {
            value: infantsValue,
            label: t("listings.filters.infant", { context: 'count', count: infantsValue || 0, })
        },
        {
            value: petsValue,
            label: t("listings.filters.pet", { context: 'count', count: petsValue || 0, })
        },
    ].filter(val => !!val.value)
        .map(val => val.label);

    return <Typography sx={style}>
        {!!valuesList.length ?
            t("listings.filters.guestSummary", {
                values: valuesList
            })
            :
            t('listings.filters.guestSummaryNone')}
    </Typography>
}
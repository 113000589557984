import React, { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ChatThreadDetails, IChatThreadDetailsProps } from '../../components/chat/ChatThreadDetails';
import { ChatChannelContext } from '../../context/chat';
import { getUIUserRoleFrom } from '../../utilities/EnumUtility';

import { ROUTE_PARAMS, ROUTE_PATH } from '../../utilities/navigation';

/** The details of a chat/thread */
export const ChatDetailsPage = () => {
    const navigate = useNavigate();
    const { [ROUTE_PARAMS.threadID]: threadID } = useParams();

    if (!threadID) {
        return null;
    }

    return (
        <ChatDetailsContainer
            channelId={threadID}
            navigateToProfile={(memberID) => navigate(ROUTE_PATH.userBuild(memberID))}
            closeDetails={() => navigate(ROUTE_PATH.inboxBuild(threadID, false))} />
    );
}

interface IChatDetailsContainerProps {
    /** The channel id we want the details from */
    channelId: string;
    /** Callback on a member click (should navigate to profile page) */
    navigateToProfile: (userId: string) => void;
    /** Callback to close itself */
    closeDetails: () => void;
}
const ChatDetailsContainer = ({ channelId, navigateToProfile, closeDetails }: IChatDetailsContainerProps) => {
    const chatDetailsContext = useContext(ChatChannelContext);
    if (!chatDetailsContext) {
        throw new Error("Need ChatChannelContext");
    }
    const { members } = chatDetailsContext;

    const memberItems = useMemo(() =>
        Object.keys(members).map(memberID => {
            const member = members[memberID];
            return {
                displayName: member.displayName,
                avatarSrc: member.avatar,
                role: getUIUserRoleFrom(member.role),
                onPress: () => navigateToProfile(memberID),
            }
        })
        , [navigateToProfile, members])

    const props: IChatThreadDetailsProps = {
        members: memberItems,
        onCloseClick: closeDetails,
    };

    return <ChatThreadDetails {...props} />
}
import { FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PropsWithLoadingState } from '../../../utilities';
import { IShopPriceProps, ShopPrice } from '../ShopPrice';

export interface IShopCartItemProps {
    /** The title of the product item */
    title: string;
    /** The image of the product item */
    image: string;
    /** (optional) The description of the product */
    description?: string;
    /** Callback on item click (should redirect to product detail)*/
    onClick?: () => void;
    /** The quantity of this item */
    quantity: number;
    /** Callback on change of quantity for this specific item */
    onQuantityChange: (quantity: number) => void;
    /** The unit price of the item */
    price: IShopPriceProps;
}

export const ShopCartItem = ({
    title,
    image,
    description,
    onClick,
    quantity,
    onQuantityChange,
    price,
    loading
}: PropsWithLoadingState<IShopCartItemProps>) => {
    const { t } = useTranslation();
    const handleQuantityChange = (event: SelectChangeEvent<number>) => {
        if (!!loading) return;
        onQuantityChange(event.target.value as number)
    }
    return (
        <Paper elevation={1} sx={{ px: 2, py: 1 }}>
            <Stack direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                spacing={2}>
                {!loading ?
                    <img alt={title}
                        src={image}
                        style={{ objectFit: 'contain', maxWidth: '20%', maxHeight: '15vh' }}
                        onClick={onClick} />
                    :
                    <Skeleton variant='rectangular' sx={{ height: 100, width: '20%' }} />
                }
                <Stack direction={'column'}
                    justifyContent={'center'}
                    alignItems={'stretch'}
                    flex={1}
                    spacing={1}>
                    <Typography
                        fontWeight={"bold"}
                        onClick={onClick}>
                        {!loading ? title : <Skeleton variant='text' sx={{ width: '50%' }} />}
                    </Typography>
                    {!loading ?
                        !!description && <Typography>{description}</Typography>
                        :
                        <Skeleton variant='text' />
                    }
                </Stack>
                <Stack direction={'column'}
                    justifyContent={'center'}
                    alignItems={'end'}
                    spacing={1}>
                    {!loading ?
                        <ShopPrice {...price} />
                        :
                        <Skeleton variant='rectangular' sx={{ height: 42, width: 42 }} />
                    }
                    {!loading ?
                        <FormControl size="small">
                            <InputLabel>{t('shop.cart.quantityLabel')}</InputLabel>
                            <Select
                                value={quantity}
                                label={t('shop.cart.quantityLabel')}
                                onChange={handleQuantityChange}
                            >
                                <MenuItem key={`quantity-choice-${0}`} value={0}>
                                    <em>0({t('shop.cart.removeFromCartLabel')})</em>
                                </MenuItem>
                                {new Array(9).fill('').map((_, index) => {
                                    return <MenuItem key={`quantity-choice-${index + 1}`}
                                        value={index + 1}>{`${index + 1}`}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        :
                        <Skeleton variant='rectangular' sx={{ height: 42, width: 42 }} />
                    }
                </Stack>
            </Stack>
        </Paper>
    );
}

const priceCalculation = (price: IShopPriceProps, quantity: number): IShopPriceProps => {
    return {
        originalPrice: {
            amount: price.originalPrice.amount * quantity,
            currency: price.originalPrice.currency,
        },
        discountedPrice: price.discountedPrice ? {
            amount: price.discountedPrice.amount * quantity,
        } : undefined,
    }
}

export const ShopCartCheckoutItem = ({
    title,
    image,
    quantity,
    price,
    loading
}: PropsWithLoadingState<Pick<IShopCartItemProps, 'title' | 'image' | 'quantity' | 'price'>>) => {

    return (
        <Stack direction={'row'}
            justifyContent={'start'}
            alignItems={'center'}
            spacing={1}
        >
            {!loading ?
                <img src={image} style={{ objectFit: 'contain', height: 50, width: 50 }} alt={title} />
                :
                <Skeleton height={50} width={50} />
            }
            <Typography>{!loading ? quantity : <Skeleton />}</Typography>
            <Typography>x</Typography>
            {!loading ? <ShopPrice {...price} /> : <Skeleton />}
            <Typography>=</Typography>
            {!loading ? <ShopPrice {...priceCalculation(price, quantity)} /> : <Skeleton />}
        </Stack>
    );
}
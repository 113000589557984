import React from "react"
import { useInfiniteQuery, useQuery } from "react-query";
import { IForumPostCardProps } from "../../components/forums/ForumPostCard/ForumPostCard";
import { IForumPostItemProps } from "../../components/forums/ForumPostItem/ForumPostItem";
import { ForumTopicScreen, IForumTopicScreenProps } from "../../components/forums/ForumTopicScreen/ForumTopicScreen"
import { getPostsList } from "../../services/forums";
import { FORUMS_POST_LIKES_COUNT_FIELD, FORUMS_POST_TIMESTAMP_FIELD, ForumPost } from "../../types/db";
import { EForumTopic } from "../../utilities/forums";
import { ForumRelatedArticlesContainer } from "./ForumRelatedArticlesContainer";

/** The number of post per page of fetching data */
const POSTS_PER_PAGE = 10;
/** The limit for the featured posts */
const FEATURED_POST_LIMIT = 3;
/** The limit for the featured posts */
const LATEST_POST_LIMIT = 3;

export interface IForumsTopicPostsContainerProps extends Pick<IForumTopicScreenProps, 'banner' | 'onCreateNewPostClick'> {
    /** The topic to filter with, if not provided will look for all */
    topic?: EForumTopic;
    /** Callback Navigation to specific forum post */
    navigateToPost: (postId: string) => void;
    /** Callback Navigation to specific help center article */
    navigateToArticle: (articleId: string) => void;
}

/** Container taking care of fetching a list of post with a specific topic */
export const ForumsTopicPostsContainer = ({ topic, navigateToPost, navigateToArticle, ...restProps }: IForumsTopicPostsContainerProps) => {
    const { data: featuredData, isLoading: isFeaturedLoading } = useQuery(
        ["featuredPost", (topic || "all").toString()],
        () => getPostsList(topic, { orderBy: { field: FORUMS_POST_LIKES_COUNT_FIELD, dir: "desc" }, limit: FEATURED_POST_LIMIT }),
        { refetchOnWindowFocus: false }
    );
    const { data: latestData, isLoading: isLatestLoading } = useQuery(
        ["latestPosts", (topic || "all").toString()],
        () => getPostsList(topic, { orderBy: { field: FORUMS_POST_TIMESTAMP_FIELD, dir: "desc" }, limit: LATEST_POST_LIMIT }),
        {
            enabled: !topic,//only want the latest posts if not in a specific topic page
            refetchOnWindowFocus: false,
        }
    );
    const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
        ["posts", (topic || "all").toString()],
        ({ pageParam }) => getPostsList(topic, { orderBy: { field: FORUMS_POST_TIMESTAMP_FIELD, dir: "desc" }, limit: POSTS_PER_PAGE, startAfter: pageParam }),
        {
            enabled: !!topic,//only want the list of post if in a specific topic,
            getNextPageParam: (_, pages) => {
                const lastPage: ForumPost[] = pages[pages.length - 1];
                if (lastPage.length < POSTS_PER_PAGE) {
                    //if last page was not fetching a complete page, we don't have more to fetch
                    return undefined;
                }
                return lastPage[lastPage.length - 1];//the last item of the last page

            },
            refetchOnWindowFocus: false,
        }
    );

    return <ForumTopicScreen {...restProps}
        featuredPosts={
            {
                topic,
                posts: isFeaturedLoading ?
                    { loading: true, length: FEATURED_POST_LIMIT }
                    : featuredData?.map(post => mapToPostCard(post, () => navigateToPost(post.id))) ?? []
            }
        }
        latestPosts={
            {
                topic,
                posts: !!topic ? [] : isLatestLoading ?
                    { loading: true, length: LATEST_POST_LIMIT }
                    : latestData?.map(post => mapToPostCard(post, () => navigateToPost(post.id))) ?? []
            }
        }
        posts={
            !topic ? undefined :
                isLoading ? { loading: true, length: POSTS_PER_PAGE }
                    : (data?.pages.flatMap(page => page.map(post => mapToPostItem(post, () => navigateToPost(post.id)))) ?? [])
        }
        onLoadMorePostsClick={(!topic || !hasNextPage || isLoading) ? undefined : fetchNextPage}
        relatedArticles={<ForumRelatedArticlesContainer
            topic={topic}
            navigateToArticle={navigateToArticle} />} />
}

const mapToPostCard = (post: ForumPost, onClick: () => void): IForumPostCardProps => {
    return {
        author: { avatar: post.author.avatar_url || '', displayname: post.author.display_name },
        title: post.title,
        content: post.content,
        onClick
    }
}

const mapToPostItem = (post: ForumPost, onClick: () => void): IForumPostItemProps => {
    return {
        author: { avatar: post.author.avatar_url || '', displayname: post.author.display_name },
        title: post.title,
        content: post.content,
        likesCount: post.likes_count || 0,
        repliesCount: post.replies_count || 0,
        timestamp: post.timestamp.toDate(),
        onClick
    }
}
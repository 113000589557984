import React, { } from 'react';
import { NewsMediaAssetContainer } from '../../containers/news/NewsMediaAssetContainer';

/** The navigation of the Media Assets Page */
export const NewsMediaPage = () => {
    return <NewsMediaAssetContainer />;
}





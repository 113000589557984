import React from 'react';
import { Avatar, Button, Divider, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { ELinkedAccount, EMedal, getLinkedAccountName, renderLinkedAccountIcon } from '../../../utilities/EnumUtility';
import { Star, VerifiedUser, WorkspacePremium } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { PropsWithLoadingState } from '../../../utilities';

export interface IProfileSummaryProps {
    displayName: string;
    avatarImgSrc?: string;
    reviews?: {
        amount: number;
        onReviewClick?: () => void;
    };
    verifications?: {
        isProfileVerified?: boolean;
        items: (ELinkedAccount | string)[];
        onAddVerificationClick?: () => void;
    };
    medals?: {
        items: EMedal[]
        onMedalSectionClick?: () => void;
    };
}

/**
 * ProfileSummary is the container with the Avatar+name+list of verified linked account like email/phone/facebook...
 */
export const ProfileSummary = ({
    displayName,
    avatarImgSrc,
    reviews,
    verifications,
    medals,
    loading
}: PropsWithLoadingState<IProfileSummaryProps>) => {
    const { t } = useTranslation();
    const renderDivider = () => {
        return <Divider variant="fullWidth" sx={{ width: '100%', my: 1 }} />
    }
    return (
        <Paper elevation={2} sx={{ maxWidth: 200, width: '100%', padding: 2 }}>
            <Stack direction={'column'} justifyContent={'start'} alignItems={'center'}>
                {!loading ?
                    <Avatar src={avatarImgSrc} alt={displayName} variant={'circular'} sx={{ width: 70, height: 70 }} />
                    :
                    <Skeleton variant={'circular'} width={70} height={70} />
                }
                <Stack direction={'row'}>
                    {!loading && !!verifications?.isProfileVerified && <VerifiedUser style={{ color: blue[500] }} />}
                    <Typography textAlign={'center'}>{!loading ? displayName : <Skeleton width={50} />}</Typography>
                </Stack>
                {!loading && !!reviews?.amount &&
                    <Stack direction={'row'}>
                        <Star />
                        <Typography onClick={reviews.onReviewClick}>{t('user.profile.reviewCountTitle', { count: reviews.amount })}</Typography>
                    </Stack>}

                {!loading && !!medals?.items.length &&
                    <>
                        {renderDivider()}
                        {medals.items.map((medal, index) =>
                            <Stack key={`profile-info-medal_${index}`} direction={'row'} alignSelf={'start'}>
                                <WorkspacePremium />
                                <Typography onClick={medals.onMedalSectionClick}>{medal.toString()}</Typography>
                            </Stack>
                        )}
                    </>
                }
                {renderDivider()}
                {!loading ?
                    (!!verifications?.items.length) ?
                        verifications.items.map((account, index) =>
                            <Stack key={`profile-info-verification_${index}`} direction={'row'} alignSelf={'start'}>
                                {renderLinkedAccountIcon(typeof account === 'string' ? ELinkedAccount.OTHER : account)}
                                <Typography>{typeof account === 'string' ? account : getLinkedAccountName(account)}</Typography>
                            </Stack>)
                        :
                        <Typography textAlign={'center'}>{t('user.profile.verificationEmpty')}</Typography>
                    :
                    <Skeleton variant={'rectangular'} width={'100%'} height={50} />
                }
                {
                    !loading && !!verifications?.onAddVerificationClick &&
                    <Button onClick={verifications.onAddVerificationClick}>{t('user.profile.addVerificationButton')}</Button>
                }
            </Stack >
        </Paper >
    );
}
import { IShopCartProps } from "./ShopCart";
import { IShopPriceProps } from "../ShopPrice";

export const calculateTotalCartValue = ({ items }: Pick<IShopCartProps, 'items'>): IShopPriceProps => {
    const tot = items.reduce((prev, curr) =>
        prev + (curr.price.discountedPrice?.amount ?? curr.price.originalPrice.amount) * curr.quantity
        , 0);
    const currency = items[0]?.price.originalPrice.currency || 'USD';
    return {
        originalPrice: {
            amount: tot,
            currency,
        }
    }
}
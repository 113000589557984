import React, { createContext, PropsWithChildren } from "react";
import { useUserInbox } from "../../hooks/chat/useUserInbox";

interface IChatInboxContext extends ReturnType<typeof useUserInbox> {
    /** Function to create a new channel. Promise returning the channel id */
    // create(channel: DeepPartial<Omit<ChannelInfo, 'id'>>): Promise<string>;
    /** Function to update a channel */
    // update(channelId: string, channelInfo: DeepPartial<Omit<ChannelInfo, 'id'>>): Promise<void>;
    /** Function to delete a channel */
    // delete(channelId: string): Promise<void>;
    /** Function to load more channels (pagination) */
    // loadMoreChannels(): Promise<void>;
}

/** The chat inbox context providing channels list and functionality to all children */
export const ChatInboxContext = createContext<IChatInboxContext | undefined>(undefined);

interface IChatInboxContextProviderProps {
    /** The userId we are retrieving the inbox from */
    userId: string;
}

export const ChatInboxContextProvider = ({ userId, children }: PropsWithChildren<IChatInboxContextProviderProps>) => {
    const { channels, isLoading, error } = useUserInbox(userId);

    return (
        <ChatInboxContext.Provider value={{
            channels,
            isLoading,
            error,
            // create: create_,
            // update: update_,
            // delete: delete_,
        }
        }>
            {children}
        </ChatInboxContext.Provider>
    );
};

import React, { useContext } from "react";
import { To } from "react-router";
import { AccountSubPageView } from "../../../components/user/account/AccountSubPageView";
import { ROUTE_PATH } from "../../../utilities/navigation";
import { AccountPageView, getTitleFromAccountPage } from "../../../components/user/account/AccountPageView";
import { UserContext } from "../../../context/UserInformation";
import { AccountSubContainer } from "../AccountSubContainer";
import { useTranslation } from "react-i18next";

export interface IAccountContainerProps {
    /** The page to fetch in the user's account, if not provided return root account page */
    page?: string;
    /** Callback to handle navigation to other pages */
    onNavigateTo: (to: To) => void;
}

export const AccountContainer = ({
    page,
    onNavigateTo
}: IAccountContainerProps) => {
    const { t } = useTranslation();
    const userContext = useContext(UserContext);

    if (!userContext) {
        console.warn('context needs to be there');
        return null;
    }

    const { user } = userContext;

    if (!page) {
        //root account page
        return <AccountPageView
            fullName={user?.firebaseUser?.displayName || ''}
            email={user?.firebaseUser?.email || ''}
            onViewProfileClick={() => onNavigateTo(ROUTE_PATH.userBuild(user?.firebaseUser?.uid || ''))}
            onSectionClick={(section) => onNavigateTo(ROUTE_PATH.accountBuild(section))}
        />
    }

    const userId = user?.firebaseUser?.uid || '';
    if (!userId) {
        return null;
    }

    //account sub-pages
    return (
        <AccountSubPageView title={getTitleFromAccountPage(t, page)}
            breadcrumbs={[{ label: getTitleFromAccountPage(t, ''), href: ROUTE_PATH.accountBuild() }]}>
            <AccountSubContainer subPage={page} userId={userId} />
        </AccountSubPageView>
    )
}

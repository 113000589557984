import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const DEFAULT_LANGUAGE = 'en';
export const SUPPORTED_LANGUAGE_CODES = ['en', 'fr', 'de'];

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: DEFAULT_LANGUAGE,
        ns: ['translations', 'languages', 'countries', 'continents'],
        defaultNS: 'translations',
        resources: {
            aa: {
                languages: require('./locales/aa/languages.json'),
            },
            ab: {
                languages: require('./locales/ab/languages.json'),
            },
            af: {
                languages: require('./locales/af/languages.json'),
            },
            ak: {
                languages: require('./locales/ak/languages.json'),
            },
            am: {
                languages: require('./locales/am/languages.json'),
            },
            ar: {
                languages: require('./locales/ar/languages.json'),
            },
            an: {
                languages: require('./locales/an/languages.json'),
            },
            as: {
                languages: require('./locales/as/languages.json'),
            },
            av: {
                languages: require('./locales/av/languages.json'),
            },
            ae: {
                languages: require('./locales/ae/languages.json'),
            },
            ay: {
                languages: require('./locales/ay/languages.json'),
            },
            az: {
                languages: require('./locales/az/languages.json'),
            },
            ba: {
                languages: require('./locales/ba/languages.json'),
            },
            bm: {
                languages: require('./locales/bm/languages.json'),
            },
            be: {
                languages: require('./locales/be/languages.json'),
            },
            bn: {
                languages: require('./locales/bn/languages.json'),
            },
            bi: {
                languages: require('./locales/bi/languages.json'),
            },
            bo: {
                languages: require('./locales/bo/languages.json'),
            },
            bs: {
                languages: require('./locales/bs/languages.json'),
            },
            br: {
                languages: require('./locales/br/languages.json'),
            },
            bg: {
                languages: require('./locales/bg/languages.json'),
            },
            ca: {
                languages: require('./locales/ca/languages.json'),
            },
            cs: {
                countries: require('./locales/cs/countries.json'),
                languages: require('./locales/cs/languages.json'),
            },
            ch: {
                languages: require('./locales/ch/languages.json'),
            },
            ce: {
                languages: require('./locales/ce/languages.json'),
            },
            cu: {
                languages: require('./locales/cu/languages.json'),
            },
            cv: {
                languages: require('./locales/cv/languages.json'),
            },
            kw: {
                languages: require('./locales/kw/languages.json'),
            },
            co: {
                languages: require('./locales/co/languages.json'),
            },
            cr: {
                languages: require('./locales/cr/languages.json'),
            },
            cy: {
                languages: require('./locales/cy/languages.json'),
            },
            da: {
                countries: require('./locales/da/countries.json'),
                languages: require('./locales/da/languages.json'),
            },
            de: {
                translations: require('./locales/de/translations.json'),
                countries: require('./locales/de/countries.json'),
                languages: require('./locales/de/languages.json'),
            },
            dv: {
                languages: require('./locales/dv/languages.json'),
            },
            dz: {
                languages: require('./locales/dz/languages.json'),
            },
            el: {
                languages: require('./locales/el/languages.json'),
            },
            en: {
                translations: require('./locales/en/translations.json'),
                countries: require('./locales/en/countries.json'),
                continents: require('./locales/en/continents.json'),
                languages: require('./locales/en/languages.json'),
            },
            eo: {
                languages: require('./locales/eo/languages.json'),
            },
            et: {
                languages: require('./locales/et/languages.json'),
            },
            eu: {
                languages: require('./locales/eu/languages.json'),
            },
            ee: {
                languages: require('./locales/ee/languages.json'),
            },
            fo: {
                languages: require('./locales/fo/languages.json'),
            },
            fa: {
                languages: require('./locales/fa/languages.json'),
            },
            fj: {
                languages: require('./locales/fj/languages.json'),
            },
            fi: {
                countries: require('./locales/fi/countries.json'),
                languages: require('./locales/fi/languages.json'),
            },
            fr: {
                translations: require('./locales/fr/translations.json'),
                countries: require('./locales/fr/countries.json'),
                continents: require('./locales/fr/continents.json'),
                languages: require('./locales/fr/languages.json'),
            },
            fy: {
                languages: require('./locales/fy/languages.json'),
            },
            ff: {
                languages: require('./locales/ff/languages.json'),
            },
            gd: {
                languages: require('./locales/gd/languages.json'),
            },
            ga: {
                languages: require('./locales/ga/languages.json'),
            },
            gl: {
                languages: require('./locales/gl/languages.json'),
            },
            gv: {
                languages: require('./locales/gv/languages.json'),
            },
            gn: {
                languages: require('./locales/gn/languages.json'),
            },
            gu: {
                languages: require('./locales/gu/languages.json'),
            },
            ht: {
                languages: require('./locales/ht/languages.json'),
            },
            ha: {
                languages: require('./locales/ha/languages.json'),
            },
            sh: {
                languages: require('./locales/sh/languages.json'),
            },
            he: {
                countries: require('./locales/he/countries.json'),
                languages: require('./locales/he/languages.json'),
            },
            hz: {
                languages: require('./locales/hz/languages.json'),
            },
            hi: {
                languages: require('./locales/hi/languages.json'),
            },
            ho: {
                languages: require('./locales/ho/languages.json'),
            },
            hr: {
                languages: require('./locales/hr/languages.json'),
            },
            hu: {
                countries: require('./locales/hu/countries.json'),
                languages: require('./locales/hu/languages.json'),
            },
            hy: {
                languages: require('./locales/hy/languages.json'),
            },
            ig: {
                languages: require('./locales/ig/languages.json'),
            },
            io: {
                languages: require('./locales/io/languages.json'),
            },
            ii: {
                languages: require('./locales/ii/languages.json'),
            },
            iu: {
                languages: require('./locales/iu/languages.json'),
            },
            ie: {
                languages: require('./locales/ie/languages.json'),
            },
            ia: {
                languages: require('./locales/ia/languages.json'),
            },
            id: {
                languages: require('./locales/id/languages.json'),
            },
            ik: {
                languages: require('./locales/ik/languages.json'),
            },
            is: {
                languages: require('./locales/is/languages.json'),
            },
            it: {
                countries: require('./locales/it/countries.json'),
                languages: require('./locales/it/languages.json'),
            },
            jv: {
                languages: require('./locales/jv/languages.json'),
            },
            ja: {
                countries: require('./locales/ja/countries.json'),
                languages: require('./locales/ja/languages.json'),
            },
            kl: {
                languages: require('./locales/kl/languages.json'),
            },
            kn: {
                languages: require('./locales/kn/languages.json'),
            },
            ks: {
                languages: require('./locales/ks/languages.json'),
            },
            ka: {
                languages: require('./locales/ka/languages.json'),
            },
            kr: {
                languages: require('./locales/kr/languages.json'),
            },
            kk: {
                languages: require('./locales/kk/languages.json'),
            },
            km: {
                languages: require('./locales/km/languages.json'),
            },
            ki: {
                languages: require('./locales/ki/languages.json'),
            },
            rw: {
                languages: require('./locales/rw/languages.json'),
            },
            ky: {
                languages: require('./locales/ky/languages.json'),
            },
            kv: {
                languages: require('./locales/kv/languages.json'),
            },
            kg: {
                languages: require('./locales/kg/languages.json'),
            },
            ko: {
                languages: require('./locales/ko/languages.json'),
            },
            kj: {
                languages: require('./locales/kj/languages.json'),
            },
            ku: {
                languages: require('./locales/ku/languages.json'),
            },
            lo: {
                languages: require('./locales/lo/languages.json'),
            },
            la: {
                languages: require('./locales/la/languages.json'),
            },
            lv: {
                languages: require('./locales/lv/languages.json'),
            },
            li: {
                languages: require('./locales/li/languages.json'),
            },
            ln: {
                languages: require('./locales/ln/languages.json'),
            },
            lt: {
                languages: require('./locales/lt/languages.json'),
            },
            lb: {
                languages: require('./locales/lb/languages.json'),
            },
            lu: {
                languages: require('./locales/lu/languages.json'),
            },
            lg: {
                languages: require('./locales/lg/languages.json'),
            },
            mh: {
                languages: require('./locales/mh/languages.json'),
            },
            ml: {
                languages: require('./locales/ml/languages.json'),
            },
            mr: {
                languages: require('./locales/mr/languages.json'),
            },
            mk: {
                languages: require('./locales/mk/languages.json'),
            },
            mg: {
                languages: require('./locales/mg/languages.json'),
            },
            mt: {
                languages: require('./locales/mt/languages.json'),
            },
            mn: {
                languages: require('./locales/mn/languages.json'),
            },
            mi: {
                languages: require('./locales/mi/languages.json'),
            },
            ms: {
                languages: require('./locales/ms/languages.json'),
            },
            my: {
                languages: require('./locales/my/languages.json'),
            },
            na: {
                languages: require('./locales/na/languages.json'),
            },
            nv: {
                languages: require('./locales/nv/languages.json'),
            },
            nr: {
                languages: require('./locales/nr/languages.json'),
            },
            nd: {
                languages: require('./locales/nd/languages.json'),
            },
            ng: {
                languages: require('./locales/ng/languages.json'),
            },
            ne: {
                languages: require('./locales/ne/languages.json'),
            },
            nl: {
                countries: require('./locales/nl/countries.json'),
                languages: require('./locales/nl/languages.json'),
            },
            nn: {
                languages: require('./locales/nn/languages.json'),
            },
            nb: {
                languages: require('./locales/nb/languages.json'),
            },
            no: {
                countries: require('./locales/no/countries.json'),
                languages: require('./locales/no/languages.json'),
            },
            ny: {
                languages: require('./locales/ny/languages.json'),
            },
            oc: {
                languages: require('./locales/oc/languages.json'),
            },
            oj: {
                languages: require('./locales/oj/languages.json'),
            },
            or: {
                languages: require('./locales/or/languages.json'),
            },
            om: {
                languages: require('./locales/om/languages.json'),
            },
            os: {
                languages: require('./locales/os/languages.json'),
            },
            pa: {
                languages: require('./locales/pa/languages.json'),
            },
            pi: {
                languages: require('./locales/pi/languages.json'),
            },
            pl: {
                countries: require('./locales/pl/countries.json'),
                languages: require('./locales/pl/languages.json'),
            },
            pt: {
                countries: require('./locales/pt/countries.json'),
                languages: require('./locales/pt/languages.json'),
            },
            ps: {
                languages: require('./locales/ps/languages.json'),
            },
            qu: {
                languages: require('./locales/qu/languages.json'),
            },
            rm: {
                languages: require('./locales/rm/languages.json'),
            },
            ro: {
                languages: require('./locales/ro/languages.json'),
            },
            rn: {
                languages: require('./locales/rn/languages.json'),
            },
            ru: {
                countries: require('./locales/ru/countries.json'),
                languages: require('./locales/ru/languages.json'),
            },
            sg: {
                languages: require('./locales/sg/languages.json'),
            },
            sa: {
                languages: require('./locales/sa/languages.json'),
            },
            si: {
                languages: require('./locales/si/languages.json'),
            },
            sk: {
                countries: require('./locales/sk/countries.json'),
                languages: require('./locales/sk/languages.json'),
            },
            sl: {
                languages: require('./locales/sl/languages.json'),
            },
            se: {
                languages: require('./locales/se/languages.json'),
            },
            sm: {
                languages: require('./locales/sm/languages.json'),
            },
            sn: {
                languages: require('./locales/sn/languages.json'),
            },
            sd: {
                languages: require('./locales/sd/languages.json'),
            },
            so: {
                languages: require('./locales/so/languages.json'),
            },
            st: {
                languages: require('./locales/st/languages.json'),
            },
            es: {
                countries: require('./locales/es/countries.json'),
                languages: require('./locales/es/languages.json'),
            },
            sq: {
                languages: require('./locales/sq/languages.json'),
            },
            sc: {
                languages: require('./locales/sc/languages.json'),
            },
            sr: {
                languages: require('./locales/sr/languages.json'),
            },
            ss: {
                languages: require('./locales/ss/languages.json'),
            },
            su: {
                languages: require('./locales/su/languages.json'),
            },
            sw: {
                languages: require('./locales/sw/languages.json'),
            },
            sv: {
                countries: require('./locales/sv/countries.json'),
                languages: require('./locales/sv/languages.json'),
            },
            ty: {
                languages: require('./locales/ty/languages.json'),
            },
            ta: {
                languages: require('./locales/ta/languages.json'),
            },
            tt: {
                languages: require('./locales/tt/languages.json'),
            },
            te: {
                languages: require('./locales/te/languages.json'),
            },
            tg: {
                languages: require('./locales/tg/languages.json'),
            },
            tl: {
                languages: require('./locales/tl/languages.json'),
            },
            th: {
                languages: require('./locales/th/languages.json'),
            },
            ti: {
                languages: require('./locales/ti/languages.json'),
            },
            to: {
                languages: require('./locales/to/languages.json'),
            },
            tn: {
                languages: require('./locales/tn/languages.json'),
            },
            ts: {
                languages: require('./locales/ts/languages.json'),
            },
            tk: {
                languages: require('./locales/tk/languages.json'),
            },
            tr: {
                countries: require('./locales/tr/countries.json'),
                languages: require('./locales/tr/languages.json'),
            },
            tw: {
                languages: require('./locales/tw/languages.json'),
            },
            ug: {
                languages: require('./locales/ug/languages.json'),
            },
            uk: {
                languages: require('./locales/uk/languages.json'),
            },
            ur: {
                languages: require('./locales/ur/languages.json'),
            },
            uz: {
                languages: require('./locales/uz/languages.json'),
            },
            ve: {
                languages: require('./locales/ve/languages.json'),
            },
            vi: {
                languages: require('./locales/vi/languages.json'),
            },
            vo: {
                languages: require('./locales/vo/languages.json'),
            },
            wa: {
                languages: require('./locales/wa/languages.json'),
            },
            wo: {
                languages: require('./locales/wo/languages.json'),
            },
            xh: {
                languages: require('./locales/xh/languages.json'),
            },
            yi: {
                languages: require('./locales/yi/languages.json'),
            },
            yo: {
                languages: require('./locales/yo/languages.json'),
            },
            za: {
                languages: require('./locales/za/languages.json'),
            },
            zh: {
                countries: require('./locales/zh/countries.json'),
                languages: require('./locales/zh/languages.json'),
            },
            zu: {
                languages: require('./locales/zu/languages.json'),
            },
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
import React from 'react';
import { Container, Stack, Typography } from "@mui/material";

//TODO in the future, should come from the Back End
export const ForumsGuidelinesPage = () => {
    return <Container sx={{ p: 3 }}>
        <Stack direction={'column'} spacing={1}>
            <Typography variant='h1'>Forums Guidelines</Typography>
            <Typography variant='subtitle2'>Last updated: Aug 11th, 2023</Typography>
            <Typography>
                It is important to know that the Forums is part of the CoHostOp platform and CoHostOp's Terms of Service and Privacy Policy apply to your use of the Forums. Additionally, this Guidelines outline expectations for everyone(Hosts, CoHosts, Guests and CoHostOp team)'s conduct when participating in this Forum (Collectively “The Guidelines”).
            </Typography>
            <Typography variant='h3'>
                Our 'Guidelines' are
            </Typography>
            <Typography>
                1. Be respectful and encouraging of each other, even if your opinions differ.
            </Typography>
            <Typography>
                2. Helps us ensure our community remains respectful and inclusive by reporting any inappropriate content.
            </Typography>
            <Typography>
                3. We do not allow the following:
            </Typography>
            <Stack direction={'column'} sx={{ paddingLeft: 3 }}>
                <Typography>
                    - Advertising or other commercial content, company logos, links, or company names
                </Typography>
                <Typography>
                    - Spam, unwanted contact, or content that is shared repeatedly
                </Typography>
                <Typography>
                    - Content that endorses or promotes illegal or harmful activity, or that is profane, vulgar, obscene, threatening, or harassing
                </Typography>
                <Typography>
                    - Content that is discriminatory.
                </Typography>
                <Typography>
                    - Attempts to impersonate another person, account, or entity, including a representative of CoHostOp
                </Typography>
                <Typography>
                    - Content that is illegal or that violates another person's or entity's rights, including intellectual property rights and privacy rights
                </Typography>
                <Typography>
                    - Content that refers to the details of an CoHostOp investigation
                </Typography>
                <Typography>
                    - Content that is sufficient to identify a listing's location or other content that may pose a personal safety risk to an CoHostOp community member
                </Typography>
                <Typography>
                    - Content that relates to an individual's personal information e.g. personal information such as email addresses, phone numbers, credit card details etc.
                </Typography>
                <Typography>
                    - Content that is off - topic, doesn't ask a question, or doesn't offer knowledge in response to a question as part of a larger discussion
                </Typography>
                <Typography>
                    - Trolling or repeatedly targeting community members
                </Typography>
            </Stack>
            <Typography>
                4. Write simply and clearly, and post full answers so other members can engage easily with you
            </Typography>
            <Typography>
                5. Your content may be removed from the Forum if it is deemed to be in violation of these Guidelines.It could also result in temporary or permanent suspension from both the Forums and the CoHostOp platform.
            </Typography>
            <Typography>
                CoHostOp Community Managers help ensure the Forums is a welcoming place for all members.If you see something that goes against these guidelines, please report it using the flag button in the post.
            </Typography>
            <Typography variant={'h4'}>
                Urgent assistance
            </Typography>
            <Typography>
                Please note the Forums is not a dedicated CoHostOp help channel so if you are traveling, have a time - sensitive request, or need help with a personal issue, please contact our Customer Service
            </Typography>
            <Typography>
                CoHostOp built this space for Hosts, CoHosts and Guests to inspire one another and engage in constructive dialogue.We welcome all your ideas, experiences, feedback, suggestions and participation.These guidelines will help ensure that all interactions remain safe, inclusive, constructive  and respectful.
            </Typography>
            <Typography>
                Thank you for your collaboration and support towards keeping the Forums a welcoming place.
            </Typography>
        </Stack>
    </Container>;
}





import React from "react";
import { Stack } from "@mui/material";
import { IListingSubListingItemProps, ListingSubListingItem } from "./ListingSubListingItem";
import { PropsWithLoadingState } from "../../../utilities";

export interface IListingSubListingSectionProps {
    items: IListingSubListingItemProps[];
}

export const ListingSubListingSection = ({
    items,
    loading
}: PropsWithLoadingState<IListingSubListingSectionProps>) => {
    if (!loading && !items.length) { return null; }
    return (
        <Stack direction={{ xs: 'column', sm: 'row', md: 'column' }}
            justifyContent={'start'}
            alignItems={'stretch'}
            spacing={1}>
            {
                !loading ?
                    items.map((item, index) =>
                        <ListingSubListingItem key={`sub-listing-${index}`} {...item} />)
                    :
                    new Array(2).fill('').map((_, index) =>
                        <ListingSubListingItem loading key={`loading-sub-listing-${index}`} />)

            }
        </Stack >
    );
}
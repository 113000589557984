import React from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ROUTE_PARAMS, ROUTE_QUERY } from '../../utilities/navigation';
import { ShopProductDetails } from '../../components/shop/ShopProductDetails';
import { ShopProductContainer } from '../../containers/shop/ShopProductContainer';

export const ShopProductPage = () => {
    const { [ROUTE_PARAMS.shopID]: shopId, [ROUTE_PARAMS.productID]: productId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const onVariantChange = (variantID: string) => {
        setSearchParams({ [ROUTE_QUERY.variantID]: variantID })
    }

    if (!productId) {
        return <ShopProductDetails error={{ message: "" }} />
    }

    return <ShopProductContainer
        shopId={shopId}
        productId={productId}
        selectedVariant={searchParams.get(ROUTE_QUERY.variantID) || undefined}
        onVariantSelected={onVariantChange}
    />;
}

import React from "react";
import { Avatar, AvatarProps, Badge, Skeleton, Stack, styled, Typography } from "@mui/material";
import { PropsWithLoadingState } from "../../../utilities";

export interface IChatInboxItemProps {
    /** The image representing the thread (can be a user avatar or a listing main picture) */
    image: AvatarProps;
    /** The optional sub image as badge of the main image (for guest/cohost) */
    subImage?: AvatarProps;
    /** The title of the thread, usually the booking date and listing name */
    title: string;
    /** The sub-title of the thread, the cohost or guest name */
    subtitle?: string;
    /** The timestamp of the last posted message in the thread */
    timestamp?: Date;
    /** The description of the thread, could be the summary of the last message in the thread */
    description?: string;
    /** To know if the item has been selected (for styling) */
    isSelected?: boolean;
    /** Callback press on the item */
    onClick: () => void;
}

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
}));

/** 
 * The item in the side bar. If clicked should open the full thread of that conversation.
 * @param props IChatInboxItemProps
 * @see ChatInbox
 */
export const ChatInboxItem = ({ image, subImage, title, subtitle, timestamp, description, isSelected, onClick, loading }: PropsWithLoadingState<IChatInboxItemProps>) => {
    return (
        <Stack direction={'row'}
            justifyContent={'start'}
            alignItems={'center'}
            spacing={2}
            onClick={onClick}
            sx={{ backgroundColor: !!isSelected ? '#F7F7F7' : '#FFF', p: 1 }}>
            {!loading && !!subImage ?
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                        <SmallAvatar {...subImage} />
                    }
                >
                    <Avatar variant="rounded" sx={{ height: 66, width: 66 }} {...image} />
                </Badge>
                :
                !loading ?
                    <Avatar variant="rounded" sx={{ height: 66, width: 66 }} {...image} />
                    :
                    <Skeleton variant='rounded' height={66} width={66} />
            }
            <Stack direction={'column'} justifyContent={'center'} alignItems={'stretch'} flex={1}>
                <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                    <Typography>{!loading ? "State" : <Skeleton width={30} />}</Typography>
                    <Typography>{!!timestamp ? timestamp.toDateString() : !!loading ? <Skeleton width={20} /> : ""}</Typography>
                </Stack>
                <Typography>{!loading ? title : <Skeleton width={'80%'} />}</Typography>
                <Typography>{!loading ? subtitle : <Skeleton width={'50%'} />}</Typography>
                {!!description && <Typography>{!loading ? description : <Skeleton width={'100%'} />}</Typography>}
            </Stack>
        </Stack>
    );
}
import { TextField, TextFieldProps } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type IChatSearchProps = TextFieldProps & {
    onChange: (search: string) => void;
}

export const ChatSearch = ({ onChange, ...props }: IChatSearchProps) => {
    const { t } = useTranslation();
    const [value, setValue] = useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setValue(newValue);
        onChange(newValue);
    };

    return <TextField
        {...props}
        maxRows={1}
        placeholder={t('chat.inbox.searchPlaceholder')}
        value={value}
        onChange={handleChange}
    />
}

/** The remote config for the news */
export const RC_NEWS = "news";

export type News = {
    /** Boolean indicating if the news is enabled */
    enabled: boolean;
}

export const DEFAULT_NEWS: News = {
    "enabled": false
}

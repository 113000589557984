import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { UserContext } from '../../../context/UserInformation';
import { EUserRole } from '../../../types/db';
import { ROUTE_PATH, buildPath } from '../../../utilities/navigation';
import { ChoAppBar } from "../ChoAppBar";
import { RC_HELP } from '../../../types/remote_config';
import { useRemoteConfig } from '../../../services/remoteConfig';
import { WEBSITE_HELP_CENTER_URL } from '../../../types/constants';

export const DashboardAppBar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const {
        // [RC_SUPPORT]: remoteConfigSupport,
        // [RC_DONATION]: remoteConfigDonation,
        [RC_HELP]: remoteConfigHelp,
    } = useRemoteConfig();

    const userRole = userContext?.user?.role;

    //Item that every roles have
    const defaultItems = [
        {
            label: t('dashboard.menu.main'),
            action: () => navigate("")
        },
        {
            label: t('dashboard.menu.inbox'),
            action: () => navigate(ROUTE_PATH.inbox)
        },
    ]

    const items = userRole === EUserRole.HOST ?
        [
            ...defaultItems,
            {
                label: t('dashboard.menu.listings'),
                action: () => navigate(ROUTE_PATH.listings)
            },
            {
                label: t('dashboard.menu.bookings', { context: userRole.toLowerCase() }),
                action: () => navigate(ROUTE_PATH.bookings)
            },
            // {
            //     label: t('dashboard.menu.events'),
            //     action: () => navigate(ROUTE_PATH.events)
            // }
        ]
        : userRole === EUserRole.COHOST ?
            [
                ...defaultItems,
                {
                    label: t('dashboard.menu.listings'),
                    action: () => navigate(ROUTE_PATH.listings)
                },
                {
                    label: t('dashboard.menu.bookings', { context: userRole.toLowerCase() }),
                    action: () => navigate(ROUTE_PATH.bookings)
                },
                // {
                //     label: t('dashboard.menu.tasks'),
                //     action: () => navigate(ROUTE_PATH.users)//TODO
                // }
            ]
            : userRole === EUserRole.GUEST ?
                [
                    ...defaultItems,
                    {
                        label: t('dashboard.menu.bookings', { context: userRole.toLowerCase() }),
                        action: () => navigate(ROUTE_PATH.bookings)
                    },
                    // {
                    //     label: t('dashboard.menu.wishlist'),
                    //     action: () => navigate(ROUTE_PATH.listings)//TODO
                    // },
                    // {
                    //     label: t('dashboard.menu.events'),
                    //     action: () => navigate(ROUTE_PATH.events)
                    // }
                ]
                : defaultItems;

    //for now same as home app bar, but could change
    const userMenuItems = [
        {
            label: t('user.menu.dashboard'),
            action: () => {
                navigate(buildPath(ROUTE_PATH.dashboard));
            }
        },
        {
            label: t('user.menu.profile'),
            action: () => {
                navigate(ROUTE_PATH.userBuild(userContext?.user?.firebaseUser?.uid ?? ""));
            }
        },
        {
            label: t('user.menu.account'),
            action: () => {
                navigate(ROUTE_PATH.accountBuild());
            }
        },
        true,
        remoteConfigHelp.enabled ? {
            label: t('user.menu.help'),
            action: () => {
                window.location.assign(WEBSITE_HELP_CENTER_URL);
            }
        } : false,
        {
            label: t('user.menu.logout'),
            action: () => {
                userContext?.logOut();
                navigate(ROUTE_PATH.home);
            },
        },
    ]

    return <ChoAppBar mainMenuItems={items} userMenuItems={userMenuItems} showOutlet={'logged-only'} />
}
import { AddCircleOutline, Check, Edit, Help, Visibility } from '@mui/icons-material';
import { Avatar, Badge, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { getI18n, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { AccountBaseInfo, AccountBaseInfoItems } from '../../../components/user/account/AccountBaseInfo';
import { IAccountHelpItemProps } from '../../../components/user/account/AccountHelpItem';
import { getTitleFromAccountPage } from '../../../components/user/account/AccountPageView';
import { addPictureToUser } from '../../../services/storage';
import { getUserInfo, getUserPrivateInfo, getUserProtectedInfo, updateUserInfo, updateUserPrivateInfo } from '../../../services/user';
import { UserAddress } from '../../../types/db';
import { hideEmailDetails, hidePhoneDetails } from '../../../utilities';
import { IAccountSubContainerProps } from '../AccountSubContainer';
import { LANGUAGE_CODES } from '../../../utilities/i18n';

export const AccountPersonalInfoContainer = ({
    subPage,
    userId,
    children,
}: IAccountSubContainerProps) => {
    const { t } = useTranslation();
    const userPublicResult = useQuery(["account", "perso", "public"],
        () => getUserInfo(userId));
    const userProtectedResult = useQuery(["account", "perso", "protected"],
        () => getUserProtectedInfo(userId));
    const userPrivateResult = useQuery(["account", "perso", "private"],
        () => getUserPrivateInfo(userId));

    const userPublicInfo = userPublicResult.data;
    const userProtectedInfo = userProtectedResult.data;
    const userPrivateInfo = userPrivateResult.data;

    const handleSaveAction = (level: "public" | "protected" | "private", safeFn: () => Promise<void>) => {
        //save the data
        return safeFn()
            .then(() => {
                //refetch info after saving something
                switch (level) {
                    case "public":
                        userPublicResult.refetch();
                        break;
                    case "protected":
                        userProtectedResult.refetch();
                        break;
                    case "private":
                        userPrivateResult.refetch();
                        break;
                }
            });
    }

    const { open: openDropZone } = useDropzone({
        noDrag: true,
        noDragEventsBubbling: true,
        accept: {
            'image/*': ['.jpeg', '.png']
        },
        maxFiles: 1,
        preventDropOnDocument: true,
        onDrop: (acceptedFiles) => {
            if (!acceptedFiles.length) {
                return;//no file chosen, then do nothing
            }
            const acceptedFile = acceptedFiles[0];//only accept one file
            new Promise<string>((resolve, reject) => {
                addPictureToUser(
                    userId,
                    acceptedFile,
                    (_, final, error) => {
                        if (!!error) {
                            reject(error);
                        } else if (!!final) {
                            resolve(final.url);
                        }
                    })
            })
                .then(url => {
                    setAvatarUrl(url)
                });

            setAvatarUrl(URL.createObjectURL(acceptedFile));
        }
    });

    const [avatarUrl, setAvatarUrl] = useState<string>(userPublicInfo?.avatar_url || '');

    const [displayName, setDisplayName] = useState<string>(userPublicInfo?.display_name || '');
    const [about, setAbout] = useState<string>(userPublicInfo?.description || '');
    const [from, setFrom] = useState<string>(userPublicInfo?.from || '');
    const [languages, setLanguages] = useState<string[]>(userPublicInfo?.languages || [getI18n().language]);

    const [firstName, setFirstName] = useState<string>(userPrivateInfo?.full_name.first_name || '');
    const [lastName, setLastName] = useState<string>(userPrivateInfo?.full_name.last_name || '');

    const [email, setEmail] = useState<string>('');

    const [address, setAddress] = useState<UserAddress>({
        line1: '',
        line2: '',
        zip: '',
        city: '',
        country: '',
    });

    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const items: AccountBaseInfoItems =
        [
            {
                label: t('user.accountPerso.publicTitle'),
                description: t('user.accountPerso.publicContent'),
                items: !userPublicInfo ? []
                    : [
                        {
                            title: t('user.accountPerso.displayNameTitle'),
                            description: t('user.accountPerso.displayNameContent'),
                            value: userPublicInfo.display_name || '',
                            children:
                                <TextField
                                    label={t('user.accountPerso.displayNameTitle')}
                                    value={displayName}
                                    onChange={(e) => setDisplayName(e.target.value)}
                                />,
                            onSaveClick: () =>
                                handleSaveAction(
                                    "public",
                                    () => updateUserInfo(userId, { display_name: displayName })
                                ),
                        },
                        {
                            title: t('user.accountPerso.avatarTitle'),
                            description: t('user.accountPerso.avatarContent'),
                            value: !!userPublicInfo.avatar_url ? t('user.account.infoProvided') : t('user.account.infoNotProvided'),
                            children:
                                <Stack
                                    direction={'row'}
                                    justifyContent={'center'}
                                    alignItems={'center'}>
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        badgeContent={
                                            <IconButton onClick={() => openDropZone()}
                                                color={'primary'}
                                                size={'large'}
                                                title={t('user.accountPerso.avatarEditButton')}
                                                type={"button"}
                                                sx={{ border: 2, borderColor: 'primary' }}>
                                                <Edit />
                                            </IconButton>
                                        }
                                    >
                                        <Avatar alt={displayName}
                                            src={avatarUrl || userPublicInfo.avatar_url}
                                            sx={{ width: '10rem', height: '10rem' }}
                                            variant={'circular'} />
                                    </Badge>
                                </Stack>,
                            onSaveClick: () =>
                                handleSaveAction(
                                    "public",
                                    () => updateUserInfo(userId, { avatar_url: avatarUrl })
                                ),
                        },
                        {
                            title: t('user.accountPerso.aboutTitle'),
                            description: t('user.accountPerso.aboutContent'),
                            value: userPublicInfo.description,
                            children:
                                <TextField
                                    label={t('user.accountPerso.aboutTitle')}
                                    multiline
                                    minRows={2}
                                    maxRows={3}
                                    value={about}
                                    onChange={(e) => setAbout(e.target.value)}
                                />,
                            onSaveClick: () =>
                                handleSaveAction(
                                    "public",
                                    () => updateUserInfo(userId, { description: about })
                                ),
                        },
                        {
                            title: t('user.accountPerso.originTitle'),
                            description: t('user.accountPerso.originContent'),
                            value: userPublicInfo.from || '',
                            children:
                                <TextField
                                    label={t('user.accountPerso.originLabel')}
                                    value={from}
                                    onChange={(e) => setFrom(e.target.value)}
                                />,
                            onSaveClick: () =>
                                handleSaveAction(
                                    "public",
                                    () => updateUserInfo(userId, { from: from })
                                ),
                        },
                        {
                            title: t('user.accountPerso.languagesTitle'),
                            description: t('user.accountPerso.languagesContent'),
                            value: (userPublicInfo.languages ?? [getI18n().language]).map(l => t(l, { ns: "languages" })).join(" | "),
                            children: <Languages currentLanguages={languages} onChange={setLanguages} inEditMode={true} editable={true} />,
                            onSaveClick: () =>
                                handleSaveAction(
                                    "public",
                                    () => updateUserInfo(userId, { languages: languages })
                                ),
                        },
                    ],
            },
            {
                label: t('user.accountPerso.protectedTitle'),
                description: t('user.accountPerso.protectedContent'),
                items: !userProtectedInfo ? []
                    : [
                        {
                            title: t('user.accountPerso.phoneTitle'),
                            value: hidePhoneDetails(userProtectedInfo.phone),
                        },
                        {
                            title: t('user.accountPerso.emailTitle'),
                            value: hideEmailDetails(userProtectedInfo.email),
                        }
                    ],
            },
            {
                label: t('user.accountPerso.privateTitle'),
                description: t('user.accountPerso.privateContent'),
                items: !userPrivateInfo ? [] : [
                    {
                        title: t('user.accountPerso.legalNameTitle'),
                        description: t('user.accountPerso.legalNameContent'),
                        value: `${userPrivateInfo.full_name.first_name || ''} ${userPrivateInfo.full_name.last_name || ''}`,
                        children:
                            <Stack direction={'row'} spacing={2}>
                                <TextField
                                    label={t('user.accountPerso.firstNameLabel')}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                <TextField
                                    label={t('user.accountPerso.lastNameLabel')}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Stack>,
                        onSaveClick: () =>
                            handleSaveAction(
                                "private",
                                () => updateUserPrivateInfo(userId, { full_name: { first_name: firstName, last_name: lastName } })
                            ),
                    },
                    {
                        title: t('user.accountPerso.emailTitle'),
                        description: t('user.accountPerso.emailContent'),
                        value: hideEmailDetails(userPrivateInfo.email),
                        children:
                            <TextField
                                label={t('user.accountPerso.emailLabel')}
                                type={'email'}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />,
                        onSaveClick: () =>
                            handleSaveAction(
                                "private",
                                () => updateUserPrivateInfo(userId, { email: email }),
                            ),
                    },
                    {
                        title: t('user.accountPerso.phoneTitle'),
                        description: t('user.accountPerso.phoneContent'),
                        value: hidePhoneDetails(userPrivateInfo.phone),
                        children:
                            <TextField
                                label={t('user.accountPerso.phoneLabel')}
                                type={'phone'}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />,
                        onSaveClick: () =>
                            handleSaveAction(
                                "private",
                                () => updateUserPrivateInfo(userId, { phone: phoneNumber })
                            ),
                    },
                    // {
                    //     title: t('user.accountPerso.govIdTitle'),
                    //     description: t('user.accountPerso.govIdContent'),
                    // },
                    {
                        title: t('user.accountPerso.addressTitle'),
                        description: t('user.accountPerso.addressContent'),
                        children: //TODO create an address input component (reusable)
                            <Stack direction={'column'}
                                spacing={1}
                                alignItems={'stretch'}>
                                <TextField
                                    label={'Street address'}
                                    value={address.line1}
                                    onChange={(e) => setAddress(a => ({ ...a, line1: e.target.value }))}
                                />
                                <TextField
                                    label={'Apt, suite (optional)'}
                                    value={address.line2 || ''}
                                    onChange={(e) => setAddress(a => ({ ...a, line2: e.target.value }))}
                                />
                                <Stack direction={{ xs: 'column', md: 'row' }}
                                    spacing={1}
                                    justifyContent={'space-between'}>
                                    <TextField
                                        sx={{ flexGrow: 1 }}
                                        label={'City'}
                                        value={address.city}
                                        onChange={(e) => setAddress(a => ({ ...a, city: e.target.value }))}
                                    />
                                    <TextField
                                        label={'Postal code/zip'}
                                        value={address.zip}
                                        onChange={(e) => setAddress(a => ({ ...a, zip: e.target.value }))}
                                    />
                                </Stack>
                                <TextField
                                    label={'State/Province/Territory/County/Region'}
                                    value={address.state}
                                    onChange={(e) => setAddress(a => ({ ...a, state: e.target.value }))}
                                />
                                <TextField
                                    label={'Country'}
                                    value={address.country}
                                    onChange={(e) => setAddress(a => ({ ...a, country: e.target.value }))}
                                />
                            </Stack>,
                        onSaveClick: () =>
                            handleSaveAction(
                                "private",
                                () => updateUserPrivateInfo(userId, { address: address })
                            ),
                    },
                    // {
                    //     title: t('user.accountPerso.emergencyContactTitle'),
                    //     description: t('user.accountPerso.emergencyContactContent'),
                    // }
                ]
            }
        ];

    const helpItems = HELP_ITEMS.map(h => ({
        ...h,
        title: t(h.title),
        description: t(h.description)
    }))

    if (!!children) {
        return children({ items, helpItems: helpItems });
    } else {
        return <AccountBaseInfo title={getTitleFromAccountPage(t, subPage)}
            items={items}
            helpItems={helpItems} />
    }
}

const HELP_ITEMS: IAccountHelpItemProps[] = [
    {
        icon: <Help />,
        title: 'user.accountPerso.helpInfo1Title',
        description: 'user.accountPerso.helpInfo1Content',
    },
    {
        icon: <Visibility />,
        title: 'user.accountPerso.helpInfo2Title',
        description: 'user.accountPerso.helpInfo2Content',
    }
];

const Languages = ({ currentLanguages, onChange, inEditMode, editable }: { currentLanguages: string[], onChange: (l: string[]) => void, inEditMode: boolean, editable: boolean }) => {
    const [editMode, setEditMode] = useState(!!inEditMode);
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleDialogClose = (values?: string[]) => {
        //transmit onChange
        if (!!values) onChange(values);
        //close dialog
        setDialogOpen(false);
    }
    return (
        <>
            <Stack direction={'row'}
                justifyContent={'start'}
                alignItems={'center'}>
                <LanguagesChips currentLanguages={currentLanguages}
                    inEditMode={editMode}
                    onChange={onChange} />
                {!!editMode &&
                    <IconButton onClick={() => setDialogOpen(true)}>
                        <AddCircleOutline />
                    </IconButton>
                }
                {
                    !!editable && !editMode &&
                    <IconButton onClick={() => setEditMode(true)}>
                        <Edit />
                    </IconButton>
                }
                {
                    !!editable && editMode &&
                    <IconButton onClick={() => setEditMode(false)}>
                        <Check />
                    </IconButton>
                }
            </Stack>
            <LanguagesDialog initCheckedLanguages={currentLanguages}
                open={dialogOpen}
                onClose={handleDialogClose} />
        </>
    )
}

const LanguagesChips = ({ currentLanguages, onChange, inEditMode }: { currentLanguages: string[], onChange: (l: string[]) => void, inEditMode?: boolean }) => {
    const { t } = useTranslation("languages");

    const handleDelete = (value: string) => {
        onChange(currentLanguages.filter(v => v !== value));
    }

    return (
        <Stack direction={'row'}
            justifyContent={'start'}
            alignItems={'center'}>
            {currentLanguages.map((cl, index) =>
                <Chip key={`language-${cl}-${index}`}
                    label={t(cl)}
                    onDelete={inEditMode ? () => handleDelete(cl) : undefined} />
            )}
        </Stack>
    )
}

const LanguagesDialog = ({ initCheckedLanguages, onClose, ...props }: { initCheckedLanguages: string[], onClose: (checkedLanguages?: string[]) => void } & Pick<DialogProps, 'open'>) => {
    const { t } = useTranslation();
    const [checkedLanguages, setCheckedLanguages] = useState(initCheckedLanguages);

    useEffect(() => {
        setCheckedLanguages(initCheckedLanguages);
    }, [initCheckedLanguages]);

    const handleCancel = () => {
        //reset to initial
        setCheckedLanguages(initCheckedLanguages);
        //close dialog with nothing
        onClose();
    }

    const handleSave = () => {
        onClose(checkedLanguages);
    }

    return (
        <Dialog {...props} onClose={handleCancel}>
            <DialogTitle>
                {t('user.accountPerso.languagesLabel')}
            </DialogTitle>
            <DialogContent>
                {
                    LANGUAGE_CODES
                        .map(lc => {
                            const nativeName = t(lc, { lng: lc, ns: "languages", defaultValue: '' });
                            const name = t(lc, { ns: "languages", defaultValue: '' });
                            if (!name) {
                                return null;
                            }
                            return (
                                <Stack key={`dialog-language-${lc}`}
                                    direction={'row'}
                                    justifyContent={'start'}
                                    alignItems={'center'}>
                                    <Checkbox checked={checkedLanguages.includes(lc)}
                                        onChange={(e) => {
                                            setCheckedLanguages(cls => e.target.checked ? [...cls, lc] : cls.filter(v => v !== lc))
                                        }} />
                                    <Typography>{!nativeName ? name : `${nativeName} (${name})`}</Typography>
                                </Stack>
                            )
                        }
                        )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>{t('user.account.cancelButton')}</Button>
                <Button onClick={handleSave}>{t('user.account.saveButton')}</Button>
            </DialogActions>
        </Dialog>
    );
}

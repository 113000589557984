import React from 'react';
import { useQuery } from 'react-query';
import { ShopCart } from '../../../components/shop/ShopCart';
import { IShopCartItemProps } from '../../../components/shop/ShopCartItem';
import { useUserCart } from '../../../context/shop';
import { getShopCartItems } from '../../../services/shop';
import { DEFAULT_SHOP_ID } from '../../../utilities/shop';

export interface IShopCartContainerProps {
    /** The shop Id the cart id from, default to CoHostOp shop */
    shopId?: string;
    /** When user want to go back to the detail about store/product/variant */
    onCartRedirectTo?: (productId?: string, variantId?: string) => void;
    /** When user want to checkout the current cart */
    onCheckoutClick: () => void;
}

export const ShopCartContainer = ({
    shopId = DEFAULT_SHOP_ID,
    onCartRedirectTo,
    onCheckoutClick,
}: IShopCartContainerProps) => {
    const userCart = useUserCart(shopId);
    const productVariantsArray = userCart?.cartItems.map(v => ({ productId: v.productId, variantId: v.variantId })) || [];
    const { data, isLoading, isError } = useQuery(
        ["cart", shopId],
        () => getShopCartItems(shopId, productVariantsArray),
        {
            enabled: !!userCart && productVariantsArray.length !== 0,
        }
    );

    if (!userCart) {
        console.warn('check context provided');
        return null;
    }

    if (isLoading) {
        return <ShopCart loading />;
    }

    if (isError) {
        return <ShopCart error
            onStoreClick={onCartRedirectTo && (() => onCartRedirectTo(shopId))} />;
    }

    const handleQuantityChange = (variantId: string, newQuantity: number) => {
        userCart.updateQuantityItem({ variantId, quantity: newQuantity });
    }

    const items: IShopCartItemProps[] = data ?
        userCart.cartItems.map(item => {
            const itemData = data.find(v => v.id === item.variantId);
            return {
                title: itemData?.name || '',
                image: itemData?.image || '',
                onClick: () => onCartRedirectTo?.(item.productId, item.variantId),
                quantity: item.quantity || 1,
                onQuantityChange: (quantity) => handleQuantityChange(item.variantId, quantity),
                price: {
                    originalPrice: {
                        amount: itemData?.price.amount ?? 0,
                        currency: itemData?.price.currency || 'USD',
                    }
                },
            };
        })
        : [];

    return (
        <ShopCart items={items}
            onCheckoutClick={onCheckoutClick}
            onStoreClick={onCartRedirectTo && (() => onCartRedirectTo())} />
    );
}

import React, { useEffect, useState, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import { Button, Dialog, AppBar, Toolbar, IconButton, Typography, Slide } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface IDialogSubPageProps {
    title?: string;
    rightActionButton?: {
        label: string;
        onClick: () => {};
    }
}

export const DialogSubPage = ({ title, rightActionButton, children }: PropsWithChildren<IDialogSubPageProps>) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setOpen(true);
        return () => setOpen(false);
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            transitionDuration={500}
            TransitionProps={
                {
                    onExited: () => navigate('../'),
                }
            }
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    {!!title &&
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {title}
                        </Typography>
                    }

                    {!!rightActionButton &&
                        <Button autoFocus color="inherit" onClick={rightActionButton.onClick}>
                            {rightActionButton.label}
                        </Button>
                    }
                </Toolbar>
            </AppBar>
            {children}
        </Dialog>
    );
}
import React from 'react';
import { Box, CircularProgress, CircularProgressProps, IconButton, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';


interface IListingImageThumbnailProps {
    name: string;
    description?: string;
    url: string;
    percent?: number;
    onDeleteClick?: () => void;
}

export const ListingImageThumbnail = ({
    name,
    description,
    url,
    percent,
    onDeleteClick,
}: IListingImageThumbnailProps) => {
    return (
        <Box sx={{
            position: 'relative',
            borderRadius: 2,
            border: '1px solid #eaeaea',
            padding: 1,
            // backgroundColor: 'red',
            height: '100%'
        }}>
            <Box sx={{ height: '100%' }}>
                <img
                    src={url}
                    style={{
                        display: 'block',
                        width: '100%',
                        height: '100%',
                        maxHeight: 300,
                        minHeight: 100,
                        objectFit: 'contain',
                    }}
                    alt={description || name}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(url) }}
                />
                <Box sx={{
                    display: 'flex',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    {(percent !== undefined && percent < 100) ?
                        <CircularProgressWithLabel value={percent} />
                        :
                        onDeleteClick ?
                            <IconButton aria-label="delete" size="large" color='primary'
                                onClick={onDeleteClick}>
                                <Delete />
                            </IconButton>
                            : null
                    }
                </Box>
            </Box>
        </Box>
    )
}


function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

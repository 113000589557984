import React, { useMemo, useState } from "react"
import { useNavigate } from "react-router";
import { Button, Divider, Stack, Typography, Stepper, Step, StepLabel } from "@mui/material";
import { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { AutoStories, Diversity1, Diversity2, Diversity3 } from "@mui/icons-material";
import { BANNER_REMOTE_WORK } from "../../../utilities/assets/assetHelper";
import { ROUTE_PATH, buildPath } from "../../../utilities/navigation";
import { Banner } from "../../../components/Banner";
import { useTranslation } from "react-i18next";

type Section = { title: string, content: string }

const renderSection = ({ title, content }: Section) => {
    return (
        <Stack direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ p: 3 }}>
            <Typography variant="h4" textAlign={'center'}>{title}</Typography>
            <Typography variant="body1" textAlign={'center'}>{content}</Typography>
        </Stack>
    );
}

/** The career index/home/welcome page */
export const CareersHomePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Stack direction={'column'} spacing={1}>
            <Banner
                {...{
                    title: t('careers.home.bannerTitle'),
                    backgroundImageSrc: BANNER_REMOTE_WORK,
                    subTitle: t('careers.home.bannerContent'),
                    primaryButton: {
                        text: t('careers.home.bannerBtn'),
                        onClick: () => navigate(buildPath(ROUTE_PATH.careers.positions)),
                    }
                }} />
            <Stack direction={'column'} spacing={2}>
                {renderSection({
                    title: t('careers.news.title'),
                    content: t('careers.news.content'),
                })}
                <Divider />
                {renderSection({
                    title: t('careers.mission.title'),
                    content: t('careers.mission.content'),
                })}
                <Divider />
                {renderSection({
                    title: t('careers.values.title'),
                    content: t('careers.values.content'),
                })}
                <Values />
            </Stack>
            <Stack direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                padding={5}
                sx={{ backgroundColor: "#00FF4222" }}>
                <Typography textAlign={'center'}>{t('careers.learnMore.title')}</Typography>
                <Typography textAlign={'center'}>{t('careers.learnMore.content')}</Typography>
                <Button variant="contained" onClick={() => navigate("/faq")}>{t('careers.learnMore.actionBtn')}</Button>
            </Stack>
        </Stack>
    );
}

const ColoredStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 90deg,rgb(0, 255, 66) 0%,rgb(255, 0, 0) 50%,rgb(0, 0, 255) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 90deg,rgb(0, 255, 66) 0%,rgb(255, 0, 0) 50%,rgb(0, 0, 255) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColoredStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 157deg, rgb(0, 0, 255) 0%, rgb(0, 255, 66) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 157deg, rgb(0, 0, 255) 0%, rgb(0, 255, 66) 100%)',
    }),
}));

function ColoredStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <AutoStories />,
        2: <Diversity1 />,
        3: <Diversity2 />,
        4: <Diversity3 />,
    };

    return (
        <ColoredStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColoredStepIconRoot>
    );
}

const Values = () => {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState<number>(0);
    const VALUES = useMemo(() => {
        return (t('careers.valuesList', { returnObjects: true, defaultValue: [] })) as Array<{ title: string, content: string }>
    }, [t]);

    return (
        <Stack sx={{ width: '100%' }}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColoredStepConnector />}>
                {VALUES.map(({ title }, index) => (
                    <Step key={`value-${index}`} onClick={() => setActiveStep(index)}>
                        <StepLabel sx={{ fontWeight: 'bold', fontSize: '30' }} StepIconComponent={ColoredStepIcon}>{title}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Typography variant="body1" p={3} textAlign={'center'}>{VALUES[activeStep]?.content ?? ''}</Typography>
        </Stack>
    );
}
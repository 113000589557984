import React from "react";
import { TextFieldProps, Stack, TextField, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DEFAULT_CURRENCY, getCurrencySymbol } from "../../../utilities/currency/function";
import { MIN_PRICE } from "../../../utilities/listings";
import { DiscountInput } from "./DiscountInput";

// type FieldName = "nightlyPrice" | "weeklyDiscount" | "monthlyDiscount";
export type FieldName = keyof Pick<IPricingInputProps, "nightlyPrice" | "weeklyDiscount" | "monthlyDiscount">;

export interface IPricingInputProps {
    currency?: string;
    nightlyPrice: Pick<TextFieldProps, 'value' | 'error' | 'helperText'>;
    weeklyDiscount: Pick<TextFieldProps, 'value' | 'error' | 'helperText'>;
    monthlyDiscount: Pick<TextFieldProps, 'value' | 'error' | 'helperText'>;
    handleOnChange: (fieldName: FieldName, text: string) => void;
    handleOnBlur: (fieldName: FieldName, text: string) => void;
}

/** The input for listing pricing */
export const PricingInput = ({
    currency = DEFAULT_CURRENCY,
    nightlyPrice, weeklyDiscount, monthlyDiscount,
    handleOnChange: handleOnChangeProps, handleOnBlur: handleOnBlurProps }: IPricingInputProps) => {
    const { t } = useTranslation();

    const handleOnChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
        = (event) => {
            const value = event.target.value;
            const name = event.target.name as FieldName;
            handleOnChangeProps(name, value);
        }
    const handleOnBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
        = (event) => {
            const value = event.target.value;
            const name = event.target.name as FieldName;
            handleOnBlurProps(name, value);
        }

    return (
        <Stack direction={'column'}
            alignItems={'stretch'}
            spacing={2}>
            <TextField label={t('listing.pricing.amountLabel')}
                placeholder={t('listing.pricing.amountPlaceholder')}
                value={""}
                name={"nightlyPrice"}
                {...nightlyPrice}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                inputProps={{
                    min: MIN_PRICE,
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>
                }}
                type={'number'}
            />
            <DiscountInput label={t('listing.pricing.weeklyDiscountLabel')}
                placeholder={t('listing.pricing.weeklyDiscountPlaceholder')}
                value={""}
                name={"weeklyDiscount"}
                {...weeklyDiscount}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
            <DiscountInput label={t('listing.pricing.monthlyDiscountLabel')}
                placeholder={t('listing.pricing.monthlyDiscountPlaceholder')}
                value={""}
                name={"monthlyDiscount"}
                {...monthlyDiscount}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
            />
        </Stack>
    )
}
import React, { PropsWithChildren, useState } from "react";
import { Edit } from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface IAccountInfoItemProps extends PropsWithChildren<{}> {
    /** The title of the item */
    title: string;
    /** The description of the item (showing when in edit mode) */
    description?: string;
    /** The string value, if present, to display (can provide a child to display more than just a text) */
    value?: string | (() => JSX.Element);
    /** Boolean indicating if the view is in edit mode (default to false) */
    isInEditMode?: boolean;
    /** Callback when edit button clicked (should transform into edit mode). 
     * If not provided, button will not show (for read only account info) */
    onEditClick?: () => void;
    /** (optional) when provided, will show this label in a button instead of default edit icon */
    editLabel?: string;
    /** Callback when in edit mode and cancel button clicked */
    onCancelClick?: () => void;
    /** Callback when in edit mode and save button clicked */
    onSaveClick?: () => Promise<void>;
    /** Boolean indicating if the item is disabled (happen if another item is in edit mode, so we avoid having 2 in edit mode at the same time) */
    disabled?: boolean;
};

/** Display of an item in the account information */
export const AccountInfoItem = ({
    title,
    description,
    value,
    isInEditMode = false,
    onEditClick,
    editLabel,
    onCancelClick,
    onSaveClick,
    disabled,
    children
}: IAccountInfoItemProps) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);

    const handleOnSaveClick = async () => {
        setSaving(true);
        try {
            await onSaveClick?.();
        } catch (e) {
            //error should be handled by parent
        } finally {
            setSaving(false);
        }
    }

    return (
        <Stack sx={disabled ? { cursor: 'not-allowed', opacity: 0.4 } : {}}
            direction={'column'}
            justifyContent={'start'}
            alignItems={'stretch'}
            p={2}
            spacing={1}
        >
            <Stack direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <Typography variant="h5">{title}</Typography>
                {
                    isInEditMode ?
                        !!onCancelClick && <Button variant="text" onClick={onCancelClick}>{t('user.account.cancelButton')}</Button>
                        :
                        !!onEditClick ?
                            !!editLabel ?
                                <Button onClick={onEditClick}>{editLabel}</Button>
                                : <IconButton onClick={onEditClick}><Edit /></IconButton>
                            : null
                }
            </Stack>

            {isInEditMode ?
                !!description && <Typography variant="subtitle1">{description}</Typography>
                : (!value || typeof value === 'string') ?
                    <Typography variant="body1">{value || t('user.account.infoNotProvided')}</Typography>
                    : value()
            }

            {isInEditMode && children}

            {isInEditMode && !!onSaveClick &&
                <Button disabled={saving} variant="contained" onClick={handleOnSaveClick}>{t('user.account.saveButton')}</Button>}
        </Stack>
    );
}
import React, { useEffect, useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Checkbox, Container, debounce, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControlLabel, FormGroup, Grid, Skeleton, Slide, Stack, Tooltip, Typography } from "@mui/material";
import { ListingItem } from "../../components/listing/ListingItem/ListingItem";
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';
import { PropsWithLoadingState } from "../../utilities";
import { useQueries } from "react-query";
import { queryFn as queryBookingComFn } from "./utils/booking_com";
import { queryFn as queryHotelComFn } from "./utils/hotel_com";
import { GOOGLE_MAPS_API_KEY } from "../../services/firebase";
import { queryFn as queryAirbnbFn } from "./utils/airbnb";
import { ESupportedPlatform, getPlatformDisplayName } from "./utils";
import { DateRangeDisplay, DateRangePicker, IDateRangePicker } from "./DateRangePicker";
import { Boundary, Filters, ISearchListingItemProps, LocationLatLng } from "./SearchListingsPage.types";
import { addDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { GoogleMap, InfoWindow, Libraries, LoadScript, Marker, OverlayView } from "@react-google-maps/api";
import { DEFAULT_LISTING_IMAGE_URL } from "../../utilities/assets/assetHelper";
import { ExpandMore, Place } from "@mui/icons-material";
import { TransitionProps } from "@mui/material/transitions";
import { queryFn as queryCohostopFn } from './utils/cohostop';
import { getCenterOfBoundary, getLatLng, isCloseBy } from "./utils/location";
import { useListingFilters } from "../../hooks/useListingFilter";
import { GuestsFilterSelection, IGuestsFilterSelectionProps } from "../../components/GuestsFilterSelection";
import { GuestsSelectionDisplay } from "../../components/GuestsFilterSelection/GuestsFilterSelection";
import { getColorFrom } from "../../utilities/theme/ColorUtils";
import { ERole } from "../../utilities/EnumUtility";
import { SearchLocationInputView } from "../../components/SearchLocationInput/SearchLocationInput";

/** The default zoom of the map */
const DEFAULT_ZOOM = 12;//around 10k diameter
/** The time in advance for default check-in */
const DEFAULT_CHECK_IN_ADVANCE = 24 * 60 * 60 * 1000;//1 day in ms
/** The default stay length (in days) */
const DEFAULT_STAY_LENGTH = 1;//1 days
/** The default location (to not have an empty string on the first view of the page for user without currentLocation) */
const DEFAULT_LOCATION = 'Vancouver, BC, Canada';

export const SearchListingsPage = () => {
  const { filters, onFiltersChange } = useListingFilters();

  return <SearchListingsContainer filters={filters} setFilters={onFiltersChange} />;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ISearchListingsContainerProps {
  /** The values to filter with (partial, will defined default in the container) */
  filters: Partial<Filters>,
  /** Callback to set the values to filter from (set search params in the navigation) */
  setFilters: (args: Partial<Filters>) => void;
  /** The array of platform we want to fetch listings from */
  platformIds?: ESupportedPlatform[];
  /** Navigate to the support page (for third party click) */
  navigateToSupportPage?: () => void;
}

/** Search listing container which takes care of fetching the data with the given filter information */
const SearchListingsContainer = ({
  filters, setFilters,
  // platformIds: platformsIdsProps = [ESupportedPlatform.COHOSTOP], //default to our platform only
  // platformIds = [ESupportedPlatform.BOOKING_COM],
  // platformIds = [ESupportedPlatform.HOTEL_COM],
  // platformIds = [ESupportedPlatform.AIRBNB],
  platformIds: platformsIdsProps = Object.values(ESupportedPlatform),
  navigateToSupportPage,
}: ISearchListingsContainerProps) => {
  const { i18n, t } = useTranslation();
  const [currentLocation, setCurrentLocation] = useState<Filters["location"]>(DEFAULT_LOCATION);
  const [platformIds, setPlatformIds] = useState(platformsIdsProps);//for now have those locally and not coming from the url path
  //the dialog information
  const [dialogInfo, setDialogInfo] = useState<{ open: boolean, postAction?: Function }>({ open: false });
  const handleDialogOpen = (postAction: Function) => {
    setDialogInfo({ open: true, postAction });
  };
  const handleDialogClose = (postAction?: Function) => {
    setDialogInfo({ open: false });
    postAction?.();
  };

  // console.log('filter', filters);
  //have all the defaults set up if not provided but needed
  const fullFilters: Filters = useMemo<Filters>(() => ({
    check_in: filters.check_in || new Date(Date.now() + DEFAULT_CHECK_IN_ADVANCE),
    check_out: filters.check_out || addDays(filters.check_in || new Date(Date.now() + DEFAULT_CHECK_IN_ADVANCE), DEFAULT_STAY_LENGTH),
    location: filters.location || currentLocation,
    adults_number: filters.adults_number || 1,
    order_by: filters.order_by || undefined,
    room_number: filters.room_number || undefined,
    children_number: filters.children_number || undefined,
    infants_number: filters.infants_number || undefined,
    pets_number: filters.pets_number || undefined,
    currency: "CAD",
    locale: i18n.language || "en",
    bounds: filters.bounds,
  }), [currentLocation, filters, i18n.language]);

  // console.log('fullFilters', fullFilters);

  const results = useQueries([
    {
      queryKey: ["listings", "booking.com", platformIds.includes(ESupportedPlatform.BOOKING_COM), fullFilters],
      queryFn: () => queryBookingComFn(filters, fullFilters),
      enabled: platformIds.includes(ESupportedPlatform.BOOKING_COM),
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
    },
    {
      queryKey: ["listings", "hotel.com", platformIds.includes(ESupportedPlatform.HOTEL_COM), fullFilters],
      queryFn: () => queryHotelComFn(filters, fullFilters),
      enabled: platformIds.includes(ESupportedPlatform.HOTEL_COM),
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
    },
    {
      queryKey: ["listings", "airbnb", platformIds.includes(ESupportedPlatform.AIRBNB), fullFilters],
      queryFn: () => queryAirbnbFn(filters, fullFilters),
      enabled: platformIds.includes(ESupportedPlatform.AIRBNB),
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
    },
    {
      queryKey: ["listings", "cohostop", platformIds.includes(ESupportedPlatform.COHOSTOP), fullFilters],
      queryFn: () => queryCohostopFn(filters, fullFilters),
      enabled: platformIds.includes(ESupportedPlatform.COHOSTOP),
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
    },
  ])

  useEffect(() => {
    // console.log('fetch current location', filters.location)
    if (!!filters.location && filters.location !== DEFAULT_LOCATION) return;// if we already have a location defined
    //get the current location of the user 
    navigator.geolocation.getCurrentPosition((position) => {
      // console.log('got user location', position);
      const userLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
      setCurrentLocation(userLocation)
      if (!filters.location) {
        //if still don't have filter by then -> adding it to the filter
        setFilters({ location: userLocation });
      }
    },
      (e) => console.warn('failed to get current location', e),
      { maximumAge: 10 });
  }, [filters.location, setFilters])

  return (
    <>
      <SearchListingsScreen
        location={{
          value: Array.isArray(fullFilters.location) ? fullFilters.location.join(',') : fullFilters.location || '',
          // onChange: (value) => console.log('value changed', value)
          onChange: (value) => setFilters({ location: value })
        }}
        mapInfo={{
          zoom: DEFAULT_ZOOM,
          boundary: filters.bounds,
          onChange: (boundary) => setFilters({ bounds: boundary })
        }}
        dates={{
          startDate: filters.check_in,
          endDate: filters.check_out,
          // onDateRangeSelected: (...args) => console.log('value changed', ...args)
          onDateRangeSelected: (start, end) => setFilters({ check_in: start, check_out: end })
        }}
        guests={{
          adults: {
            value: fullFilters.adults_number,
            // onChange: (value) => console.log('value changed', value)
            onChange: (value) => setFilters({ adults_number: value })
          },
          children: {
            value: fullFilters.children_number || 0,
            // onChange: (value) => console.log('value changed', value)
            onChange: (value) => setFilters({ children_number: value })
          },
          infants: {
            value: fullFilters.infants_number || 0,
            // onChange: (value) => console.log('value changed', value)
            onChange: (value) => setFilters({ infants_number: value })
          },
          pets: {
            value: fullFilters.pets_number || 0,
            // onChange: (value) => console.log('value changed', value)
            onChange: (value) => setFilters({ pets_number: value })
          }
        }}
        platforms={{
          value: platformIds,
          // onChange: (value) => console.log('value changed', value)
          onChange: (values) => setPlatformIds(values)
        }}
        items={results.some(result => result.isLoading) ?
          { loading: true }
          : results.flatMap(result => result.data
            ?.map((item) => (item.platformId !== ESupportedPlatform.COHOSTOP ?
              { ...item, onClick: () => handleDialogOpen(item.onClick) }
              : item))
            || [])
            .sort((a, b) => {
              //have cohostop listing priority at the top
              if (a.platformId === ESupportedPlatform.COHOSTOP && b.platformId !== ESupportedPlatform.COHOSTOP) {
                return -1;
              }
              if (a.platformId !== ESupportedPlatform.COHOSTOP && b.platformId === ESupportedPlatform.COHOSTOP) {
                return +1;
              }
              if (a.platformId === ESupportedPlatform.COHOSTOP && b.platformId === ESupportedPlatform.COHOSTOP) {
                return b.rating.rate - a.rating.rate;
              }
              return b.rating.rate - a.rating.rate;
            })}
      />
      <Dialog
        open={dialogInfo.open}
        TransitionComponent={Transition}
        onClose={() => handleDialogClose()}
      >
        <DialogTitle>{t("listings.search.dialogRedirectionTitle")}</DialogTitle>
        <DialogContent sx={{ whiteSpace: 'pre-line' }}>
          <DialogContentText>
            {t("listings.search.dialogRedirectionContent")}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ direction: 'column' }}>
          <Button onClick={() => handleDialogClose()}>{t('listings.search.dialogRedirectionCancelBtn')}</Button>
          {!!navigateToSupportPage && <Button onClick={() => navigateToSupportPage()}>{t('listings.search.dialogRedirectionSupportBtn')}</Button>}
          <Button onClick={() => handleDialogClose(dialogInfo.postAction)}>{t('listings.search.dialogRedirectionOkBtn')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface ISearchListingsScreenProps {
  /** The location field */
  location: {
    value: string | LocationLatLng,
    onChange: (value: string | LocationLatLng) => void
  };
  /** The map boundary or zoom */
  mapInfo: {
    zoom: number,
    boundary?: Boundary;
    onChange: (boundary: Boundary) => void;
  }
  /** The dates (check-in/out) */
  dates: IDateRangePicker;
  /** The guests information */
  guests: IGuestsFilterSelectionProps;
  /** The platform researched */
  platforms: { value: ESupportedPlatform[], onChange: (value: ESupportedPlatform[]) => void }
  /** The items result of the research to display */
  items: PropsWithLoadingState<ISearchListingItemProps[]>;
}

/** The list of libraries used by the SearchListingsScreen */
const GOOGLE_LIBRARIES: Libraries = ["places"];

const SearchListingsScreen = ({ location, mapInfo, dates, guests, platforms, items }: ISearchListingsScreenProps) => {
  const { t } = useTranslation();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [selectedMarker, setSelectedMarker] = useState<ISearchListingItemProps | null>(null);
  const [isMapOn, setMapOn] = useState(false);
  const [center,] = useState<LocationLatLng>({ lat: 0, lng: 0 });

  const canShowMap = !!map//!!items.length && !!map;
  const hasCoHostOpListings = !items.loading && items.some(item => item.platformId === ESupportedPlatform.COHOSTOP);

  const [inputValue, setInputValue] = useState<string>('');

  const [expandedFilter, setExpandedFilter] = React.useState<string | false>(false);
  const handleFilterExpansionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedFilter(isExpanded ? panel : false);
    };

  useEffect(() => {
    // console.warn('useEffect', location.value, mapInfo.boundary)
    //whenever the location change, we recenter the map to this new location
    const place = location.value;
    if (map && !!place) {
      const geocoder = new google.maps.Geocoder();
      if (typeof place === 'string') {
        //set the input to this place
        setInputValue(place);
        //set the map
        if (!!mapInfo.boundary) {
          //no need to search the lat/lng of the string, just take the mid of the boundary
          const newCenter = getCenterOfBoundary(mapInfo.boundary);
          map.panTo(newCenter);
        } else {
          //research lat/lng of this place
          geocoder.geocode({ address: place }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK && results?.[0]) {
              const location = results[0].geometry.location;
              const newCenter = {
                lat: location.lat(),
                lng: location.lng(),
              };

              map.panTo(newCenter);
            } else {
              console.warn('geocode failed', results)
            }
          });
        }
      } else {
        //set the input
        //research place name of this place
        geocoder.geocode({ location: place }, (results, status) => {
          // console.log('geocode', results, status)
          if (status === google.maps.GeocoderStatus.OK && !!results) {
            let result = '';
            var t = 0;
            const searches = ['locality', 'sublocality', 'postal_code', 'neighborhood', 'political', 'country'] as const;
            while (!result && t < searches.length) {
              const search = searches[t];
              result = results?.find(res => res.types.includes(search))?.formatted_address || '';
              t++;
            }
            // console.log('geocode result', result)
            //set the input to this place
            setInputValue(result);
          } else {
            console.warn('geocode result failed', results)
          }
        });
        //set the map
        if (!!mapInfo.boundary) {
          //if the boundary is provided, it takes priority
          const newCenter = getCenterOfBoundary(mapInfo.boundary);
          map.panTo(newCenter);
        } else {
          //already have the lat/lng so just center the map to it
          map.panTo(place);
        }
      }
    }

  }, [location.value, map, mapInfo.boundary])


  // Handle the selection of a place in the list of autocomplete
  const handlePlaceSelect = (place: google.maps.places.AutocompletePrediction | null) => {
    // console.warn('handlePlaceSelect')
    if (map && place) {
      (new google.maps.places.PlacesService(map)).getDetails({ placeId: place.place_id },
        (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && !!results?.geometry?.location) {
            const loc = results.geometry.location;
            const newCenter = {
              lat: loc.lat(),
              lng: loc.lng(),
            };

            map.panTo(newCenter);
            location.onChange(newCenter);
          }
        });
    }
  };

  //handle bounds changed
  const handleBoundsChange = () => {
    //get the bounds of the map
    const bounds = map?.getBounds();
    if (!bounds) return;//if no bounds, nothing to do

    const ne = getLatLng(bounds.getNorthEast());
    const sw = getLatLng(bounds.getSouthWest());
    if (!ne || !sw) return;//if no bounds determined, nothing to do

    if (!mapInfo.boundary
      || (!isCloseBy(ne, mapInfo.boundary.ne) && !isCloseBy(sw, mapInfo.boundary.sw)))
      //only if the boundary significantly changed we tell the parent
      mapInfo.onChange({
        ne,
        sw,
      })
  }
  // need to debounce those bounds change
  const debouncedBoundsChangeHandler = debounce(handleBoundsChange, 1000);

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}
      libraries={GOOGLE_LIBRARIES}
      loadingElement={<SearchListingsScreen.Loading />}>

      <Container sx={{ p: 4 }} maxWidth={false}>
        <Grid container columnSpacing={2}>
          {/* content location/filters/list */}
          <Grid item container xs={12} md={isMapOn ? 6 : 12} justifyContent={'space-evenly'} alignItems={'center'} rowSpacing={2} columnSpacing={1}>
            {/* Location */}
            <Grid item xs={12}>
              <SearchLocationInputView
                value={inputValue}
                onPlaceSelected={handlePlaceSelect}
                label={t('listings.search.locationInputLabel')}
                placeholder={t('listings.search.locationInputLabel')}
                variant="outlined"
              />
            </Grid>
            {/* filters */}
            <Grid item xs={12}>
              <Stack direction={'column'} justifyContent={'space-evenly'} alignItems={'stretch'} spacing={1}>

                <Typography variant={'h3'}>{t('listings.filters.title')}</Typography>

                <Accordion expanded={expandedFilter === 'filter1'}
                  onChange={handleFilterExpansionChange('filter1')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      {t('listings.filters.datesLabel')}
                    </Typography>
                    <DateRangeDisplay {...dates} style={{ color: 'text.secondary' }} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <DateRangePicker {...dates} />
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expandedFilter === 'filter2'} onChange={handleFilterExpansionChange('filter2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('listings.filters.guestsLabel')}</Typography>
                    <GuestsSelectionDisplay {...guests} style={{ color: 'text.secondary' }} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <GuestsFilterSelection {...guests} />
                  </AccordionDetails>
                </Accordion>
                {/* <Accordion expanded={expandedFilter === 'filter3'} onChange={handleFilterExpansionChange('filter3')}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      Sort by
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                      Popularity
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                  </AccordionDetails>
                </Accordion> */}
                <Accordion expanded={expandedFilter === 'filter4'} onChange={handleFilterExpansionChange('filter4')}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('listings.filters.platformsLabel')}</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                      {!platforms.value.length || platforms.value.length === 1 ?
                        t("listings.filters.platformsSummaryNone")
                        : platforms.value.length >= (Object.values(ESupportedPlatform).length) ?
                          t("listings.filters.platformsSummaryAll")
                          : t("listings.filters.platformsSummary", { values: platforms.value.map(platform => getPlatformDisplayName(platform)) })}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormGroup row={true}>
                      {Object.values(ESupportedPlatform)
                        .filter(platform => platform !== ESupportedPlatform.COHOSTOP)//we don't want to be able to uncheck ours
                        .map(platform =>
                          <FormControlLabel key={platform}
                            control={<Checkbox
                              checked={platforms.value.includes(platform)}
                              onChange={(_, checked) => {
                                if (checked)
                                  platforms.onChange([...platforms.value, platform])
                                else
                                  platforms.onChange(platforms.value.filter(value => value !== platform))
                              }} />
                            }
                            label={getPlatformDisplayName(platform)} />
                        )}
                    </FormGroup>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Grid>
            {/* content list */}
            <Grid item xs={12}>
              <Typography variant={'h3'}>{`${items.length || 0} listings`}</Typography>
              {!hasCoHostOpListings && !!items.length && <Typography variant={'caption'}>{t('listings.search.emptyMessage')}</Typography>}
            </Grid>

            <Grid item container xs={12}
              justifyContent={'space-evenly'} alignItems={'center'} rowSpacing={2} columnSpacing={1}
              sx={{ display: { xs: isMapOn ? 'none' : 'flex', md: 'flex' } }}>
              {!!items.loading ?
                new Array(10).fill('').map((_, index) => (
                  <Grid item key={`listing-${index}-loading`} xs={12} sm={isMapOn ? 12 : 6} md={isMapOn ? 6 : 4}>
                    <Skeleton variant="text" />
                    <Skeleton variant="rectangular" width={210} height={118} />
                    <Skeleton variant="text" />
                  </Grid>))
                :
                !items.length ?
                  // height 100% to trick the view to take all the place so it doesn't jump between loading and empty state
                  <Grid item xs={12} height={'100%'}>
                    <Typography>{t('listings.search.noResultMessage')}</Typography>
                  </Grid>
                  :
                  items.map((item: ISearchListingItemProps, index: number) => (
                    <Grid key={`${item.lat}-${item.lng}-listing-${index}`}
                      item
                      xs={12}
                      sm={isMapOn ? 12 : 6}
                      md={isMapOn ? 6 : 4}>
                      <ListingItem {...item} />
                    </Grid>
                  ))}
            </Grid>
          </Grid>
          {/* content map */}
          <Grid sx={{ display: isMapOn ? 'flex' : 'none', height: '80vh', position: 'sticky', top: 100 }} item xs={12} md={6}>
            <GoogleMap
              mapContainerStyle={{
                height: '100%',
                width: '100%',
              }}
              zoom={mapInfo.zoom}
              onLoad={setMap}
              center={
                center
              }
              clickableIcons={false}
              options={{
                streetViewControl: false,
                fullscreenControl: false,
              }}
              // onZoomChanged={() => { console.log('zoom change', map?.getZoom(), getRadiusInM(map?.getBounds())) }}
              // onBoundsChanged={() => { console.log('bounds change', map?.getBounds(), getRadiusInM(map?.getBounds())) }}
              onBoundsChanged={debouncedBoundsChangeHandler}
            >
              {(
                items.loading ?
                  []
                  : items
              ).map((item: ISearchListingItemProps, index) => {
                let color = '';
                switch (item.platformId) {
                  case ESupportedPlatform.AIRBNB:
                    color = '#FF5A5F';
                    break;
                  case ESupportedPlatform.BOOKING_COM:
                    color = '#003580';
                    break;
                  case ESupportedPlatform.HOTEL_COM:
                    color = '#EF3345';
                    break;
                  default:
                    color = getColorFrom(ERole.GUEST);

                }
                const simpleMarker = false;
                if (simpleMarker) {
                  return <Marker
                    key={`${item.lat}-${item.lng}-${index}-marker`}
                    position={{ lat: item.lat, lng: item.lng }}
                    onClick={() => setSelectedMarker(item)}
                  />
                } else {
                  return (
                    <OverlayView
                      key={`${item.lat}-${item.lng}-${index}`}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      position={{ lat: item.lat, lng: item.lng }}
                      getPixelPositionOffset={(width, height) => ({
                        x: -((width || 42) / 2),
                        y: -(height || 42),
                      })}
                    >
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '42px', /* Customize the size as needed */
                        height: '42px', /* Customize the size as needed */
                        // backgroundColor: '#007bff', /* Example background color */
                        // border: '2px solid #fff', /* Example border style */
                        // borderRadius: '50%', /* Make it circular */
                        // color: '#fff', /* Text color */
                        // fontWeight: 'bold',
                        position: 'relative', /* Needed for proper layering */
                      }}
                        onClick={() => setSelectedMarker(item)}
                      >
                        <Place sx={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          top: 0,
                          left: 0,
                          // opacity: 0.5, /* Adjust opacity as needed */
                          color, /* Adjust color as needed */
                        }} />
                        <Avatar sx={{
                          zIndex: 1, /* Ensure the Avatar is above the PlaceIcon */
                          height: '50%',
                          width: '50%',
                          backgroundColor: '#FFF',
                          alignSelf: 'start',
                          top: '15%'
                        }}
                          src={item.hostAvatar}
                          variant={'circular'} />
                      </Box>
                    </OverlayView>
                  );
                }
              })}

              {selectedMarker && (
                <InfoWindow
                  position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                  onCloseClick={() => setSelectedMarker(null)}
                >
                  <Stack direction={'column'} spacing={1}>
                    <Stack direction={'row'} spacing={1} justifyContent={'start'} alignItems={'center'}>
                      <Avatar src={selectedMarker.hostAvatar} />
                      <Typography variant="h4">{selectedMarker.title}</Typography>
                    </Stack>
                    {!!selectedMarker.images &&
                      <Box sx={{ aspectRatio: 2 / 1, height: '100%', width: '100%', objectFit: 'cover' }}
                        component={'img'}
                        borderRadius={2}
                        src={(Array.isArray(selectedMarker.images) ? selectedMarker.images[0] : selectedMarker.images) || DEFAULT_LISTING_IMAGE_URL}
                        alt={selectedMarker.title}
                      />
                    }
                    <Button variant="contained" size={'small'} onClick={selectedMarker.onClick}>View details</Button>
                  </Stack>
                </InfoWindow>
              )}
            </GoogleMap>
          </Grid>
        </Grid>
        {
          canShowMap &&
          <Box style={{ display: 'flex', position: 'sticky', bottom: 10, flexDirection: 'row', justifyContent: 'flex-end', paddingLeft: 10, paddingRight: 10 }}>
            <Tooltip title={isMapOn ? t('listings.search.toggleMapOff') : t('listings.search.toggleMapOn')}>
              <Fab variant="circular"
                color="primary"
                aria-label={isMapOn ? t('listings.search.toggleMapOff') : t('listings.search.toggleMapOn')}
                onClick={() => setMapOn(toggle => !toggle)}>
                {isMapOn ? <ListIcon /> : <MapIcon />}
              </Fab>
            </Tooltip>
          </Box>
        }
      </Container>
    </LoadScript>
  );
}

SearchListingsScreen.Loading = () => {
  return <Container sx={{ p: 4 }} maxWidth={false}>
    <Grid container columnSpacing={2}>
      <Grid item container xs={12} md={6} justifyContent={'space-evenly'} alignItems={'center'} rowSpacing={2} columnSpacing={1}>
        {/* filters */}
        <Grid item xs={12}>
          <Typography variant={'h3'}><Skeleton /></Typography>
        </Grid>
        {/* content list */}
        <Grid item xs={12}>
          <Typography variant={'h3'}><Skeleton /></Typography>
        </Grid>

        {
          new Array(10).fill('').map((_, index) => (
            <Grid key={`listing-${index}-loading`} item xs={12} sm={6} md={4}>
              <Skeleton variant="text" />
              <Skeleton variant="rectangular" width={210} height={118} />
              <Skeleton variant="text" />
            </Grid>))
        }
      </Grid>
      {/* content map */}
      <Grid sx={{ display: 'flex', maxHeight: '80vh', position: 'sticky', top: 100 }} item xs={12} md={6}>
        <Skeleton variant={'rectangular'} sx={{ height: '100%', width: '100%' }} />
      </Grid>
    </Grid>
  </Container>
}
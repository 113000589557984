
import { useNavigate, useParams } from "react-router";
import { createSearchParams } from "react-router-dom";
import { ROUTE_PARAMS, ROUTE_PATH, ROUTE_QUERY } from "../utilities/navigation";

/** helper hook which gives navigation function for the Shop subdomain */
export const useShopNavigation = () => {
    const navigate = useNavigate();
    const { [ROUTE_PARAMS.shopID]: shopId } = useParams();

    /** Navigate to the main/home page of the shop */
    const navigateToShop = () => {
        navigate(ROUTE_PATH.shop.shopBuild(shopId))
    }

    /** Navigate to a certain product in the shop */
    const navigateToProduct = (productId?: string, variantId?: string) => {
        if (!productId) {
            // only redirect to the shop itself
            navigate(ROUTE_PATH.shop.shopBuild(shopId))
        } else if (!variantId) {
            //navigate to the product
            navigate(ROUTE_PATH.shop.shopProductBuild(productId, shopId))
        } else {
            //navigate to the product and specify the variant of it
            navigate({
                pathname: ROUTE_PATH.shop.shopProductBuild(productId, shopId),
                search: variantId && `?${createSearchParams({ [ROUTE_QUERY.variantID]: variantId })}`
            });
        }
    }

    /** Navigate to the cart */
    const navigateToCart = () => {
        navigate(ROUTE_PATH.shop.shopCartBuild(shopId));
    }

    /** Navigate to checkout page */
    const navigateToCheckout = () => {
        navigate(ROUTE_PATH.shop.shopCheckoutBuild(shopId));
    }

    return {
        shopId,
        navigateToShop,
        navigateToProduct,
        navigateToCart,
        navigateToCheckout
    }
}
import React, { useState } from "react";
import { Button, IconButton, Stack, TextField, Typography } from "@mui/material"
import { useRemoteConfig } from "../../services/remoteConfig";
import { RC_MAINTENANCE_MODE } from "../../types/remote_config";
import { isEmailValid } from "../../utilities";
import { LoadingButton } from "@mui/lab";
import { SOCIALS } from "../../components/Footer/Footer";
import { ChoBackgroundScreen } from "../../components/ChoBackgroundScreen";
import { sendSupportTicket } from "../../services/support";
import { useTranslation } from "react-i18next";

export const MaintenanceModePage = () => {
    return <MaintenanceModeContainer />
}

const MaintenanceModeContainer = () => {
    const { [RC_MAINTENANCE_MODE]: FF_Maintenance } = useRemoteConfig(RC_MAINTENANCE_MODE);
    const [email, setEmail] = useState('');
    const [fieldError, setFieldError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);

    if (!FF_Maintenance.enabled) {
        //if we are not on Maintenance mode anymore, refresh the page
        window.location.reload();
    }

    const handleEmailSend = async () => {
        if (!isEmailValid(email)) {
            setFieldError(true);
            return;
        } else {
            setFieldError(false)
        }
        //put in our support log
        setLoading(true);
        await sendSupportTicket({
            type: "contact",
            feature: "maintenance",
            navigation_location: Object.keys(window.location).reduce((prev, key) => {
                const value = { ...window.location }[key];
                if (typeof value === 'function' || typeof value === 'undefined' || typeof value === 'object') {
                    return prev;
                }
                return {
                    ...prev,
                    [key]: value,
                }
            }, {}),
            user_sender: email,//grab the email instead of id in this case
            error_message: 'MaintenanceMode'
        })
        setLoading(false);
        setSent(true);
    }

    const handleContactClick = () => {
        window.open(`mailto:contact@cohostop.com?subject=${"Maintenance"}`, '_blank');
    }

    return <MaintenanceModeScreen
        emailField={FF_Maintenance.notificationEnabled ?
            sent ||
            {
                value: email,
                onChange: setEmail,
                onSend: handleEmailSend,
                isLoading: loading,
                isError: fieldError
            }
            : undefined}
        socials={FF_Maintenance.socialsEnabled ? SOCIALS : undefined}
        onContactUsClick={FF_Maintenance.contactEnabled ? handleContactClick : undefined}
    />
}

interface IMaintenanceModeScreenProps {
    /** The email field, only show if provided, if boolean true only show thanks you note for sending the email */
    emailField?: {
        value: string;
        onChange: (text: string) => void;
        isLoading: boolean;
        isError: boolean;
        onSend: () => void
    } | true,
    /** List of social links */
    socials?: {
        icon: React.JSX.Element;
        onClick: () => void;
    }[]
    /** Callback click on the contact us button, only show if provided */
    onContactUsClick?: () => void;
}

const MaintenanceModeScreen = ({ emailField, socials, onContactUsClick }: IMaintenanceModeScreenProps) => {
    const { t } = useTranslation();
    return (
        <ChoBackgroundScreen>
            <Stack height={'100%'} direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={2}>
                <Typography variant={'h1'} textAlign={'center'} fontWeight={'bold'}>{t('maintenanceMode.header')}</Typography>
                <Typography variant={'h4'} textAlign={'center'}>{t('maintenanceMode.subHeader')}</Typography>
                <Typography variant={'h3'} textAlign={'center'}>{t('maintenanceMode.content')}</Typography>
                <Typography variant={'h5'} textAlign={'center'}>{t('maintenanceMode.subTitle')}</Typography>
                {
                    !!emailField &&
                    (
                        (typeof emailField === 'boolean') ?
                            <Typography>{t('maintenanceMode.confirmationMessage')}</Typography>
                            :
                            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={'start'} alignItems={{ xs: 'center', md: 'baseline' }} spacing={1}>
                                <TextField
                                    type={'email'}
                                    label={t('maintenanceMode.inputLabel')}
                                    placeholder={t('maintenanceMode.inputPlaceholder')}
                                    value={emailField.value}
                                    onChange={(event) => emailField.onChange(event.target.value)}
                                    onKeyDown={(event) => { if (event.key === 'Enter') { emailField.onSend() } }}
                                    error={emailField.isError}
                                    helperText={emailField.isError ? t('maintenanceMode.inputError') : undefined}
                                    disabled={emailField.isLoading}
                                    size={'small'} />
                                <LoadingButton loading={emailField.isLoading} variant={'contained'} onClick={emailField.onSend}>{t('maintenanceMode.sendButton')}</LoadingButton>
                            </Stack>
                    )
                }
                {!!socials?.length &&
                    <Stack direction={'row'}>
                        {socials.map((social, index) => <IconButton key={`social-${index}`} onClick={social.onClick}>{social.icon}</IconButton>)}
                    </Stack>
                }
                {
                    !!onContactUsClick &&
                    <Button variant={'outlined'} onClick={onContactUsClick}>{t('maintenanceMode.contactButton')}</Button>
                }
            </Stack>
        </ChoBackgroundScreen>
    );
}
import { Home, Language } from '@mui/icons-material';
import { Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PropsWithLoadingState } from '../../../utilities';

export interface IProfileAboutProps {
    /**
     * The display name of the user
     */
    displayName: string;
    /**
     * The description of the user (given by the user itself)
     */
    description: string;
    /**
     * The origin of the user
     */
    from: string;
    /**
     * The date the user joined the platform
     */
    joinDate: Date;
    /**
     * The languages the user can communicate with
     */
    languages?: string[];
}

export const ProfileAbout = ({
    displayName,
    description,
    from,
    joinDate,
    languages,
    loading
}: PropsWithLoadingState<IProfileAboutProps>) => {
    const { t } = useTranslation();
    return (
        <Stack direction={'column'} spacing={1}>
            <Typography variant={'h2'}>{!loading ? t('user.profile.about', { name: displayName }) : <Skeleton />}</Typography>
            <Typography variant={'caption'}>{!loading ? t('user.profile.joinedOn', { date: joinDate?.toDateString() }) : <Skeleton />}</Typography>
            <Typography variant={'body1'}>{!loading ? description : <Skeleton height={150} />}</Typography>
            <Stack direction={'row'} spacing={1}>
                <Home />
                <Typography>{!loading ? t('user.profile.from', { location: from }) : <Skeleton width={30} />}</Typography>
            </Stack>
            {
                !loading && !!languages?.length &&
                <Stack direction={'row'} spacing={1}>
                    <Language />
                    <Typography>{t('user.profile.speaks', { languages: languages.map(l => t(l, { ns: "languages" })) })}</Typography>
                </Stack>
            }

        </Stack>
    );
}
import { INewsItemProps } from "../../components/news/NewsItem";
import { useNewsNavigation } from "../../hooks/useNewsNavigation";
import { NEWS_TIMESTAMP_PUBLISHED_AT_FIELD, NewsStory, WithID } from "../../types/db";

/** Map the story data model from the DB NewsStory to the UI props INewsItemProps */
export const mapDataToUI = (story: WithID<NewsStory, string>, { navigateToStory }: Pick<ReturnType<typeof useNewsNavigation>, 'navigateToStory'>): INewsItemProps => ({
    title: story.title || '',
    subtitle: story.subtitle || '',
    image: story.image || '',
    timestamp: story[NEWS_TIMESTAMP_PUBLISHED_AT_FIELD]?.toDate() ?? new Date(Date.now()),
    onClick: () => navigateToStory(story.id)
})
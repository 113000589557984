import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useQuery } from 'react-query';
import { getShopCategories } from '../../../services/shop';
import { TabContext, TabPanel } from '@mui/lab';
import { DEFAULT_SHOP_ID } from '../../../utilities/shop';
import { useTranslation } from 'react-i18next';
import { ShopList } from '../../../components/shop/ShopList';

export interface IShopCategoriesContainer {
    /** The DB id of the shop */
    shopId?: string;
    /** (Optional) The selected categoryId */
    selectedCategoryId?: string;
    /** The Children to render */
    children: (categoryId?: string) => React.ReactNode;
}

export const ShopCategoriesContainer = ({
    shopId = DEFAULT_SHOP_ID,
    selectedCategoryId = '',
    children }: IShopCategoriesContainer) => {
    const { t } = useTranslation();
    const { data, isLoading, isError } = useQuery(["shopCategories", shopId, selectedCategoryId], () => getShopCategories(shopId, selectedCategoryId));
    const [value, setValue] = React.useState(selectedCategoryId);

    if (isLoading) {
        return <ShopList loading />
    }

    if (isError || !data) {
        return <ShopList error />
    }

    const handleChange = (_: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
                {data.length > 1 &&
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs onChange={handleChange}
                            value={value}
                            variant={'scrollable'}
                            scrollButtons={'auto'}
                            allowScrollButtonsMobile={true}>
                            <Tab key={`shop-cat-${selectedCategoryId}-all`} label={t('shop.category.all')} value={selectedCategoryId} />
                            {
                                data.map((cat, index) => {
                                    return <Tab key={`shop-cat-${selectedCategoryId}-${index}`} label={cat.name || `cat${index}`} value={cat.id} />
                                })
                            }
                        </Tabs>
                    </Box>
                }
                <TabPanel key={`shop-pan-${selectedCategoryId}-all`} value={selectedCategoryId}>
                    {children(selectedCategoryId)}
                </TabPanel>
                {
                    !!data.length && data.map((cat, index) => {
                        return <TabPanel key={`shop-pan-${selectedCategoryId}-${index}`} value={cat.id}>
                            {/* {children(cat.id)} */}
                            <ShopCategoriesContainer
                                shopId={shopId}
                                selectedCategoryId={cat.id}
                                children={children} />
                        </TabPanel>
                    })
                }
            </TabContext>
        </Box>
    );
}
import React, { useEffect, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import { ChatMessageInput, IChatMessageInputProps } from '../ChatMessageInput';
import { ChatThreadMessages, IChatThreadMessagesProps } from '../ChatThreadMessages';
import { ChatThreadHeader, IChatThreadHeaderProps } from '../ChatThreadHeader';

export interface IChatThreadProps {
    /**
     * The chat thread header
     */
    chatHeader: IChatThreadHeaderProps;
    /** 
     * The chat thread, 
     * - if not provided or false: error state
     * - if boolean true: loading state
     * - if full props, showing the chat thread
     */
    chatThread?: IChatThreadMessagesProps<any> | boolean;
    /**
     * The chat input
     */
    chatInput: Omit<IChatMessageInputProps, 'disabled'>;
}

/** The thread of a chat, have the header and input included */
export const ChatThread = ({ chatHeader, chatThread, chatInput }: IChatThreadProps) => {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current?.scrollBy({ top: scrollRef.current.scrollHeight });
        }
    }, [chatThread]);

    return (
        <Stack direction={'column'}
            justifyContent={'space-between'}
            alignItems={'stretch'}
            height={'92vh'}
            maxHeight={'92vh'}>
            <Box flex={0} p={1}
                border={'thin'}
                borderBottom={1}
                borderColor={'ButtonShadow'}>
                <ChatThreadHeader {...chatHeader} loading={chatThread === true} />
            </Box>
            <Box flex={1} p={1}
                overflow={'auto'}
                ref={scrollRef}>
                {typeof chatThread !== 'boolean' && !!chatThread ?
                    <ChatThreadMessages {...chatThread} />
                    : !chatThread ? <ChatThreadMessages error />
                        : <ChatThreadMessages loading />}
            </Box>
            <Box flex={0} p={1}>
                <ChatMessageInput {...chatInput}
                    disabled={typeof chatThread === 'boolean' || !chatThread} />
            </Box>
        </Stack>
    );
}
/** The types for all news related db (firestore+realtime) objects */
/** NOTE: all change should be done in the shared_type folder 
 * and run `npm run build:shared_types` in root project */

import { Timestamp } from "./common";

/******************************************
 * NEWS DB FIRESTORE
 * |news //collection containing all stories
 * (as this time simple and straight forward (no comment/likes on stories))
 ******************************************/

/** The limited news categories */
export enum ENewsCategories {
    COMPANY = 'company',//news about the company
    PRODUCT = 'product',//news about the web/feature/app
    POLICY = 'policy',//news about changes in the policy
    COMMUNITY = 'community',//news within the community
    STAYS = 'stays',//highlight of some exceptional stays
}

/** The root collection for the news data in the DB */
export const NEWS_COLLECTION = "news";

/** The basic info of an author of a story */
export type NewsAuthor = {
    id: string;
    display_name: string;
    avatar_url?: string;
}

/** The category field name we can filter from */
export const NEWS_CATEGORY_FIELD = "category";

/** The author field we can filter from */
export const NEWS_AUTHOR_ID_FIELD = "author.id";

/** The tags field we can filter from */
export const NEWS_TAGS_FIELD = "tags";

/** The timestamp field of when the story was created, we can order with */
export const NEWS_TIMESTAMP_CREATED_AT_FIELD = "created_at";

/** The timestamp field of when the story was updated at, can order with */
export const NEWS_TIMESTAMP_UPDATED_AT_FIELD = "updated_at";

/** The timestamp field of when the story was published at (can be future date), we can order with */
export const NEWS_TIMESTAMP_PUBLISHED_AT_FIELD = "published_at";

export type NewsStory = {
    /** The title of the story */
    title: string;
    /** The subtitle of the story (of short description). Will be used for preview in shareable links */
    subtitle: string;
    /** The content of the story (summary, rest should be in a markdown file in storage) */
    content: string;
    /** The main image url of the story (to show in the list of stories) */
    image: string;
    /** The author of the story */
    author: NewsAuthor;
    /** The timestamp when the story was created */
    [NEWS_TIMESTAMP_CREATED_AT_FIELD]: Timestamp;
    /** The timestamp when the story was updated */
    [NEWS_TIMESTAMP_UPDATED_AT_FIELD]: Timestamp;
    /** The timestamp when the story was published */
    [NEWS_TIMESTAMP_PUBLISHED_AT_FIELD]: Timestamp;
    /** The category the story is under */
    [NEWS_CATEGORY_FIELD]: ENewsCategories;
    /** The tags linked to the story */
    [NEWS_TAGS_FIELD]?: string[];
}

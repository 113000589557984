import { functions } from "../../services/firebase";
import { UserAddress } from "../../types/db";
import { SimplifiedAddress } from "../address";

/** The default shop ID -> CoHostOp shop */
export const DEFAULT_SHOP_ID = '6yx9NSvN0oxgTA7ZGXwK';//'TWXwLp1vt55La2hUwOBm';
/** The default donation link -> CoHostOp donation link */
export const DONATION_LINK = 'https://donate.stripe.com/test_4gw4ird8I3ez5Uc6oo';


/** Fetch the tax rate for a certain address */
export const fetchTaxRate = (address: SimplifiedAddress) =>
    functions.httpsCallable("getTaxRates")({
        recipient: address,
    });


export const fetchShippingRate = (shopId: string, address: SimplifiedAddress, items?: { variant_id: string, quantity: number }[]) =>
    functions.httpsCallable("getShippingRates")({
        recipient: address,
        items,
        shopID: shopId,
    });

export const fetchPayCheckoutUrl = (shopId: string, address: UserAddress) =>
    functions.httpsCallable("getCheckoutSession")({
        shopID: shopId,
        shipping_address: address,
    });

export const fetchSessionIdInfo = (sessionId: string) =>
    functions.httpsCallable("getCheckoutSessionSuccess")({
        sessionID: sessionId,
    });
import React from 'react';
import { useQuery } from 'react-query';
import { HelpTopics } from '../../../components/help';
import { getAudienceRootTopics } from '../../../services/help';
import { EAudience } from "../../../types/db/help";

export interface IHelpTopicsContainerProps {
    audience: EAudience;
    onTopicClicked: (topicId: string) => void;
}

/** Container for all topic under a certain category/audience */
export const HelpTopicsContainer = ({
    audience,
    onTopicClicked,
}: IHelpTopicsContainerProps) => {
    const { data, isLoading, isError } = useQuery(
        ["topic", audience],
        () => getAudienceRootTopics(audience, true),
        { refetchOnWindowFocus: false });

    if (isLoading) {
        return <HelpTopics loading />
    }

    if (isError || !data) {
        return <HelpTopics error />
    }

    return <HelpTopics
        items={data.map(topic => (
            {
                title: topic.title,
                items: !!topic.sub_topics ?
                    topic.sub_topics.map(st =>
                    ({
                        title: st.title,
                        onClick: () => onTopicClicked(st.topic_id),
                    }))
                    : [],
            }
        ))}
    />;
}


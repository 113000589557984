import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LOGO_BLACK } from '../../../utilities/assets/assetHelper';
import { styles } from '../ChoAppBar';

/** The most simple app bar (for features like booking, creation) */
export const SimpleAppBar = () => {
    const { t } = useTranslation();
    return (
        <AppBar position="static" color={'transparent'}>
            <Container maxWidth={false}>
                <Toolbar disableGutters >
                    <Box>
                        <Link to={"/"}>
                            <img
                                style={styles.logoIcon as React.CSSProperties}
                                src={LOGO_BLACK}
                                alt="CoHostOp logo"
                            />
                        </Link>
                    </Box>
                    <Typography
                        variant="h4"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            ...styles.logoText,
                        }}
                    >
                        {t('appName')}
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

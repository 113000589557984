import React from 'react';
import { Container, Skeleton, Stack, Typography } from "@mui/material";
import MuiMarkdown from "mui-markdown";
import { PropsWithErrorState, PropsWithLoadingState } from '../../utilities';
import { useTranslation } from 'react-i18next';

interface ITermsPoliciesScreen {
    title: string;
    mdContent: string;
}

export const TermsPoliciesScreen = ({ title, mdContent, error, loading }: PropsWithErrorState<PropsWithLoadingState<ITermsPoliciesScreen>>) => {
    const { t } = useTranslation();

    return (
        <Container sx={{ p: 2 }}>
            <Stack direction={'column'} m={2}>
                <Typography variant='h1' autoFocus={true}>{title}</Typography>
                {
                    !error ?
                        !loading ?
                            <MuiMarkdown>{mdContent}</MuiMarkdown>
                            :
                            <Skeleton variant={'rectangular'} height={'100vh'} />
                        : <Typography>{(typeof error !== 'boolean' && error.message) || t('error.generic')}</Typography>
                }
            </Stack>
        </Container>
    )
}
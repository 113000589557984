import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Stack } from '@mui/material';
import { Outlet, useNavigate, useParams } from 'react-router';
import { UserContext } from '../../../context/UserInformation';
import { ROUTE_PARAMS, ROUTE_PATH } from '../../../utilities/navigation';
import { UserSelectRoleContainer } from '../UserDashboardPage';
import { ChatInbox } from '../../../components/chat/ChatInbox';
import { IssueStateView } from '../../../components/IssueStateView';
import { ChatInboxContext, ChatInboxContextProvider } from '../../../context/chat';
import { UserChannel, USER_CHANNEL_UNSEEN_COUNT } from '../../../types/db/chat';
import { ChatInboxItem } from '../../../components/chat/ChatInboxItem';

export const UserInboxPage = () => {
    const navigate = useNavigate();
    const { [ROUTE_PARAMS.threadID]: threadID } = useParams();

    const navigateToThread = (threadId: string) => {
        navigate(ROUTE_PATH.inboxBuild(threadId))
    }

    if (!threadID) {
        return <UserInboxContainer
            navigateToThread={navigateToThread}
        />
    }

    return <Stack direction={'row'}>
        {/* Don't show side channels list for small screen */}
        <Box flex={2} display={{ xs: 'none', md: 'block' }}>
            <UserInboxContainer
                selectedThreadId={threadID}
                navigateToThread={navigateToThread}
            />
        </Box>
        <Divider flexItem orientation='vertical' />
        <Box flex={5}>
            <Outlet />
        </Box>
    </Stack>
}

interface IUserInboxContainerProps {
    selectedThreadId?: string;
    navigateToThread: (threadId: string) => void;
}
const UserInboxContainer = (props: IUserInboxContainerProps) => {
    const userContext = useContext(UserContext);

    if (!userContext) {
        throw new Error("Need UserContext");
    }

    const userId = userContext.user?.firebaseUser?.uid;
    if (!userId) {
        console.warn('No user id');
        return null;
    }
    const userRole = userContext.user?.role;
    if (userRole === true) {
        //role still loading
        return null;
    }

    if (!userRole) {
        return <UserSelectRoleContainer />
    }

    return (
        <ChatInboxContextProvider userId={userId}>
            <InboxContainer userId={userId} {...props} />
        </ChatInboxContextProvider>
    )
}

interface IInboxContainerProps extends IUserInboxContainerProps {
    userId: string;
}
const InboxContainer = ({ userId, navigateToThread, selectedThreadId }: IInboxContainerProps) => {
    const { t } = useTranslation();
    const chatInboxContext = useContext(ChatInboxContext);

    if (!chatInboxContext) {
        throw new Error("Need ChatInboxContext")
    }
    const { channels, isLoading } = chatInboxContext;

    const renderItem = (channel: (UserChannel & { id: string })) => {
        return <ChatInboxItem
            {...{
                key: channel.id + channel.about.lastMessage?.id,
                isSelected: channel.id === selectedThreadId,
                title: channel.about.lastMessage?.sender || '',
                description: channel.about.lastMessage?.message || "",
                unreadCount: channel[USER_CHANNEL_UNSEEN_COUNT] || 0,
                hasDraft: !!channel.draft,
                image: { src: "" },
                onClick: () => navigateToThread(channel.id)
            }}
        />
    }

    if (isLoading) {
        return <ChatInbox loading={true} />
    }

    if (!channels.length) {
        return <IssueStateView
            title={t('user.inbox.emptyTitle')}
            description={t('user.inbox.emptyContent')}
        // button={{
        //     text: 'Join the community chat!!:p',
        //     onActionClick: () => createChannel(userId)
        // }}
        />
    }

    return (
        <>
            <ChatInbox
                items={channels}
                renderItem={renderItem}
            />
            {/* <Button onClick={() => { joinChannel(userId, '-NoovVbukFyfbCviu_Ne') }}>Join the other side</Button> */}
        </>
    );
}
import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH, buildPath } from '../../utilities/navigation';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import HelpIcon from '@mui/icons-material/Help';
import StoreIcon from '@mui/icons-material/Store';
import { useRemoteConfig } from '../../services/remoteConfig';
import { RC_DONATION, RC_HELP, RC_SHOP, RC_SUPPORT } from '../../types/remote_config';
import { IssueStateView } from '../../components/IssueStateView';
import { WEBSITE_HELP_CENTER_URL, WEBSITE_SHOP_URL } from '../../types/constants';

export const SupportUsPage = () => {
    const navigate = useNavigate();
    const {
        [RC_SUPPORT]: remoteConfigSupport,
        [RC_SHOP]: remoteConfigShop,
        [RC_DONATION]: remoteConfigDonation,
        [RC_HELP]: remoteConfigHelp,
    } = useRemoteConfig();

    if (!remoteConfigSupport.enabled) {
        return <IssueStateView
            title='Support'
            description='This page is not accessible at this time. Check later or contact us directly for support.'
            button={{
                text: 'Contact us',
                onActionClick: () => navigate(buildPath(ROUTE_PATH.about)),
            }}
        />;
    }

    const renderCell = ({ title, ctaIcon, ctaLabel, ctaOnClick }: { title: string, ctaIcon: React.ReactNode, ctaLabel: string, ctaOnClick: Function }) => {
        return (
            <Card variant='outlined' sx={{ height: '100%' }}>
                <CardContent>
                    <Typography>{title}</Typography>
                </CardContent>
                <CardActions>
                    <Button variant={'outlined'}
                        title={ctaLabel}
                        startIcon={ctaIcon}
                        onClick={() => ctaOnClick()}>
                        {ctaLabel}
                    </Button>
                </CardActions>
            </Card>
        )
    }

    const renderThanksCell = ({ title, description, imageSrc, ctaOnClick }: { title: string, description: string, imageSrc: string, ctaOnClick?: Function }) => {
        return (
            <Card variant='outlined' sx={{ height: '100%', px: 1 }}>
                <CardMedia
                    component="img"
                    style={{ objectFit: 'contain' }}
                    height="240"
                    image={imageSrc}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
                {ctaOnClick &&
                    <CardActions>
                        <Button variant={'text'}
                            size={'small'}
                            title={'learn more'}
                            onClick={() => ctaOnClick()}>
                            {'learn more'}
                        </Button>
                    </CardActions>
                }
            </Card>
        )
    }

    return <Grid container spacing={4} padding={2} justifyContent={'center'} alignItems={'stretch'}>
        <Grid item xs={12}>
            <Typography variant={'h1'}>{remoteConfigSupport.title}</Typography>
            <Typography variant={'subtitle1'}>{remoteConfigSupport.subtitle}</Typography>
        </Grid>
        {
            remoteConfigShop.enabled &&
            <Grid item xs={12} sm={6} md={4}>
                {
                    renderCell({
                        title: 'Share the love by offering friends and family some swags! Or get new goodies for yourself.',
                        ctaLabel: 'Shop',
                        ctaIcon: <StoreIcon />,
                        ctaOnClick: () => window.location.assign(WEBSITE_SHOP_URL)
                    })
                }
            </Grid>
        }
        {
            remoteConfigDonation.enabled &&
            <Grid item xs={12} sm={6} md={4}>
                {
                    renderCell({
                        title: remoteConfigDonation.text,
                        ctaLabel: remoteConfigDonation.label,
                        ctaIcon: <VolunteerActivismIcon />,
                        ctaOnClick: () => window.open(remoteConfigDonation.link, '_blank')
                    })
                }
            </Grid>
        }
        {
            remoteConfigHelp.enabled &&
            <Grid item xs={12} sm={12} md={4}>
                {
                    renderCell({
                        title: 'Actually looking for the help page?',
                        ctaLabel: 'Help',
                        ctaIcon: <HelpIcon />,
                        ctaOnClick: () => window.location.assign(WEBSITE_HELP_CENTER_URL),
                    })
                }
            </Grid>
        }
        <Grid item xs={12}>
            <Typography variant={'body1'}>{remoteConfigSupport.description}</Typography>
        </Grid>
        <Grid item container xs={12} spacing={1}>
            <Typography variant={'h2'}>Special Thanks!</Typography>
            <Typography variant='subtitle2'>We wanted to list a special thank you to those who have been supporting us financially and by giving some of their time to help us so far (We will try to keep this updated as much as possible!)</Typography>
            <Grid container item xs={12} spacing={4} justifyContent={'center'} alignItems={'stretch'}>
                {
                    remoteConfigSupport.thanks.map((item, index) =>
                        <Grid item xs={12} sm={6} md={3} lg={2} key={index}>
                            {renderThanksCell({
                                title: item.title,
                                description: item.description,
                                imageSrc: item.image,
                                ctaOnClick: () => window.open(item.link, '_blank'),
                            })}
                        </Grid>
                    )
                }
            </Grid>
            {
                !!remoteConfigSupport.affiliates?.length &&
                <>
                    <Typography variant={'h4'} marginTop={3}>Our partners and affiliate programs</Typography>
                    <Grid container item xs={12} spacing={4} justifyContent={'center'} alignItems={'stretch'}>
                        {
                            remoteConfigSupport.affiliates.map((item, index) =>
                                <Grid item xs={12} sm={6} md={3} lg={2} key={index}>
                                    {renderThanksCell({
                                        title: item.title,
                                        description: item.description,
                                        imageSrc: item.image,
                                        ctaOnClick: () => window.open(item.link, '_blank'),
                                    })}
                                </Grid>
                            )
                        }
                    </Grid>
                </>
            }
        </Grid>
    </Grid>
}
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getArticleContent } from '../../services/help';
import { TermsPoliciesScreen } from './TermsPoliciesScreen';

const TERMS_ARTICLE_ID = "terms_2024";

const POLICY_ARTICLE_ID = "policy_2024";

interface ITermsPoliciesContainerProps {
    /** Type of terms/policies */
    type: 'terms' | 'policies';
    /** Callback to indicate parent the content has finished to load (useful to scroll hash) */
    onContentLoaded?: () => void;
}

export const TermsPoliciesContainer = ({ type, onContentLoaded }: ITermsPoliciesContainerProps) => {
    const { t, i18n } = useTranslation();

    const title = type === 'terms' ? t('footer.termAndConditions') : t('footer.privacyPolicy')
    const articleId = type === 'terms' ? TERMS_ARTICLE_ID : POLICY_ARTICLE_ID;

    const { data, isLoading, isError } = useQuery(
        ["article", articleId, i18n.language],
        () => getArticleContent(articleId, i18n.language),
        { refetchOnWindowFocus: false, onSettled: () => onContentLoaded?.() }
    );

    if (isLoading) {
        return <TermsPoliciesScreen
            title={title}
            loading
        />
    }

    if (isError) {
        <TermsPoliciesScreen
            title={title}
            error={{ message: t('error.generic') }}
        />
    }

    return <TermsPoliciesScreen
        title={title}
        mdContent={data || ''}
    />
}

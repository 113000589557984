import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { ListingBookingSection } from "../../../components/listing/ListingBookingSection";
import { IListingBreadcrumbsProps } from "../../../components/listing/ListingBreadcrumbs/ListingBreadcrumbs";
import { ListingCalendarView } from "../../../components/listing/ListingCalendarView";
import { ListingDetails } from "../../../components/listing/ListingDetails";
import { ListingLocation } from "../../../components/listing/ListingLocation";
import { ListingPictures } from "../../../components/listing/ListingPictures";
import { ListingReviewSection } from "../../../components/listing/ListingReviewSection";
import { ListingSubListingSection } from "../../../components/listing/ListingSubListingSection";
import { UserContext } from "../../../context/UserInformation";
import { TListingFilters } from "../../../pages/listings/view/ListingDetailsPage/ListingDetailsPage";
import { getListingInfo } from "../../../services/listing";
import { getListingIconFromType } from "../../../utilities/listings";
import { ROUTE_ANCHOR } from "../../../utilities/navigation";
import { ListingPhotosContainer } from "../ListingPhotosContainer";

export interface IListingDetailsContainerProps {
    /** The listing id to fetch and display */
    listingId: string;
    /** Callback click on show more pictures */
    onShowMorePicturesClick: () => void;
    /** Callback click on show more reviews */
    onShowMoreReviewsClick: () => void;
    /** Callback click on the breadcrumbs or the sub-listing (can happen for reviews too) */
    onListingClick: (listingId: string) => void;
    /** Callback click on the host or cohost avatar to navigate to user profile (can happen for reviews too) */
    onUserClick: (userId: string) => void;
    /** Callback on book click */
    onBookClick: () => void;
    /** The filters if coming from the search listings page */
    filters: Partial<TListingFilters>;
    /** Callback if the filters changed within the listing browsing */
    onFilterChanged: (newFilters: Partial<TListingFilters>) => void;
    /** Navigate to a specific anchor (hash). If anchor not provided, just scroll to existing */
    onAnchorClick: (anchor?: keyof typeof ROUTE_ANCHOR.listing) => void;
}

export const ListingDetailsContainer = ({
    listingId,
    onShowMorePicturesClick,
    onShowMoreReviewsClick,
    onListingClick,
    onUserClick,
    onBookClick,
    filters,
    onFilterChanged,
    onAnchorClick
}: IListingDetailsContainerProps) => {
    const userContext = useContext(UserContext);
    const { data, isLoading, isError } = useQuery(
        ['ListingDetails', listingId],
        () => getListingInfo(listingId),
        { refetchOnWindowFocus: false });

    useEffect(() => {
        if (!isLoading) {
            onAnchorClick();//make sure it scroll to the right anchor after all is loaded
        }
    }, [isLoading, onAnchorClick])

    if (isLoading) {
        return <ListingDetails loading />
    }

    if (isError || !data) {
        return <ListingDetails error />
    }
    // console.log('data', data);
    const isCurrentUserOwner = !!data.host?.id
        && !!userContext?.user?.firebaseUser?.uid
        && data.host?.id === userContext?.user?.firebaseUser?.uid;

    if (data.status !== 'published') {
        if (!isCurrentUserOwner) {
            return <ListingDetails error={{ message: "" }} />
        }
    }

    const getBreadcrumbInfo = (): IListingBreadcrumbsProps => {
        return {
            listingBreadcrumbs: [
                ...(data.breadcrumbs?.map(b => (
                    {
                        label: b.title,
                        icon: () => getListingIconFromType(b.type),
                        onClick: () => onListingClick(b.id),
                    }
                )) ?? [])
                ,
                {
                    icon: () => getListingIconFromType(data.type),
                    label: data.title,
                }
            ]
        }
    }

    const handleGetDirectionClick = () => {
        window.open(`https://www.google.com/maps/@${data.location.lat},${data.location.lng},15z`, '_blank')
    }

    return <ListingDetails
        title={data.title}
        description={data.description}
        breadcrumbInfo={getBreadcrumbInfo()}
        // onEditClick={isCurrentUserOwner ? () => { } : undefined}
        // onShareClick={() => onAnchorClick(ROUTE_ANCHOR.listing.calendar)}
        // onSaveClick={() => onAnchorClick(ROUTE_ANCHOR.listing.reviews)}
        // onReportClick={() => onAnchorClick(ROUTE_ANCHOR.listing.location)}
        picturesSection={() =>
            <ListingPhotosContainer
                listingId={listingId}
                listingRef={data.ref}>
                {
                    (data) => {
                        if (data.loading) {
                            return <ListingPictures loading />
                        }
                        return <ListingPictures
                            totImages={data.totImages}
                            images={data.images.map(img => img.url)}
                            onShowMoreClick={onShowMorePicturesClick} />
                    }
                }
            </ListingPhotosContainer>
        }
        bookingSection={() =>
            data.pricing.type !== 'non-bookable' ?
                <ListingBookingSection
                    pricePerNight={data.pricing.nightly_price.amount.toFixed(2)}
                    dates={{
                        startDate: filters.check_in,
                        endDate: filters.check_out,
                        maxNights: !!data.calendar.max_night ? data.calendar.max_night : undefined,
                        onDateRangeSelected: (start, end) => onFilterChanged({ check_in: start, check_out: end })
                    }}
                    guests={{
                        adults: {
                            value: filters.adults_number || 0,
                            onChange: (newValue) => onFilterChanged({ adults_number: newValue }),
                        }
                    }}
                    onBookClick={
                        !!filters.check_in && !!filters.check_out && !!filters.adults_number ?
                            onBookClick
                            : undefined} />
                : <></>
        }
        calendarSection={() =>
            <ListingCalendarView
                selectedDates={!!filters.check_in && filters.check_out ? {
                    startDate: filters.check_in,
                    endDate: filters.check_out,
                } : undefined}
            />
        }
        reviewsSection={() =>
            <ListingReviewSection />
        }
        subListingsSection={() =>
            <ListingSubListingSection items={data.sub_listings?.map(sl => ({
                title: sl.title,
                description: sl.description,
                photoUrl: sl.main_photo?.url ?? '',
                onClick: () => onListingClick(sl.id)
            })) ?? []} />}
        host={{
            displayName: data.host?.display_name || '',
            avatarUrl: data.host?.avatar_url || ',',
        }}
        cohost={data.cohost ? {
            displayName: data.cohost.display_name,
            avatarUrl: data.cohost.avatar_url
        } : undefined}
        location={{
            label:
                [data.location?.address?.city,
                data.location?.address?.state_name,
                data.location?.address?.country_name]
                    .filter(val => !!val)
                    .join(', '),
            onClick: () => { }
        }}
        locationSection={() =>
            <ListingLocation location={{ lat: data.location.lat, lng: data.location.lng }}
                onDirectionClick={data.location && handleGetDirectionClick} />
        }
    />;
}
import React from 'react';
import { Outlet } from 'react-router';
import { Search } from '@mui/icons-material';
import { Container, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { HelpTopArticlesContainer } from '../../containers/help/HelpTopArticlesContainer';
import { useHelpCenterNavigation } from '../../hooks/useHelpCenterNavigation';

export const HelpPage = () => {
    return <Outlet />;
}

/** The initial page to show in the index of the help center */
export const HelpInitPage = () => {
    const { navigateToAllTopics, navigateToArticle } = useHelpCenterNavigation();

    return (
        <Container sx={{ p: 2 }}>
            <Stack direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}>
                <Typography variant='h1'>Hi, how can we help?</Typography>
                <TextField variant='outlined'
                    placeholder='Enter keywords'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search />
                            </InputAdornment>
                        ),
                    }} />
                <HelpTopArticlesContainer
                    onShowTopicsClick={navigateToAllTopics}
                    onArticleClick={navigateToArticle}
                />
            </Stack>
        </Container>
    )
}
import { EForumTopic } from "../forums";

export type TAudience = "host" | "cohost" | "guest";
export type TCategory = "farm" | "multi-room" | "multi-suite" | "multi-site" | "private-room" | 'shared-room' | 'suite' | 'site';

export const ROUTE_PARAMS = {
  userID: "userId",
  listingID: "listingId",
  articleID: "articleId",//for help center article
  topicID: "topicId",//for help center topic
  shopID: "shopId",//for shop id
  productID: "productId",//for shop product
  flowStepID: "flowStepId",//For new listing flow
  settingsTabID: "settingsTabId",//for the settings tab selected (for listing edit/management)
  accountPage: "accountPage",//For the user account page
  jobID: "positionId",//for career subdomain
  postID: "postId",//for forum subdomain
  storyID: "storyId",//for the news article
  threadID: "threadId",//for the chat threadId
};

export const ROUTE_QUERY = {
  search: "search",
  query: "query",
  page: "page",
  forgot_password: "forgotPW",
  category: "category",
  edit_profile: "edit_profile",
  audience: "audience",
  variantID: "variantID",
  checkout_session_id: "session_id",
  from: "from",
  topic: "topic",
  sortBy: "sort_by",
  type: "type",
  tag: "tag",
  author: "author",
  listings_search: {
    check_in_date: "check_in",
    check_out_date: "check_out",
    location: "location",
    adults_number: "adults_number",
    order_by: "order_by",
    room_number: "room_number",
    children_number: "children_number",
    infants_number: "infants_number",
    pets_number: "pets_number",
    currency: "currency",
    locale: "locale",
    bounds: "bounds",
  }
} as const;

export const ROUTE_PATH = {
  home: "/",
  //Common
  book: "book", //event/listing booking
  search: "search",
  //terms and policies
  terms: "terms-and-conditions",
  policies: "privacy-policy",
  //About
  about: "about",
  faq: "faq" as "faq",
  finance_report: "finance-report",
  aboutBuild: (path: "faq" | "finance-report"): string =>
    buildPath([ROUTE_PATH.about, path]),
  //testimonials
  testimonials: 'testimonials',
  buildTestimonials: (audience: TAudience, category: TCategory): string =>
    buildPath([ROUTE_PATH.testimonials],
      true,
      [
        { query: ROUTE_QUERY.audience, value: audience },
        { query: ROUTE_QUERY.category, value: category },
      ]),
  //USERS Auth
  auth_join: "join" as "join",
  auth_login: "login" as "login",
  auth_link: "link" as "link",
  authBuild: (authType: "join" | "login" | "link") =>
    buildPath([ROUTE_PATH.users, authType]),
  //USERS PROFILE
  users: "users",
  userBuild: (userID: string) =>
    buildPath([ROUTE_PATH.users, userID]),
  //USER'S ACCOUNT & SETTINGS
  account: "account",
  accountBuild: (subpath?: string) =>
    buildPath(!subpath ? [ROUTE_PATH.account] : [ROUTE_PATH.account, subpath]),
  //USER'S DASHBOARD
  dashboard: "dashboard",
  dashboardBuild: (path: "inbox" | "listings" | "calendar" | "events") =>
    buildPath([ROUTE_PATH.dashboard, path]),
  inbox: "inbox" as "inbox",
  inboxBuild: (threadId?: string, withDetails?: boolean): string =>
    !threadId ?
      buildPath([ROUTE_PATH.dashboard, ROUTE_PATH.inbox])
      : !withDetails ?
        buildPath([ROUTE_PATH.dashboard, ROUTE_PATH.inbox, threadId])
        : buildPath([ROUTE_PATH.dashboard, ROUTE_PATH.inbox, threadId, ROUTE_SUBPATH.details]),
  bookings: "bookings",//User's bookings
  //LISTINGS
  listings: "listings" as "listings",
  listingBuild: (listingId: string, preview?: boolean): string =>
    buildPath([ROUTE_PATH.listings, listingId], true, !!preview ? [{ query: 'preview', value: 'true' }] : []),
  listingPhotosBuild: (listingId: string, photoId?: string): string =>
    buildPath([ROUTE_PATH.listings, listingId, ROUTE_SUBPATH.photos], true, !!photoId ? [{ query: 'photoId', value: photoId }] : []),
  listingReviewsBuild: (listingId: string, reviewId?: string): string =>
    buildPath([ROUTE_PATH.listings, listingId, ROUTE_SUBPATH.reviews], true, !!reviewId ? [{ query: 'reviewId', value: reviewId }] : []),
  listingNewBuild: (parentId?: string): string =>
    buildPath([ROUTE_PATH.listings, ROUTE_SUBPATH.new], true, !!parentId ? [{ query: 'parentId', value: parentId }] : []),
  listingPublishedBuild: (listingId: string) =>
    buildPath([ROUTE_PATH.listings, ROUTE_SUBPATH.publish_success, listingId]),
  listingBookBuild: (listingId: string) =>
    buildPath([ROUTE_PATH.book, ROUTE_PATH.listings, listingId]),
  listingSettingsBuild: (listingId: string, step?: string) =>
    buildPath([ROUTE_PATH.dashboard, ROUTE_PATH.listings, listingId, step || "details"]),
  //EVENTS
  events: "events",
  support: "support",
  sitemap: "sitemap",
  //subdomain shop (possibility in the future to have users own shop on it too)
  shop: {
    shop: "shop",
    shopBuild: (shopId?: string) =>
      buildPath((!!shopId ? [ROUTE_PATH.shop.shop, shopId] : [])),
    shopProductBuild: (productID: string, shopId?: string) =>
      buildPath(!shopId ?
        [ROUTE_SUBPATH.products, productID]
        : [ROUTE_PATH.shop.shop, shopId, ROUTE_SUBPATH.products, productID]),
    shopCartBuild: (shopId?: string) =>
      buildPath(!shopId ?
        ROUTE_SUBPATH.cart
        : [ROUTE_PATH.shop.shop, shopId, ROUTE_SUBPATH.cart]),
    shopCheckoutBuild: (shopId?: string) =>
      buildPath(!shopId ?
        ROUTE_SUBPATH.checkout
        : [ROUTE_PATH.shop.shop, shopId, ROUTE_SUBPATH.checkout]),
  },
  //subdomain careers
  careers: {
    faq: "faq",
    members: "members",
    positions: "positions",
    positionBuild: (positionId?: string) =>
      buildPath(!positionId ? ROUTE_PATH.careers.positions : [ROUTE_PATH.careers.positions, positionId]),
    university: "university"
  },
  //subdomain help center
  help: {
    topics: "topics",
    topicBuild: (topicId?: string) =>
      buildPath(!topicId ?
        ROUTE_PATH.help.topics
        : [ROUTE_PATH.help.topics, topicId]),
    articles: "articles",
    articleBuild: (articleId: string) =>
      buildPath([ROUTE_PATH.help.articles, articleId]),
  },
  //subdomain forums
  forums: {
    community: EForumTopic.COMMUNITY.toString(),
    host: EForumTopic.HOST.toString(),
    cohost: EForumTopic.COHOST.toString(),
    guest: EForumTopic.GUEST.toString(),
    guideline: "guidelines",
    newPost: "new",
    posts: "posts",
    postBuild: (postId: string) => buildPath([ROUTE_PATH.forums.posts, postId]),
  },
  //subdomain news
  news: {
    about: 'about-us',
    mediaAsset: 'media-asset',
    productRelease: 'product-release',
    contact: 'contact',
    stories: "stories",
    storyBuild: (storyId: string) => buildPath([ROUTE_PATH.news.stories, storyId]),
    aboutBuild: (leaderId: string) => buildPath([ROUTE_PATH.news.about, "leadership", leaderId]),
    categoryStoryBuild: (categoryName: string) => buildPath([ROUTE_SUBPATH.category, categoryName]),
    tagStoryBuild: (tagName: string) => buildPath([ROUTE_SUBPATH.tag, tagName]),
    categoryMediaBuild: (categoryName: string) => buildPath([ROUTE_PATH.news.mediaAsset, ROUTE_SUBPATH.category, categoryName])
  }
} as const;

export const ROUTE_SUBPATH = {
  user_host: "host",
  user_cohost: "cohost",
  user_guest: "guest",
  /** Generic subpath reused in listings/shop/user/... */
  details: "details",
  photos: "photos",
  reviews: "reviews",
  products: "products",
  cart: "cart",
  checkout: "checkout",
  checkout_success: "checkout-success",
  checkout_cancel: "checkout-cancel",
  //*** creation flow other than subpath already existing ***
  type: 'type',
  location: 'location',
  basic: 'basic',
  // photos:'photos',//already in generic
  // details: "details",//already in generic
  pricing: "pricing",
  calendar: "calendar",
  publish: "publish",
  publish_success: "publish-success",
  //*** edit flow other than subpath already existing***
  guests: "guests",
  cohosts: "cohosts",
  // details: "details",//already in creation flow
  // pricing: "pricing",//already in creation flow
  // calendar: "calendar",//already in creation flow

  //generic for listing and events
  new: "new",
  //USER'S ACCOUNT
  account_perso: "personal-info",
  account_login: "login-and-security",
  account_finance: "payment-and-payout",
  account_notifs: "notifications",
  account_privacy: "privacy",
  account_prefs: "preferences",
  account_referrals: "referrals",
  isValidAccountSubpath: (subpath: string) =>
    subpath === ''//root account path
    || ([
      ROUTE_SUBPATH.account_perso,
      ROUTE_SUBPATH.account_login,
      ROUTE_SUBPATH.account_finance,
      ROUTE_SUBPATH.account_notifs,
      ROUTE_SUBPATH.account_privacy,
      ROUTE_SUBPATH.account_prefs,
      ROUTE_SUBPATH.account_referrals,
    ] as string[]).includes(subpath),
  //NEWS
  category: "category",
  tag: "tag",
  //HELP CENTER
  articles: "articles",
  topics: "topics"
} as const;

export const buildPath = (
  paths: string | string[],
  fromRoot = true,
  queries?: { query: string; value: string }[],
): string => {
  if (!!queries?.length) {
    return (
      buildPath(paths, fromRoot) +
      "?" +
      queries.map(({ query, value }) => `${query}=${value}`).join("&")
    );
  }
  const path = (Array.isArray(paths) ? paths.join("/") : paths);
  return ((fromRoot && !path.startsWith("/")) ? "/" : "") + path;
};

/** The list of Anchors (tags/id) used in the app (useful for scroll to section in page) */
export const ROUTE_ANCHOR = {
  listing: {
    calendar: 'calendar',
    reviews: 'reviews',
    location: 'location'
  }
} as const;
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { HelpArticle } from "../../../components/help";
import { HelpPage } from "../../../components/help/HelpPage";
import { getArticleInfo } from "../../../services/help";
import { ROUTE_PATH } from "../../../utilities/navigation";


export interface IHelpArticleContainerProps {
    /** The article id we want to fetch */
    articleId: string;
    /** Callback click on one sub topic or related topic */
    onTopicClick: (topicId?: string) => void;
    /** Callback click on one related article */
    onArticleClick: (articleId: string) => void;
}

export const HelpArticleContainer = ({
    articleId,
    onTopicClick,
    onArticleClick,
}: IHelpArticleContainerProps) => {
    const { i18n } = useTranslation();
    const { data, isLoading, isError } = useQuery(
        ["article", articleId, i18n.language],
        () => getArticleInfo(articleId, i18n.language),
        { refetchOnWindowFocus: false }
    );

    if (isLoading) {
        return <HelpPage loading><HelpArticle loading /></HelpPage>
    }

    if (isError || !data) {
        return <HelpArticle error={{ message: "", onRetryClick: () => onTopicClick('') }} />
    }

    const handleFeedback = (rate: number) => {

    }

    return (
        <HelpPage breadcrumbs={data.breadcrumbs?.map(b => ({
            label: b.title,
            onClick: () => onTopicClick(b.topic_id)
        }))
            ?? []}
            title={data.title}
            onHomeClick={() => window.location.assign(ROUTE_PATH.home)}
            onAllTopicClick={() => onTopicClick()}>
            <HelpArticle
                title={data.title}
                description={data.description}
                mdContent={data.content}
                relatedArticles={
                    data.related_articles?.map(a => ({
                        title: a.title,
                        description: a.description,
                        onClick: () => onArticleClick(a.article_id)
                    })) ?? []
                }
                onPositiveFeedbackClicked={() => handleFeedback(+1)}
                onNegativeFeedbackClicked={() => handleFeedback(-1)}
            />
        </HelpPage>
    );
}

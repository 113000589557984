import firebase from "firebase/app";
import { db } from "../firebase";
import { CareersJob, CAREERS_COLLECTION, WithID } from "../../types/db";
import { applyVariablesToQuery, QueryVariables } from "../db";

export const getCareersCollectionRef = () => {
    return db.collection(CAREERS_COLLECTION);
}

/** Get a list of job posts */
export const getCareerPosts = async (variables?: QueryVariables) => {
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> = getCareersCollectionRef();
    query = applyVariablesToQuery(query, variables);

    const data = await query.get();

    return data.docs.map(doc => ({
        ...doc,//full doc for pagination
        ...(doc.data() as CareersJob),
        id: doc.id,//id to redirect to details
    })) as WithID<CareersJob, string>[];
}

/** Get the basic info of a job post */
export const getCareerDetails = async (jobId: string) => {
    const data = await getCareersCollectionRef()
        .doc(jobId)
        .get();

    return data.data() as CareersJob;
}

//todo remove (this is temporary to fill up the db)
export const createJob = () => {
    const job1: CareersJob = {
        title: 'French Translator',
        description: 'Provide translation for locale copy within the website',
        domain: "media-communication",
        location: 'Paris, France',
        is_remote: true,
        is_pending_offer: true,
        is_published: false,
        key_benefits: ["Remote work"],
        key_knowledge: ["Fluent in English", "Fluent in French", "Bilingual"],
        key_responsibilities: ["Review previous translation", "Improve translation where needed", "Offer insight in improvement in the process"],
        key_skills: ["Able to explain to a non-French community the nuance of the French language", "Being able to communicate translation needs to design team and developers"],
        type: ["short-term"],
        original_language: 'en',
        salary_currency: 'EUR',
        salary_period: 'monthly',
        salary_range: [1000, 1500],
        processes: ['Video-call interview with HR', 'Team interview', 'Higher-up interview and culture review']
    }

    const job2: CareersJob = {
        title: 'Content creator for the Help Center',
        description: 'Provide helpful guide articles for user on the website',
        domain: "support",
        location: 'Vancouver, BC, Canada',
        is_remote: true,
        is_pending_offer: true,
        is_published: false,
        key_benefits: ["Remote work"],
        key_knowledge: ["Fluent in English", "Fluent in French (Bonus)", "Bilingual (Bonus)"],
        key_responsibilities: ["Review previous article", "Improve guide where needed", "Offer insight in improvement in the process"],
        key_skills: ["Able to explain to a non-French community the nuance of the French language", "Being able to communicate translation needs to translation team"],
        type: ["short-term"],
        original_language: 'en',
        salary_currency: 'CAD',
        salary_period: 'bi-weekly',
        salary_range: [1500, 2000],
        processes: ['Video-call interview with HR', 'Team interview', 'Higher-up interview and culture review']
    }

    const job3: CareersJob = {
        title: 'German Translator',
        description: 'Provide translation for locale copy within the website',
        domain: "media-communication",
        location: 'Berlin, Germany',
        is_remote: true,
        is_pending_offer: true,
        is_published: false,
        key_benefits: ["Remote work"],
        key_knowledge: ["Fluent in English", "Fluent in German", "Bilingual"],
        key_responsibilities: ["Review previous translation", "Improve translation where needed", "Offer insight in improvement in the process"],
        key_skills: ["Able to explain to a non-German community the nuance of the German language", "Being able to communicate translation needs to design team and developers"],
        type: ["short-term"],
        original_language: 'en',
        salary_currency: 'EUR',
        salary_period: 'monthly',
        salary_range: [1000, 1500],
        processes: ['Video-call interview with HR', 'Team interview', 'Higher-up interview and culture review']
    }

    const job4: CareersJob = {
        title: 'Content creator for the News',
        description: 'Keep the community up to date with news articles',
        domain: "media-communication",
        location: 'Vancouver, BC, Canada',
        is_remote: true,
        is_pending_offer: true,
        is_published: false,
        key_benefits: ["Remote work"],
        key_knowledge: ["Fluent in English", "Fluent in French (Bonus)", "Bilingual (Bonus)"],
        key_responsibilities: ["Review previous articles", "Improve guide where needed", "Offer insight in improvement in the process"],
        key_skills: ["Being able to communicate translation needs to translation team"],
        type: ["short-term"],
        original_language: 'en',
        salary_currency: 'CAD',
        salary_period: 'bi-weekly',
        salary_range: [1500, 2000],
        processes: ['Video-call interview with HR', 'Team interview', 'Higher-up interview and culture review']
    }

    return getCareersCollectionRef()
        .add(job4);
}

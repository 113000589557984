import { alpha, Box, Container } from "@mui/material"
import React, { PropsWithChildren } from "react"

/** Represent the custom background for CoHostOp */
export const ChoBackgroundScreen = ({ children }: PropsWithChildren<{}>) => {
    return (
        <Container sx={{
            height: '100vh',
            p: 4,
            backgroundImage:
                'linear-gradient( 157deg, rgb(0, 0, 255) 0%, rgb(0, 255, 66) 100%)',
        }}
            maxWidth={false}>
            <Box sx={{ height: '100%', backgroundColor: alpha('#FFF', 0.5) }}>
                {children}
            </Box>
        </Container>
    )
}
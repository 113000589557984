/** The remote config for the career center */
export const RC_CAREER = "career";

export type Career = {
    /** Boolean indicating if the career center is enabled */
    enabled: boolean;
}

export const DEFAULT_CAREER: Career = {
    enabled: false
}
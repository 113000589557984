/* List all the type from the database for the chat feature */

import { EUserRole } from "./user";

/** 
 * The realtime db root name for info on all channels. Object key is the channelID.
 * @see ChatChannels type of the info
 */
export const CHAT_CHANNELS = "chatChannels";

/**
 * The realtime db name for the chat channel latest message
 * @see CHAT_CHANNELS
 * @see ChatChannelLatestMessage type of the info in it
 * @see ChatChannel type of the parent info
 */
export const CHAT_CHANNEL_LATEST_MESSAGE = "lastMessage";

/**
 * The realtime db name for the chat channel members
 * @see CHAT_CHANNELS
 * @see ChatChannel type of the parent info
 */
export const CHAT_CHANNEL_MEMBERS = "members";

/**
 * The realtime db name for the chat channel members currently typing
 * @see CHAT_CHANNELS
 * @see ChatChannel type of the parent info
 */
export const CHAT_CHANNEL_TYPING = "typing";

export type ChatChannelLatestMessage = ChatMessage & { id: string };

/**
 * The realtime db name for the chat channel about info
 * @see ChatChannel
 * @see UserChannel
 */
export const CHAT_CHANNEL_ABOUT = "about";

/** The basic information of a channel (shared between the ChatChannel and the UserChannels) */
export type ChatChannelAbout = {
    /** The last message in the channel */
    [CHAT_CHANNEL_LATEST_MESSAGE]?: ChatChannelLatestMessage;
    /** The title of the channel */
    title: string;
    /** The image of the channel (user avatar/listing picture/event photo/...) */
    image: string;
    /** The type of the discussion (still TBD how to fill those up)*/
    type: 'listing' | 'inquiry' | 'booking' | 'event';
    /** The listing ID the channel relate to */
    listing?: string;
    /** Optional, The booking ID the channel relate to */
    booking?: string;
    /** Optional, The event ID the channel relate to */
    event?: string;
    /** The dates? */
    // dates?: [Date, Date];
    /** The status of the inquiry? */
    // status?:string;
}
/** The info of a channel */
export type ChatChannel = {
    /** The members of the channel, key is the member's user ID */
    [CHAT_CHANNEL_MEMBERS]: { [key: string]: ChatChannelMember };
    /** The members currently typing, key is the member's user ID */
    [CHAT_CHANNEL_TYPING]: { [key: string]: boolean }
    /** The details related to the channel */
    [CHAT_CHANNEL_ABOUT]: ChatChannelAbout;
};

export type ChatChannelMember = { role: EUserRole }

/** The ChatChannels is an Object with key as channelID */
export type ChatChannels = {
    [key: string]: ChatChannel;
}

/** 
 * The realtime db root name for info on all channel messages. Object key is the channelID then messageID.
 * @see ChatMessages type of the info
 */
export const CHAT_MESSAGES = "chatMessages";

/** The ChatMessage is representing the data in the realtime db for a chat message */
export type ChatMessage = {
    /** The sender's userID */
    sender: string;
    /** The timestamp of the message */
    timestamp: any;//Timestamp;
    /** The string value of the message */
    message: string;
}

/** The ChatMessages is an Object with key as messageID */
export type ChatMessages = {
    [key: string]: ChatMessage;
}

/** 
 * The realtime db root name for info on all channels the user is part of. Object key is the userID.
 * @see UserChannels type of the info
 */
export const USER_CHANNELS = "userChannels";

/**
 * The realtime db name for the user channel unread/unseen count
 * @see UserChannel
 */
export const USER_CHANNEL_UNSEEN_COUNT = "unseenCount";

/**
 * The realtime db name for the user channel last update
 * @see UserChannel
 */
export const USER_CHANNEL_LAST_UPDATE = "lastUpdate";

/** The user's channel information */
export type UserChannel = {
    /** Any draft message left by the user for this channel */
    draft?: Pick<ChatMessage, 'message'>;
    /** The number of unseen message for this user on the channel */
    [USER_CHANNEL_UNSEEN_COUNT]?: any;//number | object;//number for reading object for database increment
    /** The timestamp of the last update in the channel (allow to sort by most recent) */
    [USER_CHANNEL_LAST_UPDATE]?: any;//Timestamp
    /** The basic information about the channel (to display in box list) */
    [CHAT_CHANNEL_ABOUT]: ChatChannelAbout;
    /** Boolean indicating if the user has muted any notification coming for this channel */
    muted?: boolean;
}

/** The ChatMessages is an Object with key as channelID */
export type UserChannels = {
    [key: string]: UserChannel;
}

/** 
 * The realtime db filed name for user status.
 * @see ChatUser where used
 * @see ChatUserStatus type of the info
 */
export const CHAT_USERS_STATUS = "status";

/**
 * The realtime db root name for info on all users to be used for chat. Object key is the userID.
 * Those are very basic information only to be used in the chat feature.
 * @see ChatChannel type of the info
 */
export const CHAT_USERS = "chatUsers";

export type ChatUser = {
    /** The display name of the user */
    displayName: string;
    /** The avatar url of the user */
    avatar: string;
    /** The status of the user (presence) */
    [CHAT_USERS_STATUS]: ChatUserStatus;
}

export type ChatUserStatus = {
    /**
     * The state of the user:
     * - 'new': brand new user (will be updated by the connection of the user device)
     * - 'online': the user is currently online on the platform
     * - 'offline': user user haven't been on the platform for a while and appear offline in the chat
     * - 'deleted': minimum data is kept from a deleted account to still show the user but can't redirect to profile or private message
     */
    state: 'new' | 'online' | 'offline' | 'deleted';
    /** The last time the status was changed */
    lastChanged: any;//Timestamp
}


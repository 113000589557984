import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import "firebase/analytics";
import "firebase/functions";
import "firebase/remote-config";

import { Wrapper, WrapperProps } from "@googlemaps/react-wrapper";
import { } from "@react-google-maps/api";

//PROD config
// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
//     authDomain: "cohostop.com",//process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
//     databaseURL: process.env.REACT_APP_FIREBASE_RT_DATABASE_URL!,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID!,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID!,
// }
const firebaseConfig = {
    apiKey: "AIzaSyBLiDGjj9e0htjqqKTzH7SKw16EL6JX2t4",
    // authDomain: "co-host-op.firebaseapp.com",
    authDomain: "cohostop.com",
    databaseURL: "https://co-host-op-default-rtdb.firebaseio.com",
    projectId: "co-host-op",
    storageBucket: "co-host-op.appspot.com",
    messagingSenderId: "1027493080397",
    appId: "1:1027493080397:web:82782960e8d4a674955b04",
    measurementId: "G-M248JFZJC2"
};
//DEV config
// const firebaseConfig = {
//     apiKey: "AIzaSyAcQzdlDn0XWfiEvE7lZVpqJ5VDHlV96Z0",
//     authDomain: "co-host-op-dev.firebaseapp.com",
//     databaseURL: "https://co-host-op-dev-default-rtdb.firebaseio.com",
//     projectId: "co-host-op-dev",
//     storageBucket: "co-host-op-dev.appspot.com",
//     messagingSenderId: "64466857616",
//     appId: "1:64466857616:web:44f8377afe34aba56a3215",
//     measurementId: "G-RL6B8LPH91"
// };

// Initialize services and get a reference to each service
const app = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth(app);
const db = firebase.firestore(app);
db.settings({ ignoreUndefinedProperties: true, merge: true });
const rtdb = firebase.database(app);
const storage = firebase.storage(app);
const analytics = firebase.analytics(app);
const functions = firebase.functions(app);
const remoteConfig = firebase.remoteConfig(app);
//done in the hook useRemoteConfig as well, just left here in case used outside of the hook
remoteConfig.fetchAndActivate();

/** The API key to use for all Google Map API */
export const GOOGLE_MAPS_API_KEY = firebaseConfig.apiKey;

const MapWrapper = (props: Omit<WrapperProps, 'apiKey'>) =>
    Wrapper({ apiKey: firebaseConfig.apiKey, ...props });

const reverseGeocode = async (latitude: number, longitude: number) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`;

    try {
        const response = await fetch(url);
        const { results } = await response.json() as typeof mockReverseGeocodeResponse;
        let result = '';
        var t = 0;
        const searches = ['city', 'locality', 'sublocality', 'postal_code', 'neighborhood', 'political', 'country'] as const;
        while (!result && t < searches.length) {
            const search = searches[t];
            result = results?.find(res => res.types.includes(search))?.formatted_address || '';
            t++;
        }
        return result;
    } catch (error) {
        //try another way
        const geocoder = new google.maps.Geocoder();
        return geocoder.geocode({ location: { lat: latitude, lng: longitude } })
            .then(({ results }) => {
                if (!!results.length) {
                    let result = '';
                    var t = 0;
                    const searches = ['locality', 'sublocality', 'postal_code', 'neighborhood', 'political', 'country'] as const;
                    while (!result && t < searches.length) {
                        const search = searches[t];
                        result = results?.find(res => res.types.includes(search))?.formatted_address || '';
                        t++;
                    }
                    // console.log('geocode result', result)
                    //set the input to this place
                    return result;
                }
            });
    }
};

export { db, rtdb, auth, storage, analytics, functions, remoteConfig, MapWrapper, reverseGeocode }

const mockReverseGeocodeResponse = {
    "plus_code": {
        "compound_code": "P27Q+MC New York, NY, USA",
        "global_code": "87G8P27Q+MC"
    },
    "results": [
        {
            "address_components": [
                {
                    "long_name": "279",
                    "short_name": "279",
                    "types": ["street_number"]
                },
                {
                    "long_name": "Bedford Avenue",
                    "short_name": "Bedford Ave",
                    "types": ["route"]
                },
                {
                    "long_name": "Williamsburg",
                    "short_name": "Williamsburg",
                    "types": ["neighborhood", "political"]
                },
                {
                    "long_name": "Brooklyn",
                    "short_name": "Brooklyn",
                    "types": ["political", "sublocality", "sublocality_level_1"]
                },
                {
                    "long_name": "Kings County",
                    "short_name": "Kings County",
                    "types": ["administrative_area_level_2", "political"]
                },
                {
                    "long_name": "New York",
                    "short_name": "NY",
                    "types": ["administrative_area_level_1", "political"]
                },
                {
                    "long_name": "United States",
                    "short_name": "US",
                    "types": ["country", "political"]
                },
                {
                    "long_name": "11211",
                    "short_name": "11211",
                    "types": ["postal_code"]
                }
            ],
            "formatted_address": "279 Bedford Ave, Brooklyn, NY 11211, USA",
            "geometry": {
                "location": {
                    "lat": 40.7142484,
                    "lng": -73.9614103
                },
                "location_type": "ROOFTOP",
                "viewport": {
                    "northeast": {
                        "lat": 40.71559738029149,
                        "lng": -73.9600613197085
                    },
                    "southwest": {
                        "lat": 40.71289941970849,
                        "lng": -73.96275928029151
                    }
                }
            },
            "place_id": "ChIJT2x8Q2BZwokRpBu2jUzX3dE",
            "plus_code": {
                "compound_code": "P27Q+MC Brooklyn, New York, United States",
                "global_code": "87G8P27Q+MC"
            },
            "types": [
                "bakery",
                "cafe",
                "establishment",
                "food",
                "point_of_interest",
                "store"
            ]
        },

        // ... Additional results truncated in this example[] ...

    ],
    "status": "OK"
}


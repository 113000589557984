import React, { useEffect, useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Libraries, LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from '../../services/firebase';
import { Skeleton, debounce } from '@mui/material';

interface ISearchLocationInputViewProps extends Pick<TextFieldProps, 'label' | 'placeholder' | 'variant' | 'disabled' | 'helperText'> {
    /** Callback on place selected in the Autocomplete */
    onPlaceSelected?: (args: google.maps.places.AutocompletePrediction) => void;
    /** (Optional) If we want to specify a pre-entered value */
    value?: string;
}

//fetch all the options for a given input value
const fetchAutocompleteOptions = (inputValue: string, callback: (options: google.maps.places.AutocompletePrediction[]) => void) => {
    if (google && google.maps) {
        const autocompleteService = new google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions(
            { input: inputValue },
            (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    callback(predictions || []);
                } else {
                    callback([]);
                }
            }
        );
    } else {
        callback([]);
    }
};

/** 
 * The Search location input (autocomplete functionality) this is the view only, it needs to be wrapped with a LoadScript from @react-google-maps/api
 * Exported if LoadScript @react-google-maps/api used to wrap multiple GoogleMap component (like this and a map/address/location)
 */
export const SearchLocationInputView = ({ onPlaceSelected, value, libraries, ...props }: ISearchLocationInputProps) => {
    const [autocompleteInputValue, setAutocompleteInputValue] = useState<string>(value || '');
    const [autocompleteOptions, setAutocompleteOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);

    useEffect(() => {
        if (value === undefined) return;
        setAutocompleteInputValue(value);
    }, [value])
    // Handle the selection of a place in the list of autocomplete
    const handlePlaceSelect = (place: google.maps.places.AutocompletePrediction | null) => {
        // console.log('handlePlaceSelect', place);
        if (!place) return;//if no place selected
        onPlaceSelected?.(place);
    };

    // need to debounce those input change
    const debouncedFetchAutocompleteOptions = debounce(
        (newInputValue: string) =>
            fetchAutocompleteOptions(newInputValue, (options) => {
                setAutocompleteOptions(options);
            }),
        200);

    return <Autocomplete
        id={'search-autofill-off'}
        options={autocompleteOptions}
        getOptionLabel={(option) => typeof option === 'string' ? option : option.description}
        onChange={(_, newValue) => typeof newValue !== 'string' && handlePlaceSelect(newValue)}
        inputValue={autocompleteInputValue}
        onInputChange={(_, newInputValue) => {
            setAutocompleteInputValue(newInputValue);
            debouncedFetchAutocompleteOptions(newInputValue)
        }}
        isOptionEqualToValue={(option, value) => {
            return option.place_id === value.place_id
        }}
        inputMode={'search'}
        autoComplete={false}
        renderInput={(params) => <TextField {...params} {...props} />}
        autoHighlight
        fullWidth
        freeSolo
    />
};

/** The list of libraries used by the address search */
const GOOGLE_LIBRARIES: Libraries = ["places"];

interface ISearchLocationInputProps extends ISearchLocationInputViewProps {
    libraries?: Libraries;
}

/** 
 * The Search location input (autocomplete functionality) standalone with a LoadScript from @react-google-maps/api wrapper already included
 * Exported if need to use in a standalone situation (only it in the page)
 */
export const SearchLocationInput = ({ libraries, ...props }: ISearchLocationInputProps) => {
    return <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        libraries={libraries || GOOGLE_LIBRARIES}
        loadingElement={<Skeleton variant='rectangular' height={50} width={100} />}
        onLoad={() => console.log("loaded")}>
        <SearchLocationInputView {...props} />
    </LoadScript>
}
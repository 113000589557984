import React from "react"
import { BANNER_FORUM_MAIN_PAGE } from "../../../utilities/assets/assetHelper";
import { useForumsNavigation } from "../../../hooks/useForumNavigation";
import { ForumsTopicPostsContainer } from "../../../containers/forums/ForumsTopicPostsContainer";
import { useTranslation } from "react-i18next";

export const ForumsMainPage = () => {
    const { t } = useTranslation();
    const { navigateToNewPost, navigateToArticle, navigateToPost } = useForumsNavigation()

    const handleCreateNewPost = () => {
        navigateToNewPost()
    }

    //not specifying a topic since we want all
    return <ForumsTopicPostsContainer
        banner={{
            title: t('forum.main.title'),
            backgroundImageSrc: BANNER_FORUM_MAIN_PAGE,
            subTitle: t('forum.main.subTitle'),
            primaryButton: { text: t('forum.topic.newPostButton'), onClick: handleCreateNewPost }
        }}
        onCreateNewPostClick={handleCreateNewPost}
        navigateToPost={navigateToPost}
        navigateToArticle={navigateToArticle} />;
}

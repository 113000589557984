import React, { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Container, Paper, Slide, Snackbar, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { analytics } from '../../../services/firebase';
import { isEmailValid } from '../../../utilities';
import { useTranslation } from 'react-i18next';

export const CareerFaqPage = () => {
    const { t } = useTranslation();
    const [contactField, setContactField] =
        useState<Pick<TextFieldProps, 'value' | 'error' | 'helperText'>>({ value: '' });
    const [questionField, setQuestionField] =
        useState<Pick<TextFieldProps, 'value' | 'error' | 'helperText'>>({ value: '' });
    const [open, setOpen] = useState(false);

    const handleSendQuestion = () => {
        const email = contactField.value as string;
        const question = questionField.value as string;

        if (!email) {
            return setContactField(c => ({ ...c, error: true, helperText: t('error.requiredFiledEmpty') }))
        }

        if (!question) {
            return setQuestionField(q => ({ ...q, error: true, helperText: t('error.requiredFiledEmpty') }))
        }

        if (!isEmailValid(email)) {
            return setContactField(c => ({ ...c, error: true, helperText: t('error.invalidEmail') }))
        }

        //send somewhere...
        //ignore questions!! :P
        analytics.logEvent("generate_lead", { page: "career-faq", email, question })
        //clear inputs
        setContactField({ value: '' });
        setQuestionField({ value: '' })

        return setOpen(true);
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Container sx={{ p: 2 }}>
            <Stack direction={'column'} spacing={1}>
                <Typography variant='h1'>{t('careers.faq.title')}</Typography>
                {
                    (t('careers.QnA', { returnObjects: true, defaultValue: [] }) as Array<{ question: string, answer: string }>)
                        .map(({ question, answer }, index) =>
                            <Accordion key={`career-faq-${index}`}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Typography variant='h5'>{question}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{answer}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                }
                <Paper sx={{ p: 1 }}>
                    <Stack direction={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        spacing={1}>
                        <Typography variant='h4'>{t('careers.faq.moreQuestionTitle')}</Typography>
                        <Typography>{t('careers.faq.moreQuestionContent')}</Typography>
                        <TextField label={t('careers.faq.inputContactLabel')}
                            placeholder={t('careers.faq.inputContactPlaceholder')}
                            type={'email'}
                            variant={'outlined'}
                            fullWidth
                            onChange={(e) => setContactField({ value: e.target.value })}
                            {...contactField} />
                        <TextField label={t('careers.faq.inputQuestionLabel')}
                            placeholder={t('careers.faq.inputQuestionPlaceholder')}
                            multiline
                            minRows={2}
                            maxRows={3}
                            variant={'outlined'}
                            fullWidth
                            onChange={(e) => setQuestionField({ value: e.target.value })}
                            {...questionField} />
                        <Button variant='contained' onClick={handleSendQuestion}>{t('careers.faq.moreQuestionSendBtn')}</Button>
                    </Stack>
                </Paper>
            </Stack>
            <Snackbar open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                TransitionComponent={(props) => <Slide {...props} direction="up" />}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {t('careers.faq.moreQuestionSuccessSend')}
                </Alert>
            </Snackbar>
        </Container>
    )
}

import { Button, Stack, Typography } from '@mui/material';
import React from 'react';

export interface IDashboardSectionEmptyContentProps {
    /** The message in the empty content */
    message: string;
    /** (optional) The button call to action (to redirect somewhere) */
    button?: {
        onClick: () => void;
        label: string;
    }
}

export const DashboardSectionEmptyContent = ({
    message,
    button
}: IDashboardSectionEmptyContentProps) => {
    return (
        <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={1}
            sx={{ px: 2, py: 4, backgroundColor: '#F7F7F7', borderRadius: 2 }}>
            <Typography variant='body1' textAlign={'center'}>{message}</Typography>
            {!!button && <Button variant={'contained'}
                onClick={button.onClick}
            >{button.label}</Button>
            }
        </Stack>
    )
}
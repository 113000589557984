import React from "react";
import { Box, Button, Typography } from "@mui/material";
import * as styles from "./Banner.style";

export interface IBannerProps {
  /** The title in the banner */
  title: string;
  /** Optional subtitle in the banner */
  subTitle?: string;
  /** The main button in the banner */
  primaryButton: {
    text: string;
    onClick: () => void;
  },
  /** Optional secondary button in the banner */
  secondaryButton?: {
    text: string;
    onClick: () => void;
  },
  /** The background image url */
  backgroundImageSrc: string;
}

export const Banner = ({ title, subTitle, primaryButton, secondaryButton, backgroundImageSrc }: IBannerProps) => {
  return (
    <Box sx={styles.banner(backgroundImageSrc)}>
      <Box sx={styles.info}>
        <Typography variant={'h3'} fontWeight={'bold'}>
          {title}
        </Typography>
        {!!subTitle &&
          <Typography variant={'body1'}>
            {subTitle}
          </Typography>
        }
        <Button
          sx={styles.button}
          onClick={primaryButton.onClick}
          variant="contained"
          color="primary"
        >
          {primaryButton.text}
        </Button>
        {!!secondaryButton &&
          <Button
            sx={styles.button}
            onClick={secondaryButton.onClick}
            variant="contained"
            color="secondary"
          >
            {secondaryButton.text}
          </Button>
        }
      </Box>
    </Box>
  );
};

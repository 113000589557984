import React, { useState } from 'react';
import { useQuery } from 'react-query';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { CircularProgress, DialogContentText, Stack, Typography } from '@mui/material';

import { IShopItemProps } from '../../../components/shop/ShopItem';
import { ShopList } from '../../../components/shop/ShopList';
import { useUserCart } from '../../../context/shop';
import { getShopProducts } from '../../../services/shop';
import { DEFAULT_SHOP_ID } from '../../../utilities/shop';
import { ShopProductVariantsContainer } from '../ShopProductVariantsContainer';
import { ShopVariantsSelect } from '../../../components/shop/ShopVariants';
import { ShopProductVariant, WithID } from '../../../types/db';
import { useTranslation } from 'react-i18next';

interface IShopListContainerProps {
    /**
     * The shop id to fetch the list from
     */
    shopId?: string;
    /**
     * The category id to filter the list of items
     */
    categoryId?: string;
    /**
     * Callback on product clicked to open product details
     */
    onProductClick: (productId: string) => void;
}

export const ShopListContainer = ({
    shopId = DEFAULT_SHOP_ID,
    categoryId,
    onProductClick,
}: IShopListContainerProps) => {
    const { t } = useTranslation();
    const userCart = useUserCart(shopId);
    //fetch the list of items
    const { data, isLoading, isError } = useQuery(["ShopListProducts", categoryId || ""], () => getShopProducts(shopId, categoryId));
    //selected product to confirm to add to cart
    const [selectedProductId, setSelectedProductId] = React.useState('');
    //dialog something went wrong while adding to cart
    const [cartError, setCartError] = useState(false);

    if (isLoading) {
        return <ShopList loading />
    }

    if (isError || !data || !userCart) {
        return <ShopList error />
    }

    const handleAddToCartClick = (productId: string) => {
        const product = data.find(item => item.id === productId);
        //if product as variant to choose from -> open dialog for confirmation
        setSelectedProductId(product?.id ?? '');
    }

    const handleAddToCartConfirmation = async (selectedVariantId?: string) => {
        if (!selectedVariantId) {
            // console.log("Action canceled");
            //action confirmation canceled, closing the dialog
            setSelectedProductId("");
        } else {
            //adding the product with its selected variant in the cart
            try {
                await userCart.addToCart({
                    productId: selectedProductId,
                    variantId: selectedVariantId,
                });
            } catch (e) {
                setCartError(true)
            } finally {
                //close the dialog once done
                setSelectedProductId("");
            }
        }
    }

    const items: IShopItemProps[] = data.map(item => {
        return {
            title: item.name,
            imageUrl: item.image,
            price: {
                originalPrice: {
                    amount: item.prices[0]?.amount,
                    currency: item.prices[0]?.currency
                }
            },
            onViewClick: () => onProductClick(item.id),
            onCartClick: () => handleAddToCartClick(item.id),
        }
    });

    return (
        <>
            <ShopList items={items} />
            <AddToCartConfirmationDialog
                open={!!selectedProductId}
                shopId={shopId}
                productId={selectedProductId}
                onClose={handleAddToCartConfirmation}
            />
            <Dialog
                open={cartError}
                onClose={() => setCartError(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    {t('shop.addToCart.errorTitle')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('shop.addToCart.errorContent')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCartError(false)} autoFocus>
                        {t('ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

interface IAddToCartConfirmationDialogProps {
    shopId: string;
    productId: string;
    open?: boolean;
    onClose: (variantID?: string) => void;
}

const AddToCartConfirmationDialog = ({
    shopId,
    productId,
    open,
    onClose,
}: IAddToCartConfirmationDialogProps) => {
    const { t } = useTranslation();
    return (
        <Dialog
            id={"product-variants-menu"}
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={!!open}
        >
            {!!productId ?
                <ShopProductVariantsContainer shopId={shopId} productId={productId}>
                    {(data) => {
                        if (!data) {
                            return <CircularProgress />
                        }
                        if (data.length === 0) {
                            return <Typography>{t('shop.dialog.errorVariantFetch')}</Typography>;
                        }
                        return <AddToCartDialogContent
                            initOptionSelected={data[0].id}
                            options={data}
                            onClose={onClose}
                        />;
                    }}
                </ShopProductVariantsContainer>
                :
                <Typography>{t('shop.dialog.errorVariantFetch')}</Typography>
            }
        </Dialog>
    )
}

interface IAddToCartDialogContentProps
    extends Pick<IAddToCartConfirmationDialogProps, 'onClose'> {
    initOptionSelected: string;
    options: WithID<ShopProductVariant, string>[];
}

const AddToCartDialogContent = ({
    initOptionSelected,
    options,
    onClose
}: IAddToCartDialogContentProps) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(initOptionSelected);

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(value);
    };

    return (
        <>
            <DialogTitle>{t('shop.dialog.addToCartTitle')}</DialogTitle>
            <DialogContent dividers>
                {(options.length === 1) ?
                    <Stack direction={'column'} spacing={2}>
                        <Typography>{t('shop.dialog.confirmVariant')}</Typography>
                        <Typography>{options[0].name}</Typography>
                    </Stack>
                    :
                    <Stack direction={'column'} spacing={2}>
                        <Typography>{t('shop.dialog.selectVariant')}</Typography>
                        <ShopVariantsSelect
                            selectedVariant={value}
                            variants={options.map(v => ({
                                title: v.name,
                                image: '',
                                value: v.id,
                                info: {
                                    color: {
                                        colorName: v.color?.color_name || '',
                                        colorCode: v.color?.color_hex || '',
                                    },
                                    size: v.size || '',
                                }
                            }))}
                            onVariantSelect={setValue} />
                    </Stack>
                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    {t('cancel')}
                </Button>
                <Button onClick={handleOk} variant={'contained'}>
                    {t('shop.product.addToCartButton')}
                </Button>
            </DialogActions>
        </>
    );
}
import React, { useContext, useEffect, useState } from 'react';
import { Box, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { ListingCalendarType } from '../../../types/db';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { isValidCalendar, useCalendarOnBlurCallback, useGuestCalendarOnChangeCallback, useCalendarTypeOnChangeCallback, isSameCalendar, useCohostDiffCalendarCheckOnChangeCallback, useCohostCalendarOnChangeCallback } from '../../../utilities/listings';
import { ListingEditContext } from '../../../context/Listing/ListingEditFlow';
import { ListingEditSectionView } from './ListingEditSectionView';
import { CalendarInput, CalendarInputFieldName, ICalendarInputProps } from '../../../components/listing/CalendarInput';
import { CalendarDisplay } from '../../../components/listing/CalendarDisplay';
import { IssueStateView } from '../../../components/IssueStateView';

/** The calendar page of the listing creation flow */
export const ListingCalendarPage = () => {
    return <Container />
}

type RadioChoice = ListingCalendarType;

const Container = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext')
    }

    const { listing: { pricing }, isLoading } = listingEditContext;

    if (pricing?.type === 'non-bookable') {
        return (
            <Box>
                <Typography>{t('listing.calendar.nonBookableMessage')}</Typography>
            </Box>
        );
    }

    if (isLoading) {
        return <CircularProgress />
    }

    return (
        <Stack direction={'column'} spacing={2}>
            <ListingCalendarAvailability />
            <Divider />
            <ListingGuestCalendar />
            <Divider />
            <ListingCohostCalendar />
        </Stack>
    );
}

const ListingCalendarAvailability = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext')
    }

    const { listing: { calendar, pricing }, setActivePageInfo, handleCancel, handleSave, completed } = listingEditContext;

    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (!editing) return;// no need to check if not editing
        if (pricing?.type === 'non-bookable') {
            //if the listing is non-bookable, let the user skip this step (should never go there since blocked higher)
            setActivePageInfo({}, true);
        } else if (isValidCalendar(calendar)) {
            //only check the guest/general calendar
            setActivePageInfo({}, true)
        } else {
            //reset completion
            setActivePageInfo({}, false)
        }
    }, [calendar, editing, pricing?.type, setActivePageInfo]);


    // The type of calendar
    const calendarType: RadioChoice | undefined = calendar?.type;
    const handleCalendarTypeChange = useCalendarTypeOnChangeCallback(setActivePageInfo);

    return <ListingEditSectionView title={t('listing.edit.typeCalendarTitle')}
        isEditMode={editing}
        onEditClick={() => setEditing(true)}
        onCancelClick={() => { handleCancel(); setEditing(false) }}
        onSaveClick={() => {
            if (!completed) return;
            handleSave().then(() => setEditing(false))
        }}
        isSaveDisabled={!completed}>
        {
            !editing ?
                <Typography>{t('listing.calendar.type', { context: calendarType === 'default-available' ? 'all' : 'none' })}</Typography>
                :
                <FormControl>
                    <RadioGroup
                        value={calendarType || ''}
                        onChange={(ev) => handleCalendarTypeChange(ev.target.value as RadioChoice)}
                    >
                        <FormControlLabel
                            value={"default-available" as RadioChoice}
                            control={<Radio />}
                            label={t('listing.calendar.type', { context: 'all' })}
                        />
                        <FormControlLabel
                            value={"default-non-available" as RadioChoice}
                            control={<Radio />}
                            label={t('listing.calendar.type', { context: 'none' })}
                        />
                    </RadioGroup>
                </FormControl>
        }
    </ListingEditSectionView>
}

export const ListingGuestCalendar = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext')
    }

    const { listing: { calendar, pricing }, setActivePageInfo, handleCancel, handleSave, completed } = listingEditContext;

    const { t } = useTranslation();

    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (!editing) return;// no need to check if not editing
        if (pricing?.type === 'non-bookable') {
            //if the listing is non-bookable, let the user skip this step
            setActivePageInfo({}, true);
        } else if (isValidCalendar(calendar)) {
            //only check the guest/general calendar
            setActivePageInfo({}, true)
        } else {
            //reset completion
            setActivePageInfo({}, false)
        }
    }, [calendar, editing, pricing?.type, setActivePageInfo]);


    // The guest calendar
    const [guestCalendarErrors, setGuestCalendarErrors] = useState<{ [key in CalendarInputFieldName]?: string }>({});
    const guestCalendar: ICalendarInputProps = {
        minNight: {
            value: calendar?.min_night?.toString() || '',
            helperText: guestCalendarErrors['minNight'],
            error: !!guestCalendarErrors['minNight'],
        },
        maxNight: {
            value: calendar?.max_night?.toString() || '',
            helperText: guestCalendarErrors['maxNight'],
            error: !!guestCalendarErrors['maxNight'],
        },
    }
    const handleGuestCalendarChange = useGuestCalendarOnChangeCallback(setActivePageInfo, setGuestCalendarErrors);

    const handleCalendarOnBlur = useCalendarOnBlurCallback(t, setGuestCalendarErrors);

    return <ListingEditSectionView title={t('listing.edit.guestCalendarTitle')}
        isEditMode={editing}
        onEditClick={() => setEditing(true)}
        onCancelClick={() => { handleCancel(); setEditing(false) }}
        onSaveClick={() => {
            if (!completed) return;
            handleSave().then(() => setEditing(false))
        }}
        isSaveDisabled={!completed}>
        {
            !editing ?
                <CalendarDisplay
                    minNight={calendar?.min_night || 0}
                    maxNight={calendar?.max_night}
                />
                :
                <CalendarInput {...{
                    ...guestCalendar,
                    minNight: {
                        ...guestCalendar.minNight,
                        onChange: (ev) => handleGuestCalendarChange('minNight', ev.target.value),
                        onBlur: (ev) => handleCalendarOnBlur(calendar, 'minNight', ev.target.value),
                    },
                    maxNight: {
                        ...guestCalendar.maxNight,
                        onChange: (ev) => handleGuestCalendarChange('maxNight', ev.target.value),
                        onBlur: (ev) => handleCalendarOnBlur(calendar, 'maxNight', ev.target.value),
                    },
                }} />
        }
    </ListingEditSectionView>
}

export const ListingCohostCalendar = ({ title }: { title?: string }) => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext')
    }

    const { listing: { calendar, pricing, cohost_perk }, setActivePageInfo, handleCancel, handleSave, completed } = listingEditContext;

    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (!editing) return;// no need to check if not editing
        if (pricing?.type === 'non-bookable') {
            //if the listing is non-bookable, let the user skip this step
            setActivePageInfo({}, true);
        } else if (!cohost_perk?.open
            && isValidCalendar(calendar)) {
            //if there isn't cohost perk, only check the guest/general calendar
            setActivePageInfo({}, true)
        } else if (!!cohost_perk?.calendar
            && isValidCalendar(cohost_perk.calendar, true)
            && isValidCalendar(calendar)) {
            //if there cohost perk with a calendar, and its calendar is valid as well as the guest/general calendar
            setActivePageInfo({}, true)
        } else {
            //reset completion
            setActivePageInfo({}, false)
        }
    }, [calendar, cohost_perk, editing, pricing?.type, setActivePageInfo]);

    // The cohost calendar
    const isListingOpenToCohost = !!cohost_perk?.open;
    const isDiffCohostSettings = !!calendar && !!cohost_perk?.calendar && (!isSameCalendar(calendar, cohost_perk.calendar) || Object.keys(cohost_perk.calendar).length === 0);
    const [diffChecked, setDiffChecked] = useState(isDiffCohostSettings);
    const handleDiffCohostCheckOnChange = useCohostDiffCalendarCheckOnChangeCallback(setActivePageInfo);

    const [cohostCalendarErrors, setCohostCalendarErrors] = useState<{ [key in CalendarInputFieldName]?: string }>({});
    const cohostCalendar: ICalendarInputProps = {
        minNight: {
            value: cohost_perk?.calendar?.min_night?.toString() || '',
            helperText: cohostCalendarErrors['minNight'],
            error: !!cohostCalendarErrors['minNight'],
        },
        maxNight: {
            value: cohost_perk?.calendar?.max_night?.toString() || '',
            helperText: cohostCalendarErrors['maxNight'],
            error: !!cohostCalendarErrors['maxNight'],
        }
    };
    const handleCohostCalendarChange = useCohostCalendarOnChangeCallback(setActivePageInfo);
    const handleCohostCalendarOnBlur = useCalendarOnBlurCallback(t, setCohostCalendarErrors)

    // if (!isListingOpenToCohost) {
    //     return null;
    // }
    return <ListingEditSectionView title={title || t('listing.edit.cohostCalendarTitle')}
        isEditMode={editing}
        onEditClick={isListingOpenToCohost ? (() => setEditing(true)) : undefined}
        onCancelClick={() => { handleCancel(); setEditing(false) }}
        onSaveClick={() => {
            if (!completed) return;
            handleSave().then(() => setEditing(false))
        }}
        isSaveDisabled={!completed}>
        {
            !isListingOpenToCohost ?
                <IssueStateView title={t('listing.calendar.notOpenToCohostTitle')}
                    description={t('listing.calendar.notOpenToCohostDescription')} />
                :
                !editing ?
                    isDiffCohostSettings ?
                        <CalendarDisplay
                            minNight={cohost_perk.calendar?.min_night}
                            maxNight={cohost_perk.calendar?.max_night} />
                        :
                        <Typography>{t('listing.edit.cohostCalendarDisabled')}</Typography>
                    :
                    <FormControl>
                        <FormControlLabel control={
                            <Checkbox
                                {...{
                                    checked: diffChecked || isDiffCohostSettings,
                                    onChange: (_, checked) => { handleDiffCohostCheckOnChange(checked); setDiffChecked(checked) },
                                }} />}
                            label={t('listing.calendar.cohostDiffLabel')} />
                        {
                            (diffChecked || isDiffCohostSettings) &&
                            <CalendarInput {...{
                                ...cohostCalendar,
                                minNight: {
                                    ...cohostCalendar.minNight,
                                    onChange: (ev) => handleCohostCalendarChange('minNight', ev.target.value),
                                    onBlur: (ev) => handleCohostCalendarOnBlur(cohost_perk?.calendar, 'minNight', ev.target.value),
                                },
                                maxNight: {
                                    ...cohostCalendar.maxNight,
                                    onChange: (ev) => handleCohostCalendarChange('maxNight', ev.target.value),
                                    onBlur: (ev) => handleCohostCalendarOnBlur(cohost_perk?.calendar, 'maxNight', ev.target.value),
                                },
                            }} />
                        }
                    </FormControl>
        }
    </ListingEditSectionView>
}

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ListingEditContext } from '../../../context/Listing/ListingEditFlow';
import { getListingInfoFromStatus, getListingInfoFromType, GETTING_AROUND_MAX_LENGTH, isDescriptionValid, isListingTypeValid, isShortDescriptionValid, LONG_DESC_MAX_LENGTH, NEIGHBORHOOD_DESCRIPTION_MAX_LENGTH, SHORT_DESC_MAX_LENGTH, TITLE_MAX_LENGTH, useGettingAroundDescriptionEffect, useGettingAroundDescriptionOnBlurCallback, useHandleGettingAroundDescriptionOnChangeCallback, useHandleLongDescriptionOnBlurCallback, useHandleLongDescriptionOnChangeCallback, useHandleNeighborhoodDescriptionOnChangeCallback, useHandleShortDescriptionOnBlurCallback, useHandleShortDescriptionOnChangeCallback, useHandleTitleOnBlurCallback, useHandleTitleOnChangeCallback, useListingTypeEffect, useListingTypeOnChangeCallback, useNeighborhoodDescriptionEffect, useNeighborhoodDescriptionOnBlurCallback, useTitleEffect } from '../../../utilities/listings';
import { Info } from '@mui/icons-material';
import { ListingTree, ListingType, TListingStatus } from '../../../types/db';
import { ListingTypeCard } from '../../../components/listing/ListingTypeCard';
import { addPhotoToListing, deletePhotoFromListing, getListingPhotos } from '../../../services/listing';
import { useDropzone } from 'react-dropzone';
import { addPictureToListing, deletePictureFromListing } from '../../../services/storage';
import { ListingImageThumbnail } from '../../../components/listing/ListingImageThumbnail';
import { ListingEditSectionView } from './ListingEditSectionView';

/** The details of the listing management/edit */
export const ListingDetailsPage = () => {
    return <Container />
}

const Container = () => {
    const { t } = useTranslation();
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { isLoading } = listingEditContext;
    if (isLoading) {
        return <CircularProgress />
    }

    return (
        <Stack direction={'column'} spacing={2}>
            <ListingEditSectionView title={t('listing.edit.basicsTitle')} level={'L1'}>
                <ListingTitleContainer />
                <ListingDescriptionsContainer />
                <ListingStatusContainer />
            </ListingEditSectionView >
            <Divider />
            <ListingPhotosContainer />
            <Divider />
            <ListingEditSectionView title={t('listing.edit.locationTitle')} level={'L1'}>
                <ListingLocationAddressContainer />
                <ListingLocationNeighborhoodContainer />
                <ListingLocationGettingAroundContainer />
            </ListingEditSectionView >
            <Divider />
            <ListingEditSectionView title={t('listing.edit.propertyTitle')} level={'L1'}>
                <ListingPropertyTypeContainer />
                <ListingPropertyRoomsContainer />
            </ListingEditSectionView >
        </Stack>
    )
}

const ListingTitleContainer = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { listing: { title }, setActivePageInfo, handleSave, handleCancel, completed } = listingEditContext;
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);

    const [titleError, setTitleError] = useState<string>();

    const titleProps: TextFieldProps = {
        value: title || '',
        helperText: titleError || `${title?.length || 0}/${TITLE_MAX_LENGTH}`,
        error: !!titleError,
    };

    useTitleEffect(title, setActivePageInfo, editing);
    const handleTitleOnBlur = useHandleTitleOnBlurCallback(t, setTitleError);
    const handleTitleOnChange = useHandleTitleOnChangeCallback(setActivePageInfo, setTitleError);

    return <ListingEditSectionView title={t('listing.edit.titleTitle')}
        isEditMode={editing}
        onEditClick={() => setEditing(true)}
        onCancelClick={() => { handleCancel("title"); setEditing(false) }}
        onSaveClick={() => {
            if (!completed) return;
            handleSave().then(() => setEditing(false))
        }}
        isSaveDisabled={!completed}>
        {editing ?
            <TextField
                label={t('listing.creation.titleInputLabel')}
                placeholder={t('listing.creation.titleInputPlaceholder')}
                onBlur={handleTitleOnBlur}
                onChange={handleTitleOnChange}
                autoComplete={'off'}
                {...titleProps}
            />
            :
            <Typography>{titleProps.value}</Typography>
        }
    </ListingEditSectionView>
}

const ListingDescriptionsContainer = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { listing: { short_description, description }, handleSave, handleCancel, setActivePageInfo, completed } = listingEditContext;
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);

    const [descError, setDescError] = useState<string>();
    const [desc2Error, setDesc2Error] = useState<string>();

    const descProps: TextFieldProps = {
        value: short_description || '',
        helperText: descError || `${short_description?.length || 0}/${SHORT_DESC_MAX_LENGTH}`,
        error: !!descError,
    }
    const desc2Props: TextFieldProps = {
        value: description || '',
        helperText: desc2Error || `${description?.length || 0}/${LONG_DESC_MAX_LENGTH}`,
        error: !!desc2Error,
    };

    useEffect(() => {
        if (!editing) return;//if not editing, we don't check
        if (isShortDescriptionValid(short_description) && isDescriptionValid(description)) {
            setActivePageInfo({}, true)
        } else {
            setActivePageInfo({}, false)
        }
    }, [short_description, description, setActivePageInfo, editing]);

    const handleDescOnBlur = useHandleShortDescriptionOnBlurCallback(t, setDescError);
    const handleDescOnChange = useHandleShortDescriptionOnChangeCallback(setActivePageInfo, setDescError);

    const handleDesc2OnBlur = useHandleLongDescriptionOnBlurCallback(t, setDesc2Error);
    const handleDesc2OnChange = useHandleLongDescriptionOnChangeCallback(setActivePageInfo, setDesc2Error);

    return <ListingEditSectionView title={t('listing.edit.descriptionTitle')}
        isEditMode={editing}
        onEditClick={() => setEditing(true)}
        onCancelClick={() => { handleCancel(); setEditing(false) }}
        onSaveClick={() => {
            if (!completed) return;
            handleSave().then(() => setEditing(false))
        }}
        isSaveDisabled={!completed}>
        {editing ?
            <>
                <TextField
                    label={t('listing.creation.shortDescriptionInputLabel')}
                    placeholder={t('listing.creation.shortDescriptionInputPlaceholder')}
                    multiline={true}
                    onBlur={handleDescOnBlur}
                    onChange={handleDescOnChange}
                    {...descProps}
                />
                <TextField
                    label={t('listing.creation.descriptionLabel')}
                    placeholder={t('listing.creation.descriptionPlaceholder')}
                    multiline={true}
                    onBlur={handleDesc2OnBlur}
                    onChange={handleDesc2OnChange}
                    {...desc2Props}
                />
            </>
            :
            <>
                <Typography>{short_description}</Typography>
                <Typography sx={{ wordBreak: 'break-word' }}>{description}</Typography>
            </>
        }
    </ListingEditSectionView>
}

const ListingStatusContainer = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { listing: { status }, handleSave, handleCancel, setActivePageInfo, completed } = listingEditContext;
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);
    const [deleteListingDialog, setDeleteListingDialog] = useState({ open: false, checked: false });

    const statusInfo = getListingInfoFromStatus(status || 'in-review', t);

    const renderRadioElement = (s: TListingStatus, disabled?: boolean) => {
        const info = getListingInfoFromStatus(s, t);
        return <FormControlLabel value={s}
            control={<Radio />}
            disabled={disabled}
            label={
                <Stack direction={'column'}>
                    <Stack direction={'row'} spacing={1}>
                        {info.icon}
                        <Typography>{info.title}</Typography>
                    </Stack>
                    <Typography variant='caption'>{info.description}</Typography>
                </Stack>
            }
            sx={{ alignItems: 'start' }} />
    }

    return <>
        <ListingEditSectionView title={t('listing.edit.statusTitle')}
            isEditMode={editing}
            onEditClick={() => setEditing(true)}
            onCancelClick={() => { handleCancel("status"); setEditing(false) }}
            onSaveClick={() => {
                if (!completed) return;
                if (status === 'de-activated') {
                    setDeleteListingDialog({ open: true, checked: false });
                    return;
                }
                handleSave().then(() => setEditing(false))
            }}
            isSaveDisabled={!completed}>
            {editing ?
                <FormControl>
                    {
                        (status === 'in-review' || status === 'pending') &&
                        <Alert icon={<Info fontSize="inherit" />} severity="warning" sx={{ m: 1 }}>
                            {t('listing.status.alertActionDisabled')}
                        </Alert>
                    }
                    <RadioGroup
                        value={status}
                        onChange={(_, value) => setActivePageInfo({ status: value as TListingStatus })}
                        sx={{ gap: 2 }}
                    >
                        {renderRadioElement('published', status === 'in-review' || status === 'pending' || status === 'de-activated')}
                        {status === 'in-review' && renderRadioElement('in-review', true)}
                        {status === 'pending' && renderRadioElement('pending', true)}
                        {/* {renderRadioElement('snoozed', status === 'in-review' || status === 'pending'||status==='de-activated')} */}
                        {renderRadioElement('un-listed', status === 'in-review' || status === 'pending' || status === 'de-activated')}
                        {renderRadioElement('de-activated')}
                    </RadioGroup>
                </FormControl>
                :
                <Stack direction={'row'} spacing={1}>
                    {statusInfo.icon}
                    <Typography>{`${statusInfo.title} - ${statusInfo.description}`}</Typography>
                </Stack>
            }
        </ListingEditSectionView>
        <Dialog open={deleteListingDialog.open}
            onClose={() => setDeleteListingDialog({ open: false, checked: false })}>
            <DialogTitle>
                {t('listing.status.warningDeletionTitle')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('listing.status.warningDeletionContent')}
                </DialogContentText>
                <FormControlLabel control={<Checkbox />}
                    checked={deleteListingDialog.checked}
                    onChange={(_, checked) => setDeleteListingDialog({ open: deleteListingDialog.open, checked })}
                    label={t('listing.status.warningDeletionFooter')} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDeleteListingDialog({ open: false, checked: false })} autoFocus>{t('cancel')}</Button>
                <Button disabled={!deleteListingDialog.checked} onClick={() => { handleSave().then(() => setDeleteListingDialog({ open: false, checked: false })) }} >{t('agree')}</Button>
            </DialogActions>
        </Dialog>
    </>
}

type FileImage = {
    preview: string;
    name: string;
    description: string;
    photoRef?: any;
}
const ListingPhotosContainer = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }

    const { listing, setActivePageInfo, handleCancel, handleSave, completed } = listingEditContext;
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);

    const [files, setFiles] = useState<({ percent: number, error?: Error } & FileImage)[]>([]);

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!listing.ref) return;
        getListingPhotos(listing.ref)
            .then((photos) => {
                setFiles(photos.map(p => ({
                    percent: 100,
                    name: p.name,
                    description: p.description || '',
                    preview: p.url,
                    photoRef: p.ref,
                })))
            });
        //Only check for the change of the listing id (string) because we don't want to have infinite loop on object ref
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listing.id]);

    useEffect(() => {
        if (!editing) return;//if not editing, we don't check
        if (files.some(file => !!file.photoRef)) {
            const first = files[0];
            setActivePageInfo({
                main_photo: {
                    name: first.name,
                    description: first.description,
                    url: first.preview,
                }
            }, true);
        } else {
            setActivePageInfo({}, false);
        }
    }, [editing, files, setActivePageInfo]);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject } = useDropzone({
            noDrag: false,
            accept: {
                'image/*': ['.jpeg', '.png']
            },
            onDrop: acceptedFiles => {
                acceptedFiles.forEach((acceptedFile) => {
                    if (!listing?.ref || !listing?.id) return;
                    addPictureToListing(listing.id, acceptedFile, (percent, finalInfo, error) => {
                        if (!!error) {
                            setFiles(files => files.map((file) => {
                                if (file.name === acceptedFile.name) {
                                    return {
                                        ...file,
                                        percent: 0,
                                        error: error,
                                    }
                                }
                                return file;
                            }));
                        } else if (percent < 100) {
                            setFiles(files => files.map((file) => {
                                if (file.name === acceptedFile.name) {
                                    return {
                                        ...file,
                                        percent,
                                    }
                                }
                                return file;
                            }));
                        } else if (!!finalInfo) {
                            addPhotoToListing(
                                {
                                    url: finalInfo.url,
                                    name: finalInfo.name,
                                },
                                listing.ref || '')
                                .then((photoRef) => {
                                    setFiles(files => files.map((file) => {
                                        if (file.name === acceptedFile.name) {
                                            return {
                                                ...file,
                                                name: finalInfo.name,
                                                preview: finalInfo.url,
                                                percent: 100,
                                                photoRef
                                            }
                                        }
                                        return file;
                                    }));
                                });
                        }
                    });
                });

                setFiles(files => ([
                    ...files,
                    ...acceptedFiles.map(file => ({
                        preview: URL.createObjectURL(file),
                        name: file.name,
                        description: file.name,
                        percent: 0,
                    }))]));
            }
        });

    const handleDeletePicture = (file: FileImage) => {
        !!listing.id
            && !!file.photoRef
            && deletePictureFromListing(listing.id, file.name)
                .then(() => deletePhotoFromListing(file.photoRef))
                .then(() => setFiles(files => files.filter(f => f.name !== file.name)))
    }

    const style = useMemo(() => ({
        ...{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        },
        ...(isFocused ? { borderColor: '#2196f3' } : {}),
        ...(isDragAccept ? { borderColor: '#00e676' } : {}),
        ...(isDragReject ? { borderColor: '#ff1744' } : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <ListingEditSectionView title={t('listing.edit.photosTitle')} level={'L1'}
            isEditMode={editing}
            onEditClick={() => setEditing(true)}
            onCancelClick={() => { handleCancel("status"); setEditing(false) }}
            onSaveClick={() => {
                if (!completed) return;
                handleSave().then(() => setEditing(false))
            }}
            isSaveDisabled={!completed}>
            {
                editing ?
                    <section>
                        <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>
                        {/* Note: find a better way to fit height properly than rowSpacing */}
                        <Grid container spacing={2} rowSpacing={4} my={2} justifyContent={'center'} alignItems={'stretch'}>
                            {
                                files.map((file) =>
                                    <Grid item key={file.name} xs={12} sm={6} md={3}>
                                        <ListingImageThumbnail
                                            name={file.name}
                                            description={file.description}
                                            url={file.preview}
                                            percent={file.percent}
                                            onDeleteClick={!!file.photoRef ? () => handleDeletePicture(file) : undefined}
                                        />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </section>
                    :
                    <Grid container spacing={2} rowSpacing={4} my={2} justifyContent={'center'} alignItems={'stretch'}>
                        {
                            files.map((file) =>
                                <Grid item key={file.name} xs={12} sm={6} md={3}>
                                    <ListingImageThumbnail
                                        name={file.name}
                                        description={file.description}
                                        url={file.preview}
                                    />
                                </Grid>
                            )
                        }
                    </Grid>
            }
        </ListingEditSectionView>
    )

}

const ListingLocationAddressContainer = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { listing: { location } } = listingEditContext;
    const { t } = useTranslation();

    //TODO address view component
    return <ListingEditSectionView title={t('listing.edit.addressTitle')}>
        <Stack direction={'column'}>
            <Typography>{location?.address?.line1}</Typography>
            <Typography>{location?.address?.line2}</Typography>
            <Typography>{location?.address?.zip}</Typography>
            <Typography>{location?.address?.city}</Typography>
            <Typography>{location?.address?.country_name}</Typography>
        </Stack>
    </ListingEditSectionView>
}
const ListingLocationNeighborhoodContainer = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { listing: { location }, handleSave, handleCancel, setActivePageInfo, completed } = listingEditContext;
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);

    const [textError, setTextError] = useState<string>();

    const textProps: TextFieldProps = {
        value: location?.neighborhood || '',
        helperText: textError || `${location?.neighborhood?.length || 0}/${NEIGHBORHOOD_DESCRIPTION_MAX_LENGTH}`,
        error: !!textError,
    };

    useNeighborhoodDescriptionEffect(location?.neighborhood || '', setActivePageInfo, editing)
    const handleTextOnBlur = useNeighborhoodDescriptionOnBlurCallback(t, setTextError);
    const handleTextOnChange = useHandleNeighborhoodDescriptionOnChangeCallback(setActivePageInfo, setTextError);

    return <ListingEditSectionView title={t('listing.edit.neighborhoodTitle')}
        isEditMode={editing}
        onEditClick={() => setEditing(true)}
        onCancelClick={() => { handleCancel(); setEditing(false) }}
        onSaveClick={() => {
            if (!completed) return;
            handleSave().then(() => setEditing(false))
        }}
        isSaveDisabled={!completed}>
        {
            editing ?
                <TextField
                    label={t('listing.edit.neighborhoodInputLabel')}
                    placeholder={t('listing.edit.neighborhoodInputPlaceholder')}
                    onBlur={handleTextOnBlur}
                    onChange={handleTextOnChange}
                    multiline={true}
                    {...textProps}
                />
                :
                <Typography>{location?.neighborhood || ''}</Typography>
        }
    </ListingEditSectionView>
}

const ListingLocationGettingAroundContainer = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { listing: { location }, handleSave, handleCancel, setActivePageInfo, completed } = listingEditContext;
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);

    const [textError, setTextError] = useState<string>();

    const textProps: TextFieldProps = {
        value: location?.gettingAround || '',
        helperText: textError || `${location?.gettingAround?.length || 0}/${GETTING_AROUND_MAX_LENGTH}`,
        error: !!textError,
    };

    useGettingAroundDescriptionEffect(location?.gettingAround || '', setActivePageInfo, editing)
    const handleTextOnBlur = useGettingAroundDescriptionOnBlurCallback(t, setTextError);
    const handleTextOnChange = useHandleGettingAroundDescriptionOnChangeCallback(setActivePageInfo, setTextError);

    return <ListingEditSectionView title={t('listing.edit.gettingAroundTitle')}
        isEditMode={editing}
        onEditClick={() => setEditing(true)}
        onCancelClick={() => { handleCancel(); setEditing(false) }}
        onSaveClick={() => {
            if (!completed) return;
            handleSave().then(() => setEditing(false))
        }}
        isSaveDisabled={!completed}>
        {
            editing ?
                <TextField
                    label={t('listing.edit.gettingAroundInputLabel')}
                    placeholder={t('listing.edit.gettingAroundInputPlaceholder')}
                    onBlur={handleTextOnBlur}
                    onChange={handleTextOnChange}
                    multiline={true}
                    {...textProps}
                />
                :
                <Typography>{location?.gettingAround || ''}</Typography>
        }
    </ListingEditSectionView>
}

const ListingPropertyTypeContainer = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { listing: { type, breadcrumbs, sub_listings }, handleSave, handleCancel, setActivePageInfo, completed } = listingEditContext;
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);

    const typeInfo = getListingInfoFromType(type || 'multi-mix', t);
    const parentType: ListingType | undefined = useMemo(() =>
        breadcrumbs?.length ? (breadcrumbs[breadcrumbs.length - 1]?.type || 'multi-mix') : undefined,
        [breadcrumbs]);
    const childrenTypes: ListingType[] | undefined = useMemo(() =>
        !!sub_listings?.length ?
            sub_listings.map(child => child?.type).filter(t => !!t) as ListingType[]
            : undefined,
        [sub_listings]);

    useListingTypeEffect(parentType, type, childrenTypes, setActivePageInfo, editing);

    const handleTypeClick = useListingTypeOnChangeCallback(setActivePageInfo);

    //the option available to potentially change to
    const options: ListingType[] = (!parentType ?
        //if no parent, a parent itself
        ListingTree["multi-mix"]
            .filter(type => type.includes("multi-"))
        //there is a parent, take potential sub-listing for this parent
        : ListingTree[parentType])
        //filter the options compatible with children
        .filter(t => isListingTypeValid(parentType, t, childrenTypes));
    //only have the availability to edit if there is more than one option to change to
    const hasOptionToChange = options.length > 1;

    return <ListingEditSectionView title={t('listing.edit.typeTitle')}
        isEditMode={editing}
        onEditClick={hasOptionToChange ? () => setEditing(true) : undefined}
        onCancelClick={() => { handleCancel("status"); setEditing(false) }}
        onSaveClick={() => {
            if (!completed) return;
            handleSave().then(() => setEditing(false))
        }}
        isSaveDisabled={!completed}>
        {
            editing ?
                <Grid container spacing={2} justifyContent={'center'}>
                    {
                        options
                            .map(itemType => <Grid key={itemType} item xs={12} sm={6} md={4}><ListingTypeCard key={itemType} type={itemType} selected={itemType === type} onClick={() => handleTypeClick(itemType)} /></Grid>)
                    }
                </Grid>
                :
                <Stack direction={'row'} alignItems={'start'} spacing={1}>
                    {typeInfo.icon}
                    <Stack direction={'column'}>
                        <Typography variant={'h5'} fontWeight={'bold'}>{typeInfo.title}</Typography>
                        <Typography>{typeInfo.description}</Typography>
                    </Stack>
                </Stack>
        }
    </ListingEditSectionView>
}
const ListingPropertyRoomsContainer = () => {
    //TODO
    // const { t } = useTranslation();
    // return <ListingEditSectionView title={t('listing.edit.spacesTitle')}>

    // </ListingEditSectionView>
    return null;
}

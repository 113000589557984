import React, { useState } from 'react';
import { AttachFile, Send } from '@mui/icons-material';
import { IconButton, Stack, TextField, TextFieldProps, Typography } from '@mui/material';

export interface IChatMessageInputProps extends Omit<TextFieldProps, 'sx'> {
    /**
     * The list of members in the chat which are currently typing
     */
    typingMembers?: string[];
    onAttachFileClick?: () => Promise<any>;
    onSendClick: (message: string, media?: any) => Promise<void>;
}

export const ChatMessageInput = ({ typingMembers, onAttachFileClick, onSendClick, disabled, onChange, ...restProps }: IChatMessageInputProps) => {
    const [value, setValue] = useState<string>((restProps.value || "") as string);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event);
        setValue(event.target.value);
    };

    const handleAttachFileClick = async () => {
        await onAttachFileClick?.();
    }

    const handleSendClick = () => {
        onSendClick(value)
            .then(() => {
                setValue('');//clean out the value
            });
    }

    const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            //send message when Enter is pressed (unless shift key down too: hard return)
            handleSendClick();
        }
    }

    return (
        <Stack>
            {
                !!typingMembers && typingMembers.length > 0 &&
                <Typography>{typingMembers.join(' and ') + " typing"}</Typography>
            }
            <Stack direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <TextField
                    sx={{ flex: 1 }}
                    placeholder={'Enter message'}
                    multiline
                    maxRows={4}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    disabled={disabled}
                    {...restProps}
                />
                {!!onAttachFileClick && <IconButton onClick={handleAttachFileClick} disabled={disabled}><AttachFile /></IconButton>}
                <IconButton onClick={handleSendClick} disabled={disabled || !value.trim()}><Send /></IconButton>
            </Stack>
        </Stack>
    )
}
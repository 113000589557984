/** The remote config for the shop */
export const RC_SHOP = "shop";

export type Shop = {
    /** Boolean indicating if the shop page should be visible */
    enabled: boolean;
}

export const DEFAULT_SHOP: Shop = {
    "enabled": false
}
import React from 'react';
import { useQuery } from 'react-query';
import { Lock } from '@mui/icons-material';

import { AccountBaseInfo, AccountBaseInfoItems } from '../../../components/user/account/AccountBaseInfo';
import { IAccountHelpItemProps } from '../../../components/user/account/AccountHelpItem';
import { getTitleFromAccountPage } from '../../../components/user/account/AccountPageView';
import { getUserPrivacyInfo } from '../../../services/user';
import { IAccountSubContainerProps } from '../AccountSubContainer';
import { useTranslation } from 'react-i18next';

export const AccountPrivacyContainer = ({
    subPage,
    userId,
    children,
}: IAccountSubContainerProps) => {
    const { t } = useTranslation();
    const userPrivacyResult = useQuery(["account", "privacy"],
        () => getUserPrivacyInfo(userId));

    const userPrivacyInfo = userPrivacyResult.data;

    const items: AccountBaseInfoItems =
        !userPrivacyInfo ? [] :
            [
                {
                    label: t('user.accountPrivacy.cookiesTitle'),
                    items: [],
                },
                {
                    label: t('user.accountPrivacy.servicesTitle'),
                    items: [],
                },
                //TODO complete
            ]

    if (!!children) {
        return children({ items, helpItems: HELP_ITEMS });
    } else {
        return <AccountBaseInfo title={getTitleFromAccountPage(t, subPage)}
            items={items}
            helpItems={HELP_ITEMS} />
    }
}

const HELP_ITEMS: IAccountHelpItemProps[] = [
    {
        icon: <Lock />,
        title: 'user.accountPrivacy.helpInfo1Title',
        description: 'user.accountPrivacy.helpInfo1Content',
    },
];
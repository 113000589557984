import React, { PropsWithChildren } from 'react';
import { Home, NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Container, Link, Skeleton, Typography } from '@mui/material';
import { PropsWithLoadingState } from '../../../utilities';
import { useTranslation } from 'react-i18next';

export interface IHelpPageProps {
    /** The title of the help page (article title of topic title), don't provide it for root all topics */
    title?: string;
    /** The breadcrumbs to the document (excluding help home and all topics) */
    breadcrumbs: { label: string, onClick: () => void }[];
    /** Callback on home click */
    onHomeClick: () => void;
    /** Callback on all topic click */
    onAllTopicClick: () => void;
}

/** View surrounding all help pages to have a same layout on all pages */
export const HelpPage = ({ breadcrumbs, title, children, onHomeClick, onAllTopicClick, loading }: PropsWithChildren<PropsWithLoadingState<IHelpPageProps>>) => {
    const { t } = useTranslation();
    return (
        <Container sx={{ p: 2 }} maxWidth={'lg'}>
            {!loading ?
                <Breadcrumbs maxItems={3}
                    separator={<NavigateNext fontSize="small" />}>
                    <Link underline="hover" color="inherit" onClick={onHomeClick}>
                        <Home sx={{ mr: 0.5 }} fontSize="inherit" />
                        {t('help.breadcrumbs.homeLabel')}
                    </Link>
                    {
                        !title ?
                            <Typography color="text.primary">{t('help.breadcrumbs.allTopicsLabel')}</Typography>
                            :
                            <Link underline="hover" color="inherit" onClick={onAllTopicClick}>
                                {t('help.breadcrumbs.allTopicsLabel')}
                            </Link>
                    }
                    {
                        breadcrumbs.map((b, i) =>
                            <Link key={`help-breadcrumb-${i}`} underline="hover" color="inherit" onClick={b.onClick}>
                                {b.label}
                            </Link>)
                    }
                    {
                        !!title &&
                        <Typography color="text.primary">{title}</Typography>
                    }
                </Breadcrumbs>
                :
                <Breadcrumbs maxItems={2}
                    separator={<NavigateNext fontSize="small" />}>
                    <Skeleton width={30} />
                    <Skeleton width={50} />
                    <Skeleton width={30} />
                </Breadcrumbs>
            }
            {children}
        </Container>
    );
}

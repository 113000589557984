

import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IStepperProps } from './CreateNewListingPageView.types';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ steps, activeStep, children, handleNext, handlePrev, completed }: PropsWithChildren<IStepperProps>) {
    const { t } = useTranslation();
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                {steps.map((step, index) => (
                    <Step key={`${step.label}_${index}`} completed={index < activeStep}>
                        <StepButton color="inherit" disabled>
                            {step.label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div>
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>{steps[activeStep]?.description}</Typography>
                    {children}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handlePrev}
                            sx={{ mr: 1 }}
                        >
                            {t('listing.creation.prevBtn')}
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleNext} sx={{ mr: 1 }} disabled={!steps[activeStep].optional && !completed}>
                            {(activeStep === (steps.length - 1)) ? t('listing.creation.publishBtn') : t('listing.creation.nextBtn')}
                        </Button>
                    </Box>
                </React.Fragment>
            </div>
        </Box >
    );
}

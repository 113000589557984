import React, { useContext, useEffect, useState } from 'react';
import { Box, Checkbox, CheckboxProps, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { ListingCreationFlowContext } from '../../../context/Listing/ListingCreationFlow';
import { ListingCalendarType } from '../../../types/db';
import { t } from 'i18next';
import { isValidCalendar, isSameCalendar, useCohostDiffCalendarCheckOnChangeCallback, useCohostCalendarOnChangeCallback, useCalendarOnBlurCallback, useGuestCalendarOnChangeCallback, useCalendarTypeOnChangeCallback } from '../../../utilities/listings';
import { CalendarInput, CalendarInputFieldName, ICalendarInputProps } from '../../../components/listing/CalendarInput';

/** The calendar page of the listing creation flow */
export const ListingCalendarPage = () => {
    return <Container />
}

type RadioChoice = ListingCalendarType;

const Container = () => {
    const listingCreationFlowContext = useContext(ListingCreationFlowContext);
    if (!listingCreationFlowContext) {
        throw new Error('Need ListingCreationFlowContext')
    }

    const { listing: { calendar, pricing, cohost_perk }, setActivePageInfo } = listingCreationFlowContext;
    // console.log('listing calendar', calendar, cohost_perk);

    useEffect(() => {
        if (pricing?.type === 'non-bookable') {
            //if the listing is non-bookable, let the user skip this step
            setActivePageInfo({}, true);
        } else if (!cohost_perk?.open
            && isValidCalendar(calendar)) {
            //if there isn't cohost perk, only check the guest/general calendar
            setActivePageInfo({}, true)
        } else if (!!cohost_perk?.calendar
            && isValidCalendar(cohost_perk.calendar, true)
            && isValidCalendar(calendar)) {
            //if there cohost perk with a calendar, and its calendar is valid as well as the guest/general calendar
            setActivePageInfo({}, true)
        } else {
            //reset completion
            setActivePageInfo({}, false)
        }
    }, [calendar, cohost_perk, pricing?.type, setActivePageInfo]);

    // The type of calendar
    const calendarType: RadioChoice | undefined = calendar?.type;
    const handleCalendarTypeChange = useCalendarTypeOnChangeCallback(setActivePageInfo)

    // The guest calendar
    const [guestCalendarErrors, setGuestCalendarErrors] = useState<{ [key in CalendarInputFieldName]?: string }>({});
    const guestCalendar: ICalendarInputProps = {
        minNight: {
            value: calendar?.min_night?.toString() || '',
            helperText: guestCalendarErrors['minNight'],
            error: !!guestCalendarErrors['minNight'],
        },
        maxNight: {
            value: calendar?.max_night?.toString() || '',
            helperText: guestCalendarErrors['maxNight'],
            error: !!guestCalendarErrors['maxNight'],
        },
    }
    const handleGuestCalendarChange = useGuestCalendarOnChangeCallback(setActivePageInfo, setGuestCalendarErrors);
    const handleGuestCalendarOnBlur = useCalendarOnBlurCallback(t, setGuestCalendarErrors);

    // The cohost calendar
    const isListingOpenToCohost = !!cohost_perk?.open;
    const isDiffCohostSettings = !!calendar && !!cohost_perk?.calendar && (!isSameCalendar(calendar, cohost_perk.calendar) || Object.keys(cohost_perk.calendar).length === 0);
    const handleDiffCohostCheckOnChange = useCohostDiffCalendarCheckOnChangeCallback(setActivePageInfo);

    const [cohostCalendarErrors, setCohostCalendarErrors] = useState<{ [key in CalendarInputFieldName]?: string }>({});
    const cohostCalendar: ICalendarInputProps = {
        minNight: {
            value: cohost_perk?.calendar?.min_night?.toString() || '',
            helperText: cohostCalendarErrors['minNight'],
            error: !!cohostCalendarErrors['minNight'],
        },
        maxNight: {
            value: cohost_perk?.calendar?.max_night?.toString() || '',
            helperText: cohostCalendarErrors['maxNight'],
            error: !!cohostCalendarErrors['maxNight'],
        }
    };
    const handleCohostCalendarChange = useCohostCalendarOnChangeCallback(setActivePageInfo);
    const handleCohostCalendarOnBlur = useCalendarOnBlurCallback(t, setCohostCalendarErrors)

    if (pricing?.type === 'non-bookable') {
        return (
            <Box>
                <Typography>{t('listing.calendar.nonBookableMessage')}</Typography>
            </Box>
        );
    }

    return <View calendarTypeValue={calendarType}
        onCalendarTypeChange={handleCalendarTypeChange}
        calendarInfo={{
            ...guestCalendar,
            minNight: {
                ...guestCalendar.minNight,
                onChange: (ev) => handleGuestCalendarChange('minNight', ev.target.value),
                onBlur: (ev) => handleGuestCalendarOnBlur(calendar, 'minNight', ev.target.value),
            },
            maxNight: {
                ...guestCalendar.maxNight,
                onChange: (ev) => handleGuestCalendarChange('maxNight', ev.target.value),
                onBlur: (ev) => handleGuestCalendarOnBlur(calendar, 'maxNight', ev.target.value),
            },
        }}
        cohostSpecific={(isListingOpenToCohost && isValidCalendar(calendar)) ? {
            ...cohostCalendar,
            minNight: {
                ...cohostCalendar.minNight,
                onChange: (ev) => handleCohostCalendarChange('minNight', ev.target.value),
                onBlur: (ev) => handleCohostCalendarOnBlur(cohost_perk?.calendar, 'minNight', ev.target.value),
            },
            maxNight: {
                ...cohostCalendar.maxNight,
                onChange: (ev) => handleCohostCalendarChange('maxNight', ev.target.value),
                onBlur: (ev) => handleCohostCalendarOnBlur(cohost_perk?.calendar, 'maxNight', ev.target.value),
            },
            checkbox: {
                checked: isDiffCohostSettings,
                onChange: (_, checked) => handleDiffCohostCheckOnChange(checked),
            }
        } : undefined} />;
}

interface IViewProps {
    calendarTypeValue?: RadioChoice;
    onCalendarTypeChange: (value: RadioChoice) => void;
    calendarInfo: ICalendarInputProps;
    cohostSpecific?: ICalendarInputProps & { checkbox: Pick<CheckboxProps, 'checked' | 'onChange'> };
}

const View = ({ calendarTypeValue, onCalendarTypeChange, calendarInfo, cohostSpecific }: IViewProps) => {
    return (
        <Stack direction={'column'} spacing={2}>
            <FormControl>
                <RadioGroup
                    value={calendarTypeValue || ''}
                    onChange={(ev) => onCalendarTypeChange(ev.target.value as RadioChoice)}
                >
                    <FormControlLabel
                        value={"default-available" as RadioChoice}
                        control={<Radio />}
                        label={t('listing.calendar.type', { context: 'all' })}
                    />
                    <FormControlLabel
                        value={"default-non-available" as RadioChoice}
                        control={<Radio />}
                        label={t('listing.calendar.type', { context: 'none' })}
                    />
                </RadioGroup>
            </FormControl>
            <CalendarInput {...calendarInfo} />
            {!!cohostSpecific &&
                <FormControl>
                    <FormControlLabel control={
                        <Checkbox
                            checked={cohostSpecific.checkbox.checked}
                            onChange={cohostSpecific.checkbox.onChange} />}
                        label={t('listing.calendar.cohostDiffLabel')} />
                    {
                        cohostSpecific.checkbox.checked &&
                        <CalendarInput {...cohostSpecific} />
                    }
                </FormControl>
            }
        </Stack>
    )
}

import React from "react";
import { useTranslation } from "react-i18next";
import { PropsWithLoadingState } from "../../utilities";
import { INewsTopStoryProps, NewsTopStory } from "./NewsTopStory";
import { Grid, Stack, Typography } from "@mui/material";
import { INewsItemProps, NewsItem } from "./NewsItem";

interface INewsProductReleaseScreenProps {
    topStory: INewsTopStoryProps;
    otherStories: INewsItemProps[];
}

/** Component NewsProductReleaseScreen representing the product releases news (list of articles with a top story) */
export const NewsProductReleaseScreen = ({ topStory, otherStories, loading }: PropsWithLoadingState<INewsProductReleaseScreenProps>) => {
    const { t } = useTranslation();
    return (
        <Stack direction={'column'} spacing={1}>
            {!loading ?
                <NewsTopStory {...topStory} />
                :
                <NewsTopStory loading />
            }
            <Grid container p={2} spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h3'>{t('news.productRelease.title')}</Typography>
                </Grid>
                {
                    (!loading ? otherStories : new Array(6).fill({}))
                        .map((story, index) =>
                            <Grid item xs={12} sm={6} md={4} key={`release-story-${index}`}>
                                <NewsItem orientation='vertical'
                                    loading={loading}
                                    {...story} />
                            </Grid>
                        )
                }
            </Grid>
        </Stack>
    )
}
import { format, isDate, parse } from "date-fns";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { FilterKeys, Filters, FilterValues } from "../pages/SearchPage/SearchListingsPage.types";
import { ROUTE_QUERY } from "../utilities/navigation";

/** The format of the date in the route query search */
const DATE_FORMAT = 'yyyy-MM-dd';

/** 
 * The filters present in the url (search params) for listing.
 * Used in search as well as listing details
 */
export const useListingFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleFiltersChange = useCallback((args: Partial<Filters>) => {
        setSearchParams(prev => {
            // console.log('filter change!');
            Object.keys(args).forEach((key) => {
                if (!args[key as FilterKeys]) {
                    prev.delete(key)
                } else {
                    const value = args[key as FilterKeys];
                    // console.log('filter change', value)
                    if (typeof value === 'string') {
                        prev.set(key, value)
                    } else if (typeof value === 'number') {
                        prev.set(key, value.toString())
                    } else if (isDate(value)) {
                        // console.log('filter date', value)
                        prev.set(key, format(value as Date, DATE_FORMAT))
                    } else {
                        // console.log('filter json', value)
                        prev.set(key, JSON.stringify(value))
                    }
                }
            })
            return prev;
        })
    }, [setSearchParams])

    const filterValues: Partial<Filters> = useMemo(() =>
        Object.values(ROUTE_QUERY.listings_search)
            .reduce((prev, key) => {
                const value = searchParams.get(key);
                if (!value) return prev;
                let parsedValue = value as FilterValues[typeof key];

                //parsing specific ones
                if (key === ROUTE_QUERY.listings_search.location) {
                    try {
                        parsedValue = JSON.parse(value) as FilterValues[typeof key];
                    } catch (e) {
                        // console.log("tried parsing", value, e)
                        //will throw for string, so do nothing here
                    }
                }
                if (key === ROUTE_QUERY.listings_search.bounds) {
                    try {
                        parsedValue = JSON.parse(value) as FilterValues[typeof key];
                    } catch (e) {
                        console.warn("tried parsing", value, e)
                        //will throw for string, so do nothing here
                    }
                }
                if (key === ROUTE_QUERY.listings_search.check_in_date
                    || key === ROUTE_QUERY.listings_search.check_out_date) {
                    parsedValue = parse(value, DATE_FORMAT, 0) as FilterValues[typeof key]
                }
                if (key === ROUTE_QUERY.listings_search.room_number
                    || key === ROUTE_QUERY.listings_search.adults_number
                    || key === ROUTE_QUERY.listings_search.children_number
                    || key === ROUTE_QUERY.listings_search.infants_number
                    || key === ROUTE_QUERY.listings_search.pets_number) {
                    parsedValue = Number(value) as FilterValues[typeof key]
                }

                return {
                    ...prev,
                    [key]: parsedValue
                }
            }, {})
        // only want to know if the search params themselves changed not the whole searchParams with functions
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [searchParams.toString()]
    )

    return {
        filters: filterValues,
        onFiltersChange: handleFiltersChange,
    }
}
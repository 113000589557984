import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { ERole, getDBUserRoleFrom, getStringKeyForUserRole, getUIUserRoleFrom } from "../EnumUtility";
import { UserContext } from "../../context/UserInformation";
import { updateUserPreferencesInfo } from "../../services/user";

export const UserRoleSelector = () => {
    const { t } = useTranslation();
    const [value, setValue] = useState<ERole | "">("");
    const userContext = useContext(UserContext);
    const userId = userContext?.user?.firebaseUser?.uid;

    useEffect(() => {
        const userRole = userContext?.user?.role;
        if (userRole === true) return;
        setValue(!!userRole ? getUIUserRoleFrom(userRole) || '' : '');
    }, [userContext?.user?.role]);

    const handleOnChange = (event: SelectChangeEvent<ERole | "">) => {
        const newValue = event.target.value as ERole | "";
        // setValue(newValue);
        if (!userId) return;
        if (!newValue) {
            updateUserPreferencesInfo(userId, { role: '' })
        } else {
            updateUserPreferencesInfo(userId, { role: getDBUserRoleFrom(newValue)! })
        }
    }

    if (!value) {
        return null;
    }

    return (
        <Select
            sx={{ color: 'white' }}
            value={value}
            onChange={handleOnChange}
            inputProps={{
                name: "role",
                id: "user-role"
            }}
            variant={"outlined"}
            size={"small"}
        >
            <MenuItem value="">
                <em>Reset</em>
            </MenuItem>
            <MenuItem value={ERole.HOST}>{t(getStringKeyForUserRole(ERole.HOST))}</MenuItem>
            <MenuItem value={ERole.COHOST}>{t(getStringKeyForUserRole(ERole.COHOST))}</MenuItem>
            <MenuItem value={ERole.GUEST}>{t(getStringKeyForUserRole(ERole.GUEST))}</MenuItem>
        </Select>
    );
}
import React from 'react';
import { useTranslation } from "react-i18next";
import { PropsWithLoadingState } from "../../utilities";
import { Box, Button, Container, Skeleton, Stack, Typography } from "@mui/material";
import { INewsItemProps } from './NewsItem';


export interface INewsTopStoryProps extends INewsItemProps { }

/** Component representing the top story displayed at the top of the list */
export const NewsTopStory = ({
    title,
    subtitle,
    timestamp,
    image,
    onClick,
    loading,
}: PropsWithLoadingState<INewsTopStoryProps>) => {
    const { t } = useTranslation();
    return (
        <Container sx={{ p: 3 }}>
            <Stack direction={{ xs: 'column-reverse', sm: 'row' }} justifyContent={'center'} alignItems={'stretch'} spacing={1}>
                <Stack direction={'column'} justifyContent={'center'} alignItems={'stretch'} flex={1} spacing={1}>
                    <Typography variant='subtitle2' textAlign={'center'}>{!loading ? timestamp.toDateString() : <Skeleton />}</Typography>
                    <Typography variant='h3' textAlign={'center'}>{!loading ? title : <Skeleton />}</Typography>
                    <Typography variant='h4' textAlign={'center'}>{!loading ? subtitle : <Skeleton />}</Typography>
                    <Button onClick={onClick} variant={'contained'} disabled={!!loading}>{t('news.topStory.readMoreButton')}</Button>
                </Stack>
                {!loading ?
                    <Box sx={{ flex: 1, aspectRatio: 2 / 1, width: '100%', height: '100%', borderRadius: 3, overflow: 'hidden' }}>
                        <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={image} alt={title} />
                    </Box>
                    :
                    <Skeleton variant='rectangular' sx={{ flex: 1, aspectRatio: 2 / 1, width: '100%', height: '100%', borderRadius: 3 }} />
                }
            </Stack>
        </Container>
    )
}
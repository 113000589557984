import React from "react";
import { useTranslation } from "react-i18next";
import { AccountBaseInfo, IAccountBaseInfoProps } from "../../../components/user/account/AccountBaseInfo";
import { getTitleFromAccountPage } from "../../../components/user/account/AccountPageView";
import { ROUTE_SUBPATH } from "../../../utilities/navigation";
import { AccountFinancesContainer } from "../AccountFinancesContainer";
import { AccountLoginContainer } from "../AccountLoginContainer";
import { AccountNotificationsContainer } from "../AccountNotificationsContainer";
import { AccountPersonalInfoContainer } from "../AccountPersonalInfoContainer";
import { AccountPreferencesContainer } from "../AccountPreferencesContainer";
import { AccountPrivacyContainer } from "../AccountPrivacyContainer";
import { AccountReferralsContainer } from "../AccountReferralsContainer";
import { IAccountSubContainerProps } from "./AccountSubContainer.type";

const getContainerForSubPage = (subPage: string): (props: IAccountSubContainerProps) => JSX.Element => {
    if (subPage === ROUTE_SUBPATH.account_perso) {
        return AccountPersonalInfoContainer;
    }
    if (subPage === ROUTE_SUBPATH.account_login) {
        return AccountLoginContainer;
    }
    if (subPage === ROUTE_SUBPATH.account_finance) {
        return AccountFinancesContainer;
    }
    if (subPage === ROUTE_SUBPATH.account_notifs) {
        return AccountNotificationsContainer;
    }
    if (subPage === ROUTE_SUBPATH.account_privacy) {
        return AccountPrivacyContainer;
    }
    if (subPage === ROUTE_SUBPATH.account_prefs) {
        return AccountPreferencesContainer;
    }
    if (subPage === ROUTE_SUBPATH.account_referrals) {
        return AccountReferralsContainer;
    }
    return () => <></>;// should not happen as the page is checked/validated in the AccountSubPage
}

/** Container which takes care of selecting which container depending on the sub page */
export const AccountSubContainer = ({ subPage, userId }: Omit<IAccountSubContainerProps, 'children'>) => {
    const { t } = useTranslation();

    const renderContent = (props: Pick<IAccountBaseInfoProps, "items" | "helpItems">) =>
        <AccountBaseInfo
            title={getTitleFromAccountPage(t, subPage)}
            {...props} />;

    const Container = getContainerForSubPage(subPage);

    return (
        <Container subPage={subPage} userId={userId}>
            {renderContent}
        </Container>
    );
}
import { useEffect, useState } from "react";
import { rtdb } from "../../services/firebase";
import { ChatMessage, CHAT_MESSAGES } from "../../types/db/chat";

/** Message info is the info of the message within channel with the message id */
type MessageInfo = ChatMessage & { id: string };

/** The default limit of messages to fetch */
const DEFAULT_LIMIT_TO_LAST = 50;

/**
 * Hook which takes care of fetching and updating the list of message in the thread
 * @param channelId The channel ID of the channel
 * @returns the array of messages in the thread
 */
export const useThreadMessages = (channelId: string): {
    /** The messages in the thread */
    messages: MessageInfo[];
    /** If any action is currently loading */
    isLoading?: boolean;
    /** If any action ended up with an error */
    error?: string;
} => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [messages, setMessages] = useState<MessageInfo[]>([]);

    useEffect(() => {
        //fetch the conversation in the thread
        const fetchThread = rtdb.ref(CHAT_MESSAGES).child(channelId);

        //subscribe to any added child of that conversation
        fetchThread.limitToLast(DEFAULT_LIMIT_TO_LAST)
            .on("child_added", (snapshot) => {
                const messageInfo: ChatMessage = snapshot.val();
                const messageID = snapshot.key!;
                setIsLoading(false);
                // console.log('child_added', messageInfo)
                // append the message on the page
                setMessages(currentItems => [
                    ...currentItems,
                    {
                        id: messageID,
                        ...messageInfo,
                    }
                ]);
            });

        fetchThread.on("child_changed", (snapshot) => {
            const messageInfo: ChatMessage = snapshot.val();
            const messageID = snapshot.key!;
            // console.log('child_changed', messageInfo)
            // modify the item on the list
            setMessages(currentItems => {
                const index = currentItems.findIndex(item => item.id === messageID);
                if (index === -1) {
                    //if not found in the list, return as is
                    return currentItems;
                }

                return [
                    ...currentItems.slice(0, index),
                    {
                        id: messageID,
                        ...messageInfo,
                    },
                    ...currentItems.slice(index + 1),
                ]
            });
        })

        fetchThread.on("child_removed", (snapshot) => {
            // const messageInfo: ChatMessage = snapshot.val();
            const messageID = snapshot.key!;
            // console.log('child_removed', messageInfo)
            //remove the channel from the channel list
            setMessages(currentItems =>
                currentItems.filter(item => item.id !== messageID)
            );
        })

        return () => {
            fetchThread.off();//disconnect the existing listener
            setMessages([]);//reset the message list (channelID changed)
        };
    }, [channelId]);

    useEffect(() => {
        //from the nature of the subscription, it is possible that the user doesn't have channels
        //so `setIsLoading(false)` never called, therefore we add a timeout
        setTimeout(() => setIsLoading(false), 1000)
    }, []);

    return {
        messages,
        isLoading,
        error,
    };
}

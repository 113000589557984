import React from 'react';
import { useParams } from "react-router"
import { ROUTE_PARAMS } from "../../utilities/navigation"
import { IssueStateView } from '../../components/IssueStateView';
import { HelpArticleContainer } from '../../containers/help';
import { useHelpCenterNavigation } from '../../hooks/useHelpCenterNavigation';

export const HelpArticlePage = () => {
    const { navigateToTopic, navigateToArticle, navigateToAllTopics } = useHelpCenterNavigation();
    const { [ROUTE_PARAMS.articleID]: articleId } = useParams();

    if (!articleId) {
        return <IssueStateView
            title={'No article'}
            description={'There is no article here. Browse the topic to find what you are looking for.'}
            button={{
                text: 'Go to all topics',
                onActionClick: navigateToAllTopics
            }}
        />
    }

    return <HelpArticleContainer articleId={articleId}
        onTopicClick={navigateToTopic}
        onArticleClick={navigateToArticle} />
}

import locales, { LocaleType } from './locales';
import defaultLocales from './default-locales';
import symbols from './symbols';
import { CURRENCY_CODES, LOCALE_CODES } from './code';

/** The default currency used through the code */
export const DEFAULT_CURRENCY: typeof CURRENCY_CODES[number] = 'USD';

export const SUPPORTED_CURRENCIES: (typeof CURRENCY_CODES[number])[] = ['USD', 'CAD', 'EUR'];

type ArgsType = {
    quantity: number,
    currency?: typeof CURRENCY_CODES[number] | string,
    symbol?: string,
    locale?: string,
    decimal?: string,
    group?: string,
    pattern?: string,
}

type EncodedPattern = {
    decimalPlaces: number;
    frontPadding: string;
    backPadding: string;
    groupLengths: number[];
    zeroLength: number;
}

type Format = EncodedPattern & Pick<ArgsType, 'symbol' | 'decimal' | 'group'>;

// encodePattern Function - returns a few simple characteristics of the pattern provided
const encodePattern = (pattern: string): EncodedPattern => {
    let decimalPlaces = 0;
    let frontPadding = '';
    let backPadding = '';
    let groupLengths = [];

    let patternStarted = false;
    let decimalsStarted = false;
    let patternEnded = false;

    let currentGroupLength = 0;
    let zeroLength = 0;

    for (var i = 0; i < pattern.length; ++i) {
        var c = pattern[i];

        if (!patternStarted && ['#', '0', ',', '.'].indexOf(c) > -1) {
            patternStarted = true;
        }

        if (!patternStarted) { frontPadding += c; }

        switch (c) {
            case '#':
                ++currentGroupLength;
                break;

            case '0':
                if (decimalsStarted) { ++decimalPlaces; }
                else { ++currentGroupLength; ++zeroLength; }
                break;

            case ',':
                groupLengths.push(currentGroupLength);
                currentGroupLength = 0;
                break;

            case '.':
                groupLengths.push(currentGroupLength);
                decimalsStarted = true;
                break;
        }

        if (patternStarted && !(['#', '0', ',', '.'].indexOf(c) > -1)) {
            patternEnded = true;

            if (!decimalsStarted) {
                groupLengths.push(currentGroupLength);
            }
        }

        if (patternEnded) { backPadding += c; }
    }

    return {
        decimalPlaces,
        frontPadding,
        backPadding,
        groupLengths,
        zeroLength
    };
};

// Helper Functions
const toFixed = (n: number, precision: number) => (+(Math.round(+(n + 'e' + precision)) + 'e' + -precision)).toFixed(precision);

// Zero Padding helper function
var pad = (n: number | string, width: number) => {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}

// Format function
const format = (n: number, f: Format) => {
    var formattedNumber = toFixed(Math.abs(n), f.decimalPlaces);

    var splitNumber = formattedNumber.split(".");

    var segment = "";
    // i.e. we actually have some sort of grouping in the values
    if (f.groupLengths.length > 1) {
        var cursor = splitNumber[0].length;
        var groupIndex = f.groupLengths.length - 1;

        while (cursor > 0) {
            if (groupIndex <= 0) { groupIndex = 1; } // Always reset to the first group length if the number is big

            var currentGroupLength = f.groupLengths[groupIndex];

            var start = cursor - currentGroupLength;

            segment = splitNumber[0].substring(start, cursor) + f.group + segment;

            cursor -= currentGroupLength;

            --groupIndex;
        }

        segment = segment.substring(0, segment.length - 1);
    }

    if (segment.length < f.zeroLength) { segment = pad(segment, f.zeroLength); }

    return (f.frontPadding + segment + (!splitNumber[1] ? '' : (f.decimal + splitNumber[1])) + f.backPadding)
        .replace('!', f.symbol!);
};

const getFormatter = (options: Omit<ArgsType, 'quantity'>) => {

    // Perform checks on inputs and set up defaults as needed (defaults to en, USD)
    if (!options) { options = {}; }

    const currency = (options.currency?.toUpperCase() ?? DEFAULT_CURRENCY) as typeof CURRENCY_CODES[number];
    let locale = !options.locale ?
        locales[defaultLocales[currency as typeof CURRENCY_CODES[number]]]
        : locales[options.locale as typeof LOCALE_CODES[number]];

    if ("h" in locale) locale = locales[locale.h] as LocaleType; // Locale inheritance

    let symbol = options.symbol || symbols[currency] || currency;// In case we don't have the symbol, just use the ccy code

    const pattern = options.pattern || locale.p;
    const decimal = options.decimal || locale.d;
    const group = options.group || locale.g;

    // Use encode function to work out pattern
    var patternArray = pattern.split(";");
    var positiveFormat: Format = {
        ...encodePattern(patternArray[0]),
        symbol,
        decimal,
        group,
    };

    var negativeFormat: Format = {
        ...(!patternArray[1] ? encodePattern("-" + patternArray[0]) : encodePattern(patternArray[1])),
        symbol,
        decimal,
        group,
    };

    var zeroFormat = patternArray[2] || format(0, positiveFormat);

    return (n: number) => {
        let formattedNumber;
        n = Number(n);
        if (n > 0) { formattedNumber = format(n, positiveFormat); }
        else if (n === 0) { formattedNumber = zeroFormat.replace('!', symbol); }
        else { formattedNumber = format(n, negativeFormat); }
        return formattedNumber;
    };
};

export const currencyFormatter = ({
    quantity,
    currency = DEFAULT_CURRENCY,
    symbol,
    locale,
    decimal,
    group,
    pattern,
}: ArgsType) => {

    const format = (number: number, options: Omit<ArgsType, 'quantity'>) => {
        const formatterFunction = getFormatter(options);

        return formatterFunction(number);
    }

    return (format(quantity, {
        currency,
        symbol,
        locale,
        decimal,
        group,
        pattern
    }));
};

export const getCurrencySymbol = (currency: (typeof CURRENCY_CODES[number] | string) = DEFAULT_CURRENCY) => {
    const formattedCurrency = currency.toUpperCase() as typeof CURRENCY_CODES[number]
    return symbols[formattedCurrency] || formattedCurrency;
}
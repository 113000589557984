/** The types for all careers post related db (firestore+realtime) objects */
/** NOTE: all change should be done in the shared_type folder 
 * and run `npm run build:shared_types` in root project */

/******************************************
 * CAREERS DB FIRESTORE
 * |careers //collection containing all position
 * -|applications //applications to the position
 * -|testimonies //the testimonies from those who work(ed) at this position
 ******************************************/

/** The root collection for the forums data in the DB */
export const CAREERS_COLLECTION = "careers";

/** The salary period, specific pre-determined or custom */
export type TSalaryPeriod = 'hourly' | 'weekly' | 'bi-weekly' | 'monthly' | 'bi-monthly' | string;
/** The type of job position */
export type TJobType = 'full-time' | 'part-time' | 'short-term' | 'permanent' | 'internship';
/** The type of shift schedule */
export type TShiftSchedule = '8h' | 'day-shift' | 'night-shift' | 'M-F' | 'S-S' | 'on-call';
/** To separate your job listings by domain or field
1. **Information Technology (IT):** This category can include job listings related to software development, network administration, cybersecurity, data analysis, and other IT-related roles.
2. **Sales and Marketing:** Include positions in sales, marketing, advertising, public relations, and market research in this category.
3. **Finance and Accounting:** Group together jobs related to accounting, financial analysis, investment, and banking.
4. **Healthcare and Medicine:** This category can encompass positions in healthcare, nursing, medicine, pharmaceuticals, and medical research.
5. **Engineering:** Include various engineering disciplines, such as mechanical, electrical, civil, and software engineering.
6. **Customer Service and Support:** Group customer service representatives, technical support, and help desk positions in this category.
7. **Education and Training:** List teaching, training, and educational support roles, including K-12, higher education, and corporate training.
8. **Human Resources:** Include roles related to recruitment, talent management, HR administration, and employee relations.
9. **Manufacturing and Production:** This category can include positions in manufacturing, production, quality control, and supply chain management.
10. **Research and Development (R&D):** List positions in research and development across various industries, such as pharmaceuticals, technology, and scientific research.
11. **Design and Creative:** Group together jobs in graphic design, web design, user experience (UX) design, and other creative roles.
12. **Legal and Compliance:** Include legal and compliance-related positions, such as lawyers, paralegals, and compliance officers.
13. **Retail and Hospitality:** List positions related to retail sales, restaurant management, and the hospitality industry.
14. **Project Management:** Group project management, program management, and product management roles in this category.
15. **Consulting and Strategy:** Include positions in management consulting, strategic planning, and business analysis.
16. **Social Services and Nonprofit:** Group jobs related to social work, counseling, and nonprofit organizations in this category.
17. **Government and Public Service:** List government-related positions, including civil service roles, public administration, and government policy.
18. **Media and Communications:** Include jobs in journalism, public relations, advertising, and media production.
19. **Science and Environment:** Group together positions in various scientific disciplines, environmental science, and sustainability.
20. **Other/General:** Use this category for roles that do not fit neatly into the other categories.
*/
export type TDomain = 'it' | 'sales-marketing' | 'finance-accounting' | 'engineering' | 'support' | 'hr' | 'RnD' | 'design' | 'legal' | 'retail-hospitality' | 'management' | 'consulting-strategy' | 'media-communication' | 'others';

export type CareersJob = {
    /** The title of the job listing */
    title: string;
    /** The description of the job listing */
    description: string;
    /** The domain this job is in (support/communication/software/IT/...) */
    domain: TDomain;
    /** (Optional) Media url (img/vid/gif) for the job listing */
    media?: string;
    /** (Optional) The original language of the job details (so can be translated to user's language if needed), default to 'en' */
    original_language: string;
    /** (Optional) Boolean indicating if there is a pending offer on this job listing. @default false */
    is_pending_offer?: boolean;
    /** If the job listing is currently published. @default false */
    is_published?: boolean;
    /** The list of keywords (this will not be displayed, just interesting for interviewers) */
    keywords?: string[];
    /** The general location of the job listing. string format {city}, {country} */
    location: string;
    /** The salary range for this position */
    salary_range: [number, number];
    /** The 3-letter salary currency (to format the salary_range info) */
    salary_currency: string;
    /** The salary period (per hour/week/.../other) */
    salary_period: TSalaryPeriod;
    /** The number of position for this job listing. @default 1 */
    number_position?: number;
    /** The type of position */
    type: TJobType[];
    /** The list of key responsibilities */
    key_responsibilities: string[];
    /** The list of key knowledge : Education, Training & Experience */
    key_knowledge: string[];
    /** The list of key skills : Skills and Abilities */
    key_skills: string[];
    /** The list of key benefits of this position */
    key_benefits: string[];
    /** The shift schedules */
    shift_schedules?: TShiftSchedule[];
    /** (Optional) The person that this report to. (Ex: Executive Director) */
    report_to?: string;
    /** The description of the team */
    team_info?: string;
    /** The deadline for any application to the job (if not provided no specific deadline, available until filled) */
    application_deadline?: any//timestamp
    /** If the job is remote (default to true as no physical office at this time) */
    is_remote?: boolean;
    /** Description if any travel requirement is needed */
    travel_requirement?: string;
    /** (Optional) List of development opportunities within this job */
    development_opportunities?: string[];
    /** The interview processes */
    processes?: string[];
}

/** The applications to the job position collection */
export const CAREERS_APPLICATIONS_COLLECTION = "applications";

export type CareerApplication = {
    /** The applicant email information */
    applicant_email: string;
    /** The applicant link to CV of linkedIn */
    applicant_link: string;
    /** The timestamp when the application was created */
    timestamp: number;//FirebaseFirestore.Timestamp
}

/** The testimonies to the job listing collection */
export const CAREERS_TESTIMONIES_COLLECTION = "testimonies";

export type CareerTestimonies = {
    /** The author of the testimony */
    author: {
        /** Their display name */
        display_name: string;
        /** Their avatar */
        avatar_url: string;
        /** The link to their LinkedIn */
        link?: string;
    };
    /** The content of the testimony */
    content: string;
    /** The timestamp when the testimony was created */
    timestamp: number;//FirebaseFirestore.Timestamp
}

import { Grid } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { addPictureToListing, deletePictureFromListing } from '../../../services/storage';
import { addPhotoToListing, deletePhotoFromListing, getListingPhotos } from '../../../services/listing';
import { ListingCreationFlowContext } from '../../../context/Listing';
import { ListingImageThumbnail } from '../../../components/listing/ListingImageThumbnail';

type FileImage = {
    preview: string;
    name: string;
    description: string;
    photoRef?: any;
}

/** The page for the pictures of a listing in the creation flow */
export const ListingPicturesPage = () => {
    return <Container />;
}

/** The container taking care for the picture of a listing in the creation flow */
const Container = () => {
    const listingCreationFlowContext = useContext(ListingCreationFlowContext);
    if (!listingCreationFlowContext) {
        throw Error('Need listingCreationFlowContext')
    }

    const { listing, setActivePageInfo } = listingCreationFlowContext;

    const [files, setFiles] = useState<({ percent: number, error?: Error } & FileImage)[]>([]);

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!listing.ref) return;
        getListingPhotos(listing.ref)
            .then((photos) => {
                setFiles(photos.map(p => ({
                    percent: 100,
                    name: p.name,
                    description: p.description || '',
                    preview: p.url,
                    photoRef: p.ref,
                })))
            });
        //Only check for the change of the listing id (string) because we don't want to have infinite loop on object ref
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listing.id]);

    useEffect(() => {
        if (files.some(file => !!file.photoRef)) {
            const first = files[0];
            setActivePageInfo({
                main_photo: {
                    name: first.name,
                    description: first.description,
                    url: first.preview,
                }
            }, true);
        } else {
            setActivePageInfo({}, false);
        }
    }, [files, setActivePageInfo]);


    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject } = useDropzone({
            noDrag: false,
            accept: {
                'image/*': ['.jpeg', '.png']
            },
            onDrop: acceptedFiles => {
                acceptedFiles.forEach((acceptedFile) => {
                    if (!listing?.ref || !listing?.id) return;
                    addPictureToListing(listing.id, acceptedFile, (percent, finalInfo, error) => {
                        if (!!error) {
                            setFiles(files => files.map((file) => {
                                if (file.name === acceptedFile.name) {
                                    return {
                                        ...file,
                                        percent: 0,
                                        error: error,
                                    }
                                }
                                return file;
                            }));
                        } else if (percent < 100) {
                            setFiles(files => files.map((file) => {
                                if (file.name === acceptedFile.name) {
                                    return {
                                        ...file,
                                        percent,
                                    }
                                }
                                return file;
                            }));
                        } else if (!!finalInfo) {
                            addPhotoToListing(
                                {
                                    url: finalInfo.url,
                                    name: finalInfo.name,
                                },
                                listing.ref || '')
                                .then((photoRef) => {
                                    setFiles(files => files.map((file) => {
                                        if (file.name === acceptedFile.name) {
                                            return {
                                                ...file,
                                                name: finalInfo.name,
                                                preview: finalInfo.url,
                                                percent: 100,
                                                photoRef
                                            }
                                        }
                                        return file;
                                    }));
                                });
                        }
                    });
                });

                setFiles(files => ([
                    ...files,
                    ...acceptedFiles.map(file => ({
                        preview: URL.createObjectURL(file),
                        name: file.name,
                        description: file.name,
                        percent: 0,
                    }))]));
            }
        });

    const handleDeletePicture = (file: FileImage) => {
        !!listing.id
            && !!file.photoRef
            && deletePictureFromListing(listing.id, file.name)
                .then(() => deletePhotoFromListing(file.photoRef))
                .then(() => setFiles(files => files.filter(f => f.name !== file.name)))
    }

    const style = useMemo(() => ({
        ...{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        },
        ...(isFocused ? { borderColor: '#2196f3' } : {}),
        ...(isDragAccept ? { borderColor: '#00e676' } : {}),
        ...(isDragReject ? { borderColor: '#ff1744' } : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <section>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            {/* Note: find a better way to fit height properly than rowSpacing */}
            <Grid container spacing={2} rowSpacing={4} my={2} justifyContent={'center'} alignItems={'stretch'}>
                {
                    files.map((file) =>
                        <Grid item key={file.name} xs={12} sm={6} md={3}>
                            <ListingImageThumbnail
                                name={file.name}
                                description={file.description}
                                url={file.preview}
                                percent={file.percent}
                                onDeleteClick={!!file.photoRef ? () => handleDeletePicture(file) : undefined}
                            />
                        </Grid>
                    )
                }
            </Grid>
        </section>
    )
}

//All the storage path and storage path helper

/** Generic path builder */
const buildPath = (rootPath: string, id: String, fileName?: string): string =>
(!fileName ?
    "/" + [rootPath, id].join('/')
    : [buildPath(rootPath, id), fileName].join('/'));

/** The storage path for all listings' pictures */
export const LISTINGS_PATH = 'listings';

export const buildListingPath = (listingId: string, fileName?: string) =>
    buildPath(LISTINGS_PATH, listingId, fileName);

/** The storage path for all users' avatars */
export const USERS_PATH = 'users';

export const buildUserPath = (userId: string, fileName?: string) =>
    buildPath(USERS_PATH, userId, fileName);

/** The storage path for all attachments for chat messages */
export const CHAT_MESSAGES_PATH = 'messages';

export const buildChatMessagePath = (messageId: string, fileName?: string) =>
    buildPath(CHAT_MESSAGES_PATH, messageId, fileName);

/** The storage path for help center articles */
export const HELP_ARTICLES_PATH = 'help/articles';

export const buildHelpArticlePath = (articleId: string, language_code?: string) =>
    buildPath(HELP_ARTICLES_PATH, articleId, `${language_code || "en"}.md`);

/** The storage path for attachments in reviews */
export const REVIEWS_PATH = "reviews";

export const buildReviewPath = (reviewId: string, fileName?: string) =>
    buildPath(REVIEWS_PATH, reviewId, fileName);

/** The storage path for events's pictures */
export const EVENTS_PATH = "events";

export const buildEventPath = (eventId: string, fileName?: string) =>
    buildPath(EVENTS_PATH, eventId, fileName);

/** The storage path for help center articles */
export const NEWS_STORIES_PATH = 'news/stories';

export const buildNewsStoryPath = (storyId: string) =>
    buildPath(NEWS_STORIES_PATH, storyId);

export const buildNewsStoryContentPath = (storyId: string, language_code?: string) =>
    buildPath(NEWS_STORIES_PATH, storyId, `${language_code || "en"}.md`);

export const buildNewsStoryAssetsPath = (storyId: string, fileName?: string) =>
    buildPath(NEWS_STORIES_PATH, storyId + '/assets', fileName);

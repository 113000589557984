import { useState, useEffect } from "react";
import firebase from "firebase/app";
import { rtdb } from "../../services/firebase";
import { ChatUser, ChatChannelMember, CHAT_CHANNELS, CHAT_USERS, CHAT_CHANNEL_MEMBERS } from "../../types/db/chat";
import { removeKeyFromObject } from "../../utilities";

/** Channel member info is the info of the user, its role in the channel and its info, with the id of the user */
type ChannelMember = ChatChannelMember & ChatUser;

/**
 * Hook which takes care of fetching and keeping up to date the member list of a channel
 * @param channelId The channelId of the channel
 * @return an object with key as user id and value member info
 */
export const useThreadMembers = (channelId: string): {
    /** The data of the members of a chat (put it as object for fast retrieval) */
    members: { [id: string]: ChannelMember };
    /** If any action is currently loading */
    isLoading?: boolean;
    /** If any action ended up with an error */
    error?: string;
} => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    //put it as object for fast retrieval
    const [members, setMembers] = useState<{ [id: string]: ChannelMember }>({});

    useEffect(() => {
        //fetch the members of the thread
        const fetchMembers = rtdb.ref(CHAT_CHANNELS).child(channelId).child(CHAT_CHANNEL_MEMBERS);
        var fetchUserRefs: { [key: string]: firebase.database.Reference } = {};

        //subscribe to any added child of that member list
        fetchMembers.on("child_added", async (snapshot) => {
            const memberInfo: ChatChannelMember = snapshot.val();
            const memberId = snapshot.key!;
            //save the reference of the user information
            fetchUserRefs[memberId] = rtdb.ref(CHAT_USERS).child(memberId);

            //subscribe to change
            fetchUserRefs[memberId].on("child_changed", (snapshot) => {
                const userInfo: ChatUser = snapshot.val();
                console.log("user changed", userInfo)
                // apply the change on the member
                setMembers(currentItems => ({
                    ...currentItems,
                    [memberId]: {
                        ...currentItems[memberId],
                        [snapshot.key!]: userInfo,
                    },
                }));
            });

            var userInfo: ChatUser = { displayName: "", avatar: "", status: { lastChanged: undefined, state: 'offline' } };
            try {
                userInfo = (await fetchUserRefs[memberId].get()).val();
            } catch (e) {
                console.error(e);
            }

            // append the member
            setMembers(currentItems => ({
                ...currentItems,
                [memberId]: { ...memberInfo, ...userInfo },
            }));
        });

        //subscribe to any deleted child of that member list
        fetchMembers.on("child_removed", (snapshot) => {
            const userID = snapshot.key!;
            //unsubscribe from user info
            fetchUserRefs[userID]?.off();
            //remove from the list of refs
            fetchUserRefs = removeKeyFromObject(fetchUserRefs, userID);
            // remove the member
            setMembers(currentItems => ({
                ...removeKeyFromObject(currentItems, userID)
            }));
        });

        fetchMembers.on("child_changed", (snapshot) => {
            const changedMember: ChatChannelMember = snapshot.val();
            // change the member info
            setMembers(currentItems => ({
                ...currentItems,
                [snapshot.key!]: {
                    ...currentItems[snapshot.key!],
                    ...changedMember
                },
            }));
        })

        return () => {
            //disconnect the existing listener for members
            fetchMembers.off('child_added');
            //disconnect all user info refs
            Object.values(fetchUserRefs).forEach(ref => ref.off());
            //reset the member list (channelID changed)
            setMembers({});
        };
    }, [channelId]);

    useEffect(() => {
        //from the nature of the subscription, it is possible that the user doesn't have channels
        //so `setIsLoading(false)` never called, therefore we add a timeout
        setTimeout(() => setIsLoading(false), 1000)
    }, []);

    return {
        members,
        isLoading,
        error,
    }
}

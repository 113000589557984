import React from 'react';
import { useTranslation } from 'react-i18next';
import { HelpCategories } from '../../../components/help/HelpCategories';
import { HelpPage } from '../../../components/help/HelpPage';
import { EAudience } from '../../../types/db/help';
import { getNameForAudience } from '../../../utilities/help';
import { ROUTE_PATH } from '../../../utilities/navigation';
import { HelpTopicsContainer, IHelpTopicsContainerProps } from '../HelpTopicsContainer';

export interface IHelpCategoriesContainerProps
    extends Pick<IHelpTopicsContainerProps, 'onTopicClicked'> {
    selectedAudience: EAudience;
    onAudienceSelected: (audience: EAudience) => void;
}

export const HelpCategoriesContainer = ({
    selectedAudience,
    onAudienceSelected,
    onTopicClicked,
}: IHelpCategoriesContainerProps) => {
    const { t } = useTranslation();
    return (
        <HelpPage breadcrumbs={[]}
            onHomeClick={() => window.location.assign(ROUTE_PATH.home)}
            onAllTopicClick={() => window.location.assign(ROUTE_PATH.help.topicBuild())}>
            <HelpCategories
                selectedCategory={selectedAudience}
                categories={
                    Object.values(EAudience)
                        .map(audience => ({
                            value: audience, title: getNameForAudience(t, audience)
                        }))}
                onCategoryChange={onAudienceSelected}>
                <HelpTopicsContainer
                    audience={selectedAudience}
                    onTopicClicked={onTopicClicked} />
            </HelpCategories>
        </HelpPage>
    );
}
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Edit } from "@mui/icons-material";
import { Stack, Typography, IconButton, Button } from "@mui/material";

interface IListingEditSectionViewProps {
    /** The title of the section */
    title: string;
    /** (Optional) The description of the section (show when on edit mode) */
    description?: string;
    /** Boolean indicating if the view is on edit mode */
    isEditMode?: boolean;
    /** Callback on edit clicked, button doesn't show if not provided */
    onEditClick?: () => void;
    /** Callback on cancel clicked, cancel any changes done (ie: don't save) */
    onCancelClick?: () => void;
    /** Callback on save data click, save changes done */
    onSaveClick?: () => void;
    /** Boolean if currently loading */
    isLoading?: boolean;
    /** Boolean indicating if save is disabled */
    isSaveDisabled?: boolean;
    /** The level of the section */
    level?: 'L1' | 'L2';
}

export const ListingEditSectionView = ({ title, description, isEditMode, onEditClick, onCancelClick, onSaveClick, isSaveDisabled, level = 'L2', children }: PropsWithChildren<IListingEditSectionViewProps>) => {
    const { t } = useTranslation();
    return (
        <Stack direction={'column'} spacing={1}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant={level === 'L1' ? 'h3' : 'h4'}>{title}</Typography>
                {!!onEditClick && !isEditMode && <IconButton onClick={onEditClick} title={t('edit')}><Edit /></IconButton>}
                {isEditMode && !!onCancelClick && <Button onClick={onCancelClick}>{t('cancel')}</Button>}
            </Stack>
            {isEditMode && <Typography>{description}</Typography>}
            {children}
            {isEditMode && !!onSaveClick && <Button variant='contained' onClick={onSaveClick} disabled={isSaveDisabled}>{t('save')}</Button>}
        </Stack>
    );
}
import React, { PropsWithChildren } from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { IStepperProps } from './CreateNewListingPageView.types';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ steps, activeStep, handleNext, handlePrev, children, completed }: PropsWithChildren<IStepperProps>) {
    const { t } = useTranslation();
    const theme = useTheme();
    const maxSteps = steps.length;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'background.default',
                }}
            >
                <Typography>{steps[activeStep]?.label}</Typography>
                <Box sx={{ width: '100%', p: 2 }}>
                    <Typography sx={{ mt: 2, mb: 1 }}>{steps[activeStep]?.description}</Typography>
                    {children}
                </Box>
            </Paper>
            <MobileStepper
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={!steps[activeStep].optional && !completed}
                    >
                        {activeStep === maxSteps - 1 ? t('listing.creation.publishBtn') : t('listing.creation.nextBtn')}
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handlePrev} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        {t('listing.creation.prevBtn')}
                    </Button>
                }
            />
        </Box>
    );
}
import React from 'react';
import { Avatar, Skeleton, Stack, Typography } from '@mui/material';
import stylesFn from './ChatThreadMessageItem.style';
import { IChatThreadMessageItemProps } from './ChatThreadMessageItem.type';
import { trimUserRole } from './ChatThreadMessageItem.utils';
import { PropsWithLoadingState } from '../../../utilities';

/** The message item in a thread */
export const ChatThreadMessageItem = ({ avatar, displayName, userRole, timestamp, message, isSelf, children, loading }: PropsWithLoadingState<IChatThreadMessageItemProps>) => {
    const styles = stylesFn({ isSelf, userRole: trimUserRole({ userRole }).userRole });
    return (
        <Stack direction={isSelf ? 'row-reverse' : 'row'} justifyContent={'flex-start'} alignItems={'flex-start'} spacing={1}>
            {!loading ?
                <>
                    <Avatar {...avatar} />
                    <Stack direction={'column'}>
                        <Stack direction={isSelf ? 'row-reverse' : 'row'} justifyContent={'flex-start'} spacing={1}>
                            <Typography sx={{ lineClamp: 1 }} textOverflow={'ellipsis'}>{displayName}</Typography>
                            <Typography>{timestamp?.toDateString() ?? ''}</Typography>
                        </Stack>
                        <Stack direction={'column'} sx={styles.bubble}>
                            <Typography>{message}</Typography>
                            {children}
                        </Stack>
                    </Stack>
                </>
                :
                <>
                    <Skeleton variant='circular' height={42} width={42} />
                    <Skeleton variant='rectangular' width={'50%'} height={84} sx={stylesFn({ isSelf }).bubble} />
                </>
            }
        </Stack>
    )
}
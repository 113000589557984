import React from 'react';
import { Box, ImageList, ImageListItem, ImageListItemBar, useTheme, useMediaQuery, CircularProgress } from '@mui/material';

const SPACING = 4;

export interface IListingPictureListProps {
    /** The list of image urls and description */
    images: { url: string, description?: string }[];
    /** 
     * The loading state, if true this will show the loading state
     * @default false
     */
    loading?: boolean;
}

/**
 * The listing picture as stand alone pictures page
 * @param props IListingPictureListProps
 * @returns The rendering of the listing picture list
 */
export const ListingPictureList = ({ images, loading }: IListingPictureListProps) => {
    const theme = useTheme();
    const u_md = useMediaQuery(theme.breakpoints.up('md'));
    const u_sm = useMediaQuery(theme.breakpoints.up('sm'));
    const u_xs = useMediaQuery(theme.breakpoints.up('xs'));

    if (loading) {
        return <Box sx={{ display: 'flex', width: '100vw', height: '100vh', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
        </Box>
    }

    const cols = u_md ? 3 : u_sm ? 2 : u_xs ? 2 : 1;
    const imageWidth = Math.floor(theme.breakpoints.values.md / cols);

    return (
        <Box sx={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
            <ImageList variant="masonry" cols={cols} gap={SPACING}>
                {images.map((image) => (
                    <ImageListItem key={image.url}>
                        <img
                            src={`${image.url}?w=${imageWidth}&fit=crop&auto=format`}
                            srcSet={`${image.url}?w=${imageWidth}&fit=crop&auto=format&dpr=2 2x`}
                            alt={image.description}
                            loading="lazy"
                        />
                        <ImageListItemBar position="below" title={image.description} style={{ textAlign: 'center' }} />
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    );
}

/** The types for all reviews related db (firestore+realtime) objects */
/** NOTE: all change should be done in the shared_type folder 
 * and run `npm run build:shared_types` in root project */

import { ListingSummary } from "./listings";
import { EUserRole, UserSummary } from "./user";

/******************************************
 * REVIEWS DB FIRESTORE
 * |listings
 * -|reviews <-
 * |events
 * -|reviews <-
 * |users
 * -|reviews <-
 ******************************************/

/** The DB sub-collection for reviews */
export const REVIEWS_COLLECTION = "reviews";

export type ReviewFieldFrom = EUserRole.HOST | EUserRole.COHOST | EUserRole.GUEST;
export type ReviewFieldTo = EUserRole.HOST | EUserRole.COHOST | EUserRole.GUEST | "LISTING" | "EVENT";

export type Review = {
    booking_id: string;//either listing booking or event booking id
    host: UserSummary;
    cohost: UserSummary;
    guest: UserSummary;
    listing: ListingSummary;
    // event?: EventSummary;//TODO event feature
    from: ReviewFieldFrom;
    to: ReviewFieldTo;
    author: UserSummary,
    timestamp: number;//Timestamp;
    message: string;
    // images:[] //TODO possibility for user to leave image in reviews
}
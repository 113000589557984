/** Simple file which regroup all the helper function for location */

import { Boundary, LocationLatLng } from "../SearchListingsPage.types";

/** Helper function which calculate the distance between to location given they lat/lng */
function haversine(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371; // Earth's radius in kilometers

    // Convert latitude and longitude from degrees to radians
    const lat1Rad = toRadians(lat1);
    const lon1Rad = toRadians(lon1);
    const lat2Rad = toRadians(lat2);
    const lon2Rad = toRadians(lon2);

    // Calculate differences
    const deltaLat = lat2Rad - lat1Rad;
    const deltaLon = lon2Rad - lon1Rad;

    // Haversine formula
    const a = Math.sin(deltaLat / 2) ** 2 + Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Calculate the distance
    const distance = R * c;

    return distance;
}

// get the radius from the center of the bounds
export const getRadiusInM = (bounds?: Boundary) => {
    if (!bounds) return 0;
    return haversine(bounds.ne.lat, bounds.ne.lng,
        bounds.sw.lat, bounds.sw.lng)
        * 1000 / 2; // km->m + diameter->radius
}


/**helper function to determine if 2 locations are close together*/
export const isCloseBy = (loc1: LocationLatLng, loc2: LocationLatLng, distanceM: number = 2000) => {
    return (haversine(loc1.lat, loc1.lng, loc2.lat, loc2.lng) * 1000) < distanceM;
}

/**helper function to get the center of a boundary*/
export const getCenterOfBoundary = (boundary: Boundary): LocationLatLng => {
    return {
        lat: (boundary.ne.lat + boundary.sw.lat) / 2,
        lng: (boundary.ne.lng + boundary.sw.lng) / 2,
    }
}

function toRadians(degrees: number) {
    return degrees * (Math.PI / 180);
}


export const getLatLng = (latlng?: google.maps.LatLng): LocationLatLng | undefined => {
    if (!latlng) {
        return;
    }
    return {
        lat: latlng.lat(),
        lng: latlng.lng(),
    }
}

/** Helper function which take a place in string and return a promise with the Lat/Lng of the first found one */
export const getPlaceLocation = (place: string): Promise<LocationLatLng> => {
    return new Promise((resolve, reject) => {
        if (!place) {
            reject()
        }
        const geocoder = new google.maps.Geocoder();
        //research lat/lng of this place
        geocoder.geocode({ address: place }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK && results?.[0]) {
                const location = results[0].geometry.location;
                resolve({
                    lat: location.lat(),
                    lng: location.lng(),
                });
            } else {
                reject();
            }
        });
    });
}
import React from 'react';
import { Divider, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PropsWithErrorState, PropsWithLoadingState } from '../../../utilities';
import { IssueStateView } from '../../IssueStateView';
import { HelpTopicArticleItem, IHelpTopicArticleItemProps } from '../HelpTopicArticleItem';
import { HelpTopicSectionItem, IHelpTopicSectionItemProps } from '../HelpTopicSectionItem';

export interface IHelpTopicProps {
    /** The title of the topic */
    title: string;
    /** The list of articles under this topic (can be empty) */
    articles: IHelpTopicArticleItemProps[];
    /** The list of sub-topic under this topic (can be empty) */
    subTopics: IHelpTopicSectionItemProps[];
    /** The related topics */
    relatedTopics: IHelpTopicSectionItemProps[];
}

/** The display view of a topic page */
export const HelpTopic = ({
    title,
    articles,
    subTopics,
    relatedTopics,
    loading,
    error,
}: PropsWithErrorState<PropsWithLoadingState<IHelpTopicProps>>) => {
    const { t } = useTranslation();
    if (!!error) {
        return (
            <IssueStateView title={t('help.topic.errorTitle')}
                description={t('help.topic.errorContent')}
                button={typeof error !== 'boolean' && !!error.onRetryClick ? {
                    text: t('help.topic.errorBtn'),
                    onActionClick: error.onRetryClick,
                } : undefined} />
        )
    }
    return (
        <Stack direction={{ xs: 'column', md: 'row' }}
            justifyContent={{ xs: 'start', md: 'space-between' }}
            alignItems={{ xs: 'stretch', md: 'start' }}
            spacing={2}>
            <Stack direction={'column'}
                justifyContent={'start'}
                alignItems={'stretch'}
                spacing={2}
                flexGrow={1}>
                <Typography variant='h4'>{!loading ? title : <Skeleton />}</Typography>
                <Stack direction={'column'}
                    spacing={1}
                    justifyContent={'start'}
                    alignItems={'stretch'}>
                    {
                        !loading ?
                            subTopics.length > 0 &&
                            subTopics.map((subTopic, index) =>
                                <HelpTopicSectionItem key={`sub-topic-${index}`} {...subTopic} />
                            )
                            :
                            new Array(3).fill('').map((_, index) =>
                                <HelpTopicSectionItem loading key={`sub-topic-loading-${index}`} />
                            )
                    }
                </Stack>
                <Divider />
                <Stack direction={'column'}
                    spacing={1}
                    justifyContent={'start'}
                    alignItems={'stretch'}>
                    {
                        !loading ?
                            articles.map((article, index) =>
                                <HelpTopicArticleItem key={`article-${index}`} {...article} />
                            )
                            :
                            new Array(5).fill('').map((_, index) =>
                                <HelpTopicArticleItem loading key={`article-loading-${index}`} />
                            )
                    }
                    {
                        !loading && !articles.length && !subTopics.length &&
                        <Typography>{t('help.topic.emptyContent')}</Typography>
                    }
                </Stack>
            </Stack>
            <Stack direction={'column'} spacing={2}>
                <Typography variant='h5'>{t('help.topic.relatedTopics')}</Typography>
                <Stack direction={'column'} spacing={1}>
                    {
                        !loading ?
                            relatedTopics.map((topic, index) =>
                                <HelpTopicSectionItem key={`related-topic-${index}`} {...topic} />
                            )
                            :
                            new Array(5).fill('').map((_, index) =>
                                <HelpTopicSectionItem loading key={`related-topic-loading-${index}`} />
                            )
                    }
                </Stack>
            </Stack>
        </Stack>
    );
}


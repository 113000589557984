import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router"
import { useRemoteConfig } from '../../../services/remoteConfig';
import { RC_HELP, RC_SUPPORT } from '../../../types/remote_config';
import { ROUTE_PATH, buildPath } from '../../../utilities/navigation';
import { ChoAppBar } from "../ChoAppBar"
import { UserContext } from '../../../context/UserInformation';
import { WEBSITE_HELP_CENTER_URL } from '../../../types/constants';

export const HomeAppBar = () => {
    const { t } = useTranslation();
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const {
        [RC_SUPPORT]: remoteConfigSupport,
        // [RC_DONATION]: remoteConfigDonation,
        [RC_HELP]: remoteConfigHelp,
    } = useRemoteConfig();

    const mainMenuItems = [
        {
            label: t('home.menu.discover'),
            //for now go directly to listings as the rest is not ready
            action: () => navigate(buildPath([ROUTE_PATH.search, ROUTE_PATH.listings]))
        },
        {
            label: t('home.menu.about'),
            action: () => navigate(buildPath(ROUTE_PATH.about))
        },
        {
            label: t('home.menu.faq'),
            action: () => navigate(ROUTE_PATH.aboutBuild(ROUTE_PATH.faq))
        },
        ...(remoteConfigSupport.enabled ?
            [
                {
                    label: t('home.menu.support'),
                    action: () => navigate(ROUTE_PATH.support)
                }
            ]
            : [])
    ];

    const userMenuItems = [
        {
            label: t('user.menu.dashboard'),
            action: () => {
                navigate(buildPath(ROUTE_PATH.dashboard));
            }
        },
        {
            label: t('user.menu.profile'),
            action: () => {
                navigate(ROUTE_PATH.userBuild(userContext?.user?.firebaseUser?.uid ?? ""));
            }
        },
        {
            label: t('user.menu.account'),
            action: () => {
                navigate(ROUTE_PATH.accountBuild());
            }
        },
        true,
        remoteConfigHelp.enabled ? {
            label: t('user.menu.help'),
            action: () => {
                window.location.assign(WEBSITE_HELP_CENTER_URL);
            }
        } : false,
        {
            label: t('user.menu.logout'),
            action: () => {
                userContext?.logOut();
                navigate(ROUTE_PATH.home);
            },
        },
    ]

    return <ChoAppBar mainMenuItems={mainMenuItems} userMenuItems={userMenuItems} />
}
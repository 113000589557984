// File for all the remote config key and value type

import { Career, DEFAULT_CAREER, RC_CAREER } from "./career";
import { RC_DONATION, Donation, DEFAULT_DONATION } from "./donation";
import { DEFAULT_FORUM, Forum, RC_FORUM } from "./forums";
import { RC_HELP, Help, DEFAULT_HELP } from "./help";
import { DEFAULT_MAINTENANCE_MODE, MaintenanceMode, RC_MAINTENANCE_MODE } from "./maintenance";
import { DEFAULT_SHOP, RC_SHOP, Shop } from "./shop";
import { DEFAULT_SUPPORT, RC_SUPPORT, Support } from "./support";
import { RC_NEWS, DEFAULT_NEWS, News } from './news';

export * from './donation';
export * from './forums';
export * from './help';
export * from './career';
export * from './shop';
export * from './support';
export * from './news';
export * from './maintenance';

export type RC_KEYS = typeof RC_SUPPORT
    | typeof RC_SHOP
    | typeof RC_DONATION
    | typeof RC_HELP
    | typeof RC_CAREER
    | typeof RC_FORUM
    | typeof RC_NEWS
    | typeof RC_MAINTENANCE_MODE;

/** The full object type of the remote config */
export type RemoteConfig = {
    [RC_SUPPORT]: Support;
    [RC_SHOP]: Shop;
    [RC_DONATION]: Donation;
    [RC_HELP]: Help;
    [RC_CAREER]: Career;
    [RC_FORUM]: Forum;
    [RC_NEWS]: News;
    [RC_MAINTENANCE_MODE]: MaintenanceMode;
}

/** The full object of the default remote config (leave as is for easy copy paste to JSON) */
export const DEFAULT_REMOTE_CONFIG: RemoteConfig = {
    [RC_SUPPORT]: DEFAULT_SUPPORT,
    [RC_SHOP]: DEFAULT_SHOP,
    [RC_DONATION]: DEFAULT_DONATION,
    [RC_HELP]: DEFAULT_HELP,
    [RC_CAREER]: DEFAULT_CAREER,
    [RC_FORUM]: DEFAULT_FORUM,
    [RC_NEWS]: DEFAULT_NEWS,
    [RC_MAINTENANCE_MODE]: DEFAULT_MAINTENANCE_MODE,
}
import { useContext } from "react";
import { DEFAULT_REMOTE_CONFIG, RC_KEYS, RemoteConfig } from "../../types/remote_config";
import { RemoteConfigContext } from "./context";

/** 
 * Hook which will help in the formatting of RemoteConfig 
 * @param specificRemoteConfig (optional) specify which remote config we are interested in (save on fetching all the remote configs)
 */
export const useRemoteConfig = (specificRemoteConfig?: RC_KEYS): RemoteConfig & {
    isLoading: boolean;
} => {
    const remoteConfig = useContext(RemoteConfigContext);
    const devOverwrite: Partial<RemoteConfig> = {//overwrite while developing
        // [RC_SHOP]: { enabled: true },
        // [RC_CAREER]: { enabled: true }
    }

    if (!!specificRemoteConfig) {
        return {
            ...DEFAULT_REMOTE_CONFIG,
            [specificRemoteConfig]: remoteConfig[specificRemoteConfig],
            isLoading: remoteConfig.isLoading,
            ...devOverwrite
        };
    }

    return {
        ...remoteConfig,
        ...devOverwrite
    };
}

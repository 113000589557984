import { ArrowCircleLeftOutlined, ArrowCircleRightOutlined } from '@mui/icons-material';
import { IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useRef, useState } from 'react';

export interface ICarouselProps {
    /** The list of item element to put in the carousel (warning: need to be finite list) */
    items: JSX.Element[];
    /** 
     * The type of carousel, 'light' for 2 items per page, 'dense' for 3 items per page
     * @default 'dense'
     */
    type?: 'light' | 'dense';
}

export const Carousel = ({ items, type = 'dense' }: ICarouselProps) => {
    const theme = useTheme();
    const u_md = useMediaQuery(theme.breakpoints.up('md'));
    const u_sm = useMediaQuery(theme.breakpoints.up('sm'));
    const u_xs = useMediaQuery(theme.breakpoints.up('xs'));
    const numCols = u_md ? (type === 'light' ? 2 : 3) : u_sm ? (type === 'light' ? 1 : 2) : u_xs ? 1 : 1;
    const sliderRef = useRef<HTMLElement>();

    const [leftArrowVisible, setLeftArrowVisible] = useState(false);
    const [rightArrowVisible, setRightArrowVisible] = useState(items.length > numCols);


    const handleOnLeftClick = () => {
        if (!sliderRef.current) return;

        const itemWidth = sliderRef.current.clientWidth / numCols;
        sliderRef.current.scrollLeft -= itemWidth;

        setLeftArrowVisible(Math.floor(sliderRef.current.scrollLeft - itemWidth) > 0);
        setRightArrowVisible(true);
    }

    const handleOnRightClick = () => {
        if (!sliderRef.current) return;

        const itemWidth = sliderRef.current.clientWidth / numCols;
        sliderRef.current.scrollLeft += itemWidth;

        // console.log(sliderRef.current.scrollLeft + itemWidth);
        // console.log(itemWidth * (items.length - numCols));

        setRightArrowVisible(sliderRef.current.scrollLeft + itemWidth < itemWidth * (items.length - numCols));
        setLeftArrowVisible(true);
    }

    return (
        <Stack direction={'row'} justifyContent={'stretch'} alignItems={'center'}>
            <Box ref={sliderRef} sx={{
                width: '100%',
                height: '100%',
                whiteSpace: 'nowrap',//oblige to put all in one line
                // overflowX: 'scroll',//this shows the scrollbar
                overflowX: 'hidden',
                scrollbarWidth: 'none',
                scrollBehavior: 'smooth',//animation of the scrolling
            }}>
                {
                    items.map((item, index) =>
                        <Box key={`carousel-item-${index}`} sx={{
                            width: `${100 / numCols}%`,
                            height: '100%',
                            display: 'inline-block',//force to be in line as blocks
                            whiteSpace: 'normal',//need this to counterpart the parent so item within it still wrap normally
                        }}>
                            <Box sx={{ px: 2, py: 1, flex: 'auto' }}>
                                {/* padding horizontal need to be separated from the width calculation and be fully inside */}
                                {item}
                            </Box>
                        </Box>
                    )
                }
            </Box>
            <IconButton onClick={handleOnLeftClick}
                sx={{
                    display: !leftArrowVisible ? 'none' : 'block',
                    position: 'absolute', left: 10
                }}
                disabled={!leftArrowVisible}
            >
                <ArrowCircleLeftOutlined />
            </IconButton>
            <IconButton onClick={handleOnRightClick}
                sx={{
                    display: !rightArrowVisible ? 'none' : 'block',
                    position: 'absolute', right: 10
                }}
                disabled={!rightArrowVisible}
            >
                <ArrowCircleRightOutlined />
            </IconButton>
        </Stack>
    )
}
//File which regroup the DB types for the user
/** NOTE: all change should be done in the shared_type folder 
 * and run `npm run build:shared_types` in root project */

/** The db user root collection */
export const USERS_COLLECTION = "users";

export enum EUserRole {
    HOST = "HOST",
    COHOST = "COHOST",
    GUEST = "GUEST",
}

export enum EUserMedal {
    TOP_HOST = 'TOP_HOST',
    ACE_COHOST = 'ACE_COHOST',
    SUPER_GUEST = 'SUPER_GUEST',
}

/** The list of user verification (linked to user sign-in options) */
export enum EUserVerification {
    EMAIL = "EMAIL",
    // PHONE = "PHONE",
    GOOGLE = "GOOGLE",
    // FACEBOOK = "FACEBOOK",
    // TWITTER = "TWITTER",
    // GITHUB = "GITHUB",
    // APPLE = "APPLE",
    // YAHOO = "YAHOO",
    // HOTMAIL = "HOTMAIL",
}//note: uncomment whenever supported

/** The smallest information about a user (to show in listing and reviews) */
export type UserSummary = {
    /** The id of the user */
    id: string;
    /** The display name of the user */
    display_name: string;
    /** The avatar of the user */
    avatar_url: string;
}
export type User =
    UserSummary
    & Profile // What the user can modify
    & {// What user can see but cannot modify the following
        /** The date the user's account got created */
        join_date: number;//Timestamp
        /** The last active date the user was signed-in/online */
        last_active_date: number;//Timestamp
        /** The list of verification provided by the user */
        verifications: EUserVerification[];
        /** Boolean indicating if this user has been fully verified */
        is_verified: boolean;
        /** (Optional) The user medal(s) */
        medal?: EUserMedal | EUserMedal[];
        /** The user review summary */
        review_summary?: {
            as_host?: Omit<UserReviewSummary, 'from_host'>,
            as_cohost?: Omit<UserReviewSummary, 'from_cohost'>,
            as_guest?: Omit<UserReviewSummary, 'from_guest'>,
        };
        /** (Optional) If present, it means that the user has been flagged for removal, auth account non-existing anymore */
        flagged_for_deletion?: {
            /** The timestamp the flag was created */
            on: number;//Timestamp
        }
    }

/** The Profile Object is what the user can modify manually from the User Object */
export type Profile = {
    /** The user's full name */
    full_name: string;
    /** The user's display name (can be the same as the full name) */
    display_name: string;
    /** The user's avatar */
    avatar_url: string;
    /** The description given by the user of themselves */
    description?: string;
    /** The user's origin/location */
    from?: string;//location?
    /** The languages the user can communicate with */
    languages: string[];//TODO language code
};

export type UserReviewSummary = {
    /** The average note (out of 5) the user received */
    average: number;
    /** The number of review the user received */
    quantity: number;
    /** The summary from host in particular */
    from_host?: Pick<UserReviewSummary, 'average' | 'quantity'>;
    /** The summary from cohost in particular */
    from_cohost?: Pick<UserReviewSummary, 'average' | 'quantity'>;
    /** The summary from guest in particular */
    from_guest?: Pick<UserReviewSummary, 'average' | 'quantity'>;
}

/** The DB user sub-collection for reviews */
export const USER_REVIEWS_COLLECTION = "reviews";

export type UserReview = {
    booking_id: string;
    host_id: string;
    cohost_id: string;
    guest_id: string;
    listing_id: string;
    event_id?: string;
    from: EUserRole;
    to: EUserRole;
    author: {
        id: string;
        avatar_url: string;
        display_name: string;
    },
    timestamp: number;//Timestamp;
    message: string;
}

/**********************************************
 * USER PROTECTED INFORMATION 
 * (can be viewed only by users who confirmed a booking/event)
 *********************************************/

/** The sub-collection of the users collection for any protected documents */
export const USER_PROTECTED_COLLECTION = "protected";
/** The document id under the protected collection for any user information */
export const USER_PROTECTED_INFO_DOCUMENT = "info";

export type UserProtectedInfo = {
    phone: string;
    email?: string;
}

/**********************************************
 * USER PRIVATE INFORMATION 
 * (can be viewed and modified only by user)
 *********************************************/

/** The sub-collection of the users collection for any private documents */
export const USER_PRIVATE_COLLECTION = "private";

/** The document id under the private collection for any user information */
export const USER_PRIVATE_INFO_DOCUMENT = "info";

export type UserPrivateInfo = {
    /** The full/legal name of the user */
    full_name: UserName;
    /** The email of the user */
    email: string;
    /** The phone number of the user */
    phone?: string;//TODO in future could be an array of string
    /** The government issued ID (link to storage) */
    id?: string;
    /** The user's actual address (can be different than its listings) */
    address?: Omit<UserAddress, 'first_name' | 'last_name' | 'type'>;
    /** (optional) The user's emergency contact */
    emergency_contact?: UserPrivateInfo;
    /** The user's addresses (listings/shipping/billing) */
    addresses: UserAddress[];
}

export type UserName = {
    /** User's legal first name */
    first_name: string;
    /** User's legal last name */
    last_name: string;
}

export type UserAddress = {
    line1: string;
    line2?: string;
    zip: string;
    city: string;
    state?: string;
    country: string;
} & UserName & {
    //extra
    company?: string,
    phone?: string, //phone at this address
    email?: string; //email at this address (personal/business)
    type: 'personal' | 'business' | 'listing' | 'event' | 'shipping' | 'billing'
}

/** The document id under the private collection for any user login info */
export const USER_PRIVATE_LOGIN_DOCUMENT = "login";

export type UserPrivateLogin = {
    /** Last time the user updated his password */
    pw_update_last: number;
    /** The user's device login history */
    device_history?: {
        device_type: "mobile" | "laptop",
        device_name: string;
        platform: "Chrome" | "Safari" | "App" | string;
        location: string;
        when: Date;
    }[];
    /** The linked social accounts */
    social_accounts: {
        platform: EUserVerification;
        info: any;//TODO connection info to be able to disconnect?
    }[];
}

/** The document id under the private collection for any user financial info (basic, rest dealt with Stripe) */
export const USER_PRIVATE_FINANCE_DOCUMENT = "finances";

export type UserPrivateFinances = {
    payment: any;//TODO the payment info (for guest to book)
    payout: any;//TODO the payout info (for host to receive money)
    taxes?: any;//TODO deal with taxes number and stuff...
    service_fees?: any;//TODO see if offer same choice as RBnB slip fees or only on one side
    donations?: any;//TODO see if allow guest donation or maybe allow CHO to put the donation banner on their listing
}

/** The document id under the private collection for any user notification pref */
export const USER_PRIVATE_NOTIFICATIONS_DOCUMENT = "notifications";

//TODO
export type UserPrivateNotifications = {
    /** The notification about the user's account */
    account?: any;
    /** The notification about reminders for booking/event/reservation... */
    reminder?: any;
    /** The notification coming for the chat feature */
    chat?: any;
}

/** The document id under the private collection for any user privacy settings */
export const USER_PRIVATE_PRIVACY_DOCUMENT = "privacy";

//TODO
export type UserPrivatePrivacy = {
    /** The user's cookies settings */
    cookies?: any;
    /** The user's third party connected to this account (which can have private data) (ex:August Lock) */
    services?: any;
}

/** The document id under the private collection for any user preferences */
export const USER_PRIVATE_PREFERENCES_DOCUMENT = "preferences";

export type UserPrivatePreferences = {
    /** The user's preferred language */
    language: string;//language code
    /** The user's currency (all prices will be displayed in this currency) */
    currency: string;//currency code
    /** The user's time zone */
    time_zone: string;//time zone code
    /** The user preferred role (allow to show proper dashboard) */
    role?: EUserRole | '';
}

/** The document id under the private collection for any user referrals (summary) */
export const USER_PRIVATE_REFERRALS_DOCUMENT = "referrals";

export type UserPrivateReferrals = {
    /** The number of sign-ups which were referred by the user (should default to 0) */
    sign_ups: number;
    /** The number of completed referrals (should default to 0) */
    completed: number;
}
import React, { useContext, useEffect, useState } from 'react';
import { Stack, TextField, TextFieldProps } from '@mui/material';
import { ListingCreationFlowContext } from '../../../context/Listing/ListingCreationFlow';
import { useTranslation } from 'react-i18next';
import { isShortDescriptionValid, isTitleValid, SHORT_DESC_MAX_LENGTH, TITLE_MAX_LENGTH, useHandleShortDescriptionOnBlurCallback, useHandleShortDescriptionOnChangeCallback, useHandleTitleOnBlurCallback, useHandleTitleOnChangeCallback } from '../../../utilities/listings';

/** The basic page of the listing creation flow */
export const ListingBasicPage = () => {
    return <Container />;
}

/** The container looking for basic info from the creation flow context */
const Container = () => {
    const listingCreationFlowContext = useContext(ListingCreationFlowContext);
    if (!listingCreationFlowContext) {
        throw new Error('need ListingCreationFlowContext');
    }
    const { t } = useTranslation();

    // in the basic info, only interested by the title and the short description
    const { listing: { title, short_description }, setActivePageInfo } = listingCreationFlowContext;

    const [titleError, setTitleError] = useState<string>();
    const [descError, setDescError] = useState<string>();

    const titleProps: TextFieldProps = {
        value: title || '',
        helperText: titleError || `${title?.length || 0}/${TITLE_MAX_LENGTH}`,
        error: !!titleError,
    };
    const descProps: TextFieldProps = {
        value: short_description || '',
        helperText: descError || `${short_description?.length || 0}/${SHORT_DESC_MAX_LENGTH}`,
        error: !!descError,
    }

    useEffect(() => {
        if (isTitleValid(title) && isShortDescriptionValid(short_description)) {
            setActivePageInfo({}, true)
        } else {
            setActivePageInfo({}, false)
        }
    }, [title, short_description, setActivePageInfo]);

    const handleTitleOnBlur = useHandleTitleOnBlurCallback(t, setTitleError);
    const handleTitleOnChange = useHandleTitleOnChangeCallback(setActivePageInfo, setTitleError);

    const handleDescOnBlur = useHandleShortDescriptionOnBlurCallback(t, setDescError);
    const handleDescOnChange = useHandleShortDescriptionOnChangeCallback(setActivePageInfo, setDescError);

    return (
        <Stack direction={'column'}
            justifyContent={'space-evenly'}
            alignItems={'stretch'}
            spacing={2}>
            <TextField
                label={t('listing.creation.titleInputLabel')}
                placeholder={t('listing.creation.titleInputPlaceholder')}
                onBlur={handleTitleOnBlur}
                onChange={handleTitleOnChange}
                autoComplete={'off'}
                {...titleProps} />
            <TextField
                label={t('listing.creation.shortDescriptionInputLabel')}
                placeholder={t('listing.creation.shortDescriptionInputPlaceholder')}
                multiline={true}
                minRows={2}
                onBlur={handleDescOnBlur}
                onChange={handleDescOnChange}
                autoComplete={'off'}
                {...descProps} />
        </Stack>
    )
}
import React from 'react';
import { useQuery } from 'react-query';
import { getShopProductVariants, WithID } from '../../../services/shop';
import { ShopProductVariant } from '../../../types/db';
import { DEFAULT_SHOP_ID } from '../../../utilities/shop';
import { IShopProductContainerProps } from '../ShopProductContainer';

export interface IShopProductVariantsContainerProps
    extends Pick<IShopProductContainerProps, 'shopId' | 'productId'> {
    children: (data?: WithID<ShopProductVariant, string>[]) => React.ReactElement;
}

export const ShopProductVariantsContainer = ({
    shopId = DEFAULT_SHOP_ID,
    productId,
    children
}: IShopProductVariantsContainerProps) => {
    const { data, isLoading, isError } = useQuery(
        ["ProductVariants", productId],
        () => getShopProductVariants(shopId, productId));

    if (isLoading || isError) {
        return children()
    }
    return children(data);
}
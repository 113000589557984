import React, { ReactElement, useState } from "react";
import { Stack, Typography, Button, Container } from "@mui/material";
import { Banner, IBannerProps } from "../../Banner";
import { ForumPostItem, IForumPostItemProps } from "../ForumPostItem/ForumPostItem";
import { ForumPostsSection, IForumPostsSectionProps } from "../ForumPostsSection/ForumPostsSection";
import { ForumRelatedArticlesSection } from "../ForumRelatedArticlesSection/ForumRelatedArticlesSection";
import { LoadingButton } from "@mui/lab";
import { IForumPostCardProps } from "../ForumPostCard/ForumPostCard";
import { useTranslation } from "react-i18next";

export interface IForumTopicScreenProps {
    /** The banner on top of the screen */
    banner: IBannerProps,
    /** The featured conversations */
    featuredPosts: Omit<IForumPostsSectionProps, 'title'>,
    /** The latest posts (put at the top), optional as only in the main forum page */
    latestPosts?: Omit<IForumPostsSectionProps, 'title'>,
    /** The posts in this topic, optional for the main forum page */
    posts?: IForumPostItemProps[] | { loading: true, length: number },
    /** Callback load more posts */
    onLoadMorePostsClick?: () => Promise<any>,
    /** The related articles in the Help Center */
    relatedArticles?: IForumPostCardProps[] | ReactElement<IForumPostsSectionProps>,//TODO change with it's own rendering of article
    /** Callback on create new post, button not show if not provided */
    onCreateNewPostClick?: () => void;
}

/** The Common UI to display a Forum topic page (Reusable for Community/Host/CoHost/Guest) */
export const ForumTopicScreen = ({ banner, featuredPosts, latestPosts, posts, onLoadMorePostsClick, relatedArticles, onCreateNewPostClick }: IForumTopicScreenProps) => {
    const { t } = useTranslation();
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const handleLoadMorePosts = async () => {
        if (!onLoadMorePostsClick) {
            return;
        }
        setIsLoadingMore(true);
        await onLoadMorePostsClick();
        setIsLoadingMore(false);
    }
    return (
        <Stack direction={'column'} spacing={1}>
            <Banner {...banner} />
            <ForumPostsSection title={t('forum.topic.featuredPostsTitle')}
                {...featuredPosts}
            />
            {!!latestPosts &&
                <ForumPostsSection title={t('forum.topic.latestPostsTitle')}
                    {...latestPosts} />
            }
            {!!posts?.length &&
                <Container sx={{ p: 2, alignSelf: 'center' }}>
                    <Stack direction={'column'} spacing={2}>
                        {Array.isArray(posts) ?
                            posts.map((post, index) => <ForumPostItem key={`post-${index}`} {...post} />)
                            :
                            new Array(posts.length).fill({}).map((_, index) => <ForumPostItem loading key={`post-loading${index}`} />)}
                        {onLoadMorePostsClick && <LoadingButton loading={isLoadingMore} onClick={handleLoadMorePosts}>{t('forum.topic.loadMorePostsButton')}</LoadingButton>}
                    </Stack>
                </Container>
            }
            {
                !!onCreateNewPostClick &&
                <Container sx={{ p: 3, alignSelf: 'center' }}>
                    <Stack direction={'column'} spacing={1} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="h3" textAlign={"center"}>{t('forum.topic.newPostTitle')}</Typography>
                        <Button variant="contained" onClick={onCreateNewPostClick}>{t('forum.topic.newPostButton')}</Button>
                    </Stack>
                </Container>
            }
            {
                !!relatedArticles &&
                    Array.isArray(relatedArticles) ?
                    <ForumRelatedArticlesSection
                        title={t('forum.topic.relatedArticles')}
                        articles={relatedArticles} />
                    : relatedArticles
            }
        </Stack>
    );
}
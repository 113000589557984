import React from 'react';
import { Avatar, AvatarGroup, AvatarProps, IconButton, Menu, MenuItem, Skeleton, Stack, Typography } from '@mui/material';
import { ChevronLeft, MoreOutlined } from '@mui/icons-material';
import { getColorFrom } from '../../../utilities/theme/ColorUtils';
import { ERole } from '../../../utilities/EnumUtility';
import { PropsWithLoadingState } from '../../../utilities';

export interface IChatThreadHeaderProps {
    /**
     * Members of that thread
     */
    members?: (AvatarProps & { role?: ERole })[];
    /**
     * The thread title
     */
    title?: string;
    /**
     * Optional Callback on Back button pressed, will only show for small screen
     */
    onBackClick?: () => void;
    /**
     * Optional Callback on Details button pressed
     */
    onDetailsClick?: () => void;
}

export const ChatThreadHeader = ({ members, title, onBackClick, onDetailsClick, loading }: PropsWithLoadingState<IChatThreadHeaderProps>) => {

    const renderBackClick = () => {
        if (!loading && !onBackClick) {
            return null;
        }
        return (
            <IconButton sx={{ display: { xs: 'inline', md: 'none' } }} onClick={onBackClick} disabled={!!loading}>
                <ChevronLeft />
            </IconButton>
        );
    }

    const renderMoreClick = () => {
        if (!onDetailsClick) {
            return null;
        }
        return (
            <IconButton onClick={onDetailsClick}>
                <MoreOutlined />
            </IconButton>
        )
    }

    const renderMembers = () => {
        return (
            <AvatarGroup max={4}>
                {
                    !loading ?
                        members?.map(({ role, ...item }, index) => {
                            const extraStyling = !!role ?
                                {
                                    color: getColorFrom(role),
                                    style: {
                                        borderColor: getColorFrom(role)
                                    }
                                } : undefined;
                            return <Avatar key={`member-${index}`}
                                {...extraStyling}
                                {...item} />
                        })
                        :
                        new Array(2).fill({}).map((_, index) =>
                            <Avatar key={`member-loading-${index}`}>
                                <Skeleton
                                    variant={'circular'}
                                    width={42}
                                    height={42} />
                            </Avatar>)
                }
            </AvatarGroup>
        );
    }

    return (
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} spacing={1}>
                {renderBackClick()}
                {renderMembers()}
                <Typography>{!loading ? title : <Skeleton width={30} />}</Typography>
            </Stack>
            <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                {renderMoreClick()}
                <Menu open={false}>
                    <MenuItem>
                        <Typography>Leave</Typography>
                    </MenuItem>
                </Menu>
            </Stack>
        </Stack>
    );
}

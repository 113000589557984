import React from 'react';
import { Route, Routes } from "react-router"
import { ShopAppBar } from '../../../components/appbar/ShopAppBar';
import { Footer, ShopFooterOutlet } from "../../../components/Footer/Footer"
import { FeatureNotAvailablePage } from '../../../pages/FeatureNotAvailablePage';
import { MaintenanceModePage } from '../../../pages/MaintenanceModePage';
import { NotFoundPage } from "../../../pages/NotFoundPage";
import { ShopCartPage, ShopPage } from '../../../pages/shop';
import { ShopCheckoutCancelPage } from '../../../pages/shop/ShopCheckoutCancelPage';
import { ShopCheckoutPage } from '../../../pages/shop/ShopCheckoutPage';
import { ShopCheckoutSuccessPage } from '../../../pages/shop/ShopCheckoutSuccessPage';
import { ShopProductPage } from '../../../pages/shop/ShopProductPage';
import { useRemoteConfig } from '../../../services/remoteConfig';
import { RC_MAINTENANCE_MODE, RC_SHOP } from '../../../types/remote_config';
import { ROUTE_PARAMS, ROUTE_PATH, ROUTE_SUBPATH } from "../RouterConfig";
import { UserCart } from '../UserCart';
import { AuthPage } from '../../../pages/AuthPage';
import { EAuthType } from '../../../components/AuthContent';

export const ShopRoutes = () => {
    const { [RC_SHOP]: FF_Shop, [RC_MAINTENANCE_MODE]: FF_Maintenance } = useRemoteConfig();
    if (!FF_Shop.enabled) {
        return <FeatureNotAvailablePage />
    }
    if (FF_Maintenance.enabled && FF_Maintenance.subdomainsAffected.includes("SHOP")) {
        return <MaintenanceModePage />
    }

    return (
        <>
            {/* AppBar */}
            <Routes>
                {/* Default App bar for the shop */}
                <Route path={'*'} element={<ShopAppBar />}>
                    <Route path={'*'} element={<UserCart />} />
                </Route>
            </Routes>
            {/* Content */}
            <Routes>
                <Route path={ROUTE_PATH.home}>
                    {SubShopRoutes()}
                </Route>
                <Route path={ROUTE_PATH.shop.shop}>
                    <Route path={`:${ROUTE_PARAMS.shopID}`}>
                        {SubShopRoutes()}
                    </Route>
                </Route>
                <Route path={ROUTE_PATH.users}>
                    <Route path={ROUTE_PATH.auth_join} element={<AuthPage authType={EAuthType.SIGN_UP} />} />
                    <Route path={ROUTE_PATH.auth_login} element={<AuthPage authType={EAuthType.LOG_IN} />} />
                    <Route path={ROUTE_PATH.auth_link} element={<AuthPage authType={EAuthType.LINK} />} />
                </Route>
                {/* Anything else */}
                <Route path={'*'} element={<NotFoundPage />} />
            </Routes>
            {/* Footer */}
            <Routes>
                {/* Add specific link for the shop (Printful links) */}
                <Route path={'*'} element={<Footer />} >
                    <Route index element={<ShopFooterOutlet />} />
                    <Route path={"*"} element={<ShopFooterOutlet />} />
                </Route>
            </Routes>
        </>
    )
}

/** This is the routes for a normal shop (CHO included) */
const SubShopRoutes = () => {
    return (
        <>
            <Route index element={<ShopPage />} />
            <Route path={ROUTE_SUBPATH.products}>
                <Route path={`:${ROUTE_PARAMS.productID}`} element={<ShopProductPage />} />
            </Route>
            <Route path={ROUTE_SUBPATH.cart} element={<ShopCartPage />} />
            <Route path={ROUTE_SUBPATH.checkout} element={<ShopCheckoutPage />} />
            <Route path={ROUTE_SUBPATH.checkout_success} element={<ShopCheckoutSuccessPage />} />
            <Route path={ROUTE_SUBPATH.checkout_cancel} element={<ShopCheckoutCancelPage />} />
        </>
    )
}
import React from 'react';
import { Link, Skeleton } from '@mui/material';
import { PropsWithLoadingState } from '../../../utilities';

export interface IHelpTopicSectionItemProps {
    /** The title of the topic */
    title: string;
    /** Callback on link clicked */
    onClick?: () => void;
}

/** The display of a topic section item (the sub-topic link itself) */
export const HelpTopicSectionItem = ({
    title,
    onClick,
    loading,
}: PropsWithLoadingState<IHelpTopicSectionItemProps>) => {
    if (loading) {
        return <Skeleton variant={'text'} />
    }
    return (
        <Link underline={'hover'}
            style={{ cursor: 'pointer' }}
            onClick={onClick}
            textAlign={'center'}>{title}</Link>
    );
}

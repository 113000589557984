import React, { useState } from 'react';
import { Avatar, Box, Collapse, IconButton, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { IListingRowProps, IListingTableProps } from './ListingTable.types';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Column, Order, OrderBy } from './ListingsPageView.types';
import { getComparator, stableSort, tableColumns } from './ListingsPageView.utils';
import { useTranslation } from 'react-i18next';
import { PropsWithLoadingState } from '../../../../utilities';
import { DEFAULT_LISTING_IMAGE_URL } from '../../../../utilities/assets/assetHelper';


export const ListingTable = ({
    items,
    level = 'listing',
    simplified = false,
    loading
}: PropsWithLoadingState<IListingTableProps>) => {
    const { t } = useTranslation();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<OrderBy>('title');

    const handleSortBy = (columnId: OrderBy) => {
        if (orderBy === columnId) {
            // if already ordering by it: change order
            setOrder(o => o === 'asc' ? 'desc' : 'asc');
        } else {
            setOrder('asc');
            setOrderBy(columnId);
        }
    }

    //to know if we are going to show any collapse icon, so we can leaving space in the header for it
    const hasSubListing = !loading && items.some(row => !!row.subListing);

    const renderHeaderCell = (column: Column) => {
        return (
            <TableCell
                key={column.id}
                align={column.align}
                // style={{ minWidth: column.minWidth }}
                sortDirection={orderBy === column.id ? order : false}
            >
                <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={() => handleSortBy(column.id)}
                >
                    {t(column.label)}
                    {orderBy === column.id ? (
                        <Box component="span" sx={{ display: 'none' }}>
                            {order === 'desc' ? t('listings.table.sortDesc') : t('listings.table.sortAsc')}
                        </Box>
                    ) : null}
                </TableSortLabel>
            </TableCell>
        )
    }

    if (level === 'listing')
        return (
            <TableContainer sx={{ maxHeight: '100%' }}>
                <Table stickyHeader>
                    <TableHead>
                        {!simplified && //if simplified -> no header
                            <TableRow>
                                {/* <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                                    // checked={rowCount > 0 && numSelected === rowCount}
                                    // onChange={onSelectAllClick}
                                    inputProps={{
                                        'aria-label': 'select all desserts',
                                    }}
                                />
                            </TableCell> */}
                                {
                                    hasSubListing &&
                                    <TableCell />
                                }
                                {
                                    tableColumns
                                        .map(renderHeaderCell)
                                }
                            </TableRow>
                        }
                    </TableHead>
                    <TableBody>
                        {(loading ? new Array(5).fill({}) : stableSort(items, getComparator(order, orderBy)))
                            .map((row, index) =>
                                <ListingRow key={`row-${row.title}-${index}`} {...row}
                                    collapsible={hasSubListing}
                                    collapsed={items?.length !== 1} //if only 1 item: expand it
                                    simplified={simplified}
                                    loading={loading}
                                />
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    else {
        if (loading) return null;//if sub-listing and loading, we don't want to show anything as sub-listing
        return (
            <Table size="small">
                <TableHead>
                    {!simplified && //if simplified -> no header
                        <TableRow>
                            {
                                hasSubListing &&
                                <TableCell />
                            }
                            {tableColumns
                                // .filter((column: Column) => column.id !== 'location')
                                .map(renderHeaderCell)}
                        </TableRow>
                    }
                </TableHead>
                <TableBody>
                    {stableSort(items, getComparator(order, orderBy))
                        .map((row, index) => (
                            <ListingRow key={`row-${row.title}-${index}`} {...row}
                                collapsible={hasSubListing}
                                collapsed={items.length !== 1} //if only 1 item: expand it
                                simplified={simplified}
                            />
                        ))}
                </TableBody>
            </Table>
        )
    }
}


export const ListingRow = ({
    selectable,
    selected,
    onSelectChange,
    collapsible = true,
    collapsed = true,
    onCollapseChange,
    onClick,
    image,
    title,
    status,
    // numBedrooms,
    // numBeds,
    // numBathrooms,
    location,
    lastModified,
    subListing,
    simplified,
    loading
}: PropsWithLoadingState<IListingRowProps>) => {
    const [open, setOpen] = useState(!collapsed);

    const collapsibleView = collapsible && !!subListing?.length;

    return (
        <>
            <TableRow sx={{ '& > *': collapsibleView ? { borderBottom: 'unset' } : {} }}>
                {
                    !!collapsible &&
                    <TableCell>
                        {
                            collapsibleView &&
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => {
                                    onCollapseChange?.(!collapsed) ?? setOpen(!open);
                                }}
                            >
                                {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                            </IconButton>
                        }
                    </TableCell>
                }
                <TableCell component="th" scope="row">
                    <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} spacing={1}>
                        {
                            loading ?
                                <Skeleton variant='rounded' width={40} height={40} />
                                :
                                <Avatar variant={'rounded'} src={image || DEFAULT_LISTING_IMAGE_URL} onClick={onClick} />
                        }
                        <Typography onClick={onClick}>{loading ? <Skeleton width={50} /> : title}</Typography>
                    </Stack>
                </TableCell>
                {
                    !simplified &&
                    <>
                        <TableCell align="left">{loading ? <Skeleton /> : status}</TableCell>
                        {/* <TableCell align="right">{numBedrooms.toFixed(0)}</TableCell> */}
                        {/* <TableCell align="right">{numBeds.toFixed(0)}</TableCell> */}
                        {/* <TableCell align="right">{numBathrooms.toFixed(1)}</TableCell> */}
                        {(!!loading || location !== undefined) && <TableCell align="right">{loading ? <Skeleton /> : location}</TableCell>}
                        <TableCell align="right">{loading ? <Skeleton /> : lastModified.toLocaleString()}</TableCell>
                    </>
                }
            </TableRow>
            {collapsibleView &&
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#F1F1F142' }} colSpan={100}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <ListingTable items={subListing} level='sub-listing' simplified={!!simplified} loading={loading} />
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            }
        </>
    )
}


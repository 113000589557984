
import { useNavigate } from "react-router";
import { EAudience } from "../types/db";
import { ROUTE_PATH, ROUTE_QUERY } from "../utilities/navigation";

/** Helper function which get the query parameter from the EAudience DB */
export const getQueryFrom = (audience: EAudience): string =>
    audience.toString().toLowerCase();

/** Helper function which get the EAudience DB from the navigation query parameter */
export const getAudienceFromQuery = (query?: string | null): EAudience =>
    !query ? EAudience.COMMON : query.toUpperCase() as EAudience;

/** helper hook which gives navigation function for the Help Center subdomain */
export const useHelpCenterNavigation = () => {
    const navigate = useNavigate();

    /** Navigate to a certain topic in the Help Center, if topic not provided go to all topics */
    const navigateToTopic = (topicId?: string) => {
        navigate(ROUTE_PATH.help.topicBuild(topicId))
    }

    /** Navigate to all topics page in the Help Center */
    const navigateToAllTopics = () => {
        navigateToTopic();
    }

    /** Navigate to an article in the Help Center */
    const navigateToArticle = (articleId: string) => {
        navigate(ROUTE_PATH.help.articleBuild(articleId))
    }

    const navigateToAudienceTopic = (audience: EAudience) => {
        navigate({
            pathname: ROUTE_PATH.help.topicBuild(),
            search: "?" + ROUTE_QUERY.audience + "=" + getQueryFrom(audience)
        })
    }

    return {
        navigateToAllTopics,
        navigateToTopic,
        navigateToArticle,
        navigateToAudienceTopic,
    }
}
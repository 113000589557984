import React from 'react';
import { ShopCategoriesContainer } from '../../containers/shop/ShopCategoriesContainer';
import { ShopContainer } from '../../containers/shop/ShopContainer';
import { ShopListContainer } from '../../containers/shop/ShopListContainer';
import { useShopNavigation } from '../../hooks/useShopNavigation';

export const ShopPage = () => {
    const { shopId, navigateToProduct } = useShopNavigation();

    return (
        <ShopContainer shopId={shopId}>
            <ShopCategoriesContainer shopId={shopId}>
                {(categoryId) =>
                    <ShopListContainer shopId={shopId}
                        categoryId={categoryId}
                        onProductClick={navigateToProduct} />
                }
            </ShopCategoriesContainer>
        </ShopContainer>
    );
}
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { ListingPricing, ListingPricingType } from '../../../types/db';
import { DEFAULT_CURRENCY } from '../../../utilities/currency/function';
import { useTranslation } from 'react-i18next';
import { CurrencyMenu } from '../../../components/CurrencyMenu';
import { isDiscountValid, isPriceValid, isSamePricing, useCurrencyChangeCallback, usePricingOnBlurCallback, isCohostPriceValid, useGuestPricingChangeCallback, useCohostPricingChangeCallback, useCohostOpenListingCheckboxCallback, useCohostPricingPerkTypeChoiceCallback } from '../../../utilities/listings';
import { ListingEditContext } from '../../../context/Listing/ListingEditFlow';
import { ListingEditSectionView } from './ListingEditSectionView';
import { DiscountInput, IDiscountInputProps, IPricingInputProps, PricingInput, PricingInputFieldName } from '../../../components/listing/PricingInput';
import { PricingDisplay } from '../../../components/listing/PricingDisplay';

/** The pricing page of the listing creation flow */
export const ListingPricingPage = () => {
    return <Container />
}

const Container = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { isLoading } = listingEditContext;
    if (isLoading) {
        return <CircularProgress />
    }
    return (
        <Stack direction={'column'} spacing={2}>
            <ListingGuestPricing />
            <Divider />
            <ListingCohostPricing />
            <Divider />
            <ListingPricingCurrency />
        </Stack>
    );
}

type RadioChoice = ListingPricingType;

type CoHostPerkChoice = "none" | "price_discount" | "custom_price";

export const ListingGuestPricing = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { listing: { pricing }, setActivePageInfo, handleCancel, handleSave, completed } = listingEditContext;
    //The currency for the prices
    const currency = pricing?.nightly_price?.currency || DEFAULT_CURRENCY;

    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (!editing) return;//if not editing, we don't check
        if (pricing?.type === 'non-bookable'
            || pricing?.type === 'sum-sub-listing'
            || (pricing?.type === 'unit-bookable' && isPriceValid(pricing))) {
            setActivePageInfo({}, true)
        } else {
            setActivePageInfo({}, false)//reset to false
        }
    }, [editing, pricing, setActivePageInfo])

    //The price type
    const radioValue: RadioChoice | '' = pricing?.type || '';
    const handleRadioValueChange = (newRadioValue: RadioChoice) => {
        setActivePageInfo({ pricing: { type: newRadioValue } })
    }

    //guest pricing
    const [guestPricingInputErrors, setGuestPricingErrors] = useState<{ [key in PricingInputFieldName]?: string }>({});
    const guestPricingProps: Pick<IPricingInputProps, PricingInputFieldName> =
    {
        nightlyPrice: {
            value: pricing?.nightly_price?.amount?.toString() || '',
            helperText: guestPricingInputErrors['nightlyPrice'] || '',
            error: !!guestPricingInputErrors['nightlyPrice']
        },
        weeklyDiscount: {
            value: pricing?.weekly_discount?.toString() || '',
            helperText: guestPricingInputErrors['weeklyDiscount'] || '',
            error: !!guestPricingInputErrors['weeklyDiscount']
        },
        monthlyDiscount: {
            value: pricing?.monthly_discount?.toString() || '',
            helperText: guestPricingInputErrors['monthlyDiscount'] || '',
            error: !!guestPricingInputErrors['monthlyDiscount']
        }
    }

    const handleGuestPricingChange = useGuestPricingChangeCallback(setActivePageInfo, setGuestPricingErrors);

    const handleGuestPricingOnBlur = usePricingOnBlurCallback(t, setGuestPricingErrors);

    return <ListingEditSectionView title={t('listing.edit.guestPricingTitle')}
        isEditMode={editing}
        onEditClick={() => setEditing(true)}
        onCancelClick={() => { handleCancel(); setEditing(false) }}
        onSaveClick={() => {
            if (!completed) return;
            handleSave().then(() => setEditing(false))
        }}
        isSaveDisabled={!completed}>
        {
            !editing ?
                <>
                    <Typography>
                        {!radioValue ?
                            t('listing.edit.errorPricingTypeNotSet')//should only happen if the listing was pending creation
                            : radioValue === 'unit-bookable' ?
                                "" //don't want to show anything if custom price as will show the amounts
                                :
                                t('listing.creation.pricingChoice', { context: radioValue === 'non-bookable' ? 'none' : radioValue === 'sum-sub-listing' ? 'sum' : 'unit' })
                        }
                    </Typography>
                    {radioValue === 'unit-bookable' &&
                        !!pricing &&
                        <PricingDisplay
                            currency={(pricing as ListingPricing).nightly_price.currency}
                            nightlyPrice={(pricing as ListingPricing).nightly_price.amount}
                            weeklyDiscount={(pricing as ListingPricing).weekly_discount || 0}
                            monthlyDiscount={(pricing as ListingPricing).monthly_discount || 0}
                        />
                    }
                </>
                :
                <Stack direction={'column'}
                    alignItems={'stretch'}
                    spacing={2}>
                    <FormControl>
                        <RadioGroup
                            value={radioValue}
                            onChange={(ev) => handleRadioValueChange(ev.target.value as RadioChoice)}
                        >
                            <FormControlLabel
                                value={"non-bookable" as RadioChoice}
                                control={<Radio />}
                                label={t('listing.creation.pricingChoice', { context: 'none' })}
                            />
                            <FormControlLabel
                                value={"sum-sub-listing" as RadioChoice}
                                control={<Radio />}
                                label={t('listing.creation.pricingChoice', { context: 'sum' })}
                            />
                            <FormControlLabel
                                value={"unit-bookable" as RadioChoice}
                                control={<Radio />}
                                label={t('listing.creation.pricingChoice', { context: 'unit' })}
                            />
                        </RadioGroup>
                    </FormControl>
                    {radioValue === "unit-bookable" &&
                        <PricingInput
                            currency={currency}
                            {...guestPricingProps}
                            handleOnChange={handleGuestPricingChange}
                            handleOnBlur={handleGuestPricingOnBlur} />
                    }
                </Stack>
        }
    </ListingEditSectionView>
}

export const ListingCohostPricing = ({ checkboxControl = true, title }: { checkboxControl?: boolean, title?: string }) => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { listing: { pricing, cohost_perk }, setActivePageInfo, handleCancel, handleSave, completed } = listingEditContext;
    //The currency for the prices
    const currency = pricing?.nightly_price?.currency || DEFAULT_CURRENCY;

    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (!editing) return;//if not editing, we don't check
        if (!cohost_perk?.open) {
            //if no cohost perk (checkbox unchecked)
            setActivePageInfo({}, true)
        } else if (!!cohost_perk
            && ((typeof cohost_perk.pricing === 'number') ? isDiscountValid(cohost_perk.pricing, true) : isPriceValid(cohost_perk.pricing))
            // && isCohostPriceValid(pricing, cohost_perk.pricing)//will let it happen even if not valid, showing warning to user
        ) {
            //if cohost perk, check if both pricing are valid
            setActivePageInfo({}, true)
        } else {
            setActivePageInfo({}, false)//reset to false
        }
    }, [cohost_perk, editing, pricing, setActivePageInfo])

    //cohost perk
    const cohostChecked = !!cohost_perk && cohost_perk.open;
    const handleCohostCheckChange = useCohostOpenListingCheckboxCallback(setActivePageInfo);

    const cohostPerks: CoHostPerkChoice | '' =
        typeof cohost_perk?.pricing === 'number' ? 'price_discount' //if it's a number it's a discount of the guest price
            : isSamePricing(pricing, cohost_perk?.pricing) ? 'none' //if exactly the same price as the guest pricing no perk in pricing
                : !!cohost_perk?.pricing ? 'custom_price' //if a price is indicated (and not same as guest above) it's a custom price
                    : '';//else no choice yet
    const handleCohostPerkChoiceChange = useCohostPricingPerkTypeChoiceCallback(setActivePageInfo);

    const [cohostPricingInputErrors, setCohostPricingErrors] = useState<{ [key in (PricingInputFieldName | 'discount')]?: string }>({});
    const cohostPricingProps: Pick<IPricingInputProps, PricingInputFieldName> | IDiscountInputProps =
        !!cohost_perk?.pricing ?
            typeof cohost_perk.pricing === 'number' ?
                {
                    value: cohost_perk.pricing.toString(),
                    helperText: cohostPricingInputErrors['discount'],
                    error: !!cohostPricingInputErrors['discount'],
                }
                :
                {
                    nightlyPrice: {
                        value: cohost_perk.pricing.nightly_price?.amount?.toString() || '',
                        helperText: cohostPricingInputErrors['nightlyPrice'] || '',
                        error: !!cohostPricingInputErrors['nightlyPrice']
                    },
                    weeklyDiscount: {
                        value: cohost_perk.pricing.weekly_discount?.toString() || '',
                        helperText: cohostPricingInputErrors['weeklyDiscount'] || '',
                        error: !!cohostPricingInputErrors['weeklyDiscount']
                    },
                    monthlyDiscount: {
                        value: cohost_perk.pricing.monthly_discount?.toString() || '',
                        helperText: cohostPricingInputErrors['monthlyDiscount'] || '',
                        error: !!cohostPricingInputErrors['monthlyDiscount']
                    }
                }
            : {};

    const handleCohostPricingChange = useCohostPricingChangeCallback(setActivePageInfo, setCohostPricingErrors);

    const handlePricingOnBlur = usePricingOnBlurCallback(t, setCohostPricingErrors);

    if (pricing?.type === 'non-bookable') {
        //if the listing is not bookable, no special pricing for cohost
        return null;
    }

    return <ListingEditSectionView title={title || t('listing.edit.cohostPricingTitle')}
        isEditMode={editing}
        onEditClick={() => setEditing(true)}
        onCancelClick={() => { handleCancel(); setEditing(false) }}
        onSaveClick={() => {
            if (!completed) return;
            handleSave().then(() => setEditing(false))
        }}
        isSaveDisabled={!completed}>
        {
            !editing ?
                <>
                    <Typography>{
                        !cohostPerks || cohostPerks === 'none' ?
                            t('listing.edit.cohostPricingDisabled')
                            :
                            cohostPerks === 'price_discount' ?
                                t('listing.pricing.discountDisplay', { discount: cohost_perk?.pricing || 0 })//`Discounted: ${cohost_perk.pricing}%`
                                : ""//Custom price
                    }</Typography>
                    {!!cohost_perk?.pricing && cohostPerks !== 'none' && !isCohostPriceValid(pricing, cohost_perk.pricing) &&
                        <Alert severity='warning'>{t('listing.pricing.warningCohostPriceHigh')}</Alert>
                    }
                    {
                        !!cohost_perk &&
                        (cohostPerks === 'custom_price' ?
                            <PricingDisplay
                                currency={(cohost_perk.pricing as ListingPricing).nightly_price.currency}
                                nightlyPrice={(cohost_perk.pricing as ListingPricing).nightly_price.amount}
                                weeklyDiscount={(cohost_perk.pricing as ListingPricing).weekly_discount || 0}
                                monthlyDiscount={(cohost_perk.pricing as ListingPricing).monthly_discount || 0}
                            />
                            : cohostPerks === 'price_discount' && !!pricing?.nightly_price?.amount ?
                                <PricingDisplay
                                    currency={pricing.nightly_price.currency}
                                    nightlyPrice={pricing.nightly_price.amount}
                                    weeklyDiscount={pricing.weekly_discount || 0}
                                    monthlyDiscount={pricing.monthly_discount || 0}
                                    extraDiscount={cohost_perk?.pricing as number}
                                />
                                :
                                null
                        )
                    }
                </>
                :
                <FormControl>
                    {!!checkboxControl &&
                        <FormControlLabel control={
                            <Checkbox
                                checked={cohostChecked}
                                onChange={(_, checked) => handleCohostCheckChange(checked)} />}
                            label={t('listing.creation.openToCoHost')} />
                    }
                    {
                        cohostChecked &&
                        <>
                            {cohostPerks === 'custom_price' && !isCohostPriceValid(pricing, cohost_perk.pricing) &&
                                <Alert severity='warning'>{t('listing.pricing.warningCohostPriceHigh')}</Alert>
                            }
                            <RadioGroup
                                value={cohostPerks}
                                onChange={(ev) => {
                                    handleCohostPerkChoiceChange(ev.target.value as CoHostPerkChoice)
                                }}
                            >
                                <FormControlLabel
                                    value={"none" as CoHostPerkChoice}
                                    control={<Radio />}
                                    label={t('listing.creation.perkCoHost', { context: "none" })}
                                />
                                <FormControlLabel
                                    value={"price_discount" as CoHostPerkChoice}
                                    control={<Radio />}
                                    label={t('listing.creation.perkCoHost', { context: "discount" })}
                                />
                                <FormControlLabel
                                    value={"custom_price" as CoHostPerkChoice}
                                    control={<Radio />}
                                    label={t('listing.creation.perkCoHost', { context: "custom" })}
                                />
                            </RadioGroup>
                            {
                                cohostPerks === 'price_discount' ?
                                    <DiscountInput label={t('listing.pricing.discountLabel')}
                                        placeholder={t('listing.pricing.discountPlaceholder')}
                                        {...(cohostPricingProps as IDiscountInputProps)}
                                        onChange={(ev) => handleCohostPricingChange('discount', ev.target.value)}
                                        onBlur={(ev) => handlePricingOnBlur('discount', ev.target.value)}
                                    />
                                    : cohostPerks === 'custom_price' ?
                                        <PricingInput
                                            currency={currency}
                                            {...(cohostPricingProps as IPricingInputProps)}
                                            handleOnChange={handleCohostPricingChange}
                                            handleOnBlur={handlePricingOnBlur} />
                                        : null
                            }
                        </>
                    }
                </FormControl>
        }
    </ListingEditSectionView>
}

const ListingPricingCurrency = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { listing: { pricing }, setActivePageInfo, handleCancel, handleSave, completed } = listingEditContext;
    //The currency for the prices
    const currency = pricing?.nightly_price?.currency || DEFAULT_CURRENCY;

    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);

    const handleCurrencyChange = useCurrencyChangeCallback(setActivePageInfo);
    if (pricing?.type === 'non-bookable') {
        //if the listing is not bookable, no currency to set-up
        return null;
    }

    return <ListingEditSectionView title={t('listing.edit.pricingCurrencyTitle')}
        isEditMode={editing}
        onEditClick={() => setEditing(true)}
        onCancelClick={() => { handleCancel(); setEditing(false) }}
        onSaveClick={() => {
            if (!completed) return;
            handleSave().then(() => setEditing(false))
        }}
        isSaveDisabled={!completed}>
        {
            !editing ?
                <Typography>{currency}</Typography>
                :
                <CurrencyMenu
                    currency={currency}
                    onCurrencyChange={handleCurrencyChange}
                />
        }
    </ListingEditSectionView>
}


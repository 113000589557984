import { Avatar, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EForumTopic } from '../../../types/db';
import { PropsWithLoadingState } from '../../../utilities';

export interface IForumPostItemProps {
    /** The topic of the post (optional), will show a little flag if provided */
    topic?: EForumTopic;
    /** The author of the post */
    author: {
        avatar: string;
        displayname: string;
    },
    /** The title of the post */
    title: string;
    /** The content of the post (will be truncated) */
    content: string;
    /** the timestamp of the post */
    timestamp: Date;
    /** The number of replies to the post */
    repliesCount: number;
    /** The number of likes on the post */
    likesCount: number;
    /** Callback on click on the item */
    onClick: () => void;
}

export const ForumPostItem = ({ topic, author, title, content, timestamp, repliesCount, likesCount, onClick, loading }: PropsWithLoadingState<IForumPostItemProps>) => {
    const { t } = useTranslation();
    return (
        <Grid container onClick={onClick}>
            <Grid item xs={2} md={1}>
                <Stack direction={'column'} justifyContent={'start'} alignItems={'center'}>
                    {!loading ? <Avatar src={author.avatar} /> : <Skeleton variant={"circular"}><Avatar /></Skeleton>}
                </Stack>
            </Grid>
            <Grid item xs={10} md={11}>
                <Stack direction={'column'} justifyContent={'space-between'} alignItems={'stretch'} spacing={1}>
                    <Typography sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        wordBreak: 'break-word',
                        textDecoration: "underline",
                    }} variant='subtitle2'>
                        {!loading ? author.displayname : <Skeleton width={'20%'} />}
                    </Typography>
                    <Typography sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                    }}
                        variant='h4'>
                        {!loading ? title : <Skeleton width={'50%'} />}
                    </Typography>
                    <Typography sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                    }}
                        variant='body1'>
                        {!loading ? content : <Skeleton />}
                    </Typography>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography>{!loading ? timestamp.toDateString() : <Skeleton width={40} />}</Typography>
                        <Stack direction={'row'} justifyContent={'end'} spacing={1}>
                            <Typography justifySelf={'end'}>
                                {!loading ? t('forum.post.repliesCount', { count: repliesCount || 0 }) : <Skeleton width={20} />}
                            </Typography>
                            <Typography justifySelf={'end'}>
                                {!loading ? t('forum.post.likesCount', { count: likesCount || 0 }) : <Skeleton width={20} />}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Divider />
            </Grid>
        </Grid>
    );
}
import React from 'react';
import { Skeleton, Stack, Typography } from '@mui/material';
import { HelpTopicSectionItem, IHelpTopicSectionItemProps } from '../HelpTopicSectionItem';
import { PropsWithLoadingState } from '../../../utilities';


export interface IHelpTopicSectionProps {
    /** The display title of the topic section */
    title: string;
    /** The list of subtopics */
    items: IHelpTopicSectionItemProps[];
}

/** The display of a topic section (topic with sub-topics) */
export const HelpTopicSection = ({
    title,
    items,
    loading
}: PropsWithLoadingState<IHelpTopicSectionProps>) => {
    return (
        <Stack direction={'column'} justifyContent={'start'} alignItems={'stretch'} spacing={2}>
            <Typography variant='h5' textAlign={'center'}>{!loading ? title : <Skeleton variant={'text'} />}</Typography>
            <Stack direction={'column'} justifyContent={'start'} alignItems={'stretch'} spacing={1}>
                {
                    !loading ?
                        items.map((item, index) =>
                            <HelpTopicSectionItem
                                key={`topic-section-item-${index}`}
                                {...item} />
                        )
                        :
                        new Array(6).fill('').map((_, index) =>
                            <HelpTopicSectionItem loading key={`loading-topic-section-${index}`} />
                        )
                }
            </Stack>
        </Stack >
    );
}
import React from 'react';
import { useQuery } from 'react-query';
import { HelpTopic } from '../../../components/help';
import { HelpPage } from '../../../components/help/HelpPage';
import { getTopicInfo } from '../../../services/help';
import { ROUTE_PATH } from '../../../utilities/navigation';

export interface IHelpTopicContainerProps {
    /** The specific topic ID to display */
    topicId: string;
    /** Callback on topic link clicked */
    onTopicClicked: (topicId?: string) => void;
    /** Callback on article link clicked */
    onArticleClicked: (articleId: string) => void;
}

/** Container for one specific topic ID */
export const HelpTopicContainer = ({
    topicId,
    onTopicClicked,
    onArticleClicked,
}: IHelpTopicContainerProps) => {
    const { data, isLoading, isError } = useQuery(
        ["topic", topicId],
        () => getTopicInfo(topicId));

    if (isLoading) {
        return <HelpPage loading><HelpTopic loading /></HelpPage>
    }
    if (isError || !data) {
        return <HelpTopic error={{ message: "", onRetryClick: () => onTopicClicked() }} />
    }

    return (
        <HelpPage breadcrumbs={data.breadcrumbs?.map(b => ({
            label: b.title,
            onClick: () => onTopicClicked(b.topic_id),
        }))
            ?? []}
            title={data.title}
            onHomeClick={() => window.location.assign(ROUTE_PATH.home)}
            onAllTopicClick={() => onTopicClicked()}>
            <HelpTopic
                title={data.title}
                articles={
                    data.sub_articles?.map(article =>
                    ({
                        title: article.title,
                        description: article.description || '',
                        onClick: () => onArticleClicked(article.article_id),
                    })
                    ) ?? []
                }
                subTopics={
                    data.sub_topics?.map(topic =>
                    ({
                        title: topic.title,
                        onClick: () => onTopicClicked(topic.topic_id),
                    })
                    ) ?? []
                }
                relatedTopics={
                    data.related_topics?.map(topic =>
                    ({
                        title: topic.title,
                        onClick: () => onTopicClicked(topic.topic_id),
                    })
                    ) ?? []
                }
            />
        </HelpPage>
    );
}

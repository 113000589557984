import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";

export interface ICategoryItemProps {
  /** The image url of the category */
  imgSrc: string;
  /** The title of the category */
  title: string;
  /** The short description of the category */
  description: string;
  /** Callback onClick */
  onClick(): void;
}

export const CategoryItem = ({
  imgSrc,
  title,
  description,
  onClick,
}: ICategoryItemProps) => {
  return (
    <Card sx={{
      height: '100%',//take all the height it can
      borderRadius: 5,
      transition: "transform 0.15s ease-in-out",
      ':hover': { transform: "scale3d(1.03, 1.03, 1)" },
      cursor: 'pointer',
    }} elevation={2} onClick={onClick}>
      <CardMedia sx={{ height: '20vh' }} component={'img'} image={imgSrc} />
      <CardContent>
        <Typography fontWeight={'bold'} variant={'h5'}>{title}</Typography>
        <Typography variant={'subtitle2'}>{description}</Typography>
      </CardContent>
    </Card>
  )
};

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_CURRENCY, SUPPORTED_CURRENCIES } from '../../utilities/currency/function';
import { CURRENCY_SYMBOLS } from '../../utilities/currency/symbols'

interface ICurrencyMenuProps {
    /** The currency code */
    currency: string;
    /** Callback on currency change */
    onCurrencyChange: (currency: string) => void;
}

export const CurrencyMenu = ({ currency = DEFAULT_CURRENCY, onCurrencyChange }: ICurrencyMenuProps) => {
    const { t } = useTranslation();

    const handleChange = (event: SelectChangeEvent<string>) => {
        onCurrencyChange(event.target.value)
    }

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel>{t('listing.pricing.currencyLabel')}</InputLabel>
            <Select
                value={currency}
                label={t('listing.pricing.currencyLabel')}
                onChange={handleChange}
            >
                {
                    SUPPORTED_CURRENCIES.map((currencyCode) => {
                        return <MenuItem key={currencyCode} value={currencyCode}>{`${currencyCode} (${CURRENCY_SYMBOLS[currencyCode]})`}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    );
}
import React, { useState } from 'react';
import { Button, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { IShopPriceProps, ShopPrice } from '../ShopPrice';
import { IShopReviewsSummaryProps, ShopReviewsSummary } from '../ShopReviewsSummary';
import { IShopVariantsSelectProps, ShopVariantsSelect } from '../ShopVariants/ShopVariantsSelect';
import { IssueStateView } from '../../IssueStateView';
import { SHOP_PRODUCT_EMPTY_URL, SHOP_PRODUCT_ERROR_URL } from '../../../utilities/assets/assetHelper';
import { useTranslation } from 'react-i18next';
import { PropsWithErrorState, PropsWithLoadingState } from '../../../utilities';

export interface IShopProductDetailsProps {
    title: string;
    description: string;
    images: string[];
    reviews?: IShopReviewsSummaryProps;
    price: IShopPriceProps;
    variants?: IShopVariantsSelectProps;
    onAddToCartClick: () => void;
}

export const ShopProductDetails = ({
    title,
    description,
    images,
    reviews,
    price,
    variants,
    onAddToCartClick,
    loading,
    error,
}: PropsWithErrorState<PropsWithLoadingState<IShopProductDetailsProps>>) => {
    const { t } = useTranslation();
    const [selectedImage, setSelectedImage] = useState(0);

    if (!!error) {
        if (typeof error === 'boolean') {
            return <IssueStateView
                title={t('shop.product.errorTitle')}
                image={SHOP_PRODUCT_ERROR_URL}
                description={t('shop.product.errorContent')}
            // button={{
            //         text: t('shop.product.errorButton'),
            //         onActionClick: error.onRetryClick
            //     }}
            />;
        }
        return <IssueStateView
            title={t('shop.product.emptyTitle')}
            image={SHOP_PRODUCT_EMPTY_URL}
            description={t('shop.product.emptyContent')}
            button={typeof error !== 'boolean' && !!error.onRetryClick ?
                {
                    text: t('shop.product.emptyButton'),
                    onActionClick: error.onRetryClick
                }
                : undefined}
        />
    }

    return (
        <Grid container spacing={1} padding={2}>
            <Grid item xs={12}>
                <Typography variant={'h2'}>{!loading ? title : <Skeleton />}</Typography>
            </Grid>
            <Grid item container xs={12} sm={6} spacing={1}>
                <Grid item xs={12} xl={(!!loading || (images.length > 1)) ? 10 : 12}>

                    {/* <Paper> */}
                    {!loading ?
                        <img src={images[selectedImage]}
                            alt={`${title} ${selectedImage + 1}`}
                            style={{
                                objectFit: 'contain',
                                height: '50vh',
                                width: '100%',
                            }} />
                        :
                        <Skeleton variant='rectangular'
                            style={{
                                height: '50vh',
                                width: '100%',
                            }}
                        />
                    }
                    {/* </Paper> */}
                </Grid>
                {
                    (!!loading || images.length > 1) && //only display if more than one image or loading
                    <Grid item xs={12} xl={2}>
                        <Stack direction={{ xs: 'row', xl: 'column' }}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                            spacing={1}
                            sx={{ flex: 1, scrollBehavior: 'auto', overflow: 'scroll' }}>
                            {
                                !loading ?
                                    images.map((image, index) =>
                                        <img
                                            key={`image-${index}`}
                                            onClick={() => setSelectedImage(index)}
                                            src={image}
                                            alt={`${title} ${selectedImage + 1}`}
                                            style={{
                                                objectFit: 'cover',
                                                borderColor: 'black',
                                                borderWidth: 5,
                                                width: '10vw',
                                                height: '10vw',
                                            }}
                                        />
                                    )
                                    :
                                    new Array(4).fill('').map((_, index) =>
                                        <Skeleton key={`image-loading-${index}`}
                                            style={{
                                                width: '10vw',
                                                height: '10vw',
                                            }}
                                        />
                                    )
                            }
                        </Stack>
                    </Grid>
                }
            </Grid>
            <Grid item container xs={12} sm={6}>
                <Stack direction={'column'}
                    justifyContent={'start'}
                    alignItems={'stretch'}
                    spacing={1}
                    width={'100%'}>
                    {!!reviews && <ShopReviewsSummary {...reviews} />}
                    <Typography display={{ xs: 'none', md: 'inline' }}>{!loading ? description : <Skeleton />}</Typography>
                    {!!variants && <ShopVariantsSelect {...variants} onVariantSelect={(selection) => { setSelectedImage(0); variants.onVariantSelect(selection) }} />}
                    <Stack direction={{ xs: 'column', md: 'row' }}
                        justifyContent={'center'}
                        alignItems={'center'}
                        spacing={1}
                    >
                        {!loading ? <ShopPrice {...price} /> : <Skeleton variant='rectangular' height={50} />}
                        <Button variant={'contained'}
                            onClick={onAddToCartClick}
                            disabled={!!loading}>{t('shop.product.addToCartButton')}</Button>
                    </Stack>
                    <Typography display={{ xs: 'inline', md: 'none' }}>{!loading ? description : <Skeleton />}</Typography>
                </Stack>
            </Grid>
        </Grid>
    );
}
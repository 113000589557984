import React, { useEffect, useState } from 'react';

import { EditableAddress, ViewableAddress } from './BasicAddress';
import { IAddressProps } from './Address.types';
import { COUNTRIES_INFO } from './Address.data';
import { Address as AddressObject, DEFAULT_ADDRESS, FieldName } from './Address.utils';

const DEFAULT_COUNTRY = DEFAULT_ADDRESS['country'];

export const Address = ({ editable, address, onChange, ...props }: IAddressProps) => {
  const [country, setCountry] = useState(address?.country || DEFAULT_COUNTRY);

  useEffect(() => {
    setCountry(address?.country || DEFAULT_COUNTRY)
  }, [address?.country])

  const handleAddressChange = (newAddress: AddressObject) => {
    setCountry(newAddress.country || DEFAULT_COUNTRY);
    // console.log('onChangeAddress', newAddress);
    onChange?.(newAddress);
  };

  if (editable || !!props.editableFields?.length) {
    const layout = [
      ...(COUNTRIES_INFO[country]?.layout ?? COUNTRIES_INFO[DEFAULT_COUNTRY].layout),
      FieldName.Email
    ]
    return (
      <EditableAddress
        layout={layout}
        address={address}
        onChange={handleAddressChange}
        {...props}
      />
    );
  }

  const format = `${COUNTRIES_INFO[country]?.formatting ?? COUNTRIES_INFO[DEFAULT_COUNTRY].formatting}\n{email}`
  return (
    <ViewableAddress
      format={format}
      address={address}
      {...props}
    />
  );
};

import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Groups3, Settings } from '@mui/icons-material';

import { AccountBaseInfo, AccountBaseInfoItems } from '../../../components/user/account/AccountBaseInfo';
import { IAccountHelpItemProps } from '../../../components/user/account/AccountHelpItem';
import { getTitleFromAccountPage } from '../../../components/user/account/AccountPageView';
import { getUserPreferencesInfo, updateUserPreferencesInfo } from '../../../services/user';
import { IAccountSubContainerProps } from '../AccountSubContainer';
import { LANGUAGE_CODES } from '../../../utilities/i18n';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CURRENCY_INFO } from '../../../utilities/currency/names';
import { TIME_ZONES } from '../../../utilities/timezone';
import { ERole, getDBUserRoleFrom, getStringKeyForUserRole, getUIUserRoleFrom } from '../../../utilities/EnumUtility';

export const AccountPreferencesContainer = ({
    subPage,
    userId,
    children,
}: IAccountSubContainerProps) => {
    const { t } = useTranslation();
    const userPreferencesResult = useQuery(["account", "preferences"],
        () => getUserPreferencesInfo(userId));

    const userPreferencesInfo = userPreferencesResult.data;

    const [language, setLanguage] = useState(userPreferencesInfo?.language || 'en');
    const [currency, setCurrency] = useState(userPreferencesInfo?.currency || 'CAD');
    const [timeZone, setTimeZone] = useState(userPreferencesInfo?.time_zone || '');
    const [role, setRole] = useState<ERole | string>(!!userPreferencesInfo?.role ? getUIUserRoleFrom(userPreferencesInfo.role) || '' : '');

    const items: AccountBaseInfoItems =
        // !userPreferencesInfo ? [] :
        [
            {
                title: t('user.accountPrefs.languageTitle'),
                description: t('user.accountPrefs.languageContent'),
                value: t(userPreferencesInfo?.language || 'en', { ns: "languages" }),
                children:
                    <LanguageDropdown value={language} onChange={setLanguage} />,
                onSaveClick: () => {
                    return updateUserPreferencesInfo(userId, { language: language })
                        .then(() => { userPreferencesResult.refetch() });
                }
            },
            {
                title: t('user.accountPrefs.currencyTitle'),
                description: t('user.accountPrefs.currencyContent'),
                value: CURRENCY_INFO[userPreferencesInfo?.currency || 'CAD']?.name,
                children:
                    <CurrencyDropdown value={currency} onChange={setCurrency} />,
                onSaveClick: () => {
                    return updateUserPreferencesInfo(userId, { currency: currency })
                        .then(() => { userPreferencesResult.refetch() });
                }
            },
            {
                title: t('user.accountPrefs.timezoneTitle'),
                value: TIME_ZONES[userPreferencesInfo?.time_zone || ''],
                children:
                    <TimeZoneDropdown value={timeZone} onChange={setTimeZone} />,
                onSaveClick: () => {
                    return updateUserPreferencesInfo(userId, { time_zone: timeZone })
                        .then(() => { userPreferencesResult.refetch() });
                }
            },
            {
                title: t('user.accountPrefs.roleTitle'),
                description: t('user.accountPrefs.roleContent'),
                value: userPreferencesInfo?.role || t('user.account.infoNotSpecified'),
                children: <RoleDropdown value={role} onChange={setRole} />,
                onSaveClick: () => {
                    return updateUserPreferencesInfo(userId, { role: !role ? '' : getDBUserRoleFrom(role as ERole) || '' })
                        .then(() => { userPreferencesResult.refetch() });
                }
            }
        ]

    const helpItems = HELP_ITEMS.map(h => ({
        ...h,
        title: t(h.title),
        description: t(h.description)
    }))

    if (!!children) {
        return children({ items, helpItems: helpItems });
    } else {
        return <AccountBaseInfo title={getTitleFromAccountPage(t, subPage)}
            items={items}
            helpItems={helpItems} />
    }
}

const HELP_ITEMS: IAccountHelpItemProps[] = [
    {
        icon: <Settings />,
        title: 'user.accountPrefs.helpInfo1Title',
        description: 'user.accountPrefs.helpInfo1Content',
    },
    {
        icon: <Groups3 />,
        title: 'user.accountPrefs.helpInfo2Title',
        description: 'user.accountPrefs.helpInfo2Content',
    }
];

const LanguageDropdown = ({ value, onChange }: { value: string, onChange: (newValue: string) => void }) => {
    const { t } = useTranslation();

    const handleChange = (event: SelectChangeEvent) => {
        onChange(event.target.value as string);
    };

    return (
        <FormControl fullWidth>
            <InputLabel>{t('user.accountPrefs.languageLabel')}</InputLabel>
            <Select
                value={value}
                label={t('user.accountPrefs.languageLabel')}
                onChange={handleChange}
            >
                {LANGUAGE_CODES.map(lc => {
                    const nativeName = t(lc, { lng: lc, ns: "languages", defaultValue: '' });
                    const name = t(lc, { ns: "languages", defaultValue: '' });
                    return <MenuItem key={`language-${lc}`} value={lc}>{!nativeName ? name : `${nativeName} (${name})`}</MenuItem>
                }
                )}
            </Select>
        </FormControl>
    );
}

const CurrencyDropdown = ({ value, onChange }: { value: string, onChange: (newValue: string) => void }) => {
    // const { t } = useTranslation("currencies");//TODO translation for currency name?
    const { t } = useTranslation();
    const handleChange = (event: SelectChangeEvent) => {
        onChange(event.target.value as string);
    };

    return (
        <FormControl fullWidth>
            <InputLabel>{t('user.accountPrefs.currencyLabel')}</InputLabel>
            <Select
                value={value}
                label={t('user.accountPrefs.currencyLabel')}
                onChange={handleChange}
            >
                {Object.keys(CURRENCY_INFO).map(c => {
                    const currencyInfo = CURRENCY_INFO[c];
                    if (!currencyInfo) return null;
                    return <MenuItem key={`currency-${c}`} value={c}>{`${currencyInfo.name} (${currencyInfo.symbol})`}</MenuItem>
                }
                )}
            </Select>
        </FormControl>
    );
}

const TimeZoneDropdown = ({ value, onChange }: { value: string, onChange: (newValue: string) => void }) => {
    const { t } = useTranslation();
    const handleChange = (event: SelectChangeEvent) => {
        onChange(event.target.value as string);
    };

    return (
        <FormControl fullWidth>
            <InputLabel>{t('user.accountPrefs.timezoneLabel')}</InputLabel>
            <Select
                value={value}
                label={t('user.accountPrefs.timezoneLabel')}
                onChange={handleChange}
            >
                {Object.keys(TIME_ZONES).map(tz => {
                    return <MenuItem key={`time-zone-${tz}`} value={tz}>{`${TIME_ZONES[tz]}`}</MenuItem>
                }
                )}
            </Select>
        </FormControl>
    );
}

const NO_ROLE = "None";
const RoleDropdown = ({ value = NO_ROLE, onChange }: { value?: ERole | string, onChange: (newValue: ERole | string) => void }) => {
    const { t } = useTranslation();
    const handleChange = (event: SelectChangeEvent) => {
        onChange(event.target.value);
    };

    return (
        <FormControl fullWidth>
            <InputLabel>{t('user.accountPrefs.roleLabel')}</InputLabel>
            <Select
                value={value || NO_ROLE}
                label={t('user.accountPrefs.roleLabel')}
                onChange={handleChange}
            >
                <MenuItem key={`role-none`} value={NO_ROLE}>{t('user.accountPrefs.noRole')}</MenuItem>
                {Object.keys(ERole).map(role => {
                    return <MenuItem key={`role-${role}`} value={role}>{t(getStringKeyForUserRole(role as ERole))}</MenuItem>
                }
                )}
            </Select>
        </FormControl>
    );
}
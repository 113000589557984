import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';

import { IShopCartItemProps } from '../../../components/shop/ShopCartItem';
import { ShopCheckout } from '../../../components/shop/ShopCheckout';
import { getShopCartItems } from '../../../services/shop';
import { SHOP_STORE_FIELD, SHOP_VARIANT_ID_FIELD, UserAddress } from '../../../types/db';
import { DEFAULT_SHOP_ID, fetchPayCheckoutUrl } from '../../../utilities/shop';
import { UserContext } from '../../../context/UserInformation';
import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Address } from '../../../components/address/Address.utils';
import { ShopCheckoutContext } from '../../../context/shop/ShopCheckout';

export interface IShopCheckoutContainerProps {
    /** The shop Id the cart id from, default to CoHostOp shop */
    shopId?: string;
    /** When user want to go back to the detail about store/product/variant */
    onRedirectTo?: (productId?: string, variantId?: string) => void;
    /** When the user want to auth before checking out (non-guest checkout) */
    onAuthClick: (method: 'log-in' | 'sign-up') => void;
    /** When user want to checkout the current cart */
    onPayClick: (stripeUrl: string) => void;
}

const mapAddressDB2UI = (address: UserAddress): Address => {
    return {
        firstName: address.first_name,
        lastName: address.last_name,
        company: address.company,
        address1: address.line1,
        address2: address.line2,
        zip: address.zip,
        zone: address.state,
        city: address.city,
        country: address.country,
        phone: address.phone
    }
}

const mapAddressUI2DB = (address: Address, type: UserAddress['type']): UserAddress => {
    return {
        first_name: address.firstName || '',
        last_name: address.lastName || '',
        company: address.company,
        line1: address.address1,
        line2: address.address2,
        zip: address.zip,
        state: address.zone,
        city: address.city,
        country: address.country,
        phone: address.phone,
        type
    }
}

export const ShopCheckoutContainer = ({
    shopId = DEFAULT_SHOP_ID,
    onRedirectTo,
    onAuthClick,
    onPayClick,
}: IShopCheckoutContainerProps) => {
    const checkoutContext = useContext(ShopCheckoutContext);
    if (!checkoutContext) {
        throw new Error('Need ShopCheckoutContext')
    }
    const { t } = useTranslation();
    const userContext = useContext(UserContext);
    if (!userContext) {
        throw new Error('Need User Context')
    }
    //boolean indicating if showing the guest portal or not
    const [checkoutAsGuestDisplay, setCheckoutAsGuestDisplay] = useState(true);
    // fetching the details of the items to checkout
    const productVariantsArray = checkoutContext?.items.map(v => ({ productId: v.productId, variantId: v.variantId })) || [];
    const { data, isLoading, isError } = useQuery(
        ["cart", shopId],
        () => getShopCartItems(shopId, productVariantsArray),
        {
            enabled: productVariantsArray.length !== 0,
        }
    );

    if (checkoutAsGuestDisplay
        && (!userContext.isUserLoggedIn() || userContext.isUserAnonymous())) {
        const handleCheckoutMethod = (method: 'guest' | 'log-in' | 'sign-up') => {
            if (method === 'guest') {
                setCheckoutAsGuestDisplay(false);
                return;
            }
            onAuthClick(method);
        }
        return (
            <Grid container rowSpacing={2} columnSpacing={1} p={3}>
                <Grid item xs={12}>
                    <Typography variant={'h2'}>{t('shop.guestCheckout.title')}</Typography>
                </Grid>
                {
                    (t('shop.guestCheckoutChoices', { returnObjects: true, defaultValue: [] }) as Array<{ title: string, content: string, button: string }>)
                        .map(({ title, content, button }, index) =>
                            <Grid item key={`checkout-method-${index}`} xs={12} sm={4}>
                                <Paper variant='outlined' sx={{ height: '100%', p: 1 }}>
                                    <Stack direction={'column'} justifyContent={'space-between'} alignItems={'center'} height={'100%'}>
                                        <Typography variant={'h5'} fontWeight={'bold'}>{title}</Typography>
                                        <Typography>{content}</Typography>
                                        <Button onClick={() => handleCheckoutMethod(index === 0 ? 'guest' : index === 1 ? 'log-in' : 'sign-up')}>{button}</Button>
                                    </Stack>
                                </Paper>
                            </Grid>
                        )
                }
            </Grid>
        )
    }

    const handleBack = () => {
        onRedirectTo?.();
    }

    if (isLoading) {
        return <ShopCheckout loading />;
    }

    if (isError) {
        return <ShopCheckout error onBackClick={handleBack} />;
    }

    const handleQuantityChange = (variantId: string, newQuantity: number) => {
        checkoutContext.updateQuantityItem({ variantId: variantId, quantity: newQuantity });
    }

    const items: (IShopCartItemProps & { id: number })[] = data ?
        checkoutContext.items.map(item => {
            const itemData = data.find(v => v.id === item.variantId);
            const variantShopId = itemData?.[SHOP_STORE_FIELD]?.[SHOP_VARIANT_ID_FIELD] || 0;
            return {
                id: variantShopId,
                title: itemData?.name || '',
                image: itemData?.image || '',
                quantity: item.quantity || 1,
                onQuantityChange: (quantity) => handleQuantityChange(item.variantId, quantity),
                price: {
                    originalPrice: {
                        amount: itemData?.price.amount ?? 0,
                        currency: itemData?.price.currency || 'USD',
                    }
                },
            };
        })
        : [];

    const handleAddressChanged = (address: Address) => {
        checkoutContext.addAddress(mapAddressUI2DB(address, 'shipping'));
    };

    const handlePayClick = async () => {
        const address = checkoutContext.shippingAddress;
        if (!address) {
            //error
            return;
        }

        const res = await fetchPayCheckoutUrl(shopId, address)

        onPayClick(res.data);
    }

    return (
        <ShopCheckout items={items}
            shippingOptions={checkoutContext.shippingOptions}
            selectedShippingOptionID={checkoutContext.shippingOption?.id}
            onShippingOptionSelected={(id) => checkoutContext.selectShippingOption(checkoutContext.shippingOptions.find(val => val.id === id))}
            isShippingInfoLoading={checkoutContext.isLoading}
            taxes={checkoutContext.taxRate}
            onPayClick={handlePayClick}
            onBackClick={handleBack}
            address={checkoutContext.shippingAddress ? mapAddressDB2UI(checkoutContext.shippingAddress) : undefined}
            onAddressChanged={handleAddressChanged} />
    );
}

/** Generic file for common utility */

/**
 * Remove a specific key and its value from an Object
 */
export function removeKeyFromObject<T>(o: { [key: string]: T; }, keyToRemove: string): { [key: string]: T; } {
    return Object.keys(o || {}).filter(key => key !== keyToRemove).reduce((obj, key) => {
        obj[key] = o[key];
        return obj;
    }, {} as { [key: string]: T; }
    );
}

/**
 * Trim object, return an object which doesn't contain the keys which have undefined value
 */
export function trimObject(o: { [key: string]: any; }) {
    return Object.keys(o || {}).filter(key => o[key] !== undefined).reduce((obj, key) => {
        obj[key] = o[key];
        return obj;
    }, {} as { [key: string]: any; }
    );
}

/**
 * Function which hide partially the email (for security reason)
 * @param email The email to hide (if not provided return '')
 * @returns the email partially hidden
 */
export const hideEmailDetails = (email?: string | null) => {
    if (!email) return '';
    const split = email.split('@');
    return `${split[0][0]}***${split[0][split[0].length - 1]}@${split[1]}`;
}

/**
 * Function which hide partially the phone number (for security reason)
 * @param phone The phone number to hide (if not provided return '')
 * @returns The phone number partially
 */
export const hidePhoneDetails = (phone?: string | null) => {
    if (!phone) return '';
    return `***${phone.substring(phone.length - 4)}`
}

/** 
 * Function which simply check if the email provided is a valid email
 * @param The input to check
 * @return boolean true if valid email, false otherwise
 */
export const isEmailValid = (input: string): boolean => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    return !!input.match(validRegex);
}

/** Type which allow to make any props a loadable props */
export type PropsWithLoadingState<T> = (T & { loading?: false }) | (T extends Array<any> ? { loading: true, length?: number } : (Partial<T> & { loading: true }));

/** Type which allow to make any props a loadable props */
export type PropsWithErrorState<T> = (T & { error?: false }) | (T extends Array<any> ? { error: true | { message: string, onRetryClick?: () => void } } : (Partial<T> & { error: true | { message: string, onRetryClick?: () => void } }));

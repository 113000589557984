

const addressNameFormat: { [key: string]: 'short_name' | 'long_name' } = {
    'street_number': 'short_name',
    'route': 'long_name',
    'locality': 'long_name',
    'administrative_area_level_1': 'short_name',
    'country': 'long_name',
    'postal_code': 'short_name',
};

/** Helper function to get the line address info from the place */
export const getAddressComp = function (place: google.maps.places.PlaceResult, type: string, format?: 'short_name' | 'long_name') {
    if (!place.address_components) {
        return '';
    }
    for (const component of place.address_components) {
        if (component.types[0] === type) {
            return component[format || addressNameFormat[type] || 'long_name'];
        }
    }
    return '';
};


type GooglePlaceLineType =
    "street_address"// indicates a precise street address.
    | "route"// indicates a named route (such as "US 101").
    | "intersection"// indicates a major intersection, usually of two major roads.
    | "political"// indicates a political entity. Usually, this type indicates a polygon of some civil administration.
    | "country"// indicates the national political entity, and is typically the highest order type returned by the Geocoder.
    | "administrative_area_level_1"// indicates a first-order civil entity below the country level. Within the United States, these administrative levels are states. Not all nations exhibit these administrative levels. In most cases, administrative_area_level_1 short names will closely match ISO 3166-2 subdivisions and other widely circulated lists; however this is not guaranteed as our geocoding results are based on a variety of signals and location data.
    | "administrative_area_level_2"// indicates a second-order civil entity below the country level. Within the United States, these administrative levels are counties. Not all nations exhibit these administrative levels.
    | "administrative_area_level_3"// indicates a third-order civil entity below the country level. This type indicates a minor civil division. Not all nations exhibit these administrative levels.
    | "administrative_area_level_4"// indicates a fourth-order civil entity below the country level. This type indicates a minor civil division. Not all nations exhibit these administrative levels.
    | "administrative_area_level_5"// indicates a fifth-order civil entity below the country level. This type indicates a minor civil division. Not all nations exhibit these administrative levels.
    | "administrative_area_level_6"// indicates a sixth-order civil entity below the country level. This type indicates a minor civil division. Not all nations exhibit these administrative levels.
    | "administrative_area_level_7"// indicates a seventh-order civil entity below the country level. This type indicates a minor civil division. Not all nations exhibit these administrative levels.
    | "colloquial_area"// indicates a commonly-used alternative name for the entity.
    | "locality"// indicates an incorporated city or town political entity.
    | "sublocality"// indicates a first-order civil entity below a locality. For some locations may receive one of the additional types: sublocality_level_1 to sublocality_level_5. Each sublocality level is a civil entity. Larger numbers indicate a smaller geographic area.
    | "neighborhood"// indicates a named neighborhood
    | "premise"// indicates a named location, usually a building or collection of buildings with a common name
    | "subpremise"// indicates a first-order entity below a named location, usually a singular building within a collection of buildings with a common name
    | "plus_code"// indicates an encoded location reference, derived from latitude and longitude. Plus codes can be used as a replacement for street addresses in places where they do not exist (where buildings are not numbered or streets are not named). See https://plus.codes for details.
    | "postal_code"// indicates a postal code as used to address postal mail within the country.
    | "natural_feature"// indicates a prominent natural feature.
    | "airport"// indicates an airport.
    | "park"// indicates a named park.
    | "point_of_interest"// indicates a named point of interest. Typically, these "POI"s are prominent local entities that don't easily fit in another category, such as "Empire State Building" or "Eiffel Tower".;
    //This list is not exhaustive, and is subject to change.
    | "floor"// indicates the floor of a building address.
    | "establishment"// typically indicates a place that has not yet been categorized.
    | "landmark"// indicates a nearby place that is used as a reference, to aid navigation.
    | "point_of_interest"// indicates a named point of interest.
    | "parking"// indicates a parking lot or parking structure.
    | "post_box"// indicates a specific postal box.
    | "postal_town"// indicates a grouping of geographic areas, such as locality and sublocality, used for mailing addresses in some countries.
    | "room"// indicates the room of a building address.
    | "street_number"// indicates the precise street number.
    | "bus_station" | "train_station" | "transit_station" // indicate the location of a bus, train or public transit stop.

const mapAddress = (address: google.maps.places.PlaceResult, options: { [key: string]: GooglePlaceLineType }) => {
    //the GooglePlaceLineType we are looking for
    const valueOptions = Object.values(options);
    // the params we want it mapped to
    const keyOptions = Object.keys(options);

    let addressObject: { [key: string]: { short_name: string, long_name: string } } = {};

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
        const componentType = component.types[0] as GooglePlaceLineType;
        const index = valueOptions.findIndex(v => v === componentType);
        if (index === -1) {
            continue;//nothing to map to
        }
        addressObject = {
            ...addressObject,
            [keyOptions[index]]: {
                short_name: component.short_name,
                long_name: component.long_name,
            },
        }
    }
    //fill up the keys not found with empty information
    for (const key of keyOptions) {
        if (!(key in addressObject)) {
            addressObject = {
                ...addressObject,
                [key]: {
                    short_name: "",
                    long_name: "",
                },
            }
        }
    }
    return addressObject;
}

export type SimplifiedAddress = {
    address1: string;
    address2?: string;
    city: string;
    zip: string;
    country_code: string;
    state_code: string;
}

export const getSimplifiedAddress = (address: google.maps.places.PlaceResult): SimplifiedAddress => {
    // console.log("Address to map", address);
    const addressObject = mapAddress(address, {
        "street_num": "street_number",
        "route": "route",
        "city": "locality",
        "postal_code": "postal_code",
        "state_code": "administrative_area_level_1",
        "country": "country"
    });

    // console.log("AddressObject", addressObject);

    return {
        address1: `${addressObject.street_num.long_name} ${addressObject.route.short_name}`,
        city: addressObject.city.long_name,
        zip: addressObject.postal_code.long_name,
        state_code: addressObject.state_code.short_name,
        country_code: addressObject.country.short_name,
    }
}

import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Link, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { HelpTopicArticleItem, IHelpTopicArticleItemProps } from "../HelpTopicArticleItem";
import { ThumbDownAltOutlined, ThumbDownTwoTone, ThumbUpAltOutlined, ThumbUpTwoTone } from "@mui/icons-material";
import { IssueStateView } from "../../IssueStateView";
import MuiMarkdown from 'mui-markdown';
import { PropsWithErrorState, PropsWithLoadingState } from "../../../utilities";
import { Trans, useTranslation } from "react-i18next";

export interface IHelpArticleProps {
    /** the title of the article */
    title: string;
    /** (Optional) The short description of the article */
    description?: string;
    /** The content of the article in Markdown (from the storage file) */
    mdContent: string;
    /** The related articles */
    relatedArticles: IHelpTopicArticleItemProps[];
    /** Callback on positive feedback clicked */
    onPositiveFeedbackClicked: () => void;
    /** Callback on negative feedback clicked */
    onNegativeFeedbackClicked: () => void;
    /** Callback on contact clicked, not showing if not provided */
    onContactClick?: () => void;
    /** Callback on getting specific (message) feedback, not displaying if not provided */
    onFeedbackSent?: (feedback: string) => void;
}

export const HelpArticle = ({
    title,
    description,
    mdContent,
    relatedArticles,
    onPositiveFeedbackClicked,
    onNegativeFeedbackClicked,
    onContactClick,
    onFeedbackSent,
    loading,
    error,
}: PropsWithErrorState<PropsWithLoadingState<IHelpArticleProps>>) => {
    const { t } = useTranslation();
    const [selectedFeedback, setSelectedFeedback] = useState<0 | -1 | 1>(0);
    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

    if (!!error) {
        return <IssueStateView
            title={t('help.article.errorTitle')}
            description={t('help.article.errorContent')}
            button={typeof error !== 'boolean' && !!error.onRetryClick ? {
                text: t('help.article.errorBtn'),
                onActionClick: error.onRetryClick,
            } : undefined}
        />;
    }

    const handleFeedbackClick = (feedback: -1 | 1) => {
        //update the visual and send info to parent
        setSelectedFeedback(feedback);
        if (feedback === 1) {
            return onPositiveFeedbackClicked?.();
        }
        if (feedback === -1) {
            return onNegativeFeedbackClicked?.()
        }
    }

    const handleMoreFeedbackClick = () => {
        setFeedbackDialogOpen(true);
    }

    const handleFeedbackSend = (feedback?: string) => {
        setFeedbackDialogOpen(false);
        if (!!feedback) {
            onFeedbackSent?.(feedback);
        }
    }

    return (
        <Stack direction={{ xs: 'column', md: 'row' }}
            justifyContent={{ xs: 'start', md: 'space-between' }}
            alignItems={{ xs: 'stretch', md: 'start' }}
            spacing={2}>
            {/* The article */}
            <Stack direction={'column'}
                justifyContent={'start'}
                alignItems={'stretch'}
                flexGrow={1}
                spacing={2}
                //need max width for the article to not crush the contact section if it exist
                sx={
                    {
                        maxWidth: {
                            md: !!onContactClick ? '80%' : '100%'
                        }
                    }
                }
            >
                {!loading ?
                    <MuiMarkdown>{mdContent}</MuiMarkdown>
                    :
                    //simulate the general layout of an article
                    <>
                        <Typography variant="h1"><Skeleton /></Typography>
                        <Typography variant="h3"><Skeleton /></Typography>
                        <Skeleton variant={'rectangular'} />
                        <Typography variant="h3"><Skeleton /></Typography>
                        <Skeleton variant={'rectangular'} />
                    </>
                }
                {/* Article feedback */}
                <Stack direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}>
                    <Stack direction={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}>
                        <Typography>{t('help.article.feedbackTitle')}</Typography>
                        <IconButton onClick={() => handleFeedbackClick(1)}
                            disabled={!!loading || !!selectedFeedback}>
                            {!!selectedFeedback && selectedFeedback === 1 ?
                                <ThumbUpTwoTone color="success" />
                                :
                                <ThumbUpAltOutlined />
                            }
                        </IconButton>
                        <IconButton onClick={() => handleFeedbackClick(-1)}
                            disabled={!!loading || !!selectedFeedback}>
                            {!!selectedFeedback && selectedFeedback === -1 ?
                                <ThumbDownTwoTone color="error" />
                                :
                                <ThumbDownAltOutlined />
                            }
                        </IconButton>
                    </Stack>
                    {
                        !!selectedFeedback && !!onFeedbackSent &&
                        <Typography>
                            <Link onClick={() => handleMoreFeedbackClick()}>{t('help.article.feedbackSubtitle')}</Link>
                        </Typography>
                    }
                </Stack>

                {
                    (!loading && !relatedArticles.length) ?
                        null
                        :
                        <>
                            <Divider />
                            <Typography variant="h4">{t('help.article.relatedArticles')}</Typography>
                            <Stack direction={'column'} spacing={1}>
                                {
                                    !loading ?
                                        relatedArticles.map((article, index) =>
                                            <HelpTopicArticleItem
                                                key={`related-article-${index}`}
                                                {...article} />
                                        )
                                        : new Array(3).fill('').map((_, index) =>
                                            <HelpTopicArticleItem loading
                                                key={`related-article-loading-${index}`} />
                                        )
                                }
                            </Stack>
                        </>
                }
            </Stack>
            {/* The contact form */}
            {
                !!onContactClick &&
                <Stack direction={'column'}
                    justifyContent={'start'}
                    alignItems={'stretch'}
                    spacing={1}
                    sx={{ minWidth: '20%' }}>
                    <Typography variant="h4">{t('help.article.contactTitle')}</Typography>
                    <Typography></Typography>
                    <Button variant="outlined" disabled={!!loading}>{t('help.article.contactBtn')}</Button>
                    {
                        !loading && !!onFeedbackSent &&
                        <Trans
                            parent={Typography}
                            i18nKey={'help.article.feedbackFooter'}
                            components={[<Link onClick={() => handleMoreFeedbackClick()} />]}
                        />
                        // <Typography>You can also <Link onClick={() => handleMoreFeedbackClick()}>{"give us feedback"}</Link></Typography>
                    }
                </Stack>
            }
            <FeedbackDialog open={feedbackDialogOpen} onClose={handleFeedbackSend} />
        </Stack>
    )
}

interface IFeedbackDialogProps {
    open: boolean;
    onClose: (value?: string) => void;
}

const FeedbackDialog = ({ open, onClose }: IFeedbackDialogProps) => {
    const { t } = useTranslation();
    const [feedback, setFeedback] = useState("");

    const handleCancel = () => {
        onClose();
    }

    const handleSend = () => {
        onClose(feedback);
        //clear the feedback after send
        setFeedback('');
    }

    return (
        <Dialog open={open}
            onClose={() => onClose()}
            keepMounted
        >
            <DialogTitle>{t('help.feedback.title')}</DialogTitle>
            <DialogContent>
                <TextField multiline
                    placeholder={t('help.feedback.inputPlaceholder')}
                    rows={3}
                    value={feedback}
                    onChange={(event) => setFeedback(event.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    {t('help.feedback.cancelButton')}
                </Button>
                <Button onClick={handleSend}>
                    {t('help.feedback.sendButton')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
import React from "react";
import { Container, Stack, Typography, Button, Box } from "@mui/material";
import { Trans, TransProps, useTranslation } from "react-i18next";

interface ITestimonialScreenProps {
    /** The title of the testimonial */
    title?: string;
    /** The short description */
    description?: string;
    /** The image illustration */
    image: string;
    /** The content */
    content: string | TransProps<any>;
    /** Callback on the bottom button click to join the community */
    onButtonClick?: () => void;
    /** Overwrite for the image style */
    imageStyle?: React.CSSProperties;
}

/** The Component which render a page of testimonial */
export const TestimonialScreen = ({ title, description, image, content, onButtonClick, imageStyle }: ITestimonialScreenProps) => {
    const { t } = useTranslation();

    return (
        <Container maxWidth={'md'} sx={{ p: 0, paddingBottom: 2 }}>
            <Stack direction={'column'} spacing={2}>
                <Box position={'relative'}>
                    <img src={image} alt={title} width={'100%'} style={{ height: '50vh', objectFit: 'cover', ...imageStyle }} />
                    <Stack direction={'column'} spacing={1} p={2} position={'absolute'} bottom={0} sx={{ backgroundColor: '#00000080' }}>
                        <Typography variant="h3" color={'white'} fontWeight={'bold'}>{title}</Typography>
                        <Typography variant="h5" color={'white'}>{description}</Typography>
                    </Stack>
                </Box>
                {
                    (typeof content === 'string') ?
                        <Typography variant={'body1'}>
                            {content}
                        </Typography>
                        :
                        <Trans parent={Typography}
                            components={{ b: <Typography component={'span'} fontWeight={'bold'} /> }}
                            {...content} />
                }
                {!!onButtonClick && <Button variant={'contained'} onClick={onButtonClick}>{t('testimonial.joinButton')}</Button>}
            </Stack>
        </Container >
    )
}
import React from "react";
import { TextFieldProps, TextField, InputAdornment } from "@mui/material";
import { MIN_DISCOUNT_PERCENTAGE, MAX_DISCOUNT_PERCENTAGE } from "../../../utilities/listings";

export interface IDiscountInputProps extends Pick<TextFieldProps, 'value' | 'name' | 'label' | 'onChange' | 'onBlur' | 'error' | 'helperText' | 'placeholder'> { };

export const DiscountInput = ({ ...textFieldProps }: IDiscountInputProps) => {
    const handleOnChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
        = (event) => {
            textFieldProps.onChange?.(event);
        }
    const handleOnBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
        = (event) => {
            textFieldProps.onBlur?.(event);
        }
    return <TextField
        {...textFieldProps}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        inputProps={{
            min: MIN_DISCOUNT_PERCENTAGE,
            max: MAX_DISCOUNT_PERCENTAGE,
        }}
        InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
        }}
        type={'number'}
    />
}
/** The types for all listings related db (firestore+realtime) objects */
/** NOTE: all change should be done in the shared_type folder 
 * and run `npm run build:shared_types` in root project */

import { UserSummary } from "./user";

/******************************************
 * LISTINGS DB FIRESTORE
 * |listings
 * -|reviews
 * -|photos
 * -|listings //sub-listings
 ******************************************/

/** The collection name for all listings */
export const LISTINGS_COLLECTION = "listings";

export type ListingType =
    | "multi-suite" | "multi-room" | "multi-site" | "multi-mix" //parent type
    | "suite" //sub for multi-suite
    | "private-room" | "shared-room" //sub for multi-room
    | "site" | "cabin" | "boat"; //sub for multi-site

export const ListingTree: { [key in ListingType]: ListingType[] } = {
    //multi-mix is a wild card
    "multi-mix": ["multi-suite", "multi-room", "multi-site", "multi-mix", "suite", "private-room", "shared-room", "site", "cabin", "boat"],
    "multi-suite": ["multi-suite", "multi-room", "suite"],
    "multi-room": ["private-room", "shared-room"],
    "multi-site": ["site", "cabin", "boat"],
    "suite": [],
    "private-room": [],
    "shared-room": [],
    "site": [],
    "cabin": [],
    "boat": []
}

export type TListingStatus = "pending" | "published" | "snoozed" | "un-listed" | "in-review" | "de-activated";

/** The Listing object type */
export type Listing = {
    /** The uuid of the listing (filed up by cloud function), same as the doc id (for fetch purpose only) */
    id?: string;
    /** The type of listing */
    type: ListingType;
    /** The status of the listing */
    status: TListingStatus;
    /** The owner/host id of the listing */
    host: UserSummary;
    /** The current cohost of the listing */
    cohost: UserSummary;
    /** The title of the listing */
    title: string;
    /** The main photo of the listing */
    main_photo: ListingPhoto;
    /** The short description of the listing (showing in search and sub listing) */
    short_description: string;
    /** The long main description of the listing */
    description: string;
    /** The geo localization of the listing */
    location: ListingLocation,
    /** The breadcrumbs of all parents to this listing (excluding itself) */
    breadcrumbs: ListingSummary[];
    /** The sub-listings summary of this listing (ex: rooms into house listing) */
    sub_listings: ListingSummary[];
    /** The rating summary of this listing */
    rating: ListingReviewSummary;
    /** Optional information to indicate duplication of this listing from another listing id */
    duplicate_from?: string;
    /** Pricing of the listing (for guest) */
    pricing: ListingPricing;
    /** The listing calendar */
    calendar: ListingCalendar;
    /** The amount of guests restriction */
    guests_restriction: ListingGuestsRestriction;
    /** CoHost special perk (only present if listing available for cohost) */
    cohost_perk?: {
        /** Boolean indicating if the position is open (could have more than 1 choice in a building, so this indicate if the position already go taken)*/
        open: boolean;
        /** The pricing of the listing for a CoHost */
        pricing: Omit<ListingPricing, 'type'> | number;
        /** The calendar specification for a CoHost (ex: min/max night can be higher than a normal guest, rest the same as guest calendar) */
        calendar: Pick<ListingCalendar, 'min_night' | 'max_night'>;
    };
    /** The guests rules */
    guest_rules?: ListingGuestRules;
}

/** The guests rules */
export type ListingGuestRules = {
    /** Pet friendly listing */
    pets?: boolean;
    /** Smoking allowed */
    smoking?: boolean;
    /** Events allowed */
    events?: boolean;
    /** Commercial picture and video allowed */
    imagery?: boolean;
    /** Extra rules (user data entry) */
    extra?: string;
}

type Price = {
    /** The price amount */
    amount: number;
    /** The 3 letter currency of that price */
    currency: string;
}

/**
 * - non-bookable: If this is a parent listing and cannot be booked by itself
 * - sum-sub-listing: If this can be bookable and is simply the sum of the price of all the sub-listing
 * - unit-bookable: If this can be bookable and has it's own pricing for itself
 */
export type ListingPricingType = 'non-bookable' | 'sum-sub-listing' | 'unit-bookable';

export type ListingPricing = {
    /** The type of pricing */
    type: ListingPricingType;
    /** The listing nightly price */
    nightly_price: Price;
    /** The weekly discount percentage */
    weekly_discount?: number;
    /** The monthly discount percentage */
    monthly_discount?: number;
}

/**
 * - 'default-available': By default the calendar is available (for a all year round rental)
 * - 'default-non-available': By default the calendar is not available (in case only open for some time of the year/seasonal)
 */
export type ListingCalendarType = 'default-available' | 'default-non-available';

export type ListingCalendar = {
    /** The type of availability */
    type: ListingCalendarType;
    /** The calendar id for calendar API */
    calendar_id: string;
    /** Minimum of night someone can stay */
    min_night: number;
    /** Optional Maximum of night someone can stay */
    max_night?: number;
    /** The availabilities of the listing */
    availability: { start_date: Date, end_date: Date }[];
}

/**
 * - 'sum-sub-listing': the sum of the sub-listing guests restriction
 * - 'none': not specific restriction on guests amount
 * - 'custom': Custom for this particular listing
 */
export type ListingGuestsRestrictionType = 'sum-sub-listing' | 'none' | 'custom';

export type ListingGuestsRestriction = {
    /** The type of restriction on amount of guests */
    type: ListingGuestsRestrictionType;
    /** The maximum number of adults allowed in this listing */
    max_adults?: number;
    /** The maximum number of children allowed in this listing */
    max_children?: number;
    /** The maximum number of infants allowed in this listing */
    max_infants?: number;
    /** The maximum number of pets allowed in this listing (if this number is 0, this means that the listing is not pet friendly) */
    max_pets?: number;
}

/** The geo location hash field name (used for query) */
export const GEOLOCATION_HASH_FIELD = 'geohash';

export type ListingAddress = {
    /** Line 1 of the address */
    line1: string;
    /** (Optional) Line 2 of the address for suite number */
    line2?: string;
    /** City */
    city: string;
    /** State code */
    state_code?: string;
    /** State name */
    state_name?: string;
    /** Country code */
    country_code: string;
    /** Country name */
    country_name?: string;
    /** ZIP/Postal code */
    zip?: string;
}

/** The type of the physical location of a listing */
export type ListingLocation = {
    /** The longitude of the loc */
    lng: number;
    /** The latitude of the loc */
    lat: number;
    /** The geo hash of the location (used for query) */
    [GEOLOCATION_HASH_FIELD]: string;
    /** The firebase geopoint (extra) */
    geopoint: any;//firebase.default.firestore.GeoPoint;
    /** (optional) The place id given by google.maps.places.AutocompletePrediction.place_id */
    place_id?: string;
    /** The complete readable address */
    address: ListingAddress;
    /** The user entered neighborhood description */
    neighborhood?: string;
    /** The user entered getting around description */
    gettingAround?: string;
}

/** The summary of a listing (used in breadcrumbs and sub-listings) */
export type ListingSummary = {
    /** The type of the listing */
    type: ListingType;
    /** The title of the listing */
    title: string;
    /** The short description of the listing */
    description: string;
    /** The main photo of the sub-listing */
    main_photo: ListingPhoto;
    /** The listing Id */
    id: string;
    /** The listing document reference */
    ref: any;//firestore.DocumentReference;
}

/** The name of the collection of photos for listing and sub-listing */
export const LISTING_PHOTOS_COLLECTION = "photos";

export type ListingPhoto = {
    /** The url of the listing picture, this mostly is url from storage */
    url: string;
    /** The name of the file in the storage */
    name: string;
    /** The description of the listing image (given by the user) */
    description?: string;
    /** The timestamp when the image was uploaded */
    timestamp?: number;
}

export type ListingReviewSummary = {
    /** The average note */
    average: number;
    /** The quantity of reviews for the calculation of the average */
    quantity: number;
    /** The review summary coming from CoHost */
    from_cohost?: Omit<ListingReviewSummary, 'from_cohost'>;
    /** The review summary coming from Guest */
    from_guest?: Omit<ListingReviewSummary, 'from_guest'>;
}

import { Column, Order } from "./ListingsPageView.types";

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]?: any },
    b: { [key in Key]?: any },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const tableColumns: readonly Column[] = [
    { id: 'title', label: 'listings.table.name', minWidth: 170 },
    { id: 'status', label: 'listings.table.status', minWidth: 100 },
    // {
    //     id: 'numBedrooms',
    //     label: 'listings.table.numBedrooms',
    //     minWidth: 170,
    //     align: 'right',
    // },
    // {
    //     id: 'numBeds',
    //     label: 'listings.table.numBeds',
    //     minWidth: 170,
    //     align: 'right',
    // },
    // {
    //     id: 'numBathrooms',
    //     label: 'listings.table.numBathrooms',
    //     minWidth: 170,
    //     align: 'right',
    // },
    {
        id: 'location',
        label: 'listings.table.location',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'lastModified',
        label: 'listings.table.lastUpdated',
        minWidth: 170,
        align: 'right',
    },
];
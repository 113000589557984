import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ROUTE_PARAMS, ROUTE_PATH } from '../../../utilities/navigation';
import { Button, Container, Stack, Typography } from '@mui/material';
import { UserContext } from '../../../context/UserInformation';


export const NewListingPublishedPage = () => {
    const navigate = useNavigate();
    const { [ROUTE_PARAMS.listingID]: listingId } = useParams();

    const navigateToPreview = () => {
        if (!listingId) {
            return;
        }
        navigate(ROUTE_PATH.listingBuild(listingId, true));
    }

    const navigateToManage = () => {
        if (!listingId) {
            return;
        }
        navigate(ROUTE_PATH.listingSettingsBuild(listingId));
    }
    const navigateToSubListingCreation = () => {
        if (!listingId) {
            return;
        }
        navigate(ROUTE_PATH.listingNewBuild(listingId))//TODO route with param
    }
    const navigateToListingCreation = () => {
        navigate(ROUTE_PATH.listingNewBuild());
    }

    return (
        <CreateListingContainer
            listingId={listingId}
            navigateToPreview={navigateToPreview}
            navigateToManage={navigateToManage}
            navigateToNewSubListing={navigateToSubListingCreation}
            navigateToNewListing={navigateToListingCreation}
        />
    );
}

interface IContainerProps {
    /** The current listing id */
    listingId?: string;
    /** Navigate to the preview of the listing */
    navigateToPreview: () => void;
    /** Navigate to the settings of the listing */
    navigateToManage: () => void;
    /** Navigate to create a sub listing to the current listing */
    navigateToNewSubListing: () => void;
    /** Navigate to a new creation flow for a brand new listing */
    navigateToNewListing: () => void;
}

const CreateListingContainer = ({ listingId, navigateToPreview, navigateToManage, navigateToNewSubListing, navigateToNewListing }: IContainerProps) => {
    const userContext = useContext(UserContext);

    if (!userContext) {
        throw new Error('Need UserContext Provider')
    }

    const { user } = userContext;

    return (
        <View userName={user?.displayName || ''}
            onPreviewClick={!!listingId ? navigateToPreview : undefined}
            onManageClick={!!listingId ? navigateToManage : undefined}
            // onAddNewSubListingClick={!!listingId ? navigateToNewSubListing : undefined} //TODO functionality
            onAddNewListingClick={navigateToNewListing}
        />
    );
}

interface IViewProps {
    /** The user display name */
    userName: string;
    /** Callback on preview listing (going to the preview of the listing) */
    onPreviewClick?: () => void;
    /** Callback on manage listing (going to the listing edit) */
    onManageClick?: () => void;
    /** Callback on Add new Sub-listing to this listing */
    onAddNewSubListingClick?: () => void;
    /** Callback on Add new listing */
    onAddNewListingClick?: () => void;
}

const View = ({ userName, onPreviewClick, onManageClick, onAddNewSubListingClick, onAddNewListingClick }: IViewProps) => {
    const { t } = useTranslation();
    return (
        <Container sx={{ padding: 5 }}>
            <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={2}>
                <Typography variant='h2' textAlign={'center'}>{t('listing.creation.successTitle', { userName })}</Typography>
                <Typography textAlign={'center'}>{t('listing.creation.successContent')}</Typography>
                {
                    (!!onPreviewClick || !!onManageClick || !!onAddNewSubListingClick || !!onAddNewListingClick)
                    &&
                    <>
                        <Typography variant='h4' textAlign={'center'}>{t('listing.creation.successSubtitle')}</Typography>
                        {!!onPreviewClick && <Button variant={'contained'} color={'success'} onClick={onPreviewClick}>{t('listing.creation.successPreviewBtn')}</Button>}
                        {!!onManageClick && <Button variant={'contained'} color={'primary'} onClick={onManageClick}>{t('listing.creation.successEditBtn')}</Button>}
                        {!!onAddNewSubListingClick && <Button variant={'contained'} color={'secondary'} onClick={onAddNewSubListingClick}>{t('listing.creation.successCreateSubBtn')}</Button>}
                        {!!onAddNewListingClick && <Button variant={'contained'} color={'info'} onClick={onAddNewListingClick}>{t('listing.creation.successCreateMoreBtn')}</Button>}
                    </>
                }
            </Stack>
        </Container>
    )
}
import React from "react";
import { ForumsTopicPostsContainer } from "../../../containers/forums/ForumsTopicPostsContainer";
import { useForumsNavigation } from "../../../hooks/useForumNavigation";
import { BANNER_FORUM_COMMUNITY_PAGE } from "../../../utilities/assets/assetHelper";
import { EForumTopic } from "../../../utilities/forums";
import { useTranslation } from "react-i18next";

export const ForumsCommunityPage = () => {
    const { t } = useTranslation();
    const { navigateToNewPost, navigateToArticle, navigateToPost } = useForumsNavigation()

    const handleCreateNewPost = () => {
        navigateToNewPost(EForumTopic.COMMUNITY)
    }

    return <ForumsTopicPostsContainer
        topic={EForumTopic.COMMUNITY}
        banner={{
            title: t('forum.community.title'),
            backgroundImageSrc: BANNER_FORUM_COMMUNITY_PAGE,
            subTitle: t('forum.community.subTitle'),
            primaryButton: { text: t('forum.topic.newPostButton'), onClick: handleCreateNewPost }
        }}
        onCreateNewPostClick={handleCreateNewPost}
        navigateToPost={navigateToPost}
        navigateToArticle={navigateToArticle} />;
}
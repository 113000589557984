import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react"
import { ICreateListingPageViewProps } from "./CreateNewListingPageView.types";
import MobileFlow from "./CreateNewListing_mobile";
import WebFlow from './CreateNewListing_web';

/** The UI view of the header and footer of the listing creation flow. Have the current page as children */
export const CreateListingPageView = ({ stepper, children }: PropsWithChildren<ICreateListingPageViewProps>) => {
    return (
        <Box sx={{ padding: 5 }}>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
                {/* For mobile */}
                <MobileFlow {...stepper}>{children}</MobileFlow>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
                {/* For bigger screen */}
                <WebFlow {...stepper}>{children}</WebFlow>
            </Box>
        </Box>);
}
import React from 'react';
import { Grid } from '@mui/material';
import { HelpTopicSection, IHelpTopicSectionProps } from '../HelpTopicSection';
import { PropsWithErrorState, PropsWithLoadingState } from '../../../utilities';
import { useTranslation } from 'react-i18next';
import { IssueStateView } from '../../IssueStateView';

export interface IHelpTopicsProps {
    /** The list of topic section */
    items: IHelpTopicSectionProps[]
}

/** The display view of all topics for a specific category */
export const HelpTopics = ({
    items,
    loading,
    error,
}: PropsWithErrorState<PropsWithLoadingState<IHelpTopicsProps>>) => {
    const { t } = useTranslation();

    if (!!error) {
        return <IssueStateView
            title={t('help.category.errorTitle')}
            description={t('help.category.errorContent')}
        />;
    }
    return (
        <Grid container spacing={3}>
            {
                (!loading ?
                    items
                    : new Array(6).fill('')
                ).map((item, index) =>
                    <Grid item key={`topic-section-${index}`} xs={12} sm={6} md={4} lg={3}>
                        <HelpTopicSection {...item} loading={loading} />
                    </Grid>
                )
            }
        </Grid>
    )
}

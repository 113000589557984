import React, { useContext, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { SxProps } from '@mui/system';
import { AppBar, Button, Box, Menu, MenuItem, Container, IconButton, Toolbar, Tooltip, Typography, Theme, Stack, Avatar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { LOGO_WHITE_BOLD } from '../../../utilities/assets/assetHelper';
import { ROUTE_PATH } from '../../../utilities/navigation';
import { useForumsNavigation } from '../../../hooks/useForumNavigation';
import { UserContext } from '../../../context/UserInformation';
import { EForumTopic, getNameForTopic } from '../../../utilities/forums';

export const ChoForumsAppBar = () => {
    const { navigateToTopic } = useForumsNavigation();
    const { t } = useTranslation();
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const menuOptions =
        [
            {
                label: getNameForTopic(t, EForumTopic.COMMUNITY),
                action: () => navigateToTopic(ROUTE_PATH.forums.community)
            },
            {
                label: getNameForTopic(t, EForumTopic.HOST),
                action: () => navigateToTopic(ROUTE_PATH.forums.host)
            },
            {
                label: getNameForTopic(t, EForumTopic.COHOST),
                action: () => navigateToTopic(ROUTE_PATH.forums.cohost)
            },
            {
                label: getNameForTopic(t, EForumTopic.GUEST),
                action: () => navigateToTopic(ROUTE_PATH.forums.guest),
            }
        ];
    const userMenuOptions = [
        {
            label: "Profile",
            action: () => {
                navigate(ROUTE_PATH.userBuild(userContext?.user?.firebaseUser?.uid ?? ""))
            }
        },
        {
            label: "Logout",
            action: () => {
                userContext?.logOut();
                navigate(ROUTE_PATH.home);
            },
        },
    ];

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const styles = {
        logoText: {
            mr: 2,
            ml: 2,
            mt: 1,
            color: 'inherit',
            fontFamily: 'PartyLetPlain',
            textDecoration: 'none',
            fontWeight: 500,
        },
        logoIcon: {
            objectFit: 'contain',
            height: '42px',
        },
    };

    const renderAppIcon = (style: SxProps<Theme>) => {
        return <Box sx={style}>
            <Link to={ROUTE_PATH.home}>
                <img
                    style={styles.logoIcon as React.CSSProperties}
                    src={LOGO_WHITE_BOLD}
                    alt="CoHostOp logo"
                />
            </Link>
        </Box>
    }

    return (
        <AppBar position="sticky">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {renderAppIcon({ display: 'flex' })}
                    <Typography
                        variant="h4"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            display: 'flex',
                            ...styles.logoText,
                        }}
                    >
                        {t('appName')}
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {menuOptions.map((page) => (
                            <Button
                                key={page.label}
                                onClick={() => { handleCloseNavMenu(); page.action() }}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page.label}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} />
                    <Box sx={{ flexGrow: 0 }}>
                        {
                            !!userContext?.isUserLoggedIn() ?
                                <Stack direction={'row'}
                                    justifyContent={'end'}
                                    alignItems={'center'}
                                    spacing={2}>
                                    <Tooltip title={t('user.menu.open')}>
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar alt={userContext?.user?.displayName ?? ""} src={userContext?.user?.avatarUrl ?? ""} />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {userMenuOptions.map((page) => (
                                            <MenuItem key={page.label} onClick={() => { handleCloseUserMenu(); page.action(); }}>
                                                <Typography textAlign="center">{page.label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Stack>
                                :
                                <Button key={'join'}
                                    component={Link}
                                    to={ROUTE_PATH.authBuild(ROUTE_PATH.auth_join)}
                                    sx={{ my: 2, color: 'white' }}
                                    variant={'outlined'}
                                    color={'inherit'}>
                                    Join
                                </Button>
                        }
                    </Box>
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                        {!!menuOptions.length &&
                            <>
                                <Tooltip title={t('home.menu.open')}>
                                    <IconButton
                                        size="large"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {menuOptions.map((page) => (
                                        <MenuItem key={page.label} onClick={() => { handleCloseNavMenu(); page.action() }}>
                                            <Typography textAlign="center">{page.label}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        }
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

import { TFunction } from "i18next";
import { EAudience } from "../../types/db";

/** Get the display nme of the given audience */
export const getNameForAudience = (t: TFunction, audience: EAudience) => {
    switch (audience) {
        case EAudience.COMMON:
            return t('help.audience.common');
        case EAudience.HOST:
            return t('help.audience.host');
        case EAudience.COHOST:
            return t('help.audience.cohost');
        case EAudience.GUEST:
            return t('help.audience.guest');
        default:
            return "";
    }
}
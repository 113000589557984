import React, { PropsWithChildren } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router";
import { IssueStateView } from "../../components/IssueStateView";
import { UserContext } from "../../context/UserInformation";
import { buildPath, ROUTE_PATH, ROUTE_QUERY } from "./RouterConfig";

/** Special Route which make sure only non-anonymous user has access to (restricted area) */
export const AnonymousRestrictedRoute = ({ children, ...props }: PropsWithChildren<{}>) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userContext = useContext(UserContext);

    if (!userContext) {
        console.warn('Need UserContext');
        return <></>;
    }
    if (!userContext.user) {
        console.warn('No user info');
        return <></>;
    }

    if (!userContext || userContext.isUserAnonymous()) {
        return (
            <>
                <IssueStateView
                    title={t('user.dashboard.anonymousTitle')}
                    description={t('user.dashboard.anonymousContent')}
                    button={{
                        text: t('user.dashboard.anonymousButton'),
                        onActionClick: () => navigate(
                            buildPath(ROUTE_PATH.authBuild(ROUTE_PATH.auth_link),
                                true,
                                [{
                                    query: ROUTE_QUERY.from,
                                    value: ROUTE_PATH.account,
                                }]))
                    }}
                />
            </>
        )
    }

    return children ? <>{children}</> : <Outlet />;
}
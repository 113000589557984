import { Alert, Snackbar } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { IForumPostReplyItemProps, ForumPostReplyItem } from '../../components/forums/ForumPostReplyItem/ForumPostReplyItem';
import { UserContext } from '../../context/UserInformation';
import { addLikeTo, addReplyTo, getReplyReplies, isLiked, removeLikeFrom } from '../../services/forums';
import { sendSupportTicket } from '../../services/support';
import { ForumReply, FORUMS_REPLIES_COLLECTION, FORUMS_REPLY_TIMESTAMP_FIELD, WithID, WithRef } from '../../types/db';
import { useTranslation } from 'react-i18next';

/** Pagination for the replies of reply (lower than replies of post, in purpose) */
const REPLIES_PER_PAGE = 2;

/** The maximum depth we can reply to a reply (limiting for the data base). 
 * Once limit reached, reply will be "@ author" of the reply author on the parent reply (see facebook behavior) */
const MAX_RESPONSE_DEPTH = 3;//TODO limit deepness with UI

/** Container which takes care of a single reply */
export const ForumReplyContainer = ({ reply }: { reply: WithID<WithRef<ForumReply>, string> }) => {
    const { t } = useTranslation();
    const userContext = useContext(UserContext);
    const [props, setProps] = useState<IForumPostReplyItemProps>();
    const { data: dataReplies,//the replies of the reply
        isLoading: isLoadingReplies,
        refetch: refetchReplies,
        hasPreviousPage,
        fetchPreviousPage, } = useInfiniteQuery(
            ["replyReplies", reply.id],
            ({ pageParam }) => getReplyReplies(
                { replyRef: reply.ref },
                {
                    limitToLast: REPLIES_PER_PAGE,
                    orderBy: { field: FORUMS_REPLY_TIMESTAMP_FIELD, dir: "asc" },
                    endBefore: pageParam
                }),
            {
                enabled: !!reply,
                refetchOnWindowFocus: false,
                getPreviousPageParam: (_, pages) => {
                    if (!props?.repliesCount) {
                        return undefined
                    }
                    if (pages.length < props.repliesCount / REPLIES_PER_PAGE) {
                        const firstPage = pages[0];
                        return firstPage[0];//the first item of the first page
                    } else {
                        return undefined;//no more to fetch
                    }
                }
            });

    const [reported, setReported] = useState(false);

    useEffect(() => {
        if (!reply) {
            return;
        }
        // console.log('replyId changed?', reply)
        //register to listening to changes on the data
        return reply.ref.onSnapshot(async (snap) => {
            // console.log('onSnapshot reply', snap);
            const dataSnap = snap.data() as ForumReply;
            const liked = await isLiked(snap.ref);
            setProps((currentProps) => {
                // console.log('refetching?', currentProps?.repliesCount, dataSnap.replies_count)
                if (!!currentProps && currentProps.repliesCount !== dataSnap.replies_count) {
                    refetchReplies()
                }
                return mapReplyPropsFromData(
                    {
                        ...(snap.data() as ForumReply),
                        ref: snap.ref,
                        liked,
                    },
                    {
                    },
                    userContext?.user?.avatarUrl,
                )
            })
        })
    }, [reply, refetchReplies, userContext?.user?.avatarUrl]);

    if (!props) {
        return <ForumPostReplyItem loading />
    }

    const handleReportClick = () => {
        sendSupportTicket({
            type: "report",
            feature: "forum",
            error_message: "reporting",
            document: "reply",
            doc_ref: reply.ref,
            doc_id: reply.id,
            user_sender: userContext?.user?.firebaseUser?.uid || ''
        })
            .then(() => setReported(true))
    }

    return <>
        <ForumPostReplyItem {...props}
            replies={props.repliesCount === 0 ?
                []
                : isLoadingReplies ?
                    new Array(REPLIES_PER_PAGE).fill({}).map((_, index) => <ForumPostReplyItem loading key={`reply-loading-${index}`} />)
                    :
                    dataReplies?.pages.flatMap(page => {
                        return page.map(reply => <ForumReplyContainer key={`reply-${reply.ref.id}`} reply={reply} />);
                    })
            }
            onLoadMoreReplies={hasPreviousPage ? fetchPreviousPage : undefined}
            onReplyClick={calculateReplyDeepness(reply) >= MAX_RESPONSE_DEPTH ? undefined : props.onReplyClick}
            onReportClick={handleReportClick}
        />
        <Snackbar autoHideDuration={3000} open={reported} onClose={() => setReported(false)} >
            <Alert onClose={() => setReported(false)} severity="success" sx={{ width: '100%' }}>
                {t('forum.reply.reportConfirmMessage')}
            </Alert>
        </Snackbar>
    </>
}


const mapReplyPropsFromData = (
    data: WithRef<ForumReply> & { liked: boolean },
    callbacks: {
    },
    currentUserAvatar?: string,
): IForumPostReplyItemProps => {
    const handleLikesClick = () => {
        if (data.liked) {
            removeLikeFrom(data.ref);
        } else {
            addLikeTo(data.ref);
        }
    }
    const handleReply = async (content: string) => {
        addReplyTo(data.ref, content)
    }
    // const handleDeleteClick = () => {
    //     return deleteReply(data.ref);
    // }
    return {
        author: { avatar: data.author.avatar_url || '', displayname: data.author.display_name },
        timestamp: data.timestamp.toDate(),
        content: data.content,
        likeButton: {
            liked: data.liked,
            likesCount: data.likes_count,
            onLikeClick: handleLikesClick,
        },
        onReply: handleReply,
        onReplyClick: () => { },
        repliesCount: data.replies_count,
        onReportClick: () => { },
        onEditClick: undefined,//for now not handling edit on reply
        currentUserAvatar: currentUserAvatar || '',
    }
}

const calculateReplyDeepness = (reply: WithRef<ForumReply>): number => {
    return reply.ref.path.split('/')//split the path
        .reduce((prev, cur) => prev + (cur === FORUMS_REPLIES_COLLECTION ? 1 : 0), 0)
}
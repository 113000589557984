import firebase from "firebase/app";
import { db } from "../firebase";
import { Shop, ShopCategory, ShopProduct, ShopProductVariant, SHOP_CATEGORIES_COLLECTION, SHOP_COLLECTION, SHOP_PRODUCTS_COLLECTION, SHOP_PRODUCT_VARIANTS_COLLECTION, WithID } from "../../types/db";

const getShopCollectionRef = () => {
    return db.collection(SHOP_COLLECTION);
}

const getShopDocRef = (shopId: string) => {
    return getShopCollectionRef().doc(shopId);
}

export const getShopInfo = async (shopId: string) => {
    const data = await getShopDocRef(shopId).get()
    return data.data() as Shop;
}

export const getShopCategories = async (shopId: string, parentCategoryId?: string) => {
    var query: firebase.firestore.Query<firebase.firestore.DocumentData>
        = getShopDocRef(shopId).collection(SHOP_CATEGORIES_COLLECTION);
    if (parentCategoryId !== undefined) {
        query = query.where('parent_id', '==', parentCategoryId);
    }
    const data = await query.get();

    return data.docs.map(doc => {
        return {
            id: doc.id,
            ...doc.data(),
        } as WithID<ShopCategory, string>
    });
}

export const getShopProducts = async (shopId: string, categoryId?: string) => {
    var data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
    if (!categoryId) {
        //fetch all product
        data = await getShopDocRef(shopId).collection(SHOP_PRODUCTS_COLLECTION).get();
    } else {
        //fetch only product within this category 
        data = await getShopDocRef(shopId).collection(SHOP_PRODUCTS_COLLECTION)
            .where("categories", "array-contains", categoryId)
            .get();
    }

    return data.docs.map(doc => {
        return {
            id: doc.id,
            ...doc.data(),
        } as WithID<ShopProduct, string>
    })
}

export const getShopProduct = async (shopId: string, productId: string) => {
    return (await getShopDocRef(shopId).collection(SHOP_PRODUCTS_COLLECTION).doc(productId).get()).data() as ShopProduct | undefined;
}

export const getShopProductVariants = async (shopId: string, productId: string) => {
    const productRef = getShopDocRef(shopId).collection(SHOP_PRODUCTS_COLLECTION).doc(productId)
    const data = await productRef.collection(SHOP_PRODUCT_VARIANTS_COLLECTION).get();
    return data.docs.map(doc => {
        return {
            id: doc.id,
            ...doc.data(),
        } as WithID<ShopProductVariant, string>;
    })
}

export const getShopCartItems = async (shopId: string, productVariantMap: { productId: string, variantId: string }[]) => {
    // This is not possible with firebase 
    // showed here : https://github.com/firebase/quickstart-unity/issues/1054
    // and here : https://stackoverflow.com/questions/68433976/firestore-collection-group-query-on-document-id
    // const data = await db.collectionGroup(SHOP_PRODUCT_VARIANTS_COLLECTION)
    //     .where(firebase.firestore.FieldPath.documentId(), 'in', variantIds)
    //     .get();
    // return data.docs.map(doc => {
    //     return {
    //         id: doc.id,
    //         ...doc.data(),
    //     } as WithID<ShopProductVariant, string>;
    // })
    const data = await Promise.all(
        productVariantMap.map(item =>
            getShopDocRef(shopId)
                .collection(SHOP_PRODUCTS_COLLECTION)
                .doc(item.productId)
                .collection(SHOP_PRODUCT_VARIANTS_COLLECTION)
                .doc(item.variantId)
                .get()
        )
    );
    return data.map(doc => {
        return {
            id: doc.id,
            ...doc.data(),
        } as WithID<ShopProductVariant, string>;
    })
}
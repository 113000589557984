import { useTranslation } from "react-i18next";
import { ROUTE_PATH, buildPath } from "../utilities/navigation";

/**
 * Hooks which allow to get the questions/answers.
 * Enable to send a new question
 */
export const useFAQ = () => {
    const { t } = useTranslation();
    const faqs = t('faq.QnA', { returnObjects: true }) as any[];

    const links = [
        { label: "Home page", path: ROUTE_PATH.home },
        { label: "About page", path: buildPath(ROUTE_PATH.about) },
        /*{ label: "Financial report", path: ROUTE_PATH.buildAbout(ROUTE_PATH.finance_report) }*/]
    const sendQuestion = (question: string, from: string) => {
        // alert(`Thank you! We will be in touch soon!`);
        window.location.href = `mailto:contact@cohostop.com?subject=Question from ${from}&body=${question}`
    }
    return { faqs, links, sendQuestion }
}
import React from 'react';
import { useQuery } from 'react-query';
import { ProfileListingSection } from '../../../components/profile/ProfileListingSection';
import { getUserListings } from '../../../services/user';
import { EUserRole } from '../../../types/db';

interface IProfileListingsContainerProps {
    userId: string;
    userDisplayName: string;
}

export const ProfileListingsContainer = ({ userId, userDisplayName }: IProfileListingsContainerProps) => {
    const { isLoading, isError, data } = useQuery(['Profile-Listings', userId], () => getUserListings(userId, EUserRole.HOST));

    if (isLoading) {
        return <ProfileListingSection loading />
    }

    if (isError || !data) {
        return null;
    }

    return <ProfileListingSection
        profileDisplayName={userDisplayName}
        items={[]}
    />;
}
/** The remote config for the forums */
export const RC_FORUM = "forum";

export type Forum = {
    /** Boolean indicating if the forum is enabled */
    enabled: boolean;
}

export const DEFAULT_FORUM: Forum = {
    "enabled": false
}
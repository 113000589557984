//Utility file to fetch the listings information for CoHostOp database
import { getListingsWithinLocation } from "../../../services/geofire";
import { Listing } from "../../../types/db";
import { DEFAULT_LISTING_IMAGE_URL } from "../../../utilities/assets/assetHelper";
import { DEFAULT_CURRENCY } from "../../../utilities/currency/function";
import { ROUTE_PATH } from "../../../utilities/navigation";
import { Filters, ISearchListingItemProps } from "../SearchListingsPage.types";
import { ESupportedPlatform } from "./common";
import { getCenterOfBoundary, getPlaceLocation, getRadiusInM } from "./location";

export const queryFn = async (filterValues: Partial<Filters>, filterDefaults: Filters) => {
    // console.log('query cohostop with', filterValues)
    // const query = db.collectionGroup(LISTINGS_COLLECTION);

    // if(filterValues.location){
    //     query.where()
    // }

    // Filter query by dates if exist
    // if (filterValues.check_in && filterValues.check_out) {
    //     const selectedStartDate = filterValues.check_in;
    //     const selectedEndDate = filterValues.check_out;
    //     query.where('calendar.availability', 'array-contains', (availability: ListingCalendar['availability']) => {
    //         console.log(`looking for availability for ${selectedStartDate}-${selectedEndDate} in `, availability)
    //         //check if the selected range is strictly within any available range
    //         for (const range of availability) {
    //             const rangeStartDate = new Date(range.start_date);
    //             const rangeEndDate = new Date(range.end_date);
    //             if (selectedStartDate >= rangeStartDate
    //                 && selectedEndDate <= rangeEndDate) {
    //                 console.log('found!', range)
    //                 return true; //Strictly within range found
    //             }
    //         }
    //         console.log('no found!')
    //         return false;//No strictly within range found
    //     })
    // }
    // --> CANNOT ENTER A FUNCTION TO COMPARE VALUES

    // const data = await query.get();
    let location = filterValues.bounds ? getCenterOfBoundary(filterValues.bounds) : filterValues.location || filterDefaults.location;
    if (typeof location === 'string') {
        //research lat/lng of this place
        location = await getPlaceLocation(location)
    } else if (Array.isArray(location)) {
        location = await getPlaceLocation(location.join(','))
    }
    let data = await getListingsWithinLocation([location.lat, location.lng], getRadiusInM(filterValues.bounds || filterDefaults.bounds) || 5000);
    // console.log('listings', data.map(value => value.data()))
    data = data
        .filter(value => (value.data() as Listing).status === "published")//TODO add to the query instead of filtering FE
    if (filterValues.check_in && filterValues.check_out) {
        //filter by available date
        const selectedStartDate = filterValues.check_in;
        const selectedEndDate = filterValues.check_out;
        data = data.filter(value => {
            //check if the selected range is strictly within any available range
            for (const range of ((value.data() as Listing)?.calendar?.availability ?? [])) {
                const rangeStartDate = new Date(range.start_date);
                const rangeEndDate = new Date(range.end_date);
                if (selectedStartDate >= rangeStartDate
                    && selectedEndDate <= rangeEndDate) {
                    return true; //Strictly within range found
                }
            }
            return false;//No strictly within range found
        })
    }
    return data.map((value) => mapDataToUI(value.data() as Listing))
}

export const mapDataToUI = (item: Listing): ISearchListingItemProps => {
    return ({
        platformId: ESupportedPlatform.COHOSTOP,
        title: item.title,
        shortDescription: item.short_description,
        lat: item.location?.lat || (item as any).loc?.lat || 0,
        lng: item.location?.lng || (item as any).loc?.lng || 0,
        images: !!item.main_photo ? [item.main_photo.url] : [DEFAULT_LISTING_IMAGE_URL],
        price: {
            amount: item.pricing?.nightly_price?.amount || 0,
            currencyCode: item.pricing?.nightly_price?.currency || DEFAULT_CURRENCY,
        },
        hostAvatar: item.host?.avatar_url || '',
        cohostAvatar: item.cohost?.avatar_url,
        onClick: () => item.id && window.open(ROUTE_PATH.listingBuild(item.id)),
        rating: {
            rate: item.rating?.average || 0,
            numberReview: item.rating?.quantity || 0,
        },
    });
}
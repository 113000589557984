import { VolunteerActivism } from '@mui/icons-material';
import { Button, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IShopDonationBannerProps {
    /** The content of the donation banner, if not provided will use the default CoHostOp one */
    text?: string;
    /** Callback when action area clicked */
    onClick: () => void
}

export const ShopDonationBanner = ({ text, onClick }: IShopDonationBannerProps) => {
    const { t } = useTranslation();
    return (
        <Paper sx={{ padding: 1 }} elevation={2}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1} flexWrap={"nowrap"}>
                <Typography>
                    {text || t('support.donation.content')}
                </Typography>
                <Button sx={{ minWidth: "auto" }} //so the item doesn't get smaller than its content
                    onClick={onClick}
                    startIcon={<VolunteerActivism />}
                    variant={'contained'}>
                    {t('support.donation.button')}
                </Button>
            </Stack>
        </Paper>
    )
}
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IErrorDialogProps {
    /** Boolean indicating if the dialog is open or not */
    open: boolean;
    /** Callback on dialog closed or cancelled */
    onClose: () => void;
    /** The error message */
    content: string;
}

/** Simple/Generic Error dialog */
export const ErrorDialog = ({ open, onClose, content }: IErrorDialogProps) => {
    const { t } = useTranslation()
    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t('auth.error.title')}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {content}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                {t('ok')}
            </Button>
        </DialogActions>
    </Dialog>
}
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ForumsNewPostScreen } from "../../components/forums/ForumsNewPostScreen/ForumsNewPostScreen";
import { createNewPost } from "../../services/forums";
import { EForumTopic } from "../../utilities/forums";
import { ROUTE_QUERY } from "../../utilities/navigation";

export interface IForumsNewPostContainerProps {
    /** If the user cancel the new post, it should be navigate back to where it was */
    onGoBack: () => void;
    /** If the user navigate to an existing post, or if the user created a new post->redirect to it */
    onNavigateToPost: (postId: string) => void;
}

/** Container for forum new post (take care of displaying UIs and calling Back-End to create a new post or fetch similar posts) */
export const ForumsNewPostContainer = ({ onGoBack, onNavigateToPost }: IForumsNewPostContainerProps) => {
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();
    const topic = searchParams.get(ROUTE_QUERY.topic) as EForumTopic || EForumTopic.COMMUNITY;

    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        //we just go back
        onGoBack();
    }

    const handleTopicChange = (newValue: EForumTopic) => {
        setSearchParams({ [ROUTE_QUERY.topic]: newValue })
    }

    const handlePost = async () => {
        setLoading(true);
        //we create the post
        const newPost = await createNewPost(subject, content, topic);
        setLoading(false);
        if (!newPost) {
            //something went wrong during the creation of the post
            console.error("couldn't post");
            //notification to the user?
        } else {
            //we navigate to the newly created post
            onNavigateToPost(newPost.id);
        }
    }

    return <ForumsNewPostScreen
        subjectTextField={{
            value: subject,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setSubject(event.target.value)
        }}
        contentTextField={{
            value: content,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setContent(event.target.value)
        }}
        topicSelection={{
            value: topic,
            onValueChange: handleTopicChange,
        }}
        cancelButton={{ onClick: handleCancel, disabled: loading }}
        postButton={{ onClick: handlePost, disabled: (!subject || !content), loading }} />
}

import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Security, Visibility } from '@mui/icons-material';

import firebase from "firebase/app";
import "firebase/auth";

import { AccountBaseInfo, AccountBaseInfoItems } from '../../../components/user/account/AccountBaseInfo';
import { IAccountHelpItemProps } from '../../../components/user/account/AccountHelpItem';
import { getTitleFromAccountPage } from '../../../components/user/account/AccountPageView';
import { getUserLoginInfo } from '../../../services/user';
import { EUserVerification } from '../../../types/db';
import { getLinkedAccountName, getUIVerificationFrom } from '../../../utilities/EnumUtility';
import { IAccountSubContainerProps } from '../AccountSubContainer';
import { Stack, TextField } from '@mui/material';
import { auth } from '../../../services/firebase';
import { hideEmailDetails } from '../../../utilities';
import { useTranslation } from 'react-i18next';
import { getAuthProviderFrom } from '../../AuthContainer';

type TextFieldState = {
    value: string;
    error?: boolean;
    hint?: string;
}

export const AccountLoginContainer = ({
    subPage,
    userId,
    children,
}: IAccountSubContainerProps) => {
    const { t } = useTranslation();
    const userLoginResult = useQuery(["account", "login"],
        () => getUserLoginInfo(userId));

    const userLoginInfo = userLoginResult.data;

    const [newEmail, setNewEmail] = useState<TextFieldState>({ value: '' });
    const [reNewEmail, setReNewEmail] = useState<TextFieldState>({ value: '' });

    const [oldPassword, setOldPassword] = useState<TextFieldState>({ value: '' });
    const [newPassword, setNewPassword] = useState<TextFieldState>({ value: '' });
    const [reNewPassword, setReNewPassword] = useState<TextFieldState>({ value: '' });

    const items: AccountBaseInfoItems =
        // !userLoginInfo ? [] :
        [
            {
                label: t('user.accountLogin.loginTitle'),
                items: [
                    {
                        title: t('user.accountLogin.emailTitle'),
                        description: t('user.accountLogin.emailContent'),
                        value: hideEmailDetails(auth.currentUser?.email),
                        children:
                            <Stack direction={'column'} spacing={1}>
                                <TextField
                                    label={t('user.accountLogin.emailLabel')}
                                    value={newEmail.value}
                                    onChange={(e) => setNewEmail({ value: e.target.value })}
                                    error={newEmail.error}
                                    helperText={newEmail.hint}
                                />
                                <TextField
                                    label={t('user.accountLogin.emailLabel')}
                                    value={reNewEmail.value}
                                    onChange={(e) => setReNewEmail({ value: e.target.value })}
                                    error={reNewEmail.error}
                                    helperText={reNewEmail.hint}
                                />
                                <TextField
                                    label={t('user.accountLogin.passwordLabel')}
                                    type={'password'}
                                    value={oldPassword.value}
                                    onChange={(e) => setOldPassword({ value: e.target.value })}
                                    error={oldPassword.error}
                                    helperText={oldPassword.hint}
                                />
                            </Stack>,
                        onSaveClick: () => {
                            if (!newEmail.value) {
                                setNewEmail(v => ({ ...v, error: true, hint: t('error.requiredFieldEmpty') }));
                                return Promise.reject();
                            }
                            if (!reNewEmail.value) {
                                setReNewEmail(v => ({ ...v, error: true, hint: t('error.requiredFieldEmpty') }));
                                return Promise.reject();
                            }
                            if (newEmail.value !== reNewEmail.value) {
                                setNewEmail(v => ({ ...v, error: true, hint: t('error.unmatchedEmails') }));
                                setReNewEmail(v => ({ ...v, error: true }));
                                return Promise.reject();
                            }
                            const currentUser = auth.currentUser;
                            if (!!currentUser && currentUser.email) {
                                return currentUser.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(currentUser.email, oldPassword.value))
                                    .then((userCredential) => {
                                        return userCredential.user?.updateEmail(newEmail.value)
                                            ?? currentUser.updateEmail(newEmail.value);
                                    })
                                    .then(() => {
                                        //clear all field
                                        setNewEmail({ value: '' });
                                        setReNewEmail({ value: '' });
                                        setOldPassword({ value: '' });
                                    })
                                    .catch((e) => {
                                        console.error(e);
                                        if (e.code === 'auth/wrong-password') {
                                            setOldPassword(p => ({ ...p, error: true, hint: t('error.wrongPassword') }));
                                        } else {
                                            setReNewEmail(p => ({ ...p, error: true, hint: e.message || t('error.passwordUpdate') }));
                                        }
                                        return Promise.reject();
                                    });

                            } else {
                                //no current user with credential as email?
                                return Promise.reject();
                            }
                        }
                    },
                    {
                        title: t('user.accountLogin.passwordTitle'),
                        value: (!!userLoginInfo && userLoginInfo.pw_update_last) ?
                            t('user.accountLogin.passwordContent', { date: new Date(userLoginInfo.pw_update_last).toString() })
                            : auth.currentUser?.providerData.some(pd => pd?.providerId === getAuthProviderFrom(EUserVerification.EMAIL)?.providerId) ?
                                "*******"
                                : undefined,
                        children:
                            <Stack direction={'column'}
                                spacing={1}>
                                <TextField
                                    label={t('user.accountLogin.passwordOldLabel')}
                                    type={'password'}
                                    value={oldPassword.value}
                                    onChange={e => setOldPassword({ value: e.target.value })}
                                    error={oldPassword.error}
                                    helperText={oldPassword.hint}
                                />
                                <TextField
                                    label={t('user.accountLogin.passwordLabel')}
                                    type={'password'}
                                    value={newPassword.value}
                                    onChange={e => setNewPassword({ value: e.target.value })}
                                    error={newPassword.error}
                                    helperText={newPassword.hint}
                                />
                                <TextField
                                    label={t('user.accountLogin.password2Label')}
                                    type={'password'}
                                    value={reNewPassword.value}
                                    onChange={e => setReNewPassword({ value: e.target.value })}
                                    error={reNewPassword.error}
                                    helperText={reNewPassword.hint}
                                />
                            </Stack>,
                        onSaveClick: () => {
                            if (!oldPassword.value) {
                                setOldPassword(p => ({ ...p, error: true, hint: t('error.requiredFieldEmpty') }));
                                return Promise.reject();
                            }
                            if (!newPassword.value) {
                                setNewPassword(p => ({ ...p, error: true, hint: t('error.requiredFieldEmpty') }));
                                return Promise.reject();
                            }
                            if (newPassword.value !== reNewPassword.value) {
                                setNewPassword(p => ({ ...p, error: true, hint: t('error.unmatchedPasswords') }));
                                setReNewPassword(p => ({ ...p, error: true }));
                                return Promise.reject();
                            }
                            const currentUser = auth.currentUser;
                            if (!!currentUser && currentUser.email) {
                                return currentUser.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(currentUser.email, oldPassword.value))
                                    .then((userCredential) => {
                                        return userCredential.user?.updatePassword(newPassword.value)
                                            ?? currentUser.updatePassword(newPassword.value);
                                    })
                                    .then(() => {
                                        //clear all field
                                        setNewEmail({ value: '' });
                                        setReNewEmail({ value: '' });
                                        setOldPassword({ value: '' });
                                    })
                                    .catch((e) => {
                                        console.error(e);
                                        if (e.code === 'auth/wrong-password') {
                                            setOldPassword(p => ({ ...p, error: true, hint: t('error.wrongPassword') }));
                                        } else {
                                            setReNewPassword(p => ({ ...p, error: true, hint: e.message || t('error.updatePassword') }));
                                        }
                                        return Promise.reject();
                                    })

                            } else {
                                //no current user with credential as email?
                                return Promise.reject();
                            }
                        }
                    }],
            },
            {
                label: t('user.accountLogin.socialAccountsTitle'),
                items: Object.keys(EUserVerification)
                    .filter(entry => isNaN(Number(entry)) && entry !== EUserVerification.EMAIL)
                    .map(entry => {
                        const provider = getAuthProviderFrom(entry as EUserVerification);
                        const connected = userLoginInfo?.social_accounts.some(sa => sa.platform === entry)
                            || auth.currentUser?.providerData.some(pd => pd?.providerId === provider?.providerId);
                        const providerId = provider?.providerId;
                        return {
                            title: getLinkedAccountName(getUIVerificationFrom(entry)),
                            value: connected ? t('user.accountLogin.connectedLabel') : t('user.accountLogin.unconnectedLabel'),
                            onEditClick: !provider || !providerId ? undefined :
                                () => connected ?
                                    auth.currentUser?.unlink(providerId)
                                        .then(() => {
                                            userLoginResult.refetch();
                                        })
                                    : auth.currentUser?.linkWithPopup(provider)
                                        .then(() => {
                                            userLoginResult.refetch();
                                        })
                                        .catch(e => {
                                            console.error(e);
                                            if (e.code === 'auth/provider-already-linked') {
                                                userLoginResult.refetch();
                                            }
                                        }),
                            editLabel: connected ? t('user.accountLogin.disconnectButton') : t('user.accountLogin.connectButton'),
                        };
                    }),
            },
            //TODO add the functionality first
            // {
            //     label: t('user.accountLogin.deviceHistoryTitle'),
            //     items: userLoginInfo.device_history?.map(dh => ({
            //         title: dh.device_name
            //     })) ?? [],
            // }
        ]

    const helpItems = HELP_ITEMS.map(h => ({
        ...h,
        title: t(h.title),
        description: t(h.description)
    }))

    if (!!children) {
        return children({ items, helpItems: helpItems });
    } else {
        return <AccountBaseInfo title={getTitleFromAccountPage(t, subPage)}
            items={items}
            helpItems={helpItems} />
    }
}

const HELP_ITEMS: IAccountHelpItemProps[] = [
    {
        icon: <Security />,
        title: 'user.accountLogin.helpInfo1Title',
        description: 'user.accountLogin.helpInfo1Content',
    },
    {
        icon: <Visibility />,
        title: 'user.accountLogin.helpInfo2Title',
        description: 'user.accountLogin.helpInfo2Content',
    }
];
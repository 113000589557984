
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LISTING_CREATION_STEPS } from "../../pages/listings/create/utils";
import { DeepPartial } from "../../types/common";
import { Listing, WithID, WithRef } from "../../types/db";
import { ListingContext } from "./ListingContext";

interface IListingCreationStep {
    /** The id of the step, can be used for key in UI and for navigation */
    id: string;
    /** The title of the step */
    label: string;
    /** The description of the step */
    description: string;
    /** Boolean indicating if the step is optional or not */
    optional?: boolean;
    /** Error message for the step if something went wrong */
    error?: string | boolean;
}

type LocalListing = Partial<WithRef<WithID<DeepPartial<Listing>, string>>>;

interface IListingCreationFlowContext {
    /** The steps information */
    steps: IListingCreationStep[];
    /** The current active step index */
    activeStep: number;
    /** Function which handle the next button click */
    handleNext: () => void;
    /** Function which handle the prev button click */
    handlePrev: () => void;
    /** Boolean indicating that an action is being executed (allow to indicate a loading state on the next button for example) */
    isLoading?: boolean;
    /** The current temporary listing information entered by the user (only locally changing on the fly) */
    listing: LocalListing;
    /** Boolean indicating if the current step is completed */
    completed: boolean;
    /** Set the information of the local listing and if the step is completed */
    setActivePageInfo: (listing: Omit<LocalListing, 'id'> | undefined | ((curListingInfo: LocalListing) => LocalListing), completed?: boolean) => void;
}

export const ListingCreationFlowContext = createContext<IListingCreationFlowContext | null>(null);

interface IListingCreationFlowProviderProps {
    /** The id of the current step */
    stepId?: string;
    /** The callback on current step changing (with optional listing Id) to navigate to new step */
    onStepId: (newStepId: string, listingId?: string) => void;
    /** The callback for the last step of publishing, called after the db is updated to navigate to published successful page*/
    onPublished: () => void;
}

export const ListingCreationFlowProvider = ({
    children,
    stepId,
    onStepId,
    onPublished,
}: PropsWithChildren<IListingCreationFlowProviderProps>) => {
    const listingCreationContext = useContext(ListingContext);
    if (!listingCreationContext) {
        throw new Error('Need ListingContext');
    }
    const { create, update, publish, listing, isLoading } = listingCreationContext;

    const { t } = useTranslation();
    // initialization of the steps
    const [steps, setSteps] = useState<IListingCreationStep[]>(LISTING_CREATION_STEPS.map(s => ({
        id: s.id,
        label: t(s.labelKey),
        description: s.descriptionKey ? t(s.descriptionKey) : '',
        optional: s.optional,
    })));

    // initialization of the current step if current stepId provided look for the index
    const activeStep = (!!stepId && LISTING_CREATION_STEPS.findIndex(s => s.id === stepId)) || 0;

    // Initialization of the local information given by the user (we don't want to update the DB every touch, will update during handleNext)
    const [localListing, setLocalInfo] = useState<LocalListing>(listing || {});

    // If the DB listing has changed, we want to make sure the local listing is updated as well
    useEffect(() => {
        // console.log('listing has changed in DB: update local')
        setLocalInfo(listing || {})
    }, [listing])

    // Initialization of the completion of the current step
    const [completed, setCompleted] = useState<boolean>(!!steps[activeStep].optional);

    // If the activeStep change, we want to make sure to reset the completion
    //commented as overwriting starting completion do in sub page
    // useEffect(() => {
    //     setCompleted(!!steps[activeStep].optional)
    // }, [activeStep, steps]);

    // Handle the action done when user press the next button
    const handleNext = useCallback(() => {
        const newActiveStep = activeStep + 1;
        //if we are at the last step
        if (newActiveStep >= steps.length) {
            publish()
                .then(() => {
                    onPublished();
                })
                .catch(() => {
                    //error while publishing the new listing
                    //mark step as error
                    setSteps(curSteps => curSteps.map((s, i) => i === activeStep ? { ...s, error: true } : s))
                });
            return;
        }
        //if we are at the first step create the listing
        if (activeStep === 0) {
            const parent = localListing?.breadcrumbs?.[localListing.breadcrumbs?.length - 1]?.id || undefined;
            create(localListing, parent)
                .then((createdListingId) => {
                    onStepId(steps[newActiveStep].id, createdListingId)
                })
                .catch((e) => {
                    //error while creating the new listing
                    // console.log('error while creating the new listing', e)
                    //mark step as error
                    setSteps(curSteps => curSteps.map((s, i) => i === activeStep ? { ...s, error: true } : s))
                });
            return;
        }
        //save the information to the DB
        update({
            ...localListing,
            status: "pending",//going back to pending until publish is pressed
        })
            .then(() => {
                onStepId(steps[newActiveStep].id)
            })
            .catch(() => {
                //error while updating the new listing
                //mark step as error
                setSteps(curSteps => curSteps.map((s, i) => i === activeStep ? { ...s, error: true } : s))
            });
    }, [activeStep, create, localListing, onPublished, onStepId, publish, steps, update]);

    // Handle the action done when user press the prev button
    const handlePrev = useCallback(() => {
        const newActiveStep = activeStep - 1;
        // if we are on the first page, cannot go back more, simply do nothing (should have UI disabled)
        if (activeStep === 0) {
            return;
        }
        //save the information from the current page entered so far in the DB to not loose them
        update({
            ...localListing,
            status: "pending",//going back to pending until publish is pressed
        })
            .then(() => {
                onStepId(steps[newActiveStep].id)
                //clear error if any
                setSteps(curSteps => curSteps.map((s, i) => i === activeStep ? { ...s, error: false } : s))

            })
            .catch(() => {
                //error while updating the new listing
                //mark step as error
                setSteps(curSteps => curSteps.map((s, i) => i === activeStep ? { ...s, error: true } : s))
            });
    }, [activeStep, localListing, onStepId, steps, update]);

    // Handle the change to the local listing information
    const handleActivePageInfo = useCallback((
        listingInfo: Omit<LocalListing, 'id'> | undefined | ((curListingInfo: LocalListing) => LocalListing),
        completed?: boolean
    ) => {
        // console.log('setActivePageInfo', listingInfo, completed)
        //if listing deliberately undefined, reset the data, otherwise just merge it
        setLocalInfo(curLocalInfo => {
            if (listingInfo === undefined) {
                //reset the local info
                return {};
            }
            if (typeof listingInfo === 'function') {
                return listingInfo(curLocalInfo);
            }
            const keys = Object.keys(listingInfo) as any[];
            if (keys.length === 0) {
                //if nothing to change, just return the current local info as is
                return curLocalInfo;
            }

            return { ...curLocalInfo, ...listingInfo };
        });
        //if completed not defined keep as before, otherwise overwrite
        setCompleted(curCompleted => completed === undefined ? curCompleted : completed);
    }, [])

    return (
        <ListingCreationFlowContext.Provider value={{
            steps,
            activeStep,
            handleNext,
            handlePrev,
            completed,
            setActivePageInfo: handleActivePageInfo,
            listing: localListing,
            isLoading: isLoading //from the listing context
        }
        }>
            {children}
        </ListingCreationFlowContext.Provider>
    );
};


// import Color from "./ColorLib";

import { EUserRole } from "../../types/db";
import { ERole } from "../EnumUtility";

/**
 * The general color indicating a Host (Green like the land)
 */
const COLOR_HOST = '#00ff42ff';
/**
 * The general color indicating a cohost (Blue like the sky)
 */
const COLOR_COHOST = '#000fffff';
/**
 * The general color indicating a guest (Red like the roof over your head)
 */
const COLOR_GUEST = '#ff0000ff';


export const getColorFrom = (userRole: EUserRole | ERole, defaultColor?: string) => {
    switch (userRole) {
        case EUserRole.HOST:
        case ERole.HOST:
            return COLOR_HOST;
        case EUserRole.COHOST:
        case ERole.COHOST:
            return COLOR_COHOST;
        case EUserRole.GUEST:
        case ERole.GUEST:
            return COLOR_GUEST;
        default:
            return defaultColor || 'transparent';
    }
}

export const convertToOpacity = (c: string, opacity: number) => {
    // return Color(c)?.alpha(opacity);
    const rgb = c.match(/^rgba?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    let r, g, b;
    if (rgb && rgb.length === 4) {
        r = rgb[1];
        g = rgb[2];
        b = rgb[3];
    } else {
        const color = c.replace('#', '');
        r = parseInt(color.substring(0, 2), 16);
        g = parseInt(color.substring(2, 4), 16);
        b = parseInt(color.substring(4, 6), 16);
    }

    return `rgba(${r},${g},${b},${opacity})`;
}
import React from 'react';
import { Divider, Stack } from "@mui/material";
import { AccountHelpItem, IAccountHelpItemProps } from "../AccountHelpItem";

export interface IAccountHelpSectionProps {
    items: IAccountHelpItemProps[];
}

export const AccountHelpSection = ({
    items
}: IAccountHelpSectionProps) => {
    if (!items.length) {
        return null;
    }

    return (
        <Stack direction={'column'} spacing={2}>
            {
                items.map((i, index) => {
                    return (
                        <Stack key={`help-item-${index}`}
                            direction={'column'}
                            spacing={2}>
                            {!!index && <Divider />}
                            <AccountHelpItem {...i} />
                        </Stack>
                    );
                })
            }
        </Stack>
    )
}
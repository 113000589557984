import {
    User,
    UserPrivateFinances,
    UserPrivateInfo,
    UserPrivateLogin,
    UserPrivateNotifications,
    UserPrivatePreferences,
    UserPrivatePrivacy,
    UserPrivateReferrals,
    UserProtectedInfo,
    USERS_COLLECTION,
    USER_PRIVATE_COLLECTION,
    USER_PRIVATE_FINANCE_DOCUMENT,
    USER_PRIVATE_INFO_DOCUMENT,
    USER_PRIVATE_LOGIN_DOCUMENT,
    USER_PRIVATE_NOTIFICATIONS_DOCUMENT,
    USER_PRIVATE_PREFERENCES_DOCUMENT,
    USER_PRIVATE_PRIVACY_DOCUMENT,
    USER_PRIVATE_REFERRALS_DOCUMENT,
    USER_PROTECTED_COLLECTION,
    USER_PROTECTED_INFO_DOCUMENT,
    Listing, LISTINGS_COLLECTION,
    Review, ReviewFieldFrom, ReviewFieldTo, REVIEWS_COLLECTION,
    WithID,
    EUserRole,
    WithRef
} from "../../types/db";
import firebase from "firebase/app";
import { db } from "../firebase";

const getUsersCollectionRef = () => {
    return db.collection(USERS_COLLECTION);
}


/***********************************************
 * USER PUBLIC
 ***********************************************/

/** 
 * Get the reference to the user document (public info) 
 * @param userId the user id
 */
export const getUserDocRef = (userId: string) => {
    return getUsersCollectionRef().doc(userId);
}

/**
 * Get the public user information
 * @param userId the user id
 * @returns The user public information
 */
export const getUserInfo = async (userId: string) => {
    const data = await getUserDocRef(userId).get()
    return data.data() as User;
}

/**
 * Update the public user information
 * @param userId the user id
 * @param info the user info to update (can only update certain value from the front-end/by the user)
 */
export const updateUserInfo = (userId: string, info: Partial<Pick<User, 'display_name' | 'avatar_url' | 'description' | 'from' | 'languages'>>) => {
    return getUserDocRef(userId).update(info);
}

/***********************************************
 * USER PROTECTED
 ***********************************************/

/** 
 * Get the reference to the user document (protected info) 
 * @param userId the user id
 * @param docId the document id
 */
export const getUserProtectedDocRef = (
    userId: string,
    docId: typeof USER_PROTECTED_INFO_DOCUMENT,
) => {
    return getUserDocRef(userId).collection(USER_PROTECTED_COLLECTION).doc(docId);
}

/**
 * Get the protected user information
 * @param userId  the user id
 * @returns The user protected information
 */
export const getUserProtectedInfo = async (userId: string) => {
    const data = await getUserProtectedDocRef(userId, USER_PROTECTED_INFO_DOCUMENT).get()
    return data.data() as UserProtectedInfo;
}

/**
 * Update the protected user information
 * @param userId the user id
 * @param info the user info to update
 * @warning UPDATE: the user info cannot be updated manually by user (done by the cloud)
 * @deprecated
 */
export const updateUserProtectedInfo = (userId: string, info: never) => {
    return getUserProtectedDocRef(userId, USER_PROTECTED_INFO_DOCUMENT).set(info, { merge: true });
}

/***********************************************
 * USER PRIVATE
 ***********************************************/

/** 
 * Get the reference to the user document (private info) 
 * @param userId the user id
 * @param docId the document id
 */
export const getUserPrivateDocRef = (
    userId: string,
    docId: typeof USER_PRIVATE_INFO_DOCUMENT
        | typeof USER_PRIVATE_LOGIN_DOCUMENT
        | typeof USER_PRIVATE_FINANCE_DOCUMENT
        | typeof USER_PRIVATE_NOTIFICATIONS_DOCUMENT
        | typeof USER_PRIVATE_PRIVACY_DOCUMENT
        | typeof USER_PRIVATE_PREFERENCES_DOCUMENT
        | typeof USER_PRIVATE_REFERRALS_DOCUMENT,
) => {
    return getUserDocRef(userId).collection(USER_PRIVATE_COLLECTION).doc(docId);
}

/**
 * Get the private user general information
 * @param userId  the user id
 * @returns The user private information
 */
export const getUserPrivateInfo = async (userId: string) => {
    const data = await getUserPrivateDocRef(userId, USER_PRIVATE_INFO_DOCUMENT).get();
    return data.data() as UserPrivateInfo;
}

/**
 * Update the private user information
 * @param userId the user id
 * @param info the user info to update (can only update certain value from the front-end/by the user)
 */
export const updateUserPrivateInfo = (userId: string, info: Partial<Pick<UserPrivateInfo, 'full_name' | 'email' | 'phone' | 'address' | 'emergency_contact'>>) => {
    return getUserPrivateDocRef(userId, USER_PRIVATE_INFO_DOCUMENT).set(info, { merge: true });
}

/**
 * Get the private user login information
 * @param userId  the user id
 * @returns The user login information
 */
export const getUserLoginInfo = async (userId: string) => {
    const data = await getUserPrivateDocRef(userId, USER_PRIVATE_LOGIN_DOCUMENT).get();
    return data.data() as UserPrivateLogin;
}

/**
 * Get the private user finance information
 * @param userId  the user id
 * @returns The user finance information
 */
export const getUserFinanceInfo = async (userId: string) => {
    const data = await getUserPrivateDocRef(userId, USER_PRIVATE_FINANCE_DOCUMENT).get();
    return data.data() as UserPrivateFinances;
}

/**
 * Get the private user notification information
 * @param userId  the user id
 * @returns The user notification information
 */
export const getUserNotificationInfo = async (userId: string) => {
    const data = await getUserPrivateDocRef(userId, USER_PRIVATE_NOTIFICATIONS_DOCUMENT).get();
    return data.data() as UserPrivateNotifications;
}

/**
 * Get the private user privacy information
 * @param userId  the user id
 * @returns The user privacy information
 */
export const getUserPrivacyInfo = async (userId: string) => {
    const data = await getUserPrivateDocRef(userId, USER_PRIVATE_PRIVACY_DOCUMENT).get();
    return data.data() as UserPrivatePrivacy;
}

/**
 * Get the private user preferences information
 * @param userId  the user id
 * @returns The user preferences information
 */
export const getUserPreferencesInfo = async (userId: string) => {
    const data = await getUserPrivateDocRef(userId, USER_PRIVATE_PREFERENCES_DOCUMENT).get();
    return data.data() as UserPrivatePreferences;
}

/**
 * Update the private user information
 * @param userId the user id
 * @param info the user info to update (can only update certain value from the front-end/by the user)
 */
export const updateUserPreferencesInfo = (userId: string, info: Partial<UserPrivatePreferences>) => {
    return getUserPrivateDocRef(userId, USER_PRIVATE_PREFERENCES_DOCUMENT).set(info, { merge: true });
}

/**
 * Get the private user referrals information
 * @param userId  the user id
 * @returns The user referrals information
 */
export const getUserReferralsInfo = async (userId: string) => {
    const data = await getUserPrivateDocRef(userId, USER_PRIVATE_REFERRALS_DOCUMENT).get();
    return data.data() as UserPrivateReferrals;
}

/***********************************************
 * USER REVIEWS
 ***********************************************/

//User Review

const getUserReviewCollectionRef = (userId: string) => {
    return getUserDocRef(userId).collection(REVIEWS_COLLECTION);
}

export type UserReviewQueryParams = {
    limit?: number;
    from: ReviewFieldFrom;
    to: ReviewFieldTo;
}

export const getUserReviews = async (userId: string, queryParams?: UserReviewQueryParams) => {
    var query: firebase.firestore.Query<firebase.firestore.DocumentData>
    query = getUserReviewCollectionRef(userId);

    if (queryParams) {
        query = query.where("from", "==", queryParams.from);
        query = query.where("to", "==", queryParams.to);
        if (queryParams.limit) {
            query = query.limit(queryParams.limit);
        }
        switch (queryParams.to) {
            case EUserRole.HOST:
                query = query.where("host.id", "==", userId);
                break;
            case EUserRole.COHOST:
                query = query.where("cohost.id", "==", userId);
                break;
            case EUserRole.GUEST:
                query = query.where("guest.id", "==", userId);
                break;
        }
    }

    const data = await query.get();

    return data.docs.map(doc => doc.data() as Review);
}


/***********************************************
 * USER LISTINGS
 ***********************************************/

/**
 * Get the User's listings 
 * @param userId The user id
 * @param userRole The user role (to check for host's listing or cohost's listing)
 * @param root If we only want the root/parent listings or all of them
 * @returns the list of listings
 */
export const getUserListings = async (userId: string, userRole: EUserRole, root = true) => {
    // console.log('getUserListings', userId, userRole)
    if (userRole === EUserRole.GUEST) {
        //guests does not own/manage listings
        return [];
    }
    var query = root ? db.collection(LISTINGS_COLLECTION) : db.collectionGroup(LISTINGS_COLLECTION);
    if (userRole === EUserRole.HOST) {
        // console.log('host.id', userId, userRole)
        query = query.where("host.id", '==', userId)
    }
    if (userRole === EUserRole.COHOST) {
        // console.log('cohost.id', userId, userRole)
        query = query.where("cohost.id", '==', userId)
    }
    const data = await query.get();
    return data.docs.map(doc => ({ ...doc.data(), id: doc.id, ref: doc.ref } as WithRef<WithID<Listing, string>>));
}
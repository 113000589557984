import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Skeleton, Stack, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { IssueStateView } from '../../IssueStateView';
import { IShopCartItemProps, ShopCartItem } from '../ShopCartItem';
import { ShopPrice } from '../ShopPrice';
import { calculateTotalCartItems, calculateTotalCartValue } from './ShopCheckout.utils';
import { currencyFormatter, DEFAULT_CURRENCY } from '../../../utilities/currency/function';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { PropsWithErrorState, PropsWithLoadingState } from '../../../utilities';
import { Address } from '../../address/Address';
import { Address as AddressType } from '../../address/Address.utils';
import { GoogleMap } from '@react-google-maps/api';
import { getAddressComp } from '../../../utilities/address';
import { SearchLocationInputView } from '../../SearchLocationInput/SearchLocationInput';

export type ShippingOption = {
    /** The id of the shipping, can be used for value in radio button */
    id: string;
    /** 
     * Name of the shipping option
     * @example 'Flat Rate (Estimated delivery: Jan 13⁠–16)'
     */
    name: string;
    /**
     * The rate of the shipping
     * @example 11.28
     */
    rate: number;
    /**
     * The currency code of the shipping
     * @example 'CAD'
     */
    currency: string;
}

export type TaxInfo = {
    /** Tax required */
    required: boolean;
    /** Rate in percent (ex: 20%=0.2) */
    rate: number;
    /** Tax applies to shipping fee */
    shippingTaxable: boolean;
}

export interface IShopCheckoutProps {
    /** The list of items in the cart */
    items: IShopCartItemProps[];
    /** The shipping options for those items */
    shippingOptions: ShippingOption[];
    /** The selected shipping option, default to first available shipping option */
    selectedShippingOptionID?: string;
    /** Callback on shipping option selected */
    onShippingOptionSelected: (id: string) => void;
    /** Boolean to inform if the shipping info is loading */
    isShippingInfoLoading?: boolean;
    /** The tax associated with this checkout */
    taxes?: TaxInfo;
    /** Callback on pay button clicked */
    onPayClick: () => Promise<void>;
    /** Callback on back/cancel clicked */
    onBackClick: () => void;
    /** The shipping address */
    address?: AddressType;
    /** Callback on address changed */
    onAddressChanged: (address: AddressType) => void;
}

export const ShopCheckout = ({
    items,
    shippingOptions,
    selectedShippingOptionID,
    onShippingOptionSelected,
    isShippingInfoLoading,
    taxes,
    onPayClick,
    onBackClick,
    onAddressChanged,
    loading,
    error,
}: PropsWithErrorState<PropsWithLoadingState<IShopCheckoutProps>>) => {
    const { t } = useTranslation();

    if (!!error) {
        return (
            <IssueStateView
                title={t('shop.checkout.errorTitle')}
                image=''
                description={t('shop.checkout.errorContent')}
                button={onBackClick ? { onActionClick: onBackClick, text: t('shop.checkout.errorButton') } : undefined}
            />
        )
    }

    if (!loading && !items.length) {
        //empty state
        return (
            <IssueStateView
                title={t('shop.checkout.emptyTitle')}
                image=''
                description={t('shop.checkout.emptyContent')}
                button={onBackClick ? { onActionClick: onBackClick, text: t('shop.checkout.emptyButton') } : undefined} />
        )
    }

    return (
        <Grid container spacing={1} padding={2}>
            <Grid item xs={12} sm={8}>
                <Stack direction={'column'}
                    justifyContent={'start'}
                    alignItems={'stretch'}
                    spacing={2}>
                    <Typography variant='h3'>{t('shop.checkout.title')}</Typography>
                    <Divider />
                    {!loading ?
                        <CustomizedAccordions items={items}
                            isShippingInfoLoading={isShippingInfoLoading}
                            shippingOptions={shippingOptions}
                            selectedShippingOptionID={selectedShippingOptionID}
                            onShippingOptionSelected={onShippingOptionSelected}
                            onAddressChanged={onAddressChanged} />
                        :
                        <CustomizedAccordions
                            loading={!!loading} />
                    }
                </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
                {!loading ?
                    <OrderSummary onPayClick={onPayClick}
                        items={items}
                        taxes={taxes}
                        shipping={shippingOptions?.find(so => so.id === selectedShippingOptionID)} />
                    : <OrderSummary loading />
                }
            </Grid>
        </Grid>
    );
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const CustomizedAccordions = ({ items, shippingOptions, selectedShippingOptionID, onShippingOptionSelected, isShippingInfoLoading, address, onAddressChanged, loading }:
    PropsWithLoadingState<Pick<IShopCheckoutProps, 'items' | 'shippingOptions' | 'selectedShippingOptionID' | 'onShippingOptionSelected' | 'isShippingInfoLoading' | 'address' | 'onAddressChanged'>>) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState<string | false>('');
    const [newAddress, setNewAddress] = useState<AddressType | undefined>(address)
    const mapRef = useRef<google.maps.Map>();

    useEffect(() => {
        if (!!loading) return;//do nothing while loading
        if (shippingOptions?.length > 0) {
            setExpanded('panel3');
        } else {
            setExpanded('panel1');
        }
    }, [loading, shippingOptions]);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    // Handle the selection of a place in the list of autocomplete
    const handlePlaceSelect = (place: google.maps.places.AutocompletePrediction | null) => {
        // console.log('handlePlaceSelect', place);
        if (!place) return;//if no place selected
        if (!mapRef.current) return;//if no map reference

        //fetch the details of this place
        (new google.maps.places.PlacesService(mapRef.current))
            .getDetails({
                placeId: place.place_id,
                fields: ["address_components"]
            },
                (results, status) => {
                    // console.log('result', results)
                    if (status === google.maps.places.PlacesServiceStatus.OK && !!results?.address_components) {
                        setNewAddress({
                            address1: getAddressComp(results, "street_number") + ' ' + getAddressComp(results, "route"),
                            zip: getAddressComp(results, 'postal_code'),
                            city: getAddressComp(results, 'locality', 'long_name') || getAddressComp(results, 'administrative_area_level_2', 'long_name'),
                            zone: getAddressComp(results, 'administrative_area_level_1', 'short_name'),
                            country: getAddressComp(results, 'country', 'short_name'),
                        })
                    }
                });
    };

    //Handle the manual changes in the address
    const handleManualAddressChange = (newAddress: AddressType) => {
        setNewAddress(newAddress)
    }

    const handleAddAddress = () => {
        !!newAddress && onAddressChanged?.(newAddress);
    }

    const steps = t('shop.checkoutSteps', { returnObjects: true }) as Array<string>;
    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary>
                    <Typography>{!loading ? steps[0] : <Skeleton />}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction={'column'} spacing={1}>
                        <SearchLocationInputView
                            onPlaceSelected={handlePlaceSelect}
                            label={t('shop.checkout.shippingAddressLabel')}
                            placeholder={t('shop.checkout.shippingAddressPlaceholder')}
                            disabled={!!loading}
                            helperText={t('shop.checkout.shippingAddressCaption')} />
                        <Divider>{t('shop.checkout.shippingAddressTitle')}</Divider>
                        <Address address={newAddress} onChange={handleManualAddressChange} editable={!address} />
                        <Button onClick={handleAddAddress} variant={'contained'}>{t('shop.checkout.shippingAddressAddConfirmBtn')}</Button>
                        <GoogleMap
                            onLoad={(map) => { mapRef.current = map }}
                        ></GoogleMap>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} disabled={!shippingOptions?.length}>
                <AccordionSummary>
                    <Stack direction={'row'} justifyContent={'start'} alignItems={'center'}>
                        <Typography>{!loading ? steps[1] : <Skeleton />}</Typography>
                        {(!!loading || isShippingInfoLoading) && <CircularProgress />}
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction={'column'}
                        justifyContent={'start'}
                        alignItems={'stretch'}
                        spacing={2}>
                        <FormControl>
                            <FormLabel>{t('shop.checkout.shippingMethodLabel')}</FormLabel>
                            {!loading && !!shippingOptions?.length &&
                                <RadioGroup
                                    value={selectedShippingOptionID || ''}
                                    onChange={(event) => onShippingOptionSelected((event.target as HTMLInputElement).value)}
                                >
                                    {shippingOptions.map(so => {
                                        return <FormControlLabel key={so.id}
                                            value={so.id}
                                            control={<Radio />}
                                            label={`${currencyFormatter({ quantity: so.rate, currency: so.currency })}: ${so.name}`} />
                                    })}
                                </RadioGroup>
                            }
                        </FormControl>
                        <Divider />
                        {
                            !loading ?
                                items.map((item, index) =>
                                    <ShopCartItem key={`cart-item-${index}`} {...item} />
                                )
                                :
                                new Array(6).fill('').map((_, index) =>
                                    <ShopCartItem loading key={`cart-loading-item-${index}`} />
                                )
                        }
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

interface IOrderSummaryProps extends Pick<IShopCheckoutProps, 'items' | 'taxes' | 'onPayClick'> {
    shipping?: ShippingOption,
}

const OrderSummary = ({ onPayClick, items, shipping, taxes, loading }: PropsWithLoadingState<IOrderSummaryProps>) => {
    const { t } = useTranslation();
    const [paymentLoading, setPaymentLoading] = useState(false);
    const cartValueProps = calculateTotalCartValue({ items: !loading ? items : [] });
    const renderShippingPrice = () => {
        if (!!loading) return <Skeleton width={'20%'} />
        return shipping ?
            <ShopPrice originalPrice={{ amount: shipping.rate, currency: shipping.currency }} />
            : <Typography>{"--"}</Typography>;
    }
    const taxValueProps =
    {
        amount:
            !loading && taxes && taxes.required ?
                taxes.shippingTaxable ?
                    ((cartValueProps.discountedPrice?.amount || cartValueProps.originalPrice.amount) + (shipping?.rate ?? 0)) * taxes.rate
                    :
                    (cartValueProps.discountedPrice?.amount || cartValueProps.originalPrice.amount) * taxes.rate
                :
                0,
        currency: !loading ? cartValueProps.originalPrice.currency : DEFAULT_CURRENCY,
    };
    const renderTaxPrice = () => {
        if (!!loading) return <Skeleton width={'20%'} />
        return taxes ?
            <ShopPrice originalPrice={taxValueProps} />
            : <Typography>{"--"}</Typography>;
    }
    const renderPreTaxTotalPrice = () => {
        if (!!loading) return <Skeleton width={'20%'} />
        return shipping ?
            <ShopPrice originalPrice={{ amount: (cartValueProps.discountedPrice?.amount ?? cartValueProps.originalPrice.amount) + shipping.rate, currency: shipping.currency }} />
            : <Typography>{"--"}</Typography>;
    }
    const renderTotalPrice = () => {
        if (!!loading) return <Skeleton width={'20%'} />
        return shipping && taxes ?
            <ShopPrice originalPrice={{ amount: (cartValueProps.discountedPrice?.amount ?? cartValueProps.originalPrice.amount) + +shipping.rate + taxValueProps.amount, currency: shipping.currency }} />
            : <Typography>{"--"}</Typography>;
    }

    const handleButtonClick = async () => {
        if (!!loading) return;
        setPaymentLoading(true);
        await onPayClick();
    }
    return <Paper elevation={1} sx={{ p: 1 }}>
        <Stack direction={'column'}
            justifyContent={'center'}
            alignItems={'stretch'}
            spacing={1}
        >
            <LoadingButton variant='contained'
                onClick={handleButtonClick}
                loading={paymentLoading}
                disabled={!!loading || !shipping || !taxes}
                loadingIndicator={t('shop.checkout.processingIndicator')}>
                {t('shop.checkout.payButton')}
            </LoadingButton>
            <Divider />
            <Typography variant='h5'>
                {t('shop.checkout.orderSummaryLabel')}
            </Typography>
            <Stack direction={'row'}
                justifyContent={'space-between'}
                alignItems={'baseline'}>
                <Typography>{
                    !loading ?
                        t('punctuationColon', {
                            sentence: t('shop.checkout.itemSummaryLabel', { count: calculateTotalCartItems({ items }) })
                        })
                        : <Skeleton width={30} />
                }</Typography>
                {!loading ? <ShopPrice {...cartValueProps} /> : <Skeleton width={'20%'} />}
            </Stack>
            <Stack direction={'row'}
                justifyContent={'space-between'}
                alignItems={'baseline'}>
                <Typography>{
                    !loading ?
                        t('punctuationColon', {
                            sentence: t('shop.checkout.shippingSummaryLabel')
                        })
                        : <Skeleton width={30} />
                }</Typography>
                {renderShippingPrice()}
            </Stack>
            <Divider />
            <Stack direction={'row'}
                justifyContent={'space-between'}
                alignItems={'baseline'}>
                <Typography>{
                    !loading ?
                        t('punctuationColon', {
                            sentence: t('shop.checkout.subTotalSummaryLabel')
                        })
                        : <Skeleton width={30} />
                }</Typography>
                {renderPreTaxTotalPrice()}
            </Stack>
            <Stack direction={'row'}
                justifyContent={'space-between'}
                alignItems={'baseline'}>
                <Typography>{
                    !loading ?
                        t('punctuationColon', {
                            sentence: t('shop.checkout.taxSummaryLabel')
                        })
                        : <Skeleton width={30} />
                }</Typography>
                {renderTaxPrice()}
            </Stack>
            <Divider />
            <Stack direction={'row'}
                justifyContent={'space-between'}
                alignItems={'baseline'}>
                <Typography>{
                    !loading ?
                        t('punctuationColon', {
                            sentence: t('shop.checkout.totalSummaryLabel')
                        })
                        : <Skeleton width={30} />
                }</Typography>
                {renderTotalPrice()}
            </Stack>
        </Stack>
    </Paper>
}
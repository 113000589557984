import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTE_PATH } from "../../utilities/navigation";

export const NotFoundPage = () => {
  return (
    <Stack direction={'column'}
      sx={{ m: 2 }}
      justifyContent={'center'}
      alignItems={'center'}
      spacing={2}>

      <img
        src="https://cdn.pixabay.com/photo/2018/01/04/15/51/404-error-3060993__340.png"
        alt="Page not found"
      />
      <Typography variant={'h2'}>404 Page not found!</Typography>
      <Button
        component={Link}
        to={ROUTE_PATH.home}
        variant="contained"
      >
        Return to Home Page
      </Button>
    </Stack>
  );
};

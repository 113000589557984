import { NavigateNext } from '@mui/icons-material';
import { Button, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PropsWithLoadingState } from '../../../utilities';
import { HelpTopicArticleItem, IHelpTopicArticleItemProps } from '../HelpTopicArticleItem';

export interface IHelpTopArticlesProps {
    /** The list of items */
    items: IHelpTopicArticleItemProps[];
    /** Callback on click to show all topics */
    onShowTopicsClick: () => void;
}

/** Display a list of top rated article (summary) */
export const HelpTopArticles = ({
    items,
    onShowTopicsClick,
    loading,
}: PropsWithLoadingState<IHelpTopArticlesProps>) => {
    const { t } = useTranslation();
    if (!!loading) {
        return <LinearProgress />;
    }
    if (!items.length) {
        return (
            <Button variant='text'
                endIcon={<NavigateNext />}
                onClick={onShowTopicsClick}
            >{t('help.article.topEmptyBtn')}</Button>
        );
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Stack direction={{ xs: 'column-reverse', sm: 'row' }}
                    justifyContent={'space-between'}
                    alignItems={'center'}>
                    <Typography variant='h4'>{t('help.article.topTitle')}</Typography>
                    <Button variant='text'
                        size='small'
                        endIcon={<NavigateNext />}
                        onClick={onShowTopicsClick}
                    >{t('help.article.topSeeAllBtn')}</Button>
                </Stack>
            </Grid>
            {
                items.map((item, index) =>
                    <Grid item key={`top-article-item-${index}`} xs={12} sm={6} md={4}>
                        <HelpTopicArticleItem  {...item} />
                    </Grid>
                )
            }
        </Grid>
    );
}

import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { createListing, deleteListing, getListingInfo, moveListing, updateListing } from "../../services/listing";
import { DeepPartial } from "../../types/common";
import { EUserRole, Listing, WithID, WithRef } from "../../types/db";
import { UserContext } from "../UserInformation";

interface IListingInformation
    extends WithRef<WithID<Partial<Listing>, string>> {
}

interface IListingContext {
    /** The data of the listing with the id and reference to the DB */
    listing: IListingInformation | null;
    /** If any action is currently loading */
    isLoading?: boolean;
    /** If any action ended up with an error */
    error?: string;
    /** Function to create a new listing (if id already existing, it will move it under the parentListing provided). Promise returning the listing id */
    create(listingData: DeepPartial<Listing>, parentListing?: string): Promise<string>;
    /** Function to update a listing */
    update(listing: DeepPartial<Omit<IListingInformation, 'id'>>): Promise<void>;
    /** Function to delete a listing */
    delete(): Promise<void>;
    /** Function to publish a listing */
    publish(): Promise<void>;
    /** Function to know if a listing is publishable */
    isPublishable(): boolean;
}

/** The listing context providing listing information and functionality to all children */
export const ListingContext = createContext<IListingContext | undefined>(undefined);

interface IListingContextProviderProps {
    /** Optional listing Id which is in the screen path */
    listingId?: string;
}

export const ListingContextProvider = ({ listingId, children }: PropsWithChildren<IListingContextProviderProps>) => {
    const userContext = useContext(UserContext);
    if (!userContext) {
        throw new Error('need UserContext')
    }
    const client = useQueryClient();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [listing, setListing] = useState<IListingInformation | null>(null);

    useEffect(() => {
        if (!listingId) {
            //in the case of brand new creation (reset info)
            setListing(null);
            return;
        }
        if (listingId === listing?.id) {
            //in case the listing id is already the same as the current listing
            return;
        }

        //update the current listing with the listing info from BE
        setIsLoading(true)
        let subscription;
        getListingInfo(listingId).then((data) => {
            setIsLoading(false);
            setListing(data);//done in the onSnapshot too
            //listen to changes to the listing
            subscription = data.ref.onSnapshot((snap) => {
                // console.log('onListing Snap', snap, snap.data())
                if (!snap.exists) {
                    //got deleted
                    setListing(null);
                } else {
                    const changedListing = snap.data() as Listing;
                    setListing(curListing => ({
                        ...curListing,
                        ...changedListing,
                        ref: snap.ref,
                        id: snap.id,
                    }))
                }
            });
        });
        return subscription;
    }, [listing?.id, listingId]);

    const move_ = useCallback((parentToMoveTo?: any, newListingData?: Partial<Listing>) => {
        const userId = userContext?.user?.firebaseUser?.uid ?? '';
        if (!userId) {
            console.warn('cannot create listing if user not logged in');
            setError("un-authorized");
            return Promise.reject("un-authorized");
        }

        if (!listing) {
            console.warn('no listing to move');
            return Promise.reject('not-found')
        }

        if (listing.host?.id !== userId) {
            console.warn('cannot move listing if user is not owner');
            setError("un-authorized");
            return Promise.reject("un-authorized");
        }

        setIsLoading(true);
        return moveListing(listing.ref || listing.id, parentToMoveTo, newListingData)
            .then((doc) => {
                if (doc.id !== listing?.id) {
                    //if the listing has really been moved
                    setListing(curListing => ({ ...curListing, ref: doc, id: doc.id }))
                }
                setIsLoading(false);
                return doc.id;
            });

    }, [listing, userContext?.user?.firebaseUser?.uid])

    const create_ = useCallback(async (listingData: Partial<Listing>) => {
        const userId = userContext?.user?.firebaseUser?.uid ?? '';
        if (!userId) {
            console.warn('cannot create listing if user not logged in');
            setError("un-authorized");
            return Promise.reject("un-authorized");
        }

        setIsLoading(true);

        let newListing: Partial<Omit<IListingInformation, 'id'>> = listingData;

        const duplicateFrom = listingData?.duplicate_from;
        if (!!duplicateFrom) {
            newListing = {
                ...newListing,
                ...await getListingInfo(duplicateFrom)
            }
        }

        newListing = {
            ...newListing,
            status: "pending",
            host: {
                id: userId,
                display_name: userContext.user?.displayName || '',
                avatar_url: userContext.user?.avatarUrl || '',
            },
        }

        try {
            const parentListing = listingData?.breadcrumbs?.[listingData.breadcrumbs?.length - 1]?.ref;
            if (!!listingId) {
                //if listing id already specified move the listing
                if (!!listing?.id) {
                    return move_(parentListing, newListing);
                }
                setIsLoading(false);
                return Promise.resolve(listingId)
            } else {
                return createListing(newListing, parentListing)
                    .then((doc) => {
                        setListing({ ref: doc, id: doc.id, ...newListing });
                        setIsLoading(false);
                        return doc.id;
                    });
            }
        } catch (e) {
            console.warn('could not find parent listing?');
            setError('error-creation');
            setIsLoading(false);
            return Promise.reject(e);
        }
    }, [listing?.id, listingId, move_, userContext.user?.avatarUrl, userContext.user?.displayName, userContext.user?.firebaseUser?.uid]);

    const update_ = useCallback(async (listingData: Partial<Listing>) => {
        const listingReference = listing?.ref;
        if (!listingReference) {
            console.warn('cannot update the listing without ref');
            setError("not-found");
            return Promise.reject();
        }

        const userId = userContext?.user?.firebaseUser?.uid ?? '';
        if (!userId || listing?.host?.id !== userId) {
            console.warn('cannot update listing if user not logged in or not the owner', listing?.host?.id, userId);
            setError("un-authorized");
            return Promise.reject("un-authorized");
        }

        const updatedListing: Partial<Omit<IListingInformation, 'id'>> = {
            ...listingData,
        }

        setIsLoading(true);
        try {
            await updateListing(listingReference, updatedListing)
                .then(() => {
                    client.invalidateQueries(["user", "listings", EUserRole.HOST]);
                    client.invalidateQueries(["user", "listings", EUserRole.COHOST]);
                });
        } catch (e) {
            setError("error-update");
        } finally {
            setIsLoading(false);
        }
    }, [client, listing?.host?.id, listing?.ref, userContext?.user?.firebaseUser?.uid]);


    const delete_ = useCallback(async () => {
        const listingReference = listing?.ref;
        if (!listingReference) {
            console.warn('cannot delete the listing without id');
            setError("not-found");
            return Promise.reject();
        }
        const userId = userContext?.user?.firebaseUser?.uid ?? '';
        if (!userId || listing?.host?.id !== userId) {
            console.warn('cannot delete listing if user not logged in or not the owner');
            return Promise.reject("un-authorized");
        }
        setIsLoading(true);
        try {
            await deleteListing(listingReference);
        } catch (e) {
            setError("error-delete");
        } finally {
            setIsLoading(false);
        }
    }, [listing?.host?.id, listing?.ref, userContext?.user?.firebaseUser?.uid]);

    const isPublishable = () => {
        return !!listing
            && !!listing.title
            && !!listing.short_description
            && !!listing.main_photo
    }

    const publish_ = useCallback(() => {
        return update_({ status: "in-review" });
    }, [update_])

    return (
        <ListingContext.Provider value={{
            listing,
            isLoading,
            error,
            create: create_,
            update: update_,
            delete: delete_,
            publish: publish_,
            isPublishable
        }
        }>
            {children}
        </ListingContext.Provider>
    );
};

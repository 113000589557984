import React from 'react';
import { Avatar, Button, Container, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { ListingBookingSection } from '../ListingBookingSection';
import { ListingBreadcrumbs } from '../ListingBreadcrumbs';
import { IListingBreadcrumbsProps } from '../ListingBreadcrumbs/ListingBreadcrumbs';
import { ListingCalendarView } from '../ListingCalendarView';
import { ListingPictures } from '../ListingPictures';
import { ListingReviewSection } from '../ListingReviewSection';
import { LISTING_NOT_FOUND_URL } from '../../../utilities/assets/assetHelper';
import { IssueStateView } from '../../IssueStateView';
import { ListingSubListingSection } from '../ListingSubListingSection';
import { useTranslation } from 'react-i18next';
import { Edit, FavoriteBorder, FlagOutlined, Share } from '@mui/icons-material';
import { ROUTE_ANCHOR } from '../../../utilities/navigation';
import { PropsWithErrorState, PropsWithLoadingState } from '../../../utilities';

export interface IListingDetailsProps {
    /** The listing title */
    title: string;
    /** The rating of the listing */
    // rating:IRatingProps;
    /** The location of the listing (vague, just city,state,country) */
    location: {
        /** The label to display */
        label: string;
        /** Callback to navigate to the location section */
        onClick: () => void;
    }
    /** Render function to display the picture section */
    picturesSection: () => JSX.Element;
    /** The breadcrumbs info */
    breadcrumbInfo: IListingBreadcrumbsProps;
    /** The short description */
    shortDescription?: string;
    /** The Host of the listing */
    host: { displayName: string, avatarUrl: string };
    /** (Optional) the CoHost of the listing */
    cohost?: { displayName: string, avatarUrl: string };
    /** The description details */
    // description:{title:string,content:string}[];
    description: string;
    /** Render function to display the calendar section */
    calendarSection: () => JSX.Element;
    /** Render function to display the review section */
    reviewsSection: () => JSX.Element;
    /** Render function to display the booking section */
    bookingSection: () => JSX.Element;
    /** (Optional) Render function to display the sub-listing section */
    subListingsSection?: () => JSX.Element;
    /** Render the location section */
    locationSection: () => JSX.Element;
    /** Callback onShare clicked (Optional, only show when feature is done TODO) */
    onShareClick?: () => void;
    /** Callback onSave clicked (for user to save in their wishlist) */
    onSaveClick?: () => void;
    /** Callback onEdit clicked (for host to navigate to listing's settings) */
    onEditClick?: () => void;
    /** Callback for user to report a listing */
    onReportClick?: () => void;
}

export const ListingDetails = ({
    title,
    shortDescription,
    description,
    breadcrumbInfo,
    host,
    cohost,
    location,
    picturesSection,
    reviewsSection,
    calendarSection,
    bookingSection,
    subListingsSection,
    locationSection,
    onShareClick,
    onSaveClick,
    onEditClick,
    onReportClick,
    loading,
    error,
}: PropsWithErrorState<PropsWithLoadingState<IListingDetailsProps>>) => {
    const { t } = useTranslation();
    if (!!error) {
        if (typeof error === 'boolean') {
            return <IssueStateView
                title={t('listing.details.errorTitle')}
                image={LISTING_NOT_FOUND_URL}
                description={t('listing.details.errorContent')}
            />
        }
        return <IssueStateView
            title={t('listing.details.emptyTitle')}
            image={LISTING_NOT_FOUND_URL}
            description={t('listing.details.emptyContent')}
        />
    }
    return (
        <Container>
            <Grid container spacing={1} sx={{ padding: 2 }}>
                <Grid item xs={12}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'} spacing={1}>
                        <Stack direction={'column'} flex={2}>
                            <Typography variant={'h2'} fontWeight={'bold'}>{!loading ? title : <Skeleton width={'50%'} />}</Typography>
                            <Stack direction={'row'}>
                                {/* <Rating /> */}
                                {!loading && !!location.label && <Typography variant={'subtitle1'} onClick={location.onClick}>{location.label}</Typography>}
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} flex={1} flexWrap={'wrap'} justifyContent={'end'} spacing={1}>
                            {!!onShareClick && <Button startIcon={<Share />} onClick={onShareClick} disabled={!!loading}>{t('listing.details.share')}</Button>}
                            {!!onSaveClick && <Button startIcon={<FavoriteBorder />} onClick={onSaveClick} disabled={!!loading}>{t('listing.details.save')}</Button>}
                            {!!onEditClick && <Button startIcon={<Edit />} onClick={onEditClick} disabled={!!loading}>{t('listing.details.edit')}</Button>}
                            {!!onReportClick && <Button startIcon={<FlagOutlined />} onClick={onReportClick} disabled={!!loading}>{t('listing.details.report')}</Button>}
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    {!loading ? picturesSection() : <ListingPictures loading />}
                </Grid>
                <Grid item xs={12} md={8}>
                    <Stack direction={'column'} justifyContent={'start'} alignItems={'stretch'} spacing={1}>
                        {!loading ? <ListingBreadcrumbs {...breadcrumbInfo} /> : <ListingBreadcrumbs loading />}
                        {!!shortDescription && <Typography variant='body2'>{!loading ? shortDescription : <Skeleton />}</Typography>}
                        {!loading &&
                            <Stack direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
                                <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} spacing={1}><Avatar src={host.avatarUrl} alt={host.displayName} /><Typography textAlign={'start'}>{t('listing.details.hostedBy', { name: host.displayName })}</Typography></Stack>
                                {!!cohost && <Stack direction={'row-reverse'} justifyContent={'end'} alignItems={'center'} spacing={1}><Avatar src={cohost.avatarUrl} alt={cohost.displayName} /><Typography textAlign={'end'}>{t('listing.details.cohostedBy', { name: cohost.displayName })}</Typography></Stack>}
                            </Stack>
                        }
                        <Typography variant='h3'>{t('listing.details.descriptionSectionTitle')}</Typography>
                        <Typography variant='body1'>{!loading ? description : <Skeleton />}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Stack direction={'column'} spacing={2}>
                        {!loading ? bookingSection() : <ListingBookingSection loading />}
                        {!loading ? subListingsSection?.() : <ListingSubListingSection loading />}
                    </Stack>
                </Grid>
                <Grid item xs={12} id={ROUTE_ANCHOR.listing.calendar}>
                    {!loading ? calendarSection() : <ListingCalendarView loading />}
                </Grid>
                <Grid item xs={12} id={ROUTE_ANCHOR.listing.reviews}>
                    {!loading ? reviewsSection() : <ListingReviewSection loading />}
                </Grid>
                <Grid item xs={12} id={ROUTE_ANCHOR.listing.location}>
                    {!loading ? locationSection() : <Skeleton width={'100%'} height={150} />}
                </Grid>
            </Grid>
        </Container>
    );
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import i18n (needs to be bundled ;))
import './utilities/i18n/config';

import './fonts/PartyLET/PartyLetPlain.ttf';
import { WEBSITE_CAREER_URL } from './types/constants';

const printBrandConsole = () => {
  //space of top of the brand to not conflict with rest of the console
  const brand =
    `


   __               __     
  /   _ |__| _  _|_/  \\ _  
  \\__(_)|  |(_)_)|_\\__/|_) 
                       |      
  `
  /*
  //fun colors!!
  const brandColored =
    `


%c   %c__               %c__     
  %c/   _ %c|__| _  _|_%c/  \\ _  
  %c\\__(_)%c|  |(_)_)|_%c\\__/|_) 
                       |   `
  const colorHost = `color:${COLOR_HOST}`;
  const colorCohost = `color:${COLOR_COHOST}`;
  const colorGuest = `color:${COLOR_GUEST}`;
  const colorMap = [
    colorCohost,
    colorGuest,
    colorCohost,
    colorHost,
    colorGuest,
    colorCohost,
    colorHost,
    colorGuest
  ];
  console.log(brandColored, '', ...colorMap);

  const backgroundColor = "background:white";
  console.log(brandColored,
    backgroundColor, ...colorMap.map(c => [c, backgroundColor].join(';')));
*/
  const message = `Join us and build a new way of traveling and hosting at ${WEBSITE_CAREER_URL}`;
  console.log([brand, message].join('\n\n'));
}

printBrandConsole();


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { Check, Email, Facebook, Google, LinkedIn, Phone } from "@mui/icons-material";
import { EUserMedal, EUserRole, EUserVerification } from "../types/db";
import { green } from '@mui/material/colors';
import { getColorFrom } from './theme/ColorUtils';

/** The user role representation in the UI */
export enum ERole {
    HOST = "HOST",
    COHOST = "COHOST",
    GUEST = "GUEST",
}

export const getColorForUserRole = (role: ERole): string => {
    return getColorFrom(role);
}

export const getStringKeyForUserRole = (role: ERole): string => {
    switch (role) {
        case ERole.HOST:
            return 'userType.host';
        case ERole.COHOST:
            return 'userType.cohost';
        case ERole.GUEST:
            return 'userType.guest';
    }
}

export const getUIUserRoleFrom = (role: EUserRole): ERole | undefined => {
    switch (role) {
        case EUserRole.HOST:
            return ERole.HOST;
        case EUserRole.COHOST:
            return ERole.COHOST;
        case EUserRole.GUEST:
            return ERole.GUEST;
        default:
            return;
    }
}
export const getDBUserRoleFrom = (role: ERole): EUserRole | null => {
    switch (role) {
        case ERole.HOST:
            return EUserRole.HOST;
        case ERole.COHOST:
            return EUserRole.COHOST;
        case ERole.GUEST:
            return EUserRole.GUEST;
        default:
            return null;
    }
}

export enum ELinkedAccount {
    EMAIL = 1,
    PHONE,
    GOOGLE,
    FACEBOOK,
    LINKEDIN,
    TWITTER,
    OTHER
}

/** Return the display name of the UI Linked Account enum */
export const getLinkedAccountName = (linkedAccount: ELinkedAccount) => {
    switch (linkedAccount) {
        case ELinkedAccount.EMAIL:
            return "Email";
        case ELinkedAccount.PHONE:
            return "Phone";
        case ELinkedAccount.GOOGLE:
            return "Google";
        case ELinkedAccount.FACEBOOK:
            return "Facebook";
        case ELinkedAccount.LINKEDIN:
            return "LinkedIn";
        case ELinkedAccount.OTHER:
        default: return "Other"
    }
}

/** Return a icon representation of the UI Linked Account enum */
export const renderLinkedAccountIcon = (linkedAccount: ELinkedAccount) => {
    const props = {};
    switch (linkedAccount) {
        case ELinkedAccount.EMAIL:
            return <Email {...props} />;
        case ELinkedAccount.PHONE:
            return <Phone {...props} />;
        case ELinkedAccount.GOOGLE:
            return <Google {...props} />;
        case ELinkedAccount.FACEBOOK:
            return <Facebook {...props} />;
        case ELinkedAccount.LINKEDIN:
            return <LinkedIn {...props} />;
        default: return <Check style={{ color: green[500] }} {...props} />
    }
}

/** Transform enum from DB to enum for UI  */
export const getUIVerificationFrom = (verification: EUserVerification | string): ELinkedAccount => {
    switch (verification) {
        case EUserVerification.EMAIL:
            // case EUserVerification.YAHOO:
            return ELinkedAccount.EMAIL;
        case EUserVerification.GOOGLE:
            return ELinkedAccount.GOOGLE;
        // case EUserVerification.FACEBOOK:
        //     return ELinkedAccount.FACEBOOK;
        // case EUserVerification.PHONE:
        //     return ELinkedAccount.PHONE;
        // case EUserVerification.APPLE:
        // case EUserVerification.GITHUB:
        default:
            return ELinkedAccount.OTHER
    }
}

/** EMedal enum for UI views */
export enum EMedal {
    TOP_HOST = "SH",
    ACE_COHOST = "SC",
    SUPER_GUEST = "SG",
}

/** Transform enum from DB to enum for UI */
export const getUIMedalTypeFrom = (medal: EUserMedal): EMedal => {
    switch (medal) {
        case EUserMedal.TOP_HOST:
            return EMedal.TOP_HOST;
        case EUserMedal.ACE_COHOST:
            return EMedal.ACE_COHOST;
        case EUserMedal.SUPER_GUEST:
            return EMedal.SUPER_GUEST;
    }
}


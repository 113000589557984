import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";

export const banner = (backgroundImageUrl: string): SxProps<Theme> => ({
  backgroundImage: `url(${backgroundImageUrl})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  minHeight: '50vh',
  paddingBottom: '10px'
});

export const info: SxProps<Theme> = {
  backgroundColor: 'black',
  opacity: 0.8,
  color: 'white',
  paddingTop: '5vh',
  paddingBottom: '5vh',
  paddingLeft: '50px',
  paddingRight: '50px',
  maxWidth: '300px',
};

export const button: SxProps<Theme> = {
  marginTop: '20px'
}

import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import "firebase/auth";
import { Edit } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";

export type EditInformation = {
  displayName: string;
  avatarUrl: string;
  description: string;
}

interface IEditProfileDialogProps {
  /** Initial information */
  info: EditInformation;
  /** Boolean to know if the dialog is open or not */
  open: boolean;
  /** Callback OnClose with optional EditInformation if save button clicked */
  onClose: (info?: EditInformation) => void;
  onAvatarChange: (file: File) => Promise<string>;
}

export const EditProfileDialog = ({
  info: initInfo,
  open,
  onClose,
  onAvatarChange,
}: IEditProfileDialogProps) => {
  const [info, setInfo] = useState<EditInformation>(initInfo);
  const { open: openDropZone } = useDropzone({
    noDrag: true,
    noDragEventsBubbling: true,
    accept: {
      'image/*': ['.jpeg', '.png']
    },
    maxFiles: 1,
    preventDropOnDocument: true,
    onDrop: (acceptedFiles) => {
      if (!acceptedFiles.length) {
        return;//no file chosen, then do nothing
      }
      const acceptedFile = acceptedFiles[0];//only accept one file

      onAvatarChange(acceptedFile)
        .then(url => {
          setInfo(info => ({
            ...info,
            avatarUrl: url,
          }))
        });

      setInfo(info => ({
        ...info,
        avatarUrl: URL.createObjectURL(acceptedFile),
      }))
    }
  });

  const handleAvatarEdit = () => {
    openDropZone();
  }

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Edit Profile</DialogTitle>
      <DialogContent>
        <Stack direction={'column'}
          justifyContent={'start'}
          alignItems={'center'}
          spacing={2}
          p={2}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={
              <IconButton onClick={handleAvatarEdit}
                color={'primary'}
                size={'large'}
                title={'Edit avatar'}
                type={"button"}
                sx={{ border: 2, borderColor: 'primary' }}>
                <Edit />
              </IconButton>
            }
          >
            <Avatar alt={info.displayName}
              src={info.avatarUrl}
              sx={{ width: '10rem', height: '10rem' }}
              variant={'circular'} />
          </Badge>
          <TextField
            autoFocus
            label="Display Name"
            placeholder="Display name"
            type="text"
            fullWidth
            value={info.displayName}
            onChange={(event) => setInfo(ii => ({ ...ii, displayName: event.target.value }))}
          />
          <TextField
            multiline
            minRows={2}
            maxRows={3}
            label="Description"
            placeholder="Description"
            value={info.description}
            onChange={(event) => setInfo(ii => ({ ...ii, description: event.target.value }))}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onClose(info)} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

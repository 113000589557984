import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { NewsStoryScreen } from "../../components/news/NewsStoryScreen";
import { useNewsNavigation } from "../../hooks/useNewsNavigation";
import { getStoryInfo } from "../../services/news";
import { WEBSITE_NEWS_URL } from "../../types/constants";
import { NEWS_CATEGORY_FIELD, NEWS_TIMESTAMP_UPDATED_AT_FIELD, NEWS_TAGS_FIELD, NEWS_TIMESTAMP_PUBLISHED_AT_FIELD } from "../../types/db";
import { ROUTE_PATH } from "../../utilities/navigation";
import { Facebook, Twitter, LinkedIn, Pinterest, WhatsApp, ContentCopy } from "@mui/icons-material";

export const SHAREABLE_SOCIALS = [
    {
        icon: <Facebook />,
        onClick: (url: string) => window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank'),
    },
    {
        icon: <Twitter />,
        onClick: (url: string) => window.open(`https://twitter.com/intent/tweet?url=${url}&text=Check+out+this+article+on+CoHostOp`, '_blank'),
    },
    {
        icon: <LinkedIn />,
        onClick: (url: string) => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`)
    },
    {
        icon: <Pinterest />,
        onClick: (url: string) => window.open(`https://pinterest.com/pin/create/button/?url=${url}&description=Check+out+this+article+on+CoHostOp`, '_blank'),
    },
    {
        icon: <WhatsApp />,
        // data-action="share/whatsapp/share"
        onClick: (url: string) => window.open(`whatsapp://send?text=Check+out+this+article+on+CoHostOp:+${url}`, '_blank'),
    },
    {
        icon: <ContentCopy />,
        onClick: (url: string) => navigator.clipboard.writeText(url),
    }
]

interface INewsStoryContainerProps extends ReturnType<typeof useNewsNavigation> {
    storyId: string;
}

/** Taking care of fetching the news story data and displaying it */
export const NewsStoryContainer = ({ storyId, navigateToAuthor, navigateToCategory, navigateToStory, navigateToTag }: INewsStoryContainerProps) => {
    const { i18n } = useTranslation();
    //fetch the story
    const { data, isLoading, isError } = useQuery(
        ["story", storyId, i18n.language],
        () => getStoryInfo(storyId, i18n.language)
    );

    if (isLoading || !data) {
        return <NewsStoryScreen loading />
    }

    if (isError) {
        return <NewsStoryScreen error={{ message: "", onRetryClick: () => navigateToStory() }} />
    }

    const handleAuthorClick = () => {
        navigateToAuthor(data.author?.id || 'cohostop')
    }

    const handleCategoryClick = () => {
        navigateToCategory(data[NEWS_CATEGORY_FIELD])
    }

    const handleTagClick = (tag: string) => {
        navigateToTag(tag);
    }

    return <NewsStoryScreen
        title={data.title}
        subtitle={data.subtitle}
        timeline={{
            publishedAt: data[NEWS_TIMESTAMP_PUBLISHED_AT_FIELD]?.toDate() || '',
            updatedAt: data[NEWS_TIMESTAMP_UPDATED_AT_FIELD]?.toDate() || ''
        }}
        image={data.image}
        author={{
            displayName: data.author?.display_name || 'CoHostOp',
            onClick: handleAuthorClick
        }}
        category={{
            label: data[NEWS_CATEGORY_FIELD],
            onClick: handleCategoryClick
        }}
        shareWith={SHAREABLE_SOCIALS.map(social => ({
            icon: social.icon,
            onClick: () => social.onClick(WEBSITE_NEWS_URL + ROUTE_PATH.news.storyBuild(storyId))
        }))}
        tags={(data[NEWS_TAGS_FIELD] || []).map(tag => ({ label: tag, onClick: () => handleTagClick(tag) }))}
        content={data.content || ''}
    />
}

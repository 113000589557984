import React from 'react';
import { Box, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { ListingImage } from '../ListingPictures/ListingImage';
import { PropsWithLoadingState } from '../../../utilities';

export interface IListingSubListingItemProps {
    /** The title of the sub-listing */
    title: string;
    /** The short description of the sub-listing */
    description: string;
    /** The main photo url */
    photoUrl: string;
    /** Callback on item click */
    onClick?: () => void;
}

export const ListingSubListingItem = ({
    title,
    description,
    photoUrl,
    onClick,
    loading
}: PropsWithLoadingState<IListingSubListingItemProps>) => {
    const renderContent = () =>
        <Stack direction={'row'} spacing={2} justifyContent={'start'} alignItems={'stretch'}>
            <Box sx={{ maxWidth: '30%', minHeight: '100%' }}>
                <ListingImage imageUrl={photoUrl} description={!loading ? `${title}:${description}` : undefined} loading={loading} />
            </Box>
            <Stack direction={'column'} spacing={1} justifyContent={'start'} alignItems={'stretch'} flexGrow={1}>
                <Typography>{!loading ? title : <Skeleton />}</Typography>
                <Typography>{!loading ? description : <Skeleton />}</Typography>
            </Stack>
        </Stack>

    if (!onClick) {
        return renderContent();
    }

    return (
        <Paper sx={{ p: 1 }} onClick={onClick}>
            {renderContent()}
        </Paper>
    );
}
import { TFunction } from "i18next";
import React, { useMemo } from "react"
import { LOGO_BLACK, LOGO_COLOR_URL, LOGO_WHITE } from "../../utilities/assets/assetHelper";
import { EVENT_IMAGE_1, EVENT_IMAGE_2, EVENT_IMAGE_3, EVENT_IMAGE_4, EVENT_IMAGE_5, LISTING_IMAGE_1, LISTING_IMAGE_2, LISTING_IMAGE_3, LISTING_IMAGE_4, LISTING_IMAGE_5, SHOP_PRODUCT_IMAGE_1, SHOP_PRODUCT_IMAGE_2, SHOP_PRODUCT_IMAGE_3, SHOP_PRODUCT_IMAGE_4, SHOP_PRODUCT_IMAGE_5 } from "../../utilities/TestingUtils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTE_PATH, ROUTE_QUERY, buildPath } from "../../utilities/navigation";
import { NewsMediaAssetScreen, TAssetCategory, TAssetType, TSortBy } from "../../components/news/NewsMediaAssetScreen";
import { useTranslation } from "react-i18next";

//TODO have the categories in DB?
const MEDIA_ASSET_CATEGORIES
    = (t: TFunction): {
        image: string,
        title: string,
        category: TAssetCategory
    }[] => [
            {
                image: LOGO_COLOR_URL,
                title: t('news.mediaAssets.categoryOption', { context: 'brand' }),
                category: 'brand'
            },
            // {
            //     image: LOGO_BLACK,
            //     title: t('news.mediaAssets.categoryOption', { context: 'hosts' }),
            //     category: 'hosts'
            // },
            {
                image: LISTING_IMAGE_1,
                title: t('news.mediaAssets.categoryOption', { context: 'listings' }),
                category: 'listings'
            },
            // {
            //     image: EVENT_IMAGE_1,
            //     title: t('news.mediaAssets.categoryOption', { context: 'cohosts' }),
            //     category: 'cohosts'
            // },
            // {
            //     image: '',
            //     title: t('news.mediaAssets.categoryOption', { context: 'guests' }),
            //     category: 'guests'
            // },
            {
                image: SHOP_PRODUCT_IMAGE_1,
                title: t('news.mediaAssets.categoryOption', { context: 'product' }),
                category: 'product'
            },
        ];
//TODO asset list coming from database
const MEDIA_ASSETS: {
    title: string,
    image: string,
    filePath: string,
    category: TAssetCategory,
    fileType: TAssetType,
}[] = [
        {
            title: 'Logo color',
            image: LOGO_COLOR_URL,
            filePath: "/img/logo512.png",
            category: 'brand',
            fileType: 'image'
        },
        {
            title: 'Logo Black',
            image: LOGO_BLACK,
            filePath: "/img/logoBW100.png",
            category: 'brand',
            fileType: 'image'
        },
        {
            title: 'Logo White',
            image: LOGO_WHITE,
            filePath: "/img/logoW.png",
            category: 'brand',
            fileType: 'image'
        },
        {
            title: 'Listing 1',
            image: LISTING_IMAGE_1,
            filePath: LISTING_IMAGE_1,
            category: 'listings',
            fileType: 'image'
        },
        {
            title: 'Listing 2',
            image: LISTING_IMAGE_2,
            filePath: LISTING_IMAGE_2,
            category: 'listings',
            fileType: 'image'
        },
        {
            title: 'Listing 3',
            image: LISTING_IMAGE_3,
            filePath: LISTING_IMAGE_3,
            category: 'listings',
            fileType: 'image'
        },
        {
            title: 'Listing 4',
            image: LISTING_IMAGE_4,
            filePath: LISTING_IMAGE_4,
            category: 'listings',
            fileType: 'image'
        },
        {
            title: 'Listing 5',
            image: LISTING_IMAGE_5,
            filePath: LISTING_IMAGE_5,
            category: 'listings',
            fileType: 'image'
        },
        {
            title: 'Event 1',
            image: EVENT_IMAGE_1,
            filePath: EVENT_IMAGE_1,
            category: 'cohosts',
            fileType: 'image'
        },
        {
            title: 'Event 2',
            image: EVENT_IMAGE_2,
            filePath: EVENT_IMAGE_2,
            category: 'cohosts',
            fileType: 'image'
        },
        {
            title: 'Event 3',
            image: EVENT_IMAGE_3,
            filePath: EVENT_IMAGE_3,
            category: 'cohosts',
            fileType: 'image'
        },
        {
            title: 'Event 4',
            image: EVENT_IMAGE_4,
            filePath: EVENT_IMAGE_4,
            category: 'cohosts',
            fileType: 'image'
        },
        {
            title: 'Event 5',
            image: EVENT_IMAGE_5,
            filePath: EVENT_IMAGE_5,
            category: 'cohosts',
            fileType: 'image'
        },
        {
            title: 'Shop Product 1',
            image: SHOP_PRODUCT_IMAGE_1,
            filePath: SHOP_PRODUCT_IMAGE_1,
            category: 'product',
            fileType: 'image'
        },
        {
            title: 'Shop Product 2',
            image: SHOP_PRODUCT_IMAGE_2,
            filePath: SHOP_PRODUCT_IMAGE_2,
            category: 'product',
            fileType: 'image'
        },
        {
            title: 'Shop Product 3',
            image: SHOP_PRODUCT_IMAGE_3,
            filePath: SHOP_PRODUCT_IMAGE_3,
            category: 'product',
            fileType: 'image'
        },
        {
            title: 'Shop Product 4',
            image: SHOP_PRODUCT_IMAGE_4,
            filePath: SHOP_PRODUCT_IMAGE_4,
            category: 'product',
            fileType: 'image'
        },
        {
            title: 'Shop Product 5',
            image: SHOP_PRODUCT_IMAGE_5,
            filePath: SHOP_PRODUCT_IMAGE_5,
            category: 'product',
            fileType: 'image'
        }
    ]

// interface INewsMediaAssetContainerProps {}
/** Takes care of fetching the data for media asset and displaying it*/
export const NewsMediaAssetContainer = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const onSearch = (query: string) => {
        //resetting all filters if query being updated here
        setSearchParams({ [ROUTE_QUERY.search]: query })
    }

    const handleCategoryClick = (category: TAssetCategory) => {
        //adding category to the filter
        setSearchParams((prev) => {
            prev.set(ROUTE_QUERY.category, category)
            return prev;
        });
    }

    const handleSortByChange = (sortBy: TSortBy) => {
        //adding sort by to the filter
        setSearchParams((prev) => {
            prev.set(ROUTE_QUERY.sortBy, sortBy)
            return prev;
        });
    };


    const handleFiltersChange = (filters: TAssetType[]) => {
        //adding file type to the filter
        setSearchParams((prev) => {
            prev.set(ROUTE_QUERY.type, JSON.stringify(filters))
            return prev;
        });
    }

    const search = searchParams.get(ROUTE_QUERY.search) || undefined;
    const category = searchParams.get(ROUTE_QUERY.category) as TAssetCategory;
    const sortBy = searchParams.get(ROUTE_QUERY.sortBy) as TSortBy;
    const filters = JSON.parse(searchParams.get(ROUTE_QUERY.type) || '[]') as TAssetType[]

    const filteredAssets = useMemo(() => {
        let filtered = MEDIA_ASSETS;
        //filtering
        if (!!category)
            filtered = filtered.filter(asset => asset.category === category);
        if (!!search)
            filtered = filtered.filter(asset => asset.title.includes(search));
        if (!!filters.length)
            filtered = filtered.filter(asset => filters.includes(asset.fileType))
        //sorting
        if (!!sortBy && sortBy !== "default")
            filtered = filtered.sort((a, b) => sortBy === "AZ" ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title));
        //return filtered and sorted assets
        return filtered;
    }
        , [category, filters, search, sortBy]);

    return <NewsMediaAssetScreen
        search={search}
        onSearch={onSearch}
        category={category}
        onCategoryClick={handleCategoryClick}
        sortBy={sortBy}
        onSortByClick={handleSortByChange}
        filters={filters}
        onFiltersChange={handleFiltersChange}
        categories={MEDIA_ASSET_CATEGORIES(t)}
        assets={filteredAssets}
        onFooterClick={() => navigate(buildPath(ROUTE_PATH.news.contact))} />
}

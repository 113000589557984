import React from "react";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { TextFieldProps, ButtonProps, Button, Card, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import { EForumTopic, getBackgroundForTopic } from "../../../utilities/forums";
import { useTranslation } from "react-i18next";
import { getColorFrom } from "../../../utilities/theme/ColorUtils";
import { ERole } from "../../../utilities/EnumUtility";

export interface IForumsNewPostScreenProps {
    subjectTextField: TextFieldProps;
    contentTextField: TextFieldProps;
    topicSelection: { value: EForumTopic; onValueChange: (newValue: EForumTopic) => void; };
    cancelButton: ButtonProps;
    postButton: LoadingButtonProps;
}
/** UI component for the forum new post screen */
export const ForumsNewPostScreen = ({ subjectTextField, contentTextField, topicSelection, cancelButton, postButton }: IForumsNewPostScreenProps) => {
    const { t } = useTranslation();
    const handleTopicClick = (topic: EForumTopic) => {
        topicSelection.onValueChange(topic);
    }
    const getTitleForTopic = (topic: EForumTopic) => {
        switch (topic) {
            case EForumTopic.COMMUNITY:
                return t('forum.topic.community');
            case EForumTopic.HOST:
                return t('forum.topic.host');
            case EForumTopic.COHOST:
                return t('forum.topic.cohost');
            case EForumTopic.GUEST:
                return t('forum.topic.guest');
            default:
                //Add localization if topic added
                // return topic?.toString() || '';
                return '';
        }
    }
    const renderTopicCard = (topic: EForumTopic) => {
        const isSelected = topic === topicSelection.value;
        const backgroundColor = getBackgroundForTopic(topic)

        const textColor = topic === EForumTopic.COMMUNITY ? getColorFrom(ERole.GUEST)
            : topic === EForumTopic.HOST ? '#000' :
                topic === EForumTopic.COHOST ? "#FFF" :
                    "#000";
        return (
            <Grid item p={1} xs={12} sm={6} md={3}>
                <Card style={{ background: backgroundColor }}
                    sx={{ p: 4, border: isSelected ? '2px solid #000' : '' }}
                    onClick={() => handleTopicClick(topic)}
                    elevation={isSelected ? 1 : 5}>
                    <Typography sx={{ textDecoration: isSelected ? 'underline' : 'none' }}
                        fontWeight={'bold'}
                        textAlign={'center'}
                        color={textColor}>
                        {getTitleForTopic(topic).toUpperCase()}
                    </Typography>
                </Card>
            </Grid>
        );
    }

    return (
        <Container sx={{ p: 3 }}>
            <Stack direction={'column'} spacing={2}>
                <Typography variant="h1">{t('forum.newPost.title')}</Typography>
                <Typography variant="h2">{t('forum.newPost.subjectTitle')}</Typography>
                <TextField {...subjectTextField} placeholder={t('forum.newPost.subjectPlaceholder')} />
                <TextField {...contentTextField} multiline minRows={3} maxRows={5} />
                <Typography variant="h3">{t('forum.newPost.topicTitle')}</Typography>
                <Grid container>
                    {renderTopicCard(EForumTopic.COMMUNITY)}
                    {renderTopicCard(EForumTopic.HOST)}
                    {renderTopicCard(EForumTopic.COHOST)}
                    {renderTopicCard(EForumTopic.GUEST)}
                </Grid>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Button {...cancelButton} variant="text">{t('forum.newPost.cancelButton')}</Button>
                    <LoadingButton {...postButton} variant="contained">{t('forum.newPost.postButton')}</LoadingButton>
                </Stack>
            </Stack>
        </Container>
    );
}
import React from "react";
import { AccountBox, Handshake, Payments, Security, Settings } from "@mui/icons-material";
import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { ROUTE_SUBPATH } from "../../../../utilities/navigation";
import { AccountSectionItem, IAccountSectionItemProps } from "./AccountSectionItem";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

export interface IAccountPageViewProps {
    /** The user's full name */
    fullName: string;
    /** The user's account email */
    email: string;
    /** Callback click on the view public profile button */
    onViewProfileClick: () => void;
    /** Callback click on one of the section (sectionId as argument) */
    onSectionClick: (section: string) => void;
    /** Callback click on the delete account button (optional for now until TODO build page/feature for it) */
    onRequestDeletionClick?: () => void;
}

export const getTitleFromAccountPage = (t: TFunction, page: string) => {
    switch (page) {
        case ROUTE_SUBPATH.account_perso:
            return t('user.account.persoTitle');
        case ROUTE_SUBPATH.account_login:
            return t('user.account.loginTitle');
        case ROUTE_SUBPATH.account_finance:
            return t('user.account.financeTitle');
        case ROUTE_SUBPATH.account_notifs:
            return t('user.account.notifsTitle');
        case ROUTE_SUBPATH.account_privacy:
            return t('user.account.privacyTitle');
        case ROUTE_SUBPATH.account_prefs:
            return t('user.account.prefsTitle');
        case ROUTE_SUBPATH.account_referrals:
            return t('user.account.referralsTitle');
        default:
            return t('user.account.mainTitle');
    }
}

export const getDescriptionFromAccountPage = (t: TFunction, page: string) => {
    switch (page) {
        case ROUTE_SUBPATH.account_perso:
            return t('user.account.persoContent');
        case ROUTE_SUBPATH.account_login:
            return t('user.account.loginContent');
        case ROUTE_SUBPATH.account_finance:
            return t('user.account.financeContent');
        case ROUTE_SUBPATH.account_notifs:
            return t('user.account.notifsContent');
        case ROUTE_SUBPATH.account_privacy:
            return t('user.account.privacyContent');
        case ROUTE_SUBPATH.account_prefs:
            return t('user.account.prefsContent');
        case ROUTE_SUBPATH.account_referrals:
            return t('user.account.referralsContent');
        default:
            return '';
    }
}

const SECTIONS: (Omit<IAccountSectionItemProps, 'title' | 'description' | 'onClick'> & { id: string })[] = [
    {
        id: ROUTE_SUBPATH.account_perso,
        icon: <AccountBox />,
    },
    {
        id: ROUTE_SUBPATH.account_login,
        icon: <Security />,
    },
    {
        id: ROUTE_SUBPATH.account_finance,
        icon: <Payments />,
    },
    // {
    //     id: ROUTE_SUBPATH.account_notifs,
    //     icon: <NotificationsActive />,
    // },
    // {
    //     id: ROUTE_SUBPATH.account_privacy,
    //     icon: <PrivacyTip />,
    // },
    {
        id: ROUTE_SUBPATH.account_prefs,
        icon: <Settings />,
    },
    {
        id: ROUTE_SUBPATH.account_referrals,
        icon: <Handshake />,
    }
]

export const AccountPageView = ({
    fullName,
    email,
    onViewProfileClick,
    onSectionClick,
    onRequestDeletionClick,
}: IAccountPageViewProps) => {
    const { t } = useTranslation();
    return (
        <Container sx={{ p: 2 }}>
            <Grid container spacing={2} justifyContent={'center'}>
                <Grid item xs={12}>
                    <Stack direction={{ xs: 'column', sm: 'row' }}
                        spacing={1}
                        justifyContent={'space-between'}
                        alignItems={{ xs: 'start', sm: 'end' }}>
                        <Stack direction={'column'} spacing={1}>
                            <Typography variant="h1">{t('user.account.mainTitle')}</Typography>
                            <Typography variant="subtitle1">{t('user.account.mainSubtitle', { name: fullName, email: email })}</Typography>
                        </Stack>
                        <Button variant="contained" onClick={onViewProfileClick}>{t('user.account.showProfileButton')}</Button>
                    </Stack>
                </Grid>
                {SECTIONS.map((section, index) =>
                    <Grid key={`account-section-${index}`} item xs={12} sm={6} md={4} lg={3}>
                        <AccountSectionItem
                            {...section}
                            title={getTitleFromAccountPage(t, section.id)}
                            description={getDescriptionFromAccountPage(t, section.id)}
                            onClick={() => onSectionClick(section.id)}
                        />
                    </Grid>
                )}
                {
                    !!onRequestDeletionClick &&
                    <Grid item xs={12}>
                        <Stack direction={'column'}
                            alignItems={'center'}>
                            <Typography>{t('user.account.deleteTitle')}</Typography>
                            <Button onClick={onRequestDeletionClick}>{t('user.account.deleteContent')}</Button>
                        </Stack>
                    </Grid>
                }
            </Grid>
        </Container >
    )
}

import React from 'react';
import { useParams } from 'react-router';
import { ROUTE_PARAMS } from '../../../../utilities/navigation';
import { ListingPictureList } from '../../../../components/listing/ListingPictureList';
import { ListingPhotosContainer } from '../../../../containers/listings/ListingPhotosContainer';

export const ListingDetailsPhotosPage = () => {
    const { [ROUTE_PARAMS.listingID]: listingId } = useParams();

    if (!listingId) {
        return null;
    }

    return <ListingPhotosContainer listingId={listingId}>
        {
            (data) => {

                return <ListingPictureList
                    loading={data.loading}
                    images={data.images.map(img => ({ url: img.url, description: img.description }))} />
            }
        }
    </ListingPhotosContainer>
}
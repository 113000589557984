import React from 'react';

import { ListingLocationPage } from './ListingLocationPage';
import { ROUTE_PARAMS } from '../../../utilities/navigation';
import { useParams } from 'react-router';
import { TListingCreationStepId } from './utils';
import { ListingPicturesPage } from './ListingPicturesPage';
import { ListingNewPage } from './ListingNewPage';
import { ListingBasicPage } from './ListingBasicPage';
import { ListingCalendarPage } from './ListingCalendarPage';
import { ListingDetailsPage } from './ListingDetailsPage';
import { ListingPricingPage } from './ListingPricingPage';
import { ListingPublishPage } from './ListingPublishPage';
import { ListingTypePage } from './ListingTypePage';


export const NewListingSubPage = () => {
    const { [ROUTE_PARAMS.flowStepID]: currentStepId } = useParams();

    switch (currentStepId as TListingCreationStepId) {
        case 'type':
            return <ListingTypePage />
        case 'location':
            return <ListingLocationPage />
        case 'basic':
            return <ListingBasicPage />
        case 'photos':
            return <ListingPicturesPage />
        case 'details':
            return <ListingDetailsPage />
        case 'pricing':
            return <ListingPricingPage />
        case 'calendar':
            return <ListingCalendarPage />
        case 'publish':
            return <ListingPublishPage />
        case '':
        default:
            return <ListingNewPage />
    }
}




import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Skeleton, Stack, Typography } from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';
import { useTranslation } from 'react-i18next';
import { PropsWithLoadingState } from '../../../utilities';

interface TabProps {
    numberReviews: number;
    renderList?: () => JSX.Element;
}

export interface IListingReviewSectionProps {
    /**
     * The listing reviews tab information from guests
     */
    fromGuest?: TabProps,
    /**
     * The listing reviews tab information from guests
     */
    fromCohost?: TabProps
}

export const ListingReviewSection = ({ fromGuest, fromCohost, loading }: PropsWithLoadingState<IListingReviewSectionProps>) => {
    const { t } = useTranslation();
    const [value, setValue] = useState('1');

    const totalReviews = (fromGuest?.numberReviews ?? 0) + (fromCohost?.numberReviews ?? 0);

    const renderTotalReview = (totalReviews: number) => {
        return (
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <StarRateIcon />
                <Typography>{!loading ?
                    t('listing.review.title', { count: totalReviews })
                    : <Skeleton variant={'text'} width={50} />}</Typography>
            </Stack>
        );
    }

    if (!loading && !totalReviews) {
        //if not review at all
        return (
            <Stack direction={'column'} spacing={2}>
                {renderTotalReview(totalReviews)}
                <Typography>{t('listing.review.empty', { context: 'all' })}</Typography>
            </Stack>
        );
    }

    const handleChange = (_: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            {renderTotalReview(totalReviews)}
            {!loading ?
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange}>
                            <Tab label={t('listing.review.from', { context: 'guest', count: fromGuest?.numberReviews ?? 0 })} value="1" />
                            <Tab label={t('listing.review.from', { context: 'cohost', count: fromCohost?.numberReviews ?? 0 })} value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">{
                        fromGuest?.renderList?.() ??
                        <Typography>{t('listing.review.empty', { context: 'guest' })}</Typography>
                    }</TabPanel>
                    <TabPanel value="2">{
                        fromCohost?.renderList?.() ??
                        <Typography>{t('listing.review.empty', { context: 'cohost' })}</Typography>
                    }</TabPanel>
                </TabContext>
                :
                <Skeleton variant={'rectangular'} width={'100%'} height={100} />
            }
        </Box>
    );
}

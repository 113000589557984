import React, { PropsWithChildren } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { LanguageMenu } from "../LanguageMenu";
import { buildPath, ROUTE_PATH } from "../../utilities/navigation";
import { Facebook, LinkedIn, Twitter } from "@mui/icons-material";
import { BIO_LINK, FACEBOOK_LINK, LINKEDIN_LINK, SUBDOMAIN_CAREERS, SUBDOMAIN_FORUMS, SUBDOMAIN_HELP_CENTER, SUBDOMAIN_NEWS, SUBDOMAIN_SHOP, TWITTER_LINK, WEBSITE_CAREER_URL, WEBSITE_FORUMS_URL, WEBSITE_HELP_CENTER_URL, WEBSITE_NEWS_URL, WEBSITE_SHOP_URL } from "../../types/constants";
import { useTranslation } from "react-i18next";
import { RC_CAREER, RC_FORUM, RC_HELP, RC_MAINTENANCE_MODE, RC_NEWS, RC_SHOP } from "../../types/remote_config";
import { useRemoteConfig } from "../../services/remoteConfig";
import { extractSubdomain } from "../../utilities/navigation/subdomains/utils";

const navLinkStyle = { color: 'inherit', textDecoration: 'inherit' };

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <BasicFooter>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
        <NavLink
          to={buildPath(ROUTE_PATH.policies)}
          style={navLinkStyle}
        >
          {t('footer.privacyPolicy')}
        </NavLink>
        <Divider orientation="vertical" flexItem />
        <NavLink
          to={buildPath(ROUTE_PATH.terms)}
          style={navLinkStyle}
        >
          {t('footer.termAndConditions')}
        </NavLink>
        <Divider orientation="vertical" flexItem />
        <NavLink
          to={buildPath(ROUTE_PATH.about)}
          style={navLinkStyle}
        >
          {t('footer.about')}
        </NavLink>
        <Divider orientation="vertical" flexItem />
        <NavLink
          to={ROUTE_PATH.aboutBuild(ROUTE_PATH.faq)}
          style={navLinkStyle}
        >
          {t('footer.faq')}
        </NavLink>

        <Divider orientation="vertical" flexItem />
        <NavLink
          to={buildPath(ROUTE_PATH.sitemap)}
          style={navLinkStyle}>
          {t('footer.sitemap')}
        </NavLink>
      </Stack>
    </BasicFooter>
  );
};

/** The list of subdomain accessible through the Footer (only the list not the Footer stand-alone) */
const SubDomainsFooterList = () => {
  const { t } = useTranslation();
  const currentSubdomain = extractSubdomain();

  const { [RC_CAREER]: FF_Career, [RC_MAINTENANCE_MODE]: FF_Maintenance, [RC_NEWS]: FF_News, [RC_FORUM]: FF_Forum, [RC_SHOP]: FF_Shop, [RC_HELP]: FF_HelpCenter } = useRemoteConfig();
  //for each: if we are not currently in the subdomain and the functionality is enabled and not in maintenance
  const hasCareerLink = currentSubdomain !== SUBDOMAIN_CAREERS && FF_Career.enabled && !(FF_Maintenance.enabled && FF_Maintenance.subdomainsAffected.includes("CAREER"));
  const hasNewsLink = currentSubdomain !== SUBDOMAIN_NEWS && FF_News.enabled && !(FF_Maintenance.enabled && FF_Maintenance.subdomainsAffected.includes("NEWS"));
  const hasForumLink = currentSubdomain !== SUBDOMAIN_FORUMS && FF_Forum.enabled && !(FF_Maintenance.enabled && FF_Maintenance.subdomainsAffected.includes("FORUM"));
  const hasShopLink = currentSubdomain !== SUBDOMAIN_SHOP && FF_Shop.enabled && !(FF_Maintenance.enabled && FF_Maintenance.subdomainsAffected.includes("SHOP"));
  const hasHelpCenterLink = currentSubdomain !== SUBDOMAIN_HELP_CENTER && FF_HelpCenter.enabled && !(FF_Maintenance.enabled && FF_Maintenance.subdomainsAffected.includes("HELP_CENTER"));

  return <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
    <NavLink
      to={ROUTE_PATH.home}
      style={navLinkStyle}>
      {t('footer.home')}
    </NavLink>
    {
      hasHelpCenterLink &&
      <>
        <Divider orientation="vertical" flexItem />
        <NavLink
          target={"_blank"}
          to={WEBSITE_HELP_CENTER_URL}
          style={navLinkStyle}>
          {t('footer.helpCenter.title')}
        </NavLink>
      </>
    }
    {
      hasForumLink &&
      <>
        <Divider orientation="vertical" flexItem />
        <NavLink
          target={"_blank"}
          to={WEBSITE_FORUMS_URL}
          style={navLinkStyle}>
          {t('footer.forum.title')}
        </NavLink>
      </>
    }
    {
      hasShopLink &&
      <>
        <Divider orientation="vertical" flexItem />
        <NavLink
          target={"_blank"}
          to={WEBSITE_SHOP_URL}
          style={navLinkStyle}>
          {t('footer.shop.title')}
        </NavLink>
      </>
    }
    {
      hasNewsLink &&
      <>
        <Divider orientation="vertical" flexItem />
        <NavLink
          target={"_blank"}
          to={WEBSITE_NEWS_URL}
          style={navLinkStyle}>
          {t('footer.news.title')}
        </NavLink>
      </>
    }
    {
      hasCareerLink &&
      <>
        <Divider orientation="vertical" flexItem />
        <NavLink
          target={"_blank"}
          to={WEBSITE_CAREER_URL}
          style={navLinkStyle}>
          {t('footer.careers.title')}
        </NavLink>
      </>
    }
  </Stack>
}

export const ShopFooterOutlet = () => {
  const { t } = useTranslation();
  return (
    <>
      <NavLink
        target="_blank"
        to={"https://www.printful.com/a/cohostop"}
        style={navLinkStyle}
      >
        {t('footer.shop.affiliate', { shop: "Printful" })}
      </NavLink>
      <Divider orientation="vertical" flexItem />
      <NavLink
        target="_blank"
        to={"https://www.printful.com/policies/terms-of-service"}
        style={navLinkStyle}
      >
        {t('footer.shop.termAndConditions')}
      </NavLink>
      <Divider orientation="vertical" flexItem />
      <NavLink
        target="_blank"
        to={"https://www.printful.com/policies/privacy"}
        style={navLinkStyle}
      >
        {t('footer.shop.privacyPolicy')}
      </NavLink>
      <Divider orientation="vertical" flexItem />
      <NavLink
        target="_blank"
        to={"https://www.printful.com/policies/returns"}
        style={navLinkStyle}
      >
        {t('footer.shop.returnPolicy')}
      </NavLink>
    </>
  )
}

/** Footer for careers pages (simplified footer) */
export const CareersFooter = () => {
  return <BasicFooter />
}

/** Footer for forums pages (simplified footer) */
export const ForumsFooter = () => {
  const { t } = useTranslation();

  return <BasicFooter>
    <Stack direction={'row'} spacing={1}>
      <NavLink
        to={ROUTE_PATH.forums.guideline}
        style={navLinkStyle}
      >
        {t('footer.forum.guideline')}
      </NavLink>
    </Stack>
  </BasicFooter>
}

export const HelpFooter = () => {
  return <BasicFooter />
}

export const NewsFooter = () => {
  return <BasicFooter />
}

/** The basic footer any footer contain */
const BasicFooter = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation();
  return (
    <Stack direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        borderTop: '1px solid lightgray',
        padding: 3,
        backgroundColor: '#f7f7f7',
        textAlign: 'center',
      }}>
      <Typography>{t('footer.allRightReserved', { year: new Date().getFullYear() })}</Typography>
      <Typography>{t('footer.appNameTM')}</Typography>
      <LanguageMenu />
      {children}
      <Divider sx={{ width: '100%', height: 1, margin: 1 }} />
      <SubDomainsFooterList />
      <Stack direction={'row'} spacing={2}>
        {
          SOCIALS.map((social, index) =>
            <IconButton key={`social-${index}`} onClick={social.onClick}>
              {social.icon}
            </IconButton>
          )
        }
      </Stack>
      <Divider sx={{ width: '100%', height: 1, margin: 1 }} />
      <Stack direction={'row'} spacing={1} alignItems={'baseline'}>
        <Outlet />
      </Stack>
    </Stack >
  );
}

/** The list of social media CoHostOp has */
export const SOCIALS = [
  {
    icon: <Typography style={{ color: 'inherit', fontWeight: 'bold' }}>BIO</Typography>,
    onClick: () => window.open(BIO_LINK)
  },
  {
    icon: <Facebook />,
    onClick: () => window.open(FACEBOOK_LINK),
  },
  {
    icon: <LinkedIn />,
    onClick: () => window.open(LINKEDIN_LINK)
  },
  {
    icon: <Twitter />,
    onClick: () => window.open(TWITTER_LINK)
  }
]
import React, { createContext, PropsWithChildren } from "react";
import { useThreadMembers } from "../../hooks/chat/useThreadMembers";


interface IChatChannelContext extends ReturnType<typeof useThreadMembers> {
    /** Set the channel title */
    // setChannelTitle:(title:string)=>void;
    /** Add a member to the channel */
    // addMember: (member: string) => void;
    /** remove a member from the channel */
    // removeMember:(member:string)=>void;
}

/** The chat channel context providing members list and functionality to all children */
export const ChatChannelContext = createContext<IChatChannelContext | undefined>(undefined);

interface IChatThreadContextProviderProps {
    /** The user id of the user consulting the thread (and potentially changing channel info) */
    userId: string;
    /** The channel we are retrieving the thread/messages from */
    channelId: string;
}

export const ChatChannelContextProvider = ({ userId, channelId, children }: PropsWithChildren<IChatThreadContextProviderProps>) => {
    const { members, isLoading, error } = useThreadMembers(channelId);

    return (
        <ChatChannelContext.Provider value={{
            members,
            isLoading,
            error,
            // create: _create,
            // update: update_,
            // delete: delete_,
        }
        }>
            {children}
        </ChatChannelContext.Provider>
    );
};

import { BusinessCenter, LocationCity, LockClock, Money, People } from "@mui/icons-material";
import { Alert, Box, Button, Container, Divider, Paper, Slide, Snackbar, Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import { TFunction } from "i18next";
import React, { PropsWithChildren, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { IssueStateView } from "../../../components/IssueStateView";
import { getCareerDetails } from "../../../services/careers";
import { analytics } from "../../../services/firebase";
import { TDomain } from "../../../types/db";
import { isEmailValid } from "../../../utilities";
import { ROUTE_PARAMS, ROUTE_PATH } from "../../../utilities/navigation";

/** The Career page of a position (TODO for now all taken) */
export const CareerPositionPage = () => {
    const { [ROUTE_PARAMS.jobID]: positionId } = useParams();
    const navigate = useNavigate();

    return <CareerPositionContainer positionId={positionId}
        navigateToPosition={(id) => navigate(ROUTE_PATH.careers.positionBuild(id))} />
}

export const getDomainLabelFrom = (t: TFunction, domain: TDomain | string) => {
    switch (domain) {
        case "it":
            return t('careers.domain.it');
        case "sales-marketing":
            return t('careers.domain.marketing');
        case 'finance-accounting':
            return t('careers.domain.finance');
        // case 'health':
        // return t('careers.domain.health');
        case 'engineering':
            return t('careers.domain.engineering');
        case 'support':
            return t('careers.domain.support');
        // case 'training':
        // return t('careers.domain.training');
        case 'hr':
            return t('careers.domain.hr');
        // case 'manufacturing':
        // return t('careers.domain.manufacturing');
        case "RnD":
            return t('careers.domain.rnd');
        case 'design':
            return t('careers.domain.design');
        case 'legal':
            return t('careers.domain.legal');
        case 'retail-hospitality':
            return t('careers.domain.retail');
        case 'management':
            return t('careers.domain.management');
        case 'consulting-strategy':
            return t('careers.domain.consulting');
        // case 'services':
        // return t('careers.domain.services');
        // case 'administration':
        // return t('careers.domain.administration');
        case 'media-communication':
            return t('careers.domain.media');
        // case 'environment':
        // return t('careers.domain.environment');
        case 'others':
        default:
            return t('careers.domain.other');
    }
}
interface ICareerPositionContainerProps {
    /** The position id to fetch and display */
    positionId?: string;
    /** Callback to navigate to another position or all position if not provided */
    navigateToPosition: (positionId?: string) => void;
}
/** Container which take care of fetching the data of the position job opportunity and display its content */
const CareerPositionContainer = ({ positionId, navigateToPosition }: ICareerPositionContainerProps) => {
    const { t } = useTranslation();
    const { data, isLoading, isError } = useQuery(
        ['career', positionId],
        () => getCareerDetails(positionId || ''),
        {
            enabled: !!positionId
        });

    if (isLoading) {
        return null;
    }

    if (!positionId || isError || !data) {
        return <CareerPositionNotFoundScreen onActionClick={() => navigateToPosition()}>
            <CandidateApplication />
        </CareerPositionNotFoundScreen>
    }

    if (!!data?.is_pending_offer) {
        return <CareerPositionPendingOfferScreen onActionClick={() => navigateToPosition()}>
            <CandidateApplication />
        </CareerPositionPendingOfferScreen>
    }

    //TODO create a screen for position filled with data
    return <CareerPositionScreen
        title={data.title}
        subTitle={getDomainLabelFrom(t, data.domain)}
        description={data.description}
        applicationDeadline={data.application_deadline || ''}
        jobType={data.type.join(',')}
        location={data.location}
        numberPosition={data.number_position?.toString() || ''}
        salary={data.salary_range.join('-')}
        keyResponsibilities={data.key_responsibilities}
        keyKnowledge={data.key_knowledge}
        keySkills={data.key_skills}
        benefits={data.key_benefits}
    >
        <CandidateApplication positionId={positionId} />
    </CareerPositionScreen>
}

type ICareerPositionScreenProps<T> = PropsWithChildren<T> & {
    /** The main action in the screen */
    onActionClick?: () => void;
}

const CareerPositionPendingOfferScreen = ({ children, onActionClick }: ICareerPositionScreenProps<{}>) => {
    const { t } = useTranslation();
    return (
        <>
            <IssueStateView title={t('careers.position.pendingOfferTitle')}
                description={t('careers.position.pendingOfferContent')}
                button={!!onActionClick ? {
                    text: t('careers.position.pendingOfferBtn'),
                    onActionClick,
                } : undefined} />
            {children}
        </>
    )
}

const CareerPositionNotFoundScreen = ({ children, onActionClick }: ICareerPositionScreenProps<{}>) => {
    const { t } = useTranslation();
    return (
        <>
            <IssueStateView title={t('careers.position.notFoundTitle')}
                description={t('careers.position.notFoundContent')}
                button={!!onActionClick ? {
                    text: t('careers.position.notFoundBtn'),
                    onActionClick,
                } : undefined} />
            {children}
        </>
    )
}

interface ICandidateApplicationProps {
    /** The position id the user is applying to, 
     * if not provided it mean the position is not available anymore therefore user will just drop its CV for future opportunities */
    positionId?: string;
}
/** Container which takes care of taking the user information for a position */
const CandidateApplication = ({ positionId }: ICandidateApplicationProps) => {
    const { t } = useTranslation();
    const [contactField, setContactField] =
        useState<Pick<TextFieldProps, 'value' | 'error' | 'helperText'>>({ value: '' });
    const [linkField, setLinkField] =
        useState<Pick<TextFieldProps, 'value' | 'error' | 'helperText'>>({ value: '' });

    const [open, setOpen] = useState(false);

    const handleSendQuestion = () => {
        const email = contactField.value as string;
        const link = linkField.value as string;

        if (!email) {
            return setContactField(c => ({ ...c, error: true, helperText: t('error.requiredFieldEmpty') }))
        }

        if (!link) {
            setLinkField(l => ({ ...l, error: true, helperText: t('error.requiredFieldEmpty') }));
        }

        if (!isEmailValid(email)) {
            return setContactField(c => ({ ...c, error: true, helperText: t('error.invalidEmail') }))
        }

        //send somewhere...
        //ignore questions!! :P
        analytics.logEvent("generate_lead", { page: "career-position", email, link })
        //clear inputs
        setContactField({ value: '' });
        setLinkField({ value: '' });

        return setOpen(true);
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Paper sx={{ mx: 5, my: 3, p: 2 }} elevation={2}>
            <Stack direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                spacing={1}>
                <Typography variant='h5' textAlign={'center'}>{t(positionId ? 'careers.application.title' : 'careers.application.missedTitle')}</Typography>
                <Typography variant='body1' textAlign={'center'}>{t(positionId ? 'careers.application.content' : 'careers.application.missedContent')}</Typography>
                <TextField label={t('careers.application.contactInputLabel')}
                    placeholder={t('careers.application.contactInputPlaceholder')}
                    type={'email'}
                    variant={'outlined'}
                    fullWidth
                    onChange={(e) => setContactField({ value: e.target.value })}
                    {...contactField} />
                <TextField label={t('careers.application.linkInputLabel')}
                    placeholder={t('careers.application.linkInputPlaceholder')}
                    type={'link'}
                    variant={'outlined'}
                    fullWidth
                    onChange={(e) => setLinkField({ value: e.target.value })}
                    {...linkField} />
                <Button variant='contained' onClick={handleSendQuestion}>{t(positionId ? 'careers.application.sendBtn' : 'careers.application.missedSendBtn')}</Button>
            </Stack>
            <Snackbar open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                TransitionComponent={(props) => <Slide {...props} direction="up" />}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {t('careers.application.successSendMessage')}
                </Alert>
            </Snackbar>
        </Paper>
    );
}

/** The display of a job listing */
const CareerPositionScreen = ({
    title,
    subTitle,
    description,
    location,
    salary,
    numberPosition,
    jobType,
    keyResponsibilities,
    keyKnowledge,
    keySkills,
    benefits,
    applicationDeadline,
    travelRequirement,
    children,
}: ICareerPositionScreenProps<{
    title: string,
    subTitle: string,
    description: string,
    location: string,
    salary: string,
    numberPosition: string;
    jobType: string,
    keyResponsibilities?: string[],
    keyKnowledge?: string[];
    keySkills?: string[];
    benefits: string[];
    applicationDeadline: string;
    travelRequirement?: string;

}>) => {
    const { t } = useTranslation();
    const applicationViewRef = useRef<HTMLDivElement>();
    const handleApplyClick = () => {
        //scroll to the application section which is in the children
        applicationViewRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    const renderPoint = (text: string, icon: JSX.Element) => <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} spacing={1}>{icon}<Typography>{text}</Typography></Stack>

    return (
        <Container sx={{ p: 2 }}>
            <Stack direction={'column'} spacing={1}>
                <Typography variant="h2">{title}</Typography>
                <Typography variant="h3">{subTitle}</Typography>
                <Button onClick={handleApplyClick}>Apply</Button>
                <Divider />
                {renderPoint(location, <LocationCity />)}
                {!!numberPosition && renderPoint(numberPosition.toString(), <People />)}
                {renderPoint(jobType, <BusinessCenter />)}
                {renderPoint(salary, <Money />)}
                {!!applicationDeadline && renderPoint(applicationDeadline.toString(), <LockClock />)}
                <Divider />
                <Typography>{description}</Typography>
                {
                    !!keyResponsibilities?.length &&
                    <>
                        <Typography variant="h4">{t('careers.position.keyResponsibilitiesTitle')}</Typography>
                        <ul>
                            {keyResponsibilities.map((k, index) =>
                                <li key={'responsibility-' + index}>{k}</li>
                            )}
                        </ul>
                    </>
                }
                {
                    !!keyKnowledge?.length &&
                    <>
                        <Typography variant="h4">{t('careers.position.keyKnowledgeTitle')}</Typography>
                        <ul>
                            {keyKnowledge.map((k, index) =>
                                <li key={'knowledge-' + index}>{k}</li>
                            )}
                        </ul>
                    </>
                }
                {
                    !!keySkills?.length &&
                    <>
                        <Typography variant="h4">{t('careers.position.keySkillsTitle')}</Typography>
                        <ul>
                            {keySkills.map((k, index) =>
                                <li key={'skill-' + index}>{k}</li>
                            )}
                        </ul>
                    </>
                }
                {
                    !!benefits?.length &&
                    <>
                        <Typography variant="h4">{t('careers.position.keyBenefitsTitle')}</Typography>
                        <ul>
                            {benefits.map((k, index) =>
                                <li key={'benefit-' + index}>{k}</li>
                            )}
                        </ul>
                    </>
                }
                <Divider />
                {
                    !!travelRequirement &&
                    <>
                        <Typography variant="h4">{t('careers.position.travelRequirementsTitle')}</Typography>
                        <Typography>{travelRequirement}</Typography>
                    </>
                }
                <Divider />
                <Box ref={applicationViewRef}>
                    {children}
                </Box>
            </Stack>
        </Container>
    )
}
import React from 'react';
import { redirect, useParams } from 'react-router';
import { ROUTE_PARAMS, ROUTE_PATH, buildPath } from '../../utilities/navigation';
import { useNewsNavigation } from '../../hooks/useNewsNavigation';
import { NewsStoryContainer } from '../../containers/news/NewsStoryContainer';

/** Taking care of displaying a news story */
export const NewsStoryPage = () => {
    const newsNavigation = useNewsNavigation();
    const { [ROUTE_PARAMS.storyID]: storyId } = useParams();

    if (!storyId) {
        //if no story id, redirect to all stories
        redirect(buildPath(ROUTE_PATH.news.stories));
        return null;
    }

    return <NewsStoryContainer {...newsNavigation} storyId={storyId} />;
}


import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

export interface IAccountSectionItemProps {
    /** The image url of the category */
    icon: JSX.Element;
    /** The title of the category */
    title: string;
    /** The short description of the category */
    description: string;
    /** Callback onClick */
    onClick(): void;
}

export const AccountSectionItem = ({
    icon,
    title,
    description,
    onClick,
}: IAccountSectionItemProps) => {
    return (
        <Card sx={{
            height: '100%',//take all the height it can
            borderRadius: 3,
            transition: "transform 0.15s ease-in-out",
            ':hover': { transform: "scale3d(1.03, 1.03, 1)" },
            cursor: 'pointer',
        }} elevation={3} onClick={onClick}>
            <CardContent>
                {icon}
                <Typography fontWeight={'bold'} variant={'h5'}>{title}</Typography>
                <Typography variant={'subtitle2'}>{description}</Typography>
            </CardContent>
        </Card>
    )
};

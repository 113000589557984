import React, { createContext, useEffect, useRef, useState } from 'react';
import { DEFAULT_REMOTE_CONFIG, RemoteConfig } from '../../types/remote_config';
import { remoteConfig } from '../firebase';

export const RemoteConfigContext = createContext<RemoteConfig & { isLoading: boolean }>({ ...DEFAULT_REMOTE_CONFIG, isLoading: true });

const getFormattedRemoteConfig = (rawRemoteConfig?: ReturnType<typeof remoteConfig.getAll>) => {
    if (!rawRemoteConfig) {
        return DEFAULT_REMOTE_CONFIG;
    }
    //format the remote config value into the RemoteConfig type
    let formattedRemoteConfig = {};
    for (const key of Object.keys(rawRemoteConfig)) {
        let value = rawRemoteConfig[key].asString();
        try {
            value = JSON.parse(value);
        } catch (e) {
            //value could be pure string|number|boolean, which make them not a valid JSON
            //->ignoring and going with the original value itself as string
        }

        formattedRemoteConfig = {
            ...formattedRemoteConfig,
            [key]: value
        }
    }
    return {
        ...DEFAULT_REMOTE_CONFIG,
        ...formattedRemoteConfig,
    } as RemoteConfig;
}

export const RemoteConfigProvider: React.FC = ({ children }) => {
    const isActivated = useRef(false);
    const [values, setValues] = useState<RemoteConfig>(
        getFormattedRemoteConfig()
    );

    useEffect(() => {
        if (isActivated.current) return;//if already activated bypass the reactivation

        remoteConfig.fetchAndActivate()
            .then(() => {
                isActivated.current = true;
                //put the new remote config
                setValues(curValues => ({
                    ...curValues,
                    ...getFormattedRemoteConfig(remoteConfig.getAll()),
                } as RemoteConfig));
            });
    }, []);

    return (
        <RemoteConfigContext.Provider value={{ ...values, isLoading: !isActivated.current }} >
            {children}
        </RemoteConfigContext.Provider>
    );
};
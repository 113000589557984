/** The remote config for the support page */
export const RC_SUPPORT = "support";

export type Support = {
    /** Boolean indicating if the support page should be visible */
    enabled: boolean;
    /** The title of the support page */
    title: string;
    /** The subtitle of the support page */
    subtitle: string;
    /** The description of the support page */
    description: string;
    /** The list of thanks on the support page */
    thanks: SupportThanks[];
    /** The list of affiliates/partnership programs on the support page */
    affiliates: SupportThanks[];
}

export type SupportThanks = {
    /** The title of the item in the thanks list */
    title: string;
    /** The short description of the thanks */
    description: string;
    /** (Optional) link to the website/profile of the individual/company */
    link?: string;
    /** The person/logo url of the individual/company */
    image: string;
}

export const DEFAULT_SUPPORT: Support = {
    "enabled": false,
    "title": "Support Us",
    "subtitle": "You can support us by buying products in the shop, referring us to others, offering gift cards to friends and family or simply making a donation.",
    "description": "Any support will be greatly appreciated. As a small team, we are often volunteering our time to help CoHostOp grow. However, some other fees are unavoidable, like legal, server and other service fees (even the ones to process the payment from your generous donations)",
    "thanks": [
        {
            "title": "Carl",
            "description": "Thanks for being our first supporter in this journey!",
            "image": "https://media-exp1.licdn.com/dms/image/C5603AQGR2UWqlK-k1w/profile-displayphoto-shrink_800_800/0/1516262118801?e=1670457600&v=beta&t=THN2qC5HEP17hDB0ShwZLsJFkA0bzDZ0L3kL6OPj-YU",
            "link": "https://www.linkedin.com/in/carl-schmidt-9a33962/"
        },
        {
            "title": "Cléo",
            "description": "Thanks for your support with coding!",
            "image": "https://scontent.fyvr4-1.fna.fbcdn.net/v/t1.18169-9/560213_10200095062752031_40359156_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=cdbe9c&_nc_ohc=bDLJWNRdTlIAX9r377s&_nc_ht=scontent.fyvr4-1.fna&oh=00_AfAQTJPK0ohmCoF7OE-_-riGBKxm1XrQoSaNRE_Ev5A7UA&oe=63B59147"
        },
        {
            "title": "Maude",
            "description": "Thanks for your support in legal and finances!",
            "image": "https://media-exp1.licdn.com/dms/image/C5103AQH42Sep7cE7ig/profile-displayphoto-shrink_800_800/0/1516945101633?e=1670457600&v=beta&t=q2GEiv1_CwORsjsdHZsttqoLCD6SIKpZNqiCUfQvjec",
            "link": "https://www.linkedin.com/in/maudemarquant/"
        },
        {
            "title": "C&C",
            "description": "Thanks for sharing your experiences in the hosting industry and being our first Host on the platform!",
            "image": "https://a0.muscache.com/im/pictures/user/e21bd483-ae64-46d8-b0c9-c8955ea999db.jpg?aki_policy=profile_large",
            "link": "https://www.airbnb.ca/users/show/12310952"
        },
        {
            "title": "React",
            "description": "Thanks for your support, our website is built with this, enjoyed to learn more about it during the process! And still learning!",
            "image": "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
            "link": "https://reactjs.org/"
        },
        {
            "title": "Firebase",
            "description": "Thanks for your support in setting up our scalable database and user authentication!",
            "image": "https://firebase.google.com/static/downloads/brand-guidelines/SVG/logo-built_white.svg",
            "link": "https://firebase.google.com/"
        },
        {
            "title": "Mui",
            "description": "Thanks for your UI library, this really supported us in the implementation of our website!",
            "image": "https://mui.com/static/logo.png",
            "link": "https://mui.com/"
        },
        {
            "title": "Stripe",
            "description": "Thanks for your support in handling payment for our whole business!",
            "image": "https://cdn.brandfolder.io/KGT2DTA4/at/bskj2q8srfqx3cvfqvhk73pc/Stripe_wordmark_-_blurple_small.png",
            "link": "https://stripe.com/"
        },
    ],
    "affiliates": [
        {
            "title": "Printful",
            "description": "Thanks for your support in setting up our own store (swags!) and allowing us to easily add a stream of income by providing diversified products without having to manage the inventory and shipping!",
            "image": "https://static.cdn.printful.com/dist-pf/image-assets/off-center-full-color-black.1de1e822b15b8e74075c8d1fa631d4e3.svg",
            "link": "https://www.printful.com/a/cohostop"
        },
        {
            "title": "Viator",
            "description": "Thanks to our partner Viator for providing a platform with thousands of wonderful experiences.",
            "image": "https://images.squarespace-cdn.com/content/v1/50b4f322e4b00df0eac0e167/1596214747579-DBGL7DKVONR5MYVN7OOU/Viator_logo_034757.png?format=1000w",
            "link": "https://www.viator.com/?pid=P00086291&mcid=42383&medium=link&campaign=support-cohostop",
        },
        {
            "title": "WayAway",
            "description": "We partnered with WayAway to provide travelers of our platform a way to get to their next adventures with the best rates on airline tickets.",
            "image": "https://mma.prnewswire.com/media/1855762/WayAway_larger_logo_black_Logo.jpg?p=facebook",
            "link": "https://tp.media/r?marker=411850.support-cohostop&trs=211027&p=5976&u=https%3A%2F%2Fwayaway.io&campaign_id=200"
        },
        {
            "title": "Booking.com",
            "description": "Could not find the perfect accommodation on CoHostOp? Our partner Booking.com can help you find our next adventure.",
            "image": "https://firebasestorage.googleapis.com/v0/b/co-host-op.appspot.com/o/support%2Faffiliates%2Fbooking_logo.png?alt=media&token=942166f6-4e78-48b5-bb8c-9eb1546878ed",
            "link": "https://www.booking.com/index.html?aid=7984253",
        },
    ]
}
import React, { useState } from "react";
import { Box, Container, FormControlLabel, Grid, MenuItem, Paper, Select, Stack, Switch, Tab, Tabs, Theme, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router";
import { ROUTE_PATH } from "../../../utilities/navigation";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getCareerPosts } from "../../../services/careers";
import { getDomainLabelFrom } from "../CareerPositionPage/CareerPositionPage";

/** The career page with the list of open positions */
export const CareerPositionsPage = () => {
    const navigate = useNavigate();

    return <CareerPositionsContainer
        navigateToPosition={(id) => navigate(ROUTE_PATH.careers.positionBuild(id))} />
}

type FilterInfo = { value: string, label: string, num: number }

/** Fetch the positions and display them */
const CareerPositionsContainer = ({ navigateToPosition }: { navigateToPosition: (jobId: string) => void }) => {
    const { t } = useTranslation();
    const { data, isLoading, isError } = useQuery(['careers'], () => getCareerPosts(), { refetchOnWindowFocus: false });
    const [location, setLocation] = useState<string>("all");
    const [domain, setDomain] = useState<string>("all");

    if (isLoading) {
        return null;//TODO view for loading?
    }

    if (!data || isError) {
        return null;//TODO view for error?
    }

    //TODO having only one reduce returning locations:FilterInfo[],domains:FilterInfo[]
    const locations = data.reduce((prev, cur) => {
        // if (domain !== "all" && cur.domain !== domain) {
        //     //if the domain is specific and the current pos is not in this domain -> skip
        //     return prev;
        // }
        const findIndex = prev.findIndex(p => p.value === cur.location)
        if (findIndex !== -1) {
            //if location already in the array
            return [
                ...prev.slice(0, findIndex),
                {
                    ...prev[findIndex],
                    label: `${prev[findIndex].value} (${prev[findIndex].num + 1})`,
                    num: prev[findIndex].num + 1,
                },
                ...prev.slice(findIndex + 1),
            ]
        } else {
            return [
                ...prev,
                {
                    value: cur.location,
                    label: `${cur.location} (${1})`,
                    num: 1,
                }
            ]
        }
    }, [] as FilterInfo[])

    const domains = data.reduce((prev, cur) => {
        if (location !== "all" && cur.location !== location) {
            //if the location is specific and the current pos is not in this location -> skip
            return prev;
        }
        const findIndex = prev.findIndex(p => p.value === cur.domain)
        if (findIndex !== -1) {
            //if domain already in the array
            return [
                ...prev.slice(0, findIndex),
                {
                    ...prev[findIndex],
                    label: `${getDomainLabelFrom(t, prev[findIndex].value)} (${prev[findIndex].num + 1})`,
                    num: prev[findIndex].num + 1,
                },
                ...prev.slice(findIndex + 1),
            ]
        } else {
            return [
                ...prev,
                {
                    value: cur.domain,
                    label: `${getDomainLabelFrom(t, cur.domain)} (${1})`,
                    num: 1,
                }
            ]
        }
    }, [] as FilterInfo[])


    const positions = data
        .filter(pos =>
            (location === 'all' || pos.location === location)
            && (domain === "all" || pos.domain === domain)
        )
        .map(pos => ({
            title: pos.title,
            description: pos.description,
            domain: getDomainLabelFrom(t, pos.domain),
            location: pos.location,
            onClick: () => navigateToPosition(pos.id)
        }))

    return <CareerPositionsScreen
        positions={positions}
        locations={locations}
        location={location}
        onLocationChange={setLocation}
        domains={domains}
        domain={domain}
        onDomainChange={setDomain}
        totNumberRoles={data.length}
        totNumberLocations={locations.length}
    />
}

interface ICareerPositionScreenProps {
    /** The list of position for the given domain/location */
    positions: IPositionItemProps[];
    /** The list of locations */
    locations: { value: string, label: string }[];
    /** The selected location. @default all */
    location?: string;
    /** Callback on location change/selected */
    onLocationChange: (newLocation: string) => void;
    /** The list of domains */
    domains: { value: string, label: string }[];
    /** The selected domain. @default all */
    domain?: string;
    /** Callback on domain change/selected */
    onDomainChange: (newDomain: string) => void;
    /** The total number of roles available */
    totNumberRoles: number;
    /** The total number of locations roles are available within */
    totNumberLocations: number;
}

const CareerPositionsScreen = ({
    positions,
    locations,
    location = "all",
    onLocationChange,
    domains,
    domain = "all",
    onDomainChange,
    totNumberRoles,
    totNumberLocations }: ICareerPositionScreenProps) => {
    const { t } = useTranslation();
    const [remote, setRemote] = useState<boolean>(true);
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    return (
        <Container sx={{ p: 2 }}>
            <Stack direction={'column'} spacing={2}>
                <Typography variant="h1">{t('careers.positions.title')}</Typography>
                <Typography variant="subtitle1">{t('careers.positions.rolesInLocations', {
                    roles: t('careers.positions.role', { context: 'count', count: totNumberRoles }),
                    locations: t('careers.positions.location', { context: 'count', count: totNumberLocations })
                })}</Typography>
                <Stack direction={'row'} spacing={3}>
                    <Select
                        disabled={remote}
                        value={location}
                        onChange={(e) => onLocationChange(e.target.value)}
                    >
                        <MenuItem value={'all'}>{t('careers.filters.allLocations')}</MenuItem>
                        {
                            locations.map(loc => <MenuItem key={loc.value} value={loc.value}>{loc.label}</MenuItem>)
                        }
                    </Select>
                    <FormControlLabel
                        control={
                            <Switch checked={remote}
                                onChange={(e) => setRemote(e.target.checked)} />
                        }
                        label={t('careers.filters.remoteControlLabel')} />
                </Stack>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        ...(smUp ? {
                            display: 'flex',
                        } : {})
                    }}
                >
                    <Tabs
                        orientation={smUp ? "vertical" : "horizontal"}
                        variant="scrollable"
                        scrollButtons={true}
                        allowScrollButtonsMobile
                        value={domain}
                        onChange={(_, newDomain) => onDomainChange(newDomain)}
                        sx={smUp ? { borderRight: 1, borderColor: 'divider' } : {}}
                    >
                        <Tab value={"all"} label={t('careers.filters.allDepartments')} {...a11yProps(0)} />
                        {domains.map((d, index) =>
                            <Tab value={d.value} label={d.label} {...a11yProps(index + 1)} />
                        )}
                    </Tabs>
                    <Box sx={{ width: '100%' }}>
                        {/* Separation of the padding since not working if on same line */}
                        <Box sx={{ p: 3 }}>
                            <Grid container spacing={1}>
                                {
                                    positions.map((p, index) =>
                                        <Grid item xs={12} key={`career-${index}`}>
                                            <PositionItem {...p} />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Box>
                    </Box>
                </Box >
            </Stack>
            <Typography variant="caption">{t('careers.filters.remoteCaptionNote')}</Typography>
        </Container>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        key: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

interface IPositionItemProps {
    title: string;
    description: string;
    location: string;
    domain: string;
    onClick: () => void;
}

const PositionItem = ({
    title,
    description,
    location,
    domain,
    onClick,
}: IPositionItemProps) => {
    return (
        <Paper sx={{ cursor: 'pointer', p: 2 }}
            onClick={onClick}>
            <Stack direction={'column'}>
                <Typography variant="h4">{title}</Typography>
                <Typography variant="h6">{domain}</Typography>
                <Typography variant="subtitle1">{description}</Typography>
                <Typography variant="subtitle2">{location}</Typography>
            </Stack>
        </Paper>
    )
}
import React from 'react';

import { Divider, ImageList, ImageListItem, ImageListItemBar, Skeleton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PropsWithLoadingState } from '../../../utilities';

export interface IProfileListingSectionProps {
    profileDisplayName: string;
    items: { image: string, title: string }[];
}

export const ProfileListingSection = ({ profileDisplayName, items, loading }: PropsWithLoadingState<IProfileListingSectionProps>) => {
    const { t } = useTranslation();

    if (loading) {
        return (
            <Stack direction={'column'}>
                <Skeleton variant={'text'} />
                <Skeleton variant={'rectangular'} height={50} />
            </Stack>
        )
    }

    if (!items.length) {
        return null;
    }

    return (
        <Stack direction={'column'}>
            <Divider>{t('user.profile.listingsSectionTitle', { name: profileDisplayName })}</Divider>
            <ImageList
                sx={{
                    gridAutoFlow: "column",
                    gridTemplateColumns: "repeat(auto-fit, minmax(160px,1fr)) !important",
                    gridAutoColumns: "minmax(160px, 1fr)"
                }}
            >
                {items.map((item, index) => (
                    <ImageListItem key={`Listing-${index}`}>
                        <img src={item.image} alt={item.title} />
                        <ImageListItemBar
                            title={item.title}
                            position="below" />
                        {/* <ImageListItemBar
                            sx={{
                                background:
                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                            }}
                            title={item.title}
                            position="top"
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'white' }}
                                    aria-label={`star ${item.title}`}
                                >
                                    <StarBorder />
                                </IconButton>
                            }
                            actionPosition="left"
                        /> */}
                    </ImageListItem>
                ))}
            </ImageList>
        </Stack>
    )
}
import React from "react";
import { Paper, Skeleton, Stack, Typography } from "@mui/material";
import { PropsWithLoadingState } from "../../../utilities";


export interface IHelpTopicArticleItemProps {
    /** The title of the article */
    title: string;
    /** The short description of the article */
    description: string
    /** Callback on click on the item */
    onClick: () => void;
}

/** The display of the article under the topic */
export const HelpTopicArticleItem = ({
    title,
    description,
    onClick,
    loading
}: PropsWithLoadingState<IHelpTopicArticleItemProps>) => {
    return (
        <Paper sx={{ p: 1 }} elevation={2}>
            <Stack direction={'column'} onClick={!loading ? onClick : undefined}>
                <Typography fontWeight={'bold'}>{!loading ? title : <Skeleton width={'50%'} />}</Typography>
                <Typography>{!loading ? description : <Skeleton />}</Typography>
            </Stack>
        </Paper>
    )
}

import React from "react";
import { useNavigate } from "react-router-dom";
import { HomeContainer } from "../../containers/HomeContainer/HomeContainer";

export const HomePage = () => {
  const navigate = useNavigate();

  return <HomeContainer navigate={navigate} />

};

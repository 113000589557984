import React from "react";
import { Stack, Divider, Typography, Button, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SetURLSearchParams, useSearchParams } from "react-router-dom";
import { NewsCategoriesSelect } from "../../components/news/NewsCategoriesSelect";
import { ENewsCategories } from "../../types/db";
import { ROUTE_QUERY } from "../../utilities/navigation";

export const extractFilterFromSearchParams = (searchParams: URLSearchParams) => {
    return {
        authorFilter: searchParams.get(ROUTE_QUERY.author),
        categoryFilter: searchParams.get(ROUTE_QUERY.category),
        tagFilter: searchParams.get(ROUTE_QUERY.tag),
        queryFilter: searchParams.get(ROUTE_QUERY.query)
    }
}

const removeFilter = (
    setSearchParams: SetURLSearchParams,
    filter: typeof ROUTE_QUERY.author
        | typeof ROUTE_QUERY.category
        | typeof ROUTE_QUERY.tag
        | typeof ROUTE_QUERY.query) => {
    setSearchParams(prev => {
        prev.delete(filter);
        return prev;
    })
}

const removeAllFilters = (setSearchParams: SetURLSearchParams) => {
    setSearchParams({});
}

const updateFilter = (
    setSearchParams: SetURLSearchParams,
    filter: typeof ROUTE_QUERY.category
        | typeof ROUTE_QUERY.query,
    value: string) => {
    setSearchParams(prev => {
        prev.set(filter, value);
        return prev;
    })
}

/** Extract the filter from the search param and display it */
export const NewsStoriesFilterContainer = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const { authorFilter, categoryFilter, tagFilter, queryFilter } =
        extractFilterFromSearchParams(searchParams);

    // const [queryValue, setQueryValue] = useState(queryFilter || '');

    // useEffect(() => {
    //     setQueryValue(queryFilter || '')
    // }, [queryFilter]);

    return (
        <Stack direction={'column'} spacing={1} justifyContent={'start'} alignItems={'stretch'}>
            <Divider />
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1}>
                <Typography variant='h4'>{t('news.stories.filterTitle')}</Typography>
                {(!!authorFilter || !!categoryFilter || !!tagFilter || !!queryFilter) && //if any filter->show button
                    <Button onClick={() => removeAllFilters(setSearchParams)}>{t('news.stories.filterResetButton')}</Button>}
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'start'} alignItems={'center'} spacing={1}>
                {/* query */}
                {/* TODO commented for now due to the high amount of query to db this generate */}
                {/* <Stack direction={'row'} spacing={1} justifyContent={'start'} alignItems={'center'}>
                    <Typography>Search:</Typography>
                    <TextField
                        value={queryValue}
                        onChange={(event) => setQueryValue(event.target.value)}
                        size={"small"}
                        placeholder={'keyword'}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') updateFilter(setSearchParams, ROUTE_QUERY.query, (event.target as HTMLTextAreaElement).value)
                        }}
                        onBlur={(event) => {
                            updateFilter(setSearchParams, ROUTE_QUERY.query, queryValue)
                        }}
                        InputProps={{
                            endAdornment: <IconButton onClick={() => removeFilter(setSearchParams, ROUTE_QUERY.query)}><Close /></IconButton>
                        }}
                    />
                </Stack> */}
                {/* //only have the option to delete the query if came with it in the path for now */}
                {
                    !!queryFilter &&
                    <Stack direction={'row'} spacing={1} justifyContent={'start'} alignItems={'center'}>
                        <Typography>{t('news.stories.filterSearch')}</Typography>
                        <Chip onDelete={() => removeFilter(setSearchParams, ROUTE_QUERY.query)} label={`"${queryFilter}"`} />
                    </Stack>
                }
                {
                    !!authorFilter &&
                    <Stack direction={'row'} spacing={1} justifyContent={'start'} alignItems={'center'}>
                        <Typography>{t('news.stories.filterAuthor')}</Typography>
                        <Chip onDelete={() => removeFilter(setSearchParams, ROUTE_QUERY.author)} label={authorFilter} />
                    </Stack>
                }
                {
                    !!tagFilter &&
                    <Stack direction={'row'} spacing={1} justifyContent={'start'} alignItems={'center'}>
                        <Typography>{t('news.stories.filterTag')}</Typography>
                        <Chip onDelete={() => removeFilter(setSearchParams, ROUTE_QUERY.tag)} label={tagFilter} />
                    </Stack>
                }
            </Stack>
            <NewsCategoriesSelect
                value={!!categoryFilter ? categoryFilter.toLowerCase() as ENewsCategories : undefined}
                onChange={(value) => { if (!value) { removeFilter(setSearchParams, ROUTE_QUERY.category) } else { updateFilter(setSearchParams, ROUTE_QUERY.category, value) } }}
                hasAllChip={true}
            />
            <Divider />
        </Stack>
    )
}

import React from 'react';
import { Add, AddCircleOutline } from '@mui/icons-material';
import { Button, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { IListingTableProps } from './ListingTable.types';
import { ListingTable } from './ListingTable';
import { useTranslation } from 'react-i18next';
import { PropsWithLoadingState } from '../../../../utilities';

export interface IListingsPageViewProps extends Omit<IListingTableProps, 'level'> {
    /** Callback on Create new listing button */
    onAddListingClick: () => void;
}

export const ListingsPageView = ({ onAddListingClick, ...props }: PropsWithLoadingState<IListingsPageViewProps>) => {
    const { t } = useTranslation();

    if (!props.loading && !props.items.length) {
        return (
            <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={2}
                sx={{ p: 3, backgroundColor: '#F7F7F7', borderRadius: 2 }}>
                <Typography variant='h4' textAlign={'center'}>{t('user.listings.emptyTitle')}</Typography>
                <Typography textAlign={'center'}>{t('user.listings.emptyContent')}</Typography>
                <Button variant='contained' onClick={onAddListingClick}>{t('user.listings.emptyButton')}</Button>
            </Stack>
        )
    }
    return (
        <Stack direction={'column'} justifyContent={'stretch'} alignItems={'stretch'}
            sx={{ p: 2, minHeight: '90vh' }}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography>{props.loading ? <Skeleton width={100} /> : t('user.listings.numListings', { count: props.items.length })}</Typography>
                {props.simplified ?
                    <IconButton title={t('user.listings.addButton')} onClick={onAddListingClick} disabled={!!props.loading}><AddCircleOutline color='primary' /></IconButton>
                    :
                    <Button variant='outlined' startIcon={<Add />} onClick={onAddListingClick} disabled={!!props.loading}>{t('user.listings.addButton')}</Button>
                }
            </Stack>
            {
                !props.loading && !props.simplified && props.items.length > 2 &&
                <Typography>{t('user.listings.filtersTitle')}</Typography>
            }
            <ListingTable {...props} />
        </Stack>
    );
}



import { useQuery } from 'react-query';
import { getListingPhotos } from '../../../services/listing';
import { ListingPhoto } from '../../../types/db/listings';

export interface IListingPicturesContainerProps {
    listingId: string | string[];
    listingRef?: firebase.default.firestore.DocumentReference;
    children?: (data: State) => JSX.Element;
}

interface State {
    loading: boolean;
    images: ListingPhoto[];
    totImages: number;
}

export const ListingPhotosContainer = ({ listingId, listingRef, children }: IListingPicturesContainerProps) => {
    const { isLoading, data } = useQuery(
        ['ListingPhotos', (Array.isArray(listingId) ? listingId.join('/') : listingId)],
        () => getListingPhotos(listingRef ? listingRef : listingId));

    return !!children ?
        children({ loading: isLoading, images: data || [], totImages: data?.length ?? 0 })
        :
        null
        ;
}
import React from 'react';
import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent, timelineItemClasses, timelineOppositeContentClasses } from "@mui/lab";
import { Stack, Grid, Typography, Box } from "@mui/material";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { BANNER_HOME_PAGE } from "../../utilities/assets/assetHelper";
import { Banner } from "../Banner";

interface INewsAboutUsScreenProps {
    /** The list of facts */
    facts: {
        title: string;
        subtitle: any;
    }[];
    /** The founders' information */
    founders: {
        name: string,
        title: string,
        image: string,
    }[];
    /** The history of events */
    history: {
        date: string | Date,
        image?: string,
        title: string,
        subTitle: string,
        icon: React.JSX.Element,
        color?:
        'inherit' | 'grey' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
    }[];
}

/** Component for displaying the About Us section in the News feature/subdomain */
export const NewsAboutUsScreen = ({ facts, founders, history }: INewsAboutUsScreenProps) => {
    const foundersViewRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    return (
        <Stack direction={'column'}>
            <Banner title={t('news.about.title')}
                backgroundImageSrc={BANNER_HOME_PAGE}
                subTitle={t('news.about.description')}
                primaryButton={{
                    text: t('news.about.bannerButton'),
                    onClick: () => foundersViewRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
                } />
            <Grid container p={2} spacing={2} sx={{ backgroundColor: '#0001' }}>
                <Grid item xs={12}>
                    <Stack direction={'column'}>
                        <Typography variant='h2'>{t('news.facts.title')}</Typography>
                        <Typography variant='caption'>{t('news.facts.subTitle')}</Typography>
                    </Stack>
                </Grid>
                {
                    facts.map((fact, index) =>
                        <Grid item xs={12} sm={6} md={4} key={`fact-${index}`}>
                            <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} p={1} bgcolor={'#FFF'} borderRadius={3}>
                                <Typography variant='h3' fontWeight={'bold'}>{fact.title}</Typography>
                                <Typography variant='h5' textAlign={'center'}>{fact.subtitle}</Typography>
                            </Stack>
                        </Grid>
                    )
                }
                <Grid item xs={12}>
                    <Typography variant='caption'>{t('news.facts.footer')}</Typography>
                </Grid>
            </Grid>
            {/* Founders */}
            <Grid container spacing={2} p={2} ref={foundersViewRef} style={{ scrollMarginTop: 50 }}>
                <Grid item xs={12}>
                    <Typography variant='h2' id='founders'>{t('news.founders.title')}</Typography>
                </Grid>
                {
                    founders.map((founder, index) =>
                        <Grid item xs={12} sm={4} key={`founder-${index}`}>
                            <Stack direction={'column'}>
                                <Box
                                    component="img"
                                    sx={{
                                        objectFit: 'cover',
                                        aspectRatio: 1 / 1,
                                        height: { xs: '100vw', sm: '33vw' },
                                        borderRadius: 3,
                                    }}
                                    alt={founder.name}
                                    src={founder.image}
                                />
                                <Typography textAlign={'center'} variant={'h4'} fontWeight={'bold'}>{founder.name}</Typography>
                                <Typography textAlign={'center'}>{founder.title}</Typography>
                            </Stack>
                        </Grid>
                    )
                }
            </Grid>
            {/* History */}
            <Stack p={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1}>
                <Typography variant='h2'>{t('news.history.title')}</Typography>
                {/* <Stack direction={'row'}>
                    <Typography>Select year</Typography>
                    <Select></Select>
                </Stack> */}
            </Stack>
            <Timeline sx={{ display: { xs: 'none', sm: 'block' } }} position={"alternate"}>
                {history.map((event, index) =>
                    <TimelineItem key={`history-event-${index}`}>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align={!(index % 2) ? "right" : "left"}
                        >
                            <Typography color="text.secondary" variant="body2">{event.date}</Typography>
                            <Typography variant="h6" fontWeight={'bold'}>{event.title}</Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                            <TimelineDot color={event.color || "primary"} >
                                {event.icon}
                            </TimelineDot>
                            <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            {!!event.image && <img style={{ maxWidth: '50%' }} src={event.image} alt={event.title} />}
                            <Typography>{event.subTitle}</Typography>
                        </TimelineContent>
                    </TimelineItem>
                )}
            </Timeline>
            <Timeline sx={{
                display: { xs: 'block', sm: 'none' },
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                },
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                },
            }}>
                {history.map((event, index) =>
                    <TimelineItem key={`history-event-${index}`}>
                        <TimelineSeparator>
                            <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                            <TimelineDot color={event.color || "primary"} >
                                {event.icon}
                            </TimelineDot>
                            <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography color="text.secondary" variant="body2">{event.date}</Typography>
                            <Typography variant="h6">{event.title}</Typography>
                            {!!event.image && <img src={event.image} alt={event.title} />}
                            <Typography>{event.subTitle}</Typography>
                        </TimelineContent>
                    </TimelineItem>
                )}
            </Timeline>
        </Stack>
    )
}
import React, { useState } from "react"
import { Button, Stack, TextField, Typography } from "@mui/material"
import { WEBSITE_BASE_URL } from "../../types/constants";
import { isEmailValid } from "../../utilities";
import { LoadingButton } from "@mui/lab";
import { ChoBackgroundScreen } from "../../components/ChoBackgroundScreen";
import { sendSupportTicket } from "../../services/support";
import { useTranslation } from "react-i18next";

export const FeatureNotAvailablePage = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [fieldError, setFieldError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);

    const handleSendEmailOnClick = async () => {
        if (!isEmailValid(email)) {
            setFieldError(true);
            return;
        } else {
            setFieldError(false)
        }
        setLoading(true);
        await sendSupportTicket({
            type: "contact",
            feature: "FeatureNotAvailable",
            navigation_location: Object.keys(window.location).reduce((prev, key) => {
                const value = { ...window.location }[key];
                if (typeof value === 'function' || typeof value === 'undefined' || typeof value === 'object') {
                    return prev;
                }
                return {
                    ...prev,
                    [key]: value,
                }
            }, {}),
            user_sender: email,
            error_message: 'FeatureNotAvailable'
        })
        setLoading(false);
        setSent(true);
    }

    const handleReturnToHomePage = () => {
        window.location.replace(WEBSITE_BASE_URL)
    }

    return <ChoBackgroundScreen>
        <Stack height={'100%'} direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={2}>
            <Typography variant="h1" textAlign={'center'}>{t('featureNotAvailable.header')}</Typography>
            <Typography variant="body1" textAlign={'center'}>{t('featureNotAvailable.content')}</Typography>
            <Typography variant="h3" textAlign={'center'}>{t('featureNotAvailable.subTitle')}</Typography>
            {
                sent ?
                    <Typography textAlign={'center'}>{t('featureNotAvailable.confirmationMessage')}</Typography>
                    :
                    <>
                        <TextField
                            type={'email'}
                            placeholder={t('featureNotAvailable.inputPlaceholder')}
                            label={t('featureNotAvailable.inputLabel')}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            error={fieldError}
                            helperText={fieldError && t('featureNotAvailable.inputError')}
                            size={'small'} />
                        <LoadingButton variant="contained" onClick={handleSendEmailOnClick} loading={loading}>{t('featureNotAvailable.sendButton')}</LoadingButton>
                    </>
            }
            <Button variant="outlined" onClick={handleReturnToHomePage}>{t('featureNotAvailable.cancelButton')}</Button>
        </Stack>
    </ChoBackgroundScreen>
}
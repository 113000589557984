import React from 'react';
import firebase from 'firebase/app';
import { useQuery } from 'react-query';
import { getStories } from '../../services/news';
import { useNewsNavigation } from '../../hooks/useNewsNavigation';
import { ENewsCategories, NEWS_CATEGORY_FIELD, NEWS_TIMESTAMP_PUBLISHED_AT_FIELD } from '../../types/db';
import { mapDataToUI } from './utils';
import { NewsProductReleaseScreen } from '../../components/news/NewsProductReleaseScreen';

interface INewsProductReleaseContainerProps extends ReturnType<typeof useNewsNavigation> { }

/** Take care of fetching the latest product release article and displaying it */
export const NewsProductReleaseContainer = ({ navigateToStory }: INewsProductReleaseContainerProps) => {
    const { data, isLoading } = useQuery(
        ["stories", "products"]
        , () => getStories({
            orderBy: { field: NEWS_TIMESTAMP_PUBLISHED_AT_FIELD, dir: 'desc' },
            where: [
                { fieldPath: NEWS_CATEGORY_FIELD, opStr: '==', value: ENewsCategories.PRODUCT },
                {
                    fieldPath: NEWS_TIMESTAMP_PUBLISHED_AT_FIELD,
                    opStr: '<=',
                    value: firebase.firestore.Timestamp.now(),
                }]
        })
    );

    if (isLoading || !data) {
        return < NewsProductReleaseScreen loading />;
    }

    return <NewsProductReleaseScreen
        topStory={mapDataToUI(data[0], { navigateToStory })}
        otherStories={data.slice(1).map(story => mapDataToUI(story, { navigateToStory }))}
    />
}
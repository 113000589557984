import React from 'react';
import { Route, Routes } from "react-router"
import { NewsFooter } from "../../../components/Footer/Footer";
import { FeatureNotAvailablePage } from '../../../pages/FeatureNotAvailablePage';
import { MaintenanceModePage } from '../../../pages/MaintenanceModePage';
import { ChoNewsAppBar } from '../../../pages/news/ChoNewsAppBar';
import { NewsAboutPage } from '../../../pages/news/NewsAboutPage';
import { NewsContactPage } from '../../../pages/news/NewsContactPage';
import { NewsMainPage } from '../../../pages/news/NewsMainPage';
// import { NewsManageStoryPage } from '../../../pages/news/NewsManageStoryPage';
import { NewsMediaPage } from '../../../pages/news/NewsMediaPage';
import { NewsProductReleasePage } from '../../../pages/news/NewsProductReleasePage';
import { NewsStoriesPage } from '../../../pages/news/NewsStoriesPage';
import { NewsStoryPage } from '../../../pages/news/NewsStoryPage';
import { NotFoundPage } from "../../../pages/NotFoundPage";
import { useRemoteConfig } from '../../../services/remoteConfig';
import { RC_MAINTENANCE_MODE, RC_NEWS } from '../../../types/remote_config';
import { ROUTE_PARAMS, ROUTE_PATH } from "../RouterConfig"

/** The routes for the subdomain "news" */
export const NewsRoutes = () => {
    const { [RC_NEWS]: FF_News, [RC_MAINTENANCE_MODE]: FF_Maintenance } = useRemoteConfig();
    if (!FF_News.enabled) {
        return <FeatureNotAvailablePage />
    }
    if (FF_Maintenance.enabled && FF_Maintenance.subdomainsAffected.includes("NEWS")) {
        return <MaintenanceModePage />
    }

    return (
        <>
            {/* AppBar */}
            <Routes>
                {/* Default App bar */}
                <Route path={"*"} element={<ChoNewsAppBar />} />
            </Routes>
            {/* Content */}
            <Routes>
                <Route path={ROUTE_PATH.home} element={<NewsMainPage />} />
                {/* About us */}
                <Route path={ROUTE_PATH.news.about} element={<NewsAboutPage />} />
                {/* Media asset */}
                <Route path={ROUTE_PATH.news.mediaAsset} element={<NewsMediaPage />} />
                {/* Product releases */}
                <Route path={ROUTE_PATH.news.productRelease} element={<NewsProductReleasePage />} />
                {/* Contact */}
                <Route path={ROUTE_PATH.news.contact} element={<NewsContactPage />} />
                {/** Stories in a certain category/tag/author/search */}
                <Route path={ROUTE_PATH.news.stories}>
                    <Route index element={<NewsStoriesPage />} />
                    {/** Story in the news */}
                    <Route path={`:${ROUTE_PARAMS.storyID}`} element={<NewsStoryPage />} />
                </Route>

                {/* TODO certain user allowed to create new story
                <Route path={ROUTE_PATH.news.new} element={
                    <ProtectedRoute
                        isAllowed={!!userContext?.isUserLoggedIn()
                            && !userContext.isUserAnonymous()
                            && userContext.user?.firebaseUser?.metadata.role === 'journalist'}
                        redirectPath={ROUTE_PATH.auth_login}>
                        <NewsNewStoryPage />
                    </ProtectedRoute>
                } /> */}

                {/* Managing news TODO needs to be under protected route */}
                {/* <Route path={'manage'}> */}
                {/* Creation of a new story in the news */}
                {/* <Route index element={<NewsManageStoryPage />} /> */}
                {/* <Route path={`:${ROUTE_PARAMS.storyID}`} element={<NewsManageStoryPage />} /> */}
                {/* </Route> */}

                {/* Anything else */}
                <Route path={'*'} element={<NotFoundPage />} />
            </Routes>
            {/* Footer */}
            <Routes>
                {/* The footer showing in every other pages */}
                <Route path={"*"} element={<NewsFooter />} />
            </Routes>
        </>
    )
}
import React, { PropsWithChildren, useLayoutEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ThemeProvider, createTheme } from "@mui/material/styles";

import "./services/firebase";//init firebase

import { UserProvider } from "./context/UserInformation";
import { FullRoutes } from "./utilities/navigation";
// import { TestDialogSubPage, TestPage } from "./pages/TestPage";
import { RemoteConfigProvider } from "./services/remoteConfig";

// Create a client
const queryClient = new QueryClient();

const GlobalProviders = ({ children }: PropsWithChildren<{}>) => {
  //Create a theme
  const theme = createTheme({
    typography: {
      h1: {
        fontSize: "4rem",
      },
      h2: {
        fontSize: "3rem",
      },
      h3: {
        fontSize: "2rem",
      },
      h4: {
        fontSize: "1.5rem",
      },
      h5: {
        fontSize: "1rem",
      },
      allVariants: {
        whiteSpace: 'pre-line',//to have the \n in translation transformed into actual returns/new line
      }
    },
  });
  return (
    // Provide the client to the entire app
    <QueryClientProvider client={queryClient}>
      {/* Provider for the firebase remote config */}
      <RemoteConfigProvider>
        {/* current user provider */}
        <UserProvider>
          {/* Custom theme for the whole app */}
          <ThemeProvider theme={theme}>
            {children}
          </ThemeProvider>
        </UserProvider>
      </RemoteConfigProvider>
    </QueryClientProvider>
  )
}

const ScrollUpWrapper: React.FC<any> = ({ children = null }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
}

const App = () => {
  return (
    <GlobalProviders>
      {/* The browser router provider */}
      <BrowserRouter>
        <ScrollUpWrapper>
          <FullRoutes />
        </ScrollUpWrapper>
      </BrowserRouter>
    </GlobalProviders>
  );
}

export default App;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { PropsWithErrorState, PropsWithLoadingState } from '../../../utilities';
import { IssueStateView } from '../../IssueStateView';
import { IShopCartItemProps, ShopCartItem } from '../ShopCartItem';
import { ShopCartCheckoutItem } from '../ShopCartItem/ShopCartItem';
import { ShopPrice } from '../ShopPrice';
import { calculateTotalCartValue } from './ShopCart.utils';

export interface IShopCartProps {
    /** The list of items in the cart */
    items: IShopCartItemProps[];
    /** Callback on checkout button clicked */
    onCheckoutClick: () => void;
    /** (optional) Callback on go back to store button */
    onStoreClick?: () => void;
}

export const ShopCart = ({ items, onCheckoutClick, onStoreClick, loading, error }
    : PropsWithErrorState<PropsWithLoadingState<IShopCartProps>>) => {
    const { t } = useTranslation();
    if (!!error) {
        if (typeof error === 'boolean')
            return (
                <IssueStateView
                    title={t('shop.cart.errorTitle')}
                    image=''
                    description={t('shop.cart.errorContent')}
                    button={onStoreClick ? { onActionClick: onStoreClick, text: t('shop.cart.errorButton') } : undefined}
                />
            )
        return (
            <IssueStateView
                title={t('shop.cart.emptyTitle')}
                image=''
                description={t('shop.cart.emptyContent')}
                button={onStoreClick ? { onActionClick: onStoreClick, text: t('shop.cart.emptyButton') } : undefined} />
        )
    }
    if (!loading && !items.length) {
        //empty state
        return <ShopCart error={{ message: "" }} onStoreClick={onStoreClick} />
    }
    return (
        <Grid container spacing={2} padding={2}>
            <Grid item xs={12} sm={8}>
                <Stack direction={'column'}
                    justifyContent={'start'}
                    alignItems={'stretch'}
                    spacing={2}>
                    <Typography variant='h3'>{t('shop.cart.title')}</Typography>
                    <Divider />
                    {
                        !loading ?
                            items.map((item, index) =>
                                <ShopCartItem key={`cart-item-${index}`} {...item} />
                            )
                            :
                            new Array(3).fill('').map((_, index) =>
                                <ShopCartItem loading key={`cart-loading-item-${index}`} />
                            )
                    }
                </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Stack direction={'column'}
                    justifyContent={'start'}
                    alignItems={'stretch'}
                    spacing={1}>
                    <Button variant={'contained'}
                        onClick={onCheckoutClick}
                        disabled={!!loading}>
                        {t('shop.cart.checkoutButton')}
                    </Button>
                    {
                        !loading ?
                            items.map((item, index) =>
                                <ShopCartCheckoutItem key={`cart-checkout-item-${index}`} {...item} />
                            )
                            :
                            new Array(3).fill('').map((_, index) =>
                                <ShopCartCheckoutItem loading key={`cart-loading-item-${index}`} />
                            )
                    }
                    <Divider />
                    {!loading ?
                        <>
                            <Stack direction={{ xs: 'column', sm: 'row' }}
                                spacing={1}>
                                <Typography>{`Subtotal: `}</Typography>
                                <ShopPrice {...calculateTotalCartValue({ items })} />
                            </Stack>
                            <Typography>{`(${items.reduce((prev, curr) => prev + curr.quantity, 0)} items)`}</Typography>
                        </>
                        :
                        <Skeleton variant='text' />
                    }
                    <Divider />
                    <Typography>{t('shop.cart.totalMessage')}</Typography>
                </Stack>
            </Grid>
        </Grid>
    );
}
import React, { useCallback, useEffect } from "react"
import { AddCircle, Apartment, Cabin, Circle, HolidayVillage, HomeWork, Houseboat, NaturePeople, NightShelter, OtherHouses, Pending, RemoveCircleOutlineOutlined, Roofing, RvHookup, WatchLater } from "@mui/icons-material"
import { Listing, ListingCalendar, ListingCalendarType, ListingGuestsRestriction, ListingGuestsRestrictionType, ListingPricing, ListingTree, ListingType, TListingStatus, WithID, WithRef } from "../../types/db"
import { SxProps, Theme } from "@mui/material"
import { TFunction } from "i18next"
import { DeepPartial } from "../../types/common"
import { DEFAULT_CURRENCY } from "../currency/function"
import { PricingInputFieldName } from "../../components/listing/PricingInput"
import { CalendarInputFieldName } from "../../components/listing/CalendarInput"

/** Return the icon for the type */
export const getListingIconFromType = (type: ListingType, iconStyle?: SxProps<Theme>) => {
    switch (type) {
        case "multi-suite":
            return <Apartment sx={iconStyle} />
        case "multi-room":
            return <OtherHouses sx={iconStyle} />
        case "multi-site":
            return <NaturePeople sx={iconStyle} />
        case "suite":
            return <HomeWork sx={iconStyle} />
        case "private-room":
            return <Roofing sx={iconStyle} />
        case "shared-room":
            return <NightShelter sx={iconStyle} />
        case "site":
            return <RvHookup sx={iconStyle} />
        case "cabin":
            return <Cabin sx={iconStyle} />
        case "boat":
            return <Houseboat sx={iconStyle} />
        case "multi-mix":
        default:
            return <HolidayVillage sx={iconStyle} />
    }
}

/** Return the info for a listing type */
export const getListingInfoFromType = (type: ListingType, t: TFunction, iconStyle?: SxProps<Theme>) => {
    return {
        icon: getListingIconFromType(type, iconStyle),
        title: t('listing.type.title', { context: type }),
        description: t('listing.type.description', { context: type }),
        example: t('listing.type.example', { context: type })
    }
}

/** Return the icon for a listing status */
export const getListingIconFromStatus = (status: TListingStatus) => {
    switch (status) {
        case "pending":
            return <AddCircle color='warning' />
        case "in-review":
            return <Pending color='warning' />
        case "published":
            return <Circle color="success" />
        case "snoozed":
            return <WatchLater color='warning' />
        case "un-listed":
            return <Circle color='error' />
        case "de-activated":
            return <RemoveCircleOutlineOutlined color='error' />
        default:
            return <Circle />
    }
}

/** Return the info for a listing status */
export const getListingInfoFromStatus = (status: TListingStatus, t: TFunction) => {
    return {
        icon: getListingIconFromStatus(status),
        title: t('listing.status.title', { context: status }),
        description: t('listing.status.description', { context: status }),
    }
}

/** generic/common local context listing */
type LocalListing = Partial<WithRef<WithID<DeepPartial<Listing>, string>>>;
/** generic/common type for the setActivePageInfo */
type TSetActivePageInfoFunc = (listing: LocalListing | undefined | ((curListingInfo: LocalListing) => LocalListing), completed?: boolean) => void;

/** Generic function for text info change effect */
const useTextEffect =
    (currentText: string | undefined,
        validatorFunction: (text: string | undefined) => boolean,
        setActivePageInfo: TSetActivePageInfoFunc,
        isEditing: boolean) =>
        useEffect(() => {
            if (!isEditing) return;//not validation if not editing
            if (validatorFunction(currentText)) {
                setActivePageInfo({}, true)
            } else {
                setActivePageInfo({}, false)
            }
        }, [currentText, validatorFunction, setActivePageInfo, isEditing]);

/** Generic function for text on blur */
const useHandleTextOnBlurCallback =
    (validatorFunction: (text: string) => string | undefined,
        setTextError: React.Dispatch<React.SetStateAction<string | undefined>>) =>
        useCallback((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
            const text = event.target.value;
            setTextError(validatorFunction(text));
        }, [setTextError, validatorFunction]);

/** Generic function for text on change */
const useHandleTextOnChangeCallback =
    (fieldName: keyof Listing,
        setActivePageInfo: TSetActivePageInfoFunc,
        validatorFunction: (text: string | undefined) => boolean,
        setTextError: React.Dispatch<React.SetStateAction<string | undefined>>) =>
        useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const newText = event.target.value;
            setActivePageInfo({ [fieldName]: newText });
            //if there was an error and the new text is now valid, reset the error otherwise keep it
            setTextError(error => (!!error && validatorFunction(newText)) ? undefined : error);
        }, [fieldName, setActivePageInfo, setTextError, validatorFunction]);

/** The listing min title length */
export const TITLE_MIN_LENGTH = 3;
/** The listing max title length */
export const TITLE_MAX_LENGTH = 50;

/** Function to indicate if the listing title is valid */
export const isTitleValid = (title?: string) => {
    return !!title
        && title.length >= TITLE_MIN_LENGTH
        && title.length <= TITLE_MAX_LENGTH;
}

/** Common useEffect for the listing title */
export const useTitleEffect =
    (currentTitle: string | undefined, setActivePageInfo: TSetActivePageInfoFunc, isEditing: boolean) =>
        useTextEffect(currentTitle, isTitleValid, setActivePageInfo, isEditing);

/** Common function to handle title listing text field on Blur */
export const useHandleTitleOnBlurCallback =
    (t: TFunction, setTitleError: React.Dispatch<React.SetStateAction<string | undefined>>) =>
        useHandleTextOnBlurCallback(
            (title) => {
                if (title.length < TITLE_MIN_LENGTH) {
                    //if not valid, show error on input field
                    return t('listing.error.titleLength', { length: TITLE_MIN_LENGTH, context: 'short' });
                } else if (title.length > TITLE_MAX_LENGTH) {
                    //if too long
                    return t('listing.error.titleLength', { length: TITLE_MIN_LENGTH, context: 'long' });
                } else {
                    //if valid remove the potential previous error
                    return undefined;
                }
            },
            setTitleError,
        );

/** Common function to handle title listing text field change */
export const useHandleTitleOnChangeCallback =
    (setActivePageInfo: TSetActivePageInfoFunc, setTitleError: React.Dispatch<React.SetStateAction<string | undefined>>) =>
        useHandleTextOnChangeCallback("title", setActivePageInfo, isTitleValid, setTitleError);

/** The listing min short description length */
export const SHORT_DESC_MIN_LENGTH = 5;
/** The listing max short description length */
export const SHORT_DESC_MAX_LENGTH = 250;

/** Function to indicate if the listing short description is valid */
export const isShortDescriptionValid = (desc?: string) => {
    return !!desc
        && desc.length >= SHORT_DESC_MIN_LENGTH
        && desc.length <= SHORT_DESC_MAX_LENGTH;
}

/** Common useEffect for the listing short description */
export const useShortDescriptionEffect =
    (currentText: string | undefined, setActivePageInfo: TSetActivePageInfoFunc, isEditing: boolean) =>
        useTextEffect(currentText, isShortDescriptionValid, setActivePageInfo, isEditing);

/** Common function to handle short description listing text field on Blur */
export const useHandleShortDescriptionOnBlurCallback =
    (t: TFunction, setShortDescriptionError: React.Dispatch<React.SetStateAction<string | undefined>>) =>
        useHandleTextOnBlurCallback(
            (shortDescription) => {
                if (shortDescription.length < SHORT_DESC_MIN_LENGTH) {
                    //if not valid, show error on input field
                    return t('listing.error.descLength', { length: SHORT_DESC_MIN_LENGTH, context: 'short' });
                } else if (shortDescription.length > SHORT_DESC_MAX_LENGTH) {
                    //if too long
                    return t('listing.error.descLength', { length: SHORT_DESC_MAX_LENGTH, context: 'long' });
                } else {
                    //if valid remove the potential previous error
                    return undefined;
                }
            },
            setShortDescriptionError,
        );

/** Common function to handle short description listing text field change */
export const useHandleShortDescriptionOnChangeCallback =
    (setActivePageInfo: TSetActivePageInfoFunc, setShortDescriptionError: React.Dispatch<React.SetStateAction<string | undefined>>) =>
        useHandleTextOnChangeCallback("short_description", setActivePageInfo, isShortDescriptionValid, setShortDescriptionError);


/** The max length of the listing long description */
export const LONG_DESC_MAX_LENGTH = 500;
/** Function to indicated if the long description of listing is valid */
export const isDescriptionValid = (desc?: string) => {
    return !desc || desc.length <= LONG_DESC_MAX_LENGTH;
}

/** Common useEffect for the listing long description */
export const useLongDescriptionEffect =
    (currentText: string | undefined, setActivePageInfo: TSetActivePageInfoFunc, isEditing: boolean) =>
        useTextEffect(currentText, isDescriptionValid, setActivePageInfo, isEditing);

/** Common function to handle long description listing text field on Blur */
export const useHandleLongDescriptionOnBlurCallback =
    (t: TFunction, setDescriptionError: React.Dispatch<React.SetStateAction<string | undefined>>) =>
        useHandleTextOnBlurCallback(
            (description) => {
                // validate the data entered
                if (description.length > LONG_DESC_MAX_LENGTH) {
                    //if too long
                    return t('listing.error.descLength', { length: LONG_DESC_MAX_LENGTH, context: 'long' });
                } else {
                    //if valid remove the potential previous error
                    return undefined;
                }
            },
            setDescriptionError,
        );

/** Common function to handle long description listing text field change */
export const useHandleLongDescriptionOnChangeCallback =
    (setActivePageInfo: TSetActivePageInfoFunc, setDescriptionError: React.Dispatch<React.SetStateAction<string | undefined>>) =>
        useHandleTextOnChangeCallback("description", setActivePageInfo, isDescriptionValid, setDescriptionError);


/** The max length of the listing neighborhood description */
export const NEIGHBORHOOD_DESCRIPTION_MAX_LENGTH = 500;
/** Function to indicated if the neighborhood description of listing is valid */
export const isNeighborhoodDescriptionValid = (desc?: string) => {
    return !desc || desc.length <= NEIGHBORHOOD_DESCRIPTION_MAX_LENGTH;
}

/** Common useEffect for the listing neighborhood description */
export const useNeighborhoodDescriptionEffect =
    (currentText: string | undefined, setActivePageInfo: TSetActivePageInfoFunc, isEditing: boolean) =>
        useTextEffect(currentText, isNeighborhoodDescriptionValid, setActivePageInfo, isEditing);

/** Common function to handle neighborhood description listing text field on Blur */
export const useNeighborhoodDescriptionOnBlurCallback =
    (t: TFunction, setDescriptionError: React.Dispatch<React.SetStateAction<string | undefined>>) =>
        useHandleTextOnBlurCallback(
            (description) => {
                // validate the data entered
                if (description.length > NEIGHBORHOOD_DESCRIPTION_MAX_LENGTH) {
                    //if too long
                    return t('listing.error.descLength', { length: NEIGHBORHOOD_DESCRIPTION_MAX_LENGTH, context: 'long' });
                } else {
                    //if valid remove the potential previous error
                    return undefined;
                }
            },
            setDescriptionError,
        );

/** Common function to handle neighborhood description listing text field change */
export const useHandleNeighborhoodDescriptionOnChangeCallback =
    (setActivePageInfo: TSetActivePageInfoFunc,
        setTextError: React.Dispatch<React.SetStateAction<string | undefined>>) =>
        useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const newText = event.target.value;
            setActivePageInfo(cur => ({
                location: {
                    ...cur.location,
                    neighborhood: newText,
                }
            }));
            //if there was an error and the new text is now valid, reset the error otherwise keep it
            setTextError(error => (!!error && isNeighborhoodDescriptionValid(newText)) ? undefined : error);
        }, [setActivePageInfo, setTextError]);


/** The max length of the listing "getting around" description */
export const GETTING_AROUND_MAX_LENGTH = 500;
/** Function to indicated if the "getting around" description of listing is valid */
export const isGettingAroundDescriptionValid = (desc?: string) => {
    return !desc || desc.length <= GETTING_AROUND_MAX_LENGTH;
}

/** Common useEffect for the listing "getting around" description */
export const useGettingAroundDescriptionEffect =
    (currentText: string | undefined, setActivePageInfo: TSetActivePageInfoFunc, isEditing: boolean) =>
        useTextEffect(currentText, isGettingAroundDescriptionValid, setActivePageInfo, isEditing);

/** Common function to handle "getting around" description listing text field on Blur */
export const useGettingAroundDescriptionOnBlurCallback =
    (t: TFunction, setDescriptionError: React.Dispatch<React.SetStateAction<string | undefined>>) =>
        useHandleTextOnBlurCallback(
            (description) => {
                // validate the data entered
                if (description.length > GETTING_AROUND_MAX_LENGTH) {
                    //if too long
                    return t('listing.error.descLength', { length: GETTING_AROUND_MAX_LENGTH, context: 'long' });
                } else {
                    //if valid remove the potential previous error
                    return undefined;
                }
            },
            setDescriptionError,
        );

/** Common function to handle "getting around" description listing text field change */
export const useHandleGettingAroundDescriptionOnChangeCallback =
    (setActivePageInfo: TSetActivePageInfoFunc,
        setTextError: React.Dispatch<React.SetStateAction<string | undefined>>) =>
        useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const newText = event.target.value;
            setActivePageInfo(cur => ({
                location: {
                    ...cur.location,
                    gettingAround: newText,
                }
            }));
            //if there was an error and the new text is now valid, reset the error otherwise keep it
            setTextError(error => (!!error && isGettingAroundDescriptionValid(newText)) ? undefined : error);
        }, [setActivePageInfo, setTextError]);

/** Function to indicate if the type of listing selected is valid */
export const isListingTypeValid = (parentType?: ListingType, currentType?: ListingType, childrenType?: ListingType[]): boolean => {
    if (!parentType) {
        //if no parent type, should be a parent type itself
        if (!childrenType?.length)
            return !!currentType && (currentType.includes("multi-"))
        else
            return isListingTypeValid(parentType, currentType)
                //if children, those should be compatible too
                && childrenType.every(child => isListingTypeValid(currentType, child))
    }

    if (!childrenType?.length)
        return !!parentType && !!currentType &&
            ListingTree[parentType].includes(currentType);
    else
        return isListingTypeValid(parentType, currentType)
            //if children, those should be compatible too
            && childrenType.every(child => isListingTypeValid(currentType, child))
}

/** Common useEffect for the listing type */
export const useListingTypeEffect =
    (parentType: ListingType | undefined, currentType: ListingType | undefined, childrenType: ListingType[] | undefined, setActivePageInfo: TSetActivePageInfoFunc, isEditing: boolean) =>
        useEffect(() => {
            if (!isEditing) return;//no checking if not editing
            if (isListingTypeValid(parentType, currentType, childrenType)) {
                setActivePageInfo({}, true)
            } else {
                setActivePageInfo({}, false)
            }
        }, [parentType, currentType, childrenType, setActivePageInfo, isEditing]);

/** Common function to handle type listing change */
export const useListingTypeOnChangeCallback
    = (setActivePageInfo: TSetActivePageInfoFunc) =>
        useCallback((newType: ListingType) => {
            setActivePageInfo({ type: newType });
        }, [setActivePageInfo]);

/** Function to indicate if the guests restriction entered is valid */
export const isGuestsRestrictionValid = (guestsRestriction?: Partial<ListingGuestsRestriction>) => {
    return !!guestsRestriction && !!guestsRestriction?.type
        && (guestsRestriction.type !== 'custom' || ((guestsRestriction.max_adults || 0) > 0))
}

/** Common function to handle the guest restriction type (radio button) */
export const useGuestsRestrictionChangeCallback
    = (setActivePageInfo: TSetActivePageInfoFunc) =>
        useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
            const newType = event.target.value as ListingGuestsRestrictionType
            if (newType === 'custom') {
                //set the max adults to 1 at the same time
                setActivePageInfo({ guests_restriction: { type: newType, max_adults: 1, max_children: 0, max_infants: 0, max_pets: 0 } })
            } else {
                //set only the type and reset the rest
                setActivePageInfo({ guests_restriction: { type: newType, max_adults: undefined, max_children: undefined, max_infants: undefined, max_pets: undefined } })
            }
        }, [setActivePageInfo])

/** Common function to handle the guest restriction amount */
export const useGuestsChangeCallback
    = (setActivePageInfo: TSetActivePageInfoFunc) =>
        useCallback((fieldName: keyof Omit<ListingGuestsRestriction, 'type'>, value: number) => {
            setActivePageInfo((listing) => ({ ...listing, guests_restriction: { ...listing.guests_restriction, type: 'custom', [fieldName]: value } }))
        }, [setActivePageInfo])


/** The minimum price for a listing */
export const MIN_PRICE = 0;
/** The minimum discount for a listing price */
export const MIN_DISCOUNT_PERCENTAGE = 0;
/** The maximum discount for a listing price */
export const MAX_DISCOUNT_PERCENTAGE = 100;

export const isDiscountValid = (discount?: any, strictMin?: boolean) => {
    if (discount === undefined
        || Number.isNaN(discount)) {
        return false;
    }
    return !!strictMin ? Number(discount) > MIN_DISCOUNT_PERCENTAGE : Number(discount) >= MIN_DISCOUNT_PERCENTAGE
        && Number(discount) <= MAX_DISCOUNT_PERCENTAGE;
}

export const isPriceValid = (price?: DeepPartial<ListingPricing>) => {
    if (!price || !price.nightly_price?.amount || Number.isNaN(price.nightly_price.amount)) {
        return false;
    }
    return Number(price.nightly_price.amount) > MIN_PRICE //the amount is valid
        && !!price.nightly_price.currency //the currency is provided
        && (!price.weekly_discount || isDiscountValid(price.weekly_discount)) //weekly discount not provided or if provided amount valid
        && (!price.monthly_discount || isDiscountValid(price.monthly_discount)) //monthly discount not provided or if provided amount valid
}

/** Utils function which take a price and period and calculate the total amount with discounts applied */
export const getPriceAmount = (
    price: DeepPartial<Pick<ListingPricing, 'nightly_price' | 'weekly_discount' | 'monthly_discount'>>,
    period: 'daily' | 'weekly' | 'monthly' | number,
    extraDiscount?: number): number => {
    if (!!extraDiscount) {
        return getPriceAmount(price, period) * (1 - extraDiscount / 100)
    }
    if (typeof period === 'number') {
        if (period >= 28) {
            return getPriceAmount(price, 'daily') * period * (1 - (price.monthly_discount || 0) / 100);
        }
        if (period >= 7) {
            return getPriceAmount(price, 'daily') * period * (1 - (price.weekly_discount || 0) / 100);
        }
        return getPriceAmount(price, 'daily') * period;
    }
    switch (period) {
        case 'daily':
            return price.nightly_price?.amount || 0;
        case 'weekly':
            return getPriceAmount(price, 7)
        case 'monthly':
            return getPriceAmount(price, 28)
    }
}

/** This function just check if the discount for cohost is valid (ie: price not more expensive than what a guest would pay for) */
export const isCohostPriceValid = (guestPricing: DeepPartial<ListingPricing> | undefined, cohostPricing: DeepPartial<ListingPricing> | number | undefined) => {
    //if no guest pricing, cannot check against, checking cohost price validity
    if (!guestPricing?.nightly_price && typeof cohostPricing !== 'number') {
        return isPriceValid(cohostPricing)
    }
    // if it's a number, this means that it's a discount of the guest price
    if (typeof cohostPricing === 'number') {
        return isDiscountValid(cohostPricing, true);
    }

    return !!guestPricing?.nightly_price?.amount //exists
        && getPriceAmount(cohostPricing || {}, 'daily') < getPriceAmount(guestPricing, 'daily') //nightly price inferior
        && getPriceAmount(cohostPricing || {}, 'weekly') < getPriceAmount(guestPricing, 'weekly')//weekly price inferior
        && getPriceAmount(cohostPricing || {}, 'monthly') < getPriceAmount(guestPricing, 'monthly')//monthly price
}

/** Function which return the pricing for CoHost, can be undefined if no cohostPerk */
export const getPriceForCoHost = (guestPricing: DeepPartial<ListingPricing> | undefined, cohostPerk: DeepPartial<Listing['cohost_perk']>): Omit<ListingPricing, 'type'> | undefined => {
    //if no guest pricing, cannot calculate against it, so returning potential cohost pricing
    if (!guestPricing?.nightly_price && typeof cohostPerk?.pricing !== 'number') {
        if (!cohostPerk?.pricing?.nightly_price) return;
        return {
            ...cohostPerk?.pricing,
            nightly_price: {
                amount: cohostPerk.pricing.nightly_price.amount || 0,
                currency: cohostPerk.pricing.nightly_price.currency || DEFAULT_CURRENCY,
            }

        };
    }

    // if it's a number, this means that it's a discount of the guest price
    if (typeof cohostPerk?.pricing === 'number') {
        if (!guestPricing?.nightly_price?.amount) return;
        return {
            ...guestPricing,
            nightly_price: {
                currency: guestPricing.nightly_price.currency || DEFAULT_CURRENCY,
                amount: guestPricing.nightly_price.amount * cohostPerk.pricing / 100,
            }
        }
    }
}

/** Function which check if 2 pricing are equal (deep equal) */
export const isSamePricing = (pricing1?: DeepPartial<ListingPricing>, pricing2?: DeepPartial<ListingPricing>) => {
    return !!pricing1 && !!pricing2 //if something to compare
        && pricing1.weekly_discount === pricing2.weekly_discount//if weekly discount the same
        && pricing1.monthly_discount === pricing2.monthly_discount//if monthly discount the same
        && pricing1.nightly_price?.currency === pricing2.nightly_price?.currency //if currency the same
        && pricing1.nightly_price?.amount === pricing2.nightly_price?.amount //if price amount the same
}

type TPricingStateFunc = React.Dispatch<React.SetStateAction<{
    nightlyPrice?: string | undefined;
    weeklyDiscount?: string | undefined;
    monthlyDiscount?: string | undefined;
    discount?: string | undefined;
}>>
/** Generic handling pricing on blur (for both guest and cohost) */
export const usePricingOnBlurCallback =
    (t: TFunction, stateFn: TPricingStateFunc) =>
        useCallback((fieldName: PricingInputFieldName | 'discount', text: String) => {
            if (fieldName === 'weeklyDiscount' || fieldName === 'monthlyDiscount' || fieldName === 'discount') {
                //check discount entry
                if ((+text) < MIN_DISCOUNT_PERCENTAGE) {
                    stateFn((errors: any) => ({ ...errors, [fieldName]: t('listing.error.discountAmount', { context: 'low', amount: MIN_DISCOUNT_PERCENTAGE }) }))
                } else if ((+text) > MAX_DISCOUNT_PERCENTAGE) {
                    stateFn((errors: any) => ({ ...errors, [fieldName]: t('listing.error.discountAmount', { context: 'high', amount: MAX_DISCOUNT_PERCENTAGE }) }))
                } else {
                    //reset the error
                    stateFn((errors: any) => ({ ...errors, [fieldName]: undefined }))
                }
            } else {
                //check price entry
                if ((+text) < MIN_PRICE) {
                    stateFn((errors: any) => ({ ...errors, [fieldName]: t('listing.error.priceAmount', { context: 'low', amount: MIN_PRICE }) }))
                } else {
                    //reset the error
                    stateFn((errors: any) => ({ ...errors, [fieldName]: undefined }))
                }
            }
        }, [stateFn, t]);

const dbNameFromPricingField = (fieldName: PricingInputFieldName): keyof Omit<ListingPricing, 'type'> => {
    switch (fieldName) {
        case 'nightlyPrice': return 'nightly_price';
        case 'weeklyDiscount': return 'weekly_discount';
        case 'monthlyDiscount': return 'monthly_discount';
    }
}
/** Common handling on guest pricing change */
export const useGuestPricingChangeCallback =
    (setActivePageInfo: TSetActivePageInfoFunc, stateFn: TPricingStateFunc) =>
        useCallback((fieldName: PricingInputFieldName, text: string, currency?: string) => {
            const field = dbNameFromPricingField(fieldName);

            setActivePageInfo((listing) => {
                let changed: Partial<ListingPricing> =
                    field === 'monthly_discount' || field === 'weekly_discount' ?
                        {
                            [field]: +text,
                        } : {
                            [field]: {
                                amount: +text,
                                currency: listing.pricing?.nightly_price?.currency || currency || DEFAULT_CURRENCY,
                            }
                        };

                //if there was an error and the new text is now valid, reset the error otherwise keep it
                const isFieldValid = fieldName === 'monthlyDiscount' || fieldName === 'weeklyDiscount' ?
                    isDiscountValid(changed[field]) : isPriceValid(changed);
                stateFn(errors => (!!errors[fieldName] && isFieldValid) ? { ...errors, [fieldName]: undefined } : errors);

                //return the new active page info
                return {
                    ...listing,
                    pricing: {
                        ...listing.pricing,
                        ...changed,
                    }
                }
            });
        }, [setActivePageInfo, stateFn]);

/** Common handling of the cohost availability to book the listing */
export const useCohostOpenListingCheckboxCallback =
    (setActivePageInfo: TSetActivePageInfoFunc) =>
        useCallback((checked: boolean) => {
            setActivePageInfo((listing) => ({
                ...listing,
                cohost_perk: checked ? {
                    open: true,//mark as open
                    calendar: listing.calendar,//set cohost calendar to guest calendar (if exist)
                } : {
                    open: false,//mark as close to cohost (guest only listing)
                },
            }))
        }, [setActivePageInfo]);

type CoHostPerkChoice = "none" | "price_discount" | "custom_price";
/** Common handling of the cohost pricing perk type */
export const useCohostPricingPerkTypeChoiceCallback =
    (setActivePageInfo: TSetActivePageInfoFunc) =>
        useCallback((newChoice: CoHostPerkChoice) => {
            switch (newChoice) {
                case 'none': {
                    //set pricing to the guest pricing
                    return setActivePageInfo((listing) => ({
                        ...listing,
                        cohost_perk: {
                            ...listing.cohost_perk,
                            pricing: listing.pricing,
                        }
                    }))
                }
                case 'price_discount': {
                    //set the cohost pricing as a discount of the guest price (start at 0, rest will be set by the input)
                    return setActivePageInfo((listing) => ({
                        ...listing,
                        cohost_perk: {
                            ...listing.cohost_perk,
                            pricing: 0,
                        }
                    }))
                }
                case 'custom_price': {
                    //set the cohost pricing as a discount of the guest price (start at {}, rest will be set by the input)
                    return setActivePageInfo((listing) => ({
                        ...listing,
                        cohost_perk: {
                            ...listing.cohost_perk,
                            pricing: {},
                        }
                    }))
                }
            }
        }, [setActivePageInfo])

/** Common handling on cohost pricing change */
export const useCohostPricingChangeCallback =
    (setActivePageInfo: TSetActivePageInfoFunc, stateFn: TPricingStateFunc) =>
        useCallback((fieldName: PricingInputFieldName | 'discount', text: string, currency?: string) => {
            if (fieldName === 'discount') {
                setActivePageInfo((listing) => ({
                    ...listing,
                    cohost_perk: {
                        ...listing.cohost_perk,
                        pricing: +text,
                    }
                }));
                //if there was an error and the new text is now valid, reset the error otherwise keep it
                stateFn(errors => (!!errors[fieldName] && isDiscountValid(text, true)) ? { ...errors, [fieldName]: undefined } : errors);
            } else {
                const field = dbNameFromPricingField(fieldName);

                setActivePageInfo((listing) => {
                    let changed: Partial<ListingPricing> =
                        field === 'monthly_discount' || field === 'weekly_discount' ?
                            {
                                [field]: +text,
                            } : {
                                [field]: {
                                    amount: +text,
                                    currency: listing.pricing?.nightly_price?.currency || currency || DEFAULT_CURRENCY,
                                }
                            };

                    //if there was an error and the new text is now valid, reset the error otherwise keep it
                    const isFieldValid = fieldName === 'monthlyDiscount' || fieldName === 'weeklyDiscount' ?
                        isDiscountValid(changed[field]) : isPriceValid(changed);
                    stateFn(errors => (!!errors[fieldName] && isFieldValid) ? { ...errors, [fieldName]: undefined } : errors);

                    //return the active page listing info
                    return {
                        ...listing,
                        cohost_perk: {
                            ...listing.cohost_perk,
                            pricing: {
                                ...((typeof listing.cohost_perk?.pricing !== 'number') ? listing.cohost_perk?.pricing : {}),
                                ...changed,
                            }
                        }
                    }
                });

            }
        }, [setActivePageInfo, stateFn]);

/** Common function on the currency change for the pricing */
export const useCurrencyChangeCallback =
    (setActivePageInfo: TSetActivePageInfoFunc) =>
        useCallback((newCurrency: string) => {
            //update the currency for the guest pricing as well as the cohost pricing (if exist)
            setActivePageInfo((listing) => {
                const currency = listing?.pricing?.nightly_price?.currency
                if (!newCurrency || newCurrency === currency) {
                    //if the currency is already set or hasn't changed -> nothing to change
                    return listing;
                }
                const { pricing, cohost_perk } = listing;
                return {
                    ...listing,
                    pricing: {
                        ...pricing,
                        nightly_price: {
                            ...pricing?.nightly_price,
                            currency: newCurrency
                        }
                    },
                    ...(!cohost_perk || typeof cohost_perk.pricing === 'number' ?
                        {}//change nothing there since it's a discount of the guest's pricing
                        : {
                            cohost_perk: {
                                ...cohost_perk,
                                pricing: {
                                    ...cohost_perk?.pricing,
                                    nightly_price: {
                                        ...cohost_perk?.pricing?.nightly_price,
                                        currency: newCurrency
                                    }
                                }
                            }
                        })
                }
            }
            );
        }, [setActivePageInfo]);

/** The minimum of night that can be entered in listing calendar setting */
export const MIN_NIGHT = 1;
/** The maximum of night that can be entered in listing calendar setting (for now no max) */
export const MAX_NIGHT = Number.MAX_VALUE;

/**
 * Check if the calendar is valid
 * @param info The calendar to check
 * @param ignoreType (optional) if we want to bypass type check (for cohost calendar)
 * @returns true if valid, false otherwise
 */
export const isValidCalendar = (info?: DeepPartial<ListingCalendar>, ignoreType?: boolean) => {
    return !!info && (ignoreType || !!info.type) //a type is indicated
        && (!!info.min_night && info.min_night >= MIN_NIGHT) //the min night is indicated and above the MIN_NIGHT
        && (!info.max_night || info.max_night >= info.min_night); //the max night is not provided (inf) or it's indicated and above the min night
}

/** Function which check if 2 calendar settings are equal */
export const isSameCalendar = (
    guest: Partial<Pick<ListingCalendar, 'min_night' | 'max_night'>>,
    cohost: Partial<Pick<ListingCalendar, 'min_night' | 'max_night'>>) => {
    return guest.min_night === cohost.min_night
        && guest.max_night === cohost.max_night
}

/** Common function for calendar type on change in listing */
export const useCalendarTypeOnChangeCallback =
    (setActivePageInfo: TSetActivePageInfoFunc) =>
        useCallback((newCalendarType: ListingCalendarType) => {
            setActivePageInfo((listing) => ({
                ...listing,
                calendar: {
                    ...listing.calendar,
                    type: newCalendarType,
                    availability: newCalendarType === 'default-available' ?
                        [{ start_date: -Infinity, end_date: +Infinity }]//all available
                        : [] //non available
                }
            }))
        }, [setActivePageInfo])

type TCalendarStateFn = React.Dispatch<React.SetStateAction<{
    minNight?: string | undefined;
    maxNight?: string | undefined;
}>>
/**
 * Generic function to handle calendar input on blur (for both guest calendar and cohost calendar)
 * @param stateFn the state function to call
 * @param diffCal the calendar to test against
 * @param fieldName the field name to handle
 * @param text the value in the input
 */
export const useCalendarOnBlurCallback =
    (t: TFunction, stateFn: TCalendarStateFn) =>
        useCallback((diffCal: DeepPartial<ListingCalendar> | undefined, fieldName: CalendarInputFieldName, text: string) => {
            if (fieldName === 'minNight') {
                if ((+text) < MIN_NIGHT) {
                    //if the min night is below the authorized min night
                    stateFn(errors => ({ ...errors, [fieldName]: t('listing.error.minNight', { context: 'low', count: MIN_NIGHT }) }));
                } else if (!!text && (+text) > MAX_NIGHT) {//max night optional so could not be indicated
                    //should never happen
                    stateFn(errors => ({ ...errors, [fieldName]: t('listing.error.minNight', { context: 'high', count: MAX_NIGHT }) }));
                } else if (!!text && !!diffCal?.max_night && (+text) > diffCal.max_night) {
                    //if there is a max night and the min night is above the max night
                    //set both as error
                    stateFn(errors => ({
                        ...errors,
                        [fieldName]: t('listing.error.minmaxNight', { context: 'min' }),
                        maxNight: t('listing.error.minmaxNight', { context: 'max' }),
                    }));
                } else {
                    //reset the error
                    stateFn(errors => ({ ...errors, [fieldName]: undefined }));
                }
            } else if (fieldName === 'maxNight') {
                if (!!text && (+text) < MIN_NIGHT) {
                    stateFn(errors => ({ ...errors, [fieldName]: t('listing.error.maxNight', { context: 'low', count: MIN_NIGHT }) }));
                } else if (!!text && (+text) > MAX_NIGHT) {
                    //should never happen
                    stateFn(errors => ({ ...errors, [fieldName]: t('listing.error.maxNight', { context: 'high', count: MAX_NIGHT }) }));
                } else if (!!text && !!diffCal?.min_night && (+text) < diffCal.min_night) {
                    //if there is a min night and the max night is below the max night
                    //set both as error
                    stateFn(errors => ({
                        ...errors,
                        minNight: t('listing.error.minmaxNight', { context: 'min' }),
                        [fieldName]: t('listing.error.minmaxNight', { context: 'max' }),
                    }));
                } else {
                    //reset the error
                    stateFn(errors => ({ ...errors, [fieldName]: undefined }));
                }
            }
        }, [stateFn, t])

const dbNameFromCalendarField = (fieldName: CalendarInputFieldName): keyof Pick<ListingCalendar, 'min_night' | 'max_night'> => {
    switch (fieldName) {
        case 'minNight': return 'min_night';
        case 'maxNight': return 'max_night';
    }
}
/** Common function to handle guest calendar change */
export const useGuestCalendarOnChangeCallback =
    (setActivePageInfo: TSetActivePageInfoFunc, stateFn: TCalendarStateFn) =>
        useCallback((fieldName: CalendarInputFieldName, text: string) => {
            const changed: Partial<Pick<ListingCalendar, 'min_night' | 'max_night'>> = {
                [`${dbNameFromCalendarField(fieldName)}`]: (+text),
            }
            setActivePageInfo((listing) => {
                const newValue = {
                    ...listing.calendar,
                    ...changed,
                };
                const isListingOpenToCohost = !!listing.cohost_perk?.open;
                const isDiffCohostSettings = !!listing.calendar && !!listing.cohost_perk?.calendar
                    && (!isSameCalendar(listing.calendar, listing.cohost_perk.calendar) || Object.keys(listing.cohost_perk.calendar).length === 0);

                //if there was an error and the new text is now valid, reset the error otherwise keep it
                stateFn(errors => {
                    if (!!errors[fieldName]
                        && ((fieldName === 'minNight' && (+text) >= MIN_NIGHT && (+text) <= MAX_NIGHT)
                            ||
                            (fieldName === 'maxNight' && (+text) >= MIN_NIGHT && (+text) <= MAX_NIGHT)
                        )
                    ) {
                        if (!!newValue.min_night && !!newValue.max_night && newValue.min_night > newValue.max_night) {
                            //keep error
                            return errors;
                        } else if (!!newValue.min_night && !!newValue.max_night && newValue.min_night <= newValue.max_night && errors['minNight'] && errors['maxNight']) {
                            //if good now and errors was on both->remove errors
                            return {
                                ...errors,
                                minNight: undefined,
                                maxNight: undefined
                            }
                        } else {
                            //only remove error on the field itself
                            return {
                                ...errors,
                                [fieldName]: undefined,
                            };
                        }
                    }
                    return errors;
                })

                //return the new listing info
                return {
                    ...listing,
                    calendar: newValue,
                    //update the cohost as well if not already defined
                    ...(isListingOpenToCohost && !isDiffCohostSettings ?
                        {
                            cohost_perk: {
                                ...listing.cohost_perk,
                                calendar: {
                                    ...listing.cohost_perk?.calendar,
                                    ...changed,//only set what actually changed
                                }
                            }
                        } : {})
                }
            });

        }, [setActivePageInfo, stateFn]);

/** Common function for the cohost calendar checkbox indicating if different for guest calendar in listing */
export const useCohostDiffCalendarCheckOnChangeCallback =
    (setActivePageInfo: TSetActivePageInfoFunc) =>
        useCallback((checked: boolean) => {
            if (checked) {
                //reset the cohost calendar to empty
                setActivePageInfo((listing) => ({
                    ...listing,
                    cohost_perk: {
                        ...listing.cohost_perk,
                        calendar: {}
                    }
                }))
            } else {
                //reset the cohost calendar the guest settings by default
                setActivePageInfo((listing) => ({
                    ...listing,
                    cohost_perk: {
                        ...listing.cohost_perk,
                        calendar: {
                            min_night: listing.calendar?.min_night,
                            max_night: listing.calendar?.max_night,
                        }
                    }
                }))
            }
        }, [setActivePageInfo]);

/** Common function for the cohost calendar change */
export const useCohostCalendarOnChangeCallback =
    (setActivePageInfo: TSetActivePageInfoFunc) =>
        useCallback((fieldName: CalendarInputFieldName, text: string) => {
            const changed: Partial<Pick<ListingCalendar, 'min_night' | 'max_night'>> = {
                [`${dbNameFromCalendarField(fieldName)}`]: (+text),
            }
            setActivePageInfo((listing) => {
                const newValue = {
                    ...listing.cohost_perk?.calendar,
                    ...changed,
                };
                //return the new listing info
                return {
                    ...listing,
                    cohost_perk: {
                        ...listing.cohost_perk,
                        calendar: newValue,
                    }
                }
            })
        }, [setActivePageInfo])
import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { ListingCreationFlowContext } from '../../../context/Listing/ListingCreationFlow';
import { ListingPricingType } from '../../../types/db';
import { DEFAULT_CURRENCY } from '../../../utilities/currency/function';
import { useTranslation } from 'react-i18next';
import { CurrencyMenu } from '../../../components/CurrencyMenu';
import { isDiscountValid, isPriceValid, isSamePricing, useCohostOpenListingCheckboxCallback, useCohostPricingChangeCallback, useCohostPricingPerkTypeChoiceCallback, useCurrencyChangeCallback, useGuestPricingChangeCallback, usePricingOnBlurCallback } from '../../../utilities/listings';
import { DiscountInput, IDiscountInputProps, IPricingInputProps, PricingInput, PricingInputFieldName } from '../../../components/listing/PricingInput';

/** The pricing page of the listing creation flow */
export const ListingPricingPage = () => {
    return <Container />
}

type RadioChoice = ListingPricingType;

type CoHostPerkChoice = "none" | "price_discount" | "custom_price";

const Container = () => {
    const listingCreationFlowContext = useContext(ListingCreationFlowContext);
    if (!listingCreationFlowContext) {
        throw new Error('Need ListingCreationFlowContext');
    }

    const { t } = useTranslation()

    const { listing: { pricing, cohost_perk }, setActivePageInfo } = listingCreationFlowContext;

    //The price type
    const radioValue: RadioChoice | '' = pricing?.type || '';
    const handleRadioValueChange = (newRadioValue: RadioChoice) => {
        setActivePageInfo({ pricing: { type: newRadioValue } })
    }

    //The currency for the prices
    const currency = pricing?.nightly_price?.currency || DEFAULT_CURRENCY;
    const handleCurrencyChange = useCurrencyChangeCallback(setActivePageInfo);

    //guest pricing
    const [guestPricingInputErrors, setGuestPricingErrors] = useState<{ [key in PricingInputFieldName]?: string }>({});
    const guestPricingProps: Pick<IPricingInputProps, PricingInputFieldName> =
    {
        nightlyPrice: {
            value: pricing?.nightly_price?.amount?.toString() || '',
            helperText: guestPricingInputErrors['nightlyPrice'] || '',
            error: !!guestPricingInputErrors['nightlyPrice']
        },
        weeklyDiscount: {
            value: pricing?.weekly_discount?.toString() || '',
            helperText: guestPricingInputErrors['weeklyDiscount'] || '',
            error: !!guestPricingInputErrors['weeklyDiscount']
        },
        monthlyDiscount: {
            value: pricing?.monthly_discount?.toString() || '',
            helperText: guestPricingInputErrors['monthlyDiscount'] || '',
            error: !!guestPricingInputErrors['monthlyDiscount']
        }
    }

    const handleGuestPricingOnBlur = usePricingOnBlurCallback(t, setGuestPricingErrors)

    const handleGuestPricingChange = useGuestPricingChangeCallback(setActivePageInfo, setGuestPricingErrors);

    //cohost perk
    const cohostChecked = !!cohost_perk && cohost_perk.open;
    const handleCohostCheckChange = useCohostOpenListingCheckboxCallback(setActivePageInfo);

    const cohostPerks: CoHostPerkChoice | '' =
        typeof cohost_perk?.pricing === 'number' ? 'price_discount' //if it's a number it's a discount of the guest price
            : isSamePricing(pricing, cohost_perk?.pricing) ? 'none' //if exactly the same price as the guest pricing no perk in pricing
                : !!cohost_perk?.pricing ? 'custom_price' //if a price is indicated (and not same as guest above) it's a custom price
                    : '';//else no choice yet
    const handleCohostPerkChoiceChange = useCohostPricingPerkTypeChoiceCallback(setActivePageInfo)

    const [cohostPricingInputErrors, setCohostPricingErrors] = useState<{ [key in (PricingInputFieldName | 'discount')]?: string }>({});
    const cohostPricingProps: Pick<IPricingInputProps, PricingInputFieldName> | IDiscountInputProps =
        !!cohost_perk?.pricing ?
            typeof cohost_perk.pricing === 'number' ?
                {
                    value: cohost_perk.pricing.toString(),
                    helperText: cohostPricingInputErrors['discount'],
                    error: !!cohostPricingInputErrors['discount'],
                }
                :
                {
                    nightlyPrice: {
                        value: cohost_perk.pricing.nightly_price?.amount?.toString() || '',
                        helperText: cohostPricingInputErrors['nightlyPrice'] || '',
                        error: !!cohostPricingInputErrors['nightlyPrice']
                    },
                    weeklyDiscount: {
                        value: cohost_perk.pricing.weekly_discount?.toString() || '',
                        helperText: cohostPricingInputErrors['weeklyDiscount'] || '',
                        error: !!cohostPricingInputErrors['weeklyDiscount']
                    },
                    monthlyDiscount: {
                        value: cohost_perk.pricing.monthly_discount?.toString() || '',
                        helperText: cohostPricingInputErrors['monthlyDiscount'] || '',
                        error: !!cohostPricingInputErrors['monthlyDiscount']
                    }
                }
            : {};

    const handleCohostPricingOnBlur = usePricingOnBlurCallback(t, setCohostPricingErrors)

    const handleCohostPricingChange = useCohostPricingChangeCallback(setActivePageInfo, setCohostPricingErrors)

    useEffect(() => {
        if (!cohost_perk
            && (pricing?.type === 'non-bookable'
                || pricing?.type === 'sum-sub-listing'
                || (pricing?.type === 'unit-bookable' && isPriceValid(pricing)))) {
            //if no cohost perk and the guest pricing is valid
            setActivePageInfo({}, true)
        } else if (!!cohost_perk
            && ((typeof cohost_perk.pricing === 'number') ? isDiscountValid(cohost_perk.pricing, true) : isPriceValid(cohost_perk.pricing))
            && isPriceValid(pricing)) {
            //if cohost perk, check if both pricing are valid
            setActivePageInfo({}, true)
        } else {
            setActivePageInfo({}, false)//reset to false
        }
    }, [cohost_perk, pricing, setActivePageInfo])

    return (
        <Stack direction={'column'}
            justifyContent={'center'}
            alignItems={'stretch'}
            spacing={2}>
            <FormControl>
                <RadioGroup
                    value={radioValue}
                    onChange={(ev) => handleRadioValueChange(ev.target.value as RadioChoice)}
                >
                    <FormControlLabel
                        value={"non-bookable" as RadioChoice}
                        control={<Radio />}
                        label={t('listing.creation.pricingChoice', { context: 'none' })}
                    />
                    <FormControlLabel
                        value={"sum-sub-listing" as RadioChoice}
                        control={<Radio />}
                        label={t('listing.creation.pricingChoice', { context: 'sum' })}
                    />
                    <FormControlLabel
                        value={"unit-bookable" as RadioChoice}
                        control={<Radio />}
                        label={t('listing.creation.pricingChoice', { context: 'unit' })}
                    />
                </RadioGroup>
            </FormControl>
            {radioValue === "unit-bookable" &&
                <>
                    <CurrencyMenu
                        currency={currency}
                        onCurrencyChange={handleCurrencyChange}
                    />
                    <PricingInput
                        currency={currency}
                        {...guestPricingProps}
                        handleOnChange={handleGuestPricingChange}
                        handleOnBlur={handleGuestPricingOnBlur} />
                    <FormControl>
                        <FormControlLabel control={
                            <Checkbox
                                checked={cohostChecked}
                                onChange={(_, checked) => handleCohostCheckChange(checked)} />}
                            label={t('listing.creation.openToCoHost')} />
                        {
                            cohostChecked &&
                            <>
                                <RadioGroup
                                    value={cohostPerks}
                                    onChange={(ev) => {
                                        handleCohostPerkChoiceChange(ev.target.value as CoHostPerkChoice)
                                    }}
                                >
                                    <FormControlLabel
                                        value={"none" as CoHostPerkChoice}
                                        control={<Radio />}
                                        label={t('listing.creation.perkCoHost', { context: "none" })}
                                    />
                                    <FormControlLabel
                                        value={"price_discount" as CoHostPerkChoice}
                                        control={<Radio />}
                                        label={t('listing.creation.perkCoHost', { context: "discount" })}
                                    />
                                    <FormControlLabel
                                        value={"custom_price" as CoHostPerkChoice}
                                        control={<Radio />}
                                        label={t('listing.creation.perkCoHost', { context: "custom" })}
                                    />
                                </RadioGroup>
                                {
                                    cohostPerks === 'price_discount' ?
                                        <DiscountInput label={t('listing.pricing.discountLabel')}
                                            placeholder={t('listing.pricing.discountPlaceholder')}
                                            {...(cohostPricingProps as IDiscountInputProps)}
                                            onChange={(ev) => handleCohostPricingChange('discount', ev.target.value)}
                                            onBlur={(ev) => handleCohostPricingOnBlur('discount', ev.target.value)}
                                        />
                                        : cohostPerks === 'custom_price' ?
                                            <PricingInput
                                                currency={currency}
                                                {...(cohostPricingProps as IPricingInputProps)}
                                                handleOnChange={handleCohostPricingChange}
                                                handleOnBlur={handleCohostPricingOnBlur} />
                                            : null
                                }
                            </>
                        }
                    </FormControl>
                </>
            }
        </Stack>
    )
}
import React from 'react';
import { ShopCartContainer } from '../../containers/shop/ShopCartContainer';
import { useShopNavigation } from '../../hooks/useShopNavigation';

export const ShopCartPage = () => {
    const { shopId, navigateToShop, navigateToProduct, navigateToCheckout } = useShopNavigation();

    return <ShopCartContainer shopId={shopId}
        onCartRedirectTo={(productId, variantId) =>
            (!productId && !variantId) ?
                navigateToShop() :
                navigateToProduct(productId, variantId)}
        onCheckoutClick={navigateToCheckout} />;
}

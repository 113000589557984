
import React, { PropsWithChildren, useContext } from 'react';
import { ROUTE_PARAMS, ROUTE_PATH } from '../../../utilities/navigation';
import { CreateListingPageView } from '../../../components/listing/creation/CreateListingPageView/CreateListingPageView';
import { Outlet, useNavigate } from 'react-router';
import { useParams } from 'react-router';
import { ListingContextProvider, ListingCreationFlowContext, ListingCreationFlowProvider } from '../../../context/Listing';
import { db } from '../../../services/firebase';
import { LISTINGS_COLLECTION } from '../../../types/db';

/** The Page for the new listing flow */
export const NewListingPage = () => {
    const navigate = useNavigate();
    const { [ROUTE_PARAMS.listingID]: currentListingId, [ROUTE_PARAMS.flowStepID]: currentStepId, } = useParams();

    const navigateToStep = (newStepId: string, listingId?: string) => {
        if ((!listingId || listingId === currentListingId) && currentStepId === newStepId) {
            return;//if trying to go to the same link, just return
        }
        navigate("./" + (listingId || currentListingId) + "/" + newStepId);
    };

    const navigateToPublishSuccess = () => {
        navigate(ROUTE_PATH.listingPublishedBuild(currentListingId || ''))
    }

    return (
        <ListingContextProvider listingId={currentListingId}>
            <ListingCreationFlowProvider stepId={currentStepId}
                onStepId={navigateToStep}
                onPublished={navigateToPublishSuccess}>
                <CreateListingContainer>
                    <Outlet />
                </CreateListingContainer>
            </ListingCreationFlowProvider>
        </ListingContextProvider>
    );
}

/** The Container which take care of the header and footer of the creation flow (taking info from the context to provide to the UI) */
const CreateListingContainer = ({ children }: PropsWithChildren<{}>) => {
    const flowContext = useContext(ListingCreationFlowContext);

    if (!flowContext) {
        throw new Error('Need ListingCreationFlowContext Provider')
    }

    const { steps, activeStep, handlePrev, handleNext, completed } = flowContext;

    return (
        <CreateListingPageView stepper={{
            steps,
            activeStep,
            handleNext,
            handlePrev,
            completed,
        }}>
            {children}
        </CreateListingPageView>
    );
}


//TODO remove (kept here for quick copy paste of mass listing deletion)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleCleanUp = async () => {
    const docs = await db.collection(LISTINGS_COLLECTION)
        .where("status", '==', "pending").limit(400).get();
    const batch = db.batch();
    docs.docs.forEach(doc => batch.delete(doc.ref));
    await batch.commit();
    console.log("deleted", docs.size);
}

import React from "react";
import { CategorySection, ICategorySectionProps } from "../CategorySection/CategorySection";
import { BANNER_HOME_PAGE } from "../../utilities/assets/assetHelper";
import { Divider, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Banner } from "../Banner";

export interface IHomeScreenProps {
    /** Callback on "explore" button clicked */
    onExploreClick: () => void;
    /** Callback on "join" button clicked */
    onJoinClick: () => void;
    /** The list of categories */
    categories: [ICategorySectionProps, ICategorySectionProps, ICategorySectionProps];
}

export const HomeScreen = ({ onExploreClick, onJoinClick, categories }: IHomeScreenProps) => {
    const { t } = useTranslation();
    return (
        <Stack direction={'column'} sx={{ mb: 2 }} spacing={1}>
            <Banner
                title={t('home.banner.title')}
                subTitle={t('home.banner.content')}
                primaryButton={{
                    text: t('home.banner.exploreBtn'),
                    onClick: onExploreClick
                }}
                secondaryButton={{
                    text: t('home.banner.joinBtn'),
                    onClick: onJoinClick
                }}
                backgroundImageSrc={BANNER_HOME_PAGE}
            />
            <Stack direction={'column'} spacing={2}>
                <CategorySection {...categories[0]} />
                <Divider />
                <CategorySection {...categories[1]} />
                <Divider />
                <CategorySection {...categories[2]} />
            </Stack>
        </Stack>
    );
}


import { IShopCheckoutProps } from "./ShopCheckout";
import { IShopPriceProps } from "../ShopPrice";


export const calculateTotalCartValue = ({ items }: Pick<IShopCheckoutProps, 'items'>): IShopPriceProps => {
    const tot = items.reduce((prev, curr) =>
        prev + (curr.price.discountedPrice?.amount ?? curr.price.originalPrice.amount) * curr.quantity
        , 0);
    const currency = items[0]?.price.originalPrice.currency || 'USD';
    return {
        originalPrice: {
            amount: tot,
            currency,
        }
    }
}

export const calculateTotalCartItems = ({ items }: Pick<IShopCheckoutProps, 'items'>): number => {
    return items.reduce((prev, curr) =>
        prev + curr.quantity
        , 0);
}
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField, TextFieldProps } from '@mui/material';
import { ListingCreationFlowContext } from '../../../context/Listing/ListingCreationFlow';
import { GuestsFilterSelection } from '../../../components/GuestsFilterSelection';
import { ListingGuestsRestrictionType } from '../../../types/db';
import { isDescriptionValid, isGuestsRestrictionValid, LONG_DESC_MAX_LENGTH, useGuestsChangeCallback, useGuestsRestrictionChangeCallback, useHandleLongDescriptionOnBlurCallback, useHandleLongDescriptionOnChangeCallback } from '../../../utilities/listings';

/** The optional details (long description) of the listing creation flow */
export const ListingDetailsPage = () => {
    const listingCreationFlowContext = useContext(ListingCreationFlowContext);
    if (!listingCreationFlowContext) {
        throw new Error('Need ListingCreationFlowContext');
    }
    const { t } = useTranslation();

    const { listing: { description, guests_restriction }, setActivePageInfo } = listingCreationFlowContext;

    const [descError, setDescError] = useState<string>();

    const descProps: TextFieldProps = {
        value: description || '',
        helperText: descError || `${description?.length || 0}/${LONG_DESC_MAX_LENGTH}`,
        error: !!descError,
    }

    useEffect(() => {
        if (isDescriptionValid(description) && isGuestsRestrictionValid(guests_restriction)) {
            //allow to go farther in the process
            setActivePageInfo({}, true)
        } else {
            //disallow going farther
            setActivePageInfo({}, false)
        }
    }, [description, guests_restriction, setActivePageInfo]);

    const handleDescOnBlur = useHandleLongDescriptionOnBlurCallback(t, setDescError);
    const handleDescOnChange = useHandleLongDescriptionOnChangeCallback(setActivePageInfo, setDescError);

    const handleGuestsRestrictionChange
        = useGuestsRestrictionChangeCallback(setActivePageInfo)

    const handleGuestsChange
        = useGuestsChangeCallback(setActivePageInfo)

    return (
        <Container>
            <Stack direction={'column'}
                justifyContent={'space-evenly'}
                alignItems={'stretch'}
                spacing={2}>
                <TextField
                    label={t('listing.creation.descriptionLabel')}
                    placeholder={t('listing.creation.descriptionPlaceholder')}
                    multiline={true}
                    minRows={3}
                    onBlur={handleDescOnBlur}
                    onChange={handleDescOnChange}
                    autoComplete={'off'}
                    {...descProps} />
                <FormControl>
                    <FormLabel>{t('listing.creation.guestsRestrictionLabel')}</FormLabel>
                    <RadioGroup
                        value={guests_restriction?.type || ''}
                        onChange={handleGuestsRestrictionChange}
                    >
                        <FormControlLabel value={'none' as ListingGuestsRestrictionType}
                            control={<Radio />}
                            label={t('listing.creation.guestsRestrictionChoice', { context: 'none' })} />
                        <FormControlLabel value={'sum-sub-listing' as ListingGuestsRestrictionType}
                            control={<Radio />}
                            label={t('listing.creation.guestsRestrictionChoice', { context: 'sum' })} />
                        <FormControlLabel value={'custom' as ListingGuestsRestrictionType}
                            control={<Radio />}
                            label={t('listing.creation.guestsRestrictionChoice', { context: 'custom' })} />
                    </RadioGroup>
                </FormControl>
                {guests_restriction?.type === 'custom' &&
                    <GuestsFilterSelection
                        adults={{
                            value: guests_restriction?.max_adults || 1,
                            onChange: (value) => handleGuestsChange('max_adults', value)
                        }}
                        children={{
                            value: guests_restriction?.max_children || 0,
                            onChange: (value) => handleGuestsChange('max_children', value)
                        }}
                        infants={{
                            value: guests_restriction?.max_infants || 0,
                            onChange: (value) => handleGuestsChange('max_infants', value)
                        }}
                        pets={{
                            value: guests_restriction?.max_pets || 0,
                            onChange: (value) => handleGuestsChange('max_pets', value)
                        }}
                    />}
            </Stack>
        </Container>
    )
}
import React from 'react';
import { Container, Fab, Grid, Link, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Edit, Flag, } from '@mui/icons-material';
import { IProfileSummaryProps, ProfileSummary } from '../ProfileSummary';
import { IProfileAboutProps, ProfileAbout } from '../ProfileAbout';
import { ProfileReviewSection } from '../ProfileReviewSection';
import { ProfileListingSection } from '../ProfileListingSection';
import { IssueStateView } from '../../IssueStateView';
import { PROFILE_NOT_FOUND_URL } from '../../../utilities/assets/assetHelper';
import { EditProfileDialog } from '../../../dialog/EditProfileDialog';
import { EditInformation } from '../../../dialog/EditProfileDialog/EditProfileDialog';
import { useTranslation } from 'react-i18next';
import { PropsWithErrorState, PropsWithLoadingState } from '../../../utilities';

export interface IProfileDetailsProps {
    /** The profile side summary information */
    summary: IProfileSummaryProps;
    /** The profile more detailed information */
    about: IProfileAboutProps;
    /** Callback on profile report click. If not provided not showing button */
    onReportClick?(): void;
    onEditClick?(info?: EditInformation): void;
    isInEditMode?: boolean;
    onAvatarChanged?: (file: File) => Promise<string>;
    renderReviews: () => JSX.Element;
    renderListings: () => JSX.Element;
}

export const ProfileDetails = ({
    summary,
    about,
    onReportClick,
    onEditClick,
    isInEditMode,
    onAvatarChanged,
    renderListings,
    renderReviews,
    loading,
    error,
}: PropsWithErrorState<PropsWithLoadingState<IProfileDetailsProps>>) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));

    if (!!error) {
        return <IssueStateView
            title={t('user.profile.errorTitle')}
            image={PROFILE_NOT_FOUND_URL}
            description={t('user.profile.errorContent')}
        />
    }

    return (
        <Container sx={{ px: smUp ? 2 : 0, py: 2, minHeight: '100vh' }} >
            <Grid container spacing={1} sx={{ padding: 2 }}>
                <Grid item xs={12} sm={4}>
                    {!loading ?
                        <Stack direction={'column'} alignItems={'start'}>
                            <ProfileSummary {...summary} />
                            {!!onReportClick &&
                                <Link onClick={onReportClick}>
                                    <Stack direction={'row'}>
                                        <Flag color={"error"} />
                                        <Typography>{t('user.profile.reportButton')}</Typography>
                                    </Stack>
                                </Link>
                            }
                        </Stack>
                        :
                        <ProfileSummary loading />
                    }
                </Grid>
                <Grid item xs={12} sm={8}>
                    {!loading ?
                        <ProfileAbout {...about} />
                        :
                        <ProfileAbout loading />
                    }
                </Grid>
                <Grid item xs={12}>
                    {!loading ?
                        renderListings()
                        :
                        <ProfileListingSection loading />
                    }
                </Grid>
                <Grid item xs={12}>
                    {!loading ?
                        renderReviews()
                        :
                        <ProfileReviewSection loading />
                    }
                </Grid>
                {!loading && !!onEditClick &&
                    <>
                        <Tooltip title={t('user.profile.editButton')}>
                            <Fab variant={"circular"}
                                color={"primary"}
                                aria-label={t('user.profile.editButton')}
                                style={{ position: 'fixed', bottom: 10, right: 10 }}
                                onClick={() => onEditClick()}>
                                <Edit />
                            </Fab>
                        </Tooltip>
                        <EditProfileDialog
                            info={{
                                displayName: summary.displayName,
                                avatarUrl: summary.avatarImgSrc || '',
                                description: about.description,
                            }}
                            open={!!isInEditMode}
                            onClose={(info) => onEditClick(info)}
                            onAvatarChange={onAvatarChanged!}
                        />
                    </>
                }
            </Grid>
        </Container >
    );
}
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import { ROUTE_PATH, ROUTE_QUERY, buildPath } from "../utilities/navigation";

/** helper hook which gives navigation function for the Forms subdomain */
export const useNewsNavigation = () => {
    const navigate = useNavigate();

    /** Navigate to the page of the details of a story, if id not provided navigate to all stories */
    const navigateToStory = (storyId?: string) => {
        if (!storyId) {
            navigate(buildPath(ROUTE_PATH.news.stories));
        } else {
            navigate(ROUTE_PATH.news.storyBuild(storyId))
        }
    }

    /** Navigate to a list of stories with a certain category */
    const navigateToCategory = (categoryId: string) => {
        navigate({
            pathname: buildPath(ROUTE_PATH.news.stories),
            search: '?' + createSearchParams({ [ROUTE_QUERY.category]: categoryId })
        })
    }

    /** Navigate to a list of stories with a certain tag */
    const navigateToTag = (tagId: string) => {
        navigate({
            pathname: buildPath(ROUTE_PATH.news.stories),
            search: '?' + createSearchParams({ [ROUTE_QUERY.tag]: tagId })
        })
    }

    /** Navigate to a list of stories from a certain author */
    const navigateToAuthor = (authorId: string) => {
        navigate({
            pathname: buildPath(ROUTE_PATH.news.stories),
            search: '?' + createSearchParams({ [ROUTE_QUERY.author]: authorId })
        })
    }

    return {
        navigateToStory,
        navigateToCategory,
        navigateToTag,
        navigateToAuthor
    }
}
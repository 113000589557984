import { TFunction } from "i18next";
import { ERole } from "../../../../utilities/EnumUtility";

/** 
 * Get the title of the section
 * @param userRole The user role
 * @returns the string of the title of the section
 */
export const getSectionTitleFrom = (t: TFunction, userRole: ERole) => {
    switch (userRole) {
        case ERole.HOST:
            return t('user.dashboard.bookingsSectionTitle', { context: 'host' });
        case ERole.COHOST:
            return t('user.dashboard.bookingsSectionTitle', { context: 'cohost' });
        case ERole.GUEST:
            return t('user.dashboard.bookingsSectionTitle', { context: 'guest' });
        default:
            return '';
    }
}

/**
 * Get the message for the empty section
 * @param userRole The user role
 * @returns the string of the empty section message
 */
export const getEmptyContentMessageFrom = (t: TFunction, userRole: ERole) => {
    switch (userRole) {
        case ERole.HOST:
            return t('user.dashboard.bookingsEmptyContent', { context: 'host' });
        case ERole.COHOST:
            return t('user.dashboard.bookingsEmptyContent', { context: 'cohost' });
        case ERole.GUEST:
            return t('user.dashboard.bookingsEmptyContent', { context: 'guest' });
        default:
            return '';
    }
}
/**
 * Get the message for the empty section
 * @param userRole The user role
 * @returns the string of the empty section message
 */
export const getEmptyContentButtonLabelFrom = (t: TFunction, userRole: ERole) => {
    switch (userRole) {
        case ERole.HOST:
            return t('user.dashboard.bookingsEmptyButton', { context: 'host' });
        case ERole.COHOST:
            return t('user.dashboard.bookingsEmptyButton', { context: 'cohost' });
        case ERole.GUEST:
            return t('user.dashboard.bookingsEmptyButton', { context: 'guest' });
        default:
            return '';
    }
}
import { CreditCard, CreditCardOff, Payment } from '@mui/icons-material';
import { Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AccountBaseInfo, AccountBaseInfoItems } from '../../../components/user/account/AccountBaseInfo';
import { IAccountHelpItemProps } from '../../../components/user/account/AccountHelpItem';
import { getTitleFromAccountPage } from '../../../components/user/account/AccountPageView';
import { functions } from '../../../services/firebase';
import { IAccountSubContainerProps } from '../AccountSubContainer';

export const AccountFinancesContainer = ({
    subPage,
    userId,
    children,
}: IAccountSubContainerProps) => {
    const { t } = useTranslation();
    const cardInfo = useQuery(["account", "cc"], () => functions.httpsCallable("getUserPaymentMethods")());
    const customerPortal = useQuery(["account", "customerPortal"], () => functions.httpsCallable('createCustomerPortalSession')());

    // const userFinancesResult = useQuery(["account", "finances"],
    //     () => getUserFinanceInfo(userId));
    // const userFinancesInfo = userFinancesResult.data;

    const items: AccountBaseInfoItems =
        // !userFinancesInfo ? [] :
        [
            {
                label: t('user.accountFinance.paymentsTitle'),
                items: [{
                    title: t('user.accountFinance.paymentMethodsTitle'),
                    value: () => {
                        const card = cardInfo.data?.data[0].card;
                        if (!card) return <></>;
                        // console.log(card);
                        return (
                            <Paper sx={{ p: 2 }}>
                                <Stack direction={'row'}
                                    spacing={2}
                                    justifyContent={'start'}
                                    alignItems={'center'}>
                                    {card.brand === 'visa' ? <CreditCard /> : <CreditCardOff />}
                                    <Stack direction={'column'} spacing={1}>
                                        <Typography>{`${card.brand} ***${card.last4}`}</Typography>
                                        <Typography>{t('user.accountFinance.creditCardExpirationContent')}</Typography>
                                    </Stack>
                                </Stack>
                            </Paper>
                        )
                    },
                    onEditClick: () => {
                        window.open(customerPortal.data?.data as string, "_blank");
                    },
                    editLabel: t('user.accountFinance.paymentEditButton')
                },
                ],
            },
            // {
            //     label: t('user.accountFinance.payoutTitle'),
            //     items: [],
            // },
            //TODO add the functionality first
            // {
            //     label: t('user.accountFinance.taxesTitle'),
            //     items: userLoginInfo.device_history?.map(dh => ({
            //         title: dh.device_name
            //     })) ?? [],
            // }
            // {
            //     label: t('user.accountFinance.feesTitle'),
            //     items: userLoginInfo.device_history?.map(dh => ({
            //         title: dh.device_name
            //     })) ?? [],
            // }
            // {
            //     label: t('user.accountFinance.donationsTitle'),
            //     items: userLoginInfo.device_history?.map(dh => ({
            //         title: dh.device_name
            //     })) ?? [],
            // }
        ]

    const helpItems = HELP_ITEMS.map(h => ({
        ...h,
        title: t(h.title),
        description: t(h.description)
    }))

    if (!!children) {
        return children({ items, helpItems: helpItems });
    } else {
        return <AccountBaseInfo title={getTitleFromAccountPage(t, subPage)}
            items={items}
            helpItems={helpItems} />
    }
}

const HELP_ITEMS: IAccountHelpItemProps[] = [
    {
        icon: <Payment />,
        title: 'user.accountFinance.helpInfo1Title',
        description: 'user.accountFinance.helpInfo1Content',
    },
];
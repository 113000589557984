import { useState, useEffect } from "react";
import { rtdb } from "../../services/firebase";
import { USER_CHANNELS, UserChannel, USER_CHANNEL_LAST_UPDATE } from "../../types/db/chat";

/** Channel info is the info of a user channel with the channel id */
type ChannelInfo = UserChannel & { id: string };

/** The default limit of fetching data */
const DEFAULT_LIMIT_TO_LAST = 5;

/**
 * Hook which takes care of fetching and updating the list of threads of a user
 * @param userId The channel ID of the channel
 * @returns the array of threads in the inbox
 */
export const useUserInbox = (userId: string): {
    /** The list of threads in the inbox */
    channels: ChannelInfo[];
    /** If any action is currently loading */
    isLoading?: boolean;
    /** If any action ended up with an error */
    error?: string;
} => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [channels, setChannels] = useState<ChannelInfo[]>([]);

    useEffect(() => {
        if (!userId) {
            setIsLoading(false);
            //in the case of user not defined->no channels
            setChannels([]);
            return;
        }

        //update the current listing with the listing info from BE
        setIsLoading(true)
        //subscribe to any added child of that user's channel
        const fetchUserChannel = rtdb.ref(USER_CHANNELS).child(userId);

        fetchUserChannel.orderByChild(USER_CHANNEL_LAST_UPDATE).limitToFirst(DEFAULT_LIMIT_TO_LAST)
            .on("child_added", (snapshot) => {
                const userChannelInfo: UserChannel | null = snapshot.val();
                // console.log('child_added', userChannelInfo)
                setIsLoading(false)
                if (!userChannelInfo) {
                    //can happen if the chat is empty
                    return;
                }
                const channelID = snapshot.key!;
                // append the item on the list
                setChannels(currentItems => [
                    {
                        id: channelID,
                        ...userChannelInfo,
                    },
                    ...currentItems,
                ]);
            });

        fetchUserChannel.on("child_changed", (snapshot) => {
            const userChannelInfo: UserChannel = snapshot.val();
            const channelID = snapshot.key!;
            // console.log('child_changed', userChannelInfo)
            // modify the item on the list
            setChannels(currentItems => {
                const index = currentItems.findIndex(item => item.id === channelID);
                if (index === -1) {
                    //if not found in the list, return as is
                    return currentItems;
                }

                return [
                    ...currentItems.slice(0, index),
                    {
                        id: channelID,
                        ...userChannelInfo,
                    },
                    ...currentItems.slice(index + 1),
                ]
            });
        })

        fetchUserChannel.on("child_removed", (snapshot) => {
            // const userChannelInfo: UserChannel = snapshot.val();
            const channelID = snapshot.key!;
            // console.log('child_removed', userChannelInfo)
            //remove the channel from the channel list
            setChannels(currentItems =>
                currentItems.filter(item => item.id !== channelID)
            );
        })

        return () => {
            //unsubscribe to all listener for the user channel
            fetchUserChannel.off();
            //reset the user channel list (user changed)
            setChannels([]);
        };
    }, [userId]);

    useEffect(() => {
        //from the nature of the subscription, it is possible that the user doesn't have channels
        //so `setIsLoading(false)` never called, therefore we add a timeout
        setTimeout(() => setIsLoading(false), 1000)
    }, []);

    return {
        channels,
        isLoading,
        error,
    }
}
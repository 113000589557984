import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import { DEFAULT_LISTING_IMAGE_URL } from '../../../utilities/assets/assetHelper';

export interface ISubListingItemProps {
    /** The title of the sub-listing */
    title: string;
    /** The main image url of the sub-listing */
    image: string;
    /** Callback on press on the item */
    onClick: () => void;
}

export const SubListingItem = ({ title, image, onClick }: ISubListingItemProps) => {
    return (
        <Card>
            <CardActionArea onClick={onClick}>
                <Stack direction={'row'}>
                    <CardMedia component={'img'} image={image || DEFAULT_LISTING_IMAGE_URL} alt={title}
                        sx={{ width: '30%', aspectRatio: 1 }} />
                    <CardContent>
                        <Stack direction={'column'} justifyContent={'center'} alignItems={'stretch'}>
                            <Typography variant='h5'>{title}</Typography>
                        </Stack>
                    </CardContent>
                </Stack>
            </CardActionArea>
        </Card>
    )
}
import React from "react";
import { useQuery } from "react-query";
import { ShopCheckoutSuccess } from "../../../components/shop/ShopCheckout/ShopCheckoutSuccess";
import { fetchSessionIdInfo } from "../../../utilities/shop";

// const SESSION_ID_TEST = "cs_test_b1JSfz1bxOKS3KoGusCexJ1pDgQqVKc6bd3REQGKcF13q5J3XrJbc0phbJ";

interface IShopCheckoutSuccessContainerProps {
    sessionId: string;
    onContinueShoppingClick: () => void;
}

export const ShopCheckoutSuccessContainer = ({
    sessionId,
    onContinueShoppingClick,
}: IShopCheckoutSuccessContainerProps) => {
    const { isLoading, isError, data } = useQuery(
        ["checkout-success", sessionId],
        () => fetchSessionIdInfo(sessionId),
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,//default to true, so we don't want to refresh data which won't change here
        });

    if (isLoading) {
        return <ShopCheckoutSuccess loading />
    }
    if (isError || !data) {
        return <ShopCheckoutSuccess error onContinueShopping={onContinueShoppingClick} />
    }

    const sessionInfo = data.data;

    return <ShopCheckoutSuccess
        onContinueShopping={onContinueShoppingClick}
        items={[]}
        orderId={''}
        paymentMethod={''}
        shippingOption={''}
        shippingAddress={{
            line1: sessionInfo.customer_details.address.line1 || '',
            line2: sessionInfo.customer_details.address.line2 || undefined,
            city: sessionInfo.customer_details.address.city || '',
            zip: sessionInfo.customer_details.address.postal_code || '',
            country: sessionInfo.customer_details.address.country || '',
            phone: sessionInfo.customer_details.phone,
        }}
        email={sessionInfo.customer_details.email || undefined}
        name={sessionInfo.customer_details.name || undefined} />
}
import React from 'react';
// import { Grid, Skeleton } from '@mui/material';
import DateFnsUtils from '@date-io/date-fns';
// import { styled } from '@mui/material/styles';
// import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import { DayCalendarSkeleton } from '@mui/x-date-pickers';
import { PropsWithLoadingState } from '../../../utilities';
import { useTranslation } from 'react-i18next';
// import { CalendarPickerSkeleton } from '@mui/x-date-pickers/CalendarPickerSkeleton';

const DateFnsUtilsInstance = new DateFnsUtils();

type DatePeriod = {
    startDate: Date;
    endDate: Date;
}
export interface IListingCalendarViewProps {
    /**
     * The selected dates by the user
     */
    selectedDates?: DatePeriod;
    /**
     * Non-available dates in the calendar
     */
    occupiedPeriods?: DatePeriod[];
}

// type CustomPickerDayProps = PickersDayProps<Date> & {
//     dayIsBetween: boolean;
//     isFirstDay: boolean;
//     isLastDay: boolean;
// };

// const CustomPickersDay = styled(PickersDay, {
//     shouldForwardProp: (prop) =>
//         prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
// })<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay, disabled }) => ({
//     ...(dayIsBetween && {
//         borderRadius: 0,
//         backgroundColor: theme.palette.primary.main,
//         color: theme.palette.primary.contrastText,
//         '&:hover, &:focus': {
//             backgroundColor: theme.palette.primary.dark,
//         },
//     }),
//     ...(isFirstDay && {
//         borderTopLeftRadius: '50%',
//         borderBottomLeftRadius: '50%',
//     }),
//     ...(isLastDay && {
//         borderTopRightRadius: '50%',
//         borderBottomRightRadius: '50%',
//     }),
//     ...(disabled && {
//         textDecorationLine: 'line-through'
//     }),
// })) as React.ComponentType<CustomPickerDayProps>;

const isDayInPeriod = (day: Date, period: DatePeriod, includingEnd?: boolean) => {
    const start = new Date(period.startDate.getTime());
    start.setHours(0, 0, 0, 0);
    const end = new Date(period.endDate.getTime());
    includingEnd ?
        end.setHours(23, 59, 59, 999)
        :
        end.setHours(0, 0, 0, 0);
    return start <= day
        && (includingEnd ? day <= end : day < end);
}

const isOccupiedDay = (day: Date, occupiedPeriods?: DatePeriod[]) => {
    if (!occupiedPeriods) {
        return false;
    }

    return occupiedPeriods.some((occupiedPeriod) => {
        return isDayInPeriod(day, occupiedPeriod);
    })
}


const LoadingCalendarContent = () => {
    const CUSTOM_CALENDAR_LOADER = false;
    if (CUSTOM_CALENDAR_LOADER) {
        return (
            <Grid container sx={{ marginLeft: 2, marginRight: 2, marginTop: -10 }}>
                {new Array(5).fill({}).map((_, lineIndex) =>
                    <Grid key={`Week${lineIndex}`} item container xs={12} columns={7}>
                        {new Array(7).fill({}).map((_, dayIndex) =>
                            <Grid key={`Week${lineIndex}_Day${dayIndex}`} item xs={1}>
                                <Skeleton><PickersDay isFirstVisibleCell={true} isLastVisibleCell={true} day={new Date()} onDaySelect={() => { }} outsideCurrentMonth={false} /></Skeleton>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        );
    }

    return <DayCalendarSkeleton />;
}

export const ListingCalendarView = ({ loading, selectedDates, occupiedPeriods }: PropsWithLoadingState<IListingCalendarViewProps>) => {
    const { t } = useTranslation();
    return (
        <Stack direction={'column'}>
            <Typography variant={'h3'}>{t('listing.calendar.sectionTitle')}</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    // disableHighlightToday={true}
                    disablePast={true}
                    minDate={new Date(Date.now())}
                    // maxDate={DateFnsUtilsInstance.endOfYear(new Date(Date.now()))}
                    // showToolbar={false}
                    // value={value}
                    // onChange={(newValue) => {
                    //     setValue(newValue);
                    // }}
                    loading={loading}
                    readOnly={true}
                    autoFocus={false}
                    renderLoading={LoadingCalendarContent}
                    shouldDisableDate={(date) => isOccupiedDay(date, occupiedPeriods)}
                    showDaysOutsideCurrentMonth={false}
                    slots={{
                        day: ServerDay,
                    }}
                    slotProps={{
                        day: {
                            selectedDates
                        } as any
                    }}
                />
            </LocalizationProvider>
        </Stack>
    );
}

function ServerDay(props: PickersDayProps<Date> & { selectedDates?: DatePeriod }) {
    const { selectedDates, day, outsideCurrentMonth, ...other } = props;

    const isSelected =
        !props.outsideCurrentMonth
        && selectedDates && !!selectedDates.startDate && !!selectedDates.endDate
        && (DateFnsUtilsInstance.isSameDay(day, selectedDates.startDate) || DateFnsUtilsInstance.isAfter(day, selectedDates.startDate))
        && (DateFnsUtilsInstance.isSameDay(day, selectedDates.endDate) || DateFnsUtilsInstance.isBefore(day, selectedDates.endDate));

    //the style of the day (if selected check what day it is, otherwise no custom style)
    const sx = !isSelected ?
        {} :
        DateFnsUtilsInstance.isSameDay(day, selectedDates.startDate) ?
            {
                borderRadius: 0,
                borderTopLeftRadius: '50%',
                borderBottomLeftRadius: '50%',
            } :
            DateFnsUtilsInstance.isSameDay(day, selectedDates.endDate) ?
                {
                    borderRadius: 0,
                    borderTopRightRadius: '50%',
                    borderBottomRightRadius: '50%',
                } :
                { borderRadius: 0 }
        ;
    return (
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth}
            day={day} selected={isSelected} sx={sx} />
    );
}
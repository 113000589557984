import React from 'react';
import { useQuery } from 'react-query';
import { HelpTopArticles } from '../../../components/help/HelpTopArticles';
import { getTopArticles } from '../../../services/help';

export interface IHelpTopArticlesContainerProps {
    /** Optional, the topic id under which we want to fetch the top articles (if not provided fetch all articles) */
    topicId?: string;
    /** Callback on all topics button click, show navigate to all topic page */
    onShowTopicsClick: () => void;
    /** Callback on an article clicked */
    onArticleClick: (articleId: string) => void;
}

/** Container with fetch the top rated articles */
export const HelpTopArticlesContainer = ({
    topicId,
    onShowTopicsClick,
    onArticleClick,
}: IHelpTopArticlesContainerProps) => {
    const { data, isLoading, isError } = useQuery(
        ["top-articles", topicId || 'all'],
        () => getTopArticles(topicId),
        { refetchOnWindowFocus: false });

    if (isLoading) {
        return <HelpTopArticles loading />
    }

    if (isError || !data) {
        return null;//no showing anything as optional display
    }

    return (
        <HelpTopArticles onShowTopicsClick={onShowTopicsClick}
            items={data.map(item => ({
                title: item.title,
                description: item.description,
                onClick: () => onArticleClick(item.article_id),
            }))} />
    );
}
import React from 'react';
import { ShopCheckoutContainer } from '../../containers/shop/ShopCheckoutContainer';
import { useShopNavigation } from '../../hooks/useShopNavigation';
import { useNavigate } from 'react-router';
import { ROUTE_PATH } from '../../utilities/navigation';
import { Libraries, LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from '../../services/firebase';
import { CircularProgress } from '@mui/material';
import { ShopCheckoutProvider } from '../../context/shop/ShopCheckout';

/** The list of libraries used by the ShopCheckoutPage (for shipping/billing address) */
const GOOGLE_LIBRARIES: Libraries = ["places"];

export const ShopCheckoutPage = () => {
    const navigate = useNavigate();
    const { shopId, navigateToProduct } = useShopNavigation();

    const handleCheckout = (checkoutUrl: string) => {
        //go to the Stripe checkout url
        window.location.replace(checkoutUrl);
    }

    //TODO redirect
    const handleAuthRedirection = (method: "log-in" | "sign-up") => {
        navigate(ROUTE_PATH.authBuild(
            method === 'sign-up' ?
                'join'
                : method === 'log-in' ?
                    'login'
                    : "link"))
    }

    return (
        <ShopCheckoutProvider>
            <LoadScript
                googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                libraries={GOOGLE_LIBRARIES}
                loadingElement={<CircularProgress />}
                onLoad={() => console.log("loaded")}>
                <ShopCheckoutContainer shopId={shopId}
                    onRedirectTo={navigateToProduct}
                    onAuthClick={handleAuthRedirection}
                    onPayClick={handleCheckout} />
            </LoadScript>
        </ShopCheckoutProvider>
    )
}

import { ROUTE_SUBPATH } from "../../../utilities/navigation";

/** The listing edit tab ids (in order) */
const LISTING_EDIT_TAB_IDS = [
    ROUTE_SUBPATH.details,
    ROUTE_SUBPATH.pricing,
    ROUTE_SUBPATH.calendar,
    ROUTE_SUBPATH.guests,
    ROUTE_SUBPATH.cohosts,
] as const;

export type TListingEditTabId = typeof LISTING_EDIT_TAB_IDS[number];

type TListingEditTab = { id: TListingEditTabId, labelKey: string, descriptionKey?: string };

export const LISTING_EDIT_TABS: TListingEditTab[] = LISTING_EDIT_TAB_IDS.map((id, index) => ({
    id,
    labelKey: `listing.editTabs.${index}.title`,
    // descriptionKey: `listing.editTabs.${index}.content`,
}));

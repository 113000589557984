import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IssueStateView } from '../../../components/IssueStateView';
import { UserContext } from '../../../context/UserInformation';
import { EUserRole } from '../../../types/db';
import { UserSelectRoleContainer } from '../UserDashboardPage';

export const UserBookingsPage = () => {
    return <UserBookingsContainer />
}

const UserBookingsContainer = () => {
    const userContext = useContext(UserContext);

    if (!userContext) {
        console.warn('Need UserContext');
        return null;
    }
    if (!userContext.user) {
        console.warn('No user info');
        return null;
    }
    const userId = userContext.user?.firebaseUser?.uid;
    if (!userId) {
        console.warn('No user id');
        return null;
    }
    const userRole = userContext.user.role;
    if (userRole === true) {
        //role still loading
        return null;
    }

    if (!userRole) {
        return <UserSelectRoleContainer />
    }

    return <BookingsContainer userId={userId} userRole={userRole} />

}

interface IBookingsContainerProps {
    userId: string;
    userRole: EUserRole;
}
const BookingsContainer = ({ userId, userRole }: IBookingsContainerProps) => {
    const { t } = useTranslation();

    //TODO fetch the booking for the current user
    return <IssueStateView
        title={t('user.bookings.emptyTitle', { context: userRole.toLowerCase() })}
        description={t('user.bookings.emptyContent', { context: userRole.toLowerCase() })}
    />
}
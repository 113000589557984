import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ROUTE_QUERY } from '../../utilities/navigation';
import { ShopCheckoutSuccessContainer } from '../../containers/shop/ShopCheckoutContainer/ShopCheckoutSuccessContainer';
import { useShopNavigation } from '../../hooks/useShopNavigation';
import { ShopCheckout } from '../../components/shop/ShopCheckout';

export const ShopCheckoutSuccessPage = () => {
    const { navigateToShop } = useShopNavigation()
    const [searchParams,] = useSearchParams();

    const session_id = searchParams.get(ROUTE_QUERY.checkout_session_id) || undefined;

    if (!session_id) {
        return <ShopCheckout error />;
    }

    return <ShopCheckoutSuccessContainer
        sessionId={session_id}
        onContinueShoppingClick={navigateToShop} />;
}

import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFAQ } from '../../hooks';

export const FAQPage = () => {
    const { t } = useTranslation();
    const { faqs, links, sendQuestion } = useFAQ();
    const senderTextFieldRef = useRef<any>();
    const questionTextFieldRef = useRef<any>();

    const handleSubmit = () => {
        const sender = senderTextFieldRef.current?.value;
        const question = questionTextFieldRef.current?.value;
        if (!sender || sender === '' || !question || question === '') {
            alert("Please fill all fields before sending your question");
            return;
        }
        sendQuestion(question, sender);
    }
    return (
        <Stack direction={'column'} sx={{ m: 3 }}>
            <Typography variant={'h1'}>{t('faq.title')}</Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    {faqs.map(({ question, answer }, index) =>
                        <Accordion key={index}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}a-content`}
                                id={`panel${index}a-header`}>
                                <h3>{question}</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={"faq-answer"} dangerouslySetInnerHTML={{ __html: answer }} />
                            </AccordionDetails>
                        </Accordion>
                    )}
                </Grid>
                <Grid item xs={12} md={4}>
                    <div className="faq-extra" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant={'h3'}>{t('faq.relatedLinksTitle')}</Typography>
                        {links.map((link, index) => <Link key={`link_${index}`} to={link.path}>{link.label}</Link>)}
                        <Typography variant={'h5'} marginTop={1}>{t('faq.otherQuestionForm.title')}</Typography>
                        <Typography variant={'body1'}>{t('faq.otherQuestionForm.content')}</Typography>
                        <TextField
                            style={{ marginTop: 10 }}
                            inputRef={senderTextFieldRef}
                            label={t('faq.otherQuestionForm.inputContactLabel')}
                            rows={4}
                            placeholder={t('faq.otherQuestionForm.inputContactPlaceholder')}
                            variant="outlined"
                        />
                        <TextField
                            style={{ marginTop: 10, marginBottom: 10 }}
                            inputRef={questionTextFieldRef}
                            label={t('faq.otherQuestionForm.inputQuestionLabel')}
                            multiline
                            rows={4}
                            placeholder={t('faq.otherQuestionForm.inputQuestionPlaceholder')}
                            variant="outlined"
                        />
                        <Button variant="outlined" color="primary" onClick={handleSubmit}>{t('faq.otherQuestionForm.sendBtn')}</Button></div>
                </Grid>
            </Grid>
        </Stack>
    );
}
import { Download, Search } from '@mui/icons-material';
import { Stack, Typography, TextField, InputAdornment, Grid, Box, FormGroup, RadioGroup, FormControlLabel, Radio, Divider, Checkbox, Link as LinkMUI } from '@mui/material';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type TAssetCategory = "brand" | "hosts" | "listings" | "cohosts" | "guests" | "product";
export type TAssetType = "document" | "image" | "video";
export type TSortBy = "default" | "AZ" | "ZA";

interface INewsMediaAssetScreenProps {
    /** (optional) The search query string */
    search?: string;
    /** The input query from the UI */
    onSearch: (query: string) => void;
    /** (optional) The category selected, default to all */
    category?: TAssetCategory;
    /** Callback when a category is clicked */
    onCategoryClick: (category: TAssetCategory) => void;
    /** The sort by option */
    sortBy?: TSortBy;
    /** Callback when sortBy is chosen */
    onSortByClick: (sortBy: TSortBy) => void;
    /** The asset filter */
    filters: TAssetType[];
    /** Callback when filter changes */
    onFiltersChange: (filters: TAssetType[]) => void;

    /** The list of categories to show */
    categories: {
        image: string,
        title: string,
        category: TAssetCategory
    }[];
    /** The list of assets (filtered) */
    assets: {
        title: string,
        image: string,
        filePath: string,
        category: TAssetCategory,
        fileType: TAssetType,
    }[];

    /** Callback on footer click */
    onFooterClick: () => void;
}

/** The component which takes care of displaying the screen for the Media Asset (in the news feature) */
export const NewsMediaAssetScreen = ({ search, onSearch, category, onCategoryClick, sortBy, onSortByClick, filters, onFiltersChange, categories, assets, onFooterClick }: INewsMediaAssetScreenProps) => {
    const { t } = useTranslation();
    const [query, setQuery] = useState(search || '');

    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSearch(query)
        }
    }

    const handleSortByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSortByClick(event.target.value as TSortBy)
    };


    const handleFilterByType = (event: React.ChangeEvent<HTMLInputElement>) => {
        let arr = filters as TAssetType[];
        if (event.target.checked) {
            //add to the array
            arr.push(event.target.name as TAssetType)
        } else {
            //remove from the array
            arr = arr.filter(type => type !== event.target.name);
        }
        onFiltersChange(arr);
    }


    return (
        <Stack direction={'column'} spacing={1} p={2}>
            <Stack direction={{ xs: 'column', md: 'row' }}>
                <Stack sx={{ flex: 3 }} direction={'column'} spacing={1}>
                    <Typography variant='h1'>{t('news.mediaAssets.title', { context: !!category ? 'category' : !!search ? 'search' : 'none', category: !!category ? t('news.mediaAssets.categoryOption', { context: category }) : '' })}</Typography>
                    <Typography variant={!!search || !!category ? 'caption' : 'subtitle1'}>{t('news.mediaAssets.subTitle')}</Typography>
                </Stack>
                <TextField
                    sx={{ flex: 1 }}
                    placeholder={t('news.mediaAssets.searchPlaceholder')}
                    value={query}
                    onChange={(event) => setQuery(event.target.value)}
                    onKeyDown={onKeyDown}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }} />
            </Stack>
            {!!search &&
                <Typography>{t('news.mediaAssets.searchPlaceholder', { count: assets.length, search: search })}</Typography>
            }
            {!search && !category ?
                <Grid container>
                    {
                        categories.map(cat =>
                            <Grid item key={cat.category} xs={12} sm={6} md={4} onClick={() => onCategoryClick(cat.category)} p={2}>
                                <Stack direction={'column'} justifyContent={'space-between'} alignItems={'stretch'} spacing={1}>
                                    <Box component={'img'}
                                        src={cat.image}
                                        alt={cat.title}
                                        borderRadius={3} />
                                    <Typography variant='h4' alignSelf={'center'}>{cat.title}</Typography>
                                </Stack>
                            </Grid>
                        )
                    }
                </Grid>
                :
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Stack direction={'column'} spacing={1}>
                        <Typography>{t('news.mediaAssets.sortByTitle')}</Typography>
                        <FormGroup>
                            <RadioGroup value={sortBy || "default"} onChange={handleSortByChange}>
                                <FormControlLabel control={<Radio />} value={"default"} label={t('news.mediaAssets.sortByOption', { context: 'default' })} />
                                <FormControlLabel control={<Radio />} value={"AZ"} label={t('news.mediaAssets.sortByOption', { context: 'AZ' })} />
                                <FormControlLabel control={<Radio />} value={"ZA"} label={t('news.mediaAssets.sortByOption', { context: 'ZA' })} />
                            </RadioGroup>
                        </FormGroup>
                        <Divider />
                        <Typography>{t('news.mediaAssets.filterTitle')}</Typography>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox name={"document" as TAssetType}
                                    checked={filters.includes("document")}
                                    onChange={handleFilterByType} />}
                                label={t('news.mediaAssets.filterByOption', { context: 'document' })} />
                            <FormControlLabel control={
                                <Checkbox name={"image" as TAssetType}
                                    checked={filters.includes("image")}
                                    onChange={handleFilterByType} />}
                                label={t('news.mediaAssets.filterByOption', { context: 'image' })} />
                            <FormControlLabel control={
                                <Checkbox name={"video" as TAssetType}
                                    checked={filters.includes("video")}
                                    onChange={handleFilterByType} />}
                                label={t('news.mediaAssets.filterByOption', { context: 'video' })} />
                        </FormGroup>
                    </Stack>
                    <Divider orientation='vertical' flexItem />
                    <Grid container spacing={3}>
                        {
                            assets.map(asset =>
                                <Grid item key={asset.title} xs={12} sm={6} md={4}>
                                    <Stack direction={'column'} justifyContent={'space-between'} alignItems={'stretch'} spacing={1}>
                                        <Box component={'img'}
                                            src={asset.image}
                                            alt={asset.title} />
                                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                            <Typography>{asset.title}</Typography>
                                            <Link to={asset.filePath} target="_blank" download><Download /></Link>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            )
                        }
                    </Grid>
                </Stack>
            }
            <Typography variant='caption'>
                <Trans
                    i18nKey={'news.mediaAssets.footer'}
                    components={[
                        <LinkMUI color={'inherit'} onClick={onFooterClick} />
                    ]}
                />
            </Typography>
        </Stack>
    )
}
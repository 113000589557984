import { Divider, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo } from 'react';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router';
import { ChatThread, IChatThreadProps } from '../../components/chat/ChatThread';
import { ChatThreadMessageItem } from '../../components/chat/ChatThreadMessageItem';

import { ChatChannelContext, ChatChannelContextProvider } from '../../context/chat';
import { ChatThreadContext, ChatThreadContextProvider } from '../../context/chat/ChatThreadContext';
import { UserContext } from '../../context/UserInformation';
import { useTypingMembers } from '../../hooks/chat/useTypingMembers';
import { ChatMessage } from '../../types/db/chat';
import { getUIUserRoleFrom } from '../../utilities/EnumUtility';
import { ROUTE_PARAMS, ROUTE_PATH, ROUTE_SUBPATH } from '../../utilities/navigation';

/** The conversation within a thread */
export const ChatContentPage = () => {
    const navigate = useNavigate();
    const { [ROUTE_PARAMS.threadID]: threadID } = useParams();
    const location = useLocation();

    if (!threadID) {
        return null;
    }

    const navigateToChatDetails = () => {
        navigate(ROUTE_PATH.inboxBuild(threadID, true))
    }
    const navigateToInbox = () => {
        navigate(ROUTE_PATH.inboxBuild());
    }

    //to know if the details should be open or not
    const hasDetails = location.pathname.includes(ROUTE_SUBPATH.details);

    return (
        <ChatContentContainer detailsOpened={hasDetails}
            threadId={threadID}
            navigateToDetails={() => navigateToChatDetails()}
            closeThread={() => navigateToInbox()}>
            <Outlet />
        </ChatContentContainer>
    );
}

interface IChatContentContainerProps {
    threadId: string;
    detailsOpened: boolean;
    navigateToDetails: () => void;
    closeThread: () => void;
}

const ChatContentContainer = ({ threadId, detailsOpened, children, navigateToDetails, closeThread }: PropsWithChildren<IChatContentContainerProps>) => {
    const userContext = useContext(UserContext);

    if (!userContext) {
        throw new Error("Need UserContext");
    }

    const userId = userContext.user?.firebaseUser?.uid;
    if (!userId) {
        console.warn('No user id');
        return null;
    }

    return (
        <ChatThreadContextProvider userId={userId} channelId={threadId}>
            <ChatChannelContextProvider userId={userId} channelId={threadId}>
                {
                    !detailsOpened ?
                        <ChatThreadContainer userId={userId} threadId={threadId}
                            navigateToDetails={!detailsOpened ? navigateToDetails : undefined}
                            closeThread={closeThread} />
                        : <Stack direction={'row'}>
                            {/* Don't show side channels list for small screen */}
                            <Box flex={2} display={{ xs: 'none', sm: 'block' }}>
                                <ChatThreadContainer userId={userId} threadId={threadId}
                                    navigateToDetails={!detailsOpened ? navigateToDetails : undefined}
                                    closeThread={closeThread} />
                            </Box>
                            <Divider flexItem orientation='vertical' />
                            <Box flex={1}>
                                {children}
                            </Box>
                        </Stack>
                }
            </ChatChannelContextProvider>
        </ChatThreadContextProvider>
    );
}

interface IChatThreadContainerProps
    extends Pick<IChatContentContainerProps, 'threadId' | 'closeThread'>,
    Partial<Pick<IChatContentContainerProps, 'navigateToDetails'>> {
    userId: string;
}
const ChatThreadContainer = ({ userId, threadId: channelId, navigateToDetails, closeThread }: IChatThreadContainerProps) => {
    const chatThreadContext = useContext(ChatThreadContext);
    if (!chatThreadContext) {
        throw new Error("Need ChatThreadContext");
    }
    const chatDetailsContext = useContext(ChatChannelContext);
    if (!chatDetailsContext) {
        throw new Error("Need ChatChannelContext");
    }
    const { typingMembers, setCurrentUserTyping } = useTypingMembers(channelId)
    const { messages, create, markAsRead } = chatThreadContext;
    const { members } = chatDetailsContext;

    useEffect(() => {
        markAsRead();// mark the thread as read since user seeing the thread
    }, [markAsRead])

    const renderMessageItem = useCallback((message: (ChatMessage & { id: string })) =>
        <ChatThreadMessageItem
            {...{
                key: message.id,
                displayName: members[message.sender]?.displayName ?? '',
                timestamp: new Date(message.timestamp),
                message: message.message,
                avatar: {
                    alt: members[message.sender]?.displayName || '',
                    src: members[message.sender]?.avatar || ''
                },
                isSelf: message.sender === userId,
                userRole: getUIUserRoleFrom(members[message.sender]?.role),
            }}
        />, [members, userId])

    //use Memo for loop through members mapping
    const memberItems = useMemo(() =>
        Object.values(members).map(member => ({
            alt: member.displayName,
            src: member.avatar,
            role: getUIUserRoleFrom(member.role) || undefined,
        }))
        , [members]);

    //filter out current user and map to actual name
    const typingMembersName = useMemo(() =>
        typingMembers.filter(item => item !== userId).map(item => members[item]?.displayName)
        , [members, typingMembers, userId])

    const handleInputChange = useCallback((e) => {
        setCurrentUserTyping(e.target.value);
    }, [setCurrentUserTyping]);

    const props: IChatThreadProps = {
        chatHeader: {
            members: memberItems,
            onDetailsClick: navigateToDetails,
            onBackClick: closeThread,
        },
        chatThread: {
            items: messages,
            renderItem: renderMessageItem,
        },
        chatInput: {
            onSendClick: (message, _) => create(message).then(),
            onChange: handleInputChange,
            typingMembers: typingMembersName,
        },
    };

    return <ChatThread {...props} />;
}

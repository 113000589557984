import React from "react";
import { useParams } from "react-router";
import { ROUTE_PARAMS } from "../../../utilities/navigation";
import { ListingCalendarPage } from "./ListingCalendarPage";
import { ListingDetailsPage } from "./ListingDetailsPage";
import { ListingGuestsPage } from "./ListingGuestsPage";
import { ListingPricingPage } from "./ListingPricingPage";
import { ListingCohostPage } from "./ListingCohostsPage";
import { TListingEditTabId } from "./utils";

export const EditListingSubPage = () => {
    const { [ROUTE_PARAMS.settingsTabID]: currentTabId } = useParams();

    switch (currentTabId as TListingEditTabId) {
        case 'details':
            return <ListingDetailsPage />
        case 'pricing':
            return <ListingPricingPage />
        case 'calendar':
            return <ListingCalendarPage />
        case 'guests':
            return <ListingGuestsPage />
        case 'cohosts':
            return <ListingCohostPage />
        default:
            return null;
    }
}
import React from 'react';
import { TFunction } from 'i18next';
import { TravelExplore, Sell, ConnectWithoutContact, House, AirportShuttle, Balance, Coronavirus, Copyright, TipsAndUpdates, Web, Storefront, NewspaperRounded, Forum, MoreVert, Celebration } from '@mui/icons-material';
import { NewsAboutUsScreen } from '../../components/news/NewsAboutUsScreen';
import { useTranslation } from 'react-i18next';

//local at this time while getting into the database TODO put in database
const FACTS = (t: TFunction) => ([
    {
        title: '15',
        subtitle: t('news.facts.activeListings'),
    },
    {
        title: '500',
        subtitle: t('news.facts.guestStays'),
    },
    {
        title: '6',
        subtitle: t('news.facts.activeHosts'),
    },
    {
        title: `$${(2018 - 2014) * 2 * 12 + (2020 - 2018) * 3 * 12 + (2023 - 2020) * 4 * 12}k`,
        subtitle: t('news.facts.hostEarnings'),
    },
    {
        title: `$${(2023 - 2014) * 12 * 0.5}k`,
        subtitle: t('news.facts.cohostSaving'),
    },
    {
        title: `$${(2023 - 2014) * 12 * 1}k`,
        subtitle: t('news.facts.profitSharing'),
    },
]);

export const FOUNDERS = (t: TFunction) => (t('founders', { returnObjects: true, defaultValue: [] }) as Array<{
    name: string,
    title: string,
    image: string,
    responsibility1: string,
    responsibility2: string,
    responsibility3: string
}>);

const HISTORY: (t: TFunction) => {
    date: string | Date,
    image?: string,
    title: string,
    subTitle: string,
    icon: React.JSX.Element,
    color?:
    'inherit' | 'grey' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
}[] = (t: TFunction) => (t('news.histories', { returnObjects: true, defaultValue: [] }) as Array<{
    date: string,
    image?: string,
    title: string,
    subTitle: string,
    iconName: string,
    iconColor?: string
}>).map(history => {
    const mapIcon = (iconName: string) => {
        switch (iconName) {
            case "travel_explore":
                return <TravelExplore />;
            case "sell":
                return <Sell />
            case "connect_without_contact":
                return <ConnectWithoutContact />;
            case "house":
                return <House />;
            case "airport_shuttle":
                return <AirportShuttle />
            case "balance":
                return <Balance />
            case "coronavirus":
                return <Coronavirus />;
            case "copyright":
                return <Copyright />;
            case "tips_and_updates":
                return <TipsAndUpdates />;
            case "web":
                return <Web />;
            case "store_front":
                return <Storefront />;
            case "newspaper_rounded":
                return <NewspaperRounded />;
            case "forum":
                return <Forum />;
            case "celebration":
                return <Celebration />
            case "more_vert":
            default:
                return <MoreVert />;
        }
    }
    return {
        ...history,
        icon: mapIcon(history.iconName),
        color: history.iconColor as 'inherit' | 'grey' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    }
})

/** Container which takes care of providing information to the UI (currently just above, not in DB) */
export const NewsAboutContainer = () => {
    const { t } = useTranslation();
    return <NewsAboutUsScreen
        facts={FACTS(t)}
        founders={FOUNDERS(t)}
        history={HISTORY(t)}
    />
}
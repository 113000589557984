import React, { useContext, useState } from 'react';
import { Alert, Avatar, Button, Card, CardContent, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, Grid, Snackbar, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCohostOpenListingCheckboxCallback } from '../../../utilities/listings';
import { ListingCohostPricing } from './ListingPricingPage';
import { ListingCohostCalendar } from './ListingCalendarPage';
import { ListingEditContext } from '../../../context/Listing/ListingEditFlow';
import { getStringKeyForUserRole, ERole, getColorForUserRole } from '../../../utilities/EnumUtility';
import { PersonAdd } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { ROUTE_PATH } from '../../../utilities/navigation';
import { sendSupportTicket } from '../../../services/support';

/** The cohost page of the listing edit flow */
export const ListingCohostPage = () => {
    const navigate = useNavigate();

    const navigateToProfileDetails = (userId: string) => {
        if (!userId) return;
        navigate(ROUTE_PATH.userBuild(userId));
    }

    return <Container navigateToProfileDetails={navigateToProfileDetails} />
}

const Container = ({ navigateToProfileDetails }: { navigateToProfileDetails: (userId: string) => void }) => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { isLoading } = listingEditContext;
    if (isLoading) {
        return <CircularProgress />
    }

    return (
        <Stack direction={'column'} spacing={1}>
            <ListingCohosts navigateToProfile={navigateToProfileDetails} />
            <Divider />
            <ListingCohostEligibility />
            <Divider />
            <SubContainer />
        </Stack>
    );
}

const SubContainer = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }

    const { listing: { cohost_perk } } = listingEditContext;

    if (!cohost_perk?.open) {
        return null;
    }
    return <>
        <ListingCohostPricing checkboxControl={false} title={'Pricing'} />
        <Divider />
        <ListingCohostCalendar title={'Calendar'} />
        <Divider />
        <ListingHouseManual />
    </>
}

const ListingCohostEligibility = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }

    const { listing: { cohost_perk }, setActivePageInfo, handleCancel, handleSave } = listingEditContext;

    const { t } = useTranslation();

    const cohostChecked = !!cohost_perk && cohost_perk.open;
    const handleCohostCheckChange = useCohostOpenListingCheckboxCallback(setActivePageInfo);

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    return (
        <>
            <FormControl>
                <FormControlLabel control={
                    <Checkbox
                        checked={cohostChecked}
                        onChange={(_, checked) => { handleCohostCheckChange(checked); setSnackbarOpen(true); }} />}
                    label={t('listing.creation.openToCoHost')} />
            </FormControl>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => { setSnackbarOpen(false); handleSave(); }}
            >
                <Alert
                    severity="success"
                    variant="filled"
                    action={<Button color='inherit' size='small' onClick={() => { handleCancel(); setSnackbarOpen(false); }}>{t('undo')}</Button>}
                >
                    {t('saved')}
                </Alert>
            </Snackbar >
        </>
    );
}
const ListingHouseManual = () => {
    return null;
}

interface IListingCohostsProps {
    navigateToProfile: (userId: string) => void;
}
/** The list of host and cohost for this listing */
const ListingCohosts = ({ navigateToProfile }: IListingCohostsProps) => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }

    const { listing: { cohost, host, ...listing } } = listingEditContext;

    const { t } = useTranslation();

    const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

    return (
        <>
            <Grid container spacing={2} p={2}>
                {
                    host &&
                    <Grid item xs={12} sm={6}>
                        <CurrentUserCard
                            role={ERole.HOST}
                            avatar={host?.avatar_url}
                            displayName={host.display_name}
                            onClick={() => navigateToProfile(host.id || '')}
                        />
                    </Grid>
                }
                {
                    cohost ?
                        <Grid item xs={12} sm={6}>
                            <CurrentUserCard
                                role={ERole.COHOST}
                                avatar={cohost?.avatar_url}
                                displayName={cohost?.display_name}
                                onClick={() => navigateToProfile(cohost?.id || '')}
                            />
                        </Grid>
                        :
                        <Grid item xs={12} sm={6}>
                            <CurrentUserCard
                                role={ERole.COHOST}
                                onClick={() => setInviteDialogOpen(true)}
                            />
                        </Grid>
                }
            </Grid>
            <Dialog
                open={inviteDialogOpen}
                onClose={() => setInviteDialogOpen(false)}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        const email = formJson.email;
                        sendSupportTicket({
                            feature: 'CoHost_invite',
                            type: 'contact',
                            user_sender: host?.id || 'Host',
                            doc_ref: listing.ref,
                            doc_id: listing.id,
                            error_message: 'CoHost invite',
                            extra: {
                                email
                            }
                        })
                        setInviteDialogOpen(false);
                    },
                }}
            >
                <DialogTitle>{t('listing.cohost.inviteTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('listing.cohost.inviteMessage')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="email"
                        label={t('listing.cohost.inviteEmailLabel')}
                        placeholder={t('listing.cohost.inviteEmailPlaceholder')}
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setInviteDialogOpen(false)}>{t('cancel')}</Button>
                    <Button type="submit">{t('send')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

interface ICurrentUserCardProps {
    role: ERole;
    avatar?: string;
    displayName?: string;
    onClick: () => void;
}
const CurrentUserCard = ({ role, avatar, displayName, onClick }: ICurrentUserCardProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const bgColor = getColorForUserRole(role);
    const textColor = theme.palette.getContrastText(bgColor);

    return (
        <Card sx={{ height: '100%' }} onClick={onClick}>
            <CardContent>
                <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Typography bgcolor={bgColor} color={textColor} m={2} borderRadius={2} p={1} fontWeight={'bold'} textAlign={'center'}>
                        {t(getStringKeyForUserRole(role))}
                    </Typography>
                    {(!avatar && !displayName) ?//if no info, adding role
                        <>
                            <PersonAdd fontSize='large' />
                            <Typography fontWeight={'bold'}>{t('listing.cohost.inviteTitle')}</Typography>
                        </>
                        :
                        <>
                            <Avatar variant={'circular'}
                                src={avatar}
                                alt={displayName} />
                            <Typography>{displayName}</Typography>
                        </>
                    }

                </Stack>
            </CardContent>
        </Card>
    )
}
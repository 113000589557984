
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LISTING_EDIT_TABS } from "../../pages/listings/edit/utils";
import { DeepPartial } from "../../types/common";
import { Listing, WithID, WithRef } from "../../types/db";
import { ROUTE_SUBPATH } from "../../utilities/navigation";
import { ListingContext } from "./ListingContext";

interface IListingEditTab {
    /** The id of the step, can be used for key in UI and for navigation */
    id: string;
    /** The title of the step */
    label: string;
    /** The description of the step */
    // description: string;
    /** Boolean indicating if the step is optional or not */
    optional?: boolean;
    /** Error message for the step if something went wrong */
    error?: string | boolean;
}

type LocalListing = Partial<WithRef<WithID<DeepPartial<Listing>, string>>>;

interface IListingEditContext {
    /** The tabs information */
    tabs: IListingEditTab[];
    /** The current active tab id */
    activeTab: string;
    /** Boolean indicating that an action is being executed (allow to indicate a loading state on the save button for example) */
    isLoading?: boolean;
    /** The current temporary listing information entered by the user (only locally changing on the fly) */
    listing: LocalListing;
    /** Boolean indicating if the current action is completed */
    completed: boolean;
    /** Set the information of the local listing and if the action is completed */
    setActivePageInfo: (listing: Omit<LocalListing, 'id' | 'ref'> | undefined | ((curListingInfo: LocalListing) => LocalListing), completed?: boolean) => void;
    /** Handle the saving of the new information, optional listing info overwrite of local */
    handleSave: (listing?: LocalListing) => Promise<void>;
    /** Handle the cancel of the new information (optional field name if know which one exactly, otherwise rest all) */
    handleCancel: (fieldName?: keyof LocalListing) => void;
}

export const ListingEditContext = createContext<IListingEditContext | null>(null);

interface IListingEditProviderProps {
    /** The id of the current tab */
    tabId?: string;
}

export const ListingEditProvider = ({
    children,
    tabId,
}: PropsWithChildren<IListingEditProviderProps>) => {
    const listingEditContext = useContext(ListingContext);
    if (!listingEditContext) {
        throw new Error('Need ListingContext');
    }
    const { update, listing, isLoading } = listingEditContext;

    const { t } = useTranslation();
    // initialization of the steps
    const [tabs, setTabs] = useState<IListingEditTab[]>(LISTING_EDIT_TABS.map(s => ({
        id: s.id,
        label: t(s.labelKey),
        // description: s.descriptionKey ? t(s.descriptionKey) : '',
    })));

    // initialization of the current step if current stepId provided look for the index
    const activeTab = tabId || ROUTE_SUBPATH.details;

    // Initialization of the local information given by the user (we don't want to update the DB every touch, will update during handleSave)
    const [localListing, setLocalInfo] = useState<LocalListing>(listing || {});

    // If the DB listing has changed, we want to make sure the local listing is updated as well
    useEffect(() => {
        // console.log('listing has changed in DB: update local')
        setLocalInfo(listing || {})
    }, [listing])

    // Initialization of the completion of the current step
    const [completed, setCompleted] = useState<boolean>(false);

    // Handle the action done when user press the save button (save in db)
    const handleSave = useCallback((listing?: LocalListing) => {
        //save the information to the DB
        return update({
            ...localListing,
            ...listing,
            status: "in-review",//going back to in-review if anything change in the listing
        })
            .catch(() => {
                //error while updating the new listing
                //mark step as error
                setTabs(curSteps => curSteps.map((s) => s.id === activeTab ? { ...s, error: true } : s))
            });
    }, [activeTab, localListing, update]);

    //handle the action done when user press the cancel button (reset to origin)
    const handleCancel = useCallback((fieldName?: keyof LocalListing) => {
        if (!fieldName) {
            //reset all
            setLocalInfo(listing || {});
        } else {
            setLocalInfo(curLocListing => ({
                ...curLocListing,
                [fieldName]: listing?.[fieldName],
            }))
        }
    }, [listing])

    // Handle the change to the local listing information
    const handleActivePageInfo = useCallback((
        listingInfo: Omit<LocalListing, 'id'> | undefined | ((curListingInfo: LocalListing) => LocalListing),
        completed?: boolean
    ) => {
        // console.log('setActivePageInfo', listingInfo, completed)
        //if listing deliberately undefined, reset the data, otherwise just merge it
        setLocalInfo(curLocalInfo => {
            if (listingInfo === undefined) {
                //reset the local info
                return {};
            }
            if (typeof listingInfo === 'function') {
                return listingInfo(curLocalInfo);
            }
            const keys = Object.keys(listingInfo) as any[];
            if (keys.length === 0) {
                //if nothing to change, just return the current local info as is
                return curLocalInfo;
            }

            return { ...curLocalInfo, ...listingInfo };
        });
        //if completed not defined keep as before, otherwise overwrite
        setCompleted(curCompleted => completed === undefined ? curCompleted : completed);
    }, [])

    return (
        <ListingEditContext.Provider value={{
            tabs,
            activeTab,
            handleSave,
            handleCancel,
            completed,
            setActivePageInfo: handleActivePageInfo,
            listing: localListing,
            isLoading: isLoading //from the listing context
        }
        }>
            {children}
        </ListingEditContext.Provider>
    );
};

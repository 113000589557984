import React from "react";
import { NavigateFunction } from "react-router";
import { HomeScreen } from "../../components/HomeScreen/HomeScreen";
import { buildPath, ROUTE_PATH } from "../../utilities/navigation";
import { useGetCategorySectionProps } from "../CategoryContainer/CategoryContainer";

interface IHomeContainerProps {
    navigate: NavigateFunction;
}

export const HomeContainer = ({ navigate }: IHomeContainerProps) => {
    //all data comes from the locales
    const host = useGetCategorySectionProps('host')
    const cohost = useGetCategorySectionProps('cohost')
    const guest = useGetCategorySectionProps('guest')

    return <HomeScreen
        onExploreClick={() => navigate(buildPath(ROUTE_PATH.search))}
        onJoinClick={() => navigate(ROUTE_PATH.authBuild(ROUTE_PATH.auth_join))}
        categories={[
            host,
            cohost,
            guest
        ]}
    />
}

import React from 'react';
import { ShoppingCart } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import { useUserCart } from '../../context/shop';
import { DEFAULT_SHOP_ID } from '../shop';
import { useRemoteConfig } from '../../services/remoteConfig';
import { useShopNavigation } from '../../hooks/useShopNavigation';
import { RC_SHOP } from '../../types/remote_config';
import { getColorFrom } from '../theme/ColorUtils';
import { ERole } from '../EnumUtility';

export const UserCart = () => {
    const { shopId, navigateToCart } = useShopNavigation();
    const userCart = useUserCart(shopId || DEFAULT_SHOP_ID);

    //extra protection (even if in the router already)
    const {
        [RC_SHOP]: remoteConfigShop,
    } = useRemoteConfig(RC_SHOP);

    if (!remoteConfigShop.enabled) {
        return null;
    }

    if (!userCart) {
        //no cart information, 
        console.warn("No cart information, check the CartContext Provider is present");
        return null;
    }

    return (
        <IconButton aria-label="cart" onClick={navigateToCart}>
            <Badge badgeContent={userCart.cartItems.length ?? 0} sx={{
                "& .MuiBadge-badge": {
                    color: "white",
                    backgroundColor: getColorFrom(ERole.GUEST)
                }
            }}>
                <ShoppingCart style={{ color: 'white' }} />
            </Badge>
        </IconButton>
    );
}
import React, { PropsWithChildren } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { getShopInfo } from '../../../services/shop';
import { ShopDonationBannerContainer } from '../ShopDonationBannerContainer';
import { DEFAULT_SHOP_ID } from '../../../utilities/shop';
import { IssueStateView } from '../../../components/IssueStateView';
import { useTranslation } from 'react-i18next';

export interface IShopContainerProps {
    /** The DB id of the shop */
    shopId?: string;
}

export const ShopContainer = ({
    shopId = DEFAULT_SHOP_ID,
    children
}: PropsWithChildren<IShopContainerProps>) => {
    const { t } = useTranslation();
    const { data, isLoading, isError } = useQuery(["shop", shopId], () => getShopInfo(shopId));

    if (isLoading) {
        return <Box sx={{ display: 'flex', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
        </Box>
    }

    if (isError) {
        return <IssueStateView
            title={t('shop.errorTitle')}
            description={t('shop.errorContent')}
        />;
    }

    if (!data) {
        // return <Typography>{`No shop with this id: ${shopId}`}</Typography>
        return <IssueStateView
            title={t('shop.errorTitle')}
            description={t('shop.errorContent')}
        />;
    }

    // if (!data.is_live || data.is_temp) {
    //     return <IssueStateView
    //         title={t('shop.pendingTitle')}
    //         description={t('shop.pendingContent')}
    //     />
    // }

    return (
        <Box sx={{ width: '100%' }}>
            <Stack sx={{ padding: 2 }} direction={'column'} spacing={1}>
                {
                    !!data.donation_enabled &&
                    <ShopDonationBannerContainer />
                }
                <Typography variant='h3'>{t('shop.header', { name: data.name })}</Typography>
                {!!data.description && <Typography>{data.description}</Typography>}
                {children}
            </Stack>
        </Box>
    );
}
/** The remote config for the help center */
export const RC_HELP = "help";

export type Help = {
    /** Boolean indicating if the help center is enabled */
    enabled: boolean;
}

export const DEFAULT_HELP: Help = {
    "enabled": false
}
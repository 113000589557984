import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, AlertColor, Snackbar } from '@mui/material';

interface ICurrentLocationButtonProps {
    /** The label to display on the button */
    label: String;
    /** Callback when current location fetched */
    onCurrentLocation: (args: { lat: number, lng: number }) => void;
}

export const CurrentLocationButton = ({ label, onCurrentLocation }: ICurrentLocationButtonProps) => {
    const [loading, setLoading] = useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState<{ message: string, severity: AlertColor } | null>(null);

    const handleCloseSnackbar = () => {
        setSnackbarInfo(null);
    }

    return (
        <>
            <LoadingButton loading={loading} variant={"contained"} onClick={() => {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    setLoading(true);
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setLoading(false);
                            const pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };

                            setSnackbarInfo({
                                message: "Location Found.",
                                severity: "success",
                            });
                            onCurrentLocation(pos);
                        },
                        (e) => {
                            setLoading(false);
                            setSnackbarInfo({
                                message: "Error: The Geolocation service failed.",
                                severity: "error",
                            });
                        }
                    );
                } else {
                    // Browser doesn't support Geolocation
                    setSnackbarInfo({
                        message: "Error: Your browser doesn't support geolocation.",
                        severity: "error",
                    });
                }
            }}>{label}</LoadingButton>
            <Snackbar open={!!snackbarInfo} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert severity={snackbarInfo?.severity} sx={{ width: '100%' }}>
                    {snackbarInfo?.message ?? ''}
                </Alert>
            </Snackbar>
        </>
    );
}
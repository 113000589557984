import { Grid, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListingItem } from '../../../components/listing/ListingItem';
import { IListingItemProps } from '../../../components/listing/ListingItem/ListingItem';
import { ListingCreationFlowContext } from '../../../context/Listing';
import { UserContext } from '../../../context/UserInformation';
import { DEFAULT_CURRENCY } from '../../../utilities/currency/function';
import { ROUTE_PATH } from '../../../utilities/navigation';

/** The first page of the listing creation flow */
export const ListingPublishPage = () => {
    return <Container openPreview={(listingId) => window.open(ROUTE_PATH.listingBuild(listingId, true), '_blank')} />
}

interface IContainerProps {
    /** Callback Navigate to a preview of the listing */
    openPreview: (listingId: string) => void;
}
const Container = ({ openPreview }: IContainerProps) => {
    const listingCreationFlowContext = useContext(ListingCreationFlowContext);
    if (!listingCreationFlowContext) {
        throw new Error('Need listingCreationFlowContext')
    }

    const userContext = useContext(UserContext);
    if (!userContext) {
        console.warn('no user context, can go without it, but might fail at publishing listing')
    }

    const { listing, isLoading, setActivePageInfo } = listingCreationFlowContext;

    useEffect(() => {
        setActivePageInfo({}, !isLoading)
    }, [isLoading, setActivePageInfo])

    if (isLoading) {
        return <Typography>Loading...</Typography>
    }

    if (!listing.id) {
        return <Typography>No listing</Typography>
    }

    return <View title={listing.title || ''}
        shortDescription={listing.short_description}
        price={{
            amount: listing.pricing?.nightly_price?.amount || 0,
            currencyCode: listing.pricing?.nightly_price?.currency || DEFAULT_CURRENCY
        }}
        images={listing.main_photo?.url}
        rating={{ numberReview: 0, rate: 0 }}
        onClick={() => openPreview(listing.id || '')}
        hostAvatar={userContext?.user?.avatarUrl || ''} />
}

interface IViewProps extends IListingItemProps {

}

const View = (props: IViewProps) => {
    const { t } = useTranslation();
    return (
        <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={12} sm={6}>
                <ListingItem {...props} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Stack direction={'column'} spacing={1}>
                    <Typography variant='h4'>{t('listing.creation.publishTitle')}</Typography>
                    {
                        new Array(4).fill({}).map((_, index) =>
                            <Typography key={index}>{`- ${t(`listing.creation.publishContent${index + 1}`, { default: '' })}`}</Typography>
                        )
                    }
                    {/* {
                        ((t('listing.creation.publishPoints', { returnObjects: true, defaultValue: [] })) as Array<string>)
                            .map((point, index) =>
                                <Typography key={index}>{`- ${point}`}</Typography>
                            )
                    } */}
                </Stack>
            </Grid>
        </Grid>
    )
}
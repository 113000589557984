import React, { createContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import { auth, db, rtdb } from "../../services/firebase";
import { EUserRole, User, UserPrivatePreferences, USERS_COLLECTION, USER_PRIVATE_COLLECTION, USER_PRIVATE_PREFERENCES_DOCUMENT } from "../../types/db";
import { ChatUserStatus, CHAT_USERS, CHAT_USERS_STATUS } from "../../types/db/chat";
import { DEFAULT_CURRENCY } from "../../utilities/currency/function";
import { DEFAULT_LANGUAGE } from "../../utilities/i18n/config";

interface IUserInformation {
  /** The full firebase user information */
  firebaseUser?: firebase.User | null;
  /** The user's display name */
  displayName: string;
  /** The user avatar url */
  avatarUrl: string;
  /** (Optional) The user's preferred role (useful to know what UI to show) (true if loading) */
  role?: EUserRole | true;
  /** The user's preferred language (used to display interface in this language) */
  language: string;
  /** The user's preferred currency (user to display price in this currency) */
  currency: string;
}

interface IUserContext {
  user: IUserInformation | null;
  isUserLoggedIn(): boolean;
  isUserAnonymous(): boolean;
  logOut(): void;
  loading: boolean;
}

export const UserContext = createContext<IUserContext | undefined>(undefined);

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IUserInformation & { loading: boolean }>({
    displayName: "",
    avatarUrl: '',
    language: 'en',
    currency: 'CAD',
    role: true,
    loading: true,
  });

  useEffect(() => {
    //onAuthStateChanged
    return auth.onAuthStateChanged((user) => {
      // console.log('onAuthStateChanged', user)
      setUser(currentUser => ({
        ...currentUser,
        firebaseUser: user,
        displayName: user?.displayName ?? "",
        avatarUrl: auth.currentUser?.photoURL ?? "",
        loading: !user,
      }));
    });
  }, []);

  useEffect(() => {
    //subscribing to db changes
    const uid = user.firebaseUser?.uid;
    if (!uid) return;
    const publicInfoSub = db.collection(USERS_COLLECTION).doc(uid)
      .onSnapshot((snap) => {
        const userSnap = snap.data() as User;
        if (!userSnap) return;//no information returning
        setUser(user => ({
          ...user,
          avatarUrl: userSnap.avatar_url,
          displayName: userSnap.display_name,
          description: userSnap.description || '',
        }));
      });
    const prefInfoSub = db.collection(USERS_COLLECTION).doc(uid)
      .collection(USER_PRIVATE_COLLECTION).doc(USER_PRIVATE_PREFERENCES_DOCUMENT)
      .onSnapshot((snap) => {
        const userSnap = snap.data() as UserPrivatePreferences;
        setUser(user => ({
          ...user,
          language: userSnap?.language || DEFAULT_LANGUAGE,
          currency: userSnap?.currency || DEFAULT_CURRENCY,
          role: userSnap?.role || undefined,
        }));
      });
    return () => {
      //unsubscribe to listeners
      publicInfoSub();
      prefInfoSub();
    }
  }, [user.firebaseUser?.uid]);


  //Presence (for chat feature)
  useEffect(() => {
    // Fetch the current user's ID from Firebase Authentication.
    var uid = user.firebaseUser?.uid;
    if (!uid || user.firebaseUser?.isAnonymous) {
      //if not connected or the user is anonymous don't update the presence
      return;
    }

    // Create a reference to this user's specific status node.
    // This is where we will store data about being online/offline.
    var userStatusDatabaseRef = rtdb.ref(CHAT_USERS)
      .child(uid)
      .child(CHAT_USERS_STATUS);

    // We'll create two constants which we will write to 
    // the Realtime database when this device is offline
    // or online.
    const isOfflineForDatabase: ChatUserStatus = {
      state: 'offline',
      lastChanged: firebase.database.ServerValue.TIMESTAMP,
    };

    const isOnlineForDatabase: ChatUserStatus = {
      state: 'online',
      lastChanged: firebase.database.ServerValue.TIMESTAMP,
    };

    // Create a reference to the special '.info/connected' path in 
    // Realtime Database. This path returns `true` when connected
    // and `false` when disconnected.
    const realtimeConnectionRef = rtdb.ref('.info/connected');
    realtimeConnectionRef.on('value', function (snapshot) {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() === false) {
        return;
      };

      // If we are currently connected, then use the 'onDisconnect()' 
      // method to add a set which will only trigger once this 
      // client has disconnected by closing the app, 
      // losing internet, or any other means.
      userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
        // The promise returned from .onDisconnect().set() will
        // resolve as soon as the server acknowledges the onDisconnect() 
        // request, NOT once we've actually disconnected:
        // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

        // We can now safely set ourselves as 'online' knowing that the
        // server will mark us as offline once we lose connection.
        userStatusDatabaseRef.set(isOnlineForDatabase);
      });
    });

    return () => realtimeConnectionRef.off('value');
  }, [user.firebaseUser?.isAnonymous, user.firebaseUser?.uid]);

  const isUserLoggedIn = () => {
    return !!user?.firebaseUser;
  };

  const isUserAnonymous = () => {
    return isUserLoggedIn() && !!user.firebaseUser?.isAnonymous;
  }

  const logOut = () => {
    auth.signOut();
  };

  return (
    <UserContext.Provider value={{ user, isUserLoggedIn, isUserAnonymous, logOut, loading: user.loading }}>
      {children}
    </UserContext.Provider>
  );
};

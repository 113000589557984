import { List, Stack, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PropsWithErrorState, PropsWithLoadingState } from '../../../utilities';
import { ChatThreadMessageItem, IChatThreadMessageItemProps } from '../ChatThreadMessageItem';


export interface IChatThreadMessagesProps<T> {
    /** The list of message in the thread */
    items: T[];
    /** The function to render the items */
    renderItem: (item: T) => ReactElement<IChatThreadMessageItemProps>;
}

/** The scroll view of all the message in a thread */
export const ChatThreadMessages = <T extends any>({ items, renderItem, error, loading }: PropsWithLoadingState<PropsWithErrorState<IChatThreadMessagesProps<T>>>) => {
    const { t } = useTranslation();
    if (!!error) {
        return (
            <Stack>
                <Typography variant='h5'>{t('chat.thread.errorTitle')}</Typography>
                <Typography>{t('chat.thread.errorContent')}</Typography>
            </Stack>
        );
    }
    if (!!loading) {
        return (
            <Stack direction={'column-reverse'} justifyContent={'flex-end'} alignItems={'stretch'} spacing={2}>
                {
                    new Array(5).fill({}).map((_, index) => <ChatThreadMessageItem key={`message-loading-${index}`} isSelf={!!(index % 2)} loading />)
                }
            </Stack>
        )
    }
    return (
        <List>
            {
                items?.map((item) => renderItem?.(item))
            }
        </List>
    )
}
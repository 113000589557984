/** The default base url to the public local image folder */
const DEFAULT_IMAGE_BASE_URL = `${process.env.PUBLIC_URL}/img/`;

/** Logo color */
export const LOGO_COLOR_URL = `${process.env.PUBLIC_URL}/img/logo192.png`;
/** Logo black on transparent */
export const LOGO_BLACK = `${process.env.PUBLIC_URL}/img/logoBW100.png`;
/** Logo white on transparent */
export const LOGO_WHITE = `${process.env.PUBLIC_URL}/img/logoW.png`;
/** Logo white bold on transparent */
export const LOGO_WHITE_BOLD = `${process.env.PUBLIC_URL}/img/logoWB.png`;

/** Banner image for remote work */
export const BANNER_REMOTE_WORK = `${process.env.PUBLIC_URL}/img/remote_work_banner.jpg`;
/** Banner image for career university */
export const BANNER_UNIVERSITY = `${process.env.PUBLIC_URL}/img/careers_university.jpg`;
/** Banner image for the Home Page (TODO might need to change it to AI generated image) */
export const BANNER_HOME_PAGE = "https://media.cntraveler.com/photos/5db1d0dd11c1e500092e7133/master/pass/airbnb-ski-aspen-28328347.jpg";
/** Banner image for the Forum Main Page (TODO change it) */
export const BANNER_FORUM_MAIN_PAGE = "https://media.cntraveler.com/photos/5db1d0dd11c1e500092e7133/master/pass/airbnb-ski-aspen-28328347.jpg";
/** Banner image for the Forum Community Page (TODO change it) */
export const BANNER_FORUM_COMMUNITY_PAGE = "https://media.cntraveler.com/photos/5db1d0dd11c1e500092e7133/master/pass/airbnb-ski-aspen-28328347.jpg";
/** Banner image for the Forum Host Page (TODO change it) */
export const BANNER_FORUM_HOST_PAGE = "https://media.cntraveler.com/photos/5db1d0dd11c1e500092e7133/master/pass/airbnb-ski-aspen-28328347.jpg";
/** Banner image for the Forum CoHost Page (TODO change it) */
export const BANNER_FORUM_COHOST_PAGE = "https://media.cntraveler.com/photos/5db1d0dd11c1e500092e7133/master/pass/airbnb-ski-aspen-28328347.jpg";
/** Banner image for the Forum Guest Page (TODO change it) */
export const BANNER_FORUM_GUEST_PAGE = "https://media.cntraveler.com/photos/5db1d0dd11c1e500092e7133/master/pass/airbnb-ski-aspen-28328347.jpg";

/** Default listing image placeholder if non are provided */
export const DEFAULT_LISTING_IMAGE_URL = `${DEFAULT_IMAGE_BASE_URL}/listing_image_placeholder.jpeg`;//'https://firebasestorage.googleapis.com/v0/b/co-host-op.appspot.com/o/placeholders%2Flisting_placeholder.jpeg?alt=media&token=d78e007a-eff0-4a77-ab4a-61bc9ac82b96';
/** Image for listing empty/error result */
export const LISTING_NOT_FOUND_URL = `${DEFAULT_IMAGE_BASE_URL}/listing_not_found.png`;

/** Image for user not found */
export const PROFILE_NOT_FOUND_URL = `${DEFAULT_IMAGE_BASE_URL}/user_not_found.png`;

/** Image for the shop error result */
export const SHOP_PRODUCT_ERROR_URL = `${DEFAULT_IMAGE_BASE_URL}/shop_product_error.png`;
/** Image for the shop empty result */
export const SHOP_PRODUCT_EMPTY_URL = `${DEFAULT_IMAGE_BASE_URL}/shop_product_empty.png`;

/** Default image for help item */
export const HELP_CENTER_IMAGE_URL = `${DEFAULT_IMAGE_BASE_URL}/help_support.jpeg`;

/** Image for the user role selection */
export const USER_SELECTION_URL = `${DEFAULT_IMAGE_BASE_URL}/user_type_selection.png`;

import { Box, Rating, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IShopReviewsSummaryProps {
    /** The maximum rating, default to 5 */
    maxRating?: number;
    /** The step of the rating, default to 1 */
    step?: number;
    /** The number of ratings per step */
    numRatings: {
        /** The step this rating relate to */
        step: number,
        /** The number of rating for this step */
        ratings: number,
    }[];
}

export const ShopReviewsSummary = ({
    maxRating = 5,
    step = 1,
    numRatings,
}: IShopReviewsSummaryProps) => {
    const { t } = useTranslation();
    //calculate the sum of ratings and the number of ratings
    const [sum, totRatings] = numRatings.reduce(
        (prev, curr) => [prev[0] + curr.ratings * curr.step, prev[1] + curr.ratings],
        [0, 0]);
    const average = sum / totRatings;

    return (
        <Tooltip title={
            <Box>
                <Stack direction={'column'} justifyContent={'start'} alignItems={'start'}>
                    <Stack direction={'row'}
                        justifyContent={'start'}
                        alignItems={'center'}
                        spacing={1}>
                        <Rating
                            value={average}
                            readOnly
                            max={maxRating}
                            precision={step} />
                        <Typography>{t('shop.reviews.ratingSummary', { averageRating: average.toFixed(1), maxRating: maxRating })}</Typography>
                    </Stack>
                    <Typography>{t('shop.reviews.numberRatingSummary', { numRating: totRatings })}</Typography>
                    {
                        numRatings.sort((a, b) => -(a.step - b.step))//decreasing order
                            .map((rating, index) =>
                                <Stack key={`rating-${index}`}
                                    direction={'row'}
                                    justifyContent={'start'}
                                    alignItems={'center'}
                                    spacing={1}>
                                    <Rating
                                        value={rating.step}
                                        readOnly
                                        max={maxRating}
                                        precision={step} />
                                    <Typography>{t('shop.reviews.ratingItemSummary', {
                                        numRating: rating.ratings,
                                        percentageRating: ((rating.ratings * 100) / totRatings).toFixed(1)
                                    })}</Typography>
                                </Stack>
                            )
                    }
                </Stack>
            </Box>
        }>
            {/* This Box is necessary for the Tooltip to work */}
            <Box display="inline-block">
                <Rating
                    value={average}
                    readOnly
                    max={maxRating}
                    precision={step} />
            </Box>
        </Tooltip>
    )
}
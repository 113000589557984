import React from 'react';
import { useTranslation } from 'react-i18next';
import { Info } from '@mui/icons-material';
import { Card, Stack, Typography, Tooltip } from '@mui/material';
import { ListingType } from '../../../types/db';
import { getListingInfoFromType } from '../../../utilities/listings';

export interface IListingTypeCardProps {
    /** The type of the listing (using same as db for simplicity) */
    type: ListingType;//for type simplification using the same as DB
    /** Boolean indicating if this is selected @default false */
    selected?: boolean;
    /** Callback on card click */
    onClick: () => void;
}

/** The UI of a card representing the listing type given */
export const ListingTypeCard = ({ type, selected, onClick }: IListingTypeCardProps) => {
    const { t } = useTranslation();

    const typeInfo = getListingInfoFromType(type, t, { height: 150, width: 150 })
    return (
        <Card variant={selected ? 'outlined' : 'elevation'} onClick={onClick} sx={{ p: 2, position: 'relative', ...(selected && { border: 2, borderColor: 'blue' }) }}>
            <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
                <Typography variant={'h4'} textAlign={'center'}>{typeInfo.title}</Typography>
                {typeInfo.icon}
                <Typography textAlign={'center'}>{typeInfo.description}</Typography>
            </Stack>
            <Tooltip title={typeInfo.example}>
                <Info sx={{ position: 'absolute', right: 2, top: 2 }} color={'secondary'} />
            </Tooltip>
        </Card>
    )
}
import { TFunction } from 'i18next';

export type ZoneKey =
  | 'COUNTY'
  | 'EMIRATE'
  | 'GOVERNORATE'
  | 'PREFECTURE'
  | 'PROVINCE'
  | 'REGION'
  | 'STATE_AND_TERRITORY'
  | 'STATE';

export type ContinentCode =
  | 'AF' //"Africa",
  | 'AN' // "Antarctica",
  | 'AS' // "Asia",
  | 'CA' //"Central america",
  | 'EU' //"Europe",
  | 'NA' //"North america",
  | 'OC' //"Oceania",
  | 'SA' //"South america"
  | 'O'; //Other

export enum FieldName {
  FirstName = 'firstName',
  LastName = 'lastName',
  Country = 'country',
  City = 'city',
  PostalCode = 'zip',
  Zone = 'zone', //'province',
  Address1 = 'address1',
  Address2 = 'address2',
  Phone = 'phone',
  Company = 'company',
  Email = 'email'
}

// export type FieldNameStrings = `${FieldName}`;
export type FieldNameStrings = "firstName" | "lastName" | "country" | "city" | "zip" | "zone" | "address1" | "address2" | "phone" | "company" | "email";

/** Helper function to determine if a field is omitted */
export const isFieldOmitted = (field: FieldName | FieldNameStrings, omitFields?: FieldName[]) => {
  return !!omitFields?.length && omitFields.findIndex(of => of === field) !== -1;
}

/** The Address information */
export type Address = {
  /** (Optional) The Company name */
  [FieldName.Company]?: string;
  /** First name (optional if only want to access the address) */
  [FieldName.FirstName]?: string;
  /** Last name (optional if only want to access the address) */
  [FieldName.LastName]?: string;
  /** The address line 1 */
  [FieldName.Address1]: string;
  /** The address line 2 (optional) */
  [FieldName.Address2]?: string;
  /** The city */
  [FieldName.City]: string;
  /** The Zone (province/state/...) as a code (ex:BC for British-Columbia) */
  [FieldName.Zone]?: string;
  /** The zip/postal code/postcode */
  [FieldName.PostalCode]: string;
  /** The country code (ISO 3166-1 alpha-2) */
  [FieldName.Country]: string;
  /** (optional) The phone number */
  [FieldName.Phone]?: string;
  /** (optional) The email */
  [FieldName.Email]?: string;
}

/** An empty default address (country default to US) */
export const DEFAULT_ADDRESS: Address = {
  address1: '',
  city: '',
  country: 'US',
  zip: '',
};

/** The Country information */
export interface Country {
  /** Country code (2-letters code) */
  code: string;
  /** Continent code (2-letters code) */
  continent: ContinentCode;
  /** The phone number prefix for this country */
  phoneNumberPrefix: number;
  /** Indicate where the autocomplete is being integrated */
  autocompletionField: FieldNameStrings;
  /** Specify how the layout for the address should be for this country */
  layout: (FieldNameStrings | FieldNameStrings[])[];
  /** The list of optional fields */
  optionalFields: FieldNameStrings[];
  /** The specification of labels string keys [key: keyof typeof FieldName] */
  labelKeys?: { [key in FieldName]?: string };
  /** The format how the address should be displayed for this specific country */
  formatting: string;
  /** The Zone key */
  zoneKey: ZoneKey;
  /** List of zone code (ex:BC for British-Colombia) */
  zones: string[];
}

/** The default string key for a field */
export const DEFAULT_LABEL_KEYS: { [key: string]: string } = {
  [FieldName.Address1]: 'address.address1.default',
  [FieldName.Address2]: 'address.address2.default',
  [FieldName.City]: 'address.city.default',
  [FieldName.Company]: 'address.company',
  [FieldName.Country]: 'address.country',
  [FieldName.FirstName]: 'address.firstName',
  [FieldName.LastName]: 'address.lastName',
  [FieldName.Phone]: 'address.phone',
  [FieldName.Email]: 'address.email',
  [FieldName.PostalCode]: 'address.zip.default',
};

/** Get the localized name of the given country */
export const getCountryNameByCode = (t: TFunction, countryCode: string) => {
  return t(`${countryCode.toLowerCase()}.name`, { ns: 'countries' });
};

/** Get the localized name of the given zone within the country */
export const getZoneNameByCode = (
  t: TFunction,
  countryCode: string,
  zoneCode: string,
) => {
  return t(`${countryCode.toLowerCase()}.zones.${zoneCode.toLowerCase()}`, { ns: 'countries' });
};

/** Get the localized name of the given continent */
export const getContinentNameByCode = (t: TFunction, continentCode: string) => {
  return t(continentCode, { ns: 'continents' });
};

/** Get the localized label for the input of the given field */
export const getAddressFieldLabel = (
  t: TFunction,
  field: FieldNameStrings,
  isOptional?: boolean,
  optionKey?: string,
): string => {
  if (isOptional) {
    return t('address.optionalField', {
      ns: 'translations',
      fieldLabel: getAddressFieldLabel(t, field, false, optionKey),
    });
  }
  return t(optionKey ? `address.${optionKey}` : DEFAULT_LABEL_KEYS[field], {
    ns: 'translations',
  });
};

/** Get the localized placeholder for the input of the given field */
export const getAddressFieldHint = (
  t: TFunction,
  field: FieldNameStrings,
  option?: { zoneKey?: ZoneKey, zipKey?: string },
) => {
  const options = field === 'zone' && !!option?.zoneKey ?
    { zoneLabel: t(`address.${getZoneStringKey(option?.zoneKey)}`) }
    : field === 'zip' ?
      { zipLabel: getAddressFieldLabel(t, field, false, option?.zipKey) }
      : {};
  if (field === 'address1' || field === 'address2') {
    return getAddressFieldLabel(t, field)
  }
  return t(`address.hint.${field}`, {
    ns: 'translations',
    defaultValue: getAddressFieldLabel(t, field, false),
    ...options,
  });
};

export const getZoneStringKey = (zoneKey: ZoneKey) => {
  if (zoneKey === 'STATE_AND_TERRITORY') {
    return `zone.stateAndTerritory`
  }
  return `zone.${zoneKey.toLowerCase()}`;
};

/****************************************************
 * Utils function to use to extract from external data
 ******************************************************/
//utils function to extract country info from https://github.com/Shopify/quilt/blob/main/packages/address-mocks/src/fixtures/countries_en.ts
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getCountries = (data: any[]) => {
  const getLabelsDiff = (
    current: Omit<Address, FieldName.PostalCode> & { postalCode: string },
  ) => {
    const original = {
      address1: 'Address',
      address2: 'Apartment, suite, etc.',
      city: 'City',
      company: 'Company',
      country: 'Country/Region',
      firstName: 'First name',
      lastName: 'Last name',
      phone: 'Phone',
      postalCode: 'ZIP/Postal code',
    };
    const getStringKeyFor = (value: string) => {
      switch (value) {
        case 'District':
          return 'city.district';
        case 'Suburb':
          return 'city.suburb';
        case 'City/town':
          return 'city.town';
        case 'City/ward/town/village':
          return 'city.all';
        case 'ZIP code':
          return 'zip.zipcode';
        case 'Postal code':
          return 'zip.postalcode';
        case 'PIN code':
          return 'zip.pincode';
        case 'Postcode':
          return 'zip.postcode';
        case 'Full address':
          return 'address1.full';
        case 'Street and house number':
          return 'address1.partial';
        case 'Additional address':
          return 'address2.addon';
        default:
          console.error('no string key found');
          return '';
      }
    };
    let labels = {};
    Object.keys(original).forEach((key) => {
      const o = original[key as keyof typeof original];
      const c = current[key as keyof typeof original];
      if (o !== c && !!c) {
        if (key === 'postalCode') {
          labels = {
            ...labels,
            zip: getStringKeyFor(current[key]),
          };
        } else if (key !== 'zone') {
          labels = {
            ...labels,
            [key]: getStringKeyFor(c),
          };
        }
      }
    });
    return labels;
  };

  const getContinentCodeForString = (continent: string) => {
    switch (continent) {
      case 'Africa':
        return 'AF';
      case 'Antarctica':
        return 'AN';
      case 'Asia':
        return 'AS';
      case 'Central America':
        return 'CA';
      case 'Europe':
        return 'EU';
      case 'North America':
        return 'NA';
      case 'Oceania':
        return 'OC';
      case 'South America':
        return 'SA';
      case 'Other':
        return 'O';
      default:
        console.error('continent not found for ' + continent);
        return '';
    }
  };

  let countries = {};
  data.forEach((c) => {
    countries = {
      ...countries,
      [c.code]: {
        code: c.code,
        continent: getContinentCodeForString(c.continent),
        phoneNumberPrefix: c.phoneNumberPrefix,
        autocompletionField: c.autocompletionField,
        zoneKey: c.provinceKey,
        zones: c.zones.map((z: { code: string; name: string }) => z.code),
        formatting: c.formatting.show
          .replaceAll('_', '\n')
          .replace('province', 'zone'),
        layout: c.formatting.edit.split('_').map((l: string) =>
          l
            .replace('province', 'zone')
            .split(/{([a-zA-Z0-9]*)}/g)
            .filter((s: string) => !!s),
        ),
        optionalFields:
          !!c.optionalLabels.address2 &&
            c.optionalLabels.address2 !== c.labels.address2
            ? ['address2']
            : [],
        labelKeys: getLabelsDiff(c.labels),
      },
    };
  });
  return countries;
};

//list the labels and differences (en)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const listLabels = (data: any[]) => {
  let labels = {
    address1: ['Address'],
    address2: ['Apartment, suite, etc.'],
    city: ['City'],
    company: ['Company'],
    country: ['Country/Region'],
    firstName: ['First name'],
    lastName: ['Last name'],
    phone: ['Phone'],
    postalCode: ['Postal code'],
    zone: ['Region'],
  };
  data.forEach((c) => {
    Object.keys(labels).forEach((key) => {
      if (!labels[key as keyof typeof labels].includes(c.labels[key])) {
        labels[key as keyof typeof labels].push(c.labels[key]);
      }
    });
  });
  return labels;
};

//extract the country JSON (traslation file) from data like
// - https://github.com/Shopify/quilt/blob/main/packages/address-mocks/src/fixtures/countries_fr.ts
// - https://github.com/Shopify/quilt/blob/main/packages/address-mocks/src/fixtures/countries_en.ts
// - https://github.com/Shopify/quilt/blob/main/packages/address-mocks/src/fixtures/countries_af.ts
// - https://github.com/Shopify/quilt/blob/main/packages/address-mocks/src/fixtures/countries_ja.ts
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getCountryJSON = (data: any[]) => {
  return `{
      ${data
      .map((c, ci) => {
        if (!c.zones.length) {
          return ` "${c.code}":{
         "name":"${c.name}"
       }${ci !== data.length - 1 ? ',' : ''}`;
        } else {
          return ` "${c.code}":{
         "name":"${c.name}",
         "zones":{
           ${c.zones
              .map(
                (z: { code: string; name: string }, zi: number) =>
                  `"${z.code}":"${z.name}"${zi !== c.zones.length - 1 ? ',' : ''
                  }`,
              )
              .join('\n')}
         }
       }${ci !== data.length - 1 ? ',' : ''}`;
        }
      })
      .join('\n')}
    }`;
};

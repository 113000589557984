import { PropsWithChildren, useCallback, useEffect } from "react";
import { useLocation } from "react-router";

export const AutoScrollPage = ({ children }: PropsWithChildren<{}>) => {
    useAutoScrollPage()

    return children;
}

export const useAutoScrollPage = () => {
    const { hash } = useLocation();

    const scrollToHash = useCallback(() => {
        if (!hash) {
            window.scrollTo({ top: 0 });//scroll to top
            return;
        }

        const anchor = hash.slice(1);
        const doc = document.getElementById(anchor);
        if (!doc) {
            return;
        }
        doc?.scrollIntoView({
            behavior: "smooth",
            block: "center",
        })
    }, [hash])

    useEffect(() => {
        scrollToHash();
    }, [scrollToHash]);

    return { scrollToHash };
}
import React, { useState } from 'react';
import { Info } from '@mui/icons-material';
import { Alert, Box, CircularProgress, Dialog, DialogContent, Divider, IconButton, Stack, Tab, Tabs, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IAccountHelpItemProps } from '../AccountHelpItem';
import { AccountHelpSection } from '../AccountHelpSection';
import { AccountInfoItem, IAccountInfoItemProps } from '../AccountInfoItem';

export type AccountBaseInfoItems = IAccountInfoItemProps[] | { label: string, description?: string, items: IAccountInfoItemProps[] }[];

export interface IAccountBaseInfoProps {
    /** The title of the page */
    title: string;
    /** The items in the page or label of tab and items in each tab (optional description) */
    items: AccountBaseInfoItems;
    /** (Optional) The items for the help/info on the page */
    helpItems?: IAccountHelpItemProps[];
}

/** The base info page for all Account Info sub Pages */
export const AccountBaseInfo = ({
    title,
    items,
    helpItems = [],
}: IAccountBaseInfoProps) => {
    const [helpOpen, setHelpOpen] = useState(false);
    const theme = useTheme();
    const up_sm = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <>
            <Stack direction={'column'}
                justifyContent={'start'}
                alignItems={'stretch'}>
                {/* Title and help button (if screen size allows) */}
                <Stack direction={'row'}
                    justifyContent={'space-between'}>
                    <Typography variant="h3">{title}</Typography>
                    {!!helpItems.length && !up_sm &&
                        <Tooltip title={'info'}>
                            <IconButton onClick={() => setHelpOpen(o => !o)}>
                                <Info />
                            </IconButton>
                        </Tooltip>
                    }
                </Stack>
                {/* Content and help section(if screen size allows) */}
                <Stack direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'start'}
                    spacing={4}>
                    <Stack direction={'column'}
                        justifyContent={'start'}
                        alignItems={'stretch'}
                        flexGrow={1}>
                        <AccountBaseInfoContent items={items} />
                    </Stack>

                    {up_sm && !!helpItems?.length &&
                        <Box maxWidth={'30%'} border={1} borderColor={'grey'} borderRadius={5} p={2}>
                            <AccountHelpSection items={helpItems} />
                        </Box>
                    }
                </Stack>
            </Stack>
            <Dialog onClose={() => setHelpOpen(false)} open={helpOpen}>
                <DialogContent>
                    <AccountHelpSection items={helpItems} />
                </DialogContent>
            </Dialog>
        </>
    )
}

function instanceOfItem(array: any[]): array is IAccountInfoItemProps[] {
    return !!array && array.length > 0 && !('label' in array[0]);
}

const AccountBaseInfoContent = ({
    items
}: Pick<IAccountBaseInfoProps, 'items'>) => {
    const [itemIndexEditing, setItemIndexEditing] = useState(-1);
    const [tabActiveIndex, setTabActiveIndex] = useState(0);

    if (!items.length) {
        console.warn("This component should contain at least one items");
        return <CircularProgress />;
    }

    const handleEditClick = (item: IAccountInfoItemProps, index: number) => {
        if (!item.editLabel) {
            //if not customized
            setItemIndexEditing(index);
        }
        return item.onEditClick?.();
    }

    const handleCancelClick = (item: IAccountInfoItemProps) => {
        setItemIndexEditing(-1);
        return item.onCancelClick?.();
    }

    const handleSaveClick = async (item: IAccountInfoItemProps) => {
        try {
            await item.onSaveClick?.();
            setItemIndexEditing(-1);//edit close only if promise succeed
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    if (instanceOfItem(items)) {
        //if we simply have instance of items
        return (
            <>
                {items.map((i, index) =>
                    <Box key={`account-item-${index}`}>
                        {!!index && <Divider />}
                        <AccountInfoItem
                            {...i}
                            onEditClick={(!!i.onEditClick || !!i.onSaveClick) ? () => handleEditClick(i, index) : undefined}
                            onCancelClick={(!!i.onCancelClick || !!i.onSaveClick) ? () => handleCancelClick(i) : undefined}
                            onSaveClick={!!i.onSaveClick ? () => handleSaveClick(i) : undefined}
                            isInEditMode={itemIndexEditing === index}
                            disabled={itemIndexEditing !== -1 && itemIndexEditing !== index} />
                    </Box>
                )}
            </>);
    } else {
        //else we have tabs with instance of items
        return (
            <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabActiveIndex}
                        onChange={(_, newIndex) => setTabActiveIndex(newIndex)}>
                        {
                            items.map(({ label, description }, index) =>
                                description ?
                                    <Tab key={`account-info-tab-${index}`}
                                        label={label}
                                        disabled={itemIndexEditing !== -1} />
                                    :
                                    <Tab key={`account-info-tab-${index}`}
                                        label={label}
                                        disabled={itemIndexEditing !== -1} />
                            )
                        }
                    </Tabs>
                </Box>
                {!!items[tabActiveIndex].description &&
                    <Alert severity="info">{items[tabActiveIndex].description}</Alert>
                }
                <AccountBaseInfoContent items={items[tabActiveIndex].items
                    .map((i, index) => ({
                        ...i,
                        onEditClick: (!!i.onEditClick || !!i.onSaveClick) ? () => handleEditClick(i, index) : undefined,
                        onCancelClick: (!!i.onCancelClick || !!i.onSaveClick) ? () => handleCancelClick(i) : undefined,
                        onSaveClick: !!i.onSaveClick ? () => handleSaveClick(i) : undefined,
                    }))} />
            </>
        );
    }
}
import { TFunction } from "i18next";
import { ROUTE_PATH, TAudience, TCategory } from "../../utilities/navigation";
import { NavigateFunction, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const getCategoryProps = (t: TFunction, target: TAudience, navigate?: NavigateFunction) => {
    // const categories = t('categories', { context: target, returnObjects: true }) as any;//not formatting nested translation
    const categoriesKey = `categories_${target}`;
    return {
        title: t(`${categoriesKey}.title`),
        description: t(`${categoriesKey}.description`),
        categories: (t(`${categoriesKey}.list`, { returnObjects: true }) as Array<{ title: string, description: string, image: string, type: string }>)
            .map((category) => ({
                ...category,
                onClick: () => navigate?.(ROUTE_PATH.buildTestimonials(target, category.type as TCategory))
            }))
    };
}

export const useGetCategorySectionProps = (audience: TAudience) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return getCategoryProps(t, audience, navigate);
}

export const getCategoryInfo = (t: TFunction, audience: TAudience, category: TCategory) => {
    return getCategoryProps(t, audience, () => { }).categories.find(cat => cat.type === category)
}
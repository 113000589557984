import React from 'react';
import { Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { GOOGLE_MAPS_API_KEY } from '../../../services/firebase';
import { GoogleMap, Libraries, LoadScript, Marker } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';

/** The list of libraries used by the ListingLocation section */
const GOOGLE_LIBRARIES: Libraries = ["places"];

export interface IListingLocationProps {
    /**
     * The location of the listing
     */
    location: {
        lat: number;
        lng: number;
    }
    /**
     * The information on the neighborhood
     * @optional
     */
    neighborhoodInfo?: string;
    /**
     * The information about getting around
     * @optional
     */
    gettingAroundInfo?: string;
    /**
     * Callback to get direction to that location
     * @optional will display button only if provided
     */
    onDirectionClick?: () => void;
}

export const ListingLocation = ({ location, neighborhoodInfo, gettingAroundInfo, onDirectionClick }: IListingLocationProps) => {
    const { t } = useTranslation();
    const hasMoreInfo = !!neighborhoodInfo || !!gettingAroundInfo;

    if (!location) {
        return null;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Stack direction={'row'} justifyContent={"space-between"} alignItems={'center'}>
                    <Typography variant={'h3'}>{t('listing.location.titleSection')}</Typography>
                    {!!onDirectionClick && <Button variant={"outlined"} onClick={onDirectionClick}>{t('listing.location.directionButton')}</Button>}
                </Stack>
            </Grid>
            {
                hasMoreInfo &&
                <Grid item container xs={12} md={4} spacing={2}>
                    {
                        !!neighborhoodInfo &&
                        <Grid item xs={12} sm={6} md={12}>
                            <Typography variant={'h4'}>{t('listing.location.neighborhoodInfo')}</Typography>
                            <Typography variant={'body1'}>{neighborhoodInfo}</Typography>
                        </Grid>
                    }
                    {
                        !!gettingAroundInfo &&
                        <Grid item xs={12} sm={6} md={12}>
                            <Typography variant={'h4'}>{t('listing.location.gettingAroundInfo')}</Typography>
                            <Typography variant={'body1'}>{gettingAroundInfo}</Typography>
                        </Grid>
                    }
                </Grid>
            }
            <Grid item xs={12} md={hasMoreInfo ? 8 : 12} sx={{ minHeight: 300 }}>
                <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                    libraries={GOOGLE_LIBRARIES}
                    loadingElement={<CircularProgress />}
                    onLoad={() => console.log("loaded")}>

                    <GoogleMap
                        mapContainerStyle={{
                            height: '100%',
                            width: '100%',
                        }}
                        center={{
                            lat: location.lat,
                            lng: location.lng
                        }}
                        zoom={12}
                    // onLoad={(map) => { mapRef.current = map }}
                    >
                        {
                            <Marker position={location} />
                        }
                    </GoogleMap>
                </LoadScript>
            </Grid>
        </Grid>
    );
}

import React from "react";
import { useSearchParams } from "react-router-dom";
import { ROUTE_QUERY, TAudience, TCategory } from "../../utilities/navigation";
import { TestimonialContainer } from "../../containers/TestimonialContainer/TestimonialContainer";

export const TestimonialPage = () => {
    const [searchParams,] = useSearchParams();
    const audience = searchParams.get(ROUTE_QUERY.audience) as TAudience
    const category = searchParams.get(ROUTE_QUERY.category) as TCategory

    return <TestimonialContainer audience={audience} category={category} />
}


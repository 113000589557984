import { Button, Stack, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export interface IDashboardSectionProps {
    /** The title of the dashboard section */
    title: string;
    /** (optional) Callback on view all button */
    onViewAll?: () => void;
}

/** Generic view of a dashboard section */
export const DashboardSection = ({ title, onViewAll, children }: PropsWithChildren<IDashboardSectionProps>) => {
    const { t } = useTranslation();
    return (
        <Stack direction={'column'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant='h4'>{title}</Typography>
                {!!onViewAll && <Button onClick={onViewAll}>{t('viewAll')}</Button>}
            </Stack>
            {children}
        </Stack>
    )
}
/** The remote config for the maintenance mode */
export const RC_MAINTENANCE_MODE = "maintenance";

export type MaintenanceMode = {
    /** Boolean indicating if the maintenance mode is on */
    enabled: boolean;
    /** Boolean indicating if the contact button is displayed */
    contactEnabled: boolean;
    /** Boolean indicating if the user can enter its email to get notified */
    notificationEnabled: boolean;
    /** Boolean indicating if the links to social media are displayed */
    socialsEnabled: boolean;
    /** Array being more specific on which subdomains is being affected, or "ALL" for all the domains */
    subdomainsAffected: ('MAIN' | 'SHOP' | 'HELP_CENTER' | 'CAREER' | 'FORUM' | 'NEWS')[] | "ALL";
}

export const DEFAULT_MAINTENANCE_MODE: MaintenanceMode = {
    enabled: false,
    contactEnabled: true,
    notificationEnabled: true,
    socialsEnabled: true,
    subdomainsAffected: "ALL",
}
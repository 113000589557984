import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ELinkedAccount, renderLinkedAccountIcon } from "../../utilities/EnumUtility";
import { isEmailValid } from "../../utilities";

export enum EAuthType {
  LOG_IN,
  SIGN_UP,
  LINK
}

export interface IAuthContentProps {
  /** The type of auth log-in/sign-up/link (will determine the text to show) */
  authType?: EAuthType;
  /** Callback on change auth type */
  onAuthTypeChange: (newAuthType: EAuthType) => void;
  /** The list of provider to show */
  authProviders: {
    type: ELinkedAccount;
    onClick: (args?: { email: string, password: string }) => void;
  }[];
  /** Callback on forgot password click */
  onForgotPasswordClick: () => void;
}

export const AuthContent = ({
  authType = EAuthType.SIGN_UP,
  onAuthTypeChange,
  authProviders,
  onForgotPasswordClick,
}: IAuthContentProps) => {
  const { t } = useTranslation();
  const [emailFieldProps, setEmailFieldProps] = useState({
    value: '',
    helperText: '',
    error: false,
  });
  const [passwordFieldProps, setPasswordFieldProps] = useState({
    value: '',
    helperText: '',
    error: false,
  });
  const [repeatPasswordFieldProps, setRepeatPasswordFieldProps] = useState({
    value: '',
    helperText: '',
    error: false,
  });
  const [showPassword, setShowPassword] = useState(false);

  const onEmailChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    (ev) => {
      setEmailFieldProps(curProps => {
        const newValue = ev.target.value;
        const error = curProps.error && !isEmailValid(newValue) && !newValue
        return {
          ...curProps,
          value: newValue,
          error,
          helperText: error ? curProps.helperText : ''
        }
      })
    }

  const onPasswordChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    (ev) => {
      setPasswordFieldProps(curProps => {
        const newValue = ev.target.value;
        const error = curProps.error && !newValue
        return {
          ...curProps,
          value: newValue,
          error,
          helperText: error ? curProps.helperText : ''
        }
      })
    }

  const onRePasswordChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    (ev) => {
      setRepeatPasswordFieldProps(curProps => {
        const newValue = ev.target.value;
        const error = curProps.error && !newValue && passwordFieldProps.value !== newValue;
        return {
          ...curProps,
          value: newValue,
          error,
          helperText: error ? curProps.helperText : ''
        }
      })
    }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const renderHeader = () => {
    const onAuthChangeClick = authType === EAuthType.LOG_IN ? () => onAuthTypeChange(EAuthType.SIGN_UP) : authType === EAuthType.SIGN_UP ? () => onAuthTypeChange(EAuthType.LOG_IN) : null;
    const linkText = authType === EAuthType.LOG_IN ? t(getStringKeyFromType(EAuthType.SIGN_UP, 'title')) : authType === EAuthType.SIGN_UP ? t(getStringKeyFromType(EAuthType.LOG_IN, 'title')) : '';
    return <>
      <Typography variant={'h2'} >{t(getStringKeyFromType(authType, 'title'))}</Typography>
      <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
        <Typography variant={'subtitle1'}>{t(getStringKeyFromType(authType, 'subTitle'))}</Typography>
        {!!onAuthChangeClick && <Typography style={{ textDecoration: 'underline' }} onClick={onAuthChangeClick}>{linkText}</Typography>}
      </Stack>
    </>
  }

  const renderEmailSection = () => {
    const emailItem = authProviders.find(p => p.type === ELinkedAccount.EMAIL);
    if (!emailItem)
      return null;


    const handleEmail = () => {
      const emailValue: string = emailFieldProps.value;
      const passwordValue: string = passwordFieldProps.value;
      const repeatPasswordValue: string = repeatPasswordFieldProps.value;
      if (!emailValue) {
        setEmailFieldProps(cp => ({
          ...cp,
          error: true,
          helperText: t('error.requiredFieldEmpty'),
        }));
        return;
      }
      if (!passwordValue) {
        setPasswordFieldProps(cp => ({
          ...cp,
          error: true,
          helperText: t('error.requiredFieldEmpty'),
        }));
        return;
      }
      if (!isEmailValid(emailValue)) {
        setEmailFieldProps(cp => ({
          ...cp,
          error: true,
          helperText: t('error.invalidEmail'),
        }));
        return;
      }

      switch (authType) {
        case EAuthType.SIGN_UP:
        case EAuthType.LINK:
          if (passwordValue !== repeatPasswordValue) {
            setRepeatPasswordFieldProps(cp => ({
              ...cp,
              error: true,
              helperText: t('error.unmatchedPasswords'),
            }));
            return;
          }
          emailItem.onClick({ email: emailValue, password: passwordValue })
          break;
        case EAuthType.LOG_IN:
          emailItem.onClick({ email: emailValue, password: passwordValue })
      }
    };

    return <>
      <TextField
        {...emailFieldProps}
        onChange={onEmailChange}
        autoFocus
        margin="dense"
        id="email"
        label={t('auth.emailLabel')}
        placeholder={t('auth.emailPlaceholder')}
        type="email"
        fullWidth
      />
      <TextField
        {...passwordFieldProps}
        onChange={onPasswordChange}
        margin="dense"
        id="password"
        label={t('auth.passwordLabel')}
        placeholder={t('auth.passwordPlaceholder')}
        type={showPassword ? "text" : "password"}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={t('auth.passwordVisibilityAlt')}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {authType !== EAuthType.LOG_IN && (
        <TextField
          {...repeatPasswordFieldProps}
          onChange={onRePasswordChange}
          margin="dense"
          id="password2"
          label={t('auth.password2Label')}
          placeholder={t('auth.password2Placeholder')}
          type={showPassword ? "text" : "password"}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={t('auth.passwordVisibilityAlt')}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      <Button
        startIcon={<EmailIcon />}
        onClick={handleEmail}
        variant={"contained"}
        color={"primary"}
      >
        {t('auth.emailBtn')}
      </Button>
      {authType === EAuthType.LOG_IN && (
        <Typography
          style={{ textDecoration: 'underline' }}
          onClick={onForgotPasswordClick}
        >
          {t('auth.forgotPasswordLabel')}
        </Typography>
      )}
    </>
  }

  const renderAnonymousSection = () => {
    if (authType === EAuthType.LINK) {
      return null;//we don't link to anonymous account (only from)
    }
    const anonymItem = authProviders.find(p => p.type === ELinkedAccount.OTHER);
    if (!anonymItem)
      return null;

    return <Stack direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}>
      <Button
        startIcon={<AccountCircleIcon />}
        onClick={() => anonymItem.onClick()}
        variant={"contained"}
      >
        {t('auth.anonymousBtn')}
      </Button>
      <Typography variant={'caption'}>{t('auth.anonymousNote')}</Typography>
    </Stack>;
  }

  return (
    <Stack
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      spacing={2}
      sx={{ mx: '20vw', my: 3 }}>
      {renderHeader()}
      {renderEmailSection()}
      <Typography variant={'h4'}>{t('auth.otherOptionTitle')}</Typography>
      {
        authProviders.map((p, index) =>
          p.type !== ELinkedAccount.EMAIL && p.type !== ELinkedAccount.OTHER &&
          <Button
            key={`provider-${p.type}-${index}`}
            startIcon={renderLinkedAccountIcon(p.type)}
            onClick={() => p.onClick()}
            variant={"contained"}
          >
            {t(getButtonStringKeyFrom(p.type))}
          </Button>
        )
      }
      {renderAnonymousSection()}
    </Stack>
  );
};

const getButtonStringKeyFrom = (type: ELinkedAccount) => {
  switch (type) {
    case ELinkedAccount.EMAIL:
      return 'auth.emailBtn';
    // case ELinkedAccount.PHONE:
    //   return 'auth.phoneBtn';
    case ELinkedAccount.GOOGLE:
      return 'auth.googleBtn';
    case ELinkedAccount.FACEBOOK:
      return 'auth.facebookBtn';
    case ELinkedAccount.TWITTER:
      return 'auth.twitterBtn';
    case ELinkedAccount.LINKEDIN:
      return 'auth.googleBtn';
    case ELinkedAccount.OTHER:
    default: return 'auth.anonymousBtn'
  }
}

const getStringKeyFromType = (authType: EAuthType, extraKey?: string): string => {
  if (!extraKey) {
    switch (authType) {
      case EAuthType.SIGN_UP:
        return "auth.signUp";
      case EAuthType.LOG_IN:
        return "auth.logIn";
      case EAuthType.LINK:
        return "auth.linkAccount"
    }
  } else {
    return [getStringKeyFromType(authType), extraKey].join(".")
  }
}
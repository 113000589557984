import React from 'react';
import { Box, Button, Card, CardMedia, Skeleton, Stack, SxProps, Theme, Typography } from '@mui/material';

import styles, { IMAGE_HEIGHT } from './ShopItem.styles';
import { IShopPriceProps, ShopPrice } from '../ShopPrice';
import { IShopReviewsSummaryProps, ShopReviewsSummary } from '../ShopReviewsSummary';
import { useTranslation } from 'react-i18next';
import { PropsWithLoadingState } from '../../../utilities';

export interface IShopItemProps {
    /** The title of the item */
    title: string;
    /** The image url of the item */
    imageUrl: string;
    /** The reviews linked to this item */
    reviews?: IShopReviewsSummaryProps;
    /** The price(s) linked to this item */
    price: IShopPriceProps;
    /** Callback on click for details */
    onViewClick: () => void;
    /** Callback on click add to cart */
    onCartClick: () => void;
    /** (optional) Styling of the container */
    containerStyle?: SxProps<Theme>;
}

export const ShopItem = ({ title, imageUrl, reviews, price, onViewClick, onCartClick, containerStyle, loading }: PropsWithLoadingState<IShopItemProps>) => {
    const { t } = useTranslation();
    return (
        <Card sx={{ ...styles.container, ...containerStyle }}>
            <Box sx={{ display: 'flex', height: '100%' }}>
                <Stack direction={'column'}
                    justifyContent={'space-between'}
                    alignItems={'stretch'}
                    spacing={1}
                    padding={1}
                    sx={{ flex: 'auto' }}>
                    {!loading ?
                        <CardMedia
                            component="img"
                            height={IMAGE_HEIGHT}
                            image={imageUrl}
                            alt={title}
                            sx={{ objectFit: "contain" }}
                            onClick={onViewClick} />
                        :
                        <Skeleton variant='rectangular' height={IMAGE_HEIGHT} width={'100%'} />
                    }
                    <Typography sx={{ flex: 'auto' }} textAlign={'center'}>{!loading ? title : <Skeleton />}</Typography>
                    {!loading && !!reviews && <Box alignSelf={'start'}><ShopReviewsSummary {...reviews} /></Box>}
                    {!loading ?
                        <Box alignSelf={'end'}><ShopPrice {...price} /></Box>
                        :
                        <Skeleton variant='text' />
                    }
                    <Stack direction={'row'} justifyContent={'end'} alignItems={'center'} spacing={1}>
                        <Button variant={'text'} onClick={onViewClick} disabled={!!loading}>{t('shop.product.viewDetailsButton')}</Button>
                        <Button variant={'contained'} onClick={onCartClick} disabled={!!loading}>{t('shop.product.addToCartButton')}</Button>
                    </Stack>
                </Stack>
            </Box>
        </Card>
    );
}
import { CURRENCY_CODES, LOCALE_CODES } from "./code";

const DEFAULT_LOCALES: { [key in typeof CURRENCY_CODES[number]]: typeof LOCALE_CODES[number] } = {
    AED: 'ar_AE',
    AFN: 'fa_AF',
    ALL: 'sq',
    AMD: 'hy',
    ANG: 'nl',
    AOA: 'pt_AO',
    ARS: 'es_AR',
    AUD: 'en',
    AWG: 'nl_AW',
    AZN: 'az',
    BAM: 'bs',
    BBD: 'en_BB',
    BDT: 'bn_BD',
    BGN: 'bg',
    BHD: 'ar_BH',
    BIF: 'fr_BI',
    BMD: 'en_BM',
    BND: 'ms_BN',
    BOB: 'es_BO',
    BRL: 'pt_BR',
    BSD: 'en_BS',
    BTN: 'dz',
    BWP: 'en_BW',
    BYR: 'be',
    BZD: 'en_BZ',
    CAD: 'en_CA',
    CDF: 'fr_CD',
    CHF: 'de_CH',
    CLP: 'es_CL',
    CNY: 'zh_Hans_CN',
    COP: 'es_CO',
    CRC: 'es_CR',
    CUP: 'es_CU',
    CVE: 'pt_CV',
    CZK: 'cs',
    DJF: 'fr_DJ',
    DKK: 'da_DK',
    DOP: 'es_DO',
    DZD: 'ar_DZ',
    EGP: 'ar_EG',
    ERN: 'ti_ER',
    ETB: 'am',
    EUR: 'de',
    FJD: 'en_FJ',
    FKP: 'en_FK',
    GBP: 'en',
    GEL: 'ka',
    GHS: 'en_GH',
    GIP: 'en_GI',
    GMD: 'en_GM',
    GNF: 'fr_GN',
    GTQ: 'es_GT',
    GYD: 'en_GY',
    HKD: 'zh_Hans_HK',
    HNL: 'es_HN',
    HRK: 'hr_HR',
    HTG: 'en',
    HUF: 'hu_HU',
    IDR: 'id',
    ILS: 'he',
    INR: 'en_IN',
    IQD: 'ar_IQ',
    IRR: 'fa_IR',
    ISK: 'is',
    JMD: 'en_JM',
    JOD: 'ar_JO',
    JPY: 'ja',
    KES: 'en_KE',
    KGS: 'ky',
    KHR: 'km',
    KMF: 'ar',
    KPW: 'ko_KP',
    KRW: 'ko_KR',
    KWD: 'ar_KW',
    KYD: 'en_KY',
    KZT: 'kk',
    LAK: 'lo',
    LBP: 'ar_LB',
    LKR: 'si',
    LRD: 'en_LR',
    LSL: 'en_LS',
    LYD: 'ar_LY',
    MAD: 'ar',
    MDL: 'ro',
    MGA: 'fr_MG',
    MKD: 'mk',
    MMK: 'my',
    MNT: 'mn',
    MOP: 'zh',
    MRO: 'ar_MR',
    MUR: 'en_MU',
    MVR: 'en',
    MWK: 'en',
    MXN: 'es_MX',
    MYR: 'ms_MY',
    MZN: 'pt_MZ',
    NAD: 'en_NA',
    NGN: 'en_NG',
    NIO: 'es_NI',
    NOK: 'nb_NO',
    NPR: 'ne',
    NZD: 'en_NZ',
    OMR: 'ar_OM',
    PAB: 'es_PA',
    PEN: 'es_PE',
    PGK: 'en_PG',
    PHP: 'fil',
    PKR: 'ur_PK',
    PLN: 'pl',
    PYG: 'es_PY',
    QAR: 'ar_QA',
    RON: 'ro',
    RSD: 'sr',
    RUB: 'ru',
    RWF: 'rw',
    SAR: 'ar_SA',
    SBD: 'en_SB',
    SCR: 'en_SC',
    SDG: 'ar_SD',
    SEK: 'sv_SE',
    SGD: 'en_SG',
    SHP: 'en_SH',
    SLL: 'en_SL',
    SOS: 'so_SO',
    SRD: 'nl_SR',
    SSP: 'en',
    STD: 'pt_ST',
    SYP: 'ar_SY',
    SZL: 'en_SZ',
    THB: 'th',
    TJS: 'en',
    TMT: 'tk',
    TND: 'ar_TN',
    TOP: 'to',
    TRY: 'tr_TR',
    TTD: 'en_TT',
    TWD: 'zh_Hant_TW',
    TZS: 'sw_TZ',
    UAH: 'uk',
    UGX: 'en_UG',
    USD: 'en_US',
    UYU: 'es_UY',
    UZS: 'uz',
    VEF: 'es_VE',
    VND: 'vi',
    VUV: 'en_BI',
    WST: 'en_AS',
    XAF: 'fr',
    XCD: 'en',
    XOF: 'fr',
    XPF: 'fr_PF',
    YER: 'ar_YE',
    ZAR: 'zu',
    ZMW: 'en_ZM',
    ZWL: 'en_ZW',
    //rest missing will default to en
    BTC: 'en',
    CUC: 'en',
    EEK: 'en',
    ETH: 'en',
    GGP: 'en',
    GHC: 'en',
    IMP: 'en',
    JEP: 'en',
    LTC: 'en',
    LTL: 'en',
    LVL: 'en',
    RMB: 'en',
    SVC: 'en',
    TRL: 'en',
    TVD: 'en',
    XBT: 'en',
    ZWD: 'en'
} as const;

export default DEFAULT_LOCALES;
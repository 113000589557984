import { Stack, Typography } from '@mui/material';
import React from 'react';

export interface IAccountHelpItemProps {
    icon?: JSX.Element;
    title: string;
    description: string;
}

/** Display the side help item for an account page */
export const AccountHelpItem = ({
    icon,
    title,
    description,
}: IAccountHelpItemProps) => {
    return (
        <Stack direction={'column'}
            justifyContent={'start'}
            alignItems={'center'}>
            {icon}
            <Typography variant='h6' textAlign={'center'}>{title}</Typography>
            <Typography variant='body1' textAlign={'center'}>{description}</Typography>
        </Stack>
    )
}
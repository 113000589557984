import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { ROUTE_PATH, buildPath } from '../../../utilities/navigation';
import { ChoAppBar } from '../ChoAppBar';

export const CareersAppBar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const menuOptions =
        [
            {
                label: t('careers.menu.home'),
                action: () => navigate(ROUTE_PATH.home)
            },
            {
                label: t('careers.menu.university'),
                action: () => navigate(buildPath(ROUTE_PATH.careers.university))
            },
            {
                label: t('careers.menu.members'),
                action: () => navigate(buildPath(ROUTE_PATH.careers.members))
            },
            {
                label: t('careers.menu.positions'),
                action: () => navigate(buildPath(ROUTE_PATH.careers.positions)),
            }
        ];

    return <ChoAppBar logo={'white'} mainMenuItems={menuOptions} />
};

import React, { } from 'react';
import { useNewsNavigation } from '../../hooks/useNewsNavigation';
import { useSearchParams } from 'react-router-dom';
import { extractFilterFromSearchParams } from '../../containers/news/NewsStoriesFilterContainer';
import { NewsStoriesContainer } from '../../containers/news/NewsStoriesContainer';

/** Navigator Page taking care of displaying a list of stories */
export const NewsStoriesPage = () => {
    const newsNavigation = useNewsNavigation();
    const [searchParams,] = useSearchParams();

    return <NewsStoriesContainer {...newsNavigation}
        {...extractFilterFromSearchParams(searchParams)}
    />;
}

import { Button, Stack, Typography } from '@mui/material';
import React from 'react';

export interface IIssueStateViewProps {
    title: string;
    image?: string;
    description: string;
    button?: {
        text: string;
        onActionClick: () => void;
    }
}

export const IssueStateView = ({
    title,
    image,
    description,
    button,
}: IIssueStateViewProps) => {
    return (
        <Stack direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={1}
            padding={5}>
            {!!title &&
                <Typography variant={'h3'} textAlign={'center'}>{title}</Typography>
            }
            {!!image &&
                <img alt={title + " " + description}
                    src={image}
                    style={{ width: '50vw', objectFit: 'contain', maxHeight: '50vh' }} />
            }
            {!!description && <Typography variant={'h4'} textAlign={'center'} sx={{ whiteSpace: "pre-line" }}>{description}</Typography>}
            {!!button && <Button variant='contained' onClick={button.onActionClick}>{button.text}</Button>}
        </Stack>
    );
}
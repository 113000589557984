import { Check, Edit, NavigateBefore } from "@mui/icons-material";
import { Alert, Avatar, Button, Container, Divider, Grid, IconButton, Skeleton, Stack, StackProps, TextField, TextFieldProps, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { GuestsFilterSelection, IGuestsFilterSelectionProps } from "../../../components/GuestsFilterSelection";
import { GuestsSelectionDisplay } from "../../../components/GuestsFilterSelection/GuestsFilterSelection";
import { IPriceBreakdownProps, PriceBreakdown } from "../../../components/listing/ListingBookingSection/ListingBookingSection";
import { ListingDetails } from "../../../components/listing/ListingDetails";
import { UserContext } from "../../../context/UserInformation";
import { useListingFilters } from "../../../hooks/useListingFilter";
import { getListingInfo } from "../../../services/listing";
import { sendSupportTicket } from "../../../services/support";
import { PropsWithErrorState, PropsWithLoadingState } from "../../../utilities";
import { DEFAULT_LISTING_IMAGE_URL } from "../../../utilities/assets/assetHelper";
import { ROUTE_PARAMS, ROUTE_PATH } from "../../../utilities/navigation";
import { NotFoundPage } from "../../NotFoundPage";
import { DateRangeDisplay, DateRangePicker, IDateRangePicker } from "../../SearchPage/DateRangePicker";

export const ListingBookingPage = () => {
    const navigate = useNavigate();
    const { [ROUTE_PARAMS.listingID]: listingId } = useParams();
    const { filters, onFiltersChange } = useListingFilters();
    const [searchParams,] = useSearchParams();

    if (!listingId) {
        return <NotFoundPage />
    }

    const navigateBack = () => {
        navigate(-1);
    }

    const navigateToListing = (to?: string) => {
        navigate({
            pathname: ROUTE_PATH.listingBuild(to || listingId),
            search: searchParams.toString(),//preserve the search params
        })
    }

    const navigateToUser = (userId: string) => {
        navigate(ROUTE_PATH.userBuild(userId))
    }

    const navigateToChat = (chatId: string) => {
        // navigate(ROUTE_PATH.chatBuild(chatId)) //TODO chat feature
    }

    return <ListingBookingContainer listingId={listingId}
        filters={filters}
        onFiltersChange={onFiltersChange}
        navigateBack={navigateBack}
        navigateToListing={navigateToListing}
        navigateToUser={navigateToUser}
        navigateToChat={navigateToChat}
    />;
}

interface IListingBookingContainerProps extends ReturnType<typeof useListingFilters> {
    /** The listing id to book */
    listingId: string;
    /** Callback navigate back */
    navigateBack: () => void;
    /** Callback navigate to listing (if listing id not provided, current listing) */
    navigateToListing: (listingId?: string) => void;
    /** Callback navigate to user */
    navigateToUser: (userId: string) => void;
    /** Callback navigate to chat (triggered when booking request sent) */
    navigateToChat: (chatId: string) => void;//TODO chat feature
}

const ListingBookingContainer = ({ listingId, filters, onFiltersChange, navigateBack, navigateToListing, navigateToUser, navigateToChat }: IListingBookingContainerProps) => {
    const { t } = useTranslation();
    const userContext = useContext(UserContext);
    const userId = userContext?.user?.firebaseUser?.uid;
    const { data, isLoading, isError } = useQuery(
        ['ListingDetails', listingId],
        () => getListingInfo(listingId),
        { refetchOnWindowFocus: false, enabled: !!userId });
    const [message, setMessage] = useState('');
    const [type,] = useState<IListingBookingScreenProps['type']>('inquiry');
    //in edit mode for the dates and guests
    const [editMode, setEditMode] = useState(false);
    //boolean indicating if the booking has been send successfully
    const [confirmed, setConfirmed] = useState(false);

    if (isError) {
        //The error during fetching the listing details
        return <ListingDetails error />
    }

    const handleSendClick = () => {
        //TODO booking process
        //send to support in the meantime
        sendSupportTicket({
            type: "contact", error_message: 'booking before chat feature implemented',
            user_sender: userId || 'no user?',
            feature: "booking",
            doc_id: listingId,
            doc_ref: data?.ref,
            extra: {
                message: message,
                filters
            }
        })
        setConfirmed(true)
    }

    const isDatesAvailable = () => {
        if (filters.check_in && filters.check_out) {
            //filter by available date
            const selectedStartDate = filters.check_in;
            const selectedEndDate = filters.check_out;
            //check if the selected range is strictly within any available range
            for (const range of (data?.calendar?.availability ?? [])) {
                const rangeStartDate = new Date(range.start_date);
                const rangeEndDate = new Date(range.end_date);
                if (selectedStartDate >= rangeStartDate
                    && selectedEndDate <= rangeEndDate) {
                    return true; //Strictly within range found
                }
            }
            return false;//No strictly within range found
        }
        return true;//date not provided->date available then
    }
    const isGuestsListValid = () => {
        return !!filters.adults_number //have number of adults
            && filters.adults_number > 0 //is valid (no negative number)
            && (!data?.guests_restriction.max_adults //no restriction
                || filters.adults_number < data?.guests_restriction.max_adults);//or restriction followed
    }

    const getPotentialErrorMessage = () => {
        let i18nKey = '';
        if (data?.host.id === userId) {
            i18nKey = 'book.listing.errorOwn';
        } else if (!isDatesAvailable()) {
            i18nKey = 'book.listing.errorNotAvailable';
        } else if (!isGuestsListValid()) {
            i18nKey = 'book.listing.errorGuests';
        }
        if (!!i18nKey) {
            return {
                message: t(i18nKey)
            };
        }
        return false;//no error
    }

    return <ListingBookingScreen
        type={type}
        onBackClick={navigateBack}
        listing={isLoading || !data ? { loading: true } : {
            listing: {
                title: data.title,
                shortDescription: data.short_description,
                image: data.main_photo?.url || '',
                onClick: () => navigateToListing(),//current listing
            },
            host: {
                displayName: data.host?.display_name || '',
                avatarUrl: data.host?.avatar_url || '',
                onClick: () => navigateToUser(data.host.id),
            },
            cohost: !!data.cohost ? {
                displayName: data.cohost.display_name,
                avatarUrl: data.cohost.avatar_url,
                onClick: () => navigateToUser(data.cohost.id),
            } : undefined,
            pricing: {
                items: [],
                totalPrice: '$123'
            }
        }}
        dates={
            {
                ...(filters.check_in && filters.check_out ? {
                    startDate: filters.check_in,
                    endDate: filters.check_out,
                } : {}
                ),
                onDateRangeSelected: (start, end) => onFiltersChange({ check_in: start, check_out: end })
            }
        }
        guests={{
            adults: { value: filters.adults_number || 0, onChange: (value) => onFiltersChange({ adults_number: value }) },
            // children: {}//TODO rest
        }}
        messageInput={{
            value: message,
            onChange: (e) => setMessage(e.target.value)
        }}
        onSendClick={handleSendClick}
        error={getPotentialErrorMessage()}
        editing={editMode}
        onEditChange={setEditMode}
        isConfirmed={confirmed}
    />
}

interface IListingBookingScreenProps {
    /** 
     * The type of demand
     * - inquiry: Message the host/cohost for general inquiry
     * - request: Request to book for specific dates
     * - booking: Book directly (instant booking)
     */
    type: 'inquiry' | 'request' | 'booking';
    /** Callback on back clicked */
    onBackClick?: () => void;
    /** The listing info */
    listing: PropsWithLoadingState<Omit<IListingBookingInfoSummary, 'direction'>>;
    /** The dates picked (can be undefined if arrived on this page without filters, or generic inquiry) */
    dates?: IDateRangePicker;
    /** The guests picked (can be undefined if arrived on this page without filters, or generic inquiry)*/
    guests?: IGuestsFilterSelectionProps;
    /** The message input */
    messageInput: Pick<TextFieldProps, 'value' | 'onChange'>;
    /** Callback button cta clicked */
    onSendClick: () => void;
    /** Boolean indicating if in edit mode or not */
    editing?: boolean;
    /** Callback if the edit mode changes */
    onEditChange: (editing: boolean) => void;
    /** (TEMP: only here while the chat feature is built) Boolean confirming that the message/inquiry/booking has been sent */
    isConfirmed: boolean;
}

const ListingBookingScreen = ({ type, onBackClick, listing, dates, guests, messageInput, onSendClick, error, editing, onEditChange, isConfirmed }: PropsWithErrorState<IListingBookingScreenProps>) => {
    const { t } = useTranslation();
    const loading = !!listing?.loading;
    return (
        <Container sx={{ height: '100vh', p: 1 }}>
            <Grid container spacing={1}>
                {/* The title */}
                <Grid item xs={12}>
                    <Stack direction={'row'}>
                        {!!onBackClick && <IconButton onClick={onBackClick}><NavigateBefore /></IconButton>}
                        <Typography variant="h2">{t('book.listing.title', { context: type })}</Typography>
                    </Stack>
                </Grid>
                {/* The listing info (only visible if mobile/small screen) */}
                <Grid item xs={12} display={{ xs: 'flex', md: 'none' }}>
                    {!!listing && <ListingBookingInfoSummary {...listing} pricing={undefined} direction={{ xs: 'column', sm: 'row' }} />}
                </Grid>
                {/* The trip details */}
                <Grid item xs={12} md={8}>
                    <Stack direction={'column'} spacing={1}>
                        <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} spacing={1}>
                            <Typography variant={'h4'}>{t('book.listing.tripDetailsTitle')}</Typography>
                            {!!onEditChange && <IconButton onClick={() => onEditChange(!editing)} disabled={loading}>{!editing ? <Edit /> : <Check />}</IconButton>}
                        </Stack>
                        <Typography variant={'h6'}>{t('book.listing.tripDates')}</Typography>
                        {(!!dates && !!editing) ? <DateRangePicker {...dates} /> : <DateRangeDisplay {...dates} />}
                        <Typography variant={'h6'}>{t('book.listing.tripGuests')}</Typography>
                        {(!!guests && !!editing) ? <GuestsFilterSelection {...guests} /> : <GuestsSelectionDisplay {...guests} />}
                    </Stack>
                </Grid>
                {/* The listing pricing info (only visible if mobile/small screen) */}
                <Grid item xs={12} display={{ xs: 'flex', md: 'none' }}>
                    {!!listing?.pricing && <PriceBreakdown {...listing.pricing} loading={listing.loading} />}
                </Grid>
                {/* The trip listing and pricing (only visible if non-mobile) */}
                <Grid item xs={12} md={4} display={{ xs: 'none', md: 'flex' }}>
                    {/* Still want to see the loading for the pricing, so enter empty info */}
                    {!!listing && <ListingBookingInfoSummary {...listing} pricing={loading ? { items: [], totalPrice: '' } : listing.pricing} />}
                </Grid>
                {/* The error message if it exist */}
                {!!error &&
                    <Grid item xs={12}>
                        <Alert severity="error">{typeof error === 'string' ? error : t('error.generic')}</Alert>
                    </Grid>
                }
                {/* The message input and the button or confirmation */}
                <Grid item xs={12}>
                    {isConfirmed ?
                        <Typography>Your message has been successfully sent. You should be contacted shortly.</Typography>
                        :
                        <Stack direction={'column'} spacing={1}>
                            {loading ?
                                <Skeleton /> :
                                <TextField
                                    label={t('book.listing.messageLabel')}
                                    placeholder={t('book.listing.messagePlaceholder', { context: type })}
                                    disabled={!!error}
                                    multiline={true}
                                    rows={3}
                                    {...messageInput}
                                />
                            }
                            <Button variant="contained" onClick={onSendClick} disabled={!!error || !!loading}>{t('book.listing.button', { context: type })}</Button>
                        </Stack>
                    }
                </Grid>
            </Grid>
        </Container>
    );
}

interface IListingBookingInfoSummary {
    direction?: StackProps['direction'];
    listing: {
        image: string;
        title: string;
        shortDescription: string;
        rating?: string;
        onClick: () => void;
    };
    host: {
        displayName: string;
        avatarUrl: string;
        onClick: () => void;
    };
    cohost?: {
        displayName: string;
        avatarUrl: string;
        onClick: () => void;
    };
    pricing?: IPriceBreakdownProps
}

const ListingBookingInfoSummary = ({ direction, listing, host, cohost, pricing, loading }: PropsWithLoadingState<IListingBookingInfoSummary>) => {
    const { t } = useTranslation();
    return <Stack direction={direction || 'column'} spacing={1} p={1} width={'100%'}>
        <Stack direction={'row'} spacing={1} flex={1} onClick={!loading ? listing?.onClick : undefined}>
            {loading ?
                <Skeleton variant="rectangular" width={'50%'} height={'100%'} />
                : <img alt={listing.title} src={listing.image || DEFAULT_LISTING_IMAGE_URL} width={'50%'} />
            }
            <Stack direction={'column'} spacing={1} width={'50%'} >
                <Typography variant={'h5'}>{loading ? <Skeleton /> : listing.title}</Typography>
                <Typography variant={'caption'}>{loading ? <Skeleton /> : listing.shortDescription}</Typography>
                {!loading && listing.rating && <Typography>{listing.rating}</Typography>}
            </Stack>
        </Stack>
        <Divider />
        <Stack direction={'column'} alignItems={'center'} spacing={1} flex={1}>
            <Typography textAlign={'center'} alignSelf={'stretch'}>{loading ? <Skeleton width={'100%'} /> : t('listing.details.hostedBy', { name: host.displayName })}</Typography>
            {loading ? <Skeleton variant="circular" ><Avatar /></Skeleton> : <Avatar src={host.avatarUrl} onClick={host.onClick} />}
        </Stack>
        {
            !!cohost &&
            <>
                <Divider />
                <Stack direction={'column'} alignItems={'center'} spacing={1} flex={1}>
                    <Typography textAlign={'center'}>{t('listing.details.cohostedBy', { name: cohost.displayName })}</Typography>
                    <Avatar src={cohost.avatarUrl} onClick={cohost.onClick} />
                </Stack>
            </>
        }
        {
            !!pricing && <PriceBreakdown {...pricing} loading={loading} />
        }
    </Stack>
}
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import { WEBSITE_HELP_CENTER_URL } from "../types/constants";
import { getRouteForTopic, EForumTopic } from "../utilities/forums";
import { ROUTE_PATH, ROUTE_QUERY, buildPath } from "../utilities/navigation";

/** helper hook which gives navigation function for the Forms subdomain */
export const useForumsNavigation = () => {
    const navigate = useNavigate();

    /** Navigate to the page to create a new post, with optional preselected topic */
    const navigateToNewPost = (topic?: EForumTopic) => {
        const pathname = buildPath(ROUTE_PATH.forums.newPost, true);
        if (!!topic && !!getRouteForTopic(topic)) {
            const search = createSearchParams({ [ROUTE_QUERY.topic]: getRouteForTopic(topic) }).toString();
            navigate({
                pathname,
                search
            })
        } else {
            navigate(pathname)
        }
    }

    /** Navigate to the page of the details of a post (future optional replyId of the post to scroll to) */
    const navigateToPost = (postId: string, replyId?: string) => {
        navigate(ROUTE_PATH.forums.postBuild(postId))
    }

    /** Navigate to an article in the Help Center */
    const navigateToArticle = (articleId: string) => {
        window.location.assign(WEBSITE_HELP_CENTER_URL + ROUTE_PATH.help.articleBuild(articleId))
    }

    /** Navigate to a certain topic */
    const navigateToTopic = (topic: string) => {
        navigate(buildPath(topic, true))
    }

    const navigateToGuideline = () => {
        navigate(buildPath(ROUTE_PATH.forums.guideline, true))
    }

    //TODO
    // const navigateToUserProfile=(userId:string)=>{

    // }

    return {
        navigateToNewPost,
        navigateToPost,
        navigateToArticle,
        navigateToTopic,
        navigateToGuideline
    }
}
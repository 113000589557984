import { alpha } from "@mui/material";
import { TFunction } from "i18next";
import { EForumTopic } from "../../types/db";
import { ERole } from "../EnumUtility";
import { ROUTE_PATH } from "../navigation";
import { getColorFrom } from "../theme/ColorUtils";

/** The enum for forum topics (for simplicity, will use it for BD, navigation and UI) */
// export enum EForumTopic {
//     COMMUNITY = "community",
//     HOST = "host",
//     COHOST = "cohost",
//     GUEST = "guest"
// }
export { EForumTopic };

/** Return the background color for a given topic, to be used as `{background:getBackgroundForTopic(...)}` in `style` or `sx` */
export const getBackgroundForTopic = (topic: EForumTopic, opacity?: number) => {
    return topic === EForumTopic.COMMUNITY ? `linear-gradient(to right bottom, ${alpha(getColorFrom(ERole.COHOST), opacity || 1)}, ${alpha(getColorFrom(ERole.HOST), opacity || 1)})`
        : alpha(topic === EForumTopic.HOST ? getColorFrom(ERole.HOST) :
            topic === EForumTopic.COHOST ? getColorFrom(ERole.COHOST) :
                getColorFrom(ERole.GUEST), opacity || 1);
}

/** Get the display nme of the given topic */
export const getNameForTopic = (t: TFunction, topic: EForumTopic) => {
    switch (topic) {
        case EForumTopic.COMMUNITY:
            return t('forum.topic.community');
        case EForumTopic.HOST:
            return t('forum.topic.host');
        case EForumTopic.COHOST:
            return t('forum.topic.cohost');
        case EForumTopic.GUEST:
            return t('forum.topic.guest');
        default:
            return "";
    }
}

export const getRouteForTopic = (topic: EForumTopic) => {
    switch (topic) {
        case EForumTopic.COMMUNITY:
            return ROUTE_PATH.forums.community;
        case EForumTopic.HOST:
            return ROUTE_PATH.forums.host;
        case EForumTopic.COHOST:
            return ROUTE_PATH.forums.cohost;
        case EForumTopic.GUEST:
            return ROUTE_PATH.forums.guest;
        default:
            return "";
    }
}
import React from 'react';
import { Avatar, Button, Card, CardActionArea, CardActions, CardContent, Stack, Typography } from '@mui/material';

interface IDateRangeViewProps {
    /** The check-in date */
    checkInDate: Date;
    /** The check-out date */
    checkOutDate: Date;
}
const DateRangeView = ({
    checkInDate,
    checkOutDate,
}: IDateRangeViewProps) => {
    return (
        <Stack direction={'row'} alignItems={'baseline'} spacing={1}>
            <Typography>{checkInDate?.toDateString() ?? '*'}</Typography>
            <Typography>{"-"}</Typography>
            <Typography>{checkOutDate?.toDateString() ?? '*'}</Typography>
        </Stack>
    )
}

export interface IReservationItemProps {
    // status:'current',//determined with the date range
    /** The guest display name */
    displayName: string;
    /** The guest avatar url */
    avatarUrl: string;
    /** The date range of the reservation */
    dateRange: IDateRangeViewProps;
    /** Callback click on view details */
    onDetailsClick: () => void;
    /** Callback click on message (go to chat) */
    onMessageClick: () => void;
}

export const ReservationItem = ({
    displayName,
    avatarUrl,
    dateRange,
    onDetailsClick,
    onMessageClick,
}: IReservationItemProps) => {
    return (
        <Card>
            <CardActionArea onClick={onDetailsClick}>
                <CardContent>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'} spacing={3}>
                        <Stack direction={'column'}>
                            <Typography>{displayName}</Typography>
                            <DateRangeView {...dateRange} />
                        </Stack>
                        <Avatar src={avatarUrl} alt={displayName} />
                    </Stack>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" color="primary" onClick={onMessageClick}>
                    Message
                </Button>
            </CardActions>
        </Card>
    );
}
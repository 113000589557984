import React, { PropsWithChildren, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router";
import { Tabs, Tab, Typography, IconButton, Tooltip, Skeleton } from "@mui/material";
import { Circle, Close, Visibility } from "@mui/icons-material";
import { Box, Stack } from "@mui/system";
import { ROUTE_PARAMS, ROUTE_PATH, ROUTE_SUBPATH } from "../../../utilities/navigation";
import { LISTING_EDIT_TABS } from "./utils";
import { ListingContext, ListingContextProvider } from "../../../context/Listing";
import { PropsWithLoadingState } from "../../../utilities";
import { ListingEditContext, ListingEditProvider } from "../../../context/Listing/ListingEditFlow";
import { getListingInfoFromStatus } from "../../../utilities/listings";

/** The page to manage a certain listing (only the tabs, content of each tab in it's own page) */
export const EditListingPage = () => {
    const navigate = useNavigate();
    const { [ROUTE_PARAMS.listingID]: currentListingId, [ROUTE_PARAMS.settingsTabID]: currentTabId, } = useParams();

    const navigateToTab = (newTabId: string, listingId?: string) => {
        if ((!listingId || listingId === currentListingId) && currentTabId === newTabId) {
            return;//if trying to go to the same link, just return
        }
        const newListingId = listingId || currentListingId
        if (!newListingId) {
            return;
        }
        navigate(ROUTE_PATH.listingSettingsBuild(newListingId, newTabId));
    };
    const navigateToListings = () => {
        navigate(ROUTE_PATH.dashboardBuild("listings"));
    }
    const navigateToPreview = () => {
        if (!currentListingId) return;
        // navigate(ROUTE_PATH.listingBuild(currentListingId))
        window.open(ROUTE_PATH.listingBuild(currentListingId, true), '_blank')
    }

    return (
        <ListingContextProvider listingId={currentListingId}>
            <ListingEditProvider tabId={currentTabId}>
                <EditListingContainer
                    navigateToTab={navigateToTab}
                    navigateToListings={navigateToListings}
                    navigateToPreview={navigateToPreview}>
                    <Outlet />
                </EditListingContainer>
            </ListingEditProvider>
        </ListingContextProvider>
    );
}

interface IEditListingContainerProps {
    /** Callback to navigate to a certain tab */
    navigateToTab: (newTabId: string, anchor?: string) => void;
    /** Callback to close the edit view (navigate back to list of listings) */
    navigateToListings: () => void;
    /** Callback to navigate to the preview */
    navigateToPreview: () => void;
}
const EditListingContainer = ({ navigateToTab, navigateToListings, navigateToPreview, children }: PropsWithChildren<IEditListingContainerProps>) => {
    const listingContext = useContext(ListingContext);
    if (!listingContext) {
        throw new Error('Need ListingContext Provider')
    }
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext Provider')
    }

    const { t } = useTranslation();

    const { listing, isLoading } = listingContext;
    const { activeTab } = listingEditContext;

    const statusInfo = !!listing?.status ? getListingInfoFromStatus(listing.status, t) : undefined

    return <EditListingView
        loading={!!isLoading}
        title={listing?.title || ''}
        status={{
            icon: statusInfo?.icon ?? <Circle />,
            label: statusInfo?.title ?? '',
            onClick: () => navigateToTab(ROUTE_SUBPATH.details)
        }}
        onPreviewClick={navigateToPreview}
        onCloseClick={navigateToListings}
        tabValue={activeTab}
        onTabChange={navigateToTab}
    >
        {children}
    </EditListingView>;
}

interface IEditListingViewProps {
    /** The title of the listing */
    title: string;
    /** The status of the listing */
    status: {
        icon: JSX.Element;
        label: string;
        onClick: () => void;
    };
    /** Callback on the preview button */
    onPreviewClick?: () => void;
    /** Callback on the close button (to navigate back to the list of listings) */
    onCloseClick: () => void;
    /** The current active tab */
    tabValue: string;
    /** Callback on tab changed */
    onTabChange: (newTabValue: string) => void;
}
const EditListingView = ({ loading, title, status, onPreviewClick, onCloseClick, tabValue, onTabChange, children }: PropsWithChildren<PropsWithLoadingState<IEditListingViewProps>>) => {
    const { t } = useTranslation();
    return (
        <Stack direction={'column'} p={2} flex={1}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography variant={"h3"} flex={1}>{loading ? <Skeleton /> : title}</Typography>
                {!!loading ?
                    <IconButton><Circle /></IconButton>
                    :
                    <Tooltip title={status.label}>
                        <IconButton onClick={status.onClick}>{status.icon}</IconButton>
                    </Tooltip>
                }
                {!!onPreviewClick && <IconButton onClick={onPreviewClick}><Visibility /></IconButton>}
                <IconButton onClick={onCloseClick}><Close /></IconButton>
            </Stack>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: '90vw' }}>
                {/* maxWidth to avoid the tabs going over screen when small screen */}
                <Tabs value={tabValue} onChange={(_, value) => onTabChange?.(value)}
                    variant={'scrollable'}
                    scrollButtons={'auto'}
                    allowScrollButtonsMobile={true}
                >
                    {
                        LISTING_EDIT_TABS.map(tab =>
                            <Tab key={tab.id} label={t(tab.labelKey)} value={tab.id} />)
                    }
                </Tabs>
            </Box>
            {children}
        </Stack>
    );
}
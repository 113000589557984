// Utility file for all Hotel.com Listing search
import { reverseGeocode } from "../../../services/firebase";
import { DEFAULT_CURRENCY } from "../../../utilities/currency/function";
import { ROUTE_QUERY } from "../../../utilities/navigation";
import { Filters, ISearchListingItemProps } from "../SearchListingsPage.types";
import { ESupportedPlatform, getPlatformLogo } from "./common";
const headers = {
    'X-RapidAPI-Host': 'hotels4.p.rapidapi.com',
    'X-RapidAPI-Key': '3d394a7680msh6c2f70b58a310ccp174ff1jsn1b630541c4e8',
};

type SearchListingsVariables = {
    /** The currency code to use */
    currency: string,//'USD',
    /** The locale language code */
    locale: string,//'en_US',
    /** The value of EAPID filed returned in .../v2/get-meta-data endpoint. Ex : "eapid": 1 */
    eapid?: number,//1,
    /** The value of siteId field returned in .../v2/get-meta-data endpoint. Ex : "siteId": 300000001 */
    siteId?: number,//300000001,
    /** Specify destination location, refer the value of gaiaId and coordinates fields returned in .../locations/v3/search endpoint for best result.
Ex : "destination": {"coordinates": {"latitude": 33.77005,"longitude": -118.193741},"regionId": "2058"} */
    destination: {
        coordinates?: {
            latitude: number,//33.77005, 
            longitude: number,//-118.193741 
        }
        regionId: string,//'6054439'
    },
    /** The check-in date */
    checkInDate: {
        day: number,//10,
        month: number,//10,
        year: number,//2022
    },
    /** The check-out date */
    checkOutDate: {
        day: number,//15,
        month: number,//10,
        year: number,//2022
    },
    /** Specify rooms with adults and children stayed. Ex : "rooms": [{"adults": 2,"children": [{"age": 5},{"age": 7}]}] */
    rooms: {
        adults: number,//2,
        children: { age: number }[],//[{ age: 5 }, { age: 7 }]
    }[],
    /** The offset of records to ignore, for paging purpose. Ex : "resultsStartingIndex": 0 */
    resultsStartingIndex: number,//0,
    /** Number of items per response, for paging purpose. Ex : "resultsSize": 200 */
    resultsSize: number,//200,
    /** One of the following : PRICE_RELEVANT (Price + our picks)|REVIEW (Guest rating)|DISTANCE (Distance from downtown)|
PRICE_LOW_TO_HIGH (Price)|PROPERTY_CLASS (Star rating)|RECOMMENDED (Recommended)
Ex : "sort": "RECOMMENDED" */
    sort?: 'PRICE_RELEVANT' | 'REVIEW' | 'DISTANCE' | 'PRICE_LOW_TO_HIGH' | 'PROPERTY_CLASS' | 'RECOMMENDED',//'PRICE_LOW_TO_HIGH',
    /** Specify filters for results. */
    filters?: {
        /** Filter by hotel name, refer the value of regionNames -> lastSearchName field returned in .../locations/v3/search.
* "type" must be "HOTEL" */
        hotelName?: '',
        /** Filter by price. Ex : "price": {"max": 150,"min": 30} */
        price?: {
            max: number,//150, 
            min: number,//100
        }
        /** One of the following : 35 (Good 7+)|40 (Very good 8+)|45 (Wonderful 9+)
Ex : "guestRating": "35" */
        guestRating?: '35' | '40' | '45';
        /** One of the following : SIGN_LANGUAGE_INTERPRETER|STAIR_FREE_PATH|SERVICE_ANIMAL|IN_ROOM_ACCESSIBLE|ROLL_IN_SHOWER|ACCESSIBLE_BATHROOM|ELEVATOR|ACCESSIBLE_PARKING
Ex : "accessibility": ["SIGN_LANGUAGE_INTERPRETER","STAIR_FREE_PATH"] */
        accessibility?: ("SIGN_LANGUAGE_INTERPRETER" | "STAIR_FREE_PATH" | "SERVICE_ANIMAL" | "IN_ROOM_ACCESSIBLE" | "ROLL_IN_SHOWER" | "ACCESSIBLE_BATHROOM" | "ELEVATOR" | "ACCESSIBLE_PARKING")[];
        /** One of the following : BUSINESS|FAMILY|LGBT
Ex : "travelerType": ["BUSINESS","FAMILY"] */
        travelerType?: ("BUSINESS" | "FAMILY" | "LGBT")[];
        /** One of the following : FREE_BREAKFAST|HALF_BOARD|FULL_BOARD|ALL_INCLUSIVE
Ex : "mealPlan": ["FREE_BREAKFAST","HALF_BOARD"] */
        mealPlan?: ("FREE_BREAKFAST" | "HALF_BOARD" | "FULL_BOARD" | "ALL_INCLUSIVE")[];
        /** Filter for properties around a point of interest, format is lat,long:regionId. 
         * Ex : "poi":"12.223031,109.247187:6115844"
* Refer destination -> regionId section to get the correct value */
        poi?: string;
        /** Filter for properties with neighborhood. The value of neighborhoods -> regionId field returned right in this endpoint
Ex : "regionId":"553248635976399837" */
        regionId?: string;
        /** One of the following : VILLA|CONDO_RESORT|PENSION|TOWNHOUSE|AGRITOURISM|HOTEL_RESORT|HOLIDAY_PARK|CONDO
Ex : "lodging": ["VILLA","CONDO_RESORT"] */
        lodging?: ("VILLA" | "CONDO_RESORT" | "PENSION" | "TOWNHOUSE" | "AGRITOURISM" | "HOTEL_RESORT" | "HOLIDAY_PARK" | "CONDO")[];
        /** One of the following : FREE_AIRPORT_TRANSPORTATION|OCEAN_VIEW|HOT_TUB|PETS|CASINO|SPA_ON_SITE|CRIB|BALCONY_OR_TERRACE|
PARKING|ELECTRIC_CAR|RESTAURANT_IN_HOTEL|KITCHEN_KITCHENETTE|GYM|POOL|WASHER_DRYER|WATER_PARK|AIR_CONDITIONING|WIFI
Ex : "amenities": ["FREE_AIRPORT_TRANSPORTATION","OCEAN_VIEW"] */
        amenities?: ("FREE_AIRPORT_TRANSPORTATION" | "OCEAN_VIEW" | "HOT_TUB" | "PETS" | "CASINO" | "SPA_ON_SITE" | "CRIB" | "BALCONY_OR_TERRACE" |
            "PARKING" | "ELECTRIC_CAR" | "RESTAURANT_IN_HOTEL" | "KITCHEN_KITCHENETTE" | "GYM" | "POOL" | "WASHER_DRYER" | "WATER_PARK" | "AIR_CONDITIONING" | "WIFI")[];
        /** One of the following : 10 (1-star hotel)|20 (2-star hotel)|30 (3-star hotel)|40 (4-star hotel)|50 (5-star hotel)
Ex : "star": ["40","50"] */
        star?: ("10" | "20" | "30" | "40" | "50")[];
        /** One of the following : FREE_CANCELLATION|PAY_LATER. Ex : "paymentType": ["FREE_CANCELLATION"] */
        paymentType?: ("FREE_CANCELLATION" | "PAY_LATER")[];
        /** One of the following : 0 (Studio)|1|2|3|4. Ex : "bedroomFilter": ["0","1"] */
        bedroomFilter?: ("0" | "1" | "2" | "3" | "4")[];
        /** Only one value : SHOW_AVAILABLE_ONLY. Ex : "availableFilter": "SHOW_AVAILABLE_ONLY" */
        availableFilter?: "SHOW_AVAILABLE_ONLY";
    }
}

/** Get the listings with the defined variables */
const getListings = async (args: SearchListingsVariables) => {
    const url = 'https://hotels4.p.rapidapi.com/properties/v2/list';

    const options: RequestInit = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            ...headers,
        },
        body: JSON.stringify(args)
    };
    // console.log('variables', variables)
    const response = await fetch(url, options);
    const result = (await response.json()) as (typeof mockedListingsResult);
    return result.data.propertySearch.properties;
}

const mapDataToUI = (result: typeof mockedListingsResult.data.propertySearch.properties/*ReturnType<typeof getListings>*/): ISearchListingItemProps[] => {
    return result?.map(item => (
        {
            title: item.name,
            price: { amount: item.price.lead.amount, currencyCode: 'CAD' },
            images: item.propertyImage.image.url,
            rating: { rate: item.reviews.score, numberReview: item.reviews.total },
            onClick: () => window.open(`https://www.hotels.com/h${item.id}.Hotel-Information`, '_blank'),//https://www.hotels.com/h12325893.Hotel-Information
            hostAvatar: getPlatformLogo(ESupportedPlatform.HOTEL_COM),
            lat: item.mapMarker.latLong.latitude,
            lng: item.mapMarker.latLong.longitude,
            platformId: ESupportedPlatform.HOTEL_COM,
        }
    )) ?? [];
}

type SearchLocationVariables = {
    /** Name of countries, cities, districts, places, etc… */
    q: string;
    /** The language code  */
    locale?: string;
    /** The value of languageIdentifier field returned in …/v2/get-meta-data endpoint */
    langid?: number;
    /** The value of siteId field returned in …/v2/get-meta-data endpoint */
    siteid?: number;
}
const getLocation = async ({ langid, siteid, ...args }: SearchLocationVariables) => {
    //need to stringify all variables for URLSearchParams
    const variables: { [key: string]: string } = {
        ...args,
        ...(!!langid ? { langid: langid.toString() } : {}),
        ...(!!siteid ? { siteid: siteid.toString() } : {}),
    };
    const url = `https://hotels4.p.rapidapi.com/locations/v3/search?${(new URLSearchParams(variables)).toString()}`;
    const options = {
        method: 'GET',
        headers,
    };

    const response = await fetch(url, options);
    const result = await response.json() as typeof mockedLocationResult;
    return result;
}

export const queryFn = async (filterValues: Partial<Filters>, filterDefaults: Filters): Promise<ReturnType<typeof mapDataToUI>> => {
    const formatDate = (date: Date) => ({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
    });
    let location = filterValues.location || filterDefaults.location;
    if (typeof location === 'string' || Array.isArray(location)) {
        if (Array.isArray(location)) {
            location = location.join(',');
        }
        if (!location) {
            console.error('No location provided');
            return [];
        }
        const destinationResp = (await getLocation({ q: location })).sr[0];
        return getListings({
            checkInDate: formatDate(filterValues[ROUTE_QUERY.listings_search.check_in_date]
                || filterDefaults[ROUTE_QUERY.listings_search.check_in_date]),
            checkOutDate: formatDate(filterValues[ROUTE_QUERY.listings_search.check_out_date]
                || filterDefaults[ROUTE_QUERY.listings_search.check_out_date]),
            currency: filterValues[ROUTE_QUERY.listings_search.currency]
                || filterDefaults[ROUTE_QUERY.listings_search.currency]
                || DEFAULT_CURRENCY,
            locale: filterValues.locale || "en",
            destination: {
                regionId: destinationResp.gaiaId || "6054439",//default to Vancouver regionId
            },
            resultsStartingIndex: 0,
            resultsSize: 20,
            rooms: [{
                adults: filterValues[ROUTE_QUERY.listings_search.adults_number]
                    || filterDefaults[ROUTE_QUERY.listings_search.adults_number],
                children: [],
            }],
        })
            .then(mapDataToUI)
    }
    console.warn('location not found (trying with reverse geocoding)', location);
    const stringLocation = await reverseGeocode(location.lat, location.lng);
    if (!!stringLocation) {
        return queryFn({ ...filterValues, location: stringLocation }, filterDefaults)
    }
    return [];
}

const mockedLocationResult = {
    "q": "Paris, Denmark",
    "rid": "e81603ce1ef74a75a8dcc4a7d073222f",
    "rc": "OK",
    "sr": [
        {
            "@type": "gaiaRegionResult",
            "index": "0",
            "gaiaId": "722538475313922048",
            "type": "MULTIREGION",
            "subtype": "PROVINCE_STATE",
            "regionNames": {
                "fullName": "Denmark Parish, New Brunswick, Canada",
                "shortName": "Denmark Parish",
                "displayName": "Denmark Parish, New Brunswick, Canada",
                "primaryDisplayName": "Denmark Parish",
                "secondaryDisplayName": "New Brunswick, Canada",
                "lastSearchName": "Denmark Parish"
            },
            "essId": {
                "sourceName": "GAI",
                "sourceId": "722538475313922048"
            },
            "coordinates": {
                "lat": "47.11384804252141",
                "long": "-67.47489517719919"
            },
            "hierarchyInfo": {
                "country": {
                    "name": "Canada",
                    "isoCode2": "CA",
                    "isoCode3": "CAN"
                }
            }
        },
        {
            "@type": "gaiaRegionResult",
            "index": "1",
            "gaiaId": "553248621532512173",
            "type": "POI",
            "regionNames": {
                "fullName": "Branches Parish, Billund, Syddanmark, Denmark",
                "shortName": "Branches Parish",
                "displayName": "Branches Parish, Billund, Syddanmark, Denmark",
                "primaryDisplayName": "Branches Parish",
                "secondaryDisplayName": "Billund, Syddanmark, Denmark",
                "lastSearchName": "Branches Parish"
            },
            "essId": {
                "sourceName": "GAI",
                "sourceId": "553248621532512173"
            },
            "coordinates": {
                "lat": "55.73289",
                "long": "9.07614"
            },
            "hierarchyInfo": {
                "country": {
                    "name": "Denmark",
                    "isoCode2": "DK",
                    "isoCode3": "DNK"
                }
            }
        },
        {
            "@type": "gaiaRegionResult",
            "index": "2",
            "gaiaId": "553248621532512311",
            "type": "POI",
            "regionNames": {
                "fullName": "Sct. Nicolai Parish, Vejle, Syddanmark, Denmark",
                "shortName": "Sct. Nicolai Parish",
                "displayName": "Sct. Nicolai Parish, Vejle, Syddanmark, Denmark",
                "primaryDisplayName": "Sct. Nicolai Parish",
                "secondaryDisplayName": "Vejle, Syddanmark, Denmark",
                "lastSearchName": "Sct. Nicolai Parish"
            },
            "essId": {
                "sourceName": "GAI",
                "sourceId": "553248621532512311"
            },
            "coordinates": {
                "lat": "55.70741",
                "long": "9.53406"
            },
            "hierarchyInfo": {
                "country": {
                    "name": "Denmark",
                    "isoCode2": "DK",
                    "isoCode3": "DNK"
                }
            }
        }
    ]
}

const mockedListingsResult = {
    "data": {
        "propertySearch": {
            "__typename": "PropertySearchResults",
            "filterMetadata": {
                "__typename": "PropertyFilterMetadata",
                "amenities": [
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 7
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 83
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 14
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 82
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 16
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 17
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 19
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 27
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 70
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 71
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 73
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 79
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 80
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 81
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 84
                    },
                    {
                        "__typename": "PropertyAmenityValue",
                        "id": 85
                    }
                ],
                "neighborhoods": [
                    {
                        "__typename": "Neighborhood",
                        "name": "Nha Trang",
                        "regionId": "6054439"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Tran Phu Beach",
                        "regionId": "553248633981709361"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Cam Lam",
                        "regionId": "6182839"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Cam Ranh",
                        "regionId": "6062879"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Ninh Hoa",
                        "regionId": "6130860"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Ninh Van",
                        "regionId": "6054315"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Dien Khanh",
                        "regionId": "553248622945335509"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Dam Mon",
                        "regionId": "6182815"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Dai Lanh",
                        "regionId": "3000002419"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Vĩnh Hải",
                        "regionId": "3000002330"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Loc Tho",
                        "regionId": "553248635950844810"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Vinh Phuoc",
                        "regionId": "553248635934140183"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Van Ninh",
                        "regionId": "6165559"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Phước Lộc",
                        "regionId": "553248635976399837"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Ninh Phuoc",
                        "regionId": "6141623"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Tân Lập",
                        "regionId": "553248635976384387"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Phước Long",
                        "regionId": "553248635976396533"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Khanh Son",
                        "regionId": "553248635924014319"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Nha Trang (CXR-Cam Ranh)",
                        "regionId": "6062877"
                    },
                    {
                        "__typename": "Neighborhood",
                        "name": "Nha Trang Station",
                        "regionId": "6273492"
                    }
                ],
                "priceRange": {
                    "__typename": "PriceRange",
                    "max": 142.82,
                    "min": 104.62
                }
            },
            "universalSortAndFilter": {
                "__typename": "ShoppingSortAndFilters",
                "toolbar": {
                    "__typename": "UIToolbar",
                    "primary": "Sort & Filter",
                    "actions": {
                        "__typename": "UIToolbarActions",
                        "primary": {
                            "__typename": "UITertiaryButton",
                            "primary": null,
                            "action": {
                                "__typename": "ShoppingSortAndFilterAction",
                                "actionType": "CLOSE_AND_DO_NOT_APPLY",
                                "accessibility": null,
                                "analytics": {
                                    "__typename": "ClientSideAnalytics",
                                    "linkName": "close search filters dialog",
                                    "referrerId": "HOT.SR.CloseFilterDialog.Close"
                                }
                            }
                        },
                        "secondaries": [
                            {
                                "__typename": "UITertiaryButton",
                                "primary": "Clear",
                                "disabled": false,
                                "action": {
                                    "__typename": "ShoppingSortAndFilterAction",
                                    "actionType": "CLEAR_DATA",
                                    "accessibility": "All selections now cleared",
                                    "analytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "clear all selections",
                                        "referrerId": "HOT.SR.ClearFilters"
                                    }
                                }
                            }
                        ]
                    }
                },
                "revealAction": {
                    "__typename": "UISecondaryFloatingActionButton",
                    "primary": "Sort & Filter",
                    "action": {
                        "__typename": "ShoppingSortAndFilterAction",
                        "actionType": "OPEN_MODAL",
                        "accessibility": null,
                        "analytics": {
                            "__typename": "ClientSideAnalytics",
                            "linkName": "Launch filters takeover",
                            "referrerId": "HOT.SR:FilterControl"
                        }
                    },
                    "accessibility": "1 filter applied. Change sort or apply more filters.",
                    "badge": 1,
                    "disabled": false,
                    "icon": {
                        "__typename": "Icon",
                        "id": "tune",
                        "description": "reveals sort and filter window",
                        "size": null,
                        "token": "icon__tune",
                        "theme": null
                    }
                },
                "applyAction": {
                    "__typename": "UIPrimaryFloatingActionButton",
                    "primary": "Done",
                    "action": {
                        "__typename": "ShoppingSortAndFilterAction",
                        "actionType": "CLOSE_AND_APPLY",
                        "accessibility": null,
                        "analytics": {
                            "__typename": "ClientSideAnalytics",
                            "linkName": "done search filters dialog",
                            "referrerId": "HOT.SR.FilterControlDone"
                        }
                    },
                    "accessibility": "Apply and close Sort and Filter",
                    "badge": null,
                    "disabled": false,
                    "icon": null
                },
                "filterSections": [
                    {
                        "__typename": "ShoppingSortAndFilterSection",
                        "title": "Search by property name",
                        "fields": [
                            {
                                "__typename": "ShoppingTextInputField",
                                "primary": null,
                                "secondary": null,
                                "action": {
                                    "__typename": "ShoppingSortAndFilterAction",
                                    "actionType": "OPEN_MODAL",
                                    "accessibility": null,
                                    "analytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    }
                                },
                                "id": "hotelName",
                                "label": null,
                                "placeholder": "e.g. Marriott",
                                "selected": null,
                                "typeaheadInfo": {
                                    "__typename": "TypeaheadInfo",
                                    "client": "Hotels.Search",
                                    "isDestination": true,
                                    "lineOfBusiness": "hotels",
                                    "maxNumberOfResults": 10,
                                    "packageType": null,
                                    "personalize": false,
                                    "regionType": 128,
                                    "typeaheadFeatures": "ta_hierarchy|postal_code|contextual_ta|consistent_display"
                                },
                                "icon": {
                                    "__typename": "Icon",
                                    "id": "search",
                                    "description": "magnifying glass",
                                    "size": null,
                                    "token": "icon__search",
                                    "theme": null
                                },
                                "analytics": {
                                    "__typename": "ClientSideAnalytics",
                                    "linkName": "hotelName.",
                                    "referrerId": "HOT.SR.hotelName."
                                }
                            }
                        ]
                    },
                    {
                        "__typename": "ShoppingSortAndFilterSection",
                        "title": "Filter by",
                        "fields": [
                            {
                                "__typename": "ShoppingMultiSelectionField",
                                "primary": "Popular filters",
                                "secondary": null,
                                "expando": {
                                    "__typename": "ShoppingSelectionExpando",
                                    "threshold": 10,
                                    "collapseLabel": "See less",
                                    "expandLabel": "See more",
                                    "collapseAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    },
                                    "expandAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    }
                                },
                                "multiSelectionOptions": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "mealPlan",
                                        "primary": "Breakfast included",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "FREE_BREAKFAST",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "mealPlan.FREE_BREAKFAST",
                                            "referrerId": "HOT.SR.popularFilter.mealPlan.FREE_BREAKFAST.true:0"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "mealPlan.FREE_BREAKFAST",
                                            "referrerId": "HOT.SR.popularFilter.mealPlan.FREE_BREAKFAST.false:0"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Pool",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "pool",
                                            "description": "",
                                            "size": null,
                                            "token": "pool",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "POOL",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.POOL",
                                            "referrerId": "HOT.SR.popularFilter.amenities.POOL.true:1"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.POOL",
                                            "referrerId": "HOT.SR.popularFilter.amenities.POOL.false:1"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Ocean view",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "water",
                                            "description": "",
                                            "size": null,
                                            "token": "water",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "OCEAN_VIEW",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.OCEAN_VIEW",
                                            "referrerId": "HOT.SR.popularFilter.amenities.OCEAN_VIEW.true:2"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.OCEAN_VIEW",
                                            "referrerId": "HOT.SR.popularFilter.amenities.OCEAN_VIEW.false:2"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Nha Trang Night Market",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.23956,109.19592:6172088",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.23956,109.19592:6172088",
                                            "referrerId": "HOT.SR.popularFilter.poi.12.23956,109.19592:6172088.true:3"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.23956,109.19592:6172088",
                                            "referrerId": "HOT.SR.popularFilter.poi.12.23956,109.19592:6172088.false:3"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "lodging",
                                        "primary": "Hotel",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "HOTEL",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.HOTEL",
                                            "referrerId": "HOT.SR.popularFilter.lodging.HOTEL.true:4"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.HOTEL",
                                            "referrerId": "HOT.SR.popularFilter.lodging.HOTEL.false:4"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "ShoppingRangeField",
                                "primary": "Price per night",
                                "secondary": null,
                                "range": {
                                    "__typename": "ShoppingRangeFilterOption",
                                    "id": "price",
                                    "primary": "",
                                    "secondary": null,
                                    "icon": null,
                                    "analytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "price.",
                                        "referrerId": "HOT.SR.price."
                                    },
                                    "selected": {
                                        "__typename": "RangeValue",
                                        "id": "price",
                                        "min": 0,
                                        "max": 300
                                    },
                                    "characteristics": {
                                        "__typename": "ShoppingRangeCharacteristics",
                                        "min": 0,
                                        "max": 300,
                                        "step": 30,
                                        "labels": [
                                            {
                                                "__typename": "ShoppingRangeLabel",
                                                "label": "$0",
                                                "value": 0
                                            },
                                            {
                                                "__typename": "ShoppingRangeLabel",
                                                "label": "$30",
                                                "value": 30
                                            },
                                            {
                                                "__typename": "ShoppingRangeLabel",
                                                "label": "$60",
                                                "value": 60
                                            },
                                            {
                                                "__typename": "ShoppingRangeLabel",
                                                "label": "$90",
                                                "value": 90
                                            },
                                            {
                                                "__typename": "ShoppingRangeLabel",
                                                "label": "$120",
                                                "value": 120
                                            },
                                            {
                                                "__typename": "ShoppingRangeLabel",
                                                "label": "$150",
                                                "value": 150
                                            },
                                            {
                                                "__typename": "ShoppingRangeLabel",
                                                "label": "$180",
                                                "value": 180
                                            },
                                            {
                                                "__typename": "ShoppingRangeLabel",
                                                "label": "$210",
                                                "value": 210
                                            },
                                            {
                                                "__typename": "ShoppingRangeLabel",
                                                "label": "$240",
                                                "value": 240
                                            },
                                            {
                                                "__typename": "ShoppingRangeLabel",
                                                "label": "$270",
                                                "value": 270
                                            },
                                            {
                                                "__typename": "ShoppingRangeLabel",
                                                "label": "$300+",
                                                "value": 300
                                            }
                                        ]
                                    }
                                }
                            },
                            {
                                "__typename": "ShoppingSelectionField",
                                "primary": "Guest rating",
                                "secondary": null,
                                "expando": {
                                    "__typename": "ShoppingSelectionExpando",
                                    "threshold": 10,
                                    "collapseLabel": "See less",
                                    "expandLabel": "See more",
                                    "collapseAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    },
                                    "expandAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    }
                                },
                                "options": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "guestRating",
                                        "primary": "Any",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "",
                                        "description": null,
                                        "selected": true,
                                        "disabled": false,
                                        "default": true,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "guestRating.ANY",
                                            "referrerId": "HOT.SR.guestRating.ANY.true:0"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "guestRating.ANY",
                                            "referrerId": "HOT.SR.guestRating.ANY.false:0"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "guestRating",
                                        "primary": "Wonderful 9+",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "45",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "guestRating.45",
                                            "referrerId": "HOT.SR.guestRating.45.true:1"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "guestRating.45",
                                            "referrerId": "HOT.SR.guestRating.45.false:1"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "guestRating",
                                        "primary": "Very good 8+",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "40",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "guestRating.40",
                                            "referrerId": "HOT.SR.guestRating.40.true:2"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "guestRating.40",
                                            "referrerId": "HOT.SR.guestRating.40.false:2"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "guestRating",
                                        "primary": "Good 7+",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "35",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "guestRating.35",
                                            "referrerId": "HOT.SR.guestRating.35.true:3"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "guestRating.35",
                                            "referrerId": "HOT.SR.guestRating.35.false:3"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "ShoppingMultiSelectionStackedTileField",
                                "primary": "Star rating",
                                "secondary": null,
                                "tileMultiSelectionOptions": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "star",
                                        "primary": "1",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "star",
                                            "description": "star",
                                            "size": null,
                                            "token": "icon__star",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "10",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "star.10",
                                            "referrerId": "HOT.SR.star.10.true:0"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "star.10",
                                            "referrerId": "HOT.SR.star.10.false:0"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "star",
                                        "primary": "2",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "star",
                                            "description": "star",
                                            "size": null,
                                            "token": "icon__star",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "20",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "star.20",
                                            "referrerId": "HOT.SR.star.20.true:1"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "star.20",
                                            "referrerId": "HOT.SR.star.20.false:1"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "star",
                                        "primary": "3",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "star",
                                            "description": "star",
                                            "size": null,
                                            "token": "icon__star",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "30",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "star.30",
                                            "referrerId": "HOT.SR.star.30.true:2"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "star.30",
                                            "referrerId": "HOT.SR.star.30.false:2"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "star",
                                        "primary": "4",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "star",
                                            "description": "star",
                                            "size": null,
                                            "token": "icon__star",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "40",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "star.40",
                                            "referrerId": "HOT.SR.star.40.true:3"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "star.40",
                                            "referrerId": "HOT.SR.star.40.false:3"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "star",
                                        "primary": "5",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "star",
                                            "description": "star",
                                            "size": null,
                                            "token": "icon__star",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "50",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "star.50",
                                            "referrerId": "HOT.SR.star.50.true:4"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "star.50",
                                            "referrerId": "HOT.SR.star.50.false:4"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "ShoppingMultiSelectionField",
                                "primary": "Payment type",
                                "secondary": null,
                                "expando": {
                                    "__typename": "ShoppingSelectionExpando",
                                    "threshold": 3,
                                    "collapseLabel": "See less",
                                    "expandLabel": "See more",
                                    "collapseAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    },
                                    "expandAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    }
                                },
                                "multiSelectionOptions": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "paymentType",
                                        "primary": "Reserve now, pay later",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "PAY_LATER",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "paymentType.PAY_LATER",
                                            "referrerId": "HOT.SR.paymentType.PAY_LATER.true:0"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "paymentType.PAY_LATER",
                                            "referrerId": "HOT.SR.paymentType.PAY_LATER.false:0"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "paymentType",
                                        "primary": "Pay with Hotels.com gift card",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "GIFT_CARD",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "paymentType.GIFT_CARD",
                                            "referrerId": "HOT.SR.paymentType.GIFT_CARD.true:1"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "paymentType.GIFT_CARD",
                                            "referrerId": "HOT.SR.paymentType.GIFT_CARD.false:1"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "paymentType",
                                        "primary": "Reserve without a credit card",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "PAY_WITHOUT_CREDITCARD",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "paymentType.PAY_WITHOUT_CREDITCARD",
                                            "referrerId": "HOT.SR.paymentType.PAY_WITHOUT_CREDITCARD.true:2"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "paymentType.PAY_WITHOUT_CREDITCARD",
                                            "referrerId": "HOT.SR.paymentType.PAY_WITHOUT_CREDITCARD.false:2"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "ShoppingMultiSelectionField",
                                "primary": "Property type",
                                "secondary": null,
                                "expando": {
                                    "__typename": "ShoppingSelectionExpando",
                                    "threshold": 3,
                                    "collapseLabel": "See less",
                                    "expandLabel": "See more",
                                    "collapseAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    },
                                    "expandAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    }
                                },
                                "multiSelectionOptions": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "lodging",
                                        "primary": "Hotel",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "HOTEL",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.HOTEL",
                                            "referrerId": "HOT.SR.lodging.HOTEL.true:0"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.HOTEL",
                                            "referrerId": "HOT.SR.lodging.HOTEL.false:0"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "lodging",
                                        "primary": "Hotel resort",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "HOTEL_RESORT",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.HOTEL_RESORT",
                                            "referrerId": "HOT.SR.lodging.HOTEL_RESORT.true:1"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.HOTEL_RESORT",
                                            "referrerId": "HOT.SR.lodging.HOTEL_RESORT.false:1"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "lodging",
                                        "primary": "Apartment",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "APARTMENT",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.APARTMENT",
                                            "referrerId": "HOT.SR.lodging.APARTMENT.true:2"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.APARTMENT",
                                            "referrerId": "HOT.SR.lodging.APARTMENT.false:2"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "lodging",
                                        "primary": "Villa",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "VILLA",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.VILLA",
                                            "referrerId": "HOT.SR.lodging.VILLA.true:3"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.VILLA",
                                            "referrerId": "HOT.SR.lodging.VILLA.false:3"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "lodging",
                                        "primary": "Guest house",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "GUEST_HOUSE",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.GUEST_HOUSE",
                                            "referrerId": "HOT.SR.lodging.GUEST_HOUSE.true:4"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.GUEST_HOUSE",
                                            "referrerId": "HOT.SR.lodging.GUEST_HOUSE.false:4"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "lodging",
                                        "primary": "Apart-hotel",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "APART_HOTEL",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.APART_HOTEL",
                                            "referrerId": "HOT.SR.lodging.APART_HOTEL.true:5"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.APART_HOTEL",
                                            "referrerId": "HOT.SR.lodging.APART_HOTEL.false:5"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "lodging",
                                        "primary": "House",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "VACATION_HOME",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.VACATION_HOME",
                                            "referrerId": "HOT.SR.lodging.VACATION_HOME.true:6"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.VACATION_HOME",
                                            "referrerId": "HOT.SR.lodging.VACATION_HOME.false:6"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "lodging",
                                        "primary": "Condo",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "CONDO",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.CONDO",
                                            "referrerId": "HOT.SR.lodging.CONDO.true:7"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "lodging.CONDO",
                                            "referrerId": "HOT.SR.lodging.CONDO.false:7"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "ShoppingMultiSelectionStackedTileField",
                                "primary": "Number of bedrooms",
                                "secondary": null,
                                "tileMultiSelectionOptions": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "bedroomFilter",
                                        "primary": "Studio",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "0",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "bedroomFilter.0",
                                            "referrerId": "HOT.SR.bedroomFilter.0.true:0"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "bedroomFilter.0",
                                            "referrerId": "HOT.SR.bedroomFilter.0.false:0"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "bedroomFilter",
                                        "primary": "1",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "1",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "bedroomFilter.1",
                                            "referrerId": "HOT.SR.bedroomFilter.1.true:1"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "bedroomFilter.1",
                                            "referrerId": "HOT.SR.bedroomFilter.1.false:1"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "bedroomFilter",
                                        "primary": "2",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "2",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "bedroomFilter.2",
                                            "referrerId": "HOT.SR.bedroomFilter.2.true:2"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "bedroomFilter.2",
                                            "referrerId": "HOT.SR.bedroomFilter.2.false:2"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "bedroomFilter",
                                        "primary": "3",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "3",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "bedroomFilter.3",
                                            "referrerId": "HOT.SR.bedroomFilter.3.true:3"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "bedroomFilter.3",
                                            "referrerId": "HOT.SR.bedroomFilter.3.false:3"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "bedroomFilter",
                                        "primary": "4+",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "4",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "bedroomFilter.4",
                                            "referrerId": "HOT.SR.bedroomFilter.4.true:4"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "bedroomFilter.4",
                                            "referrerId": "HOT.SR.bedroomFilter.4.false:4"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "ShoppingSelectionField",
                                "primary": "Neighborhood",
                                "secondary": null,
                                "expando": {
                                    "__typename": "ShoppingSelectionExpando",
                                    "threshold": 3,
                                    "collapseLabel": "See less",
                                    "expandLabel": "See more",
                                    "collapseAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    },
                                    "expandAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    }
                                },
                                "options": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "regionId",
                                        "primary": "Nha Trang",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "6054439",
                                        "description": null,
                                        "selected": true,
                                        "disabled": false,
                                        "default": true,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "regionId.6054439",
                                            "referrerId": "HOT.SR.regionId.6054439.true:0"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "regionId.6054439",
                                            "referrerId": "HOT.SR.regionId.6054439.false:0"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "regionId",
                                        "primary": "Tran Phu Beach",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "553248633981709361",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "regionId.553248633981709361",
                                            "referrerId": "HOT.SR.regionId.553248633981709361.true:1"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "regionId.553248633981709361",
                                            "referrerId": "HOT.SR.regionId.553248633981709361.false:1"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "regionId",
                                        "primary": "Phước Lộc",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "553248635976399837",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "regionId.553248635976399837",
                                            "referrerId": "HOT.SR.regionId.553248635976399837.true:2"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "regionId.553248635976399837",
                                            "referrerId": "HOT.SR.regionId.553248635976399837.false:2"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "ShoppingSelectionField",
                                "primary": "Popular locations",
                                "secondary": null,
                                "expando": {
                                    "__typename": "ShoppingSelectionExpando",
                                    "threshold": 3,
                                    "collapseLabel": "See less",
                                    "expandLabel": "See more",
                                    "collapseAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    },
                                    "expandAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    }
                                },
                                "options": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Nha Trang Night Market",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.23956,109.19592:6172088",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.23956,109.19592:6172088",
                                            "referrerId": "HOT.SR.poi.12.23956,109.19592:6172088.true:0"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.23956,109.19592:6172088",
                                            "referrerId": "HOT.SR.poi.12.23956,109.19592:6172088.false:0"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Tram Huong Tower",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.24056,109.19687:6182825",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.24056,109.19687:6182825",
                                            "referrerId": "HOT.SR.poi.12.24056,109.19687:6182825.true:1"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.24056,109.19687:6182825",
                                            "referrerId": "HOT.SR.poi.12.24056,109.19687:6182825.false:1"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Vinpearl Beach",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.22261,109.24797:6115844",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.22261,109.24797:6115844",
                                            "referrerId": "HOT.SR.poi.12.22261,109.24797:6115844.true:2"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.22261,109.24797:6115844",
                                            "referrerId": "HOT.SR.poi.12.22261,109.24797:6115844.false:2"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Long Thanh Gallery",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.25057,109.18709:6183078",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.25057,109.18709:6183078",
                                            "referrerId": "HOT.SR.poi.12.25057,109.18709:6183078.true:3"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.25057,109.18709:6183078",
                                            "referrerId": "HOT.SR.poi.12.25057,109.18709:6183078.false:3"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Nha Trang Port",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.20589,109.21654:6147624",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.20589,109.21654:6147624",
                                            "referrerId": "HOT.SR.poi.12.20589,109.21654:6147624.true:4"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.20589,109.21654:6147624",
                                            "referrerId": "HOT.SR.poi.12.20589,109.21654:6147624.false:4"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "I-Resort Nha Trang",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.27142,109.17484:553248635962295342",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.27142,109.17484:553248635962295342",
                                            "referrerId": "HOT.SR.poi.12.27142,109.17484:553248635962295342.true:5"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.27142,109.17484:553248635962295342",
                                            "referrerId": "HOT.SR.poi.12.27142,109.17484:553248635962295342.false:5"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Thap Ba Hot Spring Center",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.27007,109.17780:6172074",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.27007,109.17780:6172074",
                                            "referrerId": "HOT.SR.poi.12.27007,109.17780:6172074.true:6"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.27007,109.17780:6172074",
                                            "referrerId": "HOT.SR.poi.12.27007,109.17780:6172074.false:6"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Hon Mun Island",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.16615,109.30248:553248621562620074",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.16615,109.30248:553248621562620074",
                                            "referrerId": "HOT.SR.poi.12.16615,109.30248:553248621562620074.true:7"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.16615,109.30248:553248621562620074",
                                            "referrerId": "HOT.SR.poi.12.16615,109.30248:553248621562620074.false:7"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Bai Duong Beach",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.27644,109.20254:6183082",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.27644,109.20254:6183082",
                                            "referrerId": "HOT.SR.poi.12.27644,109.20254:6183082.true:8"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.27644,109.20254:6183082",
                                            "referrerId": "HOT.SR.poi.12.27644,109.20254:6183082.false:8"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "April 2 Square",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.23935,109.19706:6182831",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.23935,109.19706:6182831",
                                            "referrerId": "HOT.SR.poi.12.23935,109.19706:6182831.true:9"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.23935,109.19706:6182831",
                                            "referrerId": "HOT.SR.poi.12.23935,109.19706:6182831.false:9"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Diamond Bay Golf Course",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.17295,109.19128:6182821",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.17295,109.19128:6182821",
                                            "referrerId": "HOT.SR.poi.12.17295,109.19128:6182821.true:10"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.17295,109.19128:6182821",
                                            "referrerId": "HOT.SR.poi.12.17295,109.19128:6182821.false:10"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Any",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "",
                                        "description": null,
                                        "selected": true,
                                        "disabled": false,
                                        "default": true,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.ANY",
                                            "referrerId": "HOT.SR.poi.ANY.true:11"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.ANY",
                                            "referrerId": "HOT.SR.poi.ANY.false:11"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Long Son Pagoda",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.25204,109.18090:6115840",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.25204,109.18090:6115840",
                                            "referrerId": "HOT.SR.poi.12.25204,109.18090:6115840.true:12"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.25204,109.18090:6115840",
                                            "referrerId": "HOT.SR.poi.12.25204,109.18090:6115840.false:12"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Vincom Plaza Le Thanh Ton",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.24330,109.19353:553248634766219589",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.24330,109.19353:553248634766219589",
                                            "referrerId": "HOT.SR.poi.12.24330,109.19353:553248634766219589.true:13"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.24330,109.19353:553248634766219589",
                                            "referrerId": "HOT.SR.poi.12.24330,109.19353:553248634766219589.false:13"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Po Nagar Cham Towers",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.26533,109.19529:6115842",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.26533,109.19529:6115842",
                                            "referrerId": "HOT.SR.poi.12.26533,109.19529:6115842.true:14"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.26533,109.19529:6115842",
                                            "referrerId": "HOT.SR.poi.12.26533,109.19529:6115842.false:14"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Dam Market",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.25441,109.19221:6158140",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.25441,109.19221:6158140",
                                            "referrerId": "HOT.SR.poi.12.25441,109.19221:6158140.true:15"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.25441,109.19221:6158140",
                                            "referrerId": "HOT.SR.poi.12.25441,109.19221:6158140.false:15"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Louisiane Brewhouse",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.23134,109.19861:6182835",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.23134,109.19861:6182835",
                                            "referrerId": "HOT.SR.poi.12.23134,109.19861:6182835.true:16"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.23134,109.19861:6182835",
                                            "referrerId": "HOT.SR.poi.12.23134,109.19861:6182835.false:16"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Hon Chong Promontory",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.27291,109.20647:553248621526918229",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.27291,109.20647:553248621526918229",
                                            "referrerId": "HOT.SR.poi.12.27291,109.20647:553248621526918229.true:17"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.27291,109.20647:553248621526918229",
                                            "referrerId": "HOT.SR.poi.12.27291,109.20647:553248621526918229.false:17"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Do Dien Khanh Gallery",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.23842,109.19189:553248621526918226",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.23842,109.19189:553248621526918226",
                                            "referrerId": "HOT.SR.poi.12.23842,109.19189:553248621526918226.true:18"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.23842,109.19189:553248621526918226",
                                            "referrerId": "HOT.SR.poi.12.23842,109.19189:553248621526918226.false:18"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "poi",
                                        "primary": "Vinpearl Golf Club",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "12.22716,109.19286:553248621532742813",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.22716,109.19286:553248621532742813",
                                            "referrerId": "HOT.SR.poi.12.22716,109.19286:553248621532742813.true:19"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "poi.12.22716,109.19286:553248621532742813",
                                            "referrerId": "HOT.SR.poi.12.22716,109.19286:553248621532742813.false:19"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "ShoppingMultiSelectionField",
                                "primary": "Meal plans available",
                                "secondary": null,
                                "expando": {
                                    "__typename": "ShoppingSelectionExpando",
                                    "threshold": 4,
                                    "collapseLabel": "See less",
                                    "expandLabel": "See more",
                                    "collapseAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    },
                                    "expandAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    }
                                },
                                "multiSelectionOptions": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "mealPlan",
                                        "primary": "Breakfast included",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "FREE_BREAKFAST",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "mealPlan.FREE_BREAKFAST",
                                            "referrerId": "HOT.SR.mealPlan.FREE_BREAKFAST.true:0"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "mealPlan.FREE_BREAKFAST",
                                            "referrerId": "HOT.SR.mealPlan.FREE_BREAKFAST.false:0"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "mealPlan",
                                        "primary": "All inclusive",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "ALL_INCLUSIVE",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "mealPlan.ALL_INCLUSIVE",
                                            "referrerId": "HOT.SR.mealPlan.ALL_INCLUSIVE.true:1"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "mealPlan.ALL_INCLUSIVE",
                                            "referrerId": "HOT.SR.mealPlan.ALL_INCLUSIVE.false:1"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "mealPlan",
                                        "primary": "Dinner included",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "FULL_BOARD",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "mealPlan.FULL_BOARD",
                                            "referrerId": "HOT.SR.mealPlan.FULL_BOARD.true:2"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "mealPlan.FULL_BOARD",
                                            "referrerId": "HOT.SR.mealPlan.FULL_BOARD.false:2"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "mealPlan",
                                        "primary": "Lunch included",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "HALF_BOARD",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "mealPlan.HALF_BOARD",
                                            "referrerId": "HOT.SR.mealPlan.HALF_BOARD.true:3"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "mealPlan.HALF_BOARD",
                                            "referrerId": "HOT.SR.mealPlan.HALF_BOARD.false:3"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "ShoppingMultiSelectionTileField",
                                "primary": "Amenities",
                                "secondary": null,
                                "tileMultiSelectionOptions": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Pool",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "pool",
                                            "description": "",
                                            "size": null,
                                            "token": "pool",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "POOL",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.POOL",
                                            "referrerId": "HOT.SR.amenities.POOL.true:0"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.POOL",
                                            "referrerId": "HOT.SR.amenities.POOL.false:0"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Ocean view",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "water",
                                            "description": "",
                                            "size": null,
                                            "token": "water",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "OCEAN_VIEW",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.OCEAN_VIEW",
                                            "referrerId": "HOT.SR.amenities.OCEAN_VIEW.true:1"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.OCEAN_VIEW",
                                            "referrerId": "HOT.SR.amenities.OCEAN_VIEW.false:1"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "WiFi included",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "wifi",
                                            "description": "",
                                            "size": null,
                                            "token": "wifi",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "WIFI",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.WIFI",
                                            "referrerId": "HOT.SR.amenities.WIFI.true:2"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.WIFI",
                                            "referrerId": "HOT.SR.amenities.WIFI.false:2"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Kitchen",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "kitchen",
                                            "description": "",
                                            "size": null,
                                            "token": "kitchen",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "KITCHEN_KITCHENETTE",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.KITCHEN_KITCHENETTE",
                                            "referrerId": "HOT.SR.amenities.KITCHEN_KITCHENETTE.true:3"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.KITCHEN_KITCHENETTE",
                                            "referrerId": "HOT.SR.amenities.KITCHEN_KITCHENETTE.false:3"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Pet friendly",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "pets",
                                            "description": "",
                                            "size": null,
                                            "token": "pets",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "PETS",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.PETS",
                                            "referrerId": "HOT.SR.amenities.PETS.true:4"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.PETS",
                                            "referrerId": "HOT.SR.amenities.PETS.false:4"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Air conditioned",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "ac_unit",
                                            "description": "",
                                            "size": null,
                                            "token": "ac_unit",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "AIR_CONDITIONING",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.AIR_CONDITIONING",
                                            "referrerId": "HOT.SR.amenities.AIR_CONDITIONING.true:5"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.AIR_CONDITIONING",
                                            "referrerId": "HOT.SR.amenities.AIR_CONDITIONING.false:5"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Gym",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "fitness_center",
                                            "description": "",
                                            "size": null,
                                            "token": "fitness_center",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "GYM",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.GYM",
                                            "referrerId": "HOT.SR.amenities.GYM.true:6"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.GYM",
                                            "referrerId": "HOT.SR.amenities.GYM.false:6"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Parking",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "local_parking",
                                            "description": "",
                                            "size": null,
                                            "token": "local_parking",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "PARKING",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.PARKING",
                                            "referrerId": "HOT.SR.amenities.PARKING.true:7"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.PARKING",
                                            "referrerId": "HOT.SR.amenities.PARKING.false:7"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Water park",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "water_park",
                                            "description": "",
                                            "size": null,
                                            "token": "water_park",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "WATER_PARK",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.WATER_PARK",
                                            "referrerId": "HOT.SR.amenities.WATER_PARK.true:8"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.WATER_PARK",
                                            "referrerId": "HOT.SR.amenities.WATER_PARK.false:8"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Outdoor space",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "balcony",
                                            "description": "",
                                            "size": null,
                                            "token": "balcony",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "BALCONY_OR_TERRACE",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.BALCONY_OR_TERRACE",
                                            "referrerId": "HOT.SR.amenities.BALCONY_OR_TERRACE.true:9"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.BALCONY_OR_TERRACE",
                                            "referrerId": "HOT.SR.amenities.BALCONY_OR_TERRACE.false:9"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Restaurant",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "local_dining",
                                            "description": "",
                                            "size": null,
                                            "token": "local_dining",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "RESTAURANT_IN_HOTEL",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.RESTAURANT_IN_HOTEL",
                                            "referrerId": "HOT.SR.amenities.RESTAURANT_IN_HOTEL.true:10"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.RESTAURANT_IN_HOTEL",
                                            "referrerId": "HOT.SR.amenities.RESTAURANT_IN_HOTEL.false:10"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Cribs",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "bedding__crib",
                                            "description": "",
                                            "size": null,
                                            "token": "bedding__crib",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "CRIB",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.CRIB",
                                            "referrerId": "HOT.SR.amenities.CRIB.true:11"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.CRIB",
                                            "referrerId": "HOT.SR.amenities.CRIB.false:11"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Electric car charging station",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "ev_station",
                                            "description": "",
                                            "size": null,
                                            "token": "ev_station",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "ELECTRIC_CAR",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.ELECTRIC_CAR",
                                            "referrerId": "HOT.SR.amenities.ELECTRIC_CAR.true:12"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.ELECTRIC_CAR",
                                            "referrerId": "HOT.SR.amenities.ELECTRIC_CAR.false:12"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Washer and dryer",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "local_laundry_service",
                                            "description": "",
                                            "size": null,
                                            "token": "local_laundry_service",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "WASHER_DRYER",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.WASHER_DRYER",
                                            "referrerId": "HOT.SR.amenities.WASHER_DRYER.true:13"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.WASHER_DRYER",
                                            "referrerId": "HOT.SR.amenities.WASHER_DRYER.false:13"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Airport shuttle included",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "airport_shuttle",
                                            "description": "",
                                            "size": null,
                                            "token": "airport_shuttle",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "FREE_AIRPORT_TRANSPORTATION",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.FREE_AIRPORT_TRANSPORTATION",
                                            "referrerId": "HOT.SR.amenities.FREE_AIRPORT_TRANSPORTATION.true:14"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.FREE_AIRPORT_TRANSPORTATION",
                                            "referrerId": "HOT.SR.amenities.FREE_AIRPORT_TRANSPORTATION.false:14"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Spa",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "spa",
                                            "description": "",
                                            "size": null,
                                            "token": "spa",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "SPA_ON_SITE",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.SPA_ON_SITE",
                                            "referrerId": "HOT.SR.amenities.SPA_ON_SITE.true:15"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.SPA_ON_SITE",
                                            "referrerId": "HOT.SR.amenities.SPA_ON_SITE.false:15"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Casino",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "casino",
                                            "description": "",
                                            "size": null,
                                            "token": "casino",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "CASINO",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.CASINO",
                                            "referrerId": "HOT.SR.amenities.CASINO.true:16"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.CASINO",
                                            "referrerId": "HOT.SR.amenities.CASINO.false:16"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "amenities",
                                        "primary": "Hot tub",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "hot_tub",
                                            "description": "",
                                            "size": null,
                                            "token": "hot_tub",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "HOT_TUB",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.HOT_TUB",
                                            "referrerId": "HOT.SR.amenities.HOT_TUB.true:17"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "amenities.HOT_TUB",
                                            "referrerId": "HOT.SR.amenities.HOT_TUB.false:17"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "ShoppingMultiSelectionField",
                                "primary": "Hotels.com® Rewards",
                                "secondary": null,
                                "expando": {
                                    "__typename": "ShoppingSelectionExpando",
                                    "threshold": 3,
                                    "collapseLabel": "See less",
                                    "expandLabel": "See more",
                                    "collapseAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    },
                                    "expandAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    }
                                },
                                "multiSelectionOptions": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "rewards",
                                        "primary": "Redeem reward nights",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "REDEEM_REWARD_NIGHTS",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "rewards.REDEEM_REWARD_NIGHTS",
                                            "referrerId": "HOT.SR.rewards.REDEEM_REWARD_NIGHTS.true:0"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "rewards.REDEEM_REWARD_NIGHTS",
                                            "referrerId": "HOT.SR.rewards.REDEEM_REWARD_NIGHTS.false:0"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "rewards",
                                        "primary": "VIP Access properties for superior stays",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "VIP",
                                        "description": "Plus extra benefits for Silver and Gold members",
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "rewards.VIP",
                                            "referrerId": "HOT.SR.rewards.VIP.true:1"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "rewards.VIP",
                                            "referrerId": "HOT.SR.rewards.VIP.false:1"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "ShoppingMultiSelectionField",
                                "primary": "Accessibility",
                                "secondary": null,
                                "expando": {
                                    "__typename": "ShoppingSelectionExpando",
                                    "threshold": 3,
                                    "collapseLabel": "See less",
                                    "expandLabel": "See more",
                                    "collapseAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    },
                                    "expandAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    }
                                },
                                "multiSelectionOptions": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "accessibility",
                                        "primary": "Wheelchair-accessible parking",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "ACCESSIBLE_PARKING",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.ACCESSIBLE_PARKING",
                                            "referrerId": "HOT.SR.accessibility.ACCESSIBLE_PARKING.true:0"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.ACCESSIBLE_PARKING",
                                            "referrerId": "HOT.SR.accessibility.ACCESSIBLE_PARKING.false:0"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "accessibility",
                                        "primary": "Roll-in shower",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "ROLL_IN_SHOWER",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.ROLL_IN_SHOWER",
                                            "referrerId": "HOT.SR.accessibility.ROLL_IN_SHOWER.true:1"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.ROLL_IN_SHOWER",
                                            "referrerId": "HOT.SR.accessibility.ROLL_IN_SHOWER.false:1"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "accessibility",
                                        "primary": "Service animals allowed",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "SERVICE_ANIMAL",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.SERVICE_ANIMAL",
                                            "referrerId": "HOT.SR.accessibility.SERVICE_ANIMAL.true:2"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.SERVICE_ANIMAL",
                                            "referrerId": "HOT.SR.accessibility.SERVICE_ANIMAL.false:2"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "accessibility",
                                        "primary": "In-room accessibility",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "IN_ROOM_ACCESSIBLE",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.IN_ROOM_ACCESSIBLE",
                                            "referrerId": "HOT.SR.accessibility.IN_ROOM_ACCESSIBLE.true:3"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.IN_ROOM_ACCESSIBLE",
                                            "referrerId": "HOT.SR.accessibility.IN_ROOM_ACCESSIBLE.false:3"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "accessibility",
                                        "primary": "Sign language-capable staff",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "SIGN_LANGUAGE_INTERPRETER",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.SIGN_LANGUAGE_INTERPRETER",
                                            "referrerId": "HOT.SR.accessibility.SIGN_LANGUAGE_INTERPRETER.true:4"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.SIGN_LANGUAGE_INTERPRETER",
                                            "referrerId": "HOT.SR.accessibility.SIGN_LANGUAGE_INTERPRETER.false:4"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "accessibility",
                                        "primary": "Elevator",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "ELEVATOR",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.ELEVATOR",
                                            "referrerId": "HOT.SR.accessibility.ELEVATOR.true:5"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.ELEVATOR",
                                            "referrerId": "HOT.SR.accessibility.ELEVATOR.false:5"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "accessibility",
                                        "primary": "Accessible bathroom",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "ACCESSIBLE_BATHROOM",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.ACCESSIBLE_BATHROOM",
                                            "referrerId": "HOT.SR.accessibility.ACCESSIBLE_BATHROOM.true:6"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.ACCESSIBLE_BATHROOM",
                                            "referrerId": "HOT.SR.accessibility.ACCESSIBLE_BATHROOM.false:6"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "accessibility",
                                        "primary": "Stair-free path to entrance",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "STAIR_FREE_PATH",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.STAIR_FREE_PATH",
                                            "referrerId": "HOT.SR.accessibility.STAIR_FREE_PATH.true:7"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "accessibility.STAIR_FREE_PATH",
                                            "referrerId": "HOT.SR.accessibility.STAIR_FREE_PATH.false:7"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "ShoppingMultiSelectionField",
                                "primary": "Traveler experience",
                                "secondary": null,
                                "expando": {
                                    "__typename": "ShoppingSelectionExpando",
                                    "threshold": 4,
                                    "collapseLabel": "See less",
                                    "expandLabel": "See more",
                                    "collapseAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    },
                                    "expandAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    }
                                },
                                "multiSelectionOptions": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "travelerType",
                                        "primary": "LGBTQ welcoming",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "LGBT",
                                        "description": "See properties that pledge to make all guests feel safe, welcome, and respected.",
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "travelerType.LGBT",
                                            "referrerId": "HOT.SR.travelerType.LGBT.true:0"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "travelerType.LGBT",
                                            "referrerId": "HOT.SR.travelerType.LGBT.false:0"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "travelerType",
                                        "primary": "Business friendly",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "BUSINESS",
                                        "description": "See properties with amenities to help you work comfortably, like WiFi and breakfast.",
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "travelerType.BUSINESS",
                                            "referrerId": "HOT.SR.travelerType.BUSINESS.true:1"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "travelerType.BUSINESS",
                                            "referrerId": "HOT.SR.travelerType.BUSINESS.false:1"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "travelerType",
                                        "primary": "Family friendly",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "FAMILY",
                                        "description": "See properties that include family essentials like in-room conveniences and activities for the kids.",
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "travelerType.FAMILY",
                                            "referrerId": "HOT.SR.travelerType.FAMILY.true:2"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "travelerType.FAMILY",
                                            "referrerId": "HOT.SR.travelerType.FAMILY.false:2"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "ShoppingMultiSelectionField",
                                "primary": "Availability",
                                "secondary": null,
                                "expando": {
                                    "__typename": "ShoppingSelectionExpando",
                                    "threshold": 3,
                                    "collapseLabel": "See less",
                                    "expandLabel": "See more",
                                    "collapseAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    },
                                    "expandAnalytics": {
                                        "__typename": "ClientSideAnalytics",
                                        "linkName": "",
                                        "referrerId": ""
                                    }
                                },
                                "multiSelectionOptions": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "availableFilter",
                                        "primary": "Only show available properties",
                                        "secondary": null,
                                        "icon": {
                                            "__typename": "Icon",
                                            "id": "",
                                            "description": "",
                                            "size": null,
                                            "token": "",
                                            "theme": null
                                        },
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "SHOW_AVAILABLE_ONLY",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "availableFilter.SHOW_AVAILABLE_ONLY",
                                            "referrerId": "HOT.SR.availableFilter.SHOW_AVAILABLE_ONLY.true:0"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "availableFilter.SHOW_AVAILABLE_ONLY",
                                            "referrerId": "HOT.SR.availableFilter.SHOW_AVAILABLE_ONLY.false:0"
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "sortSections": [
                    {
                        "__typename": "ShoppingSortAndFilterSection",
                        "title": null,
                        "fields": [
                            {
                                "__typename": "ShoppingDropdownField",
                                "primary": "Sort by",
                                "secondary": null,
                                "dropdownFilterOptions": [
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "sort",
                                        "primary": "Recommended",
                                        "secondary": null,
                                        "icon": null,
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "RECOMMENDED",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": true,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "sort.RECOMMENDED",
                                            "referrerId": "HOT.SR.sort.RECOMMENDED.true"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "sort.RECOMMENDED",
                                            "referrerId": "HOT.SR.sort.RECOMMENDED.false"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "sort",
                                        "primary": "Price: low to high",
                                        "secondary": null,
                                        "icon": null,
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "PRICE_LOW_TO_HIGH",
                                        "description": null,
                                        "selected": true,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "sort.PRICE_LOW_TO_HIGH",
                                            "referrerId": "HOT.SR.sort.PRICE_LOW_TO_HIGH.true"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "sort.PRICE_LOW_TO_HIGH",
                                            "referrerId": "HOT.SR.sort.PRICE_LOW_TO_HIGH.false"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "sort",
                                        "primary": "Price: high to low",
                                        "secondary": null,
                                        "icon": null,
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "PRICE_HIGH_TO_LOW",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "sort.PRICE_HIGH_TO_LOW",
                                            "referrerId": "HOT.SR.sort.PRICE_HIGH_TO_LOW.true"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "sort.PRICE_HIGH_TO_LOW",
                                            "referrerId": "HOT.SR.sort.PRICE_HIGH_TO_LOW.false"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "sort",
                                        "primary": "Distance from downtown",
                                        "secondary": null,
                                        "icon": null,
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "DISTANCE",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "sort.DISTANCE",
                                            "referrerId": "HOT.SR.sort.DISTANCE.true"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "sort.DISTANCE",
                                            "referrerId": "HOT.SR.sort.DISTANCE.false"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "sort",
                                        "primary": "Guest rating",
                                        "secondary": null,
                                        "icon": null,
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "REVIEW",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "sort.REVIEW",
                                            "referrerId": "HOT.SR.sort.REVIEW.true"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "sort.REVIEW",
                                            "referrerId": "HOT.SR.sort.REVIEW.false"
                                        }
                                    },
                                    {
                                        "__typename": "ShoppingSelectableFilterOption",
                                        "id": "sort",
                                        "primary": "Star rating",
                                        "secondary": null,
                                        "icon": null,
                                        "analytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "",
                                            "referrerId": ""
                                        },
                                        "value": "PROPERTY_CLASS",
                                        "description": null,
                                        "selected": false,
                                        "disabled": false,
                                        "default": false,
                                        "selectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "sort.PROPERTY_CLASS",
                                            "referrerId": "HOT.SR.sort.PROPERTY_CLASS.true"
                                        },
                                        "deselectAnalytics": {
                                            "__typename": "ClientSideAnalytics",
                                            "linkName": "sort.PROPERTY_CLASS",
                                            "referrerId": "HOT.SR.sort.PROPERTY_CLASS.false"
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "properties": [
                {
                    "__typename": "Property",
                    "id": "96457726",
                    "featuredMessages": [],
                    "name": "Wise Stay Scenia Bay Nha Trang",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 1
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Exterior",
                            "url": "https://images.trvl-media.com/lodging/97000000/96460000/96457800/96457726/32080a18.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91024
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 2.3
                        },
                        "distanceFromMessaging": null,
                        "regionId": "3000002330"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$105",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.280986,
                            "longitude": 109.202109
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Vĩnh Hải"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Mobile Exclusive",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 1 left at 20% off at",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 130.77,
                                    "formatted": "$131"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$105"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 104.62,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$105"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 130.77,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$131"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$131",
                                            "accessibilityLabel": "The price was $131"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$105",
                                            "accessibilityLabel": "The price is $105"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$593 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 130.77,
                                    "formatted": "$131"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$105"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 104.62,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$105"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 0,
                        "total": 0
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "3000002330",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MOBILE_EXCLUSIVE",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Mobile exclusive: save 20%"
                        },
                        "totalDiscountPercentage": 20
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "37999130",
                    "featuredMessages": [],
                    "name": "Radisson Blu Resort Cam Ranh",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": null
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Exterior",
                            "url": "https://images.trvl-media.com/lodging/38000000/38000000/37999200/37999130/3b7916ce.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91024
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 14.12
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6182839"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$108",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.045755,
                            "longitude": 109.204834
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Cam Lam"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "VIP Access",
                            "theme_temp": "VIP",
                            "icon_temp": null,
                            "mark": null
                        },
                        "secondary": null
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": [
                            {
                                "__typename": "OfferAttribute",
                                "type": "VIP"
                            }
                        ]
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": null,
                                "disclaimer": null,
                                "formattedDisplayPrice": "$108"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 108.47,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$108"
                        },
                        "strikeOut": null,
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$108",
                                            "accessibilityLabel": "The price is $108"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$615 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": null,
                                "disclaimer": null,
                                "formattedDisplayPrice": "$108"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 108.47,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$108"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 8.8,
                        "total": 40
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6182839",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": null,
                        "rateDiscount": null,
                        "totalDiscountPercentage": null
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "90776831",
                    "featuredMessages": [],
                    "name": "Lumina Villas Cam Ranh",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 3
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Infinity pool",
                            "url": "https://images.trvl-media.com/lodging/91000000/90780000/90776900/90776831/f4712d09.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 30005
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 15.2
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6182839"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$114",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.030801,
                            "longitude": 109.213793
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Cam Lam"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Member Price",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 3 left at 20% off at",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 142.27,
                                    "formatted": "$142"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$114"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 113.82,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$114"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 142.27,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$142"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$142",
                                            "accessibilityLabel": "The price was $142"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$114",
                                            "accessibilityLabel": "The price is $114"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$645 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 142.27,
                                    "formatted": "$142"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$114"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 113.82,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$114"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 10,
                        "total": 3
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6182839",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 20%"
                        },
                        "totalDiscountPercentage": 20
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "63505151",
                    "featuredMessages": [],
                    "name": "Sun Villa",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 1
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Front of property",
                            "url": "https://images.trvl-media.com/lodging/64000000/63510000/63505200/63505151/6100fcf6.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91001
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 3.87
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6054439"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$114",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.19734,
                            "longitude": 109.211179
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Nha Trang"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Member Price",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "10% off",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 126.54,
                                    "formatted": "$127"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$114"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 113.89,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$114"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 126.54,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$127"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$127",
                                            "accessibilityLabel": "The price was $127"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$114",
                                            "accessibilityLabel": "The price is $114"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$646 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 126.54,
                                    "formatted": "$127"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$114"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 113.89,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$114"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 0,
                        "total": 0
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6054439",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Private sale: save 10%"
                        },
                        "totalDiscountPercentage": 10
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "40460567",
                    "featuredMessages": [],
                    "name": "Mövenpick Resort Cam Ranh",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": null
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Interior",
                            "url": "https://images.trvl-media.com/lodging/41000000/40470000/40460600/40460567/06e493b7.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 83000
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 14.2
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6182839"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$114",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.044534,
                            "longitude": 109.20597
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Cam Lam"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Great for families",
                            "theme_temp": "FAMILY_FRIENDLY",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "family_friendly",
                                "description": "family_friendly"
                            },
                            "mark": null
                        },
                        "secondary": null
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": null,
                                "disclaimer": null,
                                "formattedDisplayPrice": "$114"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 113.98,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$114"
                        },
                        "strikeOut": null,
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$114",
                                            "accessibilityLabel": "The price is $114"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$646 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": null,
                                "disclaimer": null,
                                "formattedDisplayPrice": "$114"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 113.98,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$114"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 8.2,
                        "total": 144
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6182839",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": null,
                        "rateDiscount": null,
                        "totalDiscountPercentage": null
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "45982055",
                    "featuredMessages": [],
                    "name": "Alpha Bird Nha Trang",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 1
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Exterior detail",
                            "url": "https://images.trvl-media.com/lodging/46000000/45990000/45982100/45982055/aa587adf.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91023
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 1.32
                        },
                        "distanceFromMessaging": null,
                        "regionId": "553248635950844810"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$116",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.231177,
                            "longitude": 109.196135
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Tran Phu Beach"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Member Price",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 1 left at 20% off at",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 144.62,
                                    "formatted": "$145"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$116"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 115.7,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$116"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 144.62,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$145"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$145",
                                            "accessibilityLabel": "The price was $145"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$116",
                                            "accessibilityLabel": "The price is $116"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$656 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 144.62,
                                    "formatted": "$145"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$116"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 115.7,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$116"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 0,
                        "total": 0
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "553248635950844810",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 20%"
                        },
                        "totalDiscountPercentage": 20
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "41182444",
                    "featuredMessages": [],
                    "name": "Alibu Resort Nha Trang",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 5
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Outdoor pool",
                            "url": "https://images.trvl-media.com/lodging/42000000/41190000/41182500/41182444/9a0e8c62.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 30003
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 4.03
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6054439"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$116",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.294183,
                            "longitude": 109.229077
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Nha Trang"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Member Price",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 5 left at 56% off at",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 263.93,
                                    "formatted": "$264"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$116"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 116.13,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$116"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 263.93,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$264"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$264",
                                            "accessibilityLabel": "The price was $264"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$116",
                                            "accessibilityLabel": "The price is $116"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$658 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 263.93,
                                    "formatted": "$264"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$116"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 116.13,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$116"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 9,
                        "total": 10
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6054439",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 56%"
                        },
                        "totalDiscountPercentage": 56
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "45773013",
                    "featuredMessages": [],
                    "name": "Alma Resort Cam Ranh",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": null
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Exterior",
                            "url": "https://images.trvl-media.com/lodging/46000000/45780000/45773100/45773013/66bb5a8a.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91024
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 12.23
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6182839"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$118",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.072606,
                            "longitude": 109.194807
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Cam Lam"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Great for families",
                            "theme_temp": "FAMILY_FRIENDLY",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "family_friendly",
                                "description": "family_friendly"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "21% off",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": null,
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 149.564,
                                    "formatted": "$150"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$118"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 117.7,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$118"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 149.564,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$150"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$150",
                                            "accessibilityLabel": "The price was $150"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$118",
                                            "accessibilityLabel": "The price is $118"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$726 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 149.564,
                                    "formatted": "$150"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$118"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 117.7,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$118"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 9,
                        "total": 75
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6182839",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "REGULAR",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Book early and save 20%"
                        },
                        "totalDiscountPercentage": 21
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "18907213",
                    "featuredMessages": [],
                    "name": "D26 Nha Trang Hotel",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 5
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Exterior",
                            "url": "https://images.trvl-media.com/lodging/19000000/18910000/18907300/18907213/42d297d6.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91024
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 1.41
                        },
                        "distanceFromMessaging": null,
                        "regionId": "553248635950844810"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$120",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.230416,
                            "longitude": 109.197866
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Tran Phu Beach"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Mobile Exclusive",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 5 left at 10% off at",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 133.68,
                                    "formatted": "$134"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$120"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 119.94,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$120"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 133.68,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$134"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$134",
                                            "accessibilityLabel": "The price was $134"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$120",
                                            "accessibilityLabel": "The price is $120"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$778 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 133.68,
                                    "formatted": "$134"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$120"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 119.94,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$120"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 7,
                        "total": 2
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "553248635950844810",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MOBILE_EXCLUSIVE",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Mobile exclusive: save 9%"
                        },
                        "totalDiscountPercentage": 10
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "19527132",
                    "featuredMessages": [],
                    "name": "Aquatic Ocean Hotel",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 2
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Beach",
                            "url": "https://images.trvl-media.com/lodging/20000000/19530000/19527200/19527132/393a453a.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91004
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 1.06
                        },
                        "distanceFromMessaging": null,
                        "regionId": "553248635950844810"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$121",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.235376,
                            "longitude": 109.196656
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Tran Phu Beach"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Mobile Exclusive",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 2 left at 11% off at",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 135.84,
                                    "formatted": "$136"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$121"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 121.38,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$121"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 135.84,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$136"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$136",
                                            "accessibilityLabel": "The price was $136"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$121",
                                            "accessibilityLabel": "The price is $121"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$694 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 135.84,
                                    "formatted": "$136"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$121"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 121.38,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$121"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 7,
                        "total": 2
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "553248635950844810",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MOBILE_EXCLUSIVE",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Mobile exclusive: save 11%"
                        },
                        "totalDiscountPercentage": 11
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "9308177",
                    "featuredMessages": [],
                    "name": "Seaside Hotel 2",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 1
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Room",
                            "url": "https://images.trvl-media.com/lodging/10000000/9310000/9308200/9308177/6b72e0bc.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 21001
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 1.65
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6054439"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$121",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.2274,
                            "longitude": 109.199851
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Tran Phu Beach"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Mobile Exclusive",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 1 left at 11% off at",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 135.84,
                                    "formatted": "$136"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$121"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 121.38,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$121"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 135.84,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$136"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$136",
                                            "accessibilityLabel": "The price was $136"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$121",
                                            "accessibilityLabel": "The price is $121"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$694 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 135.84,
                                    "formatted": "$136"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$121"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 121.38,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$121"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 3.8,
                        "total": 19
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6054439",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MOBILE_EXCLUSIVE",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Mobile exclusive: save 11%"
                        },
                        "totalDiscountPercentage": 11
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "4757569",
                    "featuredMessages": [],
                    "name": "Whale Island Resort",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 2
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Exterior",
                            "url": "https://images.trvl-media.com/lodging/5000000/4760000/4757600/4757569/fdecba1e.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91024
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 31.06
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6165559"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$122",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.652493,
                            "longitude": 109.395062
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Van Ninh"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": null,
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 2 left at 8% off at",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": null,
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 132.18,
                                    "formatted": "$132"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$122"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 121.604,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$122"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 132.18,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$132"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$132",
                                            "accessibilityLabel": "The price was $132"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$122",
                                            "accessibilityLabel": "The price is $122"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$689 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 132.18,
                                    "formatted": "$132"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$122"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 121.604,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$122"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 8.2,
                        "total": 28
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6165559",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "REGULAR",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Stay 2 nights and save 20%"
                        },
                        "totalDiscountPercentage": 8
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "20178926",
                    "featuredMessages": [],
                    "name": " Melia Vinpearl Nha Trang Empire",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": null
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Pool",
                            "url": "https://images.trvl-media.com/lodging/21000000/20180000/20179000/20178926/301197c0.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 30000
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 0.53
                        },
                        "distanceFromMessaging": null,
                        "regionId": "553248635950844810"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$133",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.24259,
                            "longitude": 109.1939
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Tran Phu Beach"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "VIP Access",
                            "theme_temp": "VIP",
                            "icon_temp": null,
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "19% off",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [
                            {
                                "__typename": "OfferSummaryMessage",
                                "message": "Reserve now, pay later",
                                "theme": "SUCCESS",
                                "type": "PAY_LATER",
                                "mark": null
                            }
                        ],
                        "attributes": [
                            {
                                "__typename": "OfferAttribute",
                                "type": "VIP"
                            },
                            {
                                "__typename": "OfferAttribute",
                                "type": "PAYMENT_CHOICE"
                            }
                        ]
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 163.856459,
                                    "formatted": "$164"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$133"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 132.723724,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$133"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 163.856459,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$164"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$164",
                                            "accessibilityLabel": "The price was $164"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$133",
                                            "accessibilityLabel": "The price is $133"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$753 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 163.856459,
                                    "formatted": "$164"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$133"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 132.723724,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$133"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 8.6,
                        "total": 145
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "553248635950844810",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 19%"
                        },
                        "totalDiscountPercentage": 19
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "33331379",
                    "featuredMessages": [],
                    "name": "Vesna Hotel",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 1
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Pool",
                            "url": "https://images.trvl-media.com/lodging/34000000/33340000/33331400/33331379/f134ba96.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 30000
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 1.75
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6054439"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$138",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.226066,
                            "longitude": 109.20044
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Tran Phu Beach"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Member Price",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 1 left at 45% off at",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 249.47,
                                    "formatted": "$249"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$138"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 137.83,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$138"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 249.47,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$249"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$249",
                                            "accessibilityLabel": "The price was $249"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$138",
                                            "accessibilityLabel": "The price is $138"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$782 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 249.47,
                                    "formatted": "$249"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$138"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 137.83,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$138"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 6.8,
                        "total": 5
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6054439",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 45%"
                        },
                        "totalDiscountPercentage": 45
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "8751594",
                    "featuredMessages": [],
                    "name": "The Costa Nha Trang Residences",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 2
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Outdoor pool",
                            "url": "https://images.trvl-media.com/lodging/9000000/8760000/8751600/8751594/d1902a8f.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 30003
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 0.5
                        },
                        "distanceFromMessaging": null,
                        "regionId": "553248635950844810"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$138",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.244713,
                            "longitude": 109.196266
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Tran Phu Beach"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Member Price",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 2 left at 20% off at",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [
                            {
                                "__typename": "OfferSummaryMessage",
                                "message": "Reserve without credit card",
                                "theme": "SUCCESS",
                                "type": "NO_CREDIT_CARD",
                                "mark": null
                            }
                        ],
                        "attributes": [
                            {
                                "__typename": "OfferAttribute",
                                "type": "NO_CC_REQUIRED"
                            },
                            {
                                "__typename": "OfferAttribute",
                                "type": "PAYMENT_CHOICE"
                            }
                        ]
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 172.604932,
                                    "formatted": "$173"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$138"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 138.083957,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$138"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 172.604932,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$173"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$173",
                                            "accessibilityLabel": "The price was $173"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$138",
                                            "accessibilityLabel": "The price is $138"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$783 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 172.604932,
                                    "formatted": "$173"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$138"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 138.083957,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$138"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 8,
                        "total": 136
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "553248635950844810",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 20%"
                        },
                        "totalDiscountPercentage": 20
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "2540761",
                    "featuredMessages": [],
                    "name": "Hon Tam Resort",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 4
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Beach",
                            "url": "https://images.trvl-media.com/lodging/3000000/2550000/2540800/2540761/7481b1b9.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91004
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 5.87
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6054439"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$140",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.178097,
                            "longitude": 109.237653
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Nha Trang"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Member Price",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 4 left at 15% off at",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 163.78,
                                    "formatted": "$164"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$140"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 140.03,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$140"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 163.78,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$164"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$164",
                                            "accessibilityLabel": "The price was $164"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$140",
                                            "accessibilityLabel": "The price is $140"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$794 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 163.78,
                                    "formatted": "$164"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$140"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 140.03,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$140"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 8.2,
                        "total": 176
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6054439",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 14%"
                        },
                        "totalDiscountPercentage": 15
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "81631786",
                    "featuredMessages": [],
                    "name": "Ana Mandara Cam Ranh",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 1
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "View from property",
                            "url": "https://images.trvl-media.com/lodging/82000000/81640000/81631800/81631786/5ec90322.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 92002
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 11.56
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6182839"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$143",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.082331,
                            "longitude": 109.193693
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Cam Lam"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "VIP Access",
                            "theme_temp": "VIP",
                            "icon_temp": null,
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 1 left at 43% off at",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": [
                            {
                                "__typename": "OfferAttribute",
                                "type": "VIP"
                            }
                        ]
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 250.56,
                                    "formatted": "$251"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$143"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 142.82,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$143"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 250.56,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$251"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$251",
                                            "accessibilityLabel": "The price was $251"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$143",
                                            "accessibilityLabel": "The price is $143"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$1,125 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 250.56,
                                    "formatted": "$251"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$143"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 142.82,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$143"
                        }
                    },
                    "propertyFees": [
                        {
                            "__typename": "Fees",
                            "type": "RESORT"
                        }
                    ],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 8.2,
                        "total": 14
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6182839",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 42%"
                        },
                        "totalDiscountPercentage": 43
                    },
                    "saveTripItem": null
                }
            ],
            "propertySearchListings": [
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "LodgingCard"
                },
                {
                    "__typename": "Property",
                    "id": "96457726",
                    "featuredMessages": [],
                    "name": "Wise Stay Scenia Bay Nha Trang",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 1
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Exterior",
                            "url": "https://images.trvl-media.com/lodging/97000000/96460000/96457800/96457726/32080a18.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91024
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 2.3
                        },
                        "distanceFromMessaging": null,
                        "regionId": "3000002330"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$105",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.280986,
                            "longitude": 109.202109
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Vĩnh Hải"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Mobile Exclusive",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 1 left at 20% off at",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 130.77,
                                    "formatted": "$131"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$105"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 104.62,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$105"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 130.77,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$131"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$131",
                                            "accessibilityLabel": "The price was $131"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$105",
                                            "accessibilityLabel": "The price is $105"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$593 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 130.77,
                                    "formatted": "$131"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$105"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 104.62,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$105"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 0,
                        "total": 0
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "3000002330",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MOBILE_EXCLUSIVE",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Mobile exclusive: save 20%"
                        },
                        "totalDiscountPercentage": 20
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "37999130",
                    "featuredMessages": [],
                    "name": "Radisson Blu Resort Cam Ranh",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": null
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Exterior",
                            "url": "https://images.trvl-media.com/lodging/38000000/38000000/37999200/37999130/3b7916ce.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91024
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 14.12
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6182839"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$108",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.045755,
                            "longitude": 109.204834
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Cam Lam"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "VIP Access",
                            "theme_temp": "VIP",
                            "icon_temp": null,
                            "mark": null
                        },
                        "secondary": null
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": [
                            {
                                "__typename": "OfferAttribute",
                                "type": "VIP"
                            }
                        ]
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": null,
                                "disclaimer": null,
                                "formattedDisplayPrice": "$108"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 108.47,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$108"
                        },
                        "strikeOut": null,
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$108",
                                            "accessibilityLabel": "The price is $108"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$615 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": null,
                                "disclaimer": null,
                                "formattedDisplayPrice": "$108"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 108.47,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$108"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 8.8,
                        "total": 40
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6182839",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": null,
                        "rateDiscount": null,
                        "totalDiscountPercentage": null
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "90776831",
                    "featuredMessages": [],
                    "name": "Lumina Villas Cam Ranh",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 3
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Infinity pool",
                            "url": "https://images.trvl-media.com/lodging/91000000/90780000/90776900/90776831/f4712d09.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 30005
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 15.2
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6182839"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$114",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.030801,
                            "longitude": 109.213793
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Cam Lam"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Member Price",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 3 left at 20% off at",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 142.27,
                                    "formatted": "$142"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$114"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 113.82,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$114"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 142.27,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$142"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$142",
                                            "accessibilityLabel": "The price was $142"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$114",
                                            "accessibilityLabel": "The price is $114"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$645 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 142.27,
                                    "formatted": "$142"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$114"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 113.82,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$114"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 10,
                        "total": 3
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6182839",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 20%"
                        },
                        "totalDiscountPercentage": 20
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "63505151",
                    "featuredMessages": [],
                    "name": "Sun Villa",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 1
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Front of property",
                            "url": "https://images.trvl-media.com/lodging/64000000/63510000/63505200/63505151/6100fcf6.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91001
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 3.87
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6054439"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$114",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.19734,
                            "longitude": 109.211179
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Nha Trang"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Member Price",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "10% off",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 126.54,
                                    "formatted": "$127"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$114"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 113.89,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$114"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 126.54,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$127"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$127",
                                            "accessibilityLabel": "The price was $127"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$114",
                                            "accessibilityLabel": "The price is $114"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$646 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 126.54,
                                    "formatted": "$127"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$114"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 113.89,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$114"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 0,
                        "total": 0
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6054439",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Private sale: save 10%"
                        },
                        "totalDiscountPercentage": 10
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "40460567",
                    "featuredMessages": [],
                    "name": "Mövenpick Resort Cam Ranh",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": null
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Interior",
                            "url": "https://images.trvl-media.com/lodging/41000000/40470000/40460600/40460567/06e493b7.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 83000
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 14.2
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6182839"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$114",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.044534,
                            "longitude": 109.20597
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Cam Lam"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Great for families",
                            "theme_temp": "FAMILY_FRIENDLY",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "family_friendly",
                                "description": "family_friendly"
                            },
                            "mark": null
                        },
                        "secondary": null
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": null,
                                "disclaimer": null,
                                "formattedDisplayPrice": "$114"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 113.98,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$114"
                        },
                        "strikeOut": null,
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$114",
                                            "accessibilityLabel": "The price is $114"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$646 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": null,
                                "disclaimer": null,
                                "formattedDisplayPrice": "$114"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 113.98,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$114"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 8.2,
                        "total": 144
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6182839",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": null,
                        "rateDiscount": null,
                        "totalDiscountPercentage": null
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "45982055",
                    "featuredMessages": [],
                    "name": "Alpha Bird Nha Trang",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 1
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Exterior detail",
                            "url": "https://images.trvl-media.com/lodging/46000000/45990000/45982100/45982055/aa587adf.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91023
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 1.32
                        },
                        "distanceFromMessaging": null,
                        "regionId": "553248635950844810"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$116",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.231177,
                            "longitude": 109.196135
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Tran Phu Beach"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Member Price",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 1 left at 20% off at",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 144.62,
                                    "formatted": "$145"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$116"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 115.7,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$116"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 144.62,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$145"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$145",
                                            "accessibilityLabel": "The price was $145"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$116",
                                            "accessibilityLabel": "The price is $116"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$656 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 144.62,
                                    "formatted": "$145"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$116"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 115.7,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$116"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 0,
                        "total": 0
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "553248635950844810",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 20%"
                        },
                        "totalDiscountPercentage": 20
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "41182444",
                    "featuredMessages": [],
                    "name": "Alibu Resort Nha Trang",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 5
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Outdoor pool",
                            "url": "https://images.trvl-media.com/lodging/42000000/41190000/41182500/41182444/9a0e8c62.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 30003
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 4.03
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6054439"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$116",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.294183,
                            "longitude": 109.229077
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Nha Trang"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Member Price",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 5 left at 56% off at",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 263.93,
                                    "formatted": "$264"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$116"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 116.13,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$116"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 263.93,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$264"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$264",
                                            "accessibilityLabel": "The price was $264"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$116",
                                            "accessibilityLabel": "The price is $116"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$658 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 263.93,
                                    "formatted": "$264"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$116"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 116.13,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$116"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 9,
                        "total": 10
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6054439",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 56%"
                        },
                        "totalDiscountPercentage": 56
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "45773013",
                    "featuredMessages": [],
                    "name": "Alma Resort Cam Ranh",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": null
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Exterior",
                            "url": "https://images.trvl-media.com/lodging/46000000/45780000/45773100/45773013/66bb5a8a.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91024
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 12.23
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6182839"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$118",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.072606,
                            "longitude": 109.194807
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Cam Lam"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Great for families",
                            "theme_temp": "FAMILY_FRIENDLY",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "family_friendly",
                                "description": "family_friendly"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "21% off",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": null,
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 149.564,
                                    "formatted": "$150"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$118"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 117.7,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$118"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 149.564,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$150"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$150",
                                            "accessibilityLabel": "The price was $150"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$118",
                                            "accessibilityLabel": "The price is $118"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$726 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 149.564,
                                    "formatted": "$150"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$118"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 117.7,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$118"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 9,
                        "total": 75
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6182839",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "REGULAR",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Book early and save 20%"
                        },
                        "totalDiscountPercentage": 21
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "18907213",
                    "featuredMessages": [],
                    "name": "D26 Nha Trang Hotel",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 5
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Exterior",
                            "url": "https://images.trvl-media.com/lodging/19000000/18910000/18907300/18907213/42d297d6.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91024
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 1.41
                        },
                        "distanceFromMessaging": null,
                        "regionId": "553248635950844810"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$120",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.230416,
                            "longitude": 109.197866
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Tran Phu Beach"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Mobile Exclusive",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 5 left at 10% off at",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 133.68,
                                    "formatted": "$134"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$120"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 119.94,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$120"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 133.68,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$134"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$134",
                                            "accessibilityLabel": "The price was $134"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$120",
                                            "accessibilityLabel": "The price is $120"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$778 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 133.68,
                                    "formatted": "$134"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$120"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 119.94,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$120"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 7,
                        "total": 2
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "553248635950844810",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MOBILE_EXCLUSIVE",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Mobile exclusive: save 9%"
                        },
                        "totalDiscountPercentage": 10
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "19527132",
                    "featuredMessages": [],
                    "name": "Aquatic Ocean Hotel",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 2
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Beach",
                            "url": "https://images.trvl-media.com/lodging/20000000/19530000/19527200/19527132/393a453a.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91004
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 1.06
                        },
                        "distanceFromMessaging": null,
                        "regionId": "553248635950844810"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$121",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.235376,
                            "longitude": 109.196656
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Tran Phu Beach"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Mobile Exclusive",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 2 left at 11% off at",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 135.84,
                                    "formatted": "$136"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$121"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 121.38,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$121"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 135.84,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$136"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$136",
                                            "accessibilityLabel": "The price was $136"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$121",
                                            "accessibilityLabel": "The price is $121"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$694 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 135.84,
                                    "formatted": "$136"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$121"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 121.38,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$121"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 7,
                        "total": 2
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "553248635950844810",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MOBILE_EXCLUSIVE",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Mobile exclusive: save 11%"
                        },
                        "totalDiscountPercentage": 11
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "9308177",
                    "featuredMessages": [],
                    "name": "Seaside Hotel 2",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 1
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Room",
                            "url": "https://images.trvl-media.com/lodging/10000000/9310000/9308200/9308177/6b72e0bc.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 21001
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 1.65
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6054439"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$121",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.2274,
                            "longitude": 109.199851
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Tran Phu Beach"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Mobile Exclusive",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 1 left at 11% off at",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "smartphone",
                                "description": "smartphone"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 135.84,
                                    "formatted": "$136"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$121"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 121.38,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$121"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 135.84,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$136"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$136",
                                            "accessibilityLabel": "The price was $136"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$121",
                                            "accessibilityLabel": "The price is $121"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$694 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 135.84,
                                    "formatted": "$136"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$121"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 121.38,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$121"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 3.8,
                        "total": 19
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6054439",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MOBILE_EXCLUSIVE",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Mobile exclusive: save 11%"
                        },
                        "totalDiscountPercentage": 11
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "4757569",
                    "featuredMessages": [],
                    "name": "Whale Island Resort",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 2
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Exterior",
                            "url": "https://images.trvl-media.com/lodging/5000000/4760000/4757600/4757569/fdecba1e.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91024
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 31.06
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6165559"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$122",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.652493,
                            "longitude": 109.395062
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Van Ninh"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": null,
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 2 left at 8% off at",
                            "theme_temp": "DEAL_GENERIC",
                            "icon_temp": null,
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 132.18,
                                    "formatted": "$132"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$122"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 121.604,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$122"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 132.18,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$132"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$132",
                                            "accessibilityLabel": "The price was $132"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$122",
                                            "accessibilityLabel": "The price is $122"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$689 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 132.18,
                                    "formatted": "$132"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$122"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 121.604,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$122"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 8.2,
                        "total": 28
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6165559",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "REGULAR",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Stay 2 nights and save 20%"
                        },
                        "totalDiscountPercentage": 8
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "20178926",
                    "featuredMessages": [],
                    "name": " Melia Vinpearl Nha Trang Empire",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": null
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Pool",
                            "url": "https://images.trvl-media.com/lodging/21000000/20180000/20179000/20178926/301197c0.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 30000
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 0.53
                        },
                        "distanceFromMessaging": null,
                        "regionId": "553248635950844810"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$133",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.24259,
                            "longitude": 109.1939
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Tran Phu Beach"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "VIP Access",
                            "theme_temp": "VIP",
                            "icon_temp": null,
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "19% off",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [
                            {
                                "__typename": "OfferSummaryMessage",
                                "message": "Reserve now, pay later",
                                "theme": "SUCCESS",
                                "type": "PAY_LATER",
                                "mark": null
                            }
                        ],
                        "attributes": [
                            {
                                "__typename": "OfferAttribute",
                                "type": "VIP"
                            },
                            {
                                "__typename": "OfferAttribute",
                                "type": "PAYMENT_CHOICE"
                            }
                        ]
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 163.856459,
                                    "formatted": "$164"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$133"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 132.723724,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$133"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 163.856459,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$164"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$164",
                                            "accessibilityLabel": "The price was $164"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$133",
                                            "accessibilityLabel": "The price is $133"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$753 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 163.856459,
                                    "formatted": "$164"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$133"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 132.723724,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$133"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 8.6,
                        "total": 145
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "553248635950844810",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 19%"
                        },
                        "totalDiscountPercentage": 19
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "33331379",
                    "featuredMessages": [],
                    "name": "Vesna Hotel",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 1
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Pool",
                            "url": "https://images.trvl-media.com/lodging/34000000/33340000/33331400/33331379/f134ba96.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 30000
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 1.75
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6054439"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$138",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.226066,
                            "longitude": 109.20044
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Tran Phu Beach"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Member Price",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 1 left at 45% off at",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 249.47,
                                    "formatted": "$249"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$138"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 137.83,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$138"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 249.47,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$249"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$249",
                                            "accessibilityLabel": "The price was $249"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$138",
                                            "accessibilityLabel": "The price is $138"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$782 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 249.47,
                                    "formatted": "$249"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$138"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 137.83,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$138"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 6.8,
                        "total": 5
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6054439",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 45%"
                        },
                        "totalDiscountPercentage": 45
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "8751594",
                    "featuredMessages": [],
                    "name": "The Costa Nha Trang Residences",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 2
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Outdoor pool",
                            "url": "https://images.trvl-media.com/lodging/9000000/8760000/8751600/8751594/d1902a8f.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 30003
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 0.5
                        },
                        "distanceFromMessaging": null,
                        "regionId": "553248635950844810"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$138",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.244713,
                            "longitude": 109.196266
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Tran Phu Beach"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Member Price",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 2 left at 20% off at",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [
                            {
                                "__typename": "OfferSummaryMessage",
                                "message": "Reserve without credit card",
                                "theme": "SUCCESS",
                                "type": "NO_CREDIT_CARD",
                                "mark": null
                            }
                        ],
                        "attributes": [
                            {
                                "__typename": "OfferAttribute",
                                "type": "NO_CC_REQUIRED"
                            },
                            {
                                "__typename": "OfferAttribute",
                                "type": "PAYMENT_CHOICE"
                            }
                        ]
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 172.604932,
                                    "formatted": "$173"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$138"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 138.083957,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$138"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 172.604932,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$173"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$173",
                                            "accessibilityLabel": "The price was $173"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$138",
                                            "accessibilityLabel": "The price is $138"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$783 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 172.604932,
                                    "formatted": "$173"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$138"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 138.083957,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$138"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 8,
                        "total": 136
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "553248635950844810",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 20%"
                        },
                        "totalDiscountPercentage": 20
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "2540761",
                    "featuredMessages": [],
                    "name": "Hon Tam Resort",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 4
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "Beach",
                            "url": "https://images.trvl-media.com/lodging/3000000/2550000/2540800/2540761/7481b1b9.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 91004
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 5.87
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6054439"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$140",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.178097,
                            "longitude": 109.237653
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Nha Trang"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "Member Price",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 4 left at 15% off at",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": []
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 163.78,
                                    "formatted": "$164"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$140"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 140.03,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$140"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 163.78,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$164"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$164",
                                            "accessibilityLabel": "The price was $164"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$140",
                                            "accessibilityLabel": "The price is $140"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$794 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 163.78,
                                    "formatted": "$164"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$140"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 140.03,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$140"
                        }
                    },
                    "propertyFees": [],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 8.2,
                        "total": 176
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6054439",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 14%"
                        },
                        "totalDiscountPercentage": 15
                    },
                    "saveTripItem": null
                },
                {
                    "__typename": "Property",
                    "id": "81631786",
                    "featuredMessages": [],
                    "name": "Ana Mandara Cam Ranh",
                    "availability": {
                        "__typename": "PropertyAvailability",
                        "available": true,
                        "minRoomsLeft": 1
                    },
                    "propertyImage": {
                        "__typename": "PropertyImage",
                        "alt": "",
                        "fallbackImage": null,
                        "image": {
                            "__typename": "Image",
                            "description": "View from property",
                            "url": "https://images.trvl-media.com/lodging/82000000/81640000/81631800/81631786/5ec90322.jpg?impolicy=resizecrop&rw=455&ra=fit"
                        },
                        "subjectId": 92002
                    },
                    "destinationInfo": {
                        "__typename": "PropertyDestinationInfo",
                        "distanceFromDestination": {
                            "__typename": "Distance",
                            "unit": "MILE",
                            "value": 11.56
                        },
                        "distanceFromMessaging": null,
                        "regionId": "6182839"
                    },
                    "legalDisclaimer": null,
                    "listingFooter": null,
                    "mapMarker": {
                        "__typename": "MapMarker",
                        "label": "$143",
                        "latLong": {
                            "__typename": "Coordinates",
                            "latitude": 12.082331,
                            "longitude": 109.193693
                        }
                    },
                    "neighborhood": {
                        "__typename": "Region",
                        "name": "Cam Lam"
                    },
                    "offerBadge": {
                        "__typename": "OfferBadge",
                        "primary": {
                            "__typename": "Badge",
                            "text": "VIP Access",
                            "theme_temp": "VIP",
                            "icon_temp": null,
                            "mark": null
                        },
                        "secondary": {
                            "__typename": "Badge",
                            "text": "We have 1 left at 43% off at",
                            "theme_temp": "DEAL_MEMBER",
                            "icon_temp": {
                                "__typename": "Icon",
                                "id": "mod",
                                "description": "mod"
                            },
                            "mark": null
                        }
                    },
                    "offerSummary": {
                        "__typename": "OfferSummary",
                        "messages": [],
                        "attributes": [
                            {
                                "__typename": "OfferAttribute",
                                "type": "VIP"
                            }
                        ]
                    },
                    "pinnedDetails": null,
                    "price": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 250.56,
                                    "formatted": "$251"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$143"
                            }
                        ],
                        "priceMessaging": null,
                        "lead": {
                            "__typename": "Money",
                            "amount": 142.82,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$143"
                        },
                        "strikeOut": {
                            "__typename": "Money",
                            "amount": 250.56,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$251"
                        },
                        "displayMessages": [
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": {
                                            "__typename": "LodgingPlainDialog",
                                            "content": [
                                                "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                            ],
                                            "title": null
                                        },
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$251",
                                            "accessibilityLabel": "The price was $251"
                                        },
                                        "role": "STRIKEOUT"
                                    },
                                    {
                                        "__typename": "DisplayPrice",
                                        "disclaimer": null,
                                        "price": {
                                            "__typename": "FormattedMoney",
                                            "formatted": "$143",
                                            "accessibilityLabel": "The price is $143"
                                        },
                                        "role": "LEAD"
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "$1,125 total",
                                        "badge": null
                                    }
                                ]
                            },
                            {
                                "__typename": "PriceDisplayMessage",
                                "lineItems": [
                                    {
                                        "__typename": "LodgingEnrichedMessage",
                                        "accessibilityLabel": null,
                                        "mark": null,
                                        "state": null,
                                        "value": "includes taxes & fees",
                                        "badge": null
                                    }
                                ]
                            }
                        ],
                        "strikeOutType": "STANDALONE",
                        "priceMessages": [
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "per night"
                            },
                            {
                                "__typename": "LodgingPlainMessage",
                                "value": "Sep 14 - Sep 19"
                            }
                        ]
                    },
                    "priceAfterLoyaltyPointsApplied": {
                        "__typename": "PropertyPrice",
                        "options": [
                            {
                                "__typename": "PropertyPriceOption",
                                "strikeOut": {
                                    "__typename": "Money",
                                    "amount": 250.56,
                                    "formatted": "$251"
                                },
                                "disclaimer": {
                                    "__typename": "LodgingPlainMessage",
                                    "value": "Standard rate on our site, provided by the property and based on your search, before all discounts and rewards applied."
                                },
                                "formattedDisplayPrice": "$143"
                            }
                        ],
                        "lead": {
                            "__typename": "Money",
                            "amount": 142.82,
                            "currencyInfo": {
                                "__typename": "Currency",
                                "code": "USD",
                                "symbol": "$"
                            },
                            "formatted": "$143"
                        }
                    },
                    "propertyFees": [
                        {
                            "__typename": "Fees",
                            "type": "RESORT"
                        }
                    ],
                    "reviews": {
                        "__typename": "PropertyReviewsSummary",
                        "score": 8.2,
                        "total": 14
                    },
                    "sponsoredListing": null,
                    "star": null,
                    "supportingMessages": null,
                    "regionId": "6182839",
                    "priceMetadata": {
                        "__typename": "PropertyPriceMetadata",
                        "discountType": "MEMBER_DEAL",
                        "rateDiscount": {
                            "__typename": "RateDiscount",
                            "description": "Member’s price: 42%"
                        },
                        "totalDiscountPercentage": 43
                    },
                    "saveTripItem": null
                }
            ],
            "summary": {
                "__typename": "PropertyResultsSummary",
                "matchedPropertiesSize": 890,
                "pricingScheme": {
                    "__typename": "PricingScheme",
                    "type": "DAILY_RATE"
                },
                "regionCompression": null,
                "loyaltyInfo": {
                    "__typename": "PropertyLoyaltyDiscount",
                    "saveWithPointsMessage": null,
                    "saveWithPointsActionMessage": null
                },
                "resultsTitleModel": {
                    "__typename": "ResultTitleModel",
                    "header": "890 properties"
                },
                "resultsSummary": [
                    {
                        "__typename": "LodgingPlainMessage"
                    },
                    {
                        "__typename": "LodgingLinkMessage",
                        "accessibilityLabel": "Opens in a new window",
                        "value": "What we are paid impacts our sort order",
                        "link": {
                            "__typename": "LodgingLink",
                            "clientSideAnalytics": {
                                "__typename": "ClientSideAnalytics",
                                "linkName": "Sort disclaimer",
                                "referrerId": "HOT.HSR.RecommendedSort.FAQlink"
                            },
                            "uri": {
                                "__typename": "HttpURI",
                                "value": "https://service.hotels.com/en-us/#/article/19645"
                            }
                        }
                    }
                ]
            },
            "searchCriteria": {
                "__typename": "SearchCriteria",
                "resolvedDateRange": {
                    "__typename": "PropertyDateRange",
                    "checkInDate": {
                        "__typename": "Date",
                        "day": 14,
                        "month": 9,
                        "year": 2023
                    },
                    "checkOutDate": {
                        "__typename": "Date",
                        "day": 19,
                        "month": 9,
                        "year": 2023
                    }
                }
            },
            "shoppingContext": {
                "__typename": "ShoppingContext",
                "multiItem": null
            },
            "map": {
                "__typename": "PropertySearchMap",
                "subtitle": null
            },
            "clickstream": {
                "__typename": "SearchClickstreamEvents",
                "searchResultsViewed": null
            }
        }
    }
}
import { SxProps, Theme } from "@mui/material";

export const CELL_HEIGHT = 200;
export const IMAGE_HEIGHT = 145;

export const container: SxProps<Theme> = {
    // maxWidth: 400,
}

const defaultStyle = { container }
export default defaultStyle;
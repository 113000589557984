import { CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ChoBackgroundScreen } from "../../components/ChoBackgroundScreen";

interface IRedirectRouteProps {
    /** The redirection path to go to */
    redirectPath: string;
}

/**
 * Utils which allow to add a redirection route
 * @example
    Redirect of a specific route to subdomain:
    ```
    <Route
        path="shop"
        element={
        <RedirectRoute
            redirectPath="shop.cohostop.com"
        />
        }
    />
    ```
 * @param props IRedirectRouteProps
 */
export const RedirectRoute = ({
    redirectPath,
}: IRedirectRouteProps) => {
    const { t } = useTranslation();
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.replace(redirectPath);
        }, 3000);
        return () => clearTimeout(timeout);
    }, [redirectPath]);

    return (
        <ChoBackgroundScreen>
            <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} height={'100vh'} spacing={3}>
                <Typography variant="h1">{t('subdomains.redirectingTitle')}</Typography>
                <CircularProgress />
                <Typography variant="body1" fontWeight={'bold'}>{t('subdomains.redirectingContent', { url: redirectPath })}</Typography>
            </Stack>
        </ChoBackgroundScreen>
    );
};
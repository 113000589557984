import React, { createContext, PropsWithChildren, useCallback } from "react";
import firebase from "firebase/app";
import { rtdb } from "../../services/firebase";
import { ChatMessage, CHAT_MESSAGES, USER_CHANNELS, USER_CHANNEL_UNSEEN_COUNT } from "../../types/db/chat";
import { useThreadMessages } from "../../hooks/chat/useThreadMessages";

interface IChatThreadContext extends ReturnType<typeof useThreadMessages> {
    /** Function to create/send a new message. Promise returning the message id */
    create(message: string): Promise<string>;
    /** Function to update a message */
    // update(messageId: string, message: DeepPartial<Omit<MessageInfo, 'id'>>): Promise<void>;
    /** Function to delete a channel */
    // delete(messageId: string): Promise<void>;
    /** Function to load more channels (pagination) */
    // loadMoreMessages(): Promise<void>;
    /** Mark the thread as fully read */
    markAsRead: () => Promise<void>;
}

/** The chat thread context providing messages list and functionality to all children */
export const ChatThreadContext = createContext<IChatThreadContext | undefined>(undefined);

interface IChatThreadContextProviderProps {
    /** The user id of the user consulting the thread (and potentially sending new message to it) */
    userId: string;
    /** The channel we are retrieving the thread/messages from */
    channelId: string;
}

export const ChatThreadContextProvider = ({ userId, channelId, children }: PropsWithChildren<IChatThreadContextProviderProps>) => {
    const { messages, isLoading, error } = useThreadMessages(channelId);

    const _create = useCallback((message: string) => {
        const newMessage: ChatMessage = {
            message,
            timestamp: firebase.database.ServerValue.TIMESTAMP,
            sender: userId,
        }
        // create db collection and send in the data
        return rtdb.ref(CHAT_MESSAGES)
            .child(channelId)
            .push(newMessage)
            .then((ref) => ref.key!);
    }, [channelId, userId]);

    const markThreadAsRead = useCallback(() => {
        // marking as read by setting the USER_CHANNEL_UNSEEN_COUNT to 0
        return rtdb.ref(USER_CHANNELS)
            .child(userId)
            .child(channelId)
            .update({
                [USER_CHANNEL_UNSEEN_COUNT]: 0
            });
    }, [channelId, userId])

    return (
        <ChatThreadContext.Provider value={{
            messages,
            isLoading,
            error,
            create: _create,
            // update: update_,
            // delete: delete_,
            markAsRead: markThreadAsRead,
        }
        }>
            {children}
        </ChatThreadContext.Provider>
    );
};

import { buildChatMessagePath, buildListingPath, buildNewsStoryAssetsPath, buildNewsStoryContentPath, buildNewsStoryPath, buildUserPath } from '../types/storage';
import { storage } from './firebase';

type Callback = (percent: number, finalInfo?: { name: string, url: string }, error?: Error) => void;
export type { Callback as StorageCallback };

const generateFileName = (file: File) => `${file.name}_${Date.now()}`;

const addPictureToStorage = (storagePath: string, file: File, callback: Callback) => {
    // console.log("Add picture to storage", storagePath);
    const storageRef = storage.ref(storagePath);
    const uploadTask = storageRef.put(file);
    uploadTask.on("state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            // update progress
            callback(percent);
        },
        (err) => callback(0, undefined, err),
        () => {
            // download url
            storageRef.getDownloadURL().then((url) => {
                callback(100, { name: storageRef.name, url });
            });
        })
}

const deletePictureFromStorage = (storagePath: string) => {
    // console.log("Delete picture from storage:", storagePath);
    const storageRef = storage.ref(storagePath);
    return storageRef.delete();
}

const updateMDContentOfStorage = (storagePath: string, contentMD: string) => {
    // console.log("Update content of MD", storagePath);
    const storageRef = storage.ref(storagePath);
    const fileContent = new Blob([contentMD], { type: 'text/markdown' });
    return storageRef.put(fileContent);
}

/** Add listing picture to the storage (only deals with storage feature, not adding in DB) */
export const addPictureToListing = (listingId: string, file: File, callback: Callback) => {
    addPictureToStorage(buildListingPath(listingId, generateFileName(file)), file, callback);
}

/** Delete listing picture from storage (only deals with storage feature, not deleting from DB) */
export const deletePictureFromListing = (listingId: string, fileName: string) => {
    return deletePictureFromStorage(buildListingPath(listingId, fileName));
}

export const addPictureToUser = (userId: string, file: File, callback: Callback) => {
    addPictureToStorage(buildUserPath(userId, generateFileName(file)), file, callback);
}

export const deletePictureFromUser = (userId: string, fileName: string) => {
    return deletePictureFromStorage(buildUserPath(userId, fileName));
}

export const addPictureToMessage = (messageId: string, file: File, callback: Callback) => {
    addPictureToStorage(buildChatMessagePath(messageId, generateFileName(file)), file, callback);
}

export const deletePictureFromMessage = (messageId: string, fileName: string) => {
    return deletePictureFromStorage(buildChatMessagePath(messageId, fileName))
}

export const updateContentOfStory = (storyId: string, content: string, language?: string) => {
    return updateMDContentOfStorage(buildNewsStoryContentPath(storyId, language), content);
}

export const deleteContentOfStory = (storyId: string) => {
    return storage.ref(buildNewsStoryPath(storyId)).delete();
}

export const getPicturesOfStory = async (storyId: string) => {
    const assetsList = await storage.ref(buildNewsStoryAssetsPath(storyId)).listAll();

    const files: {
        preview: string,
        name: string,
        description: string,
    }[] = [];
    assetsList.items.forEach(async asset => {
        const url = (await asset.getDownloadURL()) as string;
        files.push({
            preview: url,
            name: asset.name,
            description: asset.name
        })
    })

    return files;
}

export const addPictureToStory = (storyId: string, file: File, callback: Callback) => {
    addPictureToStorage(buildNewsStoryAssetsPath(storyId, generateFileName(file)), file, callback);
}

export const deletePictureFromStory = (storyId: string, fileName: string) => {
    return deletePictureFromStorage(buildNewsStoryAssetsPath(storyId, fileName));
}
import React from 'react';
import { useQuery } from 'react-query';
import { IShopPriceProps } from '../../../components/shop/ShopPrice';
import { ShopProductDetails } from '../../../components/shop/ShopProductDetails';
import { useUserCart } from '../../../context/shop';
import { getShopProduct } from '../../../services/shop';
import { DEFAULT_SHOP_ID } from '../../../utilities/shop';
import { ShopProductVariantsContainer } from '../ShopProductVariantsContainer';
import { ShopProductVariant, WithID } from '../../../types/db';

export interface IShopProductContainerProps {
    shopId?: string;
    productId: string;
    selectedVariant?: string;
    onVariantSelected: (variantID: string) => void;
}

export const ShopProductContainer = ({
    shopId = DEFAULT_SHOP_ID,
    productId,
    selectedVariant,
    onVariantSelected,
}: IShopProductContainerProps) => {
    const cartContext = useUserCart(shopId);
    const { data, isLoading, isError } = useQuery(
        ["ProductDetails", productId],
        () => getShopProduct(shopId, productId));

    if (isLoading) {
        return <ShopProductDetails loading />
    }

    if (isError || !cartContext) {
        return <ShopProductDetails error />
    }

    if (!data) {
        return <ShopProductDetails error={{ message: "" }} />
    }

    const handleOnAddToCartClick = (productId: string, variantId: string) => {
        // console.log("Add to cart clicked", productId, variantId);
        cartContext.addToCart({
            productId,
            variantId,
        })
    }

    return (
        <ShopProductVariantsContainer shopId={shopId} productId={productId}>
            {(dataVariants) => {
                const selectedVariantID = selectedVariant || dataVariants?.[0]?.id;
                const selectedVariantData: WithID<ShopProductVariant, string> | undefined = dataVariants?.find(v => v.id === selectedVariantID);
                const price: IShopPriceProps = {
                    originalPrice: {
                        amount: (selectedVariantData?.price.amount ?? data.prices[0].amount),
                        currency: selectedVariantData?.price.currency ?? data.prices[0].currency,
                    }
                }
                return <ShopProductDetails
                    title={data.name}
                    description={data.description}
                    images={!!selectedVariantData?.image ? [selectedVariantData.image, ...data.images] : data.images}
                    variants={dataVariants ?
                        {
                            selectedVariant: selectedVariantID || dataVariants[0].id,
                            onVariantSelect: onVariantSelected,
                            variants: dataVariants.map(variant => ({
                                value: variant.id,
                                title: variant.name,
                                image: variant.image,
                                info: {
                                    color: {
                                        colorName: variant.color?.color_name ?? '',
                                        colorCode: variant.color?.color_hex ?? '',
                                    },
                                    size: variant.size || '',
                                }
                            }))
                        }
                        : undefined}
                    onAddToCartClick={() => handleOnAddToCartClick(productId, selectedVariantID || '')}
                    price={price}
                />
            }}
        </ShopProductVariantsContainer>
    );
}

import React from "react";
import { Grid, Typography } from "@mui/material";
import { ForumPostCard, IForumPostCardProps } from "../ForumPostCard/ForumPostCard";
import { useTranslation } from "react-i18next";

export interface IForumRelatedArticlesSectionProps {
    /** The title for this section */
    title?: string;
    /** The list of related articles from the Help Center */
    articles: IForumPostCardProps[] | { loading: true, length: number };
}

export const ForumRelatedArticlesSection = ({ title, articles }: IForumRelatedArticlesSectionProps) => {
    const { t } = useTranslation();
    if (Array.isArray(articles) && !articles?.length) {
        return null;
    }

    return (
        <Grid sx={{ backgroundColor: '#000', p: 3 }} container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={'h3'} color={'#FFF'}>{title || t('forum.post.relatedArticles')}</Typography>
            </Grid>
            {Array.isArray(articles) ?
                articles.map((article, index) =>
                    <Grid item xs={12} md={6} lg={4} key={`section-article-${index}`}>
                        <ForumPostCard {...article} />
                    </Grid>
                )
                :
                new Array(articles.length).fill({}).map((_, index) =>
                    <Grid item xs={12} md={6} lg={4} key={`section-article-loading-${index}`}>
                        <ForumPostCard loading />
                    </Grid>
                )}
        </Grid>
    );
}
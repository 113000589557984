import React from 'react';
import { Grid } from '@mui/material';
import { IShopItemProps, ShopItem } from '../ShopItem';
import { IssueStateView } from '../../IssueStateView';
import { SHOP_PRODUCT_EMPTY_URL, SHOP_PRODUCT_ERROR_URL } from '../../../utilities/assets/assetHelper';
import { PropsWithErrorState, PropsWithLoadingState } from '../../../utilities';
import { useTranslation } from 'react-i18next';

export interface IShopListProps {
    items: IShopItemProps[];
}

export const ShopList = ({ items, loading, error }: PropsWithErrorState<PropsWithLoadingState<IShopListProps>>) => {
    const { t } = useTranslation();

    if (!!error) {
        return <IssueStateView
            title={t('shop.list.errorTitle')}
            image={SHOP_PRODUCT_ERROR_URL}
            description={t('shop.list.errorContent')}
            button={typeof error !== 'boolean' && !!error.onRetryClick ? {
                text: t('shop.list.errorBtn'),
                onActionClick: error.onRetryClick
            } : undefined}
        />

    }

    if (!loading && !items?.length) {
        return <IssueStateView
            title={t('shop.list.emptyTitle')}
            image={SHOP_PRODUCT_EMPTY_URL}
            description={t('shop.list.emptyContent')}
        />
    }

    return (
        <Grid container justifyContent={'center'} alignItems={'stretch'} spacing={1}>
            {
                !loading ?
                    items.map((item, index) =>
                        <Grid key={`shop-product-${index}`} item xs={12} sm={6} md={3} lg={2}>
                            <ShopItem {...item} containerStyle={{ height: '100%' }} />
                        </Grid>)
                    :
                    new Array(6).fill('').map((_, index) =>
                        <Grid key={`shop-product-loading-${index}`} item xs={12} sm={6} md={3} lg={2}>
                            <ShopItem loading />
                        </Grid>)
            }
        </Grid>
    )
}
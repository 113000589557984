import { Grid, Link, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useQuery } from 'react-query';
import { Parser } from 'xml2js';
import { IssueStateView } from '../../components/IssueStateView';

type SitemapURLType = {
    /**
     * URL of the page. This URL must begin with the protocol (such as http) and end with a trailing slash, if your web server requires it. This value must be less than 2,048 characters.
     * @example "https://cohostop.com/"
     */
    loc: string;
    /** How frequently the page is likely to change. This value provides general information to search engines and may not correlate exactly to how often they crawl the page. */
    changefreq?: "always"
    | "hourly"
    | "daily"
    | "weekly"
    | "monthly"
    | "yearly"
    | "never";
    /** 
     * The date of last modification of the page. This date should be in W3C Datetime format. This format allows you to omit the time portion, if desired, and use YYYY-MM-DD. 
     * @example "2023-01-16"
     */
    lastmod?: string;
    /**
     * The priority of this URL relative to other URLs on your site. 
     * Valid values range from 0.0 to 1.0. 
     * This value does not affect how your pages are compared to pages on other sites—it only lets the search engines know which pages you deem most important for the crawlers.
     * @default 0.5
     */
    priority?: number
}

/** Sitemap object type following https://www.sitemaps.org/protocol.html */
type SitemapObjectType = {
    urlset: {
        /** Parent tag for each URL entry. The remaining tags are children of this tag. */
        url: SitemapURLType[]
    }
}

const getSitemap = () => {
    return new Promise<SitemapURLType[]>((resolve, reject) => {
        fetch(
            // `${"https://cohostop.com"}/sitemap.xml`,
            "http://localhost:3000/sitemap.xml",
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/xml; charset=utf-8"
                }
            })
            .then(response => response.text())
            .then(data => {
                let parser = new Parser({
                    explicitArray: false,
                });
                parser.parseString(
                    data,
                    function (err, result) {
                        if (!!err) {
                            reject(err);
                        } else {
                            const parsedData = result as SitemapObjectType;
                            resolve(parsedData.urlset.url);
                        }
                    }
                );
                return data;
            })
            .catch((e) => {
                reject(e);
            });
    });
}

const extractTitle = (urlObject: SitemapURLType) => {
    var parts = urlObject.loc.split('/');
    var lastSegment = parts.pop() || parts.pop() || ''; // handle potential trailing slash
    return lastSegment[0].toUpperCase() + lastSegment.slice(1);
}

export const SitemapPage = () => {
    const { data, isLoading, isError } = useQuery(['sitemap'], getSitemap);

    if (isError) {
        return <IssueStateView title='Sitemap' description='Something went wrong while retrieving the sitemap.' />
    }

    return <Grid container padding={2} justifyContent={'center'} alignItems={'stretch'}>
        <Grid item xs={12}>
            <Typography variant={'h1'} textAlign={'center'}>Sitemap</Typography>
        </Grid>
        {
            (isLoading || !data) ?
                new Array(5).fill('').map((_, index) =>
                    <Grid item xs={12}>
                        <Skeleton key={`loading-index-${index}`}
                            variant='text'
                            sx={{
                                height: 20,
                                width: '60%',
                                marginRight: '20%',
                                marginLeft: '20%'
                            }} />
                    </Grid>
                )
                :
                data.map((urlObject, index) =>
                    <Grid item xs={12}>
                        <Typography textAlign={'center'}>
                            <Link key={`link-index-${index}`}
                                href={urlObject.loc}>
                                {extractTitle(urlObject)}
                            </Link>
                        </Typography>
                    </Grid>
                )
        }
    </Grid>
}
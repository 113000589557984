import React, { PropsWithChildren, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, CircularProgress, Stack, styled, Typography } from '@mui/material';
import { ERole, getColorForUserRole, getDBUserRoleFrom, getStringKeyForUserRole, getUIUserRoleFrom } from '../../../utilities/EnumUtility';
import { UserContext } from '../../../context/UserInformation';
import { getUserListings, updateUserPreferencesInfo } from '../../../services/user';
import { EUserRole } from '../../../types/db';
import { DashboardSectionReservations } from '../../../components/user/dashboard/DashboardSectionReservations';
import { DashboardSectionListings } from '../../../components/user/dashboard/DashboardSectionListings';
import { DashboardHelpSection } from '../../../components/user/dashboard/DashboardHelpSection';
import { AddCircle } from '@mui/icons-material';
import { DashboardSectionEvents } from '../../../components/user/dashboard/DashboardSectionEvents';
import { USER_SELECTION_URL } from '../../../utilities/assets/assetHelper'
import { useQuery } from 'react-query';
import { ISubListingItemProps } from '../../../components/listing/SubListingItem';
import { useNavigate } from 'react-router';
import { ROUTE_PATH } from '../../../utilities/navigation';

const ColorButton = styled(Button)<ButtonProps & { role: ERole }>(({ theme, role }) => {
    const userColor = getColorForUserRole(role);
    return {
        color: theme.palette.getContrastText(userColor),
        backgroundColor: userColor,
        '&:hover': {
            backgroundColor: userColor,
            opacity: 0.5
        },
    };
});

export const UserDashboardPage = () => {
    const navigate = useNavigate();

    const navigateToListingPreview = (listingId: string) => {
        navigate(ROUTE_PATH.listingBuild(listingId, true))
    }
    const navigateToListingManagement = (listingId?: string) => {
        if (!listingId)
            navigate(ROUTE_PATH.dashboardBuild("listings"))
        else
            navigate(ROUTE_PATH.listingSettingsBuild(listingId))
    }
    //page will handle any potential navigation
    return <UserDashboardContainer
        navigateToListingPreview={navigateToListingPreview}
        navigateToListingManagement={navigateToListingManagement}
    />
}

interface IUserDashboardContainerProps {
    navigateToListingPreview: (listingId: string) => void;
    navigateToListingManagement: (listingId?: string) => void;
}

const UserDashboardContainer = ({
    ...navigationProps
}: IUserDashboardContainerProps) => {
    const userContext = useContext(UserContext);

    if (!userContext) {
        console.warn('Need UserContext');
        return null;
    }
    if (!userContext.user) {
        console.warn('No user info');
        return null;
    }
    const userId = userContext.user?.firebaseUser?.uid;
    if (!userId) {
        console.warn('No user id');
        return null;
    }

    const userRole = userContext.user?.role;

    if (userRole === true) {
        //still loading role
        return null
    }
    if (!userRole) {
        //if no user role predefined, make the user choose
        return <UserSelectRoleContainer />
    }

    const containerProps: IUserDashboardContainersProps = {
        userId,
        userRole
    }

    return (
        <UserDashboardPageView displayName={userContext.user.displayName}
            userRole={getUIUserRoleFrom(userRole)!}>
            <>
                <UserDashboardReservationsContainer {...containerProps} />
                <UserDashboardListingsContainer
                    {...containerProps}
                    {...navigationProps}
                />
                <UserDashboardEventsContainer {...containerProps} />
                <UserDashboardHelpContainer {...containerProps} />
            </>
        </UserDashboardPageView>
    );
}

interface IUserDashboardPageView {
    /** The user's display name */
    displayName: string;
    /** The current role of the user (practical to display something specific of this user's role) */
    userRole: ERole;
}

/** A wrapper view for every user's role dashboard */
const UserDashboardPageView = ({
    displayName,
    children
}: PropsWithChildren<IUserDashboardPageView>) => {
    const { t } = useTranslation();
    return (
        <Stack direction={'column'}
            justifyContent={'start'}
            alignItems={'stretch'}
            spacing={3}
            sx={{ px: { xs: 2, sm: 10 }, py: 2 }}>
            <Typography variant='h2'>{t('user.dashboard.title', { name: displayName })}</Typography>
            {children}
        </Stack>
    )
}

/** Generic props for all containers in user dashboard */
interface IUserDashboardContainersProps {
    userId: string;
    userRole?: EUserRole;
}

interface IUserDashboardListingsContainerProps
    extends IUserDashboardContainersProps,
    Pick<IUserDashboardContainerProps, 'navigateToListingPreview' | 'navigateToListingManagement'> { };

const UserDashboardListingsContainer = ({ userId, userRole, navigateToListingManagement, navigateToListingPreview }: IUserDashboardListingsContainerProps) => {
    const { data, isLoading, isError, } = useQuery(
        ["user", "listings", userId, userRole],
        () => getUserListings(userId || '', userRole || EUserRole.HOST, true),
        { enabled: !!userId && !!userRole });

    if (userRole === EUserRole.GUEST) {
        // A guest do not have listings (this is a protection, this should be checked before)
        return null;
    }
    if (isError) {
        //TODO proper error with retry button
        return null;
    }
    if (isLoading || !data) {
        return <CircularProgress />
    }

    const items: ISubListingItemProps[] =
        (
            (data.length === 1 && (data[0].sub_listings?.length ?? 0) > 0) ?
                data[0].sub_listings //if there is only 1 parent listing and it has sub-listing
                : data
        )
            .map(listing => ({
                title: listing.title,
                image: listing.main_photo?.url || '',
                onClick: () => userRole === EUserRole.HOST ? navigateToListingManagement(listing.id) : navigateToListingPreview(listing.id)
            }));

    return <DashboardSectionListings
        items={items}
        userRole={getUIUserRoleFrom(userRole!)!}
        onViewAll={() => navigateToListingManagement()} />;
}

/** Container which takes care of fetching the reservations for the user. 
 * Depending on role, it will either fetch the trips for guests, the bookings for host or the reservations for cohost */
const UserDashboardReservationsContainer = ({ userId, userRole }: IUserDashboardContainersProps) => {

    switch (userRole) {
        case EUserRole.HOST:
            return <DashboardSectionReservations items={[]} userRole={getUIUserRoleFrom(userRole)!} />;
        case EUserRole.COHOST:
            return <DashboardSectionReservations items={[]} userRole={getUIUserRoleFrom(userRole)!} />;
        case EUserRole.GUEST:
            return <DashboardSectionReservations items={[]} userRole={getUIUserRoleFrom(userRole)!} />;
        default:
            return null;
    }
}

/** Container which takes care of fetching the events for the user */
const UserDashboardEventsContainer = ({ userId, userRole }: IUserDashboardContainersProps) => {
    //TODO fetch data
    const overwrite = false;
    if (overwrite)
        return <DashboardSectionEvents items={[]} userRole={getUIUserRoleFrom(userRole!)!} />;
    return null
}

/** Container which takes care of fetching some article from the help center to go to */
const UserDashboardHelpContainer = ({ userId, userRole }: IUserDashboardContainersProps) => {
    const { t } = useTranslation();
    const item = {
        title: t('user.dashboard.helpTitle'),
        description: t('user.dashboard.helpContent'),
        icon: <AddCircle />,
        onClick: () => { },
    };
    //TODO fetch help with target audience userRole
    const overwrite = false;
    if (overwrite)
        return (
            <DashboardHelpSection helpItems={new Array(5).fill(item)} tipItems={new Array(5).fill(item)} />
        );

    return null;
}

export const UserSelectRoleContainer = () => {
    const { t } = useTranslation();
    const userContext = useContext(UserContext);
    const userId = userContext?.user?.firebaseUser?.uid;

    const handleUserChoice = (role: ERole) => {
        //update the role in pref
        if (!userId) {
            console.warn("no user Id")
        } else {
            updateUserPreferencesInfo(userId, { role: getDBUserRoleFrom(role)! })
        }
    }

    return (
        <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={1} sx={{ padding: 2 }}>
            <Typography variant={'h3'} textAlign={'center'}>{t('user.dashboard.noRoleTitle')}</Typography>
            <Typography variant={'caption'}>{t('user.dashboard.noRoleContent')}</Typography>
            <Stack direction={'row'} spacing={2}>
                <ColorButton role={ERole.HOST} onClick={() => handleUserChoice(ERole.HOST)}>
                    {t(getStringKeyForUserRole(ERole.HOST))}
                </ColorButton>
                <ColorButton role={ERole.COHOST} onClick={() => handleUserChoice(ERole.COHOST)} >
                    {t(getStringKeyForUserRole(ERole.COHOST))}
                </ColorButton>
                <ColorButton role={ERole.GUEST} onClick={() => handleUserChoice(ERole.GUEST)} >
                    {t(getStringKeyForUserRole(ERole.GUEST))}
                </ColorButton>
            </Stack>
            <img
                style={{ width: '50%' }}
                src={USER_SELECTION_URL}
                alt={t('user.dashboard.noRoleImgAlt')}
            />
        </Stack>
    )
}
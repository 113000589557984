import firebase from "firebase/app";
import { DeepPartial } from "../../types/common";
import { WithID, WithRef } from "../../types/db";
import { Listing, ListingPhoto, LISTINGS_COLLECTION, LISTING_PHOTOS_COLLECTION } from "../../types/db/listings";
import { Review, REVIEWS_COLLECTION } from "../../types/db/reviews";
import { db } from "../firebase";

/** Create a new listing */
export const createListing = async (listingInfo: DeepPartial<Listing>, parentListing?: string | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>) => {
    let doc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
    if (!parentListing) {
        //no parent to put under, have it added to the root collection
        doc = db.collection(LISTINGS_COLLECTION).doc();
    } else {
        if (typeof parentListing === 'string') {
            //if only have string id, research it first
            doc = (await getListingInfo(parentListing)).ref.collection(LISTINGS_COLLECTION).doc();
        } else {
            doc = parentListing.collection(LISTINGS_COLLECTION).doc();
        }
    }

    return doc.set({
        ...listingInfo,
        id: doc.id,
        status: "pending",
    }).then(() => doc);
}

/** Update an existing listing */
export const updateListing = async (
    listing: string | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
    listingInfo: DeepPartial<Listing>) => {
    let doc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;

    if (typeof listing === 'string') {
        //if only have string id, research it first
        doc = (await getListingInfo(listing)).ref;
    } else {
        doc = listing;
    }

    return doc.update({
        ...listingInfo,
    }).then(() => doc);
}

/** Delete an existing listing */
export const deleteListing = async (
    listing: string | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
) => {
    let doc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;

    if (typeof listing === 'string') {
        //if only have string id, research it first
        doc = (await getListingInfo(listing)).ref;
    } else {
        doc = listing;
    }

    return doc.delete();
}

/** Move an existing listing and return the doc ref of the moved listing */
export const moveListing = async (
    listing: string | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
    newParentListing?: string | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
    listingData?: Partial<Listing>
) => {
    let docListing: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;

    if (typeof listing === 'string') {
        //if only have string id, research it first
        docListing = (await getListingInfo(listing)).ref;
    } else {
        docListing = listing;
    }

    if (
        (typeof newParentListing === 'string' && docListing.parent.parent?.id === newParentListing)
        ||
        (typeof newParentListing !== 'string' && docListing.parent.parent?.id === newParentListing?.id)
    ) {
        //the listing is already in the destination parent
        console.log("the listing is already in the destination parent");
        if (!!listingData) {
            return updateListing(docListing, listingData);//only update with data
        }
        return docListing;
    } else {
        return docListing.get()
            .then(doc => doc.data() as Listing)//get the data
            .then(docListingData => createListing({ ...docListingData, ...listingData }, newParentListing))//put data in new parent
            .then((newDoc) => { deleteListing(docListing); return newDoc; })//delete previous doc and return the newly created doc
    }
}


/** Get the listing information details from a listing id or ref */
export const getListingInfo = async (listing: string | firebase.firestore.DocumentReference) => {
    let docSnap: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;

    if (typeof listing === 'string') {
        const data = await db.collectionGroup(LISTINGS_COLLECTION)
            .where("id", "==", listing)
            .limit(1)
            .get();
        if (data.size < 1) {
            return Promise.reject(`Listing id ${listing} not found`);
        }
        docSnap = data.docs[0];
    } else {
        docSnap = await listing.get();
    }

    return {
        ...docSnap.data() as Listing,
        id: docSnap.id,
        ref: docSnap.ref,
    } as WithRef<WithID<Listing, string>>
}

/**
 * Get the photos from the listing
 * @param listing the listing id or a list of listing ids (if we want pictures from listing and its listing parents)
 * or can be directly the listing reference (for faster fetching)
 * @returns the list of photos
 */
export const getListingPhotos = async (
    listing: string | string[]
        | firebase.firestore.DocumentReference | firebase.firestore.DocumentReference[],
    limit?: number,
): Promise<WithRef<WithID<ListingPhoto, string>>[]> => {
    if (Array.isArray(listing)) {
        return (await Promise.all(listing.map(l => getListingPhotos(l)))).reduce((prev, cur) => {
            return [...prev, ...cur];
        }, []);
    }
    let listingRef: firebase.firestore.DocumentReference;
    if (typeof listing === 'string') {
        //if it's a single listing id
        listingRef = (await getListingInfo(listing)).ref;
    } else {
        listingRef = listing;
    }
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> =
        listingRef.collection(LISTING_PHOTOS_COLLECTION);
    if (!!limit) {
        query = query.limit(limit);
    }
    const data = await query.get();
    return data.docs.map(doc => ({
        ...doc.data() as ListingPhoto,
        id: doc.id,
        ref: doc.ref,
    } as WithRef<WithID<ListingPhoto, string>>));
}

/**
 * Add a photo to the listing (only deals with the data, to add in storage call `addPictureToListing from storage service)
 * @param photo The photo
 * @param listing The listing we want to add it to
 * @returns A Promise resolved with a DocumentReference pointing to the newly created photo document after it has been written to the backend.
 */
export const addPhotoToListing = async (
    photo: Omit<ListingPhoto, 'timestamp'>,
    listing: string | firebase.firestore.DocumentReference) => {
    if (!listing) return Promise.reject('Listing not defined');
    let listingRef: firebase.firestore.DocumentReference;
    if (typeof listing === 'string') {
        //if it's a single listing id
        listingRef = (await getListingInfo(listing)).ref;
    } else {
        listingRef = listing;
    }
    const photoData: ListingPhoto = {
        ...photo,
        timestamp: Date.now(),
    }
    return listingRef.collection(LISTING_PHOTOS_COLLECTION)
        .add(photoData);
}

export const updatePhotoFromListing = (
    photo: firebase.firestore.DocumentReference,
    photoData: Omit<ListingPhoto, 'timestamp'>,
) => {
    return photo.update(photoData);
}

export const deletePhotoFromListing = (
    photo: firebase.firestore.DocumentReference,
) => {
    return photo.delete();
}

/**
 * Get the review from the listing
 * @param listing the listing id or a list of listing ids (if we want reviews from listing and its listing parents)
 * or can be directly the listing reference (for faster fetching)
 * @returns the list of reviews
 */
export const getListingReviews = async (
    listing: string | string[]
        | firebase.firestore.DocumentReference | firebase.firestore.DocumentReference[],
    limit?: number,
): Promise<Review[]> => {
    if (Array.isArray(listing)) {
        return (await Promise.all(listing.map(l => getListingReviews(l)))).reduce((prev, cur) => {
            return [...prev, ...cur];
        }, []);
    }
    let listingRef: firebase.firestore.DocumentReference;
    if (typeof listing === 'string') {
        //if it's a single listing id
        listingRef = (await getListingInfo(listing)).ref;
    } else {
        listingRef = listing;
    }
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> =
        listingRef.collection(REVIEWS_COLLECTION);
    if (!!limit) {
        query = query.limit(limit);
    }
    const data = await query.get();
    return data.docs.map(doc => doc.data() as Review);
}
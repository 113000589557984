import React from "react";
import { Card, Stack, Avatar, Typography, Skeleton } from "@mui/material";
import { PropsWithLoadingState } from "../../../utilities";

export interface IForumPostCardProps {
    /** The author of the post */
    author: {
        avatar: string;
        displayname: string;
    },
    /** The title of the post */
    title: string;
    /** The content of the post (will be truncated of summary) */
    content: string;
    /** Callback on click on the item */
    onClick: () => void;
}

/** Summary card of a forum post */
export const ForumPostCard = ({ author, title, content, onClick, loading }: PropsWithLoadingState<IForumPostCardProps>) => {
    return (
        <Card onClick={onClick} sx={{ p: 2 }}>
            <Stack direction={'column'} spacing={1}>
                <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} spacing={1}>
                    {!loading ? <Avatar src={author.avatar} /> : <Skeleton variant={"circular"}><Avatar /></Skeleton>}
                    <Typography>{!loading ? author.displayname : <Skeleton width={'20vw'} />}</Typography>
                </Stack>
                <Typography sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                }} variant={'h4'}>{!loading ? title : <Skeleton width={'50%'} />}</Typography>
                <Typography sx={{
                    // maxLines: 3,
                    // textOverflow: 'ellipsis',
                    // lineClamp: 3,
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                }}>{!loading ? content : <Skeleton variant={"rectangular"} />}</Typography>
            </Stack>
        </Card>
    )
}
import React from 'react';
import { useQuery } from 'react-query';
import { ProfileReviewSection } from '../../../components/profile/ProfileReviewSection';
import { getUserInfo, getUserReviews, UserReviewQueryParams } from '../../../services/user';
import { EUserRole, User } from '../../../types/db';

interface IProfileReviewsContainerProps {
    userId: string;
    userInfo?: User;//TODO passing the argument if already fetched, strange, refactor to not have that kind of stuff
}

const ProfileReviewsListContainer = ({ userId, queryParams }: Pick<IProfileReviewsContainerProps, 'userId'> & { queryParams: UserReviewQueryParams }) => {
    const { isLoading, isError, data } = useQuery(['Profile-Review', userId], () => getUserReviews(userId, { ...queryParams, limit: 6 }));

    if (isLoading) {
        return null;
    }
    if (isError) {
        return null;
    }
    return <><div>{JSON.stringify(queryParams)}</div><div>{JSON.stringify(data)}</div></>;
}

export const ProfileReviewsContainer = ({ userId, userInfo }: IProfileReviewsContainerProps) => {
    const { isLoading, isError, data } = useQuery(['Profile-Details', userId], () => getUserInfo(userId), { enabled: !userInfo });

    if (isLoading) {
        return <ProfileReviewSection loading />
    }

    if (isError) {
        return null;
    }

    userInfo = userInfo || data;

    if (!userInfo) {
        return null;
    }

    return <ProfileReviewSection
        profileDisplayName={userInfo.display_name}
        asHost={userInfo.review_summary?.as_host ?
            {
                numberReviews: userInfo.review_summary.as_host.quantity,
                fromCohost: userInfo.review_summary.as_host.from_cohost ?
                    {
                        numberReviews: userInfo.review_summary.as_host.from_cohost.quantity,
                        renderList: () => <ProfileReviewsListContainer userId={userId}
                            queryParams={{ to: EUserRole.HOST, from: EUserRole.COHOST }} />,
                    }
                    : undefined,
                fromGuest: userInfo.review_summary.as_host.from_guest ?
                    {
                        numberReviews: userInfo.review_summary.as_host.from_guest.quantity,
                        renderList: () => <ProfileReviewsListContainer userId={userId}
                            queryParams={{ to: EUserRole.HOST, from: EUserRole.GUEST }} />,
                    }
                    : undefined,
            }
            : undefined
        }
        asCohost={userInfo.review_summary?.as_cohost ?
            {
                numberReviews: userInfo.review_summary.as_cohost.quantity,
                fromHost: userInfo.review_summary.as_cohost?.from_host ?
                    {
                        numberReviews: userInfo.review_summary.as_cohost.from_host.quantity,
                        renderList: () => <ProfileReviewsListContainer userId={userId}
                            queryParams={{ to: EUserRole.COHOST, from: EUserRole.HOST }} />,
                    }
                    : undefined,
                fromGuest: userInfo.review_summary.as_cohost?.from_guest ?
                    {
                        numberReviews: userInfo.review_summary.as_cohost.from_guest.quantity,
                        renderList: () => <ProfileReviewsListContainer userId={userId}
                            queryParams={{ to: EUserRole.COHOST, from: EUserRole.GUEST }} />,
                    } : undefined,
            }
            : undefined
        }
        asGuest={userInfo.review_summary?.as_guest ?
            {
                numberReviews: userInfo.review_summary.as_guest.quantity,
                fromHost: userInfo.review_summary.as_guest.from_host ?
                    {
                        numberReviews: userInfo.review_summary.as_guest.from_host.quantity,
                        renderList: () => <ProfileReviewsListContainer userId={userId}
                            queryParams={{ to: EUserRole.GUEST, from: EUserRole.HOST }} />,
                    }
                    : undefined,
                fromCohost: userInfo.review_summary.as_guest.from_cohost ?
                    {
                        numberReviews: userInfo.review_summary.as_guest.from_cohost.quantity,
                        renderList: () => <ProfileReviewsListContainer userId={userId}
                            queryParams={{ to: EUserRole.GUEST, from: EUserRole.COHOST }} />,
                    }
                    : undefined,
            }
            : undefined
        }
    />;
}
import firebase from "firebase/app";
import { db, functions } from "../firebase";
import { ENewsCategories, NewsStory, NEWS_CATEGORY_FIELD, NEWS_COLLECTION, NEWS_TAGS_FIELD, NEWS_TIMESTAMP_CREATED_AT_FIELD, NEWS_TIMESTAMP_UPDATED_AT_FIELD, WithID, NEWS_TIMESTAMP_PUBLISHED_AT_FIELD } from "../../types/db";
import { applyVariablesToQuery, QueryVariables } from "../db";
import { LISTING_IMAGE_4 } from "../../utilities/TestingUtils";
import { getPicturesOfStory, updateContentOfStory } from "../storage";
import { HTTP_STORY_CONTENT_NEWS } from "../../types/functions";

export const getNewsCollectionRef = () => {
    return db.collection(NEWS_COLLECTION);
}

/** Get a list of stories */
export const getStories = async (variables: QueryVariables) => {
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> = getNewsCollectionRef();
    query = applyVariablesToQuery(query, variables);

    const data = await query.get();

    return data.docs.map(doc => ({
        ...doc,//full doc for pagination
        ...(doc.data() as NewsStory),
        id: doc.id,//id to redirect to details
    })) as WithID<NewsStory, string>[];
}

/** Get the basic info of a story */
const getStoryBasicInfo = async (storyId: string) => {
    const data = await getNewsCollectionRef()
        .doc(storyId)
        .get();

    return data.data() as NewsStory;
}

/** Get the markdown content of a story */
const getStoryContent = async (storyID: string, locale: string) => {
    return functions.httpsCallable(HTTP_STORY_CONTENT_NEWS)({
        storyID,
        // storyID: '4ammSwaYp5ZtZVRGfImv',//this is the only one with markdown file data
        locale,
    })
        .then((res) => {
            return res.data as string;
        });
}

/** Get all the assets (images) for this story */
export const getStoryAssets = (storyId: string) => {
    return getPicturesOfStory(storyId);
}

/** Get the full story details */
export const getStoryInfo = async (storyId: string, locale?: string) => {
    const [story, content] = await Promise.all([
        getStoryBasicInfo(storyId),
        getStoryContent(storyId, locale || 'en')
    ]);

    return {
        ...story,
        content
    } as NewsStory & { content: string };
}

/**
 * Update the story
 * @param storyId The story to update
 * @param data The new data (pick only what is allowed to update)
 * @param isNew Boolean indicating if the story is brand new
 * @returns A promise when update done
 */
export const updateStory = async (
    storyId: string,
    data: Pick<NewsStory, 'title' | 'content' | 'image' | typeof NEWS_CATEGORY_FIELD | typeof NEWS_TAGS_FIELD>,
    isNew: boolean,
) => {
    const { content, ...restData } = data;
    let docId = storyId;

    if (!docId || isNew) {
        //if new, we create a new one
        const newDoc = await getNewsCollectionRef().add({
            [NEWS_TIMESTAMP_PUBLISHED_AT_FIELD]: firebase.firestore.FieldValue.serverTimestamp(),
            ...restData,//potential overwrite of default published at
            author: {
                display_name: 'CoHostOp',
                id: 'cohostop',
            },
            [NEWS_TIMESTAMP_CREATED_AT_FIELD]: firebase.firestore.FieldValue.serverTimestamp(),
            [NEWS_TIMESTAMP_UPDATED_AT_FIELD]: firebase.firestore.FieldValue.serverTimestamp(),
        });
        docId = newDoc.id;
    } else {
        //if existing we update it
        await getNewsCollectionRef().doc(docId).update({
            ...restData,
            [NEWS_TIMESTAMP_UPDATED_AT_FIELD]: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }

    //update the markdown content
    const uploadTask = await updateContentOfStory(docId, content);
    return uploadTask;
}

//todo remove (this is temporary to fill up the db)
export const createStory = () => {
    return updateStory(
        '',
        {
            title: 'Title of a story - awesome stays!!',
            image: LISTING_IMAGE_4,
            category: ENewsCategories.STAYS,
            content: '',
            tags: [],
        },
        true
    )
}

//only for single update of the publish date, use updateStory for general update
export const updateStoryPublishDate = async (storyId: string, publishDate: Date) => {
    await getNewsCollectionRef().doc(storyId).update({
        [NEWS_TIMESTAMP_PUBLISHED_AT_FIELD]: firebase.firestore.Timestamp.fromDate(publishDate),
    });
}
import React from "react";
import { Stack, Typography, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ENewsCategories } from "../../types/db";

export interface INewsCategoriesSelectProps {
    value?: ENewsCategories;
    onChange: (value?: ENewsCategories) => void;
    hasAllChip?: boolean;
}

export const NewsCategoriesSelect = ({ value, onChange, hasAllChip }: INewsCategoriesSelectProps) => {
    const { t } = useTranslation();
    return (
        <Stack direction={'row'} spacing={1} justifyContent={'start'} alignItems={'center'} flexWrap={'wrap'}>
            <Typography>{t('news.main.categoryTitle')}</Typography>
            {!!hasAllChip && <Chip key={'all'}
                label={t('news.stories.categoryOption', { context: 'all' })}
                variant={!!value ? 'outlined' : 'filled'} onClick={() => onChange()} />}
            {Object.values(ENewsCategories).map(category => <Chip key={category}
                label={t('news.stories.categoryOption', { context: category })}
                variant={value !== category ? 'outlined' : 'filled'}
                onClick={() => onChange(category)} />)}
        </Stack>
    )
}

import React, { } from 'react';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface IShopCheckoutProps {
    /** Callback on continue shopping clicked */
    onContinueShopping: () => void;
}

export const ShopCheckoutCancel = ({
    onContinueShopping,
}: IShopCheckoutProps) => {
    const { t } = useTranslation();
    return (
        <Grid container spacing={1} padding={2}>
            <Grid item xs={12}>
                <Stack direction={'column'}
                    justifyContent={'start'}
                    alignItems={'center'}
                    spacing={2}>
                    <Typography variant='h3' textAlign={'center'}>{t('shop.checkout.cancelTitle')}</Typography>
                    <Divider />
                    <Typography textAlign={'center'}>
                        {t('shop.checkout.cancelInfo')}
                    </Typography>
                    <Typography textAlign={'center'}>
                        {t('shop.checkout.cancelContent')}
                    </Typography>
                    <Button variant={'contained'}
                        onClick={onContinueShopping}>
                        {t('shop.checkout.cancelButton')}
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
}

import React from "react";
import { TransProps, useTranslation } from "react-i18next";
import { TestimonialScreen } from "../../components/TestimonialScreen/TestimonialScreen";
import { ROUTE_PATH, TAudience, TCategory } from "../../utilities/navigation";
import { getCategoryInfo } from "../CategoryContainer/CategoryContainer";
import { useNavigate } from "react-router";

interface ITestimonialContainerProps {
    audience?: TAudience;
    category?: TCategory;
}

/** Container which takes care of fetching the data of a testimonial for the given audience and category */
export const TestimonialContainer = ({ audience, category }: ITestimonialContainerProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleButtonCLick = () => {
        navigate(ROUTE_PATH.authBuild("join"))
    }

    const categoryInfo = !audience || !category ?
        {
            title: t('testimonial.cnc.title'),
            description: t('testimonial.cnc.description'),
            image: 'https://a0.muscache.com/im/pictures/user/e21bd483-ae64-46d8-b0c9-c8955ea999db.jpg'
        }
        : getCategoryInfo(t, audience, category);

    const content: TransProps<any> = !audience || !category ?
        { i18nKey: `testimonial.cnc.content` }
        : { i18nKey: `testimonial.${audience}`, context: category };

    return <TestimonialScreen
        title={categoryInfo?.title}
        description={categoryInfo?.description}
        content={content}
        image={categoryInfo?.image || ''}
        onButtonClick={handleButtonCLick}
        imageStyle={!audience || !category ? { objectFit: 'contain' } : undefined} />
}

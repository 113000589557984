import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { GridView } from '@mui/icons-material';
import { ListingImage } from './ListingImage';
import { PropsWithLoadingState } from '../../../utilities';
import { useTranslation } from 'react-i18next';

const SPACING = 2;

export interface IListingPicturesProps {
    /** The list of image urls */
    images: string[];
    /** The total of images in the listing */
    totImages: number;
    /** The callback when pressed on the show more action */
    onShowMoreClick?: () => void;
}

const ListingPicturesMobile = ({ images, totImages, onShowMoreClick, loading }: PropsWithLoadingState<IListingPicturesProps>) => {
    return (
        <Box sx={{ width: "100%", height: "60vw", minHeight: 300 }} onClick={onShowMoreClick}>
            <ListingImage loading={loading} imageUrl={!loading ? images[0] : undefined} />
            {!loading &&
                <Box sx={{ position: 'absolute', top: 10, right: 24, padding: 2, borderRadius: 1, backgroundColor: "#FFFFFF88" }}>
                    <Typography>{`1/${totImages}`}</Typography>
                </Box>
            }
        </Box>
    );
}

const ListingPicturesWeb = ({ images, totImages, onShowMoreClick, loading }: PropsWithLoadingState<IListingPicturesProps>) => {
    const { t } = useTranslation();
    const MAX_NUM_PIC_SHOWN = 5;
    return (
        <Box sx={{ width: "100%", height: '50vw', minHeight: 300 }}>
            <Grid container columnSpacing={SPACING} wrap="nowrap" height={"100%"}>
                <Grid item xs={6} >
                    <ListingImage loading={loading} imageUrl={!loading ? images[0] : undefined} />
                </Grid>
                <Grid item xs={6} >
                    <Grid container columnSpacing={SPACING} rowGap={SPACING} height={"100%"}>
                        {
                            new Array(MAX_NUM_PIC_SHOWN - 1).fill('').map((_, index) =>
                                <Grid item xs={6} key={`Picture-${index}`}>
                                    <ListingImage loading={loading} imageUrl={!loading ? images[index + 1] : undefined} />
                                </Grid>)
                        }
                    </Grid>
                </Grid>
            </Grid>
            {
                (!loading && (images.length > MAX_NUM_PIC_SHOWN || totImages > MAX_NUM_PIC_SHOWN) && !!onShowMoreClick)
                &&
                <Box sx={{ position: 'absolute', bottom: 3, right: 24 }}>
                    <Button variant={'contained'} onClick={onShowMoreClick} startIcon={<GridView />}>{t('listing.pictures.showAllBtn')}</Button>
                </Box>
            }
        </Box>
    )
}

/**
 * The Listing Picture on detail page
 * @param props IListingPicturesProps
 * @returns The rendering of the picture in the listing details
 */
export const ListingPictures = (props: PropsWithLoadingState<IListingPicturesProps>) => {
    return (
        <>
            <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
                <ListingPicturesMobile {...props} />
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                < ListingPicturesWeb {...props} />
            </Box >
        </>
    );
}

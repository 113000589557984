import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { AccountContainer } from '../../../containers/user/AccountContainer';
import { ROUTE_PARAMS, ROUTE_SUBPATH } from '../../../utilities/navigation';
import { NotFoundPage } from '../../NotFoundPage';

export const AccountSubPage = ({ subPage }: { subPage?: string }) => {
    const navigate = useNavigate();
    const { [ROUTE_PARAMS.accountPage]: accountPage } = useParams();

    const page = subPage || accountPage || '';

    if (!ROUTE_SUBPATH.isValidAccountSubpath(page)) {
        return <NotFoundPage />
    }

    return <AccountContainer page={page} onNavigateTo={navigate} />
}
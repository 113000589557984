import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { buildPath, ROUTE_PARAMS, ROUTE_PATH, ROUTE_QUERY } from "../../utilities/navigation";
import { useRouterQuery } from "../../hooks";
import { ProfileDetailsContainer } from "../../containers/profile/ProfileDetailsContainer";
import { ProfileDetails } from "../../components/profile/ProfileDetails";

export const ProfilePage = () => {
  const navigate = useNavigate();
  const query = useRouterQuery();
  const { [ROUTE_PARAMS.userID]: userId } = useParams();

  if (!userId) {
    return <ProfileDetails error />
  }

  const onAddVerification = () => navigate(
    buildPath(ROUTE_PATH.authBuild(ROUTE_PATH.auth_link),
      true,
      [{
        query: ROUTE_QUERY.from,
        value: ROUTE_PATH.users,
      }]));

  const isInEditMode = !!query.get(ROUTE_QUERY.edit_profile);

  const onEditProfile = (opening: boolean) => opening ?
    navigate(buildPath(ROUTE_PATH.userBuild(userId),
      true,
      [
        {
          query: ROUTE_QUERY.edit_profile,
          value: "true",
        },
      ]))
    : navigate(buildPath(ROUTE_PATH.userBuild(userId)));

  return <ProfileDetailsContainer userId={userId}
    onEditClick={onEditProfile}
    isInEditMode={isInEditMode}
    onAddAccountVerificationClick={onAddVerification} />;
};

import { Box, Typography } from '@mui/material';
import React from 'react';

export interface ICurrencyViewProps {
    /** The amount */
    amount: number;
    /** 
     * The currency code (ISO 4217)
     * Note: optional to any string for flexibility
     */
    currencyCode: string;
    /**
     * The formatting function which format the amount into a string (can use abbreviation)
     * @optional
     */
    formatter?: (amount: number) => string;
    /**
     * The overwrite of the text style
     * @optional
     */
    textStyle?: any;
    /**
     * The overwrite of the container style
     * @optional
     */
    containerStyle?: any;
}

export const CurrencyView = ({ amount, currencyCode, formatter }: ICurrencyViewProps) => {
    const getFormattedAmount = () => {
        if (!!formatter) {
            return formatter(amount);
        }
        return amount.toFixed(2);
    }
    return <Box><Typography variant={'subtitle1'}>{`$${getFormattedAmount()}`}</Typography></Box>
}
import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "../UserInformation";
import { DEFAULT_SHOP_ID, fetchShippingRate, fetchTaxRate } from "../../utilities/shop";
import { UserAddress } from "../../types/db";
import { IShopCartContext, ShopCartItem, useUserCart } from "./ShopCart";
import { TaxInfo } from "../../components/shop/ShopCheckout/ShopCheckout";
import { useShopNavigation } from "../../hooks/useShopNavigation";

export type ShippingOption = {
  /** The id of the shipping */
  id: string;
  /** 
   * Name of the shipping option
   * @example 'Flat Rate (Estimated delivery: Jan 13⁠–16)'
   */
  name: string;
  /**
   * The rate of the shipping
   * @example 11.28
   */
  rate: number;
  /**
   * The currency code of the shipping
   * @example 'CAD'
   */
  currency: string;
}

interface IShopCheckoutContext extends Pick<IShopCartContext, 'updateQuantityItem'> {
  /** The information on all carts (usually only one, but could have multiple for different shops) */
  items: ShopCartItem[];
  /** Handle change in item quantity */
  updateQuantityItem: ({ variantId, quantity }: Pick<ShopCartItem, 'variantId' | 'quantity'>) => Promise<void>;
  /** The list of addresses (from the user's data) */
  addresses: [];
  /** Add an address to the list of selectable addresses */
  addAddress: (address: UserAddress) => Promise<void>;
  /** Remove an address from the address list */
  removeAddress: (address: UserAddress) => Promise<void>;
  /** The selected shipping address */
  shippingAddress?: UserAddress;
  /** Select shipping address */
  selectShippingAddress: (address: UserAddress) => void;
  /** The selected billing address */
  billingAddress?: UserAddress;
  /** Select billing address */
  selectBillingAddress: (address: UserAddress) => void;
  /** The shipping/delivery options */
  shippingOptions: ShippingOption[];
  /** The selected shipping/delivery options */
  shippingOption?: ShippingOption;
  /** Select shipping options */
  selectShippingOption: (option: ShippingOption | undefined) => void
  /** Set the shipping options to select from */
  setShippingOptions: (options: []) => void;
  /** The tax rate for the items and shipping*/
  taxRate?: TaxInfo;
  /** Boolean indicating if something in the checkout is loading/refreshing */
  isLoading: boolean
}

export const ShopCheckoutContext = createContext<IShopCheckoutContext | undefined>(undefined);

export const ShopCheckoutProvider: React.FC = ({ children }) => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error('Need user context');
  }
  const { shopId } = useShopNavigation();
  const cartContext = useUserCart(shopId || DEFAULT_SHOP_ID);
  if (!cartContext) {
    throw new Error('Need cart context');
  }

  const [isLoading, setIsLoading] = useState(false)

  //selected shipping address
  const [shippingAddress, setShippingAddress] = useState<UserAddress>();
  //selected billing address
  const [billingAddress, setBillingAddress] = useState<UserAddress>();

  //the list of shipping options
  const [shippingOptions, setShippingOptions] = useState<ShippingOption[]>([]);
  //the selected shipping option
  const [shippingOption, setShippingOption] = useState<ShippingOption>();

  // the tax rate for the items and shipping
  const [taxRate, setTaxRate] = useState<TaxInfo | undefined>();

  //update the shipping rate/options anytime the shippingAddress and/or Items changes
  useEffect(() => {
    // console.log('useEffect shipping')
    const address = shippingAddress;
    const shippingItems = cartContext.cartItems;
    if (!address || !shippingItems.length) {
      setShippingOptions(options => !options.length ? options : [])
      return;
    }

    setIsLoading(true)
    fetchShippingRate(
      shopId || DEFAULT_SHOP_ID,
      {
        address1: address.line1,
        city: address.city,
        zip: address.zip,
        state_code: address.state || '',
        country_code: address.country
      },
      shippingItems.map(item => ({
        product_id: item.productId,
        variant_id: item.variantId,
        quantity: item.quantity || 0,
      }))
    )
      .then(res => {
        // console.log("getShippingRates", res);
        setShippingOptions(res.data.map((item: any) => ({
          ...item,
          rate: +item.rate,
        })
        ));
        setIsLoading(false)
      });
  }, [shippingAddress, cartContext.cartItems, shopId]);

  //update the tax rate depending on shipping
  useEffect(() => {
    // console.log('useEffect tax')
    const address = shippingAddress;
    if (!address) {
      //bypass taxes since not address to fetch it for
      setTaxRate(undefined)
      return;
    }
    setIsLoading(true)
    fetchTaxRate({
      address1: address.line1,
      city: address.city,
      zip: address.zip,
      state_code: address.state || '',
      country_code: address.country
    })
      .then(res => {
        // console.log("getTaxRates", res);
        setTaxRate(res.data);
        setIsLoading(false)
      });
  }, [shippingAddress]);

  const addAddress = async (address: UserAddress) => {
    const userId = userContext.user?.firebaseUser?.uid
    if (!!userId) {
      //if user logged in add address to its address book for future use
      // getUserPrivateDocRef(userId, USER_PRIVATE_INFO_DOCUMENT)//TODO
    }
    //auto set the selected shipping address to the new added address
    setShippingAddress(address);
  }

  const removeAddress = async (address: UserAddress) => {
    if (!address) return;
    const userId = userContext.user?.firebaseUser?.uid
    if (!!userId) {
      //if user logged in add address to its address book for future use
      // getUserPrivateDocRef(userId, USER_PRIVATE_INFO_DOCUMENT)//TODO
    }
    //if the shipping or billing address were the removed address reset those
    if (address === shippingAddress) {
      setShippingAddress(undefined);
    }
    if (address === billingAddress) {
      setBillingAddress(undefined)
    }
  }

  return (
    <ShopCheckoutContext.Provider value={{
      items: cartContext.cartItems,
      updateQuantityItem: cartContext.updateQuantityItem,
      addresses: [],
      addAddress,
      removeAddress,
      shippingAddress,
      selectShippingAddress: (address) => setShippingAddress(address),
      billingAddress,
      selectBillingAddress: (address) => setBillingAddress(address),
      shippingOptions,
      setShippingOptions,
      shippingOption,
      selectShippingOption: (option) => setShippingOption(option),
      taxRate,
      isLoading
    }}>
      {children}
    </ShopCheckoutContext.Provider>
  );
};

import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { INewsItemProps, NewsItem } from "./NewsItem";
import { PropsWithLoadingState } from "../../utilities";

interface INewsStoriesScreenProps {
    items: PropsWithLoadingState<INewsItemProps[]>;
    onLoadMoreClick?: () => Promise<any>;
    renderFilters: JSX.Element;
}

export const NewsStoriesScreen = ({ items, onLoadMoreClick, renderFilters }: INewsStoriesScreenProps) => {
    const { t } = useTranslation();
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);

    const handleLoadMoreClick = async () => {
        setLoadMoreLoading(true);
        await onLoadMoreClick?.();
        setLoadMoreLoading(false);
    }
    return (
        <Container>
            <Grid container p={2} spacing={1}>
                <Grid item xs={12}>
                    <Typography variant={'h1'}>{t('news.stories.title')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {renderFilters}
                </Grid>
                <Grid item container xs={12} spacing={3}>
                    {
                        Array.isArray(items) ?
                            items.map((story, index) =>
                                <Grid item xs={12} sm={4} md={3} lg={2} xl={1} key={`story-${index}`}>
                                    <NewsItem {...story} orientation={'vertical'} />
                                </Grid>
                            )
                            :
                            new Array(items.length || 6).fill({}).map((_, index) =>
                                <Grid item xs={12} sm={4} md={3} lg={2} xl={1} key={`story-${index}`}>
                                    <NewsItem loading orientation={'vertical'} />
                                </Grid>
                            )
                    }
                </Grid>
                {Array.isArray(items) && !items.length && !onLoadMoreClick &&
                    <Grid item xs={12}>
                        <Typography>{t('news.stories.emptyContent')}</Typography>
                    </Grid>
                }
                {
                    !!onLoadMoreClick &&
                    <Grid item xs={12}>
                        <LoadingButton fullWidth onClick={handleLoadMoreClick} loading={loadMoreLoading}>{t('news.stories.loadMoreButton')}</LoadingButton>
                    </Grid>
                }
            </Grid>
        </Container>
    )
}
// import firebase from "firebase/app";
// import { GeoFire } from "geofire";
import * as geofire from 'geofire-common';
import { GEOLOCATION_HASH_FIELD, Listing, ListingLocation, LISTINGS_COLLECTION } from '../types/db/listings';
import { db } from "./firebase";

// Create a Firebase reference where GeoFire will store its information
// var firebaseRef = firebase.database().ref();

// Create a GeoFire index
// var geoFire = new GeoFire(firebaseRef);

/** GeoFire common method */
// export const geofire = require('geofire-common');


/**
 * 
 * @param listingId The listing id to apply the coordinate to
 * @param latitude 
 * @param longitude 
 * @returns 
 */
export const setListingLocation = (latitude: number, longitude: number, listingId?: string) => {
    const hash = geofire.geohashForLocation([latitude, longitude]);
    const locObject = {
        [GEOLOCATION_HASH_FIELD]: hash,
        lat: latitude,
        lng: longitude,
    } as ListingLocation
    // Add the hash and the lat/lng to the document. We will use the hash
    // for queries and the lat/lng for distance comparisons.
    return db.collection(LISTINGS_COLLECTION).doc(listingId || 'TEMP_' + hash).set(locObject, { merge: true });
}

/**
 * Get the root listings in the database within this location
 * @param center The center given as [latitude, longitude] pair.
 * @param radiusInM The radius of the circle in meter.
 * @returns The list of listing in the db within this location
 */
export const getListingsWithinLocation = (center: number[], radiusInM: number) => {
    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    // console.log('geofire with', center, radiusInM)
    const bounds = geofire.geohashQueryBounds(center, radiusInM);
    const promises = [];
    for (const b of bounds) {
        const q = db.collection(LISTINGS_COLLECTION)
            .orderBy("location." + GEOLOCATION_HASH_FIELD)
            .startAt(b[0])
            .endAt(b[1]);

        promises.push(q.get());
    }

    // Collect all the query results together into a single list
    return Promise.all(promises).then((snapshots) => {
        const matchingDocs = [];

        for (const snap of snapshots) {
            for (const doc of snap.docs) {
                const { lat, lng } = (doc.data() as Listing).location;

                // We have to filter out a few false positives due to GeoHash
                // accuracy, but most will match
                const distanceInKm = geofire.distanceBetween([lat, lng], center);
                const distanceInM = distanceInKm * 1000;
                if (distanceInM <= radiusInM) {
                    matchingDocs.push(doc);
                }
            }
        }

        return matchingDocs;
    });
}
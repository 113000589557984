import React from "react";
import { Typography, Grid } from "@mui/material";
import { EForumTopic, getBackgroundForTopic } from "../../../utilities/forums";
import { ForumPostCard, IForumPostCardProps } from "../ForumPostCard/ForumPostCard";

export interface IForumPostsSectionProps {
    /** The title of the post section */
    title: string;
    /** The topic of the posts section */
    topic?: EForumTopic;
    /** The posts list */
    posts: IForumPostCardProps[] | { loading: true, length: number };
}

/** The UI for a list of forum posts (in a grid, using ForumPostCard) */
export const ForumPostsSection = ({ title, topic, posts }: IForumPostsSectionProps) => {
    if (Array.isArray(posts) && !posts?.length) {
        return null;
    }
    return (
        <Grid container spacing={1} sx={{ background: topic ? getBackgroundForTopic(topic, 0.5) : '#FFF', p: 3 }}>
            <Grid item xs={12}>
                <Typography variant={'h3'}>{title}</Typography>
            </Grid>
            {Array.isArray(posts) ?
                posts.map((post, index) =>
                    <Grid item xs={12} md={6} lg={4} key={`section-post-${index}`}>
                        <ForumPostCard {...post} />
                    </Grid>
                )
                : new Array(posts.length).fill({}).map((_, index) =>
                    <Grid item xs={12} md={6} lg={4} key={`section-post-loading-${index}`}>
                        <ForumPostCard loading />
                    </Grid>
                )
            }
        </Grid>
    );
}

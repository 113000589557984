import { useState, useEffect, useCallback } from "react";
import { auth, rtdb } from "../../services/firebase";
import { CHAT_CHANNELS, CHAT_CHANNEL_TYPING } from "../../types/db/chat";
import useDebounce from "../useDebounce";

/**
 * Hook which takes care of returning a listing of members who are typing in the channel
 * @param channelId The channel id of the channel
 * @return an array of userID who are typing
 */
export const useTypingMembers = (channelId: string) => {
    const [isTyping, setIsTyping] = useState<boolean | string>(false);
    const [typingMembers, setTypingMembers] = useState<string[]>([]);
    const debounceIsTyping = useDebounce(isTyping, 5000);

    useEffect(() => {
        const typingRef = rtdb.ref(CHAT_CHANNELS).child(channelId).child(CHAT_CHANNEL_TYPING);

        typingRef.on('value', (snapshot) => {
            const val = snapshot.val();
            setTypingMembers(Object.keys(val || {}));
        });

        return () => {
            //unsubscribe all listener
            typingRef.off();
            //reset the array of typing users
            setTypingMembers([]);
        }
    }, [channelId]);

    /** Either set if the user is currently typing or what is been typed (so automatically remove "typing" if entry is empty) */
    const setCurrentUserTyping = useCallback((isTyping: boolean | string) => {
        setIsTyping(currentValue => {
            const userID = auth.currentUser?.uid || '';
            if (currentValue !== isTyping && !!userID) {
                //only if there is a change in the state we would call the db to update
                //and only if we have a current user
                if (isTyping) {
                    rtdb.ref(CHAT_CHANNELS).child(channelId).child(CHAT_CHANNEL_TYPING)
                        .child(userID).set(true);
                } else {
                    rtdb.ref(CHAT_CHANNELS).child(channelId).child(CHAT_CHANNEL_TYPING)
                        .child(userID).remove();
                }
            }

            return isTyping;
        });

    }, [channelId]);

    useEffect(() => {
        if (!!debounceIsTyping) {
            setCurrentUserTyping(false);
        }
    }, [debounceIsTyping, setCurrentUserTyping]);

    return { typingMembers, isCurrentUserTyping: isTyping, setCurrentUserTyping }
}
import { NotificationsActive } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { AccountBaseInfo, AccountBaseInfoItems } from '../../../components/user/account/AccountBaseInfo';
import { IAccountHelpItemProps } from '../../../components/user/account/AccountHelpItem';
import { getTitleFromAccountPage } from '../../../components/user/account/AccountPageView';
import { getUserNotificationInfo } from '../../../services/user';
import { IAccountSubContainerProps } from '../AccountSubContainer';

export const AccountNotificationsContainer = ({
    subPage,
    userId,
    children,
}: IAccountSubContainerProps) => {
    const { t } = useTranslation();
    const userNotificationsResult = useQuery(["account", "notifs"],
        () => getUserNotificationInfo(userId));

    const userNotificationsInfo = userNotificationsResult.data;

    const items: AccountBaseInfoItems =
        !userNotificationsInfo ? [] :
            [
                {
                    label: t('user.accountNotifs.accountTitle'),
                    items: [],
                },
                {
                    label: t('user.accountNotifs.reminderTitle'),
                    items: [],
                },
                {
                    label: t('user.accountNotifs.chatTitle'),
                    items: [],
                },
                //TODO complete notifs
            ]

    const helpItems = HELP_ITEMS.map(h => ({
        ...h,
        title: t(h.title),
        description: t(h.description),
    }))

    if (!!children) {
        return children({ items, helpItems: helpItems });
    } else {
        return <AccountBaseInfo title={getTitleFromAccountPage(t, subPage)}
            items={items}
            helpItems={helpItems} />
    }
}

const HELP_ITEMS: IAccountHelpItemProps[] = [
    {
        icon: <NotificationsActive />,
        title: 'user.accountNotifs.helpInfo1Title',
        description: 'user.accountNotifs.helpInfo1Content',
    },
];
import React, { useCallback, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ListingDetails } from '../../../../components/listing/ListingDetails';
import { ListingDetailsContainer } from '../../../../containers/listings/ListingDetailsContainer';
import { useListingFilters } from '../../../../hooks/useListingFilter';
import { ROUTE_ANCHOR, ROUTE_PARAMS, ROUTE_PATH } from '../../../../utilities/navigation';
import { Filters } from '../../../SearchPage/SearchListingsPage.types';

/** The filters specific to the listing itself */
export type TListingFilters = Pick<Filters, 'check_in' | 'check_out' | 'adults_number' | 'children_number' | 'infants_number' | 'pets_number' | 'currency'>

export const ListingDetailsPage = () => {
    const navigate = useNavigate();
    const { [ROUTE_PARAMS.listingID]: listingId } = useParams();
    const [searchParams,] = useSearchParams();
    const { hash } = useLocation();

    const { filters, onFiltersChange } = useListingFilters();

    const scrollToHash = useCallback(() => {
        if (!hash) {
            return;
        }

        // console.log('hash', hash);
        const anchor = hash.slice(1);
        const doc = document.getElementById(anchor);
        if (!doc) {
            // console.log('no doc found with', hash, anchor)
            return;
        }
        doc?.scrollIntoView({
            behavior: "smooth",
            block: "center",
        })
    }, [hash])

    useEffect(() => {
        scrollToHash();
    }, [scrollToHash]);


    if (!listingId) {
        return <ListingDetails error />;
    }

    const navigateToListing = (listingId: string) => {
        navigate(ROUTE_PATH.listingBuild(listingId))
    }

    const navigateToAllPhotos = () => {
        navigate(ROUTE_PATH.listingPhotosBuild(listingId));
    }

    const navigateToAllReviews = () => {
        navigate(ROUTE_PATH.listingReviewsBuild(listingId));
    }

    const navigateToUser = (userId: string) => {
        navigate(ROUTE_PATH.userBuild(userId))
    }

    const navigateToBookingFlow = () => {
        navigate({
            pathname: ROUTE_PATH.listingBookBuild(listingId),
            search: searchParams.toString(),//preserve the search params
        });
    }

    const navigateToAnchor = (anchor?: keyof typeof ROUTE_ANCHOR.listing) => {
        if (!anchor) {
            scrollToHash();
        } else {
            window.location.hash = `#${anchor}`;//replace the hash only
        }
    }

    return (
        <>
            <ListingDetailsContainer listingId={listingId}
                onShowMorePicturesClick={navigateToAllPhotos}
                onShowMoreReviewsClick={navigateToAllReviews}
                onListingClick={navigateToListing}
                onUserClick={navigateToUser}
                onBookClick={navigateToBookingFlow}
                filters={filters}
                onFilterChanged={onFiltersChange}
                onAnchorClick={navigateToAnchor}
            />
            <Outlet />
        </>
    );
}
import { db, functions } from "../firebase";
import { EAudience, Help, HelpArticle, HelpSubArticle, HelpTopic, HELP_ARTICLES_COLLECTION, HELP_ARTICLE_FEEDBACK_FIELD, HELP_AUDIENCE_FIELD, HELP_COLLECTION, HELP_TOPICS_COLLECTION, WithID } from "../../types/db";
import { QueryVariables } from "../db";
import { HTTP_ARTICLE_CONTENT_HELP } from "../../types/functions";

const getHelpCollectionRef = () => {
    return db.collection(HELP_COLLECTION);
}

const getTopicRef = async (topicId: string) => {
    const data = await db.collectionGroup(HELP_TOPICS_COLLECTION)
        .where("id", "==", topicId)
        .limit(1)
        .get();

    if (!data.size) {
        return null;
    }

    return data.docs[0].ref;
}

export const getAudienceRootTopics = async (audience: EAudience, withCommon?: boolean) => {
    let data;
    if (withCommon && audience !== EAudience.COMMON) {
        data = await getHelpCollectionRef()
            .where(HELP_AUDIENCE_FIELD, 'in', [audience, EAudience.COMMON])
            .get();
    } else {
        data = await getHelpCollectionRef()
            .where(HELP_AUDIENCE_FIELD, '==', audience)
            .get();
    }
    return data.docs.map(doc => (
        {
            id: doc.id,
            ...doc.data(),
        } as WithID<Help, string>
    ));
}

export const getTopicInfo = async (topicId: string) => {
    const data = await db.collectionGroup(HELP_TOPICS_COLLECTION)
        .where("id", "==", topicId)
        .limit(1)
        .get();

    if (!data.size) {
        return Promise.reject("Topic not found");
    }

    return data.docs[0].data() as HelpTopic;
}

const getArticleBasicInfo = async (articleId: string) => {
    const data = await db.collectionGroup(HELP_ARTICLES_COLLECTION)
        .where("id", "==", articleId)
        .limit(1)
        .get();
    if (!data.size) {
        return Promise.reject("Article not found");
    }

    return data.docs[0].data() as HelpArticle;
}

/** Get the article content md only */
export const getArticleContent = async (articleID: string, locale: string) => {
    return functions.httpsCallable(HTTP_ARTICLE_CONTENT_HELP)({
        articleID,
        // articleID: '4ammSwaYp5ZtZVRGfImv',//this is the only one with markdown file data
        locale,
    })
        .then((res) => {
            return res.data as string;
        });
}

/** Get the help article information and content md */
export const getArticleInfo = async (articleId: string, locale?: string) => {
    const [article, content] = await Promise.all([
        getArticleBasicInfo(articleId),
        getArticleContent(articleId, locale || 'en')
    ]);

    return {
        ...article,
        content
    } as HelpArticle & { content: string };
}

export const getTopArticles = async (topicId?: string, variables?: QueryVariables): Promise<(HelpArticle & HelpSubArticle)[]> => {
    let data;
    if (!topicId) {
        //search for all articles
        data = await db.collectionGroup(HELP_ARTICLES_COLLECTION)
            .orderBy(HELP_ARTICLE_FEEDBACK_FIELD, 'desc')
            .limit(6)
            .get();
    } else {
        //search specifically under a certain topicId
        const topicRef = await getTopicRef(topicId);
        if (!topicRef) {
            // return Promise.reject("Topic not found");
            //try again without topicId
            return getTopArticles();
        }

        data = await topicRef.collection(HELP_ARTICLES_COLLECTION)
            .orderBy(HELP_ARTICLE_FEEDBACK_FIELD, 'desc')
            .limit(6)
            .get();
    }

    if (!data.size) {
        return [];
    }
    return data.docs.map(doc => ({
        ...(doc.data() as HelpArticle),
        article_id: doc.id,
        article_ref: doc.ref,
    } as HelpArticle & HelpSubArticle));

}
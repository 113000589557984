import React, { useCallback, useMemo, useState } from "react";
import firebase from "firebase/app";
import { AuthContent, EAuthType, IAuthContentProps } from "../../components/AuthContent";
import { auth, functions, rtdb } from "../../services/firebase";
import { ForgotPWDialog } from "../../dialog/ForgotPWDialog";
import { EUserVerification, USERS_CART_ROOT } from "../../types/db";
import { ELinkedAccount, getUIVerificationFrom } from "../../utilities/EnumUtility";
import { ErrorDialog } from "../../dialog/ErrorDialog";
import { sendSupportTicket } from "../../services/support";

interface IAuthContainerProps {
    /** The type of auth we are looking to do */
    authType?: EAuthType;
    /** Navigate to another auth page */
    redirectToAuthType: (authType: EAuthType) => void;
    /** The redirection navigation after the auth */
    redirectTo: (path?: string) => void;
}

const mergeFromAnonymous = async (authFunc: Promise<firebase.auth.UserCredential>) => {
    const prevUser = auth.currentUser;
    if (!prevUser || !prevUser.isAnonymous) return authFunc;
    const prevData = (await rtdb.ref()
        .child(USERS_CART_ROOT)
        .child(prevUser.uid)
        .get()).val();
    //delete current data while we are on this user (access permission)
    await rtdb.ref()
        .child(USERS_CART_ROOT)
        .child(prevUser.uid)
        .remove()

    //delete anonymous user
    return prevUser.delete()
        .then(() => authFunc.catch(() => {
            //if error here recreate an anonymous user and restore data
            console.error('error while tying to connect with provider');
            return auth.signInAnonymously();
        }))
        .then(async (user) => {
            console.log('transfer data to user', prevData, user.user, auth.currentUser)
            if (!!prevData) {
                //loop for each store as the update doesn't work for deep update
                await Promise.all(Object.keys(prevData).map(store =>
                    rtdb.ref()
                        .child(USERS_CART_ROOT)
                        .child(user.user?.uid || auth.currentUser?.uid || 'temp_user')
                        .child(store)
                        .update(prevData[store])
                ))
            }
            return user;
        })
        .catch(() => Promise.reject())
}

export const AuthContainer = ({ authType, redirectToAuthType, redirectTo }: IAuthContainerProps) => {
    const [error, setError] = useState('');
    const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] = useState(false);

    const handleError = useCallback((error: any) => {
        console.error(error);
        if ('message' in error) {
            setError(error.message)
        } else {
            alert(error);//TODO have a more UI friendly dialog
        }
    }, []);

    const handleEmail = useCallback(({ email, password }: { email: string, password: string }) => {
        switch (authType) {
            case EAuthType.SIGN_UP:
                mergeFromAnonymous(auth
                    .createUserWithEmailAndPassword(
                        email,
                        password
                    ))
                    .then(() => redirectTo())
                    .catch(handleError);
                break;
            case EAuthType.LOG_IN:
                mergeFromAnonymous(auth
                    .signInWithEmailAndPassword(
                        email,
                        password
                    ))
                    .then(() => redirectTo())
                    .catch(handleError);
                break;
            case EAuthType.LINK:
                auth.currentUser
                    ?.linkWithCredential(firebase.auth.EmailAuthProvider.credential(
                        email,
                        password))
                    .then((linked) => {
                        console.log('linked with email credential', linked);
                        functions.httpsCallable("updateProfileInformation")({
                            refreshVerifications: true, uid: linked.user?.uid
                        }).catch(e => console.log('did not update verification', e))
                    })
                    .then(() => redirectTo())
                    .catch(handleError);
        }
    }, [authType, handleError, redirectTo]);

    const handleProvider = useCallback((provider: firebase.auth.AuthProvider) => async () => {
        if (authType === EAuthType.LINK && !!auth.currentUser) {
            mergeFromAnonymous(auth.currentUser.isAnonymous ?
                auth.signInWithPopup(provider)//if anonymous, proceed with signIn method
                : auth.currentUser.linkWithPopup(provider)//link only if non-anonymous
            )
                .then((linked) => {
                    console.log('linked with provider', linked);
                    functions.httpsCallable("updateProfileInformation")({
                        refreshVerifications: true, uid: linked.user?.uid
                    }).catch(e => console.log('did not update verification', e))
                })
                .then(() => redirectTo())
                .catch(async (e) => {
                    if (e.code === 'auth/credential-already-in-use') {
                        //TODO linking 2 full accounts
                        sendSupportTicket({
                            type: 'report',
                            error_message: 'auth/credential-already-in-use',
                            feature: 'auth',
                            user_sender: (auth.currentUser?.toJSON().toString() ?? e) || '',
                            extra: {
                                currentUser: auth.currentUser,
                                error: e,
                            }
                        })
                    }
                    handleError(e)
                });
        } else {
            mergeFromAnonymous(auth
                .signInWithPopup(provider))
                .then(() => redirectTo())
                .catch(handleError);
        }
    }, [authType, handleError, redirectTo]);

    const handleAnonymousLogIn = useCallback(() => {
        auth.signInAnonymously().then(() => redirectTo()).catch(handleError);
    }, [handleError, redirectTo]);

    const authProviders = useMemo(() => {
        const providers: IAuthContentProps["authProviders"] = [];
        providers.push({
            type: ELinkedAccount.EMAIL,
            onClick: (args) => args && handleEmail(args),
        })
        Object.values(EUserVerification).forEach(uv => {
            const provider = getAuthProviderFrom(uv);
            if (!provider) return;
            providers.push({
                type: getUIVerificationFrom(uv),
                onClick: handleProvider(provider)
            });
        })
        if (authType !== EAuthType.LINK) {
            providers.push({
                type: ELinkedAccount.OTHER,
                onClick: handleAnonymousLogIn
            })
        }
        return providers;
    }, [authType, handleAnonymousLogIn, handleEmail, handleProvider])

    return <>
        <AuthContent
            authType={authType}
            onAuthTypeChange={redirectToAuthType}
            authProviders={authProviders}
            onForgotPasswordClick={() => setForgotPasswordDialogOpen(true)} />
        <ForgotPWDialog
            open={forgotPasswordDialogOpen}
            onClose={() => setForgotPasswordDialogOpen(false)}
            onActionClick={(email) => {
                firebase
                    .auth()
                    .sendPasswordResetEmail(email)
                    .then(() => setForgotPasswordDialogOpen(false))
                    .catch(() => alert("Error resetting password"));
            }}
        />
        <ErrorDialog open={!!error} onClose={() => setError('')} content={error} />
    </>
}

export const getAuthProviderFrom = (uv: EUserVerification) => {
    switch (uv) {
        case EUserVerification.GOOGLE:
            return new firebase.auth.GoogleAuthProvider();
        case EUserVerification.EMAIL:
            return new firebase.auth.EmailAuthProvider();
        default:
            return null;
    }
}

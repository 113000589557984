import React, { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Stack, Typography, Tab, Divider, Skeleton } from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { PropsWithLoadingState } from '../../../utilities';

enum ETabValue {
    AS_HOST = "ah",
    AS_COHOST = "ac",
    AS_GUEST = "ag"
}
enum ESubTabValue {
    FROM_HOST = "fh",
    FROM_COHOST = "fc",
    FROM_GUEST = "fg"
}

interface SubTabProps {
    /**
     * Total number of reviews for the sub tab
     */
    numberReviews: number;
    /**
     * Function to render the content of the sub tab
     */
    renderList?: () => JSX.Element;
}

interface TabProps {
    /**
     * Total number of review for the tab
     * @optional (if not provided will be calculate for the props : fromHost, fromCoHost and fromGuest)
     */
    numberReviews?: number;
    /**
     * The user reviews tab information received from Host
     */
    fromHost?: SubTabProps;
    /**
     * The user reviews tab information received from CoHost
     */
    fromCohost?: SubTabProps;
    /**
     * The user reviews tab information received from Guest
     */
    fromGuest?: SubTabProps;
}

export interface IProfileReviewSectionProps {
    profileDisplayName: string;
    /**
    * The user reviews tab information received as Host
    */
    asHost?: Omit<TabProps, 'fromHost'>,
    /**
    * The user reviews tab information received as CoHost
    */
    asCohost?: Omit<TabProps, 'fromCohost'>,
    /**
     * The user reviews tab information received as Guest
     */
    asGuest?: Omit<TabProps, 'fromGuest'>,

}

const renderTotalReview = (t: TFunction, totalReviews: number) => {
    return (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <StarRateIcon />
            <Typography>{t('user.profile.reviewCountTitle', { count: totalReviews })}</Typography>
        </Stack>
    );
}


const renderTab = ({ tab, value, t, stringKey }: { tab: TabProps | undefined, t: TFunction, stringKey: string, value: ETabValue }) => {
    if (!tab) {
        //if no tab info -> not rendering it
        return null;
    }

    return <Tab sx={{ typography: 'body1' }} label={t(stringKey, { count: calculateTabReviewCount(tab) })} value={value} />;
}

const renderSubTab = ({ tab, value, t, stringKey }: { tab: SubTabProps | undefined, t: TFunction, stringKey: string, value: ESubTabValue }) => {
    if (!tab) {
        //if no tab info -> not rendering it
        return null;
    }
    return <Tab sx={{ typography: 'body2' }} label={t(stringKey, { count: tab.numberReviews })} value={value} />;
}

const renderTabPanel = ({ tab, to, t, value, subValue, handleSubValueChange }: { tab: TabProps | undefined, to: string, t: TFunction, value: ETabValue, subValue: ESubTabValue, handleSubValueChange: (ev: any, val: ESubTabValue) => void }) => {
    if (!tab) {
        //if no tab info nor review -> not rendering it
        return null;
    }

    return (
        <TabPanel value={value}>
            <TabContext value={subValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList sx={{ mt: -2 }} onChange={handleSubValueChange}>
                        {renderSubTab({ tab: tab.fromHost, t, stringKey: 'user.profile.reviewFromHostTitle', value: ESubTabValue.FROM_HOST })}
                        {renderSubTab({ tab: tab.fromCohost, t, stringKey: 'user.profile.reviewFromCohostTitle', value: ESubTabValue.FROM_COHOST })}
                        {renderSubTab({ tab: tab.fromGuest, t, stringKey: 'user.profile.reviewFromGuestTitle', value: ESubTabValue.FROM_GUEST })}
                    </TabList>
                </Box>
                {renderSubTabPanel({ tab: tab.fromHost, value: ESubTabValue.FROM_HOST, t, options: { from: t('userType.host'), to } })}
                {renderSubTabPanel({ tab: tab.fromCohost, value: ESubTabValue.FROM_COHOST, t, options: { from: t('userType.cohost'), to } })}
                {renderSubTabPanel({ tab: tab.fromGuest, value: ESubTabValue.FROM_GUEST, t, options: { from: t('userType.guest'), to } })}
            </TabContext>
        </TabPanel>
    );
}

const renderSubTabPanel = ({ tab, t, options, value }: { tab: SubTabProps | undefined, t: TFunction, options: { from: string, to: string }, value: ESubTabValue }) => {
    if (!tab) {
        return null;
    }
    return (
        <TabPanel value={value}>
            {
                tab.numberReviews &&
                (tab.renderList?.() ||
                    <Typography>{t('user.profile.reviewEmptyTabContent', options)}</Typography>)
            }
        </TabPanel>
    );
}

const calculateTabReviewCount = ({ fromHost, fromCohost, fromGuest }: Pick<TabProps, 'fromHost' | 'fromCohost' | 'fromGuest'>) => {
    return (fromHost?.numberReviews ?? 0) + (fromCohost?.numberReviews ?? 0) + (fromGuest?.numberReviews ?? 0);
}

const calculateTotReviewCount = ({ asHost, asCohost, asGuest }: Pick<IProfileReviewSectionProps, 'asHost' | 'asCohost' | 'asGuest'>) => {

    return (asHost?.numberReviews ?? calculateTabReviewCount({ ...asHost }))
        + (asCohost?.numberReviews ?? calculateTabReviewCount({ ...asCohost }))
        + (asGuest?.numberReviews ?? calculateTabReviewCount({ ...asGuest }))
}

const getInitTabValue = ({ asHost, asCohost, asGuest }: Pick<IProfileReviewSectionProps, 'asHost' | 'asCohost' | 'asGuest'>): ETabValue => {
    if (!!asHost) {
        return ETabValue.AS_HOST;
    }
    if (!!asCohost) {
        return ETabValue.AS_COHOST;
    }
    if (!!asGuest) {
        return ETabValue.AS_GUEST;
    }
    return ETabValue.AS_HOST;
}

const getPropsForValue = ({ asHost, asCohost, asGuest }: Pick<IProfileReviewSectionProps, 'asHost' | 'asCohost' | 'asGuest'>, value: ETabValue): TabProps | undefined => {
    switch (value) {
        case ETabValue.AS_HOST:
            return asHost;
        case ETabValue.AS_COHOST:
            return asCohost;
        case ETabValue.AS_GUEST:
            return asGuest;
        default:
            return;
    }
}

const getInitSubTabValue = ({ fromHost, fromCohost, fromGuest }: Pick<TabProps, 'fromHost' | 'fromCohost' | 'fromGuest'>): ESubTabValue => {
    if (!!fromHost) {
        return ESubTabValue.FROM_HOST;
    }
    if (!!fromCohost) {
        return ESubTabValue.FROM_COHOST;
    }
    if (!!fromGuest) {
        return ESubTabValue.FROM_GUEST;
    }
    return ESubTabValue.FROM_HOST;
}

export const ProfileReviewSection = ({ profileDisplayName, asHost, asCohost, asGuest, loading }: PropsWithLoadingState<IProfileReviewSectionProps>) => {
    const { t } = useTranslation();
    const [tabValue, setTabValue] = useState<ETabValue>(getInitTabValue({ asHost, asCohost, asGuest }));
    const [subTabValue, setSubTabValue] = useState<ESubTabValue>(getInitSubTabValue({}));

    useEffect(() => {
        setSubTabValue(getInitSubTabValue({ ...getPropsForValue({ asHost, asCohost, asGuest }, tabValue) }))
    }, [asCohost, asGuest, asHost, tabValue])

    if (!!loading) {
        return (
            <Stack direction={'column'}>
                <Skeleton variant={'text'} />
                <Skeleton variant={'rectangular'} height={50} />
            </Stack>
        )
    }

    const totalReviews = calculateTotReviewCount({ asHost, asCohost, asGuest });

    if (!totalReviews) {
        //if not review at all
        return (
            <Stack direction={'column'} spacing={2}>
                {renderTotalReview(t, totalReviews)}
                <Typography>{t('user.profile.reviewEmptyContent')}</Typography>
            </Stack>
        );
    }

    const handleTabChange = (_: React.SyntheticEvent, newValue: ETabValue) => {
        setTabValue(newValue);
    };

    const handleSubTabChange = (_: React.SyntheticEvent, newValue: ESubTabValue) => {
        setSubTabValue(newValue);
    };


    return (
        <Box sx={{ width: '100%' }}>
            <Divider>{t('user.profile.reviewsSectionTitle', { name: profileDisplayName })}</Divider>
            {renderTotalReview(t, totalReviews)}
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange}>
                        {renderTab({ tab: asHost, t, stringKey: 'user.profile.reviewToHostTitle', value: ETabValue.AS_HOST })}
                        {renderTab({ tab: asCohost, t, stringKey: 'user.profile.reviewToCohostTitle', value: ETabValue.AS_COHOST })}
                        {renderTab({ tab: asGuest, t, stringKey: 'user.profile.reviewToGuestTitle', value: ETabValue.AS_GUEST })}
                    </TabList>
                </Box>
                {renderTabPanel({ tab: asHost, to: t('userType.host'), t, value: ETabValue.AS_HOST, subValue: subTabValue, handleSubValueChange: handleSubTabChange })}
                {renderTabPanel({ tab: asCohost, to: t('userType.cohost'), t, value: ETabValue.AS_COHOST, subValue: subTabValue, handleSubValueChange: handleSubTabChange })}
                {renderTabPanel({ tab: asGuest, to: t('userType.guest'), t, value: ETabValue.AS_GUEST, subValue: subTabValue, handleSubValueChange: handleSubTabChange })}
            </TabContext>
        </Box>
    );
}

import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { SxProps } from '@mui/system';
import { AppBar, Button, Box, Menu, MenuItem, Container, IconButton, Toolbar, Tooltip, Typography, Theme } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { ROUTE_PATH, buildPath } from '../../utilities/navigation';
import { LOGO_WHITE_BOLD } from '../../utilities/assets/assetHelper';

export const ChoNewsAppBar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

    const menuOptions =
        [
            {
                label: t('news.menu.about'),
                action: () => navigate(buildPath(ROUTE_PATH.news.about))
            },
            {
                label: t('news.menu.mediaAssets'),
                action: () => navigate(buildPath(ROUTE_PATH.news.mediaAsset))
            },
            {
                label: t('news.menu.productRelease'),
                action: () => navigate(buildPath(ROUTE_PATH.news.productRelease))
            },
            {
                label: t('news.menu.contact'),
                action: () => navigate(buildPath(ROUTE_PATH.news.contact)),
            }
        ];

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const styles = {
        logoText: {
            mr: 2,
            ml: 2,
            mt: 1,
            color: 'inherit',
            fontFamily: 'PartyLetPlain',
            textDecoration: 'none',
            fontWeight: 500,
        },
        logoIcon: {
            objectFit: 'contain',
            height: '42px',
        },
    };

    const renderAppIcon = (style: SxProps<Theme>) => {
        return <Box sx={style}>
            <Link to={ROUTE_PATH.home}>
                <img
                    style={styles.logoIcon as React.CSSProperties}
                    src={LOGO_WHITE_BOLD}
                    alt="CoHostOp logo"
                />
            </Link>
        </Box>
    }

    return (
        <AppBar position="sticky">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {renderAppIcon({ display: 'flex' })}
                    <Typography
                        variant="h4"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            display: 'flex',
                            ...styles.logoText,
                        }}
                    >
                        {t('appName')}
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {menuOptions.map((page) => (
                            <Button
                                key={page.label}
                                onClick={() => { handleCloseNavMenu(); page.action() }}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page.label}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} />
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                        {!!menuOptions.length &&
                            <>
                                <Tooltip title={t('home.menu.open')}>
                                    <IconButton
                                        size="large"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {menuOptions.map((page) => (
                                        <MenuItem key={page.label} onClick={() => { handleCloseNavMenu(); page.action() }}>
                                            <Typography textAlign="center">{page.label}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        }
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Typography } from '@mui/material';
import { WarningAmber } from '@mui/icons-material';
import { ListingCreationFlowContext } from '../../../context/Listing/ListingCreationFlow';
import { ListingTree, ListingType } from '../../../types/db';
import { ListingTypeCard } from '../../../components/listing/ListingTypeCard';
import { useListingTypeEffect, useListingTypeOnChangeCallback } from '../../../utilities/listings';

/** The listing type (building/house/campground) page of the listing creation flow */
export const ListingTypePage = () => {
    return <Container />;
}

/** The container looking for Type info from the creation flow context */
const Container = () => {
    const listingCreationFlowContext = useContext(ListingCreationFlowContext);
    if (!listingCreationFlowContext) {
        throw new Error('need ListingCreationFlowContext');
    }
    const { t } = useTranslation();

    // in the Type info, only interested by the listing type and the parent type (present in the breadcrumbs)
    const { listing: { type, breadcrumbs }, setActivePageInfo } = listingCreationFlowContext;

    const parentType: ListingType | undefined = useMemo(() =>
        breadcrumbs?.length ? (breadcrumbs[breadcrumbs.length - 1]?.type || 'multi-mix') : undefined,
        [breadcrumbs]);

    useListingTypeEffect(parentType, type, undefined, setActivePageInfo, true);

    const handleTypeClick = useListingTypeOnChangeCallback(setActivePageInfo);

    return (
        <Grid container spacing={2} justifyContent={'center'}>
            {
                !!parentType && !ListingTree[parentType].length ?
                    //There isn't any sub-listing possible for this parent (should have been filtered out at the choice)
                    <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={2}>
                        <WarningAmber sx={{ height: '20vw', width: '20vw' }} color={'error'} />
                        <Typography textAlign={'center'}>{t('listing.type.errorParentNoSub', { parent: t('listing.type.title', { context: parentType }) })}</Typography>
                    </Stack>
                    :
                    (!parentType ?
                        //if no parent, a parent itself
                        ListingTree["multi-mix"]
                            .filter(type => type.includes("multi-"))
                        //there is a parent, display potential sub-listing for this parent
                        : ListingTree[parentType])
                        .map(itemType => <Grid key={itemType} item xs={12} sm={6} md={4}><ListingTypeCard key={itemType} type={itemType} selected={itemType === type} onClick={() => handleTypeClick(itemType)} /></Grid>)
            }
        </Grid>
    )
}

/** 
 * Function which takes care of extracting the subdomain
 * @return the subdomain or null if no subdomain detected
 */
export const extractSubdomain = () => {
    const host = window.location.host;
    //to test locally, can do http://careers.localhost:3000/ in Chrome
    const arr = host.split('.')
        .slice(0, host.includes('localhost') ? -1 : -2);
    if (!arr.length) {
        return null;
    }
    return arr[0];
}
import { IChatThreadMessageItemProps } from "./ChatThreadMessageItem.type";

/**
 * Function which trim the props in case the props isn't given properly (ex: empty array, array with only one item)
 * @param args  Pick<IChatThreadMessageItemProps, 'userRole'> from the component props
 * @returns the trimmed args
 */
export const trimUserRole = ({ userRole }: Pick<IChatThreadMessageItemProps, 'userRole'>): Pick<IChatThreadMessageItemProps, 'userRole'> => {
    if (Array.isArray(userRole)) {
        if (userRole.length === 0) {
            return { userRole: undefined };
        } else if (userRole.length === 1) {
            return { userRole: userRole[0] };
        } else {
            return { userRole };
        }
    } else {
        return { userRole };
    }
}
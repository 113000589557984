/** The types for all shop related db (firestore+realtime) objects */
/** NOTE: all change should be done in the shared_type folder 
 * and run `npm run build:shared_types` in root project */

import { UserAddress } from "./user";

/******************************************
 * SHOP DB FIRESTORE
 * |shop
 * -|stores (partner stores)
 * -|products
 * --|variants
 * -|categories
 * -|orders
 * --|orders (sub-orders)
 * --|items
 * --|shipments
 ******************************************/

/** The supported store provider */
export type SupportedStoreProvider = 'Printful'// | 'Printify';
/** The store information */
export type Store = {
    /** The provider */
    provider: SupportedStoreProvider;
    /** The id of the store within the provider database */
    id: string | number;
}
/** The store raw object */
type StoreRawObject = {
    /** The raw object from the partner store (might be needed for future calculation/info) */
    store_raw_object: any;
}
/** The root collection for the shop firebase data */
export const SHOP_COLLECTION = "shop";
/** The store information field (store specific information) */
export const SHOP_STORE_FIELD = "store";

/**
 * Type for the shop in the db
 */
export type Shop = {
    /** The store partner(s) used by this shop */
    [SHOP_STORE_FIELD]: Store[];
    /** The owner of the shop (this is for the authorization of managing it) */
    owner?: {
        id: string;
    }
    /** The name of the store */
    name: string;
    /** (Optional) The description of the store */
    description?: string;
    /** (Optional) Logo of the shop */
    logo_url?: string;
    /** (Optional) If the donation banner is showing in the shop page */
    donation_enabled?: boolean;
    /** If the shop is displayable */
    is_live?: boolean;
    /** If the shop is a temp one */
    is_temp?: boolean;
}

/** The stores sub-collection to have the setting for the main shop */
export const SHOP_STORES_COLLECTION = "stores";

/** Type for the store linked to the shop */
export type ShopStore = Store & {
    /** The token key to access the store and register the webhook */
    token: string;
}

/** The shop sub collection for the products of this store */
export const SHOP_PRODUCTS_COLLECTION = "products";

/** The Printful Synced Product id field name (designed product) */
export const SHOP_SYNC_PRODUCT_ID_FIELD = "printful_sync_product_id";
/** The Printful Product id field name (within catalog) */
export const SHOP_PRODUCT_ID_FIELD = "printful_product_id";
/**
 * Type for the product of a shop in db
 */
export type ShopProduct = StoreRawObject & {
    /** The store this product is from and the info in that DB */
    [SHOP_STORE_FIELD]: Store & ({
        /** The printful id of the synced product */
        [SHOP_SYNC_PRODUCT_ID_FIELD]: number;
        /** The printful id of the catalog product */
        [SHOP_PRODUCT_ID_FIELD]: number;
    });
    /** Type of product, physical (ie:goods) or virtual (ie:coupon) */
    type: "physical" | "virtual"
    /** The name of the product */
    name: string;
    /** The description of the product */
    description: string;
    /** The main product image (see variants for specific ones) */
    image: string;
    /** The list of images for this product */
    images: string[];
    /** To know if the product is live (displayable) */
    is_live: boolean;
    /** To know if the product is fully out of stock (in all variants) */
    is_out_of_stock: boolean;
    /** The list of size variants for this product if any */
    size_variants?: string[];
    /** The list of color variants for this product if any */
    color_variants?: ShopVariantColor[];
    /** The list of prices (could depends on variants) */
    prices: ShopPrice[];
    /** The list of categories (DB IDs) this product is part of */
    categories: string[];
    /** To know if this product is seasonal or not 
     * (allowing to make sure it's not showing outside of the date range) */
    seasonal: ShopSeason;
}

/** The shop product sub collection for the variants of this product */
export const SHOP_PRODUCT_VARIANTS_COLLECTION = "variants";

/** The Printful Sync Variant id field name (designed product) */
export const SHOP_SYNC_VARIANT_ID_FIELD = "printful_sync_variant_id";
/** The Printful Variant id field name (catalog id) */
export const SHOP_VARIANT_ID_FIELD = "printful_variant_id";
/** The Stripe Product id field name (catalog id)*/
export const SHOP_STRIPE_PRODUCT_ID_FIELD = "stripe_product_id";
/**
 * Type for the variant of a product
 */
export type ShopProductVariant = StoreRawObject & {
    /** The store information about this variant */
    [SHOP_STORE_FIELD]: Store & ({
        /** 
         * The printful ID used to create a printful order with sync variant id
         * @see https://developers.printful.com/docs/#section/Orders-API-examples/Using-a-sync-variant
         */
        [SHOP_SYNC_VARIANT_ID_FIELD]: number;
        /** The Printful catalog Variant ID (useful if need details about the variant) */
        [SHOP_VARIANT_ID_FIELD]: number;
    }) & ({
        /** The Stripe product id to create a checkout order. If this is not present, will build it from rest of the basic information */
        [SHOP_STRIPE_PRODUCT_ID_FIELD]?: string;
    });
    /** Name of the variant */
    name: string;
    /** Description of the variant */
    description: string;
    /** Main image of the variant */
    image: string;
    /** Images of the variant */
    images: string[];
    /** Size string representation of the variant */
    size?: string;
    /** Main color information of this variant, could be optional if no color variant */
    color?: ShopVariantColor;
    /** Optional secondary color */
    color2?: ShopVariantColor;
    /** The price of the product for this variant */
    price: ShopPrice;
    /** To know if the variant is live (available) */
    is_live: boolean;
    /** To know if the variant is out of stock */
    is_out_of_stock: boolean;
}

/** The shop sub collection for the categories in this shop */
export const SHOP_CATEGORIES_COLLECTION = "categories";

/** The Printful Category id field name */
export const SHOP_CATEGORY_ID_FIELD = "printful_category_id";
/**
 * Type of the category in a shop
 */
export type ShopCategory = StoreRawObject & {
    /** The store information about this category */
    [SHOP_STORE_FIELD]: (Store & ({
        /** The Printful category ID */
        [SHOP_CATEGORY_ID_FIELD]: number;
    }));
    /** The name of the category */
    name: string;
    /** The image url of the category */
    image?: string;
    /** To know if this category is seasonal or not 
     * (allowing to make sure it's not showing outside of the date range) */
    seasonal: ShopSeason;
    /** The parent category DB id if exist, '' if no parent */
    parent_id: string;
}

/** The shop sub collection for the list of orders this shop generated */
export const SHOP_ORDERS_COLLECTION = "orders";
/** The supported payment provider */
type SupportedPaymentProvider = 'stripe'//|'worldpay'
/** The supported payment method */
type SupportedPaymentMethod = 'credit_card';//|'debit_card'|'digital_wallet'

/** The Printful Order ID field name */
export const SHOP_ORDER_ID_FIELD = "printful_order_id";
/** The Stripe payment ID field name */
export const SHOP_STRIPE_PAYMENT_ID_FIELD = "stripe_payment_id";
/**
 * Type of an order in a shop
 */
export type ShopOrder = {
    /** The Store(s) used for this order (could be multiple if items from different stores) */
    [SHOP_STORE_FIELD]: (Store & ({
        /** The Printful order id */
        [SHOP_ORDER_ID_FIELD]: number;
    }))[];
    /** The payment used for this order */
    payment: ({
        /** The payment provider used for this order */
        payment_provider: SupportedPaymentProvider;
        /** The payment method used for this order */
        payment_method: SupportedPaymentMethod;
    } & ({
        /** The Stripe payment id linked to this order */
        [SHOP_STRIPE_PAYMENT_ID_FIELD]?: string;
    }));
    /** The status of the order */
    status: ShopOrderStatus;
    /** Time when the order was created */
    created: number;//Timestamp
    /** Time when the order was updated */
    updated: number;//Timestamp
    /** The customer and recipient of the order */
    customer: {
        /** The customer id in the db */
        id: string;
        /** The customer's name */
        name: string;
        /** The customer's email for this order */
        email: string;
        /** The customer's shipping address for this order */
        shipping_address: UserAddress;
        /** The customer's billing address for this order */
        billing_address: UserAddress;
    };
    /** The shipping basic info (shipping chosen for this order) */
    shipping: {
        /** The id of the shipping type (from Printful, could be 'STANDARD') */
        id: string;
        /** The display name of the shipping method */
        name: string;
        /** (Optional) Gift message to add to the shipping slip for this order */
        gift?: {
            /** (Optional) Subject of the message */
            subject?: string;
            /** Text of the message */
            message: string;
        };
    };
    /** The detailed price of the order */
    retail_price: {
        /** The currency (3-letter code) */
        currency: string;
        /** The sub-total of this order */
        subtotal: number;
        /** The discount applied on this order */
        discount: number;
        /** The shipping cost */
        shipping: number;
        /** The taxes */
        taxes: {
            /** The amount for this tax */
            amount: number;
            /** The percentage (ex:VAT/GST/PST) */
            percentage?: number;
            /** The name of the tax (could help for display) */
            name: string;
        }[];
        /** The total retail cost of this order */
        total: number;
    };
    /** Number of items in the order (details would be in the sub-collection) */
    item_count: number;
}

/** The order sub collection for the list of orders per suppliers */
export const SHOP_SUBORDERS_COLLECTION = SHOP_ORDERS_COLLECTION;

/** Summary of an order specific to a partner */
export type ShopOrderSubOrder =
    Pick<ShopOrder, 'status' | 'retail_price' | 'item_count'>
    & StoreRawObject
    & {
        /** The Store used for this specific sub-order */
        [SHOP_STORE_FIELD]: (Store & ({
            /** The Printful order id */
            [SHOP_ORDER_ID_FIELD]: number;
        }));
        /** The real cost of the order (will allow to see the profile between retail price and real cost) */
        costs: {
            /** The currency (3-letter code) */
            currency: string;
            /** The sub-total of this order */
            subtotal: number;
            /** The discount applied on this order */
            discount: number;
            /** The shipping cost */
            shipping: number;
            /** The taxes */
            taxes: {
                /** The amount for this tax */
                amount: number;
                /** The percentage (ex:VAT/GST/PST/..) */
                percentage?: number;
                /** The name of the tax (could help for display) */
                name: string;
            }[];
            /** The potential additional fees (fulfillment/digitalization/..) */
            additional_fees: {
                /** The amount for this fee */
                amount: number;
                /** The percentage */
                percentage?: number;
                /** The name of the fee (could help for display) */
                name: string;
            }[];
            /** The total cost of this order */
            total: number;
        }
    };

/** The shop sub collection for the list of items in an order */
export const SHOP_ORDERS_ITEMS_COLLECTION = "items";
/** The Printful order line id field */
export const SHOP_LINE_ID_FIELD = "printful_order_line_id";
/**
 * Type of an item in an order
 */
export type ShopOrderItem = {
    /** The store info this item is from */
    [SHOP_STORE_FIELD]: Store & ({
        /** The Printful sync variant id */
        [SHOP_SYNC_VARIANT_ID_FIELD]: number;
        /** The Printful Order Line ID */
        [SHOP_LINE_ID_FIELD]?: number;
    });
    /** The db product id */
    product_id: string;
    /** The db variant id */
    variant_id: string;
    /** The display name of the product */
    name: string;
    /** The thumbnail image of the product */
    image: string;
    /** The retail price of the product when bought during this order */
    purchase_price: ShopPrice;
    /** The quantity of this item in this order */
    quantity: number;
}

/** The shop sub collection for the list of shipments in an order */
export const SHOP_ORDERS_SHIPMENTS_COLLECTION = "shipments";
/** The Printful shipment id */
export const SHOP_ORDERS_SHIPMENT_ID_FIELD = 'printful_shipment_id';

/**
 * Type of a shipment in an order
 */
export type ShopOrderShipment = StoreRawObject & {
    /** The store info this shipment is from */
    [SHOP_STORE_FIELD]: Store & ({
        /** The printful order id this shipment is linked to */
        [SHOP_ORDER_ID_FIELD]: number;
        /** The Printful Shipment ID (can retrace the shipment with it) */
        [SHOP_ORDERS_SHIPMENT_ID_FIELD]: number;
    });
    /** The status of the shipment */
    status: ShopOrderShipmentStatus;
    /** 
     * The carrier's name 
     * @example "FEDEX"
     */
    carrier: string;
    /** 
     * The service's name
     * @example "FedEx SmartPost"
     */
    service: string;
    /** The shipment tracking number */
    tracking_number: string;
    /** 
     * The shipment tracking URL 
     * @example "https://www.fedex.com/fedextrack/?tracknumbers=0000000000"
     */
    tracking_url: string;
    /** The Shipping time */
    shipped_at: number | string;//Timestamp
    /** The array of items included in this shipment */
    items: ShopOrderItem[];
}

/**
 * The status of an order (Provided by Printful)
 * draft: The order is created but is not yet submitted for fulfillment. You still can edit it and confirm later.
 * pending: The order has been submitted for fulfillment, but is not yet accepted for fulfillment. You can still cancel the order if you need.
 * failed: Order was submitted for fulfillment but was returned for review because of an error (problem with address, missing print-files, charging has failed, etc.).
 * canceled: The order has been canceled and can no longer be processed. If the order was charged then the amount has been returned to your credit card.
 * in_process: The order is being fulfilled and can no longer be cancelled or modified. Contact customer support if there are any issues with the order at this point.
 * on_hold: The order has encountered a problem during the fulfillment that needs to be resolved together with Printful customer service before fulfillment can continue.
 * partial: The order is partially fulfilled (some items are shipped already, the rest will follow)
 * fulfilled: All items have been shipped successfully
 * archived: The order has been archived and hidden from the UI
 */
export type ShopOrderStatus = "draft" | "pending" | "failed" | "canceled" | "in_progress" | "on_hold" | "partial" | "fulfilled" | "archived";

/**
 * The status of a shipment
 * sent: The shipment has been sent (for the first time), by default
 * resent: Whether this is a reshipment
 * returned: Specify that this is a return shipment
 */
export type ShopOrderShipmentStatus = "sent" | "resent" | "returned";

/** The default shop season (ie: all year long) */
export const DEFAULT_SHOP_SEASON: ShopSeason = {
    start_date: 101,//0101: January 1st 
    end_date: 1231,//1231: December 31st
}

export type ShopSeason = {
    /** Season start, format MMDD in number. default 0101 (for Jan 1st) */
    start_date: number;
    /** Season end, format MMDD in number. default 1231 (for Dec 31st)  */
    end_date: number;
}

type ShopVariantColor = {
    /** The human readable color name */
    color_name: string;
    /** The hex color */
    color_hex: string;
}

type ShopPrice = {
    /** The retail price amount (number) */
    amount: number;
    /** The currency this price is in (3 letter code) */
    currency: string;
}

/******************************************
 * SHOP CART DB REALTIME
 * |userCarts
 * -|<UserUID>
 * --|<shopDB-ID>
 * ---|<variantDB-ID1>:CartItem
 * ---|<variantDB-ID2>:CartItem
 ******************************************/

/** The root name of the user cart in the DB */
export const USERS_CART_ROOT = "userCarts";

export type CartItem = {
    /** The quantity of this item in the cart */
    quantity: number;
    /** The product id (db id) of this item */
    product_id: string;
    /** The variant id (db id) of this item */
    variant_id: string;
}
/** The cart object for a specific shopId. the key is the variantId */
export type Cart = { [key: string]: CartItem }
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { GuestsFilterSelection } from '../../../components/GuestsFilterSelection';
import { ListingGuestRules, ListingGuestsRestrictionType } from '../../../types/db';
import { isGuestsRestrictionValid, useGuestsChangeCallback, useGuestsRestrictionChangeCallback } from '../../../utilities/listings';
import { ListingEditContext } from '../../../context/Listing/ListingEditFlow';
import { ListingEditSectionView } from './ListingEditSectionView';
import { GuestsSelectionDisplay } from '../../../components/GuestsFilterSelection/GuestsFilterSelection';
import { ListingGuestCalendar } from './ListingCalendarPage';
import { ListingGuestPricing } from './ListingPricingPage';
import { Cancel, Check, CheckCircle, CheckCircleOutline, Edit, HighlightOff } from '@mui/icons-material';

/** The guests page of the listing edit flow */
export const ListingGuestsPage = () => {
    return <Container />
}

const Container = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { isLoading } = listingEditContext;
    if (isLoading) {
        return <CircularProgress />
    }

    return (
        <Stack direction={'column'} spacing={2}>
            <ListingGuests />
            <Divider />
            <ListingGuestPricing />
            <Divider />
            <ListingGuestCalendar />
            <Divider />
            <ListingGuestRulesContainer />
        </Stack>
    );
}
const ListingGuests = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { t } = useTranslation();

    const { listing: { guests_restriction }, setActivePageInfo, handleCancel, handleSave, completed } = listingEditContext;

    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (!editing) return;// no need to check if not editing
        if (isGuestsRestrictionValid(guests_restriction)) {
            //allow to go farther in the process
            setActivePageInfo({}, true)
        } else {
            //disallow going farther
            setActivePageInfo({}, false)
        }
    }, [editing, guests_restriction, setActivePageInfo]);

    const handleGuestsRestrictionChange
        = useGuestsRestrictionChangeCallback(setActivePageInfo)

    const handleGuestsChange
        = useGuestsChangeCallback(setActivePageInfo)

    return (
        <ListingEditSectionView title={t('listing.edit.guestsRestrictionTitle')}
            isEditMode={editing}
            onEditClick={() => setEditing(true)}
            onCancelClick={() => { handleCancel(); setEditing(false) }}
            onSaveClick={() => {
                if (!completed) return;
                handleSave().then(() => setEditing(false))
            }}
            isSaveDisabled={!completed}>
            {
                !editing ?
                    <GuestsSelectionDisplay
                        adults={guests_restriction?.max_adults}
                        children={guests_restriction?.max_children}
                        infants={guests_restriction?.max_infants}
                        pets={guests_restriction?.max_pets}
                    />
                    :
                    <Stack direction={'column'}
                        justifyContent={'space-evenly'}
                        alignItems={'stretch'}
                        spacing={2}>
                        <FormControl>
                            <FormLabel>{t('listing.creation.guestsRestrictionLabel')}</FormLabel>
                            <RadioGroup
                                value={guests_restriction?.type || ''}
                                onChange={handleGuestsRestrictionChange}
                            >
                                <FormControlLabel value={'none' as ListingGuestsRestrictionType}
                                    control={<Radio />}
                                    label={t('listing.creation.guestsRestrictionChoice', { context: 'none' })} />
                                <FormControlLabel value={'sum-sub-listing' as ListingGuestsRestrictionType}
                                    control={<Radio />}
                                    label={t('listing.creation.guestsRestrictionChoice', { context: 'sum' })} />
                                <FormControlLabel value={'custom' as ListingGuestsRestrictionType}
                                    control={<Radio />}
                                    label={t('listing.creation.guestsRestrictionChoice', { context: 'custom' })} />
                            </RadioGroup>
                        </FormControl>
                        {guests_restriction?.type === 'custom' &&
                            <GuestsFilterSelection
                                adults={{
                                    value: guests_restriction?.max_adults || 1,
                                    onChange: (value) => handleGuestsChange('max_adults', value)
                                }}
                                children={{
                                    value: guests_restriction?.max_children || 0,
                                    onChange: (value) => handleGuestsChange('max_children', value)
                                }}
                                infants={{
                                    value: guests_restriction?.max_infants || 0,
                                    onChange: (value) => handleGuestsChange('max_infants', value)
                                }}
                                pets={{
                                    value: guests_restriction?.max_pets || 0,
                                    onChange: (value) => handleGuestsChange('max_pets', value)
                                }}
                            />}
                    </Stack>
            }
        </ListingEditSectionView>
    )
}

const ListingGuestRulesContainer = () => {
    const listingEditContext = useContext(ListingEditContext);
    if (!listingEditContext) {
        throw new Error('Need ListingEditContext');
    }
    const { t } = useTranslation();

    const { listing: { guest_rules }, setActivePageInfo, handleSave } = listingEditContext;
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleRuleChange = (fieldName: keyof ListingGuestRules, value: boolean | string) => {
        setActivePageInfo((listing) => {
            const newValue = {
                ...listing,
                guest_rules: {
                    ...listing.guest_rules,
                    [fieldName]: value,
                }
            };

            handleSave(newValue).then(() => setSnackbarOpen(true));
            return newValue;
        });
    }

    return (
        <>
            <ListingEditSectionView title={t('listing.rules.guestsRulesTitle')}>
                <ListingRules
                    lines={[
                        {
                            title: t('listing.rules.petsTitle'),
                            subtitle: t('listing.rules.petsSubtitle'),
                            allowed: guest_rules?.pets,
                            onAllowedChange: (allowed) => handleRuleChange('pets', allowed)
                        },
                        {
                            title: t('listing.rules.smokingTitle'),
                            allowed: guest_rules?.smoking,
                            onAllowedChange: (allowed) => handleRuleChange('smoking', allowed)
                        },
                        {
                            title: t('listing.rules.eventsTitle'),
                            allowed: guest_rules?.events,
                            onAllowedChange: (allowed) => handleRuleChange('events', allowed)
                        },
                        {
                            title: t('listing.rules.imageryTitle'),
                            allowed: guest_rules?.imagery,
                            onAllowedChange: (allowed) => handleRuleChange('imagery', allowed)
                        }
                    ]}
                    extra={{
                        value: guest_rules?.extra || '',
                        onValueChange: (value) => handleRuleChange('extra', value)
                    }}
                />
            </ListingEditSectionView>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    severity="success"
                    variant="filled"
                >
                    {t('saved')}
                </Alert>
            </Snackbar>
        </>);
}

interface IListingRulesProps {
    lines: IListingRuleLineProps[];
    extra: {
        value: string;
        onValueChange: (value: string) => void;
    }
}
const ListingRules = ({ lines, extra }: IListingRulesProps) => {
    const { t } = useTranslation();
    const [extraText, setExtraText] = useState(extra.value || '');
    const [editing, setEditing] = useState(false);
    return (
        <Stack direction={'column'} alignItems={'stretch'} spacing={2}>
            {
                lines.map((line, index) => <ListingRuleLine key={`rule-line-${index}`} {...line} />)
            }
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography>{t('listing.rules.extraTitle')}</Typography>
                {!editing ?
                    <IconButton onClick={() => setEditing(true)}>
                        <Edit />
                    </IconButton>
                    : <Stack direction={'row'}>
                        <IconButton onClick={() => { setEditing(false); setExtraText(extra.value) }}>
                            <Cancel />
                        </IconButton>
                        <IconButton onClick={() => { setEditing(false); extra.onValueChange(extraText) }}>
                            <Check />
                        </IconButton>
                    </Stack>
                }
            </Stack>
            <TextField
                value={extraText}
                onChange={(ev) => setExtraText(ev.target.value)}
                disabled={!editing}
                multiline={true}
            />
        </Stack>
    )
}

interface IListingRuleLineProps {
    title: string;
    subtitle?: string;
    allowed?: boolean;
    onAllowedChange: (allowed: boolean) => void;
}
const ListingRuleLine = ({ title, subtitle, allowed, onAllowedChange }: IListingRuleLineProps) => {
    return (
        <Stack direction={'row'}>
            <Stack direction={'column'} flex={1}>
                <Typography>{title}</Typography>
                {!!subtitle && <Typography variant={'caption'}>{subtitle}</Typography>}
            </Stack>
            <IconButton onClick={() => onAllowedChange(false)}>{allowed === false ? <Cancel /> : <HighlightOff />}</IconButton>
            <IconButton onClick={() => onAllowedChange(true)}>{allowed === true ? <CheckCircle /> : <CheckCircleOutline />}</IconButton>
        </Stack>
    );
}
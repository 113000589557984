import { Box, Collapse, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FINANCE_MIN_YEAR, useFinanceInformation } from '../../hooks/useFinanceInformation';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function createData(
    name: string,
    datas: {
        label: string;
        amount: number;
    }[]
) {
    const totalAmount = datas.reduce((prev, cur) => prev + cur.amount, 0);
    return {
        name,
        totalAmount,
        details: datas.map((data) => { return { label: data.label, amount: data.amount, percentage: data.amount * 100 / totalAmount } })
    };
}

function Row(props: { row: ReturnType<typeof createData> }) {
    const { row } = props;
    const [open, setOpen] = React.useState(true);

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell component="th" align="right">
                    ${row.totalAmount.toFixed(2)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Details
                            </Typography>
                            <Table size="small" aria-label="details">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Label</TableCell>
                                        <TableCell align="right">Amount ($)</TableCell>
                                        <TableCell align="right">Percentage (%)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.details.map((detailRow) => (
                                        <TableRow key={detailRow.label}>
                                            <TableCell component="th" scope="row">
                                                {detailRow.label}
                                            </TableCell>
                                            <TableCell align="right">{detailRow.amount.toFixed(2)}</TableCell>
                                            <TableCell align="right">
                                                {detailRow.percentage.toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}


export const FinanceReportPage = () => {
    const [year, setYear] = useState(FINANCE_MIN_YEAR);
    const { incomeSources, expenseSources, summary } = useFinanceInformation(year);

    const handleYearChange = (event: React.ChangeEvent<{ value: string }>) => {
        setYear(Number.parseInt(event.target.value));
    };


    return <div className="FinanceReportPage" style={{ paddingLeft: '20vw', paddingRight: '20vw' }}>
        <h2>Financial Report</h2>
        <TextField id="select" label="Year" value={`${year}`} select onChange={handleYearChange}>
            {Array(new Date().getFullYear() - FINANCE_MIN_YEAR + 1).fill(0).map((_, idx) => FINANCE_MIN_YEAR + idx)
                .map((yearItem) => <MenuItem value={`${yearItem}`}>{yearItem}</MenuItem>)}
        </TextField>
        <p>{summary}</p>
        {incomeSources.length && expenseSources.length ?
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Category</TableCell>
                            <TableCell align="right">Total amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Row row={createData("Money going in", incomeSources)} />
                        <Row row={createData("Money going out", expenseSources)} />
                    </TableBody>
                </Table>
            </TableContainer> : null}
        <p>Note: This financial report has been simplified for visualization purpose.</p>
    </div>
}
import { Box, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { IssueStateView } from '../../../components/IssueStateView';
import { IListingSubListingItemProps, ListingSubListingItem } from '../../../components/listing/ListingSubListingSection/ListingSubListingItem';
import { ListingCreationFlowContext } from '../../../context/Listing/ListingCreationFlow';
import { UserContext } from '../../../context/UserInformation';
import { getListingInfo } from '../../../services/listing';
import { getUserListings } from '../../../services/user';
import { EUserRole } from '../../../types/db';
import { ROUTE_PATH } from '../../../utilities/navigation';

/** The first page of the listing creation flow */
export const ListingNewPage = () => {
    const navigate = useNavigate();

    return <Container navigateToNew={() => navigate(ROUTE_PATH.listingNewBuild())} />
}

interface IContainerProps {
    parentId?: string;
    deepness?: number;
    /** Callback to reset the creation and navigate to a new flow */
    navigateToNew?: () => void;
}

const Container = ({ parentId, deepness = 0, navigateToNew }: IContainerProps) => {
    const { t } = useTranslation();
    const userContext = useContext(UserContext);
    if (!useContext) {
        throw new Error('Need UserContext');
    }

    const listingCreationFlowContext = useContext(ListingCreationFlowContext);
    if (!listingCreationFlowContext) {
        throw new Error('Need listingCreationFlowContext')
    }
    const { listing, setActivePageInfo } = listingCreationFlowContext;

    //if no parent id: fetch all the parent listing for the current user
    //if parent id: fetch all the sub-listing of this specific listing
    const { data, isLoading } = useQuery(
        !parentId ? ["Listings"] : ['Listings', parentId],
        async () => !parentId ? getUserListings(userContext?.user?.firebaseUser?.uid || '', EUserRole.HOST) : (await getListingInfo(parentId)).sub_listings || [],
        {
            enabled: !!userContext?.user?.firebaseUser?.uid,
            refetchOnWindowFocus: false,//don't want to refresh too often
        });

    const parentListings = useMemo(() => ([
        ...(data?.map((l: any /*Listing | ListingSubListing*/) => ({
            id: l.id,
            title: l.title,
            description: l.short_description || l.description,
            photoUrl: l.main_photo?.url || '',
            listingRef: l.ref,
        })) ?? []),
        // {
        //     id: (parentId || '') + '123',
        //     title: `Parent listing ${!!parentId ? `under ${parentId}` : ''}`,
        //     description: 'description',
        //     photoUrl: LISTING_IMAGE_1,
        // }
    ]), [data]);

    const breadcrumbs = useMemo(() => listing?.breadcrumbs?.map(b => b!.id) || [], [listing?.breadcrumbs]);

    // const [selectionRadio, setSelectionRadio] = useState<RadioChoice | undefined>(!!breadcrumbs[deepness] ? "parent" : !!listing?.duplicate_from ? "duplicate" : !!listing?.status ? "new" : undefined);
    // const [selectionParent, setSelectionParent] = useState<string | undefined>(breadcrumbs[deepness] || undefined);

    const selectedRadio = !!breadcrumbs[deepness] ? "parent" : !!listing?.duplicate_from ? "duplicate" : !!listing?.status ? "new" : undefined;
    const selectedParent = breadcrumbs[deepness] || undefined;

    // console.log('selection', selectedRadio, selectedParent)

    const handleSelections = useCallback((
        selectionRadio: typeof selectedRadio,
        selectionParent?: typeof selectedParent
    ) => {
        // console.log('handle selection with ', selectionRadio, selectionParent, deepness);
        //helper function which just reset the info to the current unchosen path
        const reset = () => setActivePageInfo({
            breadcrumbs: listing.breadcrumbs?.slice(0, deepness) ?? [],
            duplicate_from: undefined,
        }, false);

        if (!selectionRadio) {
            //if no selection made, we indicate that the step is not complete
            reset();
            return;
        }

        if (selectionRadio === "new") {
            //if "new" selected ready to complete the step
            //make sure we take the breadcrumbs only up to this deepness and the duplication has been reset
            setActivePageInfo({
                status: 'pending',
                breadcrumbs: listing.breadcrumbs?.slice(0, deepness) ?? [],
                duplicate_from: undefined,
            }, true);
        } else if (selectionRadio === "parent") {
            if (!!selectionParent) {
                // if "parent" selected and we have a parent listing selected
                // update the breadcrumbs list
                const parent = parentListings.find(pl => pl.id === selectionParent);
                setActivePageInfo({
                    breadcrumbs: [
                        ...(listing.breadcrumbs?.slice(0, deepness) ?? []),
                        {
                            id: parent?.id || selectionParent,
                            title: parent?.title,
                            description: parent?.description,
                            main_photo: parent?.photoUrl,
                            ref: parent?.listingRef,
                        }],
                    duplicate_from: undefined,
                }, true);
            } else {
                // if "parent" selected and but no parent listing selected
                reset();
            }
        } else if (selectionRadio === "duplicate") {
            if (!!selectionParent) {
                // if "duplicate" selected and we have a listing selected
                setActivePageInfo({
                    breadcrumbs: deepness > 0 ? (listing.breadcrumbs?.slice(0, deepness - 1) ?? []) : [],
                    duplicate_from: selectionParent,
                }, true);
                // getListingInfo(selectionParent)
                //     .then((dl) => {
                //         const { id, ref, ...rest } = dl;
                //         console.log('loaded info', dl);
                //         setActivePageInfo({
                //             ...rest,
                //             duplicate_from: id,
                //         }, true);
                //     })
            } else {
                // if "duplicate" selected and but no listing selected
                reset();
            }
        } else {
            setActivePageInfo({}, false);
        }
    }, [deepness, listing.breadcrumbs, parentListings, setActivePageInfo]);

    if (isLoading) {
        return <CircularProgress />
    }
    //if already have an id, listing already created
    if (!!listing.id && !!navigateToNew) {
        setActivePageInfo({}, true);
        return <IssueStateView title={t('listing.creation.errorPendingTitle')}
            description={t('listing.creation.errorPendingDescription')}
            button={{
                text: t('listing.creation.errorPendingBtn'),
                onActionClick: () => navigateToNew()
            }} />
    }

    return <View parentListings={parentListings}
        selectedListing={selectedParent}
        // onParentListingSelected={(selection) => handleSelections(selectedRadio, selection)}
        selectedRadio={selectedRadio}
        // onRadioSelected={(selection) => handleSelections(selection)}
        onSelectionChanged={handleSelections}
        deepness={deepness} />
}

type RadioChoice = "parent" | "duplicate" | "new";

interface IViewProps {
    /** The list of parent listing to choose from */
    parentListings: (Omit<IListingSubListingItemProps, 'onClick'> & { id: string })[];
    /** Optional the parent selected (listing id) */
    selectedListing?: string;
    /** Callback on parent listing selected */
    onParentListingSelected?: (selection: string) => void;
    /** Optional the radio pre-selection */
    selectedRadio?: RadioChoice;
    /** Callback on radio choice selected */
    onRadioSelected?: (radio: RadioChoice) => void;
    /** Callback on selection changed */
    onSelectionChanged: (radio?: RadioChoice, selection?: string) => void;
    /** The current deepness of the selection view */
    deepness: number;
}

const View = ({
    parentListings,
    selectedListing,
    onParentListingSelected,
    selectedRadio,
    onRadioSelected,
    onSelectionChanged,
    deepness,
}: IViewProps) => {
    const { t } = useTranslation();
    const [radioSelected, setRadioSelected] = useState<RadioChoice | ''>(selectedRadio || '');
    const [listingSelected, setListingSelected] = useState(selectedListing || '');
    const selectedListingProps = parentListings.find(pl => pl.id === listingSelected);

    useEffect(() => {
        onSelectionChanged(radioSelected || undefined, listingSelected);
        //onSelectionChanged not needed in the dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listingSelected, radioSelected])

    const handleChange = (_: any, value: string) => {
        const radioChoice = value as RadioChoice;
        setRadioSelected(radioChoice);
        onRadioSelected?.(radioChoice);
        // if the value is new, we reset the parent choice
        if (radioChoice === "new") {
            setListingSelected(cs => {
                if (cs !== '') {
                    onParentListingSelected?.('');
                }
                return ''
            });
        }
    }

    const handleParentListingChange = (event: SelectChangeEvent<string>) => {
        const selectedId = event.target.value;
        setListingSelected(selectedId);
        onParentListingSelected?.(selectedId);
    }

    return (
        <FormControl>
            <RadioGroup
                value={radioSelected}
                onChange={handleChange}
            >
                <FormControlLabel
                    value={"parent" as RadioChoice}
                    control={<Radio />}
                    label={t('listing.creation.newFrom', { context: 'parent' })}
                    disabled={!parentListings.length}
                />
                <FormControlLabel
                    value={"duplicate" as RadioChoice}
                    control={<Radio />}
                    label={t('listing.creation.newFrom', { context: 'duplicate' })}
                    disabled={!parentListings.length}
                />
                <FormControlLabel
                    value={"new" as RadioChoice}
                    control={<Radio />}
                    label={t('listing.creation.newFrom', { context: 'new' })}
                />
            </RadioGroup>
            {!!radioSelected && radioSelected !== "new" &&
                <Box sx={{ marginLeft: 3 }}>
                    <FormControl fullWidth>
                        <InputLabel>{t('listing.creation.selectFromInputLabel')}</InputLabel>
                        <Select
                            value={listingSelected}
                            label={(listingSelected === '' || !selectedListingProps) ?
                                t('listing.creation.selectFromInputLabel') :
                                <ListingSubListingItem {...selectedListingProps} />}
                            placeholder={t('listing.creation.selectFromPlaceholder')}
                            onChange={handleParentListingChange}
                        >
                            {
                                parentListings.map((pl, i) => <MenuItem key={i} value={pl.id}><ListingSubListingItem {...pl} /></MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    {!!selectedListing && radioSelected !== "duplicate" && <Container parentId={listingSelected} deepness={deepness + 1} />}
                </Box>
            }
        </FormControl>
    )
}
import React from 'react';
import { useQuery } from 'react-query';
import { Diversity1 } from '@mui/icons-material';

import { AccountBaseInfo, AccountBaseInfoItems } from '../../../components/user/account/AccountBaseInfo';
import { IAccountHelpItemProps } from '../../../components/user/account/AccountHelpItem';
import { getTitleFromAccountPage } from '../../../components/user/account/AccountPageView';
import { getUserReferralsInfo } from '../../../services/user';
import { IAccountSubContainerProps } from '../AccountSubContainer';
import { useTranslation } from 'react-i18next';

export const AccountReferralsContainer = ({
    subPage,
    userId,
    children,
}: IAccountSubContainerProps) => {
    const { t } = useTranslation();
    const userReferralsResult = useQuery(["account", "referrals"],
        () => getUserReferralsInfo(userId));

    const userReferralsInfo = userReferralsResult.data;

    const items: AccountBaseInfoItems =
        // !userReferralsInfo ? [] :
        [
            {
                title: t('user.accountReferrals.numberSignupTitle'),
                value: userReferralsInfo?.sign_ups.toFixed() || '0',
            },
            {
                title: t('user.accountReferrals.numberCompletedReferralTitle'),
                value: userReferralsInfo?.completed.toFixed() || '0',
            },
            //TODO complete
        ]

    const helpItems = HELP_ITEMS.map(h => ({
        ...h,
        title: t(h.title),
        description: t(h.description)
    }))

    if (!!children) {
        return children({ items, helpItems: helpItems });
    } else {
        return <AccountBaseInfo title={getTitleFromAccountPage(t, subPage)}
            items={items}
            helpItems={helpItems} />
    }
}

const HELP_ITEMS: IAccountHelpItemProps[] = [
    {
        icon: <Diversity1 />,
        title: 'user.accountReferrals.helpInfo1Title',
        description: 'user.accountReferrals.helpInfo1Content',
        //+" We are providing coupon for each completed referral.",//TODO need referral program set up
    },
];
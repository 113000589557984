import { CloseOutlined } from '@mui/icons-material';
import { Avatar, Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PropsWithErrorState, PropsWithLoadingState } from '../../../utilities';
import { ERole, getStringKeyForUserRole } from '../../../utilities/EnumUtility';
import { getColorFrom } from '../../../utilities/theme/ColorUtils';

export interface IChatThreadDetailsProps {
    /** The list of members in the thread */
    members: {
        displayName: string;
        avatarSrc?: string;
        role?: ERole;
        onPress: () => void;
    }[];
    /** The summary of the listing the thread is about */
    listing?: {
        title: string;
        imageSrc?: string;
        onPress: () => void;
    };
    /** Optional, The summary of the event the thread is about */
    event?: {
        title: string;
        imageSrc?: string;
        onPress: () => void;
    };
    /** Optional, The summary of the booking the thread is about */
    booking?: {

    }
    /** Callback on details closed */
    onCloseClick: () => void;
}

/**
 * Represent the details of a certain chat thread
 * @param props IChatThreadDetailsProps
 */
export const ChatThreadDetails = ({ members, onCloseClick, error, loading }: PropsWithLoadingState<PropsWithErrorState<IChatThreadDetailsProps>>) => {
    const { t } = useTranslation();
    return (
        <Box>
            <IconButton onClick={onCloseClick}><CloseOutlined /></IconButton>
            {!error ? //TODO error state
                <Stack direction={'column'} spacing={1} p={1}>
                    <Typography variant='h5' fontWeight={'bold'}>{t('chat.details.membersTitle')}</Typography>
                    {
                        !loading ?
                            members?.map((member, index) => {
                                const roleColor = !!member.role ? getColorFrom(member.role) : 'grey'
                                const extraStyling = !!member.role ?
                                    {
                                        color: roleColor,
                                        sx: {
                                            border: 2,
                                            borderColor: roleColor
                                        }
                                    } : undefined;
                                return <Stack key={`details-member-${index}`}
                                    onClick={member.onPress}
                                    direction={'row'}
                                    justifyContent={'flex-start'}
                                    alignItems={'center'}
                                    spacing={1}>
                                    <Avatar
                                        src={member.avatarSrc}
                                        alt={member.displayName}
                                        {...extraStyling} />
                                    <Stack direction={'column'}>
                                        {member.role &&
                                            <Typography variant='caption'
                                                color={(theme) => theme.palette.getContrastText(roleColor)}
                                                sx={{ backgroundColor: roleColor, borderRadius: 2, px: 1 }}>
                                                {t(getStringKeyForUserRole(member.role))}
                                            </Typography>
                                        }
                                        <Typography>{member.displayName}</Typography>
                                    </Stack>
                                </Stack>
                            }
                            ) :
                            new Array(2).fill({}).map((_, index) =>
                                <Stack key={`details-member-loading-${index}`}
                                    direction={'row'}
                                    justifyContent={'flex-start'}
                                    alignItems={'center'}
                                    spacing={1}>
                                    <Avatar><Skeleton variant='circular' /></Avatar>
                                    <Stack direction={'column'}>
                                        <Typography variant='caption'>
                                            <Skeleton width={50} />
                                        </Typography>
                                        <Typography><Skeleton width={100} /></Typography>
                                    </Stack>
                                </Stack>
                            )
                    }
                    {/* <Typography>{JSON.stringify(listing)}</Typography> */}
                </Stack>
                :
                <Stack direction={'column'} spacing={1} p={1}>
                    <Typography variant='h5'>{t('chat.details.errorTitle')}</Typography>
                    <Typography>{t('chat.details.errorContent')}</Typography>
                </Stack>
            }
        </Box>
    ); //TODO loading state and more info in the details (other than the members)
}

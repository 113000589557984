import React from 'react';
import { useNewsNavigation } from '../../hooks/useNewsNavigation';
import { NewsProductReleaseContainer } from '../../containers/news/NewsProductReleaseContainer';

/** Navigation NewsProductReleasePage which represent the list of story for product release specifically */
export const NewsProductReleasePage = () => {
    const newsNavigation = useNewsNavigation();

    return <NewsProductReleaseContainer {...newsNavigation} />;
}


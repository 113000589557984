import React from "react";
import firebase from 'firebase/app';
import { useQuery } from "react-query";
import { getStories } from "../../services/news";
import { ENewsCategories, NEWS_TAGS_FIELD, NEWS_TIMESTAMP_PUBLISHED_AT_FIELD } from "../../types/db";
import { mapDataToUI } from "./utils";
import { useNewsNavigation } from "../../hooks/useNewsNavigation";
import { NewsMainScreen } from "../../components/news/NewsMainScreen";

const STORIES_PER_SECTION = 6;

interface INewsMainPageContainerProps extends ReturnType<typeof useNewsNavigation> { }

/** Take care of fetching all the data for the main page and display it */
export const NewsMainPageContainer = ({ navigateToStory, navigateToCategory }: INewsMainPageContainerProps) => {
    //fetch data
    const resultTopStory = useQuery(
        ["story", "top"],
        () => getStories({
            limit: 1,
            orderBy: { field: NEWS_TIMESTAMP_PUBLISHED_AT_FIELD, dir: "desc" },
            where: [
                { fieldPath: NEWS_TAGS_FIELD, opStr: 'array-contains', value: "trends" },
                {
                    fieldPath: NEWS_TIMESTAMP_PUBLISHED_AT_FIELD,
                    opStr: '<=',
                    value: firebase.firestore.Timestamp.now(),
                },
            ]
        }).then(res => res[0]),
        {
            refetchOnWindowFocus: false
        }
    );
    const resultLatestStories = useQuery(
        ["stories", "latest"],
        () => getStories({
            limit: STORIES_PER_SECTION,
            orderBy: { field: NEWS_TIMESTAMP_PUBLISHED_AT_FIELD, dir: "desc" },
            where: (!!resultTopStory.data && !!resultTopStory.data[NEWS_TIMESTAMP_PUBLISHED_AT_FIELD]) ?
                [
                    {
                        fieldPath: NEWS_TIMESTAMP_PUBLISHED_AT_FIELD,
                        opStr: '<=',
                        value: firebase.firestore.Timestamp.now(),
                    },
                    { fieldPath: NEWS_TIMESTAMP_PUBLISHED_AT_FIELD, opStr: "!=", value: resultTopStory.data[NEWS_TIMESTAMP_PUBLISHED_AT_FIELD] },
                    // { fieldPath: firebase.firestore.FieldPath.documentId(), opStr: "!=", value: resultTopStory.data.id }
                ]
                :
                [
                    {
                        fieldPath: NEWS_TIMESTAMP_PUBLISHED_AT_FIELD,
                        opStr: '<=',
                        value: firebase.firestore.Timestamp.now(),
                    },
                ]
        }),
        {
            enabled: !resultTopStory.isLoading
        }
    );
    const resultOtherStories = useQuery(
        ["stories", "other"],
        () => getStories({
            limit: STORIES_PER_SECTION,
            orderBy: { field: NEWS_TIMESTAMP_PUBLISHED_AT_FIELD, dir: "asc" },
            where: [{
                fieldPath: NEWS_TIMESTAMP_PUBLISHED_AT_FIELD,
                opStr: '<=',
                value: firebase.firestore.Timestamp.now(),
            }]
        }),
    );

    const handleNavigateToStories = (category?: ENewsCategories) => {
        if (!category) {
            navigateToStory()
        } else {
            navigateToCategory(category)
        }
    }

    return <NewsMainScreen
        topStory={resultTopStory.isLoading ?
            { loading: true }
            : resultTopStory.data && mapDataToUI(resultTopStory.data, { navigateToStory })
        }
        latestStories={resultTopStory.isLoading || resultLatestStories.isLoading ?
            { loading: true, length: STORIES_PER_SECTION }
            : resultLatestStories.data?.map(story => mapDataToUI(story, { navigateToStory })) ?? []
        }
        otherStories={resultOtherStories.isLoading ?
            { loading: true, length: STORIES_PER_SECTION }
            : resultOtherStories.data?.map(story => mapDataToUI(story, { navigateToStory })) ?? []
        }
        seeAllStoriesClick={handleNavigateToStories}
    />;
}
import React from 'react';
import { useParams } from "react-router"
import { useSearchParams } from 'react-router-dom';
import { ROUTE_PARAMS, ROUTE_QUERY } from "../../utilities/navigation";
import { EAudience } from '../../types/db/help';
import { HelpCategoriesContainer, HelpTopicContainer } from '../../containers/help';
import { getAudienceFromQuery, getQueryFrom, useHelpCenterNavigation } from '../../hooks/useHelpCenterNavigation';


export const HelpTopicPage = () => {
    const { navigateToTopic, navigateToArticle } = useHelpCenterNavigation();
    const { [ROUTE_PARAMS.topicID]: topicId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const onAudienceChange = (audience: EAudience) => {
        setSearchParams({ [ROUTE_QUERY.audience]: getQueryFrom(audience) })
    }

    if (!topicId) {
        return <HelpCategoriesContainer
            selectedAudience={getAudienceFromQuery(searchParams.get(ROUTE_QUERY.audience))}
            onAudienceSelected={onAudienceChange}
            onTopicClicked={navigateToTopic}
        />;
    }

    return <HelpTopicContainer
        topicId={topicId}
        onTopicClicked={navigateToTopic}
        onArticleClicked={navigateToArticle} />;
}


/** The types for all forums related db (firestore+realtime) objects */
/** NOTE: all change should be done in the shared_type folder 
 * and run `npm run build:shared_types` in root project */

import { Timestamp } from "./common";

/******************************************
 * FORUMS DB FIRESTORE
 * |forums //collection containing all posts
 * -|replies //replies to the posts
 * --|replies //replies to the replies
 * ---|replies... //can be infinite?
 * --|likes //like on the reply
 * -|likes //likes on the post
 ******************************************/

/** The root collection for the forums data in the DB */
export const FORUMS_COLLECTION = "forums";

/** The topic of forum copy of "src/utilities/forums"*/
/** The enum for forum topics (for simplicity, will use it for BD, navigation and UI) */
export enum EForumTopic {
    COMMUNITY = "community",
    HOST = "host",
    COHOST = "cohost",
    GUEST = "guest"
}

/** The basic info of an author of a post/reply */
export type ForumAuthor = {
    id: string;
    display_name: string;
    avatar_url?: string;
}

/** The topic field name we can filter from */
export const FORUMS_TOPIC_FIELD = "topic";
/** The post timestamp we can order by*/
export const FORUMS_POST_TIMESTAMP_FIELD = "timestamp";
/** The post's like count field*/
export const FORUMS_POST_LIKES_COUNT_FIELD = "likes_count";
/** The post's reply count field*/
export const FORUMS_POST_REPLIES_COUNT_FIELD = "replies_count";

export type ForumPost = {
    /** The topic of the post */
    [FORUMS_TOPIC_FIELD]: EForumTopic;
    /** The title of the post */
    title: string;
    /** The content of the post */
    content: string;
    /** The author of the post */
    author: ForumAuthor;
    /** The timestamp when the post was created */
    [FORUMS_POST_TIMESTAMP_FIELD]: Timestamp;
    /** The likes count on the post (updated with cloud functions) */
    [FORUMS_POST_LIKES_COUNT_FIELD]: number;
    /** The reply count on the post (updated with cloud functions) */
    [FORUMS_POST_REPLIES_COUNT_FIELD]: number;
    /** Boolean indicating if the reply as been deactivated for this post */
    reply_disabled: boolean;
    /** The tags linked to the post */
    tags?: string[];
}

/** The reply to post/reply collection */
export const FORUMS_REPLIES_COLLECTION = "replies";
/** The reply timestamp we can order by*/
export const FORUMS_REPLY_TIMESTAMP_FIELD = "timestamp";
/** The reply's like count field*/
export const FORUMS_REPLY_LIKES_COUNT_FIELD = "likes_count";
/** The reply's reply count field*/
export const FORUMS_REPLY_REPLIES_COUNT_FIELD = "replies_count";

export type ForumReply = {
    /** The author of the reply */
    author: ForumAuthor;
    /** The content of the reply */
    content: string;
    /** The timestamp when the reply was created */
    [FORUMS_REPLY_TIMESTAMP_FIELD]: Timestamp;
    /** The likes count on the reply (updated with cloud functions) */
    [FORUMS_REPLY_LIKES_COUNT_FIELD]: number;
    /** The reply count on the reply (updated with cloud functions) */
    [FORUMS_REPLY_REPLIES_COUNT_FIELD]: number;
}

/** The like to post/like collection */
export const FORUMS_LIKES_COLLECTION = "likes";
/** The post's like timestamp */
export const FORUMS_LIKE_TIMESTAMP_FIELD = "timestamp";

export type ForumLike = {
    /** The author of the like */
    author: Omit<ForumAuthor, 'avatar_url'>;
    /** The timestamp when the like was created */
    [FORUMS_LIKE_TIMESTAMP_FIELD]: Timestamp
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Address,
  DEFAULT_ADDRESS,
  FieldNameStrings,
  getAddressFieldHint,
  getAddressFieldLabel,
  getCountryNameByCode,
  getZoneNameByCode,
  getZoneStringKey,
  isFieldOmitted,
} from './Address.utils';
import { COUNTRIES_INFO } from './Address.data';
import { MenuItem, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import { IAddressProps } from './Address.types';

export interface IEditableAddressProps extends Omit<IAddressProps, 'editable'> {
  layout: (FieldNameStrings | FieldNameStrings[])[];
}

export const EditableAddress = ({
  layout,
  address: addressProp,
  onChange,
  omitFields,
  editableFields,
}: IEditableAddressProps) => {
  const [address, setAddress] = useState(addressProp);

  useEffect(() => {
    setAddress(addressProp)
  }, [addressProp])

  const handleFieldChange = (fieldName: FieldNameStrings, newValue: string) => {
    const newAddress = {
      ...DEFAULT_ADDRESS,
      ...address,
      [fieldName]: newValue,
    };
    if (fieldName === 'country' && address?.country !== newValue) {
      //if we change the country, we need to reset the zone
      newAddress.zone = undefined;
    }
    onChange?.(newAddress);
    setAddress(newAddress);
  };

  const renderInput = (field: FieldNameStrings) => {
    const editable = !editableFields?.length || editableFields.findIndex(ef => ef === field) !== -1;
    return <AddressInput
      key={field}
      type={field}
      value={address?.[field] || ''}
      onChange={(v) => handleFieldChange(field, v)}
      countryCode={address?.country}
      disabled={!editable}
    />
  };

  const filteredLayout = useMemo(() => {
    if (!!omitFields?.length) {
      return layout
        .map((l) => {
          if (Array.isArray(l)) {
            return l.filter(field => !isFieldOmitted(field, omitFields))
          }
          if (isFieldOmitted(l, omitFields)) {
            return [];
          }
          return l;
        })
        .filter(l => Array.isArray(l) ? !!l.length : !!l);
    }
    return layout;
  }, [layout, omitFields])

  return (
    <Stack direction={'column'} justifyContent={'start'} alignItems={'stretch'} spacing={2}>
      {filteredLayout
        .map((l) => {
          if (Array.isArray(l)) {
            return (
              <Stack key={`container-${l}`} direction={{ xs: 'column', sm: 'row' }} justifyContent={'center'} alignItems={'center'} spacing={2}>
                {l.map((sl) => renderInput(sl))}
              </Stack>
            );
          }
          return renderInput(l);
        })
      }
    </Stack>
  );
};

interface IAddressInputProps {
  /** The type of input */
  type: FieldNameStrings;
  /** The address info for this field */
  value: string;
  /** Callback on value changed */
  onChange: (value: string) => void;
  /** The country code this address input is intended for */
  countryCode?: string;
  /** If the input is disabled */
  disabled?: boolean;
}

const AddressInput = ({
  type,
  value: valueProps,
  onChange,
  countryCode,
  disabled,
}: IAddressInputProps) => {
  const { t } = useTranslation(["translations", "countries", "continents"]);
  const [value, setValue] = useState(valueProps);

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])

  const handleOnChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      const newValue = ev.target.value;
      setValue(newValue);
      onChange(newValue);
    },
    [onChange],
  );

  const isOptional =
    !!countryCode && COUNTRIES_INFO[countryCode]?.optionalFields.includes(type);

  if (
    type === 'zone' &&
    (!countryCode || !COUNTRIES_INFO[countryCode].zones.length)
  ) {
    //if we don't have this information, or there isn't any zone for this country/region -> return null;
    return null;
  }

  const label = getAddressFieldLabel(
    t,
    type,
    isOptional,
    countryCode
      ? type === 'zone'
        ? getZoneStringKey(COUNTRIES_INFO[countryCode].zoneKey)
        : COUNTRIES_INFO[countryCode].labelKeys?.[type]
      : undefined,
  );
  const placeholder = getAddressFieldHint(t, type,
    countryCode ?
      {
        zoneKey: COUNTRIES_INFO[countryCode].zoneKey,
        zipKey: type === 'zip' ? COUNTRIES_INFO[countryCode].labelKeys?.[type] : undefined
      }
      : undefined
  )

  return (
    <>
      {type === 'country' ? ( //if it's the country field: need to show selector for countries
        <TextField
          value={value || ''}
          onChange={handleOnChange}
          placeholder={placeholder}
          label={label}
          disabled={disabled}
          select
          fullWidth
        >
          <MenuItem
            value={''}
            disabled={true}
          >{placeholder}</MenuItem>
          {Object.keys(COUNTRIES_INFO).map((key) => {
            const { code } = COUNTRIES_INFO[key];
            return (
              <MenuItem
                key={code}
                value={code}
              >{getCountryNameByCode(t, code)}</MenuItem>
            );
          })}
        </TextField>
      ) : type === 'zone' ? (
        <TextField
          value={value || ''}
          onChange={handleOnChange}
          placeholder={placeholder}
          label={label}
          disabled={disabled}
          select
          fullWidth
        >
          <MenuItem
            value={''}
            disabled={true}
          >{placeholder}</MenuItem>
          {COUNTRIES_INFO[countryCode!].zones.map((zone) => {
            return (
              <MenuItem
                key={zone}
                value={zone}
              >{getZoneNameByCode(t, countryCode!, zone)}</MenuItem>
            );
          })}
        </TextField>
      ) : (
        <TextField
          value={value}
          onChange={handleOnChange}
          autoCapitalize={
            type === 'firstName' || type === 'lastName' ? 'words' : 'none'
          }
          autoComplete={
            type === 'address1'
              ? 'street-address'
              : type === 'phone'
                ? 'tel'
                : 'off'
          }
          placeholder={placeholder}
          label={label}
          disabled={disabled}
          fullWidth
        />
      )}
    </>
  );
};

interface IViewableAddressProps extends Omit<IAddressProps, 'editable' | 'editableFields' | 'onChange'> {
  format: string;
}

export const ViewableAddress = ({ format, address, omitFields }: IViewableAddressProps) => {
  const { t } = useTranslation();
  if (!address) {
    return <Typography>{t('address.empty')}</Typography>;
  }
  return <Typography style={{ whiteSpace: 'pre-line' }}>{getFormattedAddress({ format, address, omitFields })}</Typography>;
};

const getFormattedAddress = ({ format, address, omitFields }: IViewableAddressProps) => {
  if (!address) {
    return '';
  }
  var str = format.toString();
  //replace the item we know
  for (var key of Object.keys(address)) {
    const value = address[key as keyof Address];
    if (isFieldOmitted(key as keyof Address, omitFields)) {
      //If field omitted, don't replace by value
      str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), '');
    } else {
      str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), value || '');
    }
  }
  //replace the rest by empty string
  str = str.replace(new RegExp('\\{[a-z]*\\}', 'gi'), '');
  return str.trim();
};

import { ROUTE_SUBPATH } from "../../../utilities/navigation";

/** The listing creation steps ids (in order) */
const LISTING_CREATION_STEPS_IDS = [
    '',//no route for this one
    ROUTE_SUBPATH.type,
    ROUTE_SUBPATH.location,
    ROUTE_SUBPATH.basic,
    ROUTE_SUBPATH.photos,
    ROUTE_SUBPATH.details,
    ROUTE_SUBPATH.pricing,
    ROUTE_SUBPATH.calendar,
    ROUTE_SUBPATH.publish,
] as const;

export type TListingCreationStepId = typeof LISTING_CREATION_STEPS_IDS[number];

type TListingCreationStep = { id: TListingCreationStepId, labelKey: string, descriptionKey?: string, optional?: boolean };

export const LISTING_CREATION_STEPS: TListingCreationStep[] = LISTING_CREATION_STEPS_IDS.map((id, index) => ({
    id,
    labelKey: `listing.creationSteps.${index}.title`,
    descriptionKey: `listing.creationSteps.${index}.content`,
}));

import React, { useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { INewsItemProps } from "../../components/news/NewsItem";
import { useNewsNavigation } from "../../hooks/useNewsNavigation";
import { QueryVariablesWhere } from "../../services/db";
import { getStories, createStory } from "../../services/news";
import { NEWS_AUTHOR_ID_FIELD, NEWS_CATEGORY_FIELD, NEWS_TAGS_FIELD, NewsStory, NEWS_TIMESTAMP_PUBLISHED_AT_FIELD } from "../../types/db";
import { NewsStoriesScreen } from "../../components/news/NewsStoriesScreen";
import { NewsStoriesFilterContainer } from "./NewsStoriesFilterContainer";

/** The number of stories fetch in one batch */
const STORIES_PER_PAGE = 12;

interface INewsStoriesContainerProps extends ReturnType<typeof useNewsNavigation> {
    authorFilter?: string | null;
    categoryFilter?: string | null;
    queryFilter?: string | null;
    tagFilter?: string | null;
}

const TODAY = new Date(new Date(Date.now()).setHours(0, 0, 0, 0))

/** Taking care of fetching the list of stories depending on the filters */
export const NewsStoriesContainer = ({ navigateToStory, authorFilter, categoryFilter, queryFilter, tagFilter }: INewsStoriesContainerProps) => {
    //fetch list of stories
    const { data, isLoading, isError, refetch, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
        ["stories", authorFilter, categoryFilter, tagFilter],
        ({ pageParam }) => getStories({
            orderBy: { field: NEWS_TIMESTAMP_PUBLISHED_AT_FIELD, dir: 'desc' },//most recent first
            limit: STORIES_PER_PAGE,
            startAfter: pageParam,
            where: [
                {
                    fieldPath: NEWS_TIMESTAMP_PUBLISHED_AT_FIELD,
                    opStr: '<=',
                    value: TODAY,
                },
                {
                    fieldPath: NEWS_AUTHOR_ID_FIELD,
                    opStr: '==',
                    value: authorFilter,
                },
                {
                    fieldPath: NEWS_CATEGORY_FIELD,
                    opStr: '==',
                    value: categoryFilter,
                },
                {
                    fieldPath: NEWS_TAGS_FIELD,
                    opStr: 'array-contains',
                    value: tagFilter,
                }
            ].filter(where => !!where.value) as QueryVariablesWhere[]//filter out the ones which are not defined
        }),
        {
            getNextPageParam: (_, pages) => {
                if (!pages?.length)
                    return undefined
                const lastPage: NewsStory[] = pages[pages.length - 1];
                if (lastPage.length < STORIES_PER_PAGE) {
                    //if last page was not fetching a complete page, we don't have more to fetch
                    return undefined;
                }
                return lastPage[lastPage.length - 1];//the last item of the last page
            },
            refetchOnWindowFocus: false
        }
    );


    const items: INewsItemProps[] = useMemo(() => {
        return data?.pages.flatMap(page =>
            (!queryFilter ?
                page //if no queryFilter take page as is
                : page.filter(story =>
                    story.title.includes(queryFilter)
                    || story.content?.includes(queryFilter)
                    || story.author.display_name.includes(queryFilter)
                    || story[NEWS_CATEGORY_FIELD].includes(queryFilter)
                    || story[NEWS_TAGS_FIELD]?.includes(queryFilter)
                )
            )
                .map(story => ({
                    title: story.title,
                    subtitle: story.subtitle,
                    image: story.image,
                    timestamp: story[NEWS_TIMESTAMP_PUBLISHED_AT_FIELD].toDate(),
                    onClick: () => navigateToStory(story.id),
                })))
            ?? []
    }, [data?.pages, navigateToStory, queryFilter])

    if (isError) {
        // return null //items will be empty so just showing empty state
    }

    if (items.length < STORIES_PER_PAGE && hasNextPage && !isFetchingNextPage) {
        //the query can remove most of the result (TODO improve this as we are reading data that we are not showing)
        fetchNextPage();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCreateNewStory = async () => {
        await createStory();
        await refetch()
    }

    return <>
        {/* <Button onClick={handleCreateNewStory}>Generate one new one</Button> */}
        <NewsStoriesScreen items={isLoading ? { loading: true, length: STORIES_PER_PAGE } : items}
            onLoadMoreClick={hasNextPage ? fetchNextPage : undefined}
            renderFilters={<NewsStoriesFilterContainer />}
        />
    </>
}

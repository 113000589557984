import React, { useMemo } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { IShopPriceProps, ShopPrice } from '../ShopPrice';
import { useTranslation } from 'react-i18next';

type ColorInfo = {
    colorName: string;
    colorCode: string;
}
type SizeInfo = string;
type Info = {
    color: ColorInfo;
    size: SizeInfo;
};
type VariantType = {
    value: string;
    image: string;
    title: string;
    info: Info,
    price?: IShopPriceProps;
};

export interface IShopVariantsSelectProps {
    variants: VariantType[],
    selectedVariant: string;
    onVariantSelect: (selection: string) => void;
}

const getSelectedVariantFromID = (variants: VariantType[], selectedVariant: string) => {
    return variants.find(v => v.value === selectedVariant) || variants[0];
}

const getSelectedVariantFromInfo = (variants: VariantType[], info: Info) => {
    return variants.find(v => JSON.stringify(v.info) === JSON.stringify(info));
}

const getColorListFromVariants = (variants: VariantType[]): ColorInfo[] => {
    return variants.reduce((prev, curr) => {
        if (prev.findIndex(v => JSON.stringify(v) === JSON.stringify(curr.info.color)) !== -1) {
            return prev;
        } else {
            return [
                ...prev,
                curr.info.color,
            ]
        }
    }, [] as ColorInfo[])
}

const getSizeListFromVariants = (variants: VariantType[]): SizeInfo[] => {
    return variants.reduce((prev, curr) => {
        if (prev.findIndex(v => JSON.stringify(v) === JSON.stringify(curr.info.size)) !== -1) {
            return prev;
        } else {
            return [
                ...prev,
                curr.info.size,
            ]
        }
    }, [] as SizeInfo[])
}

const getVariantColorValue = (color: ColorInfo) => {
    return `${color.colorName}-${color.colorCode}`;
}

export const ShopVariantsSelect = ({
    variants,
    selectedVariant,
    onVariantSelect
}: IShopVariantsSelectProps) => {
    const { t } = useTranslation();
    const selectedVariantData = getSelectedVariantFromID(variants, selectedVariant);

    const listColor = useMemo(() =>
        getColorListFromVariants(variants)
        , [variants]);

    const listSize = useMemo(() =>
        getSizeListFromVariants(variants)
        , [variants]);

    //protection
    if (!variants?.length || variants.length === 1) {
        return null;
    }

    const handleColorSelect = (event: SelectChangeEvent) => {
        const colorValue = event.target.value as string;
        onVariantSelect(getSelectedVariantFromInfo(variants,
            {
                ...selectedVariantData.info,
                color: listColor.find(c => getVariantColorValue(c) === colorValue) as ColorInfo
            })?.value || selectedVariantData.value
        );
    }
    const renderColorSelect = () => {
        if (!listColor?.length || listColor.length === 1) {
            //if no color or only one, not showing a select
            return null;
        }
        return (
            <FormControl fullWidth>
                <InputLabel>{t('shop.product.colorVariant')}</InputLabel>
                <Select
                    value={getVariantColorValue(selectedVariantData.info.color)}
                    label={t('shop.product.colorVariant')}
                    onChange={handleColorSelect}
                >
                    {
                        listColor.map(color =>
                            <MenuItem value={getVariantColorValue(color)}
                                key={getVariantColorValue(color)}>
                                <Stack direction={'row'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    spacing={2}
                                >
                                    <Box style={{ height: 50, width: 50, backgroundColor: color.colorCode }}
                                        border={1}
                                        borderColor={'black'} />
                                    <Typography>{color.colorName}</Typography>
                                </Stack>
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        )
    }

    const handleSizeSelect = (event: SelectChangeEvent) => {
        const size = event.target.value as string;
        onVariantSelect(getSelectedVariantFromInfo(variants,
            {
                ...selectedVariantData.info,
                size: listSize.find(s => s === size) as SizeInfo,
            })?.value || selectedVariantData.value
        );
    }
    const renderSizeSelect = () => {
        if (!listSize?.length || listSize.length === 1) {
            //if no size or only one, not showing a select
            return null;
        }
        return (
            <FormControl fullWidth>
                <InputLabel>{t('shop.product.sizeVariant')}</InputLabel>
                <Select
                    value={selectedVariantData.info.size}
                    label={t('shop.product.sizeVariant')}
                    onChange={handleSizeSelect}
                >
                    {
                        listSize.map(size =>
                            <MenuItem value={size}
                                key={size}>
                                <Stack direction={'row'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    spacing={2}
                                >
                                    <Typography>{size}</Typography>
                                </Stack>
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        )
    }

    const handleChange = (event: SelectChangeEvent) => {
        onVariantSelect(event.target.value as string);
    };
    const renderSelect = () => {
        if (listColor.length > 1 || listSize.length > 1) {
            //if we have a spec on color or size, we would show them instead of the generic one
            return null;
        }
        return (
            <FormControl fullWidth>
                <InputLabel>{t('shop.product.otherVariant')}</InputLabel>
                <Select
                    value={selectedVariant}
                    label={t('shop.product.otherVariant')}
                    onChange={handleChange}
                >
                    {
                        variants.map(variant =>
                            <MenuItem value={variant.value}
                                key={variant.value}>
                                <Stack direction={'row'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    spacing={2}
                                >
                                    <img src={variant.image}
                                        alt={variant.title}
                                        style={{ height: 50, width: 50, objectFit: 'contain' }} />
                                    <Typography>{variant.title}</Typography>
                                    {!!variant.price && <ShopPrice {...variant.price} />}
                                </Stack>
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        );
    }

    return (
        <Box sx={{ minWidth: 120 }}>
            <Stack direction={'column'} spacing={1}>
                {renderColorSelect()}
                {renderSizeSelect()}
                {renderSelect()}
            </Stack>
        </Box>
    );
}
import React, { } from "react";
import { EAuthType } from "../../components/AuthContent";
import { useNavigate } from "react-router";
import { ROUTE_PATH, ROUTE_QUERY } from "../../utilities/navigation";
import { useRouterQuery } from "../../hooks";
import { auth } from "../../services/firebase";
import { AuthContainer } from "../../containers/AuthContainer";

interface IAuthPage {
  authType?: EAuthType;
}

export const AuthPage = ({ authType }: IAuthPage) => {
  const navigate = useNavigate();
  const query = useRouterQuery();

  const redirectHome = () => {
    navigate(ROUTE_PATH.home);
  };

  const redirectFrom = () => {
    const from = query.get(ROUTE_QUERY.from);
    if (!!from) {
      if (from === ROUTE_PATH.users) {
        const uid = auth.currentUser?.uid;
        if (uid) {
          navigate(ROUTE_PATH.userBuild(uid))
        } else {
          redirectHome();
        }
      }
    } else {
      redirectHome()
    }
  }

  const redirectToAuthType = (type: EAuthType) => {
    switch (type) {
      case EAuthType.LOG_IN:
        return navigate(ROUTE_PATH.authBuild(ROUTE_PATH.auth_login));
      case EAuthType.SIGN_UP:
        return navigate(ROUTE_PATH.authBuild(ROUTE_PATH.auth_join));
      case EAuthType.LINK:
        return navigate(ROUTE_PATH.authBuild(ROUTE_PATH.auth_link));
    }
  }

  return <AuthContainer authType={authType}
    redirectToAuthType={redirectToAuthType}
    redirectTo={() => redirectFrom()} />;
};

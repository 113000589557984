/** The types for all shop related db (firestore+realtime) objects */
/** NOTE: all change should be done in the shared_type folder 
 * and run `npm run build:shared_types` in root project */

/******************************************
 * HELP DB FIRESTORE
 * |help //collection containing main topics
 * -|topics
 * --|articles //articles in this topic
 * --|topics //sub topic
 * ---|articles //articles in the sub-topic
 * ---|... //the sub-topic deepness can be virtually infinite
 ******************************************/

/** The root collection for the help center data in the DB */
export const HELP_COLLECTION = "help";

/** The audience of certain topics/article */
export enum EAudience {
    COMMON = "COMMON",
    HOST = "HOST",
    COHOST = "COHOST",
    GUEST = "GUEST",
};
/** The audience field name we can filter from */
export const HELP_AUDIENCE_FIELD = "audience";

export type Help = HelpTopic;//the root help collection is a list of main topics

/** The topics collection */
export const HELP_TOPICS_COLLECTION = "topics";

export type HelpTopic = {
    /** The audience this topic is mainly directed to */
    [HELP_AUDIENCE_FIELD]: EAudience;
    /** The breadcrumbs (topic parents) for this topic */
    breadcrumbs: HelpSubTopic[];
    /** The title of the topics */
    title: string;
    /** The short description of this topic */
    description?: string;
    /** The related topics from this topic */
    related_topics?: HelpSubTopic[];
    /** The summary of the sub-topics (managed by the cloud with trigger) */
    sub_topics?: HelpSubTopic[];
    /** The summary of the sub-article (managed by the cloud with trigger) */
    sub_articles?: HelpSubArticle[];
}

export type HelpSubTopic = {
    /** The title of the sub topic */
    title: string;
    /** The topic ID of the sub topic (easier to have it already extracted) */
    topic_id: string;
    /** A reference to the topic */
    topic_ref: any;//firestore.DocumentReference;
}

/** The article collection */
export const HELP_ARTICLES_COLLECTION = "articles";

/** Field in article used to order articles from their feedback rate/ratio */
export const HELP_ARTICLE_FEEDBACK_FIELD = 'feedback_ratio';

export type HelpArticle = {
    /** The audience this article is mainly directed to */
    [HELP_AUDIENCE_FIELD]: EAudience;
    /** The breadcrumbs (topic parents) for this article */
    breadcrumbs: HelpSubTopic[];
    /** The title of the article */
    title: string;
    /** The short description of this article */
    description: string;
    /** The number of positive-negative feedbacks (it helps knowing if this article is useful and rank it in the top articles) */
    [HELP_ARTICLE_FEEDBACK_FIELD]: number;
    /** The related articles from this article */
    related_articles?: HelpSubArticle[];
}

export type HelpSubArticle = {
    /** The title of the article */
    title: string;
    /** The short description/summary of the article */
    description: string;
    /** The article ID of the sub article (easier to have it already extracted) */
    article_id: string;
    /** The reference to the article */
    article_ref: any;//firestore.DocumentReference;
}
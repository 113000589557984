import React, { useContext, useState } from 'react';
import { Link, Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { SxProps } from '@mui/system';
import { AppBar, Avatar, Button, Box, Menu, MenuItem, Container, IconButton, Toolbar, Tooltip, Typography, Theme, Stack, Divider } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

import { UserContext } from '../../../context/UserInformation';
import { ROUTE_PATH } from '../../../utilities/navigation';
import { LOGO_COLOR_URL, LOGO_WHITE_BOLD } from '../../../utilities/assets/assetHelper';

interface IChoAppBarProps {
    /** The logo to use (color by default) */
    logo?: 'color' | 'white';
    /** The main menu items */
    mainMenuItems: { label: string, action: () => void }[];
    /** The menu items under the user (boolean true for divider) (undefined if we don't want avatar in app bar) */
    userMenuItems?: ({ label: string, action: () => void } | boolean)[];
    /** Info indicating if showing the Outlet even if user not logged in */
    showOutlet?: 'always' | 'logged-only' | 'never';
}

/** The style of the app bar (exported to be reused) */
export const styles = {
    logoText: {
        mr: 2,
        ml: 2,
        mt: 1,
        color: 'inherit',
        fontFamily: 'PartyLetPlain',
        textDecoration: 'none',
        fontWeight: 500,
    },
    logoIcon: {
        objectFit: 'contain',
        height: '42px',
    },
};

/** Generic CoHostOp AppBar to be reused along all app bars */
export const ChoAppBar = ({ logo = 'color', mainMenuItems, userMenuItems, showOutlet = 'always' }: IChoAppBarProps) => {
    const { t } = useTranslation();
    const userContext = useContext(UserContext);
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const renderAppIcon = (style: SxProps<Theme>) => {
        return <Box sx={style}>
            <Link to={"/"}>
                <img
                    style={styles.logoIcon as React.CSSProperties}
                    src={logo === 'color' ? LOGO_COLOR_URL : LOGO_WHITE_BOLD}
                    alt="CoHostOp logo"
                />
            </Link>
        </Box>
    }

    return (
        <AppBar position="sticky">
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    {renderAppIcon({ display: { xs: 'none', md: 'flex' } })}
                    <Typography
                        variant="h4"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            ...styles.logoText,
                        }}
                    >
                        {t('appName')}
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        {!!mainMenuItems.length &&
                            <>
                                <Tooltip title={t('home.menu.open')}>
                                    <IconButton
                                        size="large"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {mainMenuItems.map((page) => (
                                        <MenuItem key={page.label} onClick={() => { handleCloseNavMenu(); page.action() }}>
                                            <Typography textAlign="center">{page.label}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        }
                        {renderAppIcon({ display: { xs: 'flex', sm: 'none' } })}
                    </Box>
                    <Typography
                        variant="h4"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            display: { xs: 'none', sm: 'flex', md: 'none' },
                            flexGrow: 1,
                            ...styles.logoText,
                        }}
                    >
                        {t('appName')}
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {mainMenuItems.map((page) => (
                            <Button
                                key={page.label}
                                onClick={() => { handleCloseNavMenu(); page.action() }}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page.label}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        {
                            // !!userContext?.loading ? null :
                            !!userContext?.isUserLoggedIn() ?
                                <Stack direction={'row'}
                                    justifyContent={'end'}
                                    alignItems={'center'}
                                    spacing={2}>
                                    {showOutlet !== 'never' && <Outlet />}
                                    {!!userMenuItems &&
                                        <>
                                            <Tooltip title={t('user.menu.open')}>
                                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                                    <Avatar alt={userContext?.user?.displayName ?? ""} src={userContext?.user?.avatarUrl ?? ""} />
                                                </IconButton>
                                            </Tooltip>
                                            <Menu
                                                sx={{ mt: '45px' }}
                                                id="menu-appbar"
                                                anchorEl={anchorElUser}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                open={Boolean(anchorElUser)}
                                                onClose={handleCloseUserMenu}
                                            >
                                                {userMenuItems.map((page, index) => (typeof page === 'boolean' && page === true) ?
                                                    (
                                                        <Divider key={`divider-${index}`} />
                                                    )
                                                    : !page ?
                                                        null
                                                        :
                                                        (
                                                            <MenuItem key={page.label} onClick={() => { handleCloseUserMenu(); page.action(); }}>
                                                                <Typography textAlign="center">{page.label}</Typography>
                                                            </MenuItem>
                                                        ))}
                                            </Menu>
                                        </>
                                    }
                                </Stack>
                                :
                                <Stack direction={'row'}
                                    justifyContent={'end'}
                                    alignItems={'center'}
                                    spacing={2}>
                                    {showOutlet === 'always' && <Outlet />}
                                    {!!userMenuItems &&
                                        <Button key={'join'}
                                            component={Link}
                                            to={ROUTE_PATH.authBuild(ROUTE_PATH.auth_join)}
                                            sx={{ my: 2, color: 'white' }}
                                            variant={'outlined'}
                                            color={'inherit'}>
                                            {t('home.menu.join')}
                                        </Button>
                                    }
                                </Stack>
                        }
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

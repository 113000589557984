import { Box, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { Outlet, useNavigate, useParams } from 'react-router';
import { ListingsPageView } from '../../../components/user/listings/ListingsPageView';
import { IListingRowProps } from '../../../components/user/listings/ListingsPageView/ListingTable.types';
import { UserContext } from '../../../context/UserInformation';
import { getUserListings } from '../../../services/user';
import { EUserRole, Listing, WithID } from '../../../types/db';
import { ROUTE_PARAMS, ROUTE_PATH } from '../../../utilities/navigation';
import { UserSelectRoleContainer } from '../UserDashboardPage';

/** The User own listing page */
export const UserListingsPage = () => {
    const navigate = useNavigate();
    const { [ROUTE_PARAMS.listingID]: listingID } = useParams();

    const navigateToListingPreview = (listingId: string) => {
        navigate(ROUTE_PATH.listingBuild(listingId, true));
    }
    const navigateToListingSettings = (listingId: string) => {
        navigate(ROUTE_PATH.listingSettingsBuild(listingId));
    }
    const navigateToListingCreation = (parentListingId?: string) => {
        navigate(ROUTE_PATH.listingNewBuild(parentListingId));
    }
    if (!listingID) {
        return <UserListingsContainer
            isFullPage={!listingID} //full page if no listingId indicated in the url
            navigateToListingPreview={navigateToListingPreview}
            navigateToListingSettings={navigateToListingSettings}
            navigateToListingCreation={navigateToListingCreation}
        />
    }
    return <Stack direction={'row'}>
        {/* Don't show side listings list for small screen */}
        <Box flex={1} display={{ xs: 'none', md: 'block' }}>
            <UserListingsContainer
                isFullPage={!listingID} //full page if no listingId indicated in the url
                navigateToListingPreview={navigateToListingPreview}
                navigateToListingSettings={navigateToListingSettings}
                navigateToListingCreation={navigateToListingCreation}
            />
        </Box>
        <Box flex={3}>
            <Outlet />
        </Box>
    </Stack>
}

interface IUserListingsContainerProps {
    /** Boolean indication if this is the root page, therefor full view, or not */
    isFullPage: boolean;
    /** Callback navigation to the listing preview */
    navigateToListingPreview: (listingId: string) => void;
    /** Callback navigation to the listing settings (will go by default to the first tab) */
    navigateToListingSettings: (listingId: string) => void;
    /** Callback navigation to the listing creation flow with optional parentId */
    navigateToListingCreation: (parentListingId?: string) => void
}

const UserListingsContainer = ({ isFullPage, navigateToListingPreview, navigateToListingSettings, navigateToListingCreation }: IUserListingsContainerProps) => {
    const userContext = useContext(UserContext);
    const userId = userContext?.user?.firebaseUser?.uid;
    const userRole = userContext?.user?.role;

    const { data, isLoading, isError } = useQuery(
        ["user", "listings", userId, userRole],
        () => getUserListings(userId || '', (userRole !== true && userRole) || EUserRole.HOST, false),
        { enabled: !!userId && userRole !== true && !!userRole }
    );

    if (!userContext) {
        console.warn('Need UserContext');
        return null;
    }
    if (!userContext.user) {
        console.warn('No user info');
        return null;
    }
    if (!userId) {
        console.warn('No user id');
        return null;
    }

    const overwrite = false;//trick to see the loading state (put back to false on prod)
    if (isLoading || !data || overwrite || userRole === true) {
        return <ListingsPageView
            // asRole={userRole}
            loading={true}
            simplified={!isFullPage} />
    }

    if (isError) {
        //TODO have proper error page
        return <Typography>Something went wrong</Typography>
    }

    const mapping = (listing: WithID<Listing, string>): Omit<IListingRowProps, 'simplified'> => {
        const subListings = listing.sub_listings
            ?.map((sl) => {
                //getting the sublisting full info from data
                return data.find(d => d.id === sl.id);
            })
            .filter((sl) => !!sl)//only get the one which got found
            ?? [];
        return {
            ...mapDataToUI(listing),
            onClick: () => navigateToListingSettings(listing.id),
            subListing: subListings.map(sl => mapping(sl!))
        }
    }

    const listProps =
        data
            .filter((listing) => !listing.breadcrumbs?.length)//only get the root listing
            .map((listing) => {
                return mapping(listing);
            }, [] as IListingRowProps[])

    const handleAddListingClick = () => {
        navigateToListingCreation();
    }

    if (!userRole) {
        return <UserSelectRoleContainer />
    }

    return <ListingsPageView
        // asRole={userRole}
        simplified={!isFullPage}
        items={listProps}
        onAddListingClick={handleAddListingClick} />
}

const mapDataToUI = (listing: Listing): Omit<IListingRowProps, 'simplified'> => {
    return {
        title: listing.title,
        image: listing.main_photo?.url || '',
        lastModified: new Date(),
        status: listing.status === 'published' ? 'on' : 'off',
        location: listing.location?.address?.city || '',
    }
}